// URLs
export const CONFIG_URL = '/api/system/';
export const PATIENT_URL = '/api/patients';
export const SCHEDULER_URL = '/api/scheduler';
export const REPORTING_URL = '/api/reporting';
export const SETTING_URL = '/api/setting';
export const PHARMACY_URL = '/api/pharmacy';
export const MESSAGING_URL = '/api/chat';
export const RESOURCE_URL = '/api/resources';
export const WORKFLOW_URL = '/api/workflow';
export const STATIC_URL = '/api/static';
export const SHARED_URL = '/api/shared';
export const STATISTIC_URL = '/api/statistic';
export const DOCUMENT_URL = '/api/documents';
export const PRINTER_URL = '/api/printer';
export const MAIL_URL = '/api/mail';
export const WS_URL = '/ws';
export const EXT_WS_URL = '/eid-ws';
export const LOGIN_URL = '/api/login';
export const LOGOUT_URL = '/api/logout';
export const REFRESH_URL = '/api/token/refresh';
export const USER_CHECK_URL = '/api/user/whoami';
export const PACS_URL = `/api/pacs`;
export const OFFICE_URL = `/office`;
export const OFFICE_COAUTHORING_URL = '/coauthoring/CommandService.ashx';
export const PRINTERS_URL = `/printers/`;
export const GIT_URL = `https://git.internal.selfip.com/api/v4/projects`;
export const QUEUE_URL = `/queue`;