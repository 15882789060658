import {Pipe, PipeTransform} from '@angular/core';
import {groupBy} from 'lodash';
import * as moment from 'moment';

@Pipe({
    name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {

    transform(array: any[], ...args: any[]): any[] {
        let [groupByProp] = args;

        let gr = groupBy(array, groupByProp);

        let result: { key: any, values: any }[] = [];

        // if key is instanceof Date
        Object.entries(gr).forEach(keyValue => {
            let [key, value] = keyValue;
            let found = result.find(it => moment(it.key).isSame(moment(key), 'd'));
            if (found) {
                result[result.indexOf(found)]['values'] = [...result[result.indexOf(found)]['values'], ...(value as any)];
            } else result.push({key: moment(key), values: value})
        });
        return result;
    }

}
