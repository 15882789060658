<mat-toolbar color="primary">
    <mat-icon fontIcon="mdi-table" fontSet="mdi"></mat-icon>
    {{'NEW_TABLE_VIEW' | translate}}
    <span fxFlex></span>
    <button mat-icon-button matDialogClose tabindex="-1">
        <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
    </button>
</mat-toolbar>

<div mat-dialog-content fxLayout="column" [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
        <mat-form-field appearance="outline" [style.width.%]="100">
            <mat-label>{{'NAME' | translate}}</mat-label>
            <input matInput [placeholder]="'NAME' | translate" formControlName="name">
        </mat-form-field>

        <mat-slide-toggle [style.fontSize.px]="13" formControlName="publique">{{'PUBLIC' | translate}}</mat-slide-toggle>
    </div>

    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)" [style.overflow]="'auto'">
        <mat-checkbox class="example-box" *ngFor="let col of columns | async" [value]="col" (change)="columnSelectionToggle(col)" cdkDrag [checked]="col.selected">{{col.header | translate}}</mat-checkbox>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button color="accent" mat-raised-button matDialogClose>{{'CLOSE' | translate}}</button>
    <button (click)="save()" [disabled]="!form.get('name').value" color="primary" mat-raised-button>{{'SAVE' | translate}}</button>
</div>
