<div fxLayout="column" class="card-view">
    <div [formGroup]="filterForm" class="search-area" fxLayout="row wrap" fxLayoutAlign="end end"
         fxLayoutGap="4px">
        <mat-form-field floatLabel="never" fxFlex>
            <span matPrefix><mat-icon fontIcon="mdi-magnify" fontSet="mdi" class="prefix-icon" style="top: -3px;"
                                      tabindex="-1"></mat-icon>&nbsp;</span>
            <input [autofocus]="true" [placeholder]="'SEARCH' | translate" class="filter-field" formControlName="key"
                   matInput>
        </mat-form-field>

        <span fxFlex></span>
        <div fxLayout="column">
            <span class="label-option">{{'MODALITY' | translate}}</span>
            <mat-button-toggle-group aria-label="modality" formControlName="modality" multiple
                                     name="modality">
                <mat-button-toggle *ngFor="let modality of modalities"
                                   [value]="modality">
                    {{modality}}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div fxLayout="column">
            <span class="label-option">{{'REPORT_STATUS' | translate}}</span>
            <mat-button-toggle-group aria-label="Report status" formControlName="completedReportStatus"
                                     multiple
                                     name="completedReportStatus">
                <mat-button-toggle *ngFor="let rule of  columnsFormattingRules | filterBy: 'REPORT_COMPLETION_STATUS'"
                                   [matTooltip]="rule.primaryFormatValue | translate" [value]="rule.primaryFormatValue">
                    <ng-container [ngSwitch]="rule.formattingStyle?.displayMode">
                        <ng-container *ngSwitchCase="'ICON'">
                            <mat-icon [fontIcon]="rule.formattingStyle?.icon"
                                      [ngStyle]="{background: 'linear-gradient(45deg, black, '+rule.formattingStyle?.color+', white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}" fontSet="mdi"></mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <span [style.color]="rule.formattingStyle?.color">{{rule.primaryFormatValue | translate}}</span>
                        </ng-container>
                    </ng-container>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div fxLayout="column">
            <span class="label-option">{{'PATIENT_STATUS' | translate}}</span>
            <mat-button-toggle-group aria-label="patient status" formControlName="patientStatus" multiple
                                     name="patientStatus">
                <mat-button-toggle *ngFor="let rule of  columnsFormattingRules | filterBy: 'PATIENT_STATUS'"
                                   [matTooltip]="rule.primaryFormatValue | translate" [value]="rule.primaryFormatValue">
                    <ng-container [ngSwitch]="rule.formattingStyle?.displayMode">
                        <ng-container *ngSwitchCase="'ICON'">
                            <mat-icon [fontIcon]="rule.formattingStyle?.icon"
                                      [ngStyle]="{background: 'linear-gradient(45deg, black, '+rule.formattingStyle?.color+', white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}" fontSet="mdi"></mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <span [style.color]="rule.formattingStyle?.color">{{rule.primaryFormatValue | translate}}</span>
                        </ng-container>
                    </ng-container>
                </mat-button-toggle>
            </mat-button-toggle-group>

        </div>

        <div *ngIf="generalSetting && generalSetting.billingRequired" fxLayout="column">
            <span class="label-option">{{'PAYMENT_STATUS' | translate}}</span>
            <mat-button-toggle-group aria-label="payment status" formControlName="paymentStatus" multiple
                                     name="paymentStatus">
                <mat-button-toggle *ngFor="let rule of  columnsFormattingRules | filterBy: 'PAYMENT_STATUS'"
                                   [matTooltip]="rule.primaryFormatValue | translate" [value]="rule.primaryFormatValue">
                    <ng-container [ngSwitch]="rule.formattingStyle?.displayMode">
                        <ng-container *ngSwitchCase="'ICON'">
                            <mat-icon [fontIcon]="rule.formattingStyle?.icon"
                                      [ngStyle]="{background: 'linear-gradient(45deg, black, '+rule.formattingStyle?.color+', white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}" fontSet="mdi"></mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <span [style.color]="rule.formattingStyle?.color">{{rule.primaryFormatValue | translate}}</span>
                        </ng-container>
                    </ng-container>
                </mat-button-toggle>
            </mat-button-toggle-group>

        </div>

        <div *ngIf="group.value === 'OT'" class="date-range-2" fxLayout="row nowrap" fxLayoutAlign="start end"
             fxLayoutGap="4px">
            <mat-form-field fxFlex="120px">
                <input (dateInput)="changePeriod()" [matDatepicker]="startDate" [placeholder]="'FROM' | translate"
                       formControlName="startDate" matInput>
                <mat-datepicker-toggle [for]="startDate" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #startDate (closed)="changePeriod()"></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex="120px">
                <input (dateInput)="changePeriod()" [matDatepicker]="endDate" [placeholder]="'TO' | translate"
                       formControlName="endDate"
                       matInput>
                <mat-datepicker-toggle [for]="endDate" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #endDate (closed)="changePeriod()"></mat-datepicker>
            </mat-form-field>
        </div>


        <mat-button-toggle-group #group="matButtonToggleGroup" (change)="changeRange($event)" aria-label="Period"
                                 formControlName="period" name="period">
            <mat-button-toggle value="TODAY">
                {{'TODAY' | translate}}
            </mat-button-toggle>
            <mat-button-toggle value="YESTERDAY">
                {{'YESTERDAY' | translate}}
            </mat-button-toggle>
            <mat-button-toggle value="3D">
                {{'THREE_DAYS' | translate}}
            </mat-button-toggle>
            <mat-button-toggle value="1W">
                {{'ONE_WEEK' | translate}}
            </mat-button-toggle>
            <mat-button-toggle value="1M">
                {{'ONE_MONTH' | translate}}
            </mat-button-toggle>
            <mat-button-toggle value="3M">
                {{'THREE_MONTHS' | translate}}
            </mat-button-toggle>
            <mat-button-toggle value="OT">
                {{'ALL' | translate}}
            </mat-button-toggle>
        </mat-button-toggle-group>

        <!--        <button (click)="refreshAZ()" [matTooltip]="'REFRESH' | translate" color="accent" mat-icon-button><mat-icon fontIcon="mdi-database-refresh" fontSet="mdi"></mat-icon></button>-->

        <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon class="table-custom-column-icon" fontIcon="mdi-table-cog" fontSet="mdi"></mat-icon>
        </button>
    </div>

    <mat-drawer-container [hasBackdrop]="false" class="scheduler-container" fxFlex="calc(100vh - 166px)">
        <mat-drawer #drawer [style.marginTop.px]="-1" [style.width.px]="320" mode="over" position="end">
            <table-formatter (close)="drawer.toggle()" (onSave)="onSaveTableConfig($event)"
                             [tableConfig]="schedulerTableConfig"></table-formatter>
        </mat-drawer>
        <mat-drawer-content>
            <div fxLayout="column" style="width: 100%; height: 100%;">

                <div style="height: calc(100vh - 207px); overflow: auto">

                    <mat-menu #menu2="matMenu" [overlapTrigger]="true">
                        <div (cdkDropListDropped)="drop2($event)" cdkDropList class="ft-drag-list">
                            <button (click)="$event.stopPropagation();toggleColumn2(column)"
                                    *ngFor="let column of examsColumns" cdkDrag
                                    class="ft-drag-box" mat-menu-item>
                                <mat-icon
                                        fontIcon="{{column.hidden ? 'mdi-checkbox-blank-circle-outline': 'mdi-check-circle'}}"
                                        fontSet="mdi"
                                        style="color: #1565c0;"></mat-icon>
                                {{column.header | translate}}
                            </button>
                        </div>
                    </mat-menu>

                    <mat-table [@state]="resultsLength" [dataSource]="dataSource"
                               [matSortActive]="'id'"
                               [matSortDirection]="'asc'"
                               [trackBy]="trackById"
                               class="fire-table expandable-rows-table"
                               matSort
                               matSortDisableClear
                               multiTemplateDataRows>

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                              [checked]="selection.hasValue() && isAllSelected()"
                                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                                              (click)="$event.stopPropagation()"
                                              [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </mat-cell>
                            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                        </ng-container>

                        <!-- Nested row-->
                        <ng-container matColumnDef="expandedRow">
                            <mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplay.length">
                                <div [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'"
                                     class="example-element-detail">
                                    <ng-container *ngIf="expandedElement">

                                        <table class="nested-table">
                                            <thead>
                                            <tr>
                                                <th *ngFor="let col of examsColumnsToDisplay">{{col.header | translate}}</th>
                                                <th>
                                                    <button [matMenuTriggerFor]="menu2" [style.float]="'right'"
                                                            mat-icon-button>
                                                        <mat-icon class="menu-btn" fontIcon="mdi-dots-vertical"
                                                                  fontSet="mdi"></mat-icon>
                                                    </button>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let el of expandedElement.workflowItems">
                                                <ng-container *ngFor="let col of examsColumnsToDisplay">
                                                    <td *ngIf="col.header === 'SPSSTATUS'">
                                                        <mat-icon
                                                                [ngClass]="{'mdi-spin': getPatientStatusIcon(el) === 'mdi-timelapse'}"
                                                                [ngStyle]="{'color': getIconColor(el), 'fontSize': '20px !important'}"
                                                                fontIcon="{{getPatientStatusIcon(el)}}"
                                                                fontSet="mdi"></mat-icon>
                                                    </td>

                                                    <td *ngIf="col.header === 'PROCEDURE_TYPE'">
                                                    <span [style.backgroundColor]="getExamColor(el)"
                                                          class="ft-label">{{el[col.attr]}}</span>
                                                    </td>

                                                    <td *ngIf="col.header === 'REPORT_COMPLETION_STATUS'">
                                                        <mat-icon
                                                                [ngStyle]="{'color': getReportIconColor(el[col.attr]), 'fontSize': '20px !important'}"
                                                                fontIcon="{{getReportingStatusIcon(el[col.attr])}}"
                                                                fontSet="mdi">
                                                        </mat-icon>
                                                    </td>
                                                    <td *ngIf="['PERFORMING_PHYSICIAN', 'TECHNICIAN'].includes(col.header)">
                                                        {{shortenName(el[col.attr])}}
                                                    </td>
                                                    <td *ngIf="['EXAM_START', 'EXAM_FINISH'].includes(col.header)">
                                                        {{el[col.attr] | date: 'HH:mm'}}
                                                    </td>
                                                    <td *ngIf="col.header === 'PATIENTARRIVAL'">
                                                        {{el[col.attr] | date: todayFormat}}
                                                    </td>
                                                    <td *ngIf="col.header === 'PAYMENT_STATUS'">
                                            <span [class.animate]="el[col.attr] === 'NOT_PAID'"
                                                  [ngStyle]="{background: getPaymentStatusColor(el[col.attr]), borderRadius: '50%'}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </td>
                                                    <td *ngIf="['TOTAL_AMOUNT', 'PAID_AMOUNT', 'DISCOUNT', 'LEFT_AMOUNT'].includes(col.header)">
                                                        {{el[col.attr] | numeric: {numberFormat, comma: true} }}
                                                    </td>
                                                    <td *ngIf="!specialFormat(col.header)">{{el[col.attr]}}</td>
                                                </ng-container>

                                                <td fxLayout="row nowrap">
                                                    <button (click)="deliverReport(el)"
                                                            [disabled]="!isGranted(el,'printReport') || !canPrintReport(el.completedReportStatus)"
                                                            [matTooltip]="'REPORT_DELIVERED' | translate"
                                                            color="accent"
                                                            mat-icon-button>
                                                        <mat-icon [class.btn-action-deliver]="isGranted(el,'printReport') && canPrintReport(el.completedReportStatus)" [style.color]="'inherit'" fontIcon="mdi-file-move"
                                                                  fontSet="mdi"></mat-icon>
                                                    </button>

                                                    <button [disabled]="el.old" [matMenuTriggerFor]="scMenu"
                                                            mat-icon-button>
                                                        <mat-icon fontIcon="mdi-dots-horizontal"
                                                                  fontSet="mdi"></mat-icon>
                                                    </button>

                                                    <mat-menu #scMenu="matMenu" [overlapTrigger]="true">

                                                        <button (click)="editExam(el)"
                                                                *ngIf="(profile && profile.editExam !== 'NONE') && (el.examinationStarted === null)"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
                                                            {{'EDIT' | translate}}
                                                        </button>
                                                        <button (click)="sendReport(row)"
                                                                [disabled]="!canPrintReport(el.completedReportStatus)"
                                                                class="fire-action"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-send" fontSet="mdi"></mat-icon>
                                                            {{'SEND_REPORT' | translate}}
                                                        </button>
                                                        <button (click)="sendSMS(el)"
                                                                class="fire-action"
                                                                [disabled]="cannot('sendSms')"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-email-plus"
                                                                      fontSet="mdi"></mat-icon>
                                                            {{'SEND_SMS' | translate}}
                                                        </button>
                                                        <mat-divider></mat-divider>
                                                        <button (click)="debitStock(el)" mat-menu-item>
                                                            <mat-icon fontIcon="mdi-store" fontSet="mdi"></mat-icon>
                                                            {{'PHARMACY' | translate}}
                                                        </button>
                                                        <mat-divider></mat-divider>
                                                        <button (click)="enterPatient(el)"
                                                                [disabled]="cannot('authorizeExam') || !isGranted(el, 'authorizeExam')"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-login" fontSet="mdi"></mat-icon>
                                                            {{'ENTER_PATIENT' | translate}}
                                                        </button>

                                                        <button (click)="onPatientLeave(el)"
                                                                [disabled]="cannot('exitPatient') || !isGranted(el, 'exitPatient')"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-logout" fontSet="mdi"></mat-icon>
                                                            {{'PATIENTLEAVE' | translate}}
                                                        </button>

                                                        <button (click)="addComment(el)" mat-menu-item>
                                                            <mat-icon fontIcon="mdi-comment-alert"
                                                                      fontSet="mdi"></mat-icon>
                                                            {{'ADD_COMMENT' | translate}}
                                                        </button>


                                                        <mat-divider></mat-divider>

                                                        <ng-container
                                                                *ngIf="generalSetting?.reportPrintMode === 'CHROME'">
                                                            <button (click)="printReportingTask(el)"
                                                                    [disabled]="cannot('printReport') || !isGranted(el, 'printReport')"
                                                                    mat-menu-item>
                                                                <mat-icon fontIcon="mdi-printer"
                                                                          fontSet="mdi"></mat-icon>
                                                                {{'PRINT_REPORT' | translate}}
                                                            </button>

                                                            <button (click)="printBooklet(el)"
                                                                    [disabled]="cannot('printReport') || !isGranted(el, 'printBooklet')"
                                                                    mat-menu-item>
                                                                <mat-icon fontIcon="mdi-printer"
                                                                          fontSet="mdi"></mat-icon>
                                                                {{'PRINT_BOOKLET' | translate}}
                                                            </button>
                                                        </ng-container>
                                                        <ng-template>
                                                            <button [disabled]="cannot('printReport') || !isGranted(el, 'printReport')"
                                                                    [matMenuTriggerFor]="printersMenu3"
                                                                    mat-menu-item>
                                                                <mat-icon fontIcon="mdi-printer"
                                                                          fontSet="mdi"></mat-icon>
                                                                {{'PRINT_REPORT' | translate}}
                                                            </button>

                                                            <button [disabled]="cannot('printReport') || !isGranted(el, 'printBooklet')"
                                                                    [matMenuTriggerFor]="printersMenu4"
                                                                    mat-menu-item>
                                                                <mat-icon fontIcon="mdi-printer"
                                                                          fontSet="mdi"></mat-icon>
                                                                {{'PRINT_BOOKLET' | translate}}
                                                            </button>

                                                            <mat-menu #printersMenu3="matMenu">
                                                                <button (click)="printReportingTask(el, printer['label'])"
                                                                        *ngFor="let printer of printers"
                                                                        mat-menu-item>
                                                                    <mat-icon fontIcon="mdi-printer"
                                                                              fontSet="mdi"></mat-icon>
                                                                    {{printer['label']}}
                                                                </button>
                                                            </mat-menu>
                                                            <mat-menu #printersMenu4="matMenu">
                                                                <button (click)="printBooklet(el, printer['label'])"
                                                                        *ngFor="let printer of printers"
                                                                        mat-menu-item>
                                                                    <mat-icon fontIcon="mdi-printer"
                                                                              fontSet="mdi"></mat-icon>
                                                                    {{printer['label']}}
                                                                </button>
                                                            </mat-menu>

                                                        </ng-template>

                                                        <button (click)="showPatientFolder(el)"
                                                                [disabled]="!can(el, 'viewPatientFolder')"
                                                                [matTooltip]="'PATIENT_FOLDER' | translate"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-folder-account"
                                                                      fontSet="mdi"></mat-icon>
                                                            {{'PATIENT_FOLDER' | translate}}
                                                        </button>

                                                        <mat-divider></mat-divider>

                                                        <button (click)="printRdvForResult(el)"
                                                                [disabled]="!can(el, 'createRdvForResult') || !isGranted(el, 'createRdvForResult')"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                            {{'RDV_FOR_RESULT' | translate}}
                                                        </button>

                                                        <button (click)="deleteExam(el)"
                                                                *ngIf="profile && profile.manageExam !== 'NONE' && notPaid(el)"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                                                            {{'DELETE' | translate}}
                                                        </button>
                                                    </mat-menu>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </ng-container>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <!--Table columns-->
                        <ng-container *ngFor="let col of displayedColumns" [class.hidden]="col.hidden"
                                      matColumnDef="{{col.label}}">

                            <mat-header-cell *matHeaderCellDef [disabled]="!col.sortable" class="fire-header-cell"
                                             [matTooltip]="col.header | translate"
                                             mat-sort-header="{{col.value}}">
                                {{col.header | translate}}
                            </mat-header-cell>
                            <ng-container [ngSwitch]="col.type">
                                <ng-container *ngSwitchCase="'DATE'">
                                    <mat-cell *matCellDef="let row" [ngStyle]="getColumnStyle(col.type, col, row)"
                                              class="fire-cell">
                                        <span>{{row[col.label] | date:  todayFormat }}</span>
                                    </mat-cell>
                                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>

                                <ng-container *ngSwitchCase="'BOOLEAN'">
                                    <mat-cell *matCellDef="let row"
                                              [ngStyle]="getColumnStyleDisplayMode(col.type, col, row)"
                                              class="fire-cell">
                                        <ng-container [ngSwitch]="getColumnDisplayMode(col.header)">
                                            <ng-container *ngSwitchCase="'ICON'">
                                                <mat-icon
                                                        [fontIcon]="getColumnFormattingIcon(col.header, row[col.label])"
                                                        [matTooltip]="(row[col.label] ? 'YES': 'NO') | translate"
                                                        [ngStyle]="getColumnFormattingIconStyle(col.header, row[col.label])"
                                                        fontSet="mdi"></mat-icon>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'BADGE'">
                                                <span [ngStyle]="getColumnStyleDisplayMode(col.type, col, row, 'BADGE')"
                                                      class="ft-badge">{{(row[col.label] ? 'YES' : 'NO') | translate}}</span>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                {{(row[col.label] ? 'YES' : 'NO')  | translate }}
                                            </ng-container>
                                        </ng-container>

                                    </mat-cell>
                                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>

                                <ng-container *ngSwitchCase="'STATUS'">
                                    <mat-cell *matCellDef="let row"
                                              [ngStyle]="getColumnStyleDisplayMode(col.type, col, row)"
                                              class="fire-cell">
                                        <ng-container [ngSwitch]="getColumnDisplayMode(col.header)">
                                            <ng-container *ngSwitchCase="'ICON'">
                                                <mat-icon
                                                        [fontIcon]="getColumnFormattingIcon(col.header, row[col.label])"
                                                        [matTooltip]="row[col.label] | translate"
                                                        [ngStyle]="getColumnFormattingIconStyle(col.header, row[col.label])"
                                                        fontSet="mdi"></mat-icon>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'BADGE'">
                                                <span [ngStyle]="getColumnBooleanBadgeStyle(col.header, row[col.label])"
                                                      class="ft-badge">{{row[col.label]  | translate}}</span>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                {{ row[col.label] | translate }}
                                            </ng-container>
                                        </ng-container>

                                    </mat-cell>
                                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>


                                <ng-container *ngSwitchCase="'NUMERIC'">
                                    <mat-cell *matCellDef="let row" [ngStyle]="getColumnStyle(col.type, col, row)"
                                              class="fire-cell">
                                        {{ row | sumWorkItemBy: col.label | numeric: {
                                        numberFormat,
                                        comma: col.label != 'count'
                                    } }}
                                    </mat-cell>
                                    <mat-footer-cell *matFooterCellDef>
                                        <span style="width: 100%">{{calculateSum(col.label, col.label != 'count')}}</span>
                                    </mat-footer-cell>
                                </ng-container>

                                <ng-container *ngSwitchDefault>
                                    <ng-container *ngIf="col.unit === 'ICON'">
                                        <mat-cell *matCellDef="let row" class="fire-cell">
                                            <mat-icon *ngIf="row[col.label]"
                                                      [fontIcon]="'mdi-message'"
                                                      [matTooltipClass]="'alert-tooltip'"
                                                      [matTooltip]="getNoteAlert(row[col.label])"
                                                      [ngStyle]="{color: '#00BCD4'}"
                                                      fontSet="mdi"></mat-icon>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container *ngIf="col.unit !== 'ICON'">
                                        <mat-cell *matCellDef="let row"
                                                  [ngStyle]="getColumnStyle(col.type, col, row)"
                                                  class="fire-cell"
                                                  fxLayoutGap="4px">
                                            <ng-container *ngIf="col.label === 'patientName'">
                                                <div fxLayout="row nowrap" fxLayoutAlign="space-between center">
                                                    <a [routerLink]="'/patients/details/'+row.patientId"
                                                       class="patient-name">{{row[col.label]}}</a>

                                                    <ng-container *ngIf="row.smsSent">
                                                        <mat-icon [matTooltip]="row.smsContent"
                                                                  [style.color]="'#607D8B'"
                                                                  fontIcon="mdi-email-send"
                                                                  fontSet="mdi"></mat-icon>
                                                    </ng-container>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="col.label !== 'patientName'">
                                                <span [matTooltip]="['insuranceName', 'procedureCode', 'sideEffects'].includes(col.label) ? (row[col.label] | translate): null">{{ row[col.label] | translate }}</span>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-footer-cell *matFooterCellDef><span
                                            style="width: 100%">{{calculateTotal(col.label)}}</span></mat-footer-cell>
                                </ng-container>
                            </ng-container>

                        </ng-container>

                        <!--actions-->
                        <ng-container [stickyEnd]="true" matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef class="fire-header-cell">
                                <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%;padding-right: 8px;">
                                    <span fxFlex></span>
                                    <button (click)="drawer.toggle()"
                                            [matTooltip]="'CONDITIONAL_FORMATTING' | translate"
                                            mat-icon-button>
                                        <mat-icon class="toolbar-icon-gradient" fontIcon="mdi-cog" fontSet="mdi"></mat-icon>
                                    </button>
                                </div>
                            </mat-header-cell>
                            <mat-cell (click)="$event.stopPropagation();" *matCellDef="let row" class="fire-cell">
                                <div fxLayout="row nowrap" fxLayoutAlign="end center"
                                     style="width: 100%;padding-right: 8px;">
                                    <mat-menu #wfMenu="matMenu" [overlapTrigger]="true">
                                        <button (click)="newExam(row)" mat-menu-item>
                                            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                                            {{'NEW_EXAM' | translate}}
                                        </button>
                                        <mat-divider></mat-divider>
                                        <button (click)="editExam(row)"
                                                *ngIf="(profile && profile.editExam !== 'NONE') && (row.examinationStarted === null) && row.count == 1"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
                                            {{'EDIT' | translate}}
                                        </button>
                                        <button (click)="printAttestation(row)"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                            {{'PRINT_ATTESTATION' | translate}}
                                        </button>
                                        <button (click)="sendReport(row)" *ngIf="row.count == 1"
                                                [disabled]="!canPrintReport(row.completedReportStatus)"
                                                class="fire-action" mat-menu-item>
                                            <mat-icon fontIcon="mdi-send" fontSet="mdi"></mat-icon>
                                            {{'SEND_REPORT' | translate}}
                                        </button>
                                        <button (click)="addComment(row)" mat-menu-item>
                                            <mat-icon fontIcon="mdi-comment-alert" fontSet="mdi"></mat-icon>
                                            {{'ADD_COMMENT' | translate}}
                                        </button>
                                        <button (click)="sendSMS(row)"
                                                class="fire-action"
                                                [disabled]="cannot('sendSms')"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-email-plus" fontSet="mdi"></mat-icon>
                                            {{'SEND_SMS' | translate}}
                                        </button>
                                        <button (click)="printTicket(row)" mat-menu-item>
                                            <mat-icon fontIcon="mdi-barcode" fontSet="mdi"></mat-icon>
                                            {{'PRINT_STICKER' | translate}}
                                        </button>

                                        <ng-container *ngIf="row.count == 1">
                                            <mat-divider></mat-divider>
                                            <button (click)="debitStock(row)" mat-menu-item>
                                                <mat-icon fontIcon="mdi-store" fontSet="mdi"></mat-icon>
                                                {{'PHARMACY' | translate}}
                                            </button>
                                        </ng-container>

                                        <mat-divider></mat-divider>
                                        <button (click)="enterPatient(row)"
                                                [disabled]="cannot('authorizeExam') || !isGranted(row, 'authorizeExam')"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-login" fontSet="mdi"></mat-icon>
                                            {{'ENTER_PATIENT' | translate}}
                                        </button>
                                        <button (click)="onPatientLeave(row)"
                                                *ngIf="row.count === 1"
                                                [disabled]="cannot('exitPatient') || !isGranted(row, 'exitPatient')"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-logout" fontSet="mdi"></mat-icon>
                                            {{'PATIENTLEAVE' | translate}}
                                        </button>

                                        <mat-divider></mat-divider>

                                        <ng-container *ngIf="generalSetting?.reportPrintMode === 'CHROME'">
                                            <button (click)="printReportingTask(row)"
                                                    *ngIf="row.count === 1"
                                                    [disabled]="cannot('printReport') || !isGranted(row, 'printReport')"
                                                    mat-menu-item>
                                                <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                {{'PRINT_REPORT' | translate}}
                                            </button>

                                            <button (click)="printBooklet(row)"
                                                    *ngIf="row.count === 1"
                                                    [disabled]="cannot('printReport') || !isGranted(row, 'printBooklet')"
                                                    mat-menu-item>
                                                <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                {{'PRINT_BOOKLET' | translate}}
                                            </button>
                                        </ng-container>
                                        <ng-template>
                                            <button *ngIf="row.count === 1"
                                                    [disabled]="cannot('printReport') || !isGranted(row, 'printReport')"
                                                    [matMenuTriggerFor]="printersMenu"
                                                    mat-menu-item>
                                                <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                {{'PRINT_REPORT' | translate}}
                                            </button>
                                            <mat-menu #printersMenu="matMenu">
                                                <button (click)="printReportingTask(row, printer['label'])"
                                                        *ngFor="let printer of printers"
                                                        mat-menu-item>
                                                    <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                    {{printer['label']}}
                                                </button>
                                            </mat-menu>

                                            <button *ngIf="row.count === 1"
                                                    [disabled]="cannot('printReport') || !isGranted(row, 'printBooklet')"
                                                    [matMenuTriggerFor]="printersMenu2"
                                                    mat-menu-item>
                                                <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                {{'PRINT_BOOKLET' | translate}}
                                            </button>
                                            <mat-menu #printersMenu2="matMenu">
                                                <button (click)="printBooklet(row, printer['label'])"
                                                        *ngFor="let printer of printers"
                                                        mat-menu-item>
                                                    <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                    {{printer['label']}}
                                                </button>
                                            </mat-menu>

                                        </ng-template>
                                        <button (click)="showPatientFolder(row)"
                                                [disabled]="!can(row, 'viewPatientFolder')"
                                                [matTooltip]="'PATIENT_FOLDER' | translate"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-folder-account" fontSet="mdi"></mat-icon>
                                            {{'PATIENT_FOLDER' | translate}}
                                        </button>

                                        <mat-divider></mat-divider>

                                        <button (click)="printRdvForResult(row)"
                                                [disabled]="!can(row, 'createRdvForResult') || !isGranted(row, 'createRdvForResult')"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                            {{'RDV_FOR_RESULT' | translate}}
                                        </button>

                                        <button (click)="deleteExam(row)"
                                                *ngIf="profile && profile.manageExam !== 'NONE' && (row.count === 1) && notPaid(row)"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                                            {{'DELETE' | translate}}
                                        </button>
                                    </mat-menu>
                                    <button (click)="sendToBilling(row)"
                                            *ngIf="generalSetting && generalSetting.billingRequired && !row['sent'] && isAfterUpdate()"
                                            [disabled]="profile && !profile['managePayment']"
                                            [matTooltip]="'SYNC_BILLING' | translate"
                                            mat-icon-button>
                                        <mat-icon fontIcon="mdi-sync" fontSet="mdi"></mat-icon>
                                    </button>
                                    <button (click)="deliverReport(row)" *ngIf="row.count == 1"
                                            [disabled]="!isGranted(row,'printReport') || !canPrintReport(row['completedReportStatus'])"
                                            [matTooltip]="'REPORT_DELIVERED' | translate"
                                            color="accent"
                                            mat-icon-button>
                                        <mat-icon [class.btn-action-deliver]="isGranted(row,'printReport') && canPrintReport(row['completedReportStatus'])" [style.color]="'inherit'" fontIcon="mdi-file-move"
                                                  fontSet="mdi"></mat-icon>
                                    </button>
                                    <button (click)="addPayment(row)"
                                            *ngIf="generalSetting && generalSetting.billingRequired && profile['managePayment']"
                                            [disabled]="cannot('addPayment')" [matTooltip]="'PAYMENT' | translate"
                                            mat-icon-button>
                                        <mat-icon fontIcon="mdi-currency-usd" fontSet="mdi"></mat-icon>
                                    </button>

                                    <button [disabled]="row['old']" [matMenuTriggerFor]="wfMenu" mat-icon-button>
                                        <mat-icon fontIcon="mdi-dots-horizontal" fontSet="mdi"></mat-icon>
                                    </button>
                                </div>
                            </mat-cell>
                            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                        </ng-container>

                        <mat-menu #menu="matMenu" [overlapTrigger]="true">
                            <div (cdkDropListDropped)="drop($event)" cdkDropList class="ft-drag-list">
                                <button (click)="$event.stopPropagation();toggleColumn(item)"
                                        *ngFor="let item of availableColumns" cdkDrag
                                        class="ft-drag-box" mat-menu-item>
                                    <mat-icon
                                            fontIcon="{{item.hidden ? 'mdi-checkbox-blank-circle-outline': 'mdi-check-circle'}}"
                                            fontSet="mdi"
                                            style="color: #1565c0;"></mat-icon>
                                    {{item.header | translate}}
                                </button>
                            </div>
                        </mat-menu>

                        <!--header-->
                        <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"
                                        class="fire-header-row"></mat-header-row>
                        <!--            <mat-header-row *matHeaderRowDef="subColumnsToDisplay; sticky: true" class="fire-header-row"></mat-header-row>-->

                        <mat-row (click)="expandRow(row)"
                                 *matRowDef="let row; columns: columnsToDisplay;"
                                 [ngClass]="{'fire-row': true, 'hide': row.hidden}"
                                 [ngStyle]="getRowFormattingStyle(row)"></mat-row>

                        <!--                        <mat-row (click)="expandRow(row)"
                                                         *matRowDef="let row; columns: columnsToDisplay;"
                                                         [ngClass]="{'fire-row': true, 'hide': row.hidden}"
                                                         [style.background]="getRowBgColor(row.paymentStatus)"></mat-row>-->

                        <mat-row *matRowDef="let row; columns: ['expandedRow']" [class.visible]="row == expandedElement"
                                 class="example-detail-row"></mat-row>
                        <mat-footer-row *matFooterRowDef="columnsToDisplay; sticky: true"
                                        [class.hidden-footer]="resultsLength == 0"
                                        [style.minHeight.px]="32"></mat-footer-row>
                    </mat-table>
                    <div [style.display]="resultsLength === 0 ? '' : 'none'" class="example-no-results">
                        <div *ngIf="isLoadingResults; else noItem">
                            <mat-spinner [diameter]="50" [strokeWidth]="3" color="accent"></mat-spinner>
                        </div>
                        <ng-template #noItem>
                            {{'NOITEMSFOUND' | translate}}
                        </ng-template>
                    </div>

                </div>
                <mat-paginator [length]="resultsLength"
                               [pageIndex]="0"
                               [pageSizeOptions]="[20, 50, 100, 150, 200]"
                               [pageSize]="defaultPageSize"
                               showFirstLastButtons>
                </mat-paginator>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>




