import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {SchedulerService} from '../scheduler.service';
import {BehaviorSubject, merge, of as observableOf, Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {catchError, debounceTime, delay, map, startWith, switchMap, tap} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SC_COLS, SC_COLUMNS} from './table-conf';
import {tableAnimation} from '../../animations';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {
    ColumnDataType,
    DisplayMode,
    FormatRule,
    FormattingRule,
    GeneralSetting,
    PatientWorkflow,
    RendezVous,
    TableColumn,
    TableConfig,
    ThermalPrintModel,
    WorkflowFilter,
    WorkflowItem
} from '../../model';
import {findIndex, get, map as _map, sortBy, union, xorBy} from 'lodash';
import {
    checkCondition,
    CommentsComponent,
    DeleteConfirmComponent,
    getDisplayStyle,
    groupWorkflowData,
    hasPermission,
    LocalStorageService,
    PatientArrivedComponent,
    paymentColor,
    PaymentOrderComponent,
    RdvPrintComponent,
    SharedService,
    shortName,
    SmsSenderComponent,
    specialAttributes,
    StockMovementComponent,
    waitingDuration
} from '../../shared';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import * as numeral from 'numeral';
import {SettingService} from '../../setting/setting.service';
import {
    DateUtils,
    defaultWorkflowFormatting,
    getCompetedReportIconColor,
    getIconColor,
    getPatientStatusIcon,
    getReportStatusIcon,
    StringUtils,
    sumBy
} from '../../utils';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';
import {ReportingService} from '../../reporting/reporting.service';
import {SelectionModel} from '@angular/cdk/collections';
import {WorkflowService} from '../../workflow/workflow.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {WsService} from '../../ws.service';
import {ExamAddComponent} from '../exam-add/exam-add.component';
import {EmailSendComponent} from '../../reporting/email-send/email-send.component';
import {ExamAdvancedComponent} from '../exam-advanced/exam-advanced.component';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from '../../app-config.service';


const SC_TABLE_CONFIG_NAME = 'Scheduler_v4';

@Component({
    selector: 'app-schedule-manager',
    templateUrl: './schedule-manager.component.html',
    styleUrls: ['./schedule-manager.component.scss'],
    animations: [tableAnimation,
        trigger('detailExpand', [
            state('collapsed', style({
                height: '0px',
                minHeight: '0',
                display: 'none',
                visibility: 'hidden',
                zIndex: '-1'
            })),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class ScheduleManagerComponent implements AfterViewInit, OnDestroy {

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    dataSource = new MatTableDataSource<PatientWorkflow>();

    isLoadingResults = true;
    isRateLimitReached = false;
    resultsLength: number;
    expandedElement: any;
    displayedColumns: TableColumn[] = SC_COLUMNS;
    columnsToDisplay: TableColumn[] = [];
    subColumnsToDisplay: string[] = [];
    availableColumns = [];
    defaultPageSize = 100;
    examsColumnsToDisplay = SC_COLS;
    examsColumns = SC_COLS;
    generalSetting: GeneralSetting;
    todayFormat = 'HH:mm';
    profile: any;
    canViewConfData: boolean;
    printers = [];
    selection = new SelectionModel<PatientWorkflow>(true, []);
    filterForm: FormGroup;

    private wf = new WorkflowFilter();
    private workflowFilterSubject = new BehaviorSubject<WorkflowFilter>(new WorkflowFilter());
    private query = new BehaviorSubject<string>(null);
    currentDate = moment().format('LLLL');

    private mainTableCols = 'scheduler_table';
    private nestedTableCols = 'sc_cols';

    private reasonForExams = [];
    schedulerTableConfig: TableConfig;
    linesFormattingRules: FormattingRule[] = [];
    columnsFormattingRules: FormattingRule[] = [];
    styles = {};
    totalAmount = {totalAmount: 0, paidAmount: 0, globalDiscount: 0, leftAmount: 0};
    private subTotalSubject: BehaviorSubject<any> = new BehaviorSubject<any>([0, 0, 0, 0]);
    public readonly numberFormat: string;
    private user: any;
    private ws$: Subscription;

    private static isConditionFilled(colDataType: ColumnDataType, formatRule: FormatRule, data: any, firstValue: any, secondValue: any): boolean {
        return checkCondition(colDataType, formatRule, data, firstValue, secondValue);
    }

    constructor(private service: SchedulerService, private localStorage: LocalStorageService,
                private scheduler: SchedulerService,
                private workflowService: WorkflowService,
                private reportingService: ReportingService,
                private wsService: WsService,
                private dialog: MatDialog,
                private snack: MatSnackBar,
                private _config: AppConfigService,
                private route: ActivatedRoute,
                private router: Router,
                private fb: FormBuilder,
                private translate: TranslateService,
                private shared: SharedService,
                private setting: SettingService) {

        this.numberFormat = this._config.numberFormat;
        this.user = get(this.route.snapshot.data, 'user');

        this.subTotalSubject.asObservable().subscribe((data: any[]) => {
            if (data && data.length !== 0) {
                this.totalAmount['totalAmount'] = data[0];
                this.totalAmount['paidAmount'] = data[1];
                this.totalAmount['globalDiscount'] = data[2];
                this.totalAmount['leftAmount'] = data[3];
            }
        });

        // this.subTotalSubject.asObservable().subscribe((data: PatientWorkflow[]) => {
        //     if (data && data.length !== 0) {
        //         this.totalAmount['totalAmount'] = sumBy('totalAmount', data);
        //         this.totalAmount['paidAmount'] = sumBy('paidAmount', data);
        //         this.totalAmount['globalDiscount'] = sumBy('globalDiscount', data);
        //         this.totalAmount['leftAmount'] = sumBy('leftAmount', data);
        //     }
        // });

        this.columnsToDisplay = sortBy(SC_COLUMNS.filter(item => !item.hidden), 'order').map(it => it.label);
        this.columnsToDisplay = union(this.columnsToDisplay, ['action']);
        this.subColumnsToDisplay = this.columnsToDisplay.map(it => it + '1');
        this.availableColumns = this.localStorage.getItem(this.mainTableCols) || sortBy(SC_COLUMNS.filter(item => !item.hidden), 'order');

        this.defaultPageSize = parseInt(this.localStorage.getItem('sc_page_size') || 25, 0);
        this.setting.getGeneralSetting().subscribe(value => {
            this.generalSetting = value;

            if (this.generalSetting && (!this.generalSetting.billingRequired || !this.profile.managePayment)) {
                this.availableColumns = this.availableColumns.filter(col => !['paymentStatus', 'totalAmount', 'paidAmount', 'leftAmount', 'discount', 'globalDiscount', 'billed'].includes(col.label));
                this.examsColumns = this.examsColumns.filter(col => !['totalAmount', 'discount'].includes(col.attr));
                this.examsColumnsToDisplay = this.examsColumnsToDisplay.filter(col => !['totalAmount', 'discount'].includes(col.attr));
            }
            this.setColumnsToDisplay(this.availableColumns, this.mainTableCols);
        });
        this.shared.getPrinters().subscribe(data => this.printers = data);
        this.shared.getReasonForExams().subscribe(data => this.reasonForExams = data);

        this.canViewConfData = this.user.canViewConfidentialData;
        this.profile = this.user.profile;
        let userId = this.user.id;

        this.setting.getTableConfig(SC_TABLE_CONFIG_NAME, userId).subscribe(result => {
            if (result) {
                let columns = get(result, 'tableColumns', []);
                let xorArray: TableColumn[] = xorBy(columns, SC_COLUMNS, 'label');

                this.schedulerTableConfig = result;

                if (xorArray.length !== 0) {
                    this.schedulerTableConfig.tableColumns = union(columns, xorArray);
                    this.onSaveTableConfig(this.schedulerTableConfig);
                } else this.dispatchRules(result.formattingRules);
            } else {
                this.schedulerTableConfig = {
                    id: 0,
                    userId: userId,
                    name: SC_TABLE_CONFIG_NAME,
                    tableColumns: SC_COLUMNS,
                    formattingRules: defaultWorkflowFormatting
                };

                this.onSaveTableConfig(this.schedulerTableConfig);
            }
        });

        this.selection.changed.asObservable().subscribe(res => console.log('selected: ', res.added.map(it => it.accessionNumber)));

        this.createFilterForm();

        setTimeout(() => this.ws$ = this.subscribeToWsTopic(), 2000);
    }

    ngOnDestroy() {
        this.ws$?.unsubscribe();
    }

    trackById = (index: number, item: any): string => item.accessionNumber;

    private createFilterForm() {
        this.filterForm = this.fb.group({
            key: '',
            startDate: new Date(),
            endDate: new Date(),
            period: 'TODAY',
            modality: null,
            technicianId: null,
            physicianId: null,
            reportStatus: null,
            patientStatus: null,
            paymentStatus: null,
            completedReportStatus: null
        });

        this.filterForm.valueChanges.subscribe(value => this.buildWorkflowFilter(value));
    }

    changePeriod() {
        this.filterForm.get('period').patchValue('OT');
    }

    changeRange(e) {
        let dateRange = DateUtils.PeriodDateRange(e.value);
        this.filterForm.patchValue(dateRange);
        this.wf.dateRange = `${dateRange.startDate.format('YYYYMMDD')}-${dateRange.endDate.format('YYYYMMDD')}`;
        this.workflowFilterSubject.next(this.wf);
    }

    private buildQuery() {
        this.workflowFilterSubject
            .asObservable()
            .subscribe((wf: WorkflowFilter) => {
                this.query.next([wf.key.replace('@', ''), wf.dateRange, wf.technicianId, wf.physicianId, wf.patientStatuses, wf.reportStatuses, wf.modalities, wf.paymentStatuses].join('@'))
            });
    }

    private buildWorkflowFilter(value: any) {
        this.todayFormat = value.period === 'TODAY' ? 'HH:mm' : this._config.dateTimeFormat;

        let startDate = moment(value.startDate).isValid() ? moment(value.startDate) : moment().subtract(10, 'year');
        let endDate = moment(value.endDate).isValid() ? moment(value.endDate) : moment().add(10, 'd');

        let start = startDate.format('YYYYMMDD');
        let end = endDate.format('YYYYMMDD');

        this.currentDate = value.period === 'OT' ? '' : start === end ? startDate.format('LLLL') : DateUtils.formatRange(startDate, endDate, this._config.appLang);

        this.wf.key = value.key;
        this.wf.dateRange = `${start}-${end}`;
        this.wf.modalities = value.modality ? value.modality.join('-') : 'ALL';
        let crs = value.completedReportStatus;
        if (crs && crs.includes('INPROGRESS')) crs = [...crs, 'IN_PROGRESS', 'TO_SIGN', 'TO_TRANSCRIBE', 'TO_VERIFY', 'VERIFIED', 'FINISHED'];
        if (crs && crs.includes('READY')) crs = [...crs, 'SIGNED'];
        this.wf.reportStatuses = crs && crs.length !== 0 ? crs.join('-') : 'ALL';
        this.wf.technicianId = value.technicianId;
        this.wf.physicianId = value.physicianId;
        this.wf.patientStatuses = value.patientStatus && value.patientStatus.length !== 0 ? value.patientStatus.join('-') : 'ALL';
        this.wf.paymentStatuses = value.paymentStatus && value.paymentStatus.length !== 0 ? value.paymentStatus.join('-') : 'ALL';

        this.workflowFilterSubject.next(this.wf);
    }

    private resetPaginator = () => this.query.subscribe(() => this.paginator.pageIndex = 0);

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    sendToBilling(row: PatientWorkflow) {
        this.workflowService.syncWithBilling(row.accessionNumber).subscribe(console.log)
    }

    deliverReport(row: WorkflowItem | PatientWorkflow) {
        this.reportingService
            .deliverCompletedReport(row.reportingTaskId)
            .subscribe(ok => {
                if (ok) this.workflowFilterSubject.next(this.wf)
            });
    }

    sumByField(row, label): number {
        switch (label) {
            case 'totalAmount':
                return sumBy('totalAmount', row.workflowItems) - sumBy('discount', row.workflowItems);
            case 'globalDiscount':
                return row[label];
            case 'leftAmount':
                return sumBy('totalAmount', row.workflowItems) - sumBy('paidAmount', row.workflowItems) - sumBy('discount', row.workflowItems) - row['globalDiscount'];
        }
        return sumBy(label, row.workflowItems)
    }

    sendReport(row) {
        this.dialog.open(EmailSendComponent, {
            width: '600px',
            data: row,
            hasBackdrop: false,
            disableClose: true,
            position: {bottom: '0', right: '80px'}
        }).afterClosed().subscribe(result => {
            if (result) {
                let status = get(result, 'service');
                if (status !== 'ERROR') {
                    this.workflowFilterSubject.next(this.wf);
                    this.service.updateReportingTaskStatus(row.reportingTaskId, 'DELIVERED').subscribe();
                }
                this.snack.open(status, 'Ok', {duration: 2000});
            }
        })
    }

    isAfterUpdate() {
        return moment().isSameOrAfter(moment('2019-10-23', 'YYYY-MM-DD'), 'd');
    }

    canPrintReport(status: string): boolean {
        return ['SENT', 'PRINTED', 'READY', 'DELIVERED'].includes(status);
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.columnsToDisplay, event.previousIndex, event.currentIndex);
        moveItemInArray(this.availableColumns, event.previousIndex, event.currentIndex);

        this.setColumnsToDisplay(this.availableColumns, this.mainTableCols);
    }

    drop2(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.examsColumns, event.previousIndex, event.currentIndex);
        this.localStorage.setItem(this.nestedTableCols, this.examsColumns);

        this.examsColumnsToDisplay = this.examsColumns.filter(it => !it.hidden);
    }

    toggleColumn(col) {
        let idx = findIndex(this.availableColumns, {header: col.header});
        this.availableColumns[idx].hidden = !col.hidden;
        this.setColumnsToDisplay(this.availableColumns, this.mainTableCols);
    }

    setColumnsToDisplay(columns, key: string) {
        this.localStorage.setItem(key, columns);

        this.columnsToDisplay = _map(columns.filter(c => !c.hidden), 'value');
        this.columnsToDisplay = union(this.columnsToDisplay, ['action']);
    }

    toggleColumn2(col) {
        let idx = findIndex(this.examsColumns, {header: col.header});
        this.examsColumns[idx].hidden = !col.hidden;
        this.examsColumnsToDisplay = this.examsColumns.filter(it => !it.hidden);
        this.localStorage.setItem(this.nestedTableCols, this.examsColumns);
    }

    isPatientAngry(row: WorkflowItem): boolean {
        return row.patientStatus === 'WAITING' && this.generalSetting && (waitingDuration(row) > this.generalSetting.waitingDurationBeforeAlert)
    }

    formatNumeral(numValue: any, comma: boolean = false): any {
        return numeral(numValue).format(`${comma ? this.numberFormat : '0,0'}`);
    }

    getIconColor(row: WorkflowItem): string {
        return this.isPatientAngry(row) ? '#f00' : getIconColor(row.patientStatus);
    }

    getPatientStatusIcon(row: WorkflowItem): string {
        if (this.isPatientAngry(row)) return 'mdi-emoticon-sad';
        return getPatientStatusIcon(row.patientStatus);
    }

    deleteExam(wf: WorkflowItem) {
        this.dialog.open(DeleteConfirmComponent).afterClosed().subscribe(ok => {
            if (ok) {
                this.scheduler.deleteExam(wf.isrId).subscribe(res => {
                    if (res) this.snack.open(this.translate.instant('EXAM_DELETED'), 'Ok', {duration: 2000});
                });
            }
        });
    }

    addPayment(row: WorkflowItem) {
        this.dialog.open(PaymentOrderComponent, {
            data: {
                spsId: row.spsId,
                patientID: row.patientID,
                patientName: row.patientName
            },
            disableClose: true
        })
            .afterClosed()
            .subscribe(order => {
                if (order)
                    this.scheduler
                        .orderPayment(order)
                        .subscribe(res => {
                            if (res && res.id) {
                                this.snack.open(this.translate.instant('NEW_PAYMENT_DONE'), 'OK', {duration: 2000});
                            }
                        });
            });
    }

    public printReportingTask(row, printer?: string) {

        let matSnackBarRef = this.snack.open(this.translate.instant('PRINTING_IN_PROGRESS'), '', {duration: 10000});

        if (this.generalSetting.reportPrintMode === 'CHROME')
            this.reportingService.printSimpleReport(row.reportingTaskId, printer, 1).subscribe(_ => matSnackBarRef.dismiss());
        else {
            this.reportingService.printCupsSimpleReport(row.reportingTaskId, printer, 1)
                .subscribe(res => {
                    matSnackBarRef.dismiss();
                    if (res['status'] !== 'ok') alert('Cannot print the report');
                    else this.snack.open(this.translate.instant('FINALIZING_PRINTING'), '', {duration: 3000});
                });
        }
    }

    public printBooklet(row, printer?: string) {
        let matSnackBarRef = this.snack.open(this.translate.instant('PRINTING_IN_PROGRESS'), '', {duration: 10000});

        if (this.generalSetting.reportPrintMode === 'CHROME') this.reportingService.printReport(row.reportingTaskId).subscribe(_ => matSnackBarRef.dismiss());
        else {
            this.reportingService
                .printCupsReport(row.reportingTaskId, printer, "1")
                .subscribe(response => {
                    if (response['status'] !== 'ok') alert('Cannot print the booklet');
                    else {
                        matSnackBarRef.dismiss();
                        this.snack.open(this.translate.instant('FINALIZING_PRINTING'), '', {duration: 4000});
                    }
                });
        }
    }

    getReportingStatusIcon = (status): string => getReportStatusIcon(status);
    getReportIconColor = (status): string => getCompetedReportIconColor(status);
    getPaymentStatusColor = (status: string): string => paymentColor(status);

    specialFormat(header: string): boolean {
        return specialAttributes(header);
    }

    enterPatient(row) {
        this.scheduler.getISRByAN(row.accessionNumber).subscribe(isr => {
            this.dialog
                .open(PatientArrivedComponent, {
                    data: {isr: isr, canViewConfData: this.canViewConfData},
                    width: '600px'
                })
                .afterClosed()
                .subscribe(isr => {
                    if (isr) {
                        this.scheduler.markPatientAsArrived(isr)
                            .subscribe(next => {
                                setTimeout(() => this.workflowFilterSubject.next(this.wf), 10000);
                                this.snack.open(this.translate.instant('PATIENT_AUTHORIZED'), 'Ok', {duration: 2000})
                            });
                    }
                });
        });
    }

    onPatientLeave(row) {
        this.scheduler
            .exitPatient(row.accessionNumber)
            .subscribe(nxt => {
                setTimeout(() => this.workflowFilterSubject.next(this.wf), 10000);
                this.snack.open(this.translate.instant('PATIENT_LEFT'), 'OK', {duration: 2000});
            });
    }

    notPaid(row: any): boolean {
        return ['NOT_PAID', 'EXEMPT'].includes(row.paymentStatus);
    }

    printRdvForResult(row) {
        let rdv = new RendezVous(
            row.patientID,
            row.accessionNumber,
            row.patientName,
            moment().format('YYYY-MM-DD'), '', ''
        );

        this.dialog.open(RdvPrintComponent, {data: rdv});
    }

    showPatientFolder = (row) => this.router.navigate(['/patients/details', row.patientId]);
    can = (row: any, action: string): boolean => (this.profile[action] !== 'NONE') && !row.confidential || this.canViewConfData;
    cannot = (action: string): boolean => this.profile[action] === 'NONE';
    isGranted = (row: WorkflowItem, status: string): boolean => hasPermission(status, row);
    columnFormattingRules = (header: string): FormattingRule[] => this.columnsFormattingRules ? this.columnsFormattingRules.filter(it => it.targetColumn === header) : [];
    modalities: any[] = ['CR', 'CT', 'DX', 'MG', 'MR', 'US', 'XA'];

    shortenName(name: string): string {
        return shortName(name)
    }

    expandRow(row: any) {
        this.expandedElement = this.expandedElement === row ? null : row;
    }

    getExamColor(row: PatientWorkflow | WorkflowItem): string {
        let exam = this.reasonForExams.find(it => it.value === row.examType);
        return exam ? exam.color : '';
    }

    printTicket(item: any) {
        this.service.getISRByAN(item.accessionNumber).subscribe(isr => {
            if (isr) {
                let date = moment(item.patientArrival).format('DD/MM/YYYY HH:mm');
                let printable = new ThermalPrintModel(item.patientID, item.patientName, date, isr.imagingOrder.referringPhysician ? isr.imagingOrder.referringPhysician.fullName : '-', item.accessionNumber, item.procedureCode);

                if (this.generalSetting.ticketPrintMode === 'CHROME') this.service.printTicket(printable).subscribe();
                else {
                    this.service.printCupsTicket(printable).subscribe(ok => {
                        if (ok['status'] !== 'ok') alert('Cannot print the ticket');
                        else this.snack.open(this.translate.instant('PRINTING_IN_PROGRESS'), '', {duration: 3000});
                    });
                }
            }
        });
    }

    editExam(row: WorkflowItem | PatientWorkflow) {
        this.service.getISRByAN(row.accessionNumber).subscribe(isr => {
            if (isr) {
                let editable = (row.reportSignature === null) && ['NOT_PAID', 'EXEMPT'].includes(row.paymentStatus);

                let sps = get(isr, 'requestedProcedure.scheduledProcedureSteps[0]');

                this.dialog.open(ExamAddComponent, {
                    data: {
                        spsStatus: sps.scheduledProcedureStepStatus,
                        resource: 'n/a',
                        selectedDateRange: {
                            start: sps.scheduledProcedureStepStartDate,
                            end: sps.scheduledProcedureStepEndDate
                        },
                        patient: get(isr, 'patient'),
                        ids: {isrId: isr.id, rpId: sps.requestedProcedureId, spsId: sps.id},
                        isr: isr,
                        editable: editable
                    }
                }).afterClosed().subscribe(res => {
                    if (res) {
                        if (res['action'] === 'updateISR') {
                            this.service.updateISR(res.data).subscribe(res2 => {
                                if (res2) this.snack.open(this.translate.instant('EXAM_SCHEDULED'), 'Ok', {duration: 2000});
                            });
                        }
                    }
                });
            }
        })
    }

    newExam(row: any) {
        this.service.getPatientById(row.patientId).subscribe(patient => {
            if (patient) this.dialog.open(ExamAdvancedComponent, {
                data: {
                    spsStatus: 'ARRIVED',
                    resource: 'n/a',
                    patient, isr: null,
                    selectedDateRange: {start: moment(), end: moment().add(15, 'm')},
                    editable: true,
                    queryParam: null,
                    admissionNumber: row.admissionNumber,
                    panelClass: 'exam-dialog'
                },
                disableClose: true
            }).afterClosed().subscribe(res => {
                if (res) this.workflowFilterSubject.next(this.wf)
            });
        });
    }

    debitStock(row: WorkflowItem | PatientWorkflow) {
        this.dialog.open(StockMovementComponent, {data: row, disableClose: true})
            .afterClosed()
            .subscribe(_ => this.workflowFilterSubject.next(this.wf));
    }

    getRowBgColor(paymentStatus: any): any {
        if (paymentStatus === 'PAID') return;
        return paymentColor(paymentStatus, true);
    }

    ngAfterViewInit(): void {
        this.buildQuery();
        this.resetPaginator();

        let observedFilters = [
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.query.pipe(debounceTime(250))
        ];

        merge(...observedFilters).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                let query = this.query.getValue();
                this.localStorage.setItem('sc_page_size', this.paginator.pageSize);
                return this.service.getImagingServiceRequests(this.paginator.pageSize,
                    this.paginator.pageIndex,
                    this.sort.active,
                    this.sort.direction,
                    query);
            }),
            tap(data => {
                this.isLoadingResults = false;
                this.isRateLimitReached = false;
                this.resultsLength = data['totalElements'];
            }),
            map(data => data['content'] as WorkflowItem[]),
            catchError(() => {
                this.isLoadingResults = false;
                this.isRateLimitReached = true;
                return observableOf([]);
            })
        ).subscribe(data => {
            this.dataSource.data = groupWorkflowData(data as WorkflowItem[]);

            this.workflowService.calculateSubTotals(this.query.getValue()).subscribe(res => this.subTotalSubject.next(res));

            this.getLinesFormattingStyles();
        });


        if (window.location.hostname.startsWith('fireris')) setTimeout(() => {
            this.filterForm.get('period').patchValue('3M');
            this.changeRange({value: '3M'})
        });
    }

    getNoteAlert(note: string): string {
        let tooltip;
        let text = note.split('|');
        if (text.length !== 0) {
            tooltip = text.map(t => {
                let tokens = t.split(';');
                return tokens.length > 1 ? `- ${tokens[1].toUpperCase()}:\n${tokens[2]}` : '';
            }).join('\n')
        }
        return tooltip.indexOf(undefined) !== -1 ? '' : tooltip
    }


    onSaveTableConfig(tableConfig: TableConfig) {
        this.setting.saveTableConfig(tableConfig).subscribe(res => {
            if (res) {
                this.schedulerTableConfig = res;
                this.dispatchRules(res.formattingRules);

                this.getLinesFormattingStyles();
            }
        });
    }

    getColumnStyle(colType: ColumnDataType, column: TableColumn, row: PatientWorkflow | WorkflowItem): any {
        if (!this.columnsFormattingRules) return;
        let rule = this.columnsFormattingRules.find(it => it.targetColumn === column.header);
        if (rule && ScheduleManagerComponent.isConditionFilled(colType, rule.formatRule, row[column.label], rule.primaryFormatValue, rule.secondaryFormatValue)) return getDisplayStyle(rule.formattingStyle);
    }

    getColumnBooleanTextStyle(header: string, cellValue: any, displayMode = 'TEXT'): any {
        let rules = this.columnFormattingRules(header);

        let rule = rules.find(it => it.primaryFormatValue == cellValue.toString());
        let style = rule ? rule.formattingStyle : null;

        let displayStyle = getDisplayStyle(style);

        if (rule && rule.formattingStyle.displayMode === displayMode) return displayStyle;
    }

    getColumnDisplayMode(header: string): DisplayMode {
        let rules = this.columnFormattingRules(header);
        let rule = rules[0];
        return rule ? rule.formattingStyle.displayMode : DisplayMode.TEXT;
    }

    getColumnFormattingIcon(header: string, cellValue: any): any {
        let rules = this.columnFormattingRules(header);
        let rule = rules.find(it => it.primaryFormatValue == cellValue.toString());
        return rule && rule.primaryFormatValue == cellValue.toString() ? rule.formattingStyle.icon : '';
    }

    getColumnFormattingIconStyle(header: string, cellValue: any): any {
        let rules = this.columnFormattingRules(header);
        let rule = rules.find(it => it.primaryFormatValue == cellValue.toString());
        if (rule) return getDisplayStyle(rule.formattingStyle);
    }

    getColumnBooleanBadgeStyle(header: string, cellValue: any): any {
        return this.getColumnBooleanTextStyle(header, cellValue, 'BADGE');
    }

    getColumnStyleDisplayMode(colType: ColumnDataType, column: TableColumn, row: PatientWorkflow | WorkflowItem, displayMode: string = DisplayMode.TEXT): any {
        if (!this.columnsFormattingRules) return;

        let rule = this.columnsFormattingRules.find(it => it.targetColumn === column.header);
        if (rule && rule.formattingStyle.displayMode === displayMode && ScheduleManagerComponent.isConditionFilled(colType, rule.formatRule, row[column.label], rule.primaryFormatValue, rule.secondaryFormatValue)) return getDisplayStyle(rule.formattingStyle);
    }

    getRowFormattingStyle(row: WorkflowItem | PatientWorkflow): any {
        if (!this.linesFormattingRules) return;
        return this.styles[row.accessionNumber];
    }

    private getLinesFormattingStyles() {
        if (!this.linesFormattingRules) return;

        this.linesFormattingRules.forEach(rule => {
            let column = this.schedulerTableConfig.tableColumns.find(it => it.header === rule.targetColumn);
            this.dataSource.data.forEach(row => {
                if (ScheduleManagerComponent.isConditionFilled(column.type, rule.formatRule, row[column.label], rule.primaryFormatValue, rule.secondaryFormatValue))
                    this.styles[row.accessionNumber] = getDisplayStyle(rule.formattingStyle);
            });
        });
    }


    private dispatchRules(defaultRules: FormattingRule[]) {
        let rules = StringUtils.groupBy(defaultRules, 'appliedTo');
        this.linesFormattingRules = rules['ROW'];
        this.columnsFormattingRules = rules['COLUMN'];
    }

    calculateSum(label: string, comma = false): string {
        if (label === 'count') return String(this.resultsLength);
        return this.formatNumeral(get(this.totalAmount, label) || 0, comma);
    }

    calculateTotal(label: string): any {
        if (label === 'patientName') return 'Total patients: ' + this.formatNumeral(this.dataSource.data.length);
    }

    addComment(row: WorkflowItem) {
        this.dialog.open(CommentsComponent, {
            data: {username: this.user.username, comment: row.noteAlert},
            width: '400px',
            disableClose: true
        }).afterClosed().subscribe(comments => {
            if (comments && comments === 'dismiss') return;

            this.reportingService.getReportingTask(row.reportingTaskId).subscribe(task => {
                task.noteAlert = comments;
                this.reportingService.saveNoteAlert(task).subscribe(res => this.snack.open(this.translate.instant('COMMENT_SAVED'), 'OK', {duration: 2000}))
            });

        });
    }

    sendSMS(row) {
        this.dialog.open(SmsSenderComponent, {data: row, minWidth: '360px'}).afterClosed().subscribe(ok => {
            if (ok) this.snack.open('SMS envoyé à ' + row.patientName, '', {duration: 3000});
        });
    }

    private subscribeToWsTopic(): Subscription {
        return this.wsService.observeTopic('workflow')
            .pipe(delay(1000))
            .subscribe({
                next: res => {
                    if (res.topic === 'workflow') {
                        console.log('update workflow')
                        this.workflowFilterSubject.next(this.wf)
                    }
                },
                error: err => console.log(err),
                complete: () => {
                    console.log('complete')
                }
            });
    }

    printAttestation(row: any) {
        let matSnackBarRef = this.snack.open(this.translate.instant('PRINTING_IN_PROGRESS'), '', {duration: 10000});
        this.shared.printAttestation(row.id).subscribe(_ => matSnackBarRef.dismiss())
    }
}
