<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
    <h3 mat-dialog-title>{{itemType | uppercase | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <form [formGroup]="staticForm" class="width-300">


        <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'VALUE' | translate}}</mat-label>
            <input matInput placeholder="{{'VALUE' | translate}}" formControlName="value">
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
            <textarea matInput placeholder="{{'DESCRIPTION' | translate}}" formControlName="description"></textarea>
        </mat-form-field>


        <mat-form-field appearance="outline" *ngIf="itemType==='modality'" [formGroup]="aetForm">
            <mat-label>{{'DEFAULTAETITLE' | translate}}</mat-label>
            <mat-select placeholder="{{'DEFAULTAETITLE' | translate}}" formControlName="title" class="full-width">
                <mat-option *ngFor="let item of aets" [value]="item.title" class="option">
                    {{item.title}}
                </mat-option>
            </mat-select>

        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                {{'COLOR' | translate}}:
                <span class=" color-area" [(colorPicker)]="color" [style.background]="color">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </div>
        </div>
    </form>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="warn" [mat-dialog-close]="null">{{'CANCEL' | translate}}</button>
    <button mat-button color="primary" (click)="onSave(staticForm.value)" [disabled]="staticForm.invalid">{{'SAVE' |
        translate}}
    </button>
</mat-dialog-actions>
