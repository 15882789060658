<div class="popup-title">{{'NEW_CHAT' | translate}}</div>

<div *ngIf="message" class="popup-container" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4px">

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
        <div class="contact-initials" fxFlex="42px">
            {{message.sender.name | initials}}
        </div>

        <div fxFlex fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="4px">
            <div class="contact">{{message.sender?.name}}</div>

            <ng-container [ngSwitch]="message.messageType">
                <div *ngSwitchCase="'TEXT'" class="message">{{message.text}}</div>
                <div *ngSwitchDefault class="message">
                    <mat-icon fontIcon="mdi-image" fontSet="mdi"></mat-icon>
                    {{message.messageType | translate}}</div>
            </ng-container>

        </div>
    </div>

    <div fxFlex="72px" fxLayout="column" fxLayoutAlign="center start" style="border-left: 1px solid #4e4e4e">
        <button (click)="open()" mat-button>{{'SHOW' | translate}}</button>
        <button (click)="dismiss()" color="accent" mat-button>{{'CLOSE' | translate}}</button>
    </div>
</div>
