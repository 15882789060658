<ng-template #spinner>
    <div class="spinner">
        <mat-spinner [strokeWidth]="3"></mat-spinner>
    </div>
</ng-template>


<div *ngIf="patient; else spinner" fxLayout="column" fxFill id="patient">

    <mat-tab-group color="accent" fxFlex="calc(100vh - 150px)">
        <mat-tab>
            <ng-template matTabLabel>
                <mat-icon class="patient-detail-icon" fontSet="mdi" fontIcon="mdi-card-account-details"></mat-icon>&nbsp;
                <span fxHide.sm fxHide.xs>{{'PATIENT_FILE' | translate}}</span>
            </ng-template>
            <div class="pad12">
                <form [formGroup]="form">
                    <div fxLayout.gt-md="row" fxLayout="column" fxLayoutGap="4px">

                        <div class="side-area" fxLayout="column" fxFlexAlign.xs="center" fxFlexAlign.sm="center"
                             fxFlexAlign.md="center">
                            <img class="profile-img" [src]="patientPhotoUrl" alt="profile">
                            <button mat-button (click)="$event.stopPropagation();editImage()" style="border-radius: 0 0 6px 6px !important;" [tabIndex]="'-1'">
                                <mat-icon fontIcon="mdi-camera" fontSet="mdi"></mat-icon>
                                {{'EDIT_IMAGE' | translate}}
                            </button>
                            <div class="space-top" fxLayout="row" style="font-size: 12px">
                                <mat-slide-toggle fxFlex formControlName="confidential" [color]="'warn'">
                                    {{'CONFIDENTIAL_DATA' | translate}}
                                </mat-slide-toggle>
                            </div>
                            <div class="alerts" *ngIf="medicalForm.get('medicalAlerts').valueChanges | async">
                                <mat-icon fontSet="mdi" fontIcon="mdi-alert"></mat-icon>
                                {{medicalForm.get('medicalAlerts').value}}
                            </div>
                        </div>

                        <div fxFlex="grow" class="pat-card">

                            <div fxLayout="row wrap" fxLayoutGap="4px">

                                <mat-form-field appearance="standard">
                                    <mat-label>{{'PATIENTID' | translate}}</mat-label>
                                    <input matInput placeholder="{{'PATIENTID' | translate}}"
                                           formControlName="externalPatientID">
                                </mat-form-field>

                                <mat-form-field appearance="standard" formGroupName="title">
                                    <mat-label>{{'TITLE' | translate}}</mat-label>
                                    <mat-select placeholder="{{'TITLE' | translate}}" formControlName="id">
                                        <mat-option *ngFor="let title of titles" [value]="title.id">
                                            {{title.value}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="standard" fxFlex>
                                    <mat-label>{{'LASTNAME' | translate}}</mat-label>
                                    <input matInput placeholder="{{'LASTNAME' | translate}}"
                                           formControlName="lastName" required>
                                </mat-form-field>

                                <mat-form-field appearance="standard" fxFlex>
                                    <mat-label>{{'FIRSTNAME' | translate}}</mat-label>
                                    <input matInput placeholder="{{'FIRSTNAME' | translate}}"
                                           formControlName="firstName" required>
                                </mat-form-field>

                            </div>

                            <div [formGroup]="demographicForm" fxLayout="row wrap" fxLayoutAlign="start end"
                                 fxLayoutGap="4px">
                                <mat-form-field appearance="standard" [formGroup]="form">
                                    <mat-label>{{'CIN' | translate}}</mat-label>
                                    <input matInput placeholder="{{'CIN' | translate}}"
                                           formControlName="cin">
                                </mat-form-field>
                                <mat-form-field [formGroup]="form" appearance="standard">
                                    <mat-label>{{'NATIONAL_NUMBER' | translate}}</mat-label>
                                    <input formControlName="nationalNumber" matInput
                                           placeholder="{{'NATIONAL_NUMBER' | translate}}">
                                </mat-form-field>
                                <mat-form-field appearance="standard" fxFlex="180px">
                                    <mat-label>{{'DATEOFBIRTH' | translate}}</mat-label>
                                    <input matInput formControlName="dateOfBirth" [matDatepicker]="picker"
                                           (change)="changeAge()"
                                           placeholder="{{'DATEOFBIRTH' | translate}}">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker (closed)="changeAge()" #picker></mat-datepicker>
                                </mat-form-field>

                                <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="4px" [formGroup]="ageForm">
                                    <mat-form-field appearance="standard" fxFlex="80px">
                                        <mat-label>{{'AGE_YEARS' | translate}}</mat-label>
                                        <input type="number" matInput placeholder="{{'AGE_YEARS' | translate}}" formControlName="years" (keyup)="changeDate()">
                                    </mat-form-field>

                                    <mat-form-field appearance="standard" fxFlex="80px">
                                        <mat-label>{{'AGE_MONTHS' | translate}}</mat-label>
                                        <input type="number" matInput placeholder="{{'AGE_MONTHS' | translate}}" formControlName="months" (keyup)="changeDate()">
                                    </mat-form-field>

                                    <mat-form-field appearance="standard" fxFlex="80px">
                                        <mat-label>{{'AGE_DAYS' | translate}}</mat-label>
                                        <input type="number" matInput placeholder="{{'AGE_DAYS' | translate}}" formControlName="days" (keyup)="changeDate()">
                                    </mat-form-field>
                                </div>

                                <div fxLayout="column" fxLayoutAlign="space-between" formGroupName="gender">
                                    <div class="label-option">{{'GENDER' | translate}}</div>

                                    <mat-radio-group fxLayout="row" fxLayoutAlign="start end"
                                                     formControlName="id">
                                        <mat-radio-button fxFlex *ngFor="let option of genders" [value]="option.id">
                                            {{option.value == 'U' ? ('UNKNOWN' | translate): option.value}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>

                            <div fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="8px"
                                 [formGroup]="demographicForm">

                                <mat-form-field appearance="standard" formGroupName="maritalStatus">
                                    <mat-label>{{'MARITALSTATUS' | translate}}</mat-label>
                                    <mat-select placeholder="{{'MARITALSTATUS' | translate}}" formControlName="id">
                                        <mat-option *ngFor="let ms of maritalStatuses" [value]="ms.id">
                                            {{ms.description}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div [formGroup]="form">
                                    <mat-form-field appearance="standard" formGroupName="patientClass">
                                        <mat-label>{{'PATIENT_CLASS' | translate}}</mat-label>
                                        <mat-select [placeholder]="'PATIENT_CLASS' | translate" formControlName="id">
                                            <mat-option *ngFor="let item of patientClasses" [value]="item.id">
                                                {{item.description}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <mat-form-field appearance="standard" fxFlex="200px">
                                    <mat-label>{{'REFERRING_PHYSICIAN' | translate}}</mat-label>
                                    <input [formControl]="referringPhysicianControl" [matAutocomplete]="refPhyList1"
                                           [placeholder]="'REFERRING_PHYSICIAN' | translate"
                                           matInput>
                                    <mat-autocomplete #refPhyList1="matAutocomplete"
                                                      (optionSelected)="onChangeRefPhy($event)"
                                                      [autoActiveFirstOption]="true">
                                        <ng-container>
                                            <mat-option *ngFor="let user of filteredReferringPhysicians"
                                                        [value]="user" class="option-line">
                                                <div fxLayout="column" fxLayoutAlign="center start" [style.lineHeight.px]="14" [style.fontSize.px]="12">
                                                    <span fxFlex class="option-line-1"> {{ user.fullName }} </span>
                                                    <span *ngIf="user.codeAnam" class="option-line-2"
                                                          fxFlex> Code: {{ user.codeAnam || '-'}} </span>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                    <button (click)="$event.stopImmediatePropagation(); addReferringPhysician()" mat-icon-button
                                            matSuffix>
                                        <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                                    </button>
                                    <button (click)="$event.stopImmediatePropagation(); findReferringPhysician()"
                                            mat-icon-button
                                            matSuffix>
                                        <mat-icon fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
                                    </button>
                                </mat-form-field>


                                <div style="width: 200px;" [formGroup]="demographicForm">
                                    <div fxLayout="column" fxLayoutAlign="space-between" formGroupName="confidentiality">
                                        <div class="label-option">{{'CONFIDENTIALITY' | translate}}</div>
                                        <mat-radio-group fxLayout="row" fxLayoutAlign="start end" formControlName="id">
                                            <mat-radio-button fxFlex *ngFor="let option of confidentialities" [value]="option.id">
                                                {{option.description}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>

                            </div>

                            <div fxLayout="column" [formGroup]="demographicForm">

                                <div style="width: 200px;">
                                    <div fxLayout="column" fxLayoutAlign="space-between" [formGroup]="form">
                                        <div class="label-option">{{'DEBITER' | translate}}</div>

                                        <mat-radio-group fxLayout="row" fxLayoutAlign="start end"
                                                         formControlName="debiter">
                                            <mat-radio-button fxFlex *ngFor="let option of debiters" [value]="option">
                                                {{option | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>

                            </div>

                            <mat-divider class="divider-padding"></mat-divider>

                            <div class="subtitle">{{'CONTACT' | translate}}</div>

                            <div [formGroup]="form" fxLayout="row" fxLayoutGap="4px">
                                <mat-form-field appearance="standard" fxFlex>
                                    <mat-label>{{'PHONE' | translate}}</mat-label>
                                    <input matInput placeholder="{{'PHONE' | translate}}"
                                           formControlName="phone" type="tel">
                                </mat-form-field>
                                <mat-form-field appearance="standard" fxFlex>
                                    <mat-label>{{'EMAIL' | translate}}</mat-label>
                                    <input matInput placeholder="{{'EMAIL' | translate}}" formControlName="email">
                                </mat-form-field>
                            </div>

                            <div fxLayout="column" [formGroup]="addressForm">

                                <div fxLayout="row" fxLayoutGap="4px">
                                    <mat-form-field appearance="standard" fxFlex>
                                        <mat-label>{{'STREET' | translate}}</mat-label>
                                        <input matInput placeholder="{{'STREET' | translate}}" formControlName="street">
                                    </mat-form-field>

                                    <mat-form-field appearance="standard" fxFlex="200px">
                                        <mat-label>{{'POSTAL_CODE' | translate}}</mat-label>
                                        <input [formControl]="postalCodeControl"
                                               [matAutocomplete]="postalCodeAuto"
                                               [placeholder]="'POSTAL_CODE' | translate"
                                               matInput>
                                        <mat-autocomplete #postalCodeAuto="matAutocomplete"
                                                          (optionSelected)="onChangePostalCode($event)"
                                                          [autoActiveFirstOption]="true">
                                            <ng-container>
                                                <mat-option *ngFor="let city of filteredPostalCodes"
                                                            [value]="city" class="option-line">
                                                    {{ city.code }} - {{city.location}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="4px">

                                    <mat-form-field appearance="standard" fxFlex>
                                        <mat-label>{{'COUNTRY' | translate}}</mat-label>
                                        <input matInput placeholder="{{'COUNTRY' | translate}}"
                                               [matAutocomplete]="coAuto" formControlName="country">
                                        <mat-autocomplete #coAuto="matAutocomplete"
                                                          (optionSelected)="onSelectCountry($event)">
                                            <mat-option *ngFor="let country of filteredCountries | async"
                                                        [value]="country.value">
                                                {{ country.value }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <mat-form-field appearance="standard" fxFlex>
                                        <mat-label>{{'PROVINCE' | translate}}</mat-label>
                                        <input matInput placeholder="{{'PROVINCE' | translate}}"
                                               [matAutocomplete]="provAuto" formControlName="province">
                                        <mat-autocomplete #provAuto="matAutocomplete"
                                                          (optionSelected)="onSelectProvince($event)">
                                            <mat-option *ngFor="let province of filteredProvinces | async"
                                                        [value]="province.value">
                                                <span>{{ province.value }}</span>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <mat-form-field appearance="standard" fxFlex="200px">
                                        <mat-label>{{'CITY' | translate}}</mat-label>
                                        <input [formControl]="cityControl"
                                               [matAutocomplete]="cityAuto1"
                                               [placeholder]="'CITY' | translate"
                                               matInput>
                                        <mat-autocomplete #cityAuto1="matAutocomplete"
                                                          (optionSelected)="onChangePostalCode($event)"
                                                          [autoActiveFirstOption]="true">
                                            <ng-container>
                                                <mat-option *ngFor="let city of filteredPostalCodes"
                                                            [value]="city" class="option-line">
                                                    {{ city.location }}
                                                </mat-option>
                                            </ng-container>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                </div>
                            </div>


                            <div *ngIf="form.get('debiter').value !== 'PATIENT'">
                                <mat-divider class="divider-padding"></mat-divider>
                                <div class="subtitle">{{'INSURANCE' | translate}}</div>
                                <div formGroupName="insurance">
                                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="4px">

                                        <mat-form-field appearance="standard" fxFlex="360px">
                                            <input [matAutocomplete]="organismAuto"
                                                   [placeholder]="'INS_NAME' | translate"
                                                   aria-label="Insurance organism"
                                                   formControlName="organismName"
                                                   matInput
                                                   type="text">
                                            <mat-autocomplete #organismAuto="matAutocomplete"
                                                              (optionSelected)="onSelectOrganism()"
                                                              autoActiveFirstOption>
                                                <mat-option *ngFor="let organism of filteredOrganisms | async"
                                                            [value]="organism.name">
                                                    {{organism.code}} - {{organism.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>

                                        <mat-form-field appearance="standard">
                                            <mat-label>{{'INS_CODE' | translate}}</mat-label>
                                            <input matInput [placeholder]="'INS_CODE' | translate"
                                                   formControlName="code">
                                        </mat-form-field>


                                        <mat-form-field appearance="standard">
                                            <mat-label>{{'CONVENTION' | translate}}</mat-label>
                                            <mat-select [placeholder]="'CONVENTION' | translate"
                                                        [compareWith]="compareConvention" formControlName="conventionName">
                                                <mat-option *ngFor="let convention of conventions" [value]="convention">
                                                    {{convention}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>


                                        <mat-form-field appearance="standard">
                                            <mat-label>{{'COVER_NUMBER' | translate}}</mat-label>
                                            <input [placeholder]="'COVER_NUMBER' | translate" formControlName="coverNumber" matInput>
                                        </mat-form-field>


                                        <div style="width: 200px;">
                                            <div fxLayout="column" fxLayoutAlign="space-between">
                                                <div class="label-option">{{'RELATION_WITH_INSURED' | translate}}</div>

                                                <mat-radio-group fxLayout="row" fxLayoutAlign="start end"
                                                                 formControlName="relationWithInsured">
                                                    <mat-radio-button fxFlex
                                                                      *ngFor="let option of ['HIMSELF', 'PARTNER', 'CHILD']"
                                                                      [value]="option">
                                                        {{option | translate}}
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                        </div>

                                    </div>

                                    <div *ngIf="form.get('insurance.relationWithInsured').value !== 'HIMSELF'"
                                         fxLayout="column">

                                        <div fxLayout="row wrap" fxLayoutGap="4px">
                                            <mat-form-field appearance="standard">
                                                <mat-label>{{'TITLE' | translate}}</mat-label>
                                                <mat-select placeholder="{{'TITLE' | translate}}"
                                                            formControlName="insuredTitle">
                                                    <mat-option *ngFor="let title of titles" [value]="title.value">
                                                        {{title.value}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field appearance="standard">
                                                <mat-label>{{'LAST_NAME' | translate}}</mat-label>
                                                <input matInput [placeholder]="'LAST_NAME' | translate"
                                                       formControlName="insuredLastName">
                                            </mat-form-field>

                                            <mat-form-field appearance="standard">
                                                <mat-label>{{'FIRST_NAME' | translate}}</mat-label>
                                                <input matInput [placeholder]="'FIRST_NAME' | translate"
                                                       formControlName="insuredFirstName">
                                            </mat-form-field>

                                        </div>

                                        <mat-form-field appearance="standard">
                                            <mat-label>{{'ADDRESS' | translate}}</mat-label>
                                            <textarea matInput [placeholder]="'ADDRESS' | translate"
                                                      formControlName="address"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template matTabLabel>
                <mat-icon class="medical-history-icon" fontIcon="mdi-dna" fontSet="mdi"></mat-icon>
                <span fxHide.sm fxHide.xs>{{'MEDICAL_FILE' | translate}}</span>
            </ng-template>
            <div class="pad12">
                <div class="pat-card" fxLayout="column" [formGroup]="medicalForm">
                    <mat-form-field appearance="standard" fxFlexFill>
                        <mat-label>{{'SIDE_EFFECTS' | translate}}</mat-label>
                        <mat-select [placeholder]="'SIDE_EFFECTS' | translate" formControlName="allergies"
                                    multiple>
                            <mat-option *ngFor="let effect of sideEffectsList" [value]="effect.value">
                                {{effect.value}}
                            </mat-option>
                        </mat-select>
                        <button mat-icon-button matSuffix (click)="addAllergy($event)">
                            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlexFill>
                        <mat-label>{{'ADDITIONAL_PATIENT_HISTORY' | translate}}</mat-label>
                        <textarea matInput placeholder="{{'ADDITIONAL_PATIENT_HISTORY' | translate}}"
                                  formControlName="additionalPatientHistory"></textarea>
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex>
                        <mat-label>{{'SPECIALNEEDS' | translate}}</mat-label>
                        <input matInput placeholder="{{'SPECIALNEEDS' | translate}}"
                               formControlName="specialNeeds">
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex>
                        <mat-label>{{'MEDICALALERTS' | translate}}</mat-label>
                        <input matInput placeholder="{{'MEDICALALERTS' | translate}}"
                               formControlName="medicalAlerts">
                    </mat-form-field>

                    <mat-form-field appearance="standard">
                        <mat-label>{{'OBSERVATIONS' | translate}}</mat-label>
                        <textarea matInput [placeholder]="'OBSERVATIONS' | translate"
                                  formControlName="observations"></textarea>
                    </mat-form-field>

                    <mat-divider class="divider-padding"></mat-divider>

                    <div class="subtitle">{{'BIOMETRY' | translate}}</div>

                    <div fxLayout="row wrap" fxLayoutAlign="space-between none" fxLayoutGap="4px" [formGroup]="form">
                        <mat-form-field appearance="standard" fxFlex>
                            <mat-label>{{'HEIGHT' | translate}}</mat-label>
                            <input matInput placeholder="{{'HEIGHT' | translate}}"
                                   formControlName="height" (keyup)="calculateIMCAndSC()">
                        </mat-form-field>
                        <mat-form-field appearance="standard" fxFlex>
                            <mat-label>{{'WEIGHT' | translate}}</mat-label>
                            <input matInput placeholder="{{'WEIGHT' | translate}}"
                                   formControlName="weight" (keyup)="calculateIMCAndSC()">
                        </mat-form-field>
                        <mat-form-field appearance="standard" fxFlex>
                            <mat-label>{{'IMC' | translate}}</mat-label>
                            <input matInput placeholder="{{'IMC' | translate}}"
                                   formControlName="imc">
                        </mat-form-field>
                        <mat-form-field appearance="standard" fxFlex>
                            <mat-label>{{'SC' | translate}}</mat-label>
                            <input matInput placeholder="{{'SC' | translate}}"
                                   formControlName="sc">
                        </mat-form-field>

                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template matTabLabel>
                <mat-icon class="file-manager-icon" fontSet="mdi" fontIcon="mdi-folder"></mat-icon>
                <span fxHide.sm fxHide.xs>{{'FILES_ATTACHED' | translate}}</span>
            </ng-template>
            <div>
                <app-file-explorer [fileElements]="fileElements | async"
                                   [path]="currentPath"
                                   [patientID]="patientID"
                                   [modalMode]="false"
                                   [canNavigateUp]="canNavigateUp"
                                   (folderAdded)="addFolder($event)"
                                   (elementRemoved)="removeElement($event)"
                                   (navigatedDown)="navigateToFolder($event)"
                                   (navigatedUp)="navigateUp()"
                                   (onUpload)="afterUpload($event)"
                                   (elementRenamed)="renameElement($event)"
                                   (elementMoved)="moveElement($event)">
                </app-file-explorer>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template matTabLabel>
                <mat-icon  class="rdv-icon" fontSet="mdi" fontIcon="mdi-calendar-question"></mat-icon>
                <span fxHide.sm fxHide.xs>{{'APPOINTMENTS' | translate}}</span>
            </ng-template>

            <div class="pad12">

                <div class="ft-table">
                    <div class="table-header">
                        <div>{{'APPOINTMENTS' | translate}}</div>
                        <div *ngIf="profile && profile.schedulerModule && profile.createOrder !== 'NONE'"
                             style="text-align: right">
                            <button (click)="createNewAppointment()" color="accent" mat-button style="margin-top: -8px">
                                <mat-icon style="color: inherit;" fontSet="mdi" fontIcon="mdi-plus-circle"></mat-icon>
                                {{'ADD_APPOINTMENT' | translate}}
                            </button>
                        </div>
                    </div>
                    <div class="ft-row ft-order-row ft-header">
                        <div class="ft-cell">{{'REQUESTEDDATE' | translate}}</div>
                        <div class="ft-cell">{{'ORDERING_PROVIDER' | translate}}</div>
                        <div class="ft-cell">{{'REASON_FOR_EXAM' | translate}}</div>
                        <div class="ft-cell"></div>
                    </div>
                    <div *ngFor="let apt of appointments" class="ft-row ft-order-row">
                        <div class="ft-cell">{{apt.startDate | date: 'dd/MM/yyyy'}}</div>
                        <div class="ft-cell">{{apt.referringPhysician?.fullName || '-'}}</div>
                        <div class="ft-cell">{{ apt.appointmentReason || '-' }}</div>
                        <div class="ft-cell">
                            <button mat-icon-button [matTooltip]="'SCHEDULEMENU' | translate"
                                    (click)="onScheduleAppointment(apt)">
                                <mat-icon class="mat-18" fontSet="mdi" fontIcon="mdi-calendar"></mat-icon>
                            </button>
                            <button mat-icon-button [matTooltip]="'DELETEMENU' | translate"
                                    (click)="onDeleteAppointments(apt)">
                                <mat-icon class="mat-18" fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template matTabLabel>
                <mat-icon  class="scheduled-exam-icon" fontSet="mdi" fontIcon="mdi-calendar-check"></mat-icon>
                <span fxHide.sm fxHide.xs>{{'SCHEDULEDPROCEDURES' | translate}}</span>
            </ng-template>
            <div class="pad12">
                <div class="ft-table">
                    <div class="table-header">
                        <div>{{'SCHEDULEDPROCEDURES' | translate}}</div>
                        <div *ngIf="profile && profile.schedulerModule && profile.scheduleExam !== 'NONE'"
                             style="text-align: right">
                            <button style="margin-top: -8px" mat-button color="accent" (click)="scheduleNewOrder()">
                                <mat-icon style="color: inherit;" fontSet="mdi" fontIcon="mdi-calendar-plus"></mat-icon>
                                {{'SCHEDULE_ORDER' | translate}}
                            </button>
                        </div>
                    </div>
                    <div class="ft-row ft-sps-row ft-header">
                        <div class="ft-cell">{{'SCHEDULED_DATE' | translate}}</div>
                        <div class="ft-cell">{{'PERFORMING_RADIOLOGIST' | translate}}</div>
                        <div class="ft-cell">{{'SCHEDULEDAET' | translate}}</div>
                        <div class="ft-cell">{{'EXAM' | translate}}</div>
                        <div class="ft-cell"></div>
                    </div>
                    <div class="ft-row ft-sps-row" *ngFor="let sps of scheduledProcedures">
                        <div class="ft-cell">{{ formatDate(sps.scheduledProcedureStepStartDate, true)?.substr(0, 10)}}
                            {{sps.scheduledProcedureStepStartTime}}
                        </div>
                        <div class="ft-cell">{{ sps.scheduledPerformingPhysiciansName?.fullName }}</div>
                        <div class="ft-cell">{{ sps.scheduledStationAETitle?.name}}</div>
                        <div class="ft-cell">{{ sps.procedureCode?.code || '-' }}</div>
                        <div class="ft-cell">
                            <button *ngIf="isEditable(sps)" mat-icon-button [matTooltip]="'EDIT' | translate"
                                    (click)="editSPS(sps)">
                                <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
                            </button>
                            <button *ngIf="isSameDate(sps)" mat-icon-button
                                    [matTooltip]="'MAKE_PATIENT_ARRIVED' | translate" (click)="enterPatient(sps)">
                                <mat-icon fontSet="mdi" fontIcon="mdi-arrow-down"></mat-icon>
                            </button>
                            <button *ngIf="isFuture(sps)" mat-icon-button [matTooltip]="'PRINT_RDV' | translate"
                                    (click)="printRDV(sps)">
                                <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
                            </button>
                            <button *ngIf="isEditable(sps)" mat-icon-button [matTooltip]="'DELETE' | translate"
                                    (click)="deleteSPS(sps)">
                                <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template matTabLabel>
                <mat-icon class="exam-history-icon" fontSet="mdi" fontIcon="mdi-clipboard-pulse"></mat-icon>
                <span fxHide.sm fxHide.xs>{{'PATIENT_WORKFLOW' | translate}}</span>
            </ng-template>
            <div class="pad12">

                <div class="ft-table">
                    <div class="table-header">
                        {{'PATIENT_EXAM_HISTORY' | translate}}
                    </div>

                    <div class="ft-row ft-header">
                        <div class="ft-cell">{{'ACCESSION_NUMBER' | translate}}</div>
                        <div class="ft-cell">{{'AET' | translate}}</div>
                        <div class="ft-cell">{{'PERFORMING_RADIOLOGIST' | translate}}</div>
                        <div class="ft-cell">{{'U.'}}</div>
                        <div class="ft-cell">{{'PATIENT_ARRIVAL' | translate}}</div>
                        <div class="ft-cell">{{'EXAM_STARTED' | translate}}</div>
                        <div class="ft-cell">{{'EXAM_FINISHED' | translate}}</div>
                        <div class="ft-cell">{{'EXAM_STATUS' | translate}}</div>
                        <div class="ft-cell">{{'REPORT_STATUS' | translate}}</div>
                        <div class="ft-cell">{{'NUM_OF_FILMS' | translate}}</div>
                        <div class="ft-cell">{{'CONTRAST_PRODUCT' | translate}}</div>
                        <div class="ft-cell"></div>
                    </div>
                    <div class="ft-row" *ngFor="let exam of patientExams">
                        <div class="ft-cell">{{exam.accessionNumber}}</div>
                        <div class="ft-cell">{{exam.aet}}</div>
                        <div class="ft-cell">{{exam.performingPhysician}}</div>
                        <div class="ft-cell">
                            <mat-icon style="color: #b10011" fontSet="mdi"
                                      fontIcon="{{exam.urgent === true ? 'mdi-ambulance': ''}}"></mat-icon>
                        </div>
                        <div class="ft-cell">{{formatDate(exam.patientArrival, true)}}</div>
                        <div class="ft-cell">{{exam.examinationStarted | date: 'HH:mm'}}</div>
                        <div class="ft-cell">{{exam.examinationFinished | date: 'HH:mm'}}</div>
                        <div class="ft-cell">{{exam.spsStatus | translate}}</div>
                        <div class="ft-cell" [ngStyle]="{'color': getColor(exam.reportStatus), 'font-weight': 'bold'}">
                            {{getReportStatus(exam.reportStatus) | translate}}
                        </div>
                        <div class="ft-cell">{{exam.numberOfFilms}}</div>
                        <div class="ft-cell">{{exam.contrast}}</div>
                        <div class="ft-cell">

                            <button mat-icon-button
                                    *ngIf="exam.reportStatus !== 'None' && profile && profile.viewPatientReport"
                                    [matTooltip]="'EDIT_REPORT' | translate"
                                    (click)="openReportingTask(exam)">
                                <mat-icon fontIcon="mdi-file-document" fontSet="mdi"></mat-icon>
                            </button>

                            <button mat-icon-button *ngIf="canPrint(exam)"
                                    [matTooltip]="'DOWNLOAD_REPORT' | translate"
                                    (click)="downloadReport(exam)">
                                <mat-icon fontIcon="mdi-cloud-download" fontSet="mdi"></mat-icon>
                            </button>

                            <button mat-icon-button
                                    *ngIf="profile && profile.viewPatientStudies !== 'NONE' && exam.imagesAvailables && exam.studyInstanceUID !== ''"
                                    [matTooltip]="'OPEN_IN_VIEWER' | translate"
                                    (click)="openInViewer(exam.studyInstanceUID)">
                                <mat-icon fontIcon="mdi-eye" fontSet="mdi"></mat-icon>
                            </button>

                            <a *ngIf="profile && profile.viewPatientStudies !== 'NONE' && exam.imagesAvailables && exam.studyInstanceUID !== ''"
                               mat-icon-button [matTooltip]="'OPEN_IN_OSIRIX' | translate" (click)="openInOsirix(exam)">
                                <mat-icon fontIcon="mdi-eye" fontSet="mdi"></mat-icon>
                            </a>

                        </div>
                    </div>

                </div>

            </div>
        </mat-tab>
    </mat-tab-group>

    <div [style.paddingRight.px]="24" fxFlex="42px" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px">
        <button class="close-button" (click)="goBack()" color="accent" mat-raised-button>{{'BACK_TO_LIST' | translate}}</button>
        <button class="save-and-close-button" (click)="savePatientAndBack(form.value)" color="primary" mat-raised-button
                [disabled]="form.invalid || demographicForm.invalid || medicalForm.invalid">{{'SAVE_AND_BACK' |
            translate}}
        </button>
        <button class="save-button" (click)="savePatient(form.value)" [tabIndex]="1" autofocus color="primary" mat-raised-button
                [disabled]="form.invalid || demographicForm.invalid || medicalForm.invalid">{{'SAVE' | translate}}
        </button>
    </div>
</div>
