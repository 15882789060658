import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ft-ft-menu',
  templateUrl: './ft-menu.component.html',
  styleUrls: ['./ft-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FtMenuComponent {

  @Output() onMenuItemSelect = new EventEmitter();

  private _listItem: MenuItem[];

  @Input()
  set listItem(listItem: MenuItem[]) {
    this._listItem = listItem;
  }

  get listItem(): MenuItem[] {
    return this._listItem;
  }
}

export interface MenuItem { icon: string, name: string, value: string }
