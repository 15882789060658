import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {SettingService} from '../../setting.service';

@Component({
    selector: 'app-room-edit',
    templateUrl: './room-edit.component.html',
    styleUrls: ['./room-edit.component.scss']
})
export class RoomEditComponent implements OnInit {

    selectedItem: any;
    form: FormGroup;

    constructor(private fb: FormBuilder,
                public dialogRef: MatDialogRef<RoomEditComponent>,
                private settingService: SettingService) {
        this.form = this.fb.group({
            id: '',
            name: '',
            examType: ''
        });
    }

    onSave(data) {
        this.settingService
            .saveRoom(data)
            .subscribe(res => this.dialogRef.close(res));
    }

    ngOnInit() {
        this.form.patchValue(this.selectedItem);
    }

}
