<div fxLayout="row nowrap" fxLayoutAlign="space-between" fxFill>
    <div fxFlex fxLayout="column">
        <div id="reporter" fxFlex></div>
        <button (click)="searchParagraphs()" class="paragraph-search" [color]="searchingParagraphs ?'warn': 'primary'" mat-mini-fab>
            <mat-icon style="font-size: 18px;" fontSet="mdi" [fontIcon]="searchingParagraphs ? 'mdi-close': 'mdi-text'"></mat-icon>
        </button>
    </div>
    <div id="p-sidenav">
        <ng-container *ngIf="searchingParagraphs">
            <ft-report-paragraph [procedureType]="procedureType" [procedureCode]="procedureCode"
                                 (onInsertParagraph)="insertParagraph($event)"></ft-report-paragraph>
        </ng-container>
    </div>
    <img *ngIf="approved" alt="Approved" id="signed" src="../../../assets/images/approved.png" tabindex="0">
</div>