<div style="padding: 16px; height: 100%" fxLayout="column">

    <div fxFlex="nogrow" class="subtitle">{{'REPORTINGCONFIG' | translate}}</div>

    <form #f="ngForm" *ngIf="reportingConfig" fxFlex="grow" fxLayout="column" fxLayoutAlign="space-between none">

        <div style="margin: 20px 0" fxFlex="nogrow">

            <mat-radio-group [(ngModel)]="taskListOption" [ngModelOptions]="{standalone: true}"
                             (change)="onSelectTaskList($event)">
                <mat-radio-button *ngFor="let task of tasks" [value]="task">
                    {{task | translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>


        <div *ngIf="taskListOption === 'MANYTASKLISTS'" style="margin-left: 16px" fxFlex="grow">

            <div fxLayout="row" fxLayoutAlign="space-between stretch" style="height: 42px;">
                <mat-checkbox fxFlex
                              [(ngModel)]="reportingConfig.interpretation"
                              name="interpretation"
                              color="primary">
                    {{'INTERPRETATION' | translate}}
                </mat-checkbox>


            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start" style="height: 42px;">
                <mat-checkbox fxFlex
                              [(ngModel)]="reportingConfig.transcription"
                              name="transcription"
                              color="primary">
                    {{'TRANSCRIPTION' | translate}}
                </mat-checkbox>

            </div>


            <div fxLayout="row" fxLayoutAlign="space-between stretch" style="height: 42px;">
                <mat-checkbox fxFlex
                              [(ngModel)]="reportingConfig.verification"
                              name="verification"
                              color="primary">
                    {{'VERIFICATION' | translate}}
                </mat-checkbox>

            </div>


            <div fxLayout="row" fxLayoutAlign="space-between stretch" style="height: 42px;">
                <mat-checkbox fxFlex
                              [(ngModel)]="reportingConfig.review"
                              name="review"
                              color="primary">
                    {{'REVIEW' | translate}}
                </mat-checkbox>

            </div>


            <div fxLayout="row" fxLayoutAlign="space-between stretch" style="height: 42px;">
                <mat-checkbox fxFlex
                              [(ngModel)]="reportingConfig.signing"
                              name="signing"
                              color="primary">
                    {{'SIGNING' | translate}}
                </mat-checkbox>

            </div>

        </div>


        <div fxFlex="nogrow" fxFlexAlign="end" fxLayout="row" fxLayoutAlign="end none" fxLayoutGap="4px"
             class="btn-actions">
            <button mat-button color="accent">{{'CANCEL' | translate}}</button>
            <button mat-button color="primary" (click)="saveReportingConfig(f.value)" [disabled]="f.invalid">{{'SAVE' |
                translate}}
            </button>
        </div>
    </form>


</div>
