import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SchedulerService} from '../../scheduler/scheduler.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {GeneralSetting, Payment, PaymentItem, PaymentStatus, Profile} from '../../model';
import {assign, keys} from 'lodash';
import {forkJoin, Subscription} from 'rxjs';
import {SharedService} from '../shared.service';
import {PathologyEditComponent} from '../../setting/pathology-setting/pathology-edit/pathology-edit.component';
import * as $ from 'jquery';
import * as moment from 'moment';
import {MatSelectChange} from '@angular/material/select';
import {SettingService} from "../../setting/setting.service";
import {AppConfigService} from "../../app-config.service";
import {ProcedureCodeSearchComponent} from "../../scheduler/procedure-code-search/procedure-code-search.component";
import set = Reflect.set;


@Component({
    selector: 'app-payment-order',
    templateUrl: './payment-order.component.html',
    styleUrls: ['./payment-order.component.scss']
})
export class PaymentOrderComponent implements OnInit, OnDestroy {

    form: FormGroup;
    details = false;
    currencyFormat = 'DH';
    banks: Array<any>;
    organisms: any[] = [];
    paymentMethods: Array<any>;

    organismPart = 0.0;
    dialogData = {type: 'external', icon: 'plus'};
    private basePrice: number;
    payment = new Payment();
    paymentItems: PaymentItem[];
    performedPayment: boolean;
    leftAmount: number;
    public profile: Profile;

    conventions: any = [];
    selectedConvention: { name: string, patientPartPercentage: number, organismPartPercentage: number };
    private subs: Subscription[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private service: SchedulerService,
                private _appConfig: AppConfigService,
                private shared: SharedService,
                private setting: SettingService,
                private dialog: MatDialog,
                private snackBar: MatSnackBar) {
        this.currencyFormat = _appConfig.currencyFormat;

        this.subs.push(this.setting.getGeneralSetting().subscribe(res => this.generalSetting = res));

        this.createForm();

        this.profile = JSON.parse(localStorage.getItem('profile'));

        this.subs.push(this.form.get('paymentDispense').valueChanges
            .subscribe(value => {
                for (let key in this.form.controls) if (!['paymentDispense', 'locked'].includes(key)) {
                    value ? this.form.controls[key].disable() : this.form.controls[key].enable()
                }
            }));

        this.subs.push(this.form.get('payer').valueChanges.subscribe(payer => {
            if (payer === 'THIRD_PAYER') this.shared.getOrganismsList().subscribe(res => this.organisms = res);
        }));

    }

    private generalSetting: GeneralSetting;

    private static adjustConvention(convention: any) {
        let cnv = convention.split('@');
        let patientPartPercentage = parseFloat(cnv[2]);
        let organismPartPercentage = parseFloat(cnv[3]);
        return {
            name: convention,
            organismPartPercentage: organismPartPercentage,
            patientPartPercentage: patientPartPercentage
        };
    }

    private static buildPayment(payment: Payment): Payment {
        return assign(payment, {
            paymentMethod: payment.paymentMethod && payment.paymentMethod.id !== '' ? payment.paymentMethod : null,
            bank: payment.bank && payment.bank.id !== '' ? payment.bank : null,
            organism: payment.organism && payment.organism.id !== '' ? payment.organism : null,
        })
    }

    private static getPayment(payment: Payment) {
        return assign(payment, {
            paymentMethod: payment.paymentMethod || {id: ''},
            bank: payment.bank || {id: ''},
            organism: payment.organism || {id: ''},
            due: this.formatNumber(payment.due),
            discount: this.formatNumber(payment.discount),
            baseAmount: this.formatNumber(payment.baseAmount),
            enteredAmount: this.formatNumber(payment.enteredAmount),
            totalAfterDiscount: this.formatNumber(payment.totalAfterDiscount)
        });
    }

    private static formatNumber(value: number): string {
        return value.toFixed(2);
    }

    updatePaymentForm(paymentItems: PaymentItem[], disable?: boolean) {
        this.basePrice = paymentItems.map(v => v.initialPrice !== 0.0 ? v.initialPrice : v.price).reduce((a, b) => a + b, 0);
        let totalDue = PaymentOrderComponent.formatNumber(paymentItems.map(v => (v.price * (v.quantity || 1) - v.discount)).reduce((a, b) => a + b, 0));
        let patientDue = PaymentOrderComponent.formatNumber(paymentItems.map(v => (v.price * (v.quantity || 1) - v.discount)*v.patientPart/100).reduce((a, b) => a + b, 0));
        this.form.patchValue({
            baseAmount: PaymentOrderComponent.formatNumber(this.basePrice),
            due: totalDue,
            enteredAmount: patientDue,
            patientName: this.data.patientName,
            patientID: this.data.patientID,
            paymentItems: paymentItems,
            patientPart: patientDue,
            totalAfterDiscount: totalDue,
            discount: 0.0
        });

        if (disable) setTimeout(() => {
            this.form.get('baseAmount').disable();
            this.form.get('due').disable();
            this.form.get('patientPart').disable();
            this.form.get('organismPart').disable();
            this.form.get('totalAfterDiscount').disable();
        })
    }

    ngOnInit() {

        this.subs.push(forkJoin(
            [this.shared.getBanks(),
                this.shared.getPaymentMethods()]
        ).subscribe(data => {
            [this.banks, this.paymentMethods] = data;
            this.form.get('paymentMethod').patchValue(data[1].find(v => v.code === 'CASH'))
        }));

        this.getPaymentDetails();
        this.calculateDue();
    }

    createForm = () => this.form = this.fb.group(assign(this.payment, {
        paymentMethod: this.fb.group({id: ''}),
        bank: this.fb.group({id: ''}),
        organism: this.fb.group({id: '', name: '', defaultRefundAmount: ''})
    }));

    orgAttrByIdx = (organism: string, idx: number): string => {
        return organism ? organism.split('@')[idx] : organism;
    };

    createPayment() {
        this.payment = this.form.getRawValue();

        let status = this.getPaymentStatus();

        assign(this.payment, {
            date: moment(this.payment.date).add(1, 'hour'),
            dueDate: moment(this.payment.dueDate).add(1, 'hour'),
            paymentItems: this.paymentItems.map(item => {
                item.status = status;
                item.paymentID = this.payment.paymentID;
                return item;
            }),
            paymentStatus: status
        });

        let payment = PaymentOrderComponent.buildPayment(this.payment);

        this.subs.push(this.service.createPayment(payment).subscribe(res => {
            if (res && res.id) {
                this.getPaymentDetails();
                this.snackBar.open('Paiement reçu avec succès!', '', {duration: 2000});
            }
        }));
    }

    addBank() {
        assign(this.dialogData, {title: 'BANK'});
        this.subs.push(this.dialog.open(PathologyEditComponent, {data: this.dialogData, disableClose: true})
            .afterClosed()
            .subscribe(data => {
                if (data) {
                    this.shared.createBank(data)
                        .subscribe(res => {
                            if (res && res.id) {
                                this.banks.push(res);
                                this.form.get('bank').patchValue(res);
                                this.snackBar.open('Nouvelle Banque enregistrée avec succès!', 'Ok', {duration: 2000})
                            }
                        })
                }
            }));
    }

    compareOrganism = (c1: any, c2: any): boolean => c1 && c2 ? c1.id == c2.id : c1 == c2;

    enterDiscount(e) {
        let discount = parseFloat(e.target.value);
        let due = this.form.get('due').value;
        let totalAfterDiscount = due - discount;
        let percentage = discount * 100 / due;

        let patientPart = (this.selectedConvention ? this.selectedConvention.patientPartPercentage : 100) * totalAfterDiscount / 100;
        let organismPart = (this.selectedConvention ? this.selectedConvention.organismPartPercentage : 0) * totalAfterDiscount / 100;
        let organismPartPercentage = organismPart * 100 / totalAfterDiscount;

        this.form.get('patientPart').patchValue(patientPart.toFixed(2));
        this.form.get('organismPart').patchValue(organismPart.toFixed(2));
        this.form.get('organismPartPercentage').patchValue(organismPartPercentage.toFixed(2));
        this.form.get('totalAfterDiscount').patchValue(totalAfterDiscount.toFixed(2));
        this.form.get('discountPercentage').patchValue(percentage.toFixed(2));
        this.form.get('enteredAmount').patchValue(patientPart.toFixed(2))
    }

    cancelPayment() {
        this.subs.push(this.service.cancelPayment(this.payment.paymentID).subscribe(res => {
            if (res) {
                this.performedPayment = false;
                this.payment = new Payment();
                this.getPaymentDetails();
                this.snackBar.open('Le paiement a été annulé !', 'OK', {duration: 2000});
            }
        }));
    }

    showPreview(url) {
        let $frame = $('<iframe />').attr('src', url).css({position: 'absolute', top: '-9999px'});
        $frame.on('load', () => setTimeout(() => $frame.remove(), 0));
        $(document.body).append($frame);
    }

    enterAmount(e) {
        let due = this.form.get('due').value;
        let amount = parseFloat(e.target.value);
        let discount = this.form.get('discount').value;
        let orgPart = this.form.get('organismPart').value;

        let amt = due - discount - orgPart;

        if (amount > amt) amount = amt;

        this.form.get('enteredAmount').patchValue(amount.toFixed(2));
    }

    private get printMode(): 'CUPS' | 'CHROME' {
        return this.generalSetting.receiptPrintMode;
    }

    onSelectOrganism(selection: MatSelectChange) {
        let org = selection.value;
        if (org) {
            this.payment.organismName = org;
            this.subs.push(this.shared.getOrganismConventions(this.orgAttrByIdx(org, 1)).subscribe(conventions => {
                this.conventions = conventions;
                this.adjustParts(conventions[0]);
            }));
        }
    }

    editProcedureCode(item: PaymentItem) {
        this.subs.push(this.dialog.open(ProcedureCodeSearchComponent, {minWidth: '600px'})
            .afterClosed()
            .subscribe(code => {
                if (code) {
                    set(item, 'procedureCode', code.code);
                    set(item, 'billingCode', code.billingCode.code);
                    set(item, 'price', code.billingCode.price);

                    set(item, 'patientPartPrice', (item.price * item.patientPart / 100).toFixed(2));
                    set(item, 'organismPartPrice', (item.price * item.organismPart / 100).toFixed(2));

                    this.updatePaymentForm(this.payment.paymentItems);
                }
            }));
    }

    onSelectConvention(selection: MatSelectChange) {
        this.adjustParts(selection.value);
    }

    ngOnDestroy() {
        this.subs?.forEach(sub => sub?.unsubscribe());
    }

    private adjustParts(convention: any) {
        if (!convention) return;

        this.selectedConvention = PaymentOrderComponent.adjustConvention(convention);

        let payment = this.form.getRawValue() as Payment;

        let patientPart = Number((this.selectedConvention.patientPartPercentage * payment.totalAfterDiscount / 100).toFixed(2));
        this.form.get('patientPart').patchValue(patientPart);
        this.form.get('enteredAmount').patchValue(patientPart);
        this.form.get('organismPart').patchValue(Number((this.selectedConvention.organismPartPercentage * payment.totalAfterDiscount / 100).toFixed(2)));
    }

    private getPaymentStatus(): string {
        let payment = this.form.getRawValue() as Payment;

        if (payment.totalAfterDiscount === 0 || payment.paymentDispense) return PaymentStatus.EXEMPT;
        else if (payment.enteredAmount === 0) return PaymentStatus.NOT_PAID;
        else if (payment.enteredAmount === payment.totalAfterDiscount) return PaymentStatus.PAID;
        else return PaymentStatus.PAID_PARTIALLY;
    }

    changeItemValue(property: string, item: PaymentItem, event: any) {
        let new_value = Number(event.target['innerText'] || 0);
        set(item, property, new_value);
        set(item, 'leftAmount', item['price'] * item['quantity'] - item['discount']);
        set(item, 'percentageDiscount', Number((item['discount'] * 100 / (item['price'] * item['quantity'])).toFixed(2)));

        set(item, 'patientPartPrice', (item.price * item.patientPart / 100).toFixed(2));
        set(item, 'organismPartPrice', (item.price * item.organismPart / 100).toFixed(2));

        setTimeout(() => this.updatePaymentForm(this.payment.paymentItems));
    }

    private getPaymentDetails() {
        this.subs.push(this.service.getPaymentDetails(this.data.spsId)
            .subscribe(payment => {
                if (payment) {
                    this.payment = payment;
                    this.paymentItems = payment.paymentItems.map(it =>
                        assign(it, {
                            patientPartPrice: (it.patientPart * it.price / 100).toFixed(2),
                            organismPartPrice: (it.organismPart * it.price / 100).toFixed(2),
                        })
                    );
                    this.basePrice = payment.due;

                    this.performedPayment = [
                        PaymentStatus.EXEMPT,
                        PaymentStatus.PAID,
                        PaymentStatus.PAID_PARTIALLY
                    ].includes(PaymentStatus[payment.paymentStatus]);

                    this.form.patchValue(PaymentOrderComponent.getPayment(payment));
                    // this.updatePaymentForm(this.paymentItems)
                } else {
                    this.service.getPaymentOrderDetails(this.data.spsId).subscribe(paymentItems => {

                        this.paymentItems = paymentItems.map(it =>
                            assign(it, {
                                patientPartPrice: (it.patientPart * it.price / 100).toFixed(2),
                                organismPartPrice: (it.organismPart * it.price / 100).toFixed(2),
                            })
                        );
                        this.payment = assign(this.payment, {paymentItems: paymentItems});

                        this.performedPayment = false;
                        this.updatePaymentForm(paymentItems, true);
                    });
                }

                setTimeout(() => {
                    let formFields = keys(new Payment());
                    if (this.performedPayment) formFields.forEach(key => this.form.get(key).disable({
                        onlySelf: true,
                        emitEvent: false
                    }));
                    else formFields.forEach(key => this.form.get(key).enable({onlySelf: true, emitEvent: false}));
                }, 0);
            }));
    }

    private calculateDue() {
        this.subs.push(this.form.valueChanges.subscribe((payment: Payment) => {
            let total = this.form.getRawValue().due || 0;
            let amount = payment.enteredAmount || 0;
            let discount = payment.discount || 0;

            let leftAmount = Number((total - amount - discount).toFixed(2));
            this.leftAmount = Number((leftAmount < 0 ? 0 : leftAmount).toFixed(2));
        }));
    }

    printPaymentReceipt() {
        let snackBarRef = this.snackBar.open('Impression en cours...', '', {duration: 10000});
        if (this.printMode == 'CHROME') this.subs.push(this.service.printPaymentReceipt(this.payment).subscribe(_ => snackBarRef.dismiss()));
        else {
            this.subs.push(this.service.printCupsPaymentReceipt(this.payment).subscribe(ok => {
                if (ok['status'] !== 'ok') console.log('Cannot print the receipt');
                snackBarRef.dismiss();
            }));
        }
    }
}
