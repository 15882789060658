import {Component, OnDestroy, OnInit} from '@angular/core';
import {SharedService} from '../shared.service';
import {StringUtils} from '../../utils';
import {WsService} from '../../ws.service';
import {drop, map as _map, mapValues, reduce, take} from 'lodash';
import {Subscription} from 'rxjs';
import * as moment from 'moment';


interface QueueItem {
    patientName: string;
    patientSex: string;
    resourceName: string;
    ticketNumber: string;
    appointmentTime: string;
    expectedDuration: number;
    photo: any;
    performingPhysician: string;
}

@Component({
    selector: 'ft-queue',
    templateUrl: './queue.component.html',
    styleUrls: ['./queue.component.scss']
})
export class QueueComponent implements OnInit, OnDestroy {
    queueItems: QueueItem[] = [];
    resourceQueueItems: { resourceName: string, queueItems: QueueItem[] }[] = [];
    resources: string[] = [];
    private sub$: Subscription;

    constructor(private shared: SharedService, private _ws: WsService) {
        this._ws.observeTopic('ft-queue').subscribe(res => {
            if (res.response == 'update') {
                this.unsubscribe();
                this.getQueueItems();
            }
        });
    }

    ngOnInit(): void {
        this.getQueueItems();
    }

    dropFirst = (array: any): any => drop(array);

    ngOnDestroy() {
        this.unsubscribe();
    }

    private getQueueItems():void{
        this.sub$ = this.shared.getQueueItems().subscribe(data => {
            let resourceQueueItems = StringUtils.groupBy(data, `resourceName`);
            this.resources = Object.keys(resourceQueueItems);

            let builtData = mapValues(resourceQueueItems, (data) => this.buildData(data));

            this.resourceQueueItems = _map(this.resources, (res) => {
                return {resourceName: res, queueItems: builtData[res]}
            });
        });
    }

    private unsubscribe() {
        if (this.sub$) this.sub$.unsubscribe();
    }

    private buildData(data: any): QueueItem[] {
        return data.map(it => {
            let first = data && data.length != 0 ? data[0]: null;

            let idx = data.findIndex(v => v.ticketNumber == it.ticketNumber);
            let previous = data[idx - 1];

            let previousData = take(data, idx);

            if (previousData.length != 0) {
                let durationShift = reduce(_map(previousData, 'expectedDuration'), (sum, a) => sum + a, 0);

                if (first && moment(first.appointmentTime, 'HH:mm').add(durationShift, 'minutes').isAfter(moment(), 'm')) {
                    it.appointmentTime = moment(first.appointmentTime, 'HH:mm').add(durationShift, 'minutes').format('HH:mm');
                } else if (moment(it.appointmentTime, 'HH:mm').subtract(it.expectedDuration, 'minutes').isBefore(moment(previous.appointmentTime, 'HH:mm'), 'm')) {
                    it.appointmentTime = moment(it.appointmentTime, 'HH:mm').add(previous.expectedDuration, 'minutes').format('HH:mm');
                }

            }
            return it;
        });
    }
}
