/**
 * Created by MBX on 27/04/2019.
 */

export class Viewer {
    constructor(public id?: number,
                public name?: string,
                public host?: string,
                public remotePath?: string,
                public defaultViewer?: boolean,
                public osirix?: boolean) {
    }
}
