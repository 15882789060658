<div class="formatting-card">
    <div [style.padding.px]="12">

        <div class="section-label">{{'APPLY_TO' | translate}}</div>

        <mat-radio-group [(ngModel)]="rule.appliedTo" aria-label="format option">
            <mat-radio-button value="COLUMN">{{'COLUMN' | translate}}</mat-radio-button>
            <mat-radio-button value="ROW">{{'ROW' | translate}}</mat-radio-button>
        </mat-radio-group>


        <div [style.marginTop.px]="12" class="section-label">{{'FORMAT_RULES' | translate}}</div>

        <div class="sub-section-label"
             style="margin-bottom: 0 !important;">{{'FORMATTING_BASED_COLUMN' | translate}}</div>
        <mat-form-field [style.width.%]="100" appearance="outline" floatLabel="never">
            <mat-select (valueChange)="filterRules($event)"
                        [(ngModel)]="rule.targetColumn"
                        [placeholder]="'FORMATTING_BASED_COLUMN' | translate">
                <mat-option *ngFor="let column of filteredColumns(rule.appliedTo)"
                            [value]="column.header">{{column.header | translate}}</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="sub-section-label" style="margin-bottom: 0 !important;">{{'FORMAT_IF' | translate}}</div>
        <mat-form-field [style.width.%]="100" appearance="outline" floatLabel="never">
            <mat-select [(ngModel)]="rule.formatRule" [placeholder]="'FORMATTING_RULE' | translate">
                <mat-option *ngFor="let option of filteredRules"
                            [value]="option">{{option | translate}}</mat-option>
            </mat-select>
        </mat-form-field>

        <ng-container *ngIf="!rule.formatRule.toString().includes('EMPTY')" [ngSwitch]="filteredType">

            <mat-form-field *ngSwitchCase="'STATUS'" [style.width.%]="100" appearance="outline" floatLabel="never">
                <mat-select [(ngModel)]="rule.primaryFormatValue" [placeholder]="'STATUS' | translate">
                    <mat-option *ngFor="let option of getColumnStatuses(rule.targetColumn)"
                                [value]="option">{{option | translate}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngSwitchCase="'DATE'" [style.width.%]="100" appearance="outline" floatLabel="never">
                <mat-select [(ngModel)]="rule.primaryFormatValue" [placeholder]="'DATE' | translate">
                    <mat-option *ngFor="let option of dateOptions"
                                [value]="option">{{option | translate}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-radio-group *ngSwitchCase="'BOOLEAN'" [(ngModel)]="rule.primaryFormatValue"
                             aria-label="boolean option">
                <mat-radio-button [value]="'true'">{{'YES' | translate}}</mat-radio-button>
                <mat-radio-button [value]="'false'">{{'NO' | translate}}</mat-radio-button>
            </mat-radio-group>

            <ng-container *ngSwitchDefault>
                <div class="value-input" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
                    <mat-form-field [fxFlex]="rule.formatRule.toString().includes('BETWEEN') ? '139px': ''"
                                    [style.width.%]="100" appearance="outline" floatLabel="never">
                        <input [(ngModel)]="rule.primaryFormatValue" [placeholder]="(rule.formatRule.toString().includes('BETWEEN') ? 'MIN':'VALUE') | translate"
                               [type]="rule.formatRule.toString().includes('BETWEEN') ? 'number':'text'"
                               matInput>
                    </mat-form-field>
                    <ng-container *ngIf="rule.formatRule.toString().includes('BETWEEN')">
                        <span>&</span>
                        <mat-form-field [style.width.%]="100" appearance="outline" floatLabel="never" fxFlex="139px">
                            <input [(ngModel)]="rule.secondaryFormatValue" [placeholder]="'MAX' | translate"
                                   matInput
                                   type="number">
                        </mat-form-field>
                    </ng-container>
                </div>
            </ng-container>

        </ng-container>


        <div class="sub-section-label">{{'FORMATTING_STYLE' | translate}}</div>

        <div class="formatting-style-card">
            <div [ngStyle]="previewStyle(rule)" class="preview">
                {{'PREVIEW' | translate}}
            </div>
            <div class="style-icons" fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="4px">
                <mat-icon (colorPickerClose)="rule.formattingStyle.backgroundColor = $event"
                          [(colorPicker)]="rule.formattingStyle.backgroundColor"
                          [cpCancelButtonClass]="'cp-cancel-btn'"
                          [cpCancelButton]="true"
                          [cpOKButtonClass]="'cp-ok-btn'"
                          [cpOKButton]="true"
                          [cpOutputFormat]="'hex'"
                          [style.borderBottomColor]="rule.formattingStyle.backgroundColor"
                          class="color-indicator"
                          fontIcon="mdi-format-color-fill" fontSet="mdi"></mat-icon>
                <mat-icon (colorPickerClose)="rule.formattingStyle.color = $event"
                          [(colorPicker)]="rule.formattingStyle.color"
                          [cpCancelButtonClass]="'cp-cancel-btn'"
                          [cpCancelButton]="true" [cpOKButtonClass]="'cp-ok-btn'"
                          [cpOKButton]="true"
                          [cpOutputFormat]="'hex'"
                          [style.borderBottomColor]="rule.formattingStyle.color"
                          class="color-indicator"
                          fontIcon="mdi-format-color-text" fontSet="mdi"></mat-icon>

                <span style="height: 24px; border-left: 1px solid #e5e5e5;margin: 0 2px;"></span>

                <mat-icon (click)="rule.formattingStyle.bold = !rule.formattingStyle.bold"
                          [class.checked]="rule.formattingStyle.bold"
                          fontIcon="mdi-format-bold" fontSet="mdi"></mat-icon>
                <mat-icon (click)="rule.formattingStyle.italic = !rule.formattingStyle.italic"
                          [class.checked]="rule.formattingStyle.italic"
                          fontIcon="mdi-format-italic" fontSet="mdi"></mat-icon>
                <mat-icon (click)="rule.formattingStyle.underlined = !rule.formattingStyle.underlined"
                          [class.checked]="rule.formattingStyle.underlined"
                          fontIcon="mdi-format-underline" fontSet="mdi"></mat-icon>
                <mat-icon (click)="rule.formattingStyle.strikethrough = !rule.formattingStyle.strikethrough"
                          [class.checked]="rule.formattingStyle.strikethrough"
                          fontIcon="mdi-format-strikethrough" fontSet="mdi"></mat-icon>
            </div>
        </div>

        <ng-container *ngIf="rule.appliedTo === 'COLUMN' && ['STATUS', 'BOOLEAN'].includes(filteredType)">
            <div class="sub-section-label" style="margin-bottom: 0 !important;">{{'DISPLAY_MODE' | translate}}</div>
            <div class="display-mode" fxLayout="column">
                <mat-button-toggle-group [(ngModel)]="rule.formattingStyle.displayMode" [style.width]="'fit-content'" aria-label="Display Mode" name="displayMode">
                    <mat-button-toggle *ngFor="let mode of displayModes" [value]="mode">{{mode | translate}}</mat-button-toggle>
                </mat-button-toggle-group>


                <div class="display-mode-container">
                    <ng-container [ngSwitch]="rule.formattingStyle.displayMode">
                        <ng-container *ngSwitchCase="'ICON'">
                            <button (click)="selectIcon()" mat-button style="padding: 0 8px; line-height: 20px;">
                                <mat-icon [fontIcon]="selectedIcon" [ngStyle]="previewStyle(rule)" fontSet="mdi" style="padding: 4px; border-radius: 50%;"></mat-icon>
                                {{rule.primaryFormatValue | translate}}
                            </button>
                        </ng-container>
                        <ng-container *ngSwitchCase="'BADGE'">
                            <span [ngStyle]="previewStyle(rule)" class="ft-badge">{{rule.primaryFormatValue | translate}}</span>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <div [ngStyle]="previewStyle(rule)" style="padding: 4px;">{{rule.primaryFormatValue | translate}}</div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>



    </div>
    <div [style.padding.px]="12" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px">
        <button (click)="cancel()" color="warn" mat-stroked-button>{{'CANCEL' | translate}}</button>
        <button (click)="applyRule()" color="primary" mat-stroked-button>{{'APPLY' | translate}}</button>
    </div>
</div>