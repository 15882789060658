import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {SharedService} from '../shared.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ReportingService} from '../../reporting/reporting.service';
import {assign} from 'lodash';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-performer-assign',
    templateUrl: './performer-assign.component.html',
    styleUrls: ['./performer-assign.component.scss']
})
export class PerformerAssignComponent implements OnInit, OnDestroy {

    radiologists: any[] = [];
    assignedRadiologist: any;
    reportingTask: any;
    comment: string = '';
    title: string = 'DELEGATE_TASK';
    private sub: Subscription;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private shared: SharedService,
                private dialogRef: MatDialogRef<PerformerAssignComponent>,
                private snack: MatSnackBar,
                private reporting: ReportingService) {
        this.title = this.data.title;
        this.sub = this.shared.getPerformingPhysicians().subscribe(data => this.radiologists = data);
        this.reporting.getReportingTask(this.data.task.id).subscribe(res => this.reportingTask = res);
    }

    saveRow() {

        if (this.title === 'DELEGATE_TASK') {
            assign(this.reportingTask, {
                secondPerformerName: this.assignedRadiologist,
                assigningComment: this.comment
            });
        } else if (this.title === 'RE_ASSIGN_TASK') {
            assign(this.reportingTask, {
                performerName: this.assignedRadiologist,
                secondPerformerName: null
            });
        }

        this.reporting.saveReportingTask(this.reportingTask).subscribe(res => {
            if (res && res.id) {
                this.snack.open(`La tâche a été ${this.title === 'DELEGATE_TASK' ? 'Déléguée' : 'affectée'} à ${ this.assignedRadiologist.fullName}`, 'ok', {duration: 2000});
                this.dialogRef.close(null)
            }
        });
    }

    removeAssignee() {
        assign(this.reportingTask, {
            secondPerformerName: null,
            assigningComment: ''
        });

        this.reporting.saveReportingTask(this.reportingTask).subscribe(res => {
            if (res && res.id) {
                this.snack.open(`${ this.assignedRadiologist.fullName} has been unassigned from report`, 'ok', {duration: 2000});
                this.dialogRef.close(null)
            }
        });
    }

    compareFn(a: any, b: any): boolean {
        return a && b ? a.id === b.id : a === b;
    }

    ngOnInit() {
        this.assignedRadiologist = this.data.task.secondPerformerName;
        this.comment = this.data.task.assigningComment;
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

}
