import {BehaviorSubject, forkJoin, Observable, of, of as observableOf, Subscription} from 'rxjs';

import {catchError, debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, Inject, isDevMode, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {assign, filter as _filter, find as _find, get, keys, set as _set, union, xor} from 'lodash';
import {AvailabilityCheckComponent} from '../availability-check/availability-check.component';
import {
    addressEntity,
    Appointment,
    AVSC,
    DEBITERS, DefaultValues,
    Demographic,
    GeneralSetting,
    Hl7IdName,
    Insurance,
    Payment,
    PaymentChangeModel,
    PaymentItem,
    PaymentStatus, PostalCode,
    ProcedureCode,
    ThermalPrintModel,
    WorkflowItem,
    WsMessage
} from '../../model';
import {SettingService} from '../../setting/setting.service';
import {PatientSearchComponent} from '../../patient/patient-search/patient-search.component';
import * as moment from 'moment';
import {
    FileElement,
    FileService,
    LocalStorageService,
    PatientScheduledExamsComponent,
    ReferringPhysicianAddComponent,
    ReferringPhysiciansSearchComponent,
    SharedService,
    ValueDescriptionComponent
} from '../../shared';
import {buildISR, month_from_str, StringUtils} from '../../utils';
import {SchedulerService} from '../scheduler.service';
import {ProcedureCodeSearchComponent} from '../procedure-code-search/procedure-code-search.component';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {PathologyEditComponent} from '../../setting/pathology-setting/pathology-edit/pathology-edit.component';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {AppConfigService} from '../../app-config.service';
import {fromFetch} from 'rxjs/fetch';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {PROFILE_PLACEHOLDER} from '../../global-variables';
import {v4} from 'uuid';
import {PatientService} from '../../patient/patient.service';
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";


class PaymentChange {
    constructor(public convention?: any, public exam?: any, public pricing?: any, public pec: boolean = false) {
    }
}

@Component({
    selector: 'app-exam-advanced',
    templateUrl: './exam-advanced.component.html',
    styleUrls: ['./exam-advanced.component.scss'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
    }]
})
export class ExamAdvancedComponent implements OnInit, OnDestroy, AfterViewInit {

    order = false;
    singleReport = false;
    anExists = false;
    patientIdExists: boolean;
    patientSelected = false;
    searchKeys = {fn: '', ln: ''};
    age: number;
    @ViewChild('lastName') lastName: ElementRef;

    patientForm: FormGroup;
    examForm: FormGroup;
    orderForm: FormGroup;

    exams: FormArray;

    titles: any[];
    genders: any[];
    priorities: any[];
    appointmentReasons: any[];
    reasonForExams: any[];
    patientClasses: any[];
    aets: any[];
    technicians: any[];
    confidentialities: any[];
    debiters: string[];

    generalSetting: GeneralSetting;
    numOfDuplicatePatients: number;

    resId: number;
    aet: any;
    scheduledPerfPhysician: any;

    hasFutureExams: Observable<boolean>;

    fileElements: Observable<FileElement[]>;
    currentRoot: FileElement;
    currentPath: string;
    canNavigateUp = false;

    patientID: string;
    filteredAets: Array<any[]> = [];
    performingPhysicians: any[];
    subs: Subscription[] = [];
    private accessionNumber: any;
    ageForm: FormGroup;
    private workflowItemToUpdate: WorkflowItem;
    private paymentItemToUpdate: WorkflowItem;

    selected: number;

    convType: string = '';
    procedureCodeControl = [new FormControl()];
    referringPhysicianControl = [new FormControl()];
    filteredProcedures: Array<ProcedureCode[]> = [];
    filteredReferringPhysicians: Array<any[]> = [];
    minDate = new Date();

    organismPecs: any[] = [];
    organismConventions: any[] = [];

    organisms: Hl7IdName[] = [];
    organismSelection: boolean;
    private exceptions: { procedureCode: string, patientPart: number, organismPart: number }[] = [];

    paymentForm: FormGroup;
    banks: any[] = [];
    paymentMethods: any[] = [];
    leftAmount: number = 0.0;
    payment = new Payment();

    filteredPostalCodes: PostalCode[] = [];
    cityControl = new FormControl('');
    postalCodeControl = new FormControl('');

    dialogData = {type: 'external', icon: 'plus'};
    examSaved: boolean;
    saving: boolean;
    posEnabled: boolean;
    tariffs: any[] = [];
    tariffId: number;
    private paymentPartition = new BehaviorSubject<{ patientPart: any, organismPart: any }>(null);
    private savedIsrIdFromPacs: any;

    private paymentFormChange = new BehaviorSubject<PaymentChange>(new PaymentChange());
    private paymentChangeModel = new BehaviorSubject<PaymentChangeModel>(new PaymentChangeModel());
    private pecLines: { code: string, price: number, grantedPrice: number } [] = [];
    private selectedOrganismId: number;
    private selectedPecID: string;
    private selectedPecRef: string;
    private selectedOrganism: string;
    private tariffLines: any[] = [];
    currencyFormat = 'DH';
    private selectedRange: any;

    filteredOrganisms: Observable<Hl7IdName[]>;
    billingRequired: boolean;
    public profilePicture: SafeResourceUrl;
    private profilePicBase64: string;
    private readonly admissionNumber: any;
    private readonly paymentID: any;
    private readonly accessionNumbers: string[];
    private readonly defaultConvention = 'none@0@100@0';
    private scannerIdUrl: any;
    private is_external: boolean;
    private convention: any;
    public ready: boolean = false;
    public alerts: string;

    private defaultValues: DefaultValues;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<ExamAdvancedComponent>,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private snack: MatSnackBar,
                private _sanitizer: DomSanitizer,
                private _localStorage: LocalStorageService,
                private patientService: PatientService,
                private setting: SettingService,
                private _config: AppConfigService,
                private fileService: FileService,
                private service: SchedulerService,
                private shared: SharedService) {

        this.profilePicture = PROFILE_PLACEHOLDER;

        this.posEnabled = this._localStorage.getItem('posEnabled') || false;

        this.resId = data.resource ? data.resource.id : 0;
        this.debiters = DEBITERS;
        this.admissionNumber = this.data.admissionNumber;
        this.accessionNumbers = this.data.accessionNumbers;
        this.paymentID = this.data.paymentID;

        this.is_external = this.data.is_external

        this.currencyFormat = _config.currencyFormat;

        this.createForm();
        this.checkAnExists();
        this.checkExistingPatientsCount();
        this.checkPatientIdExists();
        this.setAnonymousData();
        this.createAgeForm();
    }

    printAndSave(patientData, data) {
        this.saving = true;
        this.printTicket(patientData, data, () => this.save(patientData, data))
    }

    scanIDCard() {
        if (this.scannerIdUrl.startsWith('beid')) {

            this.ready = false;

            if (isDevMode() || this.scannerIdUrl.includes('demo')) {
                setTimeout(() => {
                    let eid_data = StringUtils.ELKAFIL_EID_CARD;
                    this.ready = true;
                    this.setCardData(eid_data);
                }, 2500);
            } else {
                this.service.scanIdCard.next(true);
                this.service.beidCardData.subscribe(data => {
                    let card_data = JSON.parse(data);
                    this.ready = true;
                    if (card_data['success']) {
                        this.setCardData(card_data);
                    } else {
                        this.snack.open(card_data['message'], '', {duration: 2000});
                    }

                });
            }
        } else {
            let data$ = fromFetch(this.scannerIdUrl).pipe(
                switchMap(response => {
                    if (response.ok) return response.json();
                    else return of({error: true, message: `Error ${response.status}`});
                }),
                catchError(err => {
                    console.error(err);
                    return of({error: true, message: err.message});
                })
            );

            let card = {};
            data$.subscribe({
                next: res => {
                    if (res && res.documento) {
                        let doc = res.documento;
                        assign(card, {
                            firstName: doc.nombre,
                            lastName: doc.apellido1,
                            cin: doc.numero_documento,
                            dob: doc.fecha_nacimiento ? moment(doc.fecha_nacimiento, 'DDMMYYYY') : null,
                            address: doc.localidad_nacimiento,
                            sex: doc.sexo,
                            country: doc.pais,
                            profilePicture: doc.fichero_fotografia
                        });
                    }
                },
                complete: () => {

                    this.profilePicBase64 = get(card, 'profilePicture');
                    this.profilePicture = this._sanitizer.bypassSecurityTrustResourceUrl(this.profilePicBase64 || PROFILE_PLACEHOLDER);

                    this.patientForm.patchValue({
                        firstName: get(card, 'firstName', ''),
                        lastName: get(card, 'lastName', ''),
                        cin: get(card, 'cin', ''),
                    });

                    this.patientForm.get('demographic').patchValue({
                        dateOfBirth: get(card, 'dob'),
                        gender: this.genders.find(it => it.value === get(card, 'sex')) || {id: ''}
                    });

                    this.changeAge();
                }
            });
        }
    }

    createForm() {
        this.patientForm = this.fb.group({
            id: '',
            externalPatientID: '',
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.email],
            phone: ['', [Validators.maxLength(10), Validators.pattern(new RegExp('\\d'))]],
            cin: '',
            nationalNumber: '',
            debiter: 'PATIENT',
            insurance: this.fb.group(new Insurance()),
            title: this.fb.group({id: ''}),
            patientClass: this.fb.group({id: ''}),
            demographic: this.fb.group(assign(new Demographic(), {
                gender: this.fb.group({id: ['', Validators.required]}),
                confidentiality: this.fb.group({id: ''}),
            })),
            address: this.fb.group(addressEntity)
        });

        this.examForm = this.fb.group({
            exams: this.fb.array([this.createExamForm()])
        });

        this.orderForm = this.fb.group({
            appointmentReason: null,
            date: [new Date(), Validators.required],
            time: [moment().format('HH:mm'), Validators.required],
            duration: ['10', Validators.required],
        });

        this.paymentForm = this.fb.group(assign(this.payment, {
            paymentMethod: this.fb.group({id: ''}),
            bank: this.fb.group({id: ''}),
            organism: this.fb.group({id: '', name: '', defaultRefundAmount: ''})
        }));

        this.subs.push(this.paymentForm.get('paymentDispense').valueChanges
            .subscribe(value => {
                for (let key in this.paymentForm.controls) if (!['paymentDispense', 'locked'].includes(key)) {
                    value ? this.paymentForm.controls[key].disable() : this.paymentForm.controls[key].enable()
                }
            }));

        this.exams = this.examForm.get('exams') as FormArray;
    }

    private get printMode(): 'CUPS' | 'CHROME' {
        return this.generalSetting.receiptPrintMode;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.lastName.nativeElement.focus();
        }, 100);
    }

    onChangePostalCode(event: MatAutocompleteSelectedEvent) {
        let postalCode = event.option.value;

        this.patientForm.get('address').get('city').patchValue(postalCode.location);
        this.patientForm.get('address').get('postalCode').patchValue(postalCode.code);
        this.cityControl.patchValue(postalCode.location);
        this.postalCodeControl.patchValue(postalCode.code);
    }

    save(patientData, data) {

        let paymentModel = this.paymentChangeModel.getValue();

        let isrs = buildISR(patientData, data, this.reasonForExams, this.aets, this.data.spsStatus, null, null, this.performingPhysicians);

        let accessionNumbers = isrs.map(isr => isr.accessionNumber);

        if (this.data.isr) isrs[0].id = this.data.isr.id;

        let paymentID = this.paymentID || v4();

        isrs.forEach(isr => {
            // isr.requestedProcedure.scheduledProcedureSteps[0].attachedAccessionNumbers = xor(accessionNumbers, [isr.accessionNumber]).join(',');
            isr.requestedProcedure.scheduledProcedureSteps[0].attachedAccessionNumbers = union(xor(accessionNumbers, [isr.accessionNumber]), this.accessionNumbers).join(',');
            isr.requestedProcedure.scheduledProcedureSteps[0].singleReport = this.singleReport;
            isr.requestedProcedure.scheduledProcedureSteps[0].paymentID = paymentID;
            isr.requestedProcedure.scheduledProcedureSteps[0].paymentType = paymentModel.admissionType;
            isr.requestedProcedure.scheduledProcedureSteps[0].tariffId = paymentModel.tariffId;
            isr.admissionID = this.admissionNumber;

            let studyInstanceUID = get(this.data, 'queryParam.studyInstanceUID');
            if (studyInstanceUID) {
                isr.requestedProcedure.scheduledProcedureSteps[0].studyInstanceUID = studyInstanceUID;
                isr.requestedProcedure.scheduledProcedureSteps[0].scheduledProcedureStepStatus = 'FINISHED';
                isr.requestedProcedure.scheduledProcedureSteps[0].instancesAvailable = true;
            }

            if (paymentModel.admissionType === 'PEC') {
                isr.requestedProcedure.scheduledProcedureSteps[0].pecId = this.selectedPecID;
                isr.requestedProcedure.scheduledProcedureSteps[0].pecRef = this.selectedPecRef;
            }

            isr.patient.insurance.organismName = paymentModel.organism;
            isr.patient.imageBase64 = this.profilePicBase64;
        });

        let payment: Payment = this.paymentForm.getRawValue();
        payment.paymentID = paymentID;

        payment.paymentItems = [(new PaymentItem())];
        this.payment = payment;

        let exam = {exams: isrs, payment: this.buildPayment(payment)};

        this.subs.push(this.service.scheduleISRWithPayment(exam).subscribe(response => {
            this.examSaved = !!response;
            this.savedIsrIdFromPacs = this.data.queryParam ? response[0].isrId : null;

            this.saving = false;

            setTimeout(() => {
                let formFields = keys(new Payment());
                if (this.examSaved) formFields.forEach(key => this.paymentForm.get(key).disable({
                    onlySelf: true,
                    emitEvent: false
                }));
                else formFields.forEach(key => this.paymentForm.get(key).enable({onlySelf: true, emitEvent: false}));
            }, 0);
        }));

    }

    private buildPayment(payment: Payment): Payment {
        payment = assign(payment, {
            paymentMethod: payment.paymentMethod && payment.paymentMethod.id !== '' ? payment.paymentMethod : null,
            bank: payment.bank && payment.bank.id !== '' ? payment.bank : null,
            organism: payment.organism && payment.organism.id !== '' ? payment.organism : null,
            date: moment(payment.date).utc(true)
        });
        if (this.paymentForm.get('due').value === 0) {
            payment.paymentStatus = PaymentStatus.EXEMPT;
            payment.paymentDispense = true;
        }
        return payment;
    }

    checkAvailability(fg) {
        let value = fg.getRawValue();

        const dialogRef = this.dialog.open(AvailabilityCheckComponent, {data: true});
        dialogRef.componentInstance.avsc = new AVSC(get(value, 'technician.id'), get(value, 'aet.id'), get(value, 'date'), get(value, 'time'), get(value, 'duration', 15));

        this.subs.push(dialogRef
            .afterClosed()
            .subscribe(val => {
                if (val) fg.patchValue({
                    date: new Date(moment(val.date).format()),
                    time: val.start
                });
            }));
    }

    showPatientFutureExams() {
        this.subs.push(this.dialog
            .open(PatientScheduledExamsComponent, {data: this.patientForm.get('externalPatientID').value})
            .afterClosed()
            .subscribe(console.log))
    }

    findPatient() {
        assign(this.searchKeys, {
            fn: this.patientForm.get('firstName').value,
            ln: this.patientForm.get('lastName').value
        });

        let dialogRef = this.dialog.open(PatientSearchComponent, {
            width: '760px',
            data: this.searchKeys
        });

        dialogRef.componentInstance.selectedPatient = {};

        this.subs.push(dialogRef
            .afterClosed()
            .subscribe(selectedPatient => {
                if (selectedPatient) {
                    selectedPatient.title = _find(this.titles, {value: selectedPatient.title_value});
                    this.selectPatient(selectedPatient);
                }
            }));
    }

    addReferringPhysician(fg, idx) {
        this.subs.push(this.dialog.open(ReferringPhysicianAddComponent)
            .afterClosed()
            .subscribe(physician => {
                if (physician) {
                    this.filteredReferringPhysicians[idx].push(physician);
                    fg.patchValue({referringPhysician: physician});
                    this.referringPhysicianControl.forEach(it => it.patchValue(physician.fullName));
                }
            }))
    }

    selectPatient(patient: any) {
        if (patient) {

            this.patientSelected = true;

            if (patient.medicalHistory && patient.medicalHistory.medicalAlerts) {
                this.alerts = patient.medicalHistory.medicalAlerts;
            }

            this.patientID = patient.externalPatientID;

            this.fileElements = this.fileService.getPatientDocuments(this.patientID, 'root');

            let dob = patient.demographic.dateOfBirth;

            let gender = patient.demographic.gender || {id: ''};
            let patientClass = patient.patientClass || this.patientClasses[2];
            let title = patient.title || {id: ''};
            let debiter = patient.debiter || 'PATIENT';
            let confidentiality = patient.demographic.confidentiality || this.confidentialities[0];
            let insurance = patient.insurance || {};
            let org = get(insurance, 'organismName');
            if (org) _set(insurance, 'organismName', this.splitter(org, 0))

            _set(patient, 'title', title);
            _set(patient, 'debiter', debiter);
            _set(patient, 'patientClass', patientClass);
            _set(patient, 'insurance', insurance);
            _set(patient, 'demographic.gender', gender);
            _set(patient, 'demographic.dateOfBirth', dob ? dob : new Date());
            _set(patient, 'demographic.confidentiality', confidentiality);

            let address = patient.address

            this.patientForm.get('address').patchValue({
                street: address?.street,
                city: address?.city,
                postalCode: address?.postalCode,
            });

            this.cityControl.patchValue(address?.city);
            this.postalCodeControl.patchValue(address?.postalCode);


            this.patientForm.patchValue(patient);

            this.onSelectOrganism();

            this.patientForm.get('insurance.conventionName').patchValue(get(insurance, 'conventionName') || this.defaultConvention);
            this.patientForm.get('insurance.coverNumber').patchValue(get(insurance, 'coverNumber'));

            this.referringPhysicianControl.forEach(fc => fc.patchValue(patient.consultingDoctor ? patient.consultingDoctor.fullName : ''));

            if (!this.order && !patient.externalPatientID) this.generatePatientID();

            this.changeAge();
        }
    }

    changeDate() {
        let age = this.ageForm.getRawValue();
        if (age.years !== null && age.months !== null && age.days !== null) {
            this.service.calculateDOB(`${age.years || '0'}-${age.months || '0'}-${age.days || '0'}`).subscribe((res: WsMessage) => {
                let dob = moment(res.data, this._config.momentDateFormat);
                setTimeout(() => this.patientForm.get('demographic').patchValue({dateOfBirth: dob}), 200);
            });
        }
    }

    changeAge() {
        let value = this.patientForm.get('demographic.dateOfBirth').value;
        this.subs.push(this.service.calculateAge(moment(value).format('YYYYMMDD')).subscribe((res: WsMessage) => {
            if (res.data) {
                let age = res.data.split('-');

                this.ageForm.get('years').patchValue(age[0]);
                this.ageForm.get('months').patchValue(age[1]);
                this.ageForm.get('days').patchValue(age[2]);
            }
        }));
    }

    private createAgeForm() {
        this.ageForm = this.fb.group({years: 0, months: 0, days: 0});
    }

    onChangeProcedureCode(event, fg, idx) {
        let code = event.option.value;
        this.selected = code;

        let exCode = _find(this.exceptions, {procedureCode: code.code.trim()});

        if (exCode && this.convType !== 'PEC') fg.patchValue({
            patientPart: exCode.patientPart,
            organismPart: exCode.organismPart
        });

        this.selectProcedure(fg, code, idx);
    }

    private findOrganismPECs(organismId: any) {
        this.organismPecs = [];
        this.subs.push(this.shared.getOrganismPECS(this.patientID, organismId).subscribe(data => this.organismPecs = data));
    }

    private updatePaymentChangeModel(change: any) {
        let paymentModel = this.paymentChangeModel.getValue();
        assign(paymentModel, change);
        this.paymentChangeModel.next(paymentModel);
    }

    private observePaymentChangeModel() {
        this.subs.push(this.paymentChangeModel.subscribe(model => {
            if (model.procedures) {
                this.updateLinearExamPartitionFields(model.convention);
                this.updatePayment(model);
            }
        }));
    }

    onSelectConvention(convention: any) {
        this.convention = convention;
        this.updatePaymentChangeModel({convention});

        let conventionId = convention.includes('@') ? Number(convention.split('@')[1]) : 0;
        this.patientForm.get('insurance.conventionId').patchValue(conventionId);

        this.subs.push(this.shared.getConventionException(this.splitter(convention, 1)).subscribe(data => {
            if (data) {
                let conventionExceptions = data.map(v => {
                    let ex = v.split('@');
                    return {
                        procedureCode: ex[0].trim(),
                        patientPart: Number(Number(ex[1]).toFixed(2)),
                        organismPart: Number(Number(ex[2]).toFixed(2))
                    };
                });

                this.updatePaymentChangeModel({conventionExceptions});
            }
        }));
    }

    ngOnInit() {
        this.patientForm.get('insurance.organismName').disable();

        this.observePaymentChangeModel();

        if (this.data.resource !== 'n/a') this.setResource(this.data.resource);
        this.subs.push(this.patientForm.get('debiter').valueChanges.subscribe(value => {

            this.updatePaymentChangeModel({admissionType: value});

            if (value === 'PATIENT') this.patientForm.get('insurance.organismName').disable();
            else this.patientForm.get('insurance.organismName').enable();

            this.convType = value;
            this.organismSelection = value !== 'PATIENT';

            if (value !== 'PATIENT') {
                this.onSelectOrganism();
                this.paymentForm.get('payer').patchValue('THIRD_PAYER');
            } else {
                this.selectedOrganismId = null;
                this.patientForm.get('insurance.conventionName').patchValue('');
                this.paymentFormChange.next(new PaymentChange());
                this.paymentForm.get('payer').patchValue(value);
            }
        }));

        this.subs.push(forkJoin([
            this.shared.getPriorities(),
            this.shared.getAppointmentReasons(),
            this.shared.getReasonForExams(),
            this.shared.getAetList(),
            this.shared.getTechnicians(),
            this.shared.getPerformingPhysicians(),
            this.shared.getBanks(),
            this.shared.getPaymentMethods(),
            this.shared.getGenders(),
            this.shared.getTitles(),
            this.shared.getPatientClasses(),
            this.shared.getConfidentialities(),
            this.setting.getGeneralSetting(),
            this.shared.getOrganismsList(),
            this.setting.getDefaultValues(),
        ]).subscribe(data => {
            [
                this.priorities,
                this.appointmentReasons,
                this.reasonForExams,
                this.aets,
                this.technicians,
                this.performingPhysicians,
                this.banks,
                this.paymentMethods,
                this.genders,
                this.titles,
                this.patientClasses,
                this.confidentialities,
                this.generalSetting,
                this.organisms,
                this.defaultValues
            ] = data;

            this.setDefaultValues(this.defaultValues);

            this.shared.getTariffs().subscribe(value => this.tariffs = value);

            this.ready = true;
            this.billingRequired = this.generalSetting && this.generalSetting.billingRequired;

            this.filteredOrganisms = this.patientForm.get('insurance.organismName').valueChanges.pipe(
                startWith(''),
                map(value => this._filter(value))
            );

            this.scannerIdUrl = this.generalSetting.scannerIdUrl;

            this.paymentForm.get('paymentMethod').patchValue(this.paymentMethods.find(it => it.value === this.defaultValues.defaultPaymentMethod) || this.paymentMethods.find(v => v.code === 'CASH'));

            this.filteredAets[0] = this.aets;
            this.singleReport = this.generalSetting ? this.generalSetting.singleReport : false;

            if (this.generalSetting && this.generalSetting.patientIdGenerationMode === 'AUTOMATIC' && !get(this.data, 'patient.externalPatientID') && !get(this.data, 'queryParam.patientID'))
                this.generatePatientID();

            if (this.generalSetting && this.generalSetting.accessionIdGenerationMode === 'AUTOMATIC' && !this.data.ids && !get(this.data, 'queryParam.accessionNumber')) this.generateAccessionNumber()

            this.subs.push(this.procedureCodeControl[0].valueChanges
                .pipe(
                    startWith(''),
                    switchMap(query => this.shared.getPaginatedProcedureCodes(10, 0, 'code', 'asc', query)),
                    map(data => data['content']),
                    catchError(() => observableOf([]))
                ).subscribe(data => this.filteredProcedures[0] = data));
            this.procedureCodeControl[0].patchValue('');

            this.subs.push(this.referringPhysicianControl[0].valueChanges
                .pipe(
                    startWith(''),
                    switchMap(query => this.shared.getPaginatedReferringPhysicians(10, 0, 'lastName', 'asc', query)),
                    map(data => data['content']),
                    catchError(() => observableOf([]))
                ).subscribe(data => this.filteredReferringPhysicians[0] = data));
            this.referringPhysicianControl[0].patchValue('');

            this.patientForm.get('demographic.confidentiality').patchValue(this.confidentialities[0]);
            this.patientForm.get('patientClass').patchValue(this.patientClasses[2]);

            this.setDate(this.data.selectedDateRange);

            this.selectPatient(this.data.patient);

            this.selectISR(this.data.isr);

            this.selectPacsData(this.data.queryParam);

            this.subs.push(this.patientForm.get('demographic.gender').valueChanges.subscribe(gender => {
                let title = gender.id === 10 ? this.titles[0] : this.titles[1];
                this.patientForm.get('title').patchValue(title);
            }));

            if (!this.data.editable) {
                this.patientForm.disable();
                this.orderForm.disable();
                this.examForm.disable();
                this.orderForm.disable();
            }

            this.subs.push(this.cityControl.valueChanges
                .pipe(
                    startWith(''),
                    switchMap(query => this.shared.getPaginatedPostalCodes(10, 0, 'code', 'asc', query)),
                    map(data => data['content']),
                    catchError(() => observableOf([]))
                ).subscribe(data => this.filteredPostalCodes = data));
            this.cityControl.patchValue('');

            this.subs.push(this.postalCodeControl.valueChanges
                .pipe(
                    startWith(''),
                    switchMap(query => this.shared.getPaginatedPostalCodes(10, 0, 'code', 'asc', query)),
                    map(data => data['content']),
                    catchError(() => observableOf([]))
                ).subscribe(data => this.filteredPostalCodes = data));
            this.postalCodeControl.patchValue('');
        }));

    }

    onSelectOrganism() {
        let organismName = this.patientForm.get('insurance.organismName').value;
        if (!organismName) return;

        let org = this.organisms.find(it => it.name.trim() == organismName.trim());

        let organism = [org.name, org.id].join('@');

        this.updatePaymentChangeModel({organism});

        this.selectedOrganism = organism;

        this.paymentForm.get('organismName').patchValue(organism);

        this.patientForm.get('insurance.conventionName').patchValue(this.defaultConvention);
        this.patientForm.get('insurance.organismName').patchValue(organismName);
        this.patientForm.get('insurance.organismId').patchValue(org.id);
        this.patientForm.get('insurance.code').patchValue(org.code);

        let form = this.examForm.get('exams')['controls'][0];

        if (this.billingRequired) {
            if (this.convType === 'THIRD_PARTY_PAYMENT') {
                this.findConventions(org.id);
            } else if (this.convType === 'PEC') {
                this.findOrganismPECs(org.id);
            }
        }

        form.get('paymentType').patchValue(this.convType)
    }

    conventionCompare = (c1: any, c2: any): boolean => c1 && c2 ? this.splitter(c1, 0) == this.splitter(c2, 0) : c1 == c2;


    private setPaymentChangeAttribute(attr: string, value: any) {
        let change = this.paymentFormChange.getValue();
        change[attr] = value;
        this.paymentFormChange.next(change);
    }

    createExamForm(): FormGroup {
        return this.fb.group({
            orderId: '',
            reasonForExam: this.fb.group({id: ''}),
            procedureCode: [null, Validators.required],
            aet: this.fb.group({id: ['', Validators.required]}),
            technician: this.fb.group({id: ''}),
            referringPhysician: this.fb.group({id: '', firstName: '', lastName: '', phone: '', fullName: ''}),
            performingPhysician: this.fb.group({id: ''}),
            priority: this.fb.group({id: 262}),
            date: [moment(), Validators.required],
            time: [moment().format('HH:mm'), Validators.required],
            duration: [10, Validators.required],
            accessionNumber: '',
            patientPart: 100.0,
            organismPart: 0.0,
            paymentType: 'PATIENT',
            pecId: null,
            pecRef: '',
            totalAmount: 0.0,
            discount: 0.0,
            percentageDiscount: 0.0,
            notes: '',
        });
    }

    private setCardData(card_data: any): void {
        this.patientForm.patchValue({
            firstName: card_data['firstnames'],
            lastName: card_data['surname'],
            cin: card_data['card_number'],
            nationalNumber: card_data['national_number'],
        });

        let dob = card_data['date_of_birth'].trim().split(' ').filter(it => it != '');
        console.log(dob);
        let d = dob[0], m = month_from_str(dob[1]), y = dob[2];

        this.patientForm.get('demographic').patchValue({
            dateOfBirth: new Date(y, m - 1, d),
            gender: this.genders.find(it => it.value === card_data['gender']) || {id: ''}
        });

        this.patientForm.get('address').patchValue({
            street: card_data['address_street_and_number'],
            province: '',
            city: card_data['address_municipality'],
            country: 'Belgique',
            postalCode: card_data['address_zip']
        });

        this.postalCodeControl.patchValue(card_data['address_zip']);
        this.cityControl.patchValue(card_data['address_municipality']);

        this.changeAge();
    }

    addItem(): FormGroup {
        this.exams = this.examForm.get('exams') as FormArray;
        let physician = this.referringPhysicianControl[0].value;
        this.procedureCodeControl.push(new FormControl());
        this.referringPhysicianControl.push(new FormControl(physician));

        let exams = this.exams['controls'] as any[];
        let l = exams.length;

        let date = exams[l - 1].get('date').value;
        let time = exams[l - 1].get('time').value;
        // let duration = exams[l - 1].get('duration').value;
        let duration = 0;

        let exForm = this.createExamForm();

        exForm.patchValue({
            priority: this.priorities[2],
            performingPhysician: this.performingPhysicians[0],
            date: date,
            time: moment(time, 'HH:mm').add(duration, 'm').format('HH:mm'),
            accessionNumber: this.incrementAccessionNumber()
        });

        this.subs.push(this.procedureCodeControl[l].valueChanges
            .pipe(
                startWith(''),
                switchMap(query => this.shared.getPaginatedProcedureCodes(10, 0, 'code', 'asc', query)),
                map(data => data['content']),
                catchError(() => observableOf([]))
            ).subscribe(data => this.filteredProcedures[l] = data));

        this.procedureCodeControl[l].patchValue('');

        this.subs.push(this.referringPhysicianControl[l].valueChanges
            .pipe(
                startWith(''),
                switchMap(query => this.shared.getPaginatedReferringPhysicians(10, 0, 'lastName', 'asc', query)),
                map(data => data['content']),
                catchError(() => observableOf([]))
            ).subscribe(data => this.filteredReferringPhysicians[l] = data));

        this.exams.push(exForm);
        return exForm;
    }

    removeItem(idx) {
        this.exams.removeAt(idx);
        this.procedureCodeControl.splice(idx, 1);
        this.updateLinearExamPartitionFields(this.convention);
    }

    fillForm(pecLine: { code: string, price: number, grantedPrice: number }, idx: number): FormGroup {
        const fg = this.addItem();

        this.subs.push(this.shared.getProcedureCode(pecLine.code).subscribe(value => {
            if (value) {
                this.procedureCodeControl[idx].patchValue(value.code);
                this.selectProcedure(fg, value, idx);
            } else console.log('Code not found !');
        }));

        return fg;
    }

    incrementAccessionNumber(): any {
        if (this.accessionNumber.length > 2 && this.generalSetting.accessionNumberPrefix) {
            let anSuffix = parseFloat(this.accessionNumber.replace(new RegExp(this.generalSetting.accessionNumberPrefix), ''));
            return this.accessionNumber = `${this.generalSetting.accessionNumberPrefix}${++anSuffix}`;
        }
    }

    enterAmount(e) {
        let amount = Number(parseFloat(e.target.value || '0').toFixed(2));
        this.paymentForm.get('enteredAmount').patchValue(amount);
        this.updatePaymentChangeModel({enteredAmount: amount, changeAmount: true});
    }

    onChangeRefPhy(event, fg, idx) {
        let physician = event.option.value;
        fg.patchValue({referringPhysician: physician});
        this.referringPhysicianControl.forEach(it => it.patchValue(physician.fullName));
    }

    searchProcedureCode(fg, idx) {
        this.subs.push(this.dialog.open(ProcedureCodeSearchComponent, {minWidth: '600px'})
            .afterClosed()
            .subscribe(code => {
                if (code) this.selectProcedure(fg, code, idx)
            }));
    }

    setDate(range: any) {
        this.selectedRange = {
            date: moment(range.start).format('YYYY-MM-DD'),
            time: moment(range.start).format('HH:mm'),
            duration: moment(range.end).diff(moment(range.start), 'minutes', true).toFixed(0)
        };

        this.orderForm.patchValue(this.selectedRange);
        this.examForm.get('exams')['controls'].forEach(c => {
            c.patchValue(this.selectedRange);
            c.patchValue({priority: this.priorities[2], performingPhysician: this.performingPhysicians[0]});
        });
    }

    addFolder(folder: { name: string }) {
        let file: FileElement = {
            folder: true,
            patientID: this.patientID,
            name: folder.name,
            parent: this.currentRoot ? this.currentRoot.uuid : 'root'
        };

        this.subs.push(this.fileService.createFile(file).subscribe((res: FileElement) => {
            this.updateFileElementQuery();
        }));
    }

    moveElement(event: { element: FileElement; moveTo: FileElement }) {

        let elt = event.element;
        _set(elt, 'parent', event.moveTo.uuid);

        this.subs.push(this.fileService.updateFile(elt).subscribe(res => {
            this.updateFileElementQuery();
        }));
    }

    afterUpload(event) {
        if (event) this.updateFileElementQuery();
    }

    renameElement(element: FileElement) {
        _set(element, 'name', element.name);

        this.subs.push(this.fileService.updateFile(element).subscribe(res => {
            this.updateFileElementQuery();
        }));
    }

    removeElement(element: FileElement) {
        this.subs.push(this.fileService.deleteFile(element).subscribe(ok => {
            if (ok) this.updateFileElementQuery();
        }));
    }

    ngOnDestroy() {
        this.patientID = null;
        this.currentRoot = null;
        this.currentPath = null;
        this.fileElements = observableOf([]);
        this.subs.forEach(sub => sub.unsubscribe());
        this.convType = '';
        this.tariffId = null
    }

    private setResource(resource: any) {
        switch (resource.resourceName) {
            case 'aet':
                this.subs.push(this.shared.getAetList().subscribe(res => {
                    this.aet = res.find(val => val.id === this.resId);
                }));
                break;
            case 'physician':
                this.subs.push(this.shared.getPerformingPhysicians().subscribe(res => {
                    this.scheduledPerfPhysician = res.find(val => val.id === this.resId);
                }));
        }
    }

    addTitle() {
        this.subs.push(this.dialog.open(ValueDescriptionComponent).afterClosed()?.subscribe(title => {
            if (title) this.subs.push(this.shared.createTitle(title).subscribe(res => {
                this.titles.push(res);
                this.patientForm.get('title').patchValue(res);
            }));
        }));
    }

    private checkForPatientFutureAppointment() {
        if (!this.order && !this.data.patient) this.subs.push(this.patientForm.get('externalPatientID').valueChanges.subscribe(value => {
            if (String(value).length !== 0) this.hasFutureExams = this.shared.checkForPatientFutureAppointment(value);
        }));
    }

    private setAnonymousData() {
        if (!this.order && !this.data.patient) this.subs.push(this.patientForm.get('patientClass').valueChanges.subscribe(value => {
            if (!this.patientForm.get('firstName').value && !this.patientForm.get('lastName').value) this.setAnon(value['id']);
        }));
    }

    private setAnon(id: number) {
        let date = moment().format('YYMDHms');
        if (id === 1) this.patientForm.patchValue({
            firstName: 'Anon_' + date,
            lastName: 'Anon_' + date
        });
    }

    // Attached documents
    updateFileElementQuery() {
        this.fileElements = this.fileService.getPatientDocuments(this.patientID, this.currentRoot ? this.currentRoot.uuid : 'root');
    }

    navigateUp() {
        if (this.currentRoot && this.currentRoot.parent === 'root') {
            this.currentRoot = null;
            this.canNavigateUp = false;
            this.updateFileElementQuery();
        } else {
            this.currentRoot = this.fileService.get(this.currentRoot.parent);
            this.updateFileElementQuery();
        }
        this.currentPath = this.popFromPath(this.currentPath);
    }

    navigateToFolder(element: FileElement) {
        this.currentRoot = element;
        this.updateFileElementQuery();
        this.currentPath = this.pushToPath(this.currentPath, element.name);
        this.canNavigateUp = true;
    }

    pushToPath(path: string, folderName: string) {
        let p = path ? path : '';
        p += `${folderName}/`;
        return p;
    }

    popFromPath(path: string) {
        let p = path ? path : '';
        let split = p.split('/');
        split.splice(split.length - 2, 1);
        p = split.join('/');
        return p;
    }

    private checkPatientIdExists() {
        if (!this.order && !this.data.patient) this.subs.push(this.patientForm.get('externalPatientID').valueChanges.subscribe(value => {
            if (String(value).length !== 0) this.subs.push(this.shared.checkIdIfExists(value).subscribe(res => this.patientIdExists = res));
        }));
    }

    private selectProcedure(fg: FormGroup, procedure: ProcedureCode, idx: number) {

        this.selected = procedure.id;
        fg.patchValue({procedureCode: procedure});

        let tarif = this.tariffLines.find(tarif => tarif.code === procedure.code);
        fg.get('totalAmount').patchValue(tarif ? tarif.price : (procedure.billingCode ? procedure.billingCode.price : 0));

        this.procedureCodeControl[idx].patchValue(procedure.code !== procedure.description ? procedure.code+' - '+procedure.description: procedure.description);

        this.updatePaymentChangeModelProcedures();

        this.subs.push(this.shared
            .getAetList()
            .subscribe(aets => {
                this.filteredAets[idx] = procedure.modality ? _filter(aets, ae => ae.modality.id === procedure.modality.id) : aets;

                let defaultPhysician = procedure.defaultPerformingPhysician ? _find(this.performingPhysicians, {id: procedure.defaultPerformingPhysician.id}) : this.defaultValues.defaultPerformingPhysician ? this.performingPhysicians.find(it => it.id === this.defaultValues.defaultPerformingPhysician) : this.performingPhysicians[0];

                if (this.filteredAets[idx].length === 0) {
                    this.snack.open('Aucun appareil lié à cet examen', 'OK', {duration: 10000});
                    return;
                }

                fg.patchValue({
                    aet: this.filteredAets[idx][0] || {id: ''},
                    technician: this.filteredAets[idx][0].defaultTechnician || this.technicians.find(it => it.id === this.defaultValues.defaultTechnician),
                    performingPhysician: defaultPhysician,
                    reasonForExam: procedure.reasonForExam,
                    time: this.selectedRange.time
                });
            }));

        this.setPaymentChangeAttribute('exam', procedure);
    }

    calculatePercentage(e) {
        let discount = Number(parseFloat(e.target.value || '0').toFixed(2));
        this.updatePaymentChangeModel({globalDiscount: discount});
    }

    private checkExistingPatientsCount() {
        this.subs.push(this.patientForm.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            if (value.firstName && value.lastName)
                this.subs.push(this.patientService
                    .countExistingPatients(value.firstName, value.lastName)
                    .subscribe(total => this.numOfDuplicatePatients = total));

        }));
    }

    private generatePatientID() {
        this.subs.push(this.shared.generatePatientId().subscribe(res => {
            this.patientForm.get('externalPatientID').patchValue(res.id);
            this.patientID = res.id;
        }));
    }

    private checkAnExists() {
        if (!this.data.ids) this.examForm.get('exams')['controls']
            .forEach(c => this.subs.push(c.get('accessionNumber').valueChanges.subscribe(value => {
                if (String(value).length !== 0) this.subs.push(this.shared.checkAnIfExists(value).subscribe(res => this.anExists = res));
            })));
    }

    handlePaymentEmptyField(field: string, e: any) {
        let value = Number(parseFloat(e.target.value || '0').toFixed(2));
        if (value === 0) this.paymentForm.get(field).patchValue(value);
    }

    private findConventions(organismId: any) {
        this.organismConventions = [];
        this.subs.push(this.shared.getOrganismConventions(organismId).subscribe(res => {
            this.organismConventions = res;
            if (res.length === 0) this.paymentPartition.next({patientPart: 100.00, organismPart: 0.00});
        }));
    }

    splitter = (str: string, idx: number): string => str.split('@')[idx];
    paymentItems: any = [];
    paymentItemsObject: any = [];

    private _filter(value: string): Hl7IdName[] {
        const filterValue = value ? value.toLowerCase() : '';

        return this.organisms.filter(it => (it.name + it.code).toLowerCase().includes(filterValue));
    }

    onSelectPEC(pec: MatSelectChange) {
        let pecId = this.splitter(pec.value, 0);
        let pecRef = this.splitter(pec.value, 1);

        this.selectedPecID = pecId;
        this.selectedPecRef = pecRef;

        this.updatePaymentChangeModel({pec: pec.value});

        let forms = this.examForm.get('exams')['controls'] as any[];
        forms.forEach(fg => {
            fg.patchValue({
                pecId: pecId,
                pecRef: pecRef
            })
        });

        this.getPECLines(pecId);
    }

    /**
     * Create a Backend Flux<Data> endpoint with API_URL,
     * see Trisha Lee Reactive Spring with Kotlin
     */
    // createEventSourceObserver(): Observable<any[]> {
    //     return new Observable((observer: Observer<any[]>) => {
    //         const source =  new EventSource(API_URL);
    //
    //         source.onmessage = (event) => {
    //             console.log(event.data);
    //             // this.results.push(event.data);
    //             // this.results.sort();
    //             // this.ngZone.run(() => observer.next(this.results));
    //
    //         };
    //
    //         source.onopen = (event) => {
    //             if (this.endSearch) {
    //                 source.close();
    //                 this.ngZone.run(() => {
    //                     observer.complete();
    //                     this.showProgressBar = false;
    //                 })
    //             }
    //             this.endSearch = true;
    //         }
    //     });
    // }

    private getPECLines(pecId: any) {
        this.pecLines = [];
        this.subs.push(this.shared.getPECLines(pecId).subscribe(data => {
            this.pecLines = data.map(it => {
                let line = it.split('@');
                return {code: line[0], price: parseFloat(line[1]), grantedPrice: parseFloat(line[2])}
            });

            this.updatePaymentChangeModel({pecLines: this.pecLines});

            for (let i = 0; i < this.pecLines.length; i++) {
                let pecLine = this.pecLines[i];
                if (pecLine.code) {
                    const fg = this.fillForm(pecLine, i);
                    let patientPart = 100 * (pecLine.price - pecLine.grantedPrice) / pecLine.price;

                    setTimeout(() => {
                        fg.patchValue({
                            patientPart: patientPart,
                            organismPart: 100 - patientPart
                        });
                    }, 400);
                }
            }
            this.removeItem(0);
        }));
    }

    cancel() {
        if (!this.patientSelected && !this.examSaved) this.subs.push(this.service.deleteFiles(this.patientID).subscribe());
        if (this.examSaved) this.dialogRef.close(true)
        else this.dialogRef.close({isrId: this.savedIsrIdFromPacs})
    }

    onChangeTotalAmount(e) {
        let amount = parseFloat(e.target.value);
        setTimeout(() => {
            if (amount !== null) {
                let discount = this.paymentForm.get('discount').value;
                let percentage = discount * 100 / amount;
                this.paymentForm.get('discountPercentage').patchValue(percentage);
                this.paymentForm.get('enteredAmount').patchValue(amount - discount);
            }
        }, 400);
    }

    private updatePaymentChangeModelProcedures() {
        let exs = this.examForm.get('exams') as FormArray;
        let formGroups = exs['controls'] as any[];

        let items = formGroups.filter(it => it.value.procedureCode).map(it => {
            let exam = it.value;
            return {procedure: exam.procedureCode.code.trim(), price: exam.totalAmount, discount: exam.discount};
        });

        this.updatePaymentChangeModel({procedures: items});
    }

    private updateLinearExamPartitionFields(convention) {

        let formArray = this.examForm.get('exams') as FormArray;
        let formGroups = formArray['controls'] as FormGroup[];

        //Get convention exceptions
        let model = this.paymentChangeModel.getValue();
        let exceptions = model.conventionExceptions;

        if (!convention) {
            formGroups.forEach(it => this.buildPaymentItems(model, it));
            return;
        }

        let patientPart = parseFloat(this.splitter(convention, 2));
        let organismPart = parseFloat(this.splitter(convention, 3));

        formGroups.forEach(it => {
            it.patchValue({patientPart, organismPart});

            let procedureCode = it.value.procedureCode;
            if (procedureCode && model.admissionType === 'THIRD_PARTY_PAYMENT') {
                let exception = _find(exceptions, {procedureCode: procedureCode.code.trim()});
                console.log('exception found for : ', procedureCode.code)
                if (exception) {
                    it.patchValue({
                        patientPart: exception.patientPart,
                        organismPart: exception.organismPart
                    });
                }
            }

            this.buildPaymentItems(model, it)
        });
    }

    private buildPaymentItems(model: PaymentChangeModel, form: FormGroup) {
        let accessionNumber = form.get('accessionNumber').value;
        let procedureCode = form.get('procedureCode').value;
        let procedure = model.procedures.find(it => it.procedure === procedureCode.code.trim());

        let organismPrice = 0;
        if (model.conventionExceptions && model.conventionExceptions.length != 0) {
            let codeException = model.conventionExceptions.find(c => c.procedureCode == procedureCode.code.trim());

            if (codeException) organismPrice = Number((codeException.organismPart * (procedure.price - procedure.discount) / 100).toFixed(2));
            else if (model.convention) organismPrice = Number(parseFloat(this.splitter(model.convention, 3)) * (procedure.price - procedure.discount) / 100);
        } else if (model.convention) organismPrice = Number(parseFloat(this.splitter(model.convention, 3)) * (procedure.price - procedure.discount) / 100);

        this.paymentItemsObject[accessionNumber] = {
            accessionNumber,
            procedureCode: procedureCode.description,
            price: procedure.price.toFixed(2),
            patientPartPrice: (procedure.price - organismPrice).toFixed(2),
            organismPartPrice: organismPrice.toFixed(2),
            discount: 0
        };

        this.paymentItems = Object.values(this.paymentItemsObject);
    }

    addBank() {
        assign(this.dialogData, {title: 'BANK'});
        this.subs.push(this.dialog.open(PathologyEditComponent, {data: this.dialogData, disableClose: true})
            .afterClosed()
            .subscribe(data => {
                if (data) {
                    this.subs.push(this.shared.createBank(data)
                        .subscribe(res => {
                            if (res && res.id) {
                                this.banks.push(res);
                                this.paymentForm.get('bank').patchValue(res);
                                this.snack.open('Nouvelle Banque enregistrée avec succès!', 'Ok', {duration: 2000})
                            }
                        }));
                }
            }));
    }

    printTicket(patientData, data, callback?: (a: any, b: any) => void) {
        let procedureCodes = data.exams.map(e => e.procedureCode.description).join(', ');
        let accessionNumbers = data.exams.map(e => e.accessionNumber).join(' ');
        let physician = this.referringPhysicianControl[0].value;
        let patientID = patientData.externalPatientID;
        let patientName = patientData.lastName + ' ' + patientData.firstName;
        let date = moment(data.exams[0].date).format('DD/MM/YYYY');

        let printable = new ThermalPrintModel(patientID, patientName, date, physician, accessionNumbers, procedureCodes);


        if (this.generalSetting.ticketPrintMode === 'CHROME') this.subs.push(this.service.printTicket(printable).subscribe(_ => {
            if (callback) callback(patientData, data);
        }));
        else {
            this.subs.push(this.service.printCupsTicket(printable).subscribe(ok => {
                if (ok['status'] !== 'ok') console.log('Cannot print the ticket');
                else {
                    this.snack.open('Printing ticket ...', '', {duration: 3000});
                    if (callback) callback(patientData, data);
                }
            }));
        }
    }

    printPaymentReceipt() {
        if (this.printMode == 'CHROME') {
            let snackBarRef = this.snack.open('Impression en cours...', '', {duration: 10000});
            this.subs.push(this.service.printPaymentReceipt(this.payment).subscribe(_ => snackBarRef.dismiss()));
        } else {
            this.subs.push(this.service.printCupsPaymentReceipt(this.payment).subscribe(ok => {
                if (ok['status'] !== 'ok') alert('Cannot print the receipt');
                else this.snack.open('Printing receipt ...', '', {duration: 3000});
            }));
        }
    }

    savePrintState(event: MatSlideToggleChange) {
        this.posEnabled = event.checked;
        this._localStorage.setItem('posEnabled', this.posEnabled);
    }

    private selectISR(isr: any) {
        if (isr && isr instanceof Appointment) {

            let organism = get(isr, 'patient.insurance.organismName');

            if (organism && !organism.includes('@')) organism += `@${this.organisms.find(it => it.name == organism).id}`;

            if (organism) this.subs.push(this.shared.getOrganismConventions(this.splitter(organism, 1)).subscribe(data => this.organismConventions = data));

            let startDate = get(isr, 'startDate');
            let startTime = get(isr, 'startTime');

            let referringPhysician = get(isr, 'referringPhysician');

            this.generateAccessionNumber();

            let form = this.examForm.get('exams')['controls'][0];

            form.patchValue({
                date: startDate,
                time: startTime,
                referringPhysician: referringPhysician || {id: ''},
                accessionNumber: this.accessionNumber,
                notes: this.data.patient ? get(this.data.patient, 'medicalHistory.additionalPatientHistory') : ''
            });

            if (referringPhysician !== null) this.referringPhysicianControl[0].patchValue(referringPhysician.fullName);

            if (isr.appointmentReason) {
                this.subs.push(this.shared
                    .getProcedureCodeById(isr.appointmentReasonId)
                    .subscribe(code => {
                        if (code) this.selectProcedure(form, code, 0);
                    }));
            }

        } else if (isr) {
            let order = get(isr, 'imagingOrder');
            let organism = get(isr, 'patient.insurance.organismName');

            if (organism && !organism.includes('@')) organism += `@${this.organisms.find(it => it.name == organism).id}`;

            if (organism) this.subs.push(this.shared.getOrganismConventions(this.splitter(organism, 1)).subscribe(data => this.organismConventions = data));

            this.singleReport = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].singleReport');
            this.workflowItemToUpdate = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].workflowItem');
            this.paymentItemToUpdate = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].paymentItem');
            this.tariffId = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].tariffId');


            if (this.paymentItemToUpdate) {
                this.convType = this.paymentItemToUpdate['paymentType'];
                if (this.convType === 'PEC' && organism) this.findOrganismPECs(this.splitter(organism, 1));
            }

            let code = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].procedureCode');

            let startDate = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].scheduledProcedureStepStartDate');
            let startTime = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].scheduledProcedureStepStartTime');

            this.accessionNumber = get(isr, 'accessionNumber');

            let form = this.examForm.get('exams')['controls'][0];

            form.patchValue({
                orderId: order.id,
                reasonForExam: get(order, 'reasonForExam') || {id: ''},
                priority: get(order, 'priority') || this.priorities.find(it => it.value === this.defaultValues.defaultPriority) || this.priorities[2],
                performingPhysician: this.performingPhysicians[0] || {id: ''},
                date: startDate || get(order, 'requestedDate'),
                time: startTime || get(order, 'requestedTime'),
                referringPhysician: get(order, 'referringPhysician') || {id: ''},
                accessionNumber: this.accessionNumber,
                notes: this.data.patient ? get(this.data.patient, 'medicalHistory.additionalPatientHistory') : '',
                paymentType: this.paymentItemToUpdate ? this.paymentItemToUpdate['paymentType'] : '',
                patientPart: this.paymentItemToUpdate ? this.paymentItemToUpdate['patientPart'] : '',
                organismPart: this.paymentItemToUpdate ? this.paymentItemToUpdate['organismPart'] : '',
                pecId: this.paymentItemToUpdate ? this.paymentItemToUpdate['pecId'] : '',
                pecRef: this.paymentItemToUpdate ? this.paymentItemToUpdate['pecRef'] : '',
                totalAmount: this.paymentItemToUpdate ? this.paymentItemToUpdate['totalAmount'] : 0,
                discount: this.paymentItemToUpdate ? this.paymentItemToUpdate['discount'] : 0,
                percentageDiscount: this.paymentItemToUpdate ? this.paymentItemToUpdate['percentageDiscount'] : 0,
            });

            let p = get(order, 'referringPhysician');
            if (p !== null) this.referringPhysicianControl[0].patchValue(p.fullName);
            if (code) this.selectProcedure(form, code, 0);
        }
    }

    private generateAccessionNumber() {
        this.subs.push(this.shared.generateAccessionNumber().subscribe(res => {
            this.accessionNumber = res.id;
            this.examForm.get('exams')['controls'][0].get('accessionNumber').patchValue(res.id);
        }));
    }

    enterLinearDiscount(fg: FormGroup, e: KeyboardEvent) {
        let discount = parseFloat(e.target['value'] || '0');
        let total = fg.get('totalAmount').value;
        let percentageDiscount = 0;
        if (total && discount) percentageDiscount = discount * 100 / total;
        fg.get('percentageDiscount').patchValue(percentageDiscount);

        this.updatePaymentChangeModelLinearField(fg, 'discount', discount);
    }

    private updatePaymentChangeModelLinearField(fg: FormGroup, fieldName: string, fieldValue: any) {
        let model = this.paymentChangeModel.getValue();
        let items = model.procedures.map(it => {
            if (it.procedure === fg.get('procedureCode').value.code?.trim()) it[fieldName] = fieldValue;
            return it;
        });

        this.updatePaymentChangeModel({procedures: items});
    }


    enterLinearTotalAmount(fg: FormGroup, e: KeyboardEvent) {
        let total = parseFloat(e.target['value']);
        let percentage = fg.get('percentageDiscount').value || 0;

        fg.get('discount').patchValue(total * percentage / 100);
        this.updatePaymentChangeModelLinearField(fg, 'price', total);

    }

    enterLinearPercentageDiscount(fg: FormGroup, e: KeyboardEvent) {
        let percentage = parseFloat(e.target['value']);
        let total = fg.get('totalAmount').value;

        let discount = 0;
        if (total && percentage) discount = total * percentage / 100;

        fg.get('discount').patchValue(discount);
        this.updatePaymentChangeModelLinearField(fg, 'discount', discount);
    }

    changeTariff(selection: MatSelectChange) {
        this.updatePaymentChangeModel({tariffId: selection.value});
        this.subs.push(this.shared.getTariffLines(selection.value).subscribe(data => {
            if (data) {
                let tariffLines = data.map(it => {
                    let line = it.split('@');
                    return {code: line[0], price: Number(line[1])}
                });
                this.tariffLines = tariffLines;

                this.updatePaymentChangeModel({tariffLines});
            }
        }));
    }

    upperCase(event: any, field: string) {
        let name = event.target.value;
        this.patientForm.get(field).patchValue(name.toUpperCase())
    }

    capitalize(event1: any, field: string) {
        let name = event1.target.value;
        this.patientForm.get(field).patchValue(name.charAt(0).toUpperCase() + name.substring(1))
    }

    private selectPacsData(queryParam) {
        if (queryParam) {
            let pn = queryParam.patientName, fn = '', ln = '';
            if (pn) {
                let pnSplit = pn.replace(/\^/g, '@').trim().split('@');
                ln = pnSplit[0] || 'Inconnu';
                fn = pnSplit[1] || 'Inconnu';
            }
            this.patientForm.patchValue({
                firstName: fn,
                lastName: ln,
                externalPatientID: queryParam.patientID.split(' ').join('')//.replace(/([.\-:])/g, '')
            });

            if (queryParam.patientSex && ['M', 'F', 'O'].includes(queryParam.patientSex.toUpperCase())) {
                let sex = queryParam.patientSex;
                if (sex == 'O') sex = 'U'
                let gender = this.genders.find(g => g.value === sex);
                this.patientForm.get('demographic.gender').patchValue(gender);
            } else this.patientForm.get('demographic.gender').patchValue(this.genders.find(g => g.value === 'U'));

            let form = this.examForm.get('exams')['controls'][0];
            form.get('accessionNumber').patchValue(queryParam.accessionNumber);
            form.get('date').patchValue(moment(queryParam.studyDate).utc(true));
            if (queryParam.studyTime) form.get('time').patchValue(moment(queryParam.studyTime.substr(0, 6), 'HHmmss').utc(true).format('HH:mm'));

            console.log(queryParam.studyDescription, queryParam.modalitiesInStudy);

            this.subs.push(this.shared.findProcedureCode(queryParam.studyDescription, queryParam.modalitiesInStudy).subscribe(res => this.selectProcedure(form, res, 0)));
        }
    }

    private updatePayment(model: PaymentChangeModel) {
        let payment = this.paymentForm.getRawValue() as Payment;
        payment.discount = model.globalDiscount;


        let patientDue = model.procedures.map(it => {
            if (model.conventionExceptions && model.conventionExceptions.length != 0) {
                let codeException = model.conventionExceptions.find(c => c.procedureCode == it.procedure);

                if (codeException) return {price: Number((codeException.patientPart * (it.price - it.discount) / 100).toFixed(2))};
                else if (model.convention) return {price: Number(parseFloat(this.splitter(model.convention, 2)) * (it.price - it.discount) / 100)}
            } else if (model.convention) return {price: Number(parseFloat(this.splitter(model.convention, 2)) * (it.price - it.discount) / 100)}

            return {price: it.price - it.discount};
        });

        let patientPart = patientDue.map(it => it.price).reduce((a, c) => a + c, 0);

        payment.baseAmount = model.procedures.map(it => it.price).reduce((p, c) => p + c, 0);
        payment.due = model.procedures.map(it => it.price - it.discount).reduce((p, c) => p + c, 0);
        if (payment.discount > payment.due) payment.discount = payment.due;

        payment.totalAfterDiscount = payment.due - payment.discount;
        payment.enteredAmount = model.changeAmount ? model.enteredAmount : payment.totalAfterDiscount;

        payment.patientPart = Number(patientPart.toFixed(2))//Number((model.convention ? parseFloat(this.splitter(model.convention, 2)) * payment.totalAfterDiscount / 100 : payment.totalAfterDiscount).toFixed(2));
        payment.organismPart = Number((payment.totalAfterDiscount - payment.patientPart).toFixed(2))//Number((model.convention ? parseFloat(this.splitter(model.convention, 3)) * payment.totalAfterDiscount / 100 : payment.totalAfterDiscount - payment.patientPart).toFixed(2));

        if (payment.organismPart < 0) payment.organismPart = 0;

        payment.discountPercentage = Number((payment.discount * 100 / payment.due).toFixed(2));

        if (model.admissionType === 'PEC' && model.pecLines) {
            const totalPrice = model.pecLines.map(it => it.price).reduce((p, c) => p + c, 0);
            const totalGranted = model.pecLines.map(it => it.grantedPrice).reduce((p, c) => p + c, 0);

            payment.patientPart = Number((totalPrice - totalGranted).toFixed(2));
            payment.organismPart = Number(totalGranted.toFixed(2));


            if (payment.discount > payment.patientPart) {
                payment.discount = Number(payment.patientPart.toFixed(2));
                payment.patientPart = 0;
            }

            payment.patientPart -= payment.discount;
            if (payment.patientPart < 0) payment.patientPart = 0;

            payment.discountPercentage = Number((payment.discount * 100 / payment.patientPart).toFixed(2));
        }

        if (model.admissionType === 'THIRD_PARTY_PAYMENT') payment.enteredAmount = Number(payment.patientPart.toFixed(2));

        // Calculate left amount
        this.leftAmount = Number((payment.totalAfterDiscount - payment.enteredAmount).toFixed(2));
        if (this.leftAmount < 0) {
            payment.enteredAmount += this.leftAmount;
            this.leftAmount = 0;
        }

        this.paymentForm.patchValue(payment);

        this.paymentForm.get('due').disable();
        this.paymentForm.get('totalAfterDiscount').disable();
        this.paymentForm.get('patientPart').disable();
        this.paymentForm.get('organismPart').disable();
    }

    findReferringPhysician(item: any, i: number) {
        this.subs.push(this.dialog.open(ReferringPhysiciansSearchComponent, {
            minWidth: '600px',
            minHeight: '60vh',
            disableClose: true
        }).afterClosed().subscribe(physician => {
            if (physician) {
                this.filteredReferringPhysicians[i].push(physician);
                item.patchValue({referringPhysician: physician});
                this.referringPhysicianControl.forEach(it => it.patchValue(physician.fullName));
            }
        }))
    }

    private setDefaultValues(defaultValues: DefaultValues) {
        let patientClass = this.patientClasses.find(it => it.value === defaultValues.defaultPatientClass);
        let gender = this.genders.find(it => it.value === defaultValues.defaultGender);
        let title = this.titles.find(it => it.value === defaultValues.defaultTitle);
        let priority = this.priorities.find(it => it.value === defaultValues.defaultPriority);
        let technician = this.technicians.find(it => it.id === defaultValues.defaultTechnician);
        let performingPhysician = this.technicians.find(it => it.id === defaultValues.defaultPerformingPhysician);
        let confidentiality = this.confidentialities.find(it => it.value === defaultValues.defaultConfidentiality);
        let debiter = DEBITERS.find(it => it === defaultValues.defaultPaymentModality);
        let relationWithInsured = ['HIMSELF', 'PARTNER', 'CHILD'].find(it => it === defaultValues.defaultRelationWithInsured);

        this.patientForm.patchValue({title, patientClass, debiter});
        this.patientForm.get('insurance.relationWithInsured').patchValue(relationWithInsured);

        this.patientForm.get('demographic').patchValue({confidentiality, gender});

        let form = this.examForm.get('exams')['controls'][0];
        form.patchValue({technician, performingPhysician, priority});
    }
}
