import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import * as SockJS from 'sockjs-client';
import {Stomp, StompHeaders} from '@stomp/stompjs';
import {EXT_WS_URL, WS_URL} from './urls';
import {WsMessage} from './model';

@Injectable()
export class WsService {
    private _topicSubject = new Subject<{ topic: string, response: any }>();
    private _externalTopicSubject = new Subject<{ topic: string, response: any }>();

    public chatUpdate: Subject<WsMessage> = new Subject<WsMessage>();
    public soundPlayer: Subject<boolean> = new Subject<boolean>();

    constructor() {
    }

    observeTopic(topic: string): Subject<any> {
        const sockjs = () => new SockJS(WS_URL, '',  {'Authorization': `Bearer ${localStorage.getItem('access_token')}`});
        const stompClient = Stomp.over(sockjs);

        stompClient.debug = () => {
        };

        let self = this;
        stompClient.connect({'Authorization':`Bearer ${localStorage.getItem('access_token')}`}, () => {
                stompClient.subscribe(`/topic/${topic}`, (message: any) => {
                    if (message.body) self._topicSubject.next({topic: topic, response: message.body});
                }, {id: topic.replaceAll('/', '-')});
            },
            // On Stomp Error
            err => {
                console.log('On Stomp Error: ', err);
            },
            // On Stomp Close
            () => {
                console.log('Connection closed');
                setTimeout(() => self.observeTopic(topic), 10000);
            });

        return this._topicSubject;
    }

    observeExternalTopic(topic: string): Subject<any> {
        const sockjs = () => new SockJS(EXT_WS_URL);
        const stompClient = Stomp.over(sockjs);
        stompClient.debug = () => {
        };

        let self = this;
        stompClient.connect({'Authorization':`Bearer ${localStorage.getItem('access_token')}`}, () => {
                stompClient.subscribe(`/topic/${topic}`, (message: any) => {
                    if (message.body) self._externalTopicSubject.next({topic: topic, response: message.body});
                }, {id: topic.replaceAll('/', '-')});
            },
            // On Stomp Error
            err => {
                console.log('On Stomp Error: ', err);
            },
            // On Stomp Close
            () => {
                console.log('Connection closed');
                setTimeout(() => self.observeTopic(topic), 10000);
            });

        return this._externalTopicSubject;
    }
}
