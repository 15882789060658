import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'ft-loader',
  templateUrl: './loader.component.html',
  styles: [`
    .loading {
      top: 0px;
      height: 100%;
      width: 100%;
      z-index: 2;
      position: fixed;
      bottom: 0;
      left: 0;
      background: rgba(255, 241, 242, 0.84);
    }
  `]
})
export class LoaderComponent implements OnChanges {
  @Input() while: boolean | any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['while'] && changes['while'].currentValue)
      this.while = changes['while'].currentValue;
  }

}
