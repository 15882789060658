import * as moment from 'moment';
import {Moment} from 'moment';

export const YYYYMMDD: string = 'YYYYMMDD';

export function month_from_str(str: string): number {
    if (!str) return 1;
    if (str.toLowerCase().startsWith('jan')) return 1;
    if (str.toLowerCase().startsWith('fe') || str.toLowerCase().startsWith('fé')) return 2;
    if (str.toLowerCase().startsWith('mar')) return 3;
    if (str.toLowerCase().startsWith('av') || str.toLowerCase().startsWith('ap')) return 4;
    if (str.toLowerCase().startsWith('mai') || str.toLowerCase().startsWith('may')) return 5;
    if (str.toLowerCase().startsWith('jun') || str.toLowerCase().startsWith('juin')) return 6;
    if (str.toLowerCase().startsWith('jul') || str.toLowerCase().startsWith('juil')) return 7;
    if (str.toLowerCase().startsWith('ao') || str.toLowerCase().startsWith('au')) return 8;
    if (str.toLowerCase().startsWith('sep')) return 9;
    if (str.toLowerCase().startsWith('oc')) return 10;
    if (str.toLowerCase().startsWith('no')) return 11;
    if (str.toLowerCase().startsWith('de')) return 12;
}

export function formatTimer(timer: number): string {
    let minutes: number, seconds: number, milliseconds: number;

    milliseconds = Math.floor((timer % 100) / 10);
    seconds = Math.floor(timer / 100) % 60;
    minutes = Math.floor(timer / 6000);

    return `${minutes.toString().length === 1 ? '0' + minutes : minutes}:${seconds.toString().length === 1 ? '0' + seconds : seconds}:${milliseconds}`
}

export class DateUtils {

    static formatRange(start: Moment, end: Moment, lang: string): string {
        let s, e;

        if (start.get('y') === end.get('y')) {
            if (start.get('month') === end.get('month')) {
                s = start.format(lang == 'en' ? 'MMMM D' : 'dddd D');
                e = end.format(lang == 'en' ? 'D, GGGG' : 'LLLL')
            } else {
                s = start.format( lang == 'en' ? 'MMMM D': 'D MMMM');
                e = end.format('LL')
            }
        } else {
            s = start.format('LL');
            e = end.format('LL');
        }
        return `${s} - ${e}`
    }

    static PeriodInMinutes(period: string): number {

        let todayPastMinutes = moment().hour()*60;

        switch (period) {
            case 'TODAY':
                return todayPastMinutes;
            case 'YESTERDAY':
                return todayPastMinutes + 24 * 60;
            case '3D':
                return todayPastMinutes + 3 * 24 * 60;
            case '1W':
                return todayPastMinutes + 7 * 24 * 60;
            case '1M':
                return todayPastMinutes + 30 * 24 * 60;
            default:
                return todayPastMinutes
        }
    }

    static PeriodDateRange(period: string): { startDate: Moment, endDate: Moment } {

        let start = moment().format(YYYYMMDD);
        let end = start;

        let startDate = moment(start, YYYYMMDD),
            endDate = moment(end, YYYYMMDD);

        switch (period) {
            case 'TODAY':
                return {startDate, endDate};
            case 'YESTERDAY':
                start = moment().subtract(1, 'd').format(YYYYMMDD);
                startDate = moment(start, YYYYMMDD);
                endDate = moment(start, YYYYMMDD);
                return {startDate, endDate};
            case '3D':
                start = moment().subtract(2, 'd').format(YYYYMMDD);
                startDate = moment(start, YYYYMMDD);
                endDate = moment(end, YYYYMMDD);
                return {startDate, endDate};
            case '1W':
                start = moment().subtract(6, 'd').format(YYYYMMDD);
                startDate = moment(start, YYYYMMDD);
                endDate = moment(end, YYYYMMDD);
                return {startDate, endDate};
            case '1W+':
                start = moment().format(YYYYMMDD);
                startDate = moment(start, YYYYMMDD);
                endDate = moment(end, YYYYMMDD).add(7, 'd');
                return {startDate, endDate};
            case '2W':
                start = moment().subtract(13, 'd').format(YYYYMMDD);
                startDate = moment(start, YYYYMMDD);
                endDate = moment(end, YYYYMMDD);
                return {startDate, endDate};
            case '1M':
                start = moment().subtract(1, 'month').format(YYYYMMDD);
                startDate = moment(start, YYYYMMDD);
                endDate = moment(end, YYYYMMDD);
                return {startDate, endDate};
            case '1M+':
                start = moment().format(YYYYMMDD);
                startDate = moment(start, YYYYMMDD);
                endDate = moment(end, YYYYMMDD).add(1, 'month');
                return {startDate, endDate};
            case '3M':
                start = moment().subtract(3, 'month').format(YYYYMMDD);
                startDate = moment(start, YYYYMMDD);
                endDate = moment(end, YYYYMMDD);
                return {startDate, endDate};
            case '3M+':
                start = moment().format(YYYYMMDD);
                startDate = moment(start, YYYYMMDD);
                endDate = moment(end, YYYYMMDD).add(3, 'month');
                return {startDate, endDate};
            case '6M':
                start = moment().subtract(6, 'month').format(YYYYMMDD);
                startDate = moment(start, YYYYMMDD);
                endDate = moment(end, YYYYMMDD);
                return {startDate, endDate};
            case '1Y':
                start = moment().subtract(1, 'year').format(YYYYMMDD);
                startDate = moment(start, YYYYMMDD);
                endDate = moment(end, YYYYMMDD);
                return {startDate, endDate};
            case 'OT':
                start = moment().subtract(1, 'year').format(YYYYMMDD);
                end = moment().add(10, 'd').format(YYYYMMDD);
                startDate = moment(start, YYYYMMDD);
                endDate = moment(end, YYYYMMDD);
                return {startDate, endDate};
            default:
                return {startDate, endDate};
        }
    }

}
