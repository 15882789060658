export const R_DISPLAY_COLS = [
    {
        label: 'id',
        header: 'ID',
        value: 'id',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: true,
        searchable: true,
        required: false
    }, {
        label: 'patientID',
        header: 'PATIENT_ID',
        value: 'patientID',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: true,
        searchable: true,
        required: false
    }, {
        label: 'patientName',
        header: 'FULL_NAME',
        value: 'patientName',
        type: 'string',
        unit: 'FULL_NAME',
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'performerName_lastName',
        header: 'RADIOLOGIST',
        value: 'performerName.fullName',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: false,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'completion',
        header: 'COMPLETIONDATE',
        value: 'expectedCompletionDateTime',
        type: 'string',
        unit: 'date',
        defaultValue: [null],
        sortable: true,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'priority_value',
        header: 'PRIORITY',
        value: 'priority.value',
        type: 'string',
        unit: 'prio',
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'scheduledProcedureStepStartDateTime',
        header: 'EXAM_DATE',
        value: 'scheduledProcedureStepStartDateTime',
        type: 'string',
        unit: 'date',
        defaultValue: [null],
        sortable: true,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'scheduledStationName',
        header: 'SCHEDULEDSTATION',
        value: 'scheduledStationName',
        type: 'string',
        unit: null,
        defaultValue: [null],
        sortable: true,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'reportingTaskStatus_value',
        header: 'TASKSTATUS',
        value: 'reportingTaskStatus.description',
        type: 'string',
        unit: 'status',
        defaultValue: '-',
        sortable: false,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'reportingTaskType_description',
        header: 'TASK_TYPE',
        value: 'reportingTaskType.description',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: false,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'pathology',
        header: 'PATHOLOGY',
        value: 'pathology',
        type: 'string',
        unit: 'pathology',
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'priority_color',
        header: 'PRIORITY_COLOR',
        value: 'priority.color',
        type: 'color',
        unit: 'color',
        defaultValue: '#757575',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'reportingStatus',
        header: 'REPORTING_STATUS',
        value: 'reportingStatus',
        type: 'status',
        unit: 'status',
        defaultValue: 'WAITING',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'assigningComment',
        header: 'NOTES',
        value: 'assigningComment',
        type: 'comment',
        unit: 'comment',
        defaultValue: '',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'paymentStatus',
        header: 'PAYMENT_STATUS',
        value: 'paymentStatus',
        type: 'payment',
        unit: 'payment',
        defaultValue: 'NOT_PAID',
        sortable: false,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'procedureCodes',
        header: 'EXAM',
        value: 'procedureCodes',
        type: null,
        unit: null,
        defaultValue: '-',
        sortable: false,
        hidden: false,
        searchable: true,
        required: false
    }, {
        label: 'patientMedicalHistory',
        header: 'MEDICAL_HISTORY',
        value: 'patientMedicalHistory',
        type: null,
        unit: 'history',
        defaultValue: '-',
        sortable: false,
        hidden: false,
        searchable: true,
        required: false
    }
];

export const R_TABLE_COLS = [
    'scheduledProcedureStepStartDateTime',
    'patientName',
    'patientID',
    'procedureCodes',
    'performerName_lastName',
    'priority_value',
    'assigningComment',
    'patientMedicalHistory',
    'reportingStatus',
    'paymentStatus',
    'pathology',
    'action'
];
