<mat-toolbar fxLayout="row">
    <span style="font-weight: bold;">{{'ADD_APPOINTMENT' | translate}}</span>
    <span fxFlex></span>
    <button [matDialogClose]="null" mat-icon-button tabindex="-1">
        <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
    </button>
</mat-toolbar>
<div [formGroup]="appointmentForm" matDialogContent>
    <div [formGroup]="patientForm" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">

        <mat-form-field appearance="standard" formGroupName="title" tabindex="-1">
            <mat-label>{{'TITLE' | translate}}</mat-label>
            <mat-select [placeholder]="'TITLE' | translate" formControlName="id" tabindex="-1">
                <mat-option *ngFor="let title of titles" [value]="title.id">{{title.value}}</mat-option>
            </mat-select>
            <button (click)="$event.stopImmediatePropagation(); addTitle()" mat-icon-button matSuffix tabindex="-1">
                <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex>
            <mat-label>{{'LAST_NAME' | translate}}</mat-label>
            <input (blur)="upperCase($event, 'lastName')" [placeholder]="'LAST_NAME' | translate"
                   formControlName="lastName" matInput required type="text">
        </mat-form-field>

        <mat-form-field appearance="standard">
            <mat-label>{{'FIRST_NAME' | translate}}</mat-label>
            <input (blur)="capitalize($event, 'firstName')" [placeholder]="'FIRST_NAME' | translate"
                   formControlName="firstName" matInput required>
        </mat-form-field>


        <button (click)="findPatient()" *ngIf="!data.patient" class="find-patient" mat-icon-button>
            <mat-icon fontIcon="mdi-magnify" fontSet="mdi" style="color: #FFFFFF;"></mat-icon>
            <span *ngIf="numOfDuplicatePatients && !patientSelected"
                  class="badge">{{numOfDuplicatePatients}}</span>
        </button>

    </div>

    <div [formGroup]="demographicForm" fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="4px">
        <mat-form-field appearance="standard" fxFlex="180px">
            <mat-label>{{'DATEOFBIRTH' | translate}}</mat-label>
            <input (dateInput)="changeAge()" [matDatepicker]="picker" [max]="minDate" formControlName="dateOfBirth"
                   matInput
                   placeholder="{{'DATEOFBIRTH' | translate}}">
            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker (closed)="changeAge()"></mat-datepicker>
        </mat-form-field>

        <div [formGroup]="ageForm" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="4px">
            <mat-form-field appearance="standard" fxFlex="80px">
                <mat-label>{{'AGE_YEARS' | translate}}</mat-label>
                <input (keyup)="changeDate()" formControlName="years" matInput
                       placeholder="{{'AGE_YEARS' | translate}}"
                       type="number">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex="80px">
                <mat-label>{{'AGE_MONTHS' | translate}}</mat-label>
                <input (keyup)="changeDate()" formControlName="months" matInput
                       placeholder="{{'AGE_MONTHS' | translate}}"
                       type="number">
            </mat-form-field>
        </div>

        <div formGroupName="gender" fxLayout="column">
            <div [style.color]="demographicForm.get('gender').invalid ? '#cc4040':''"
                 class="label-option">{{'GENDER' | translate}}*
            </div>

            <mat-radio-group formControlName="id" fxLayout="row" fxLayoutAlign="start end">
                <mat-radio-button *ngFor="let option of genders" [value]="option.id" fxFlex required>
                    {{option.value == 'U' ? ('UNKNOWN' | translate) : option.value}}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <span fxFlex></span>


        <div formGroupName="confidentiality" fxLayout="column" fxLayoutAlign="space-between">
            <div class="label-option">{{'CONFIDENTIALITY' | translate}}</div>

            <mat-radio-group formControlName="id" fxLayout="row" fxLayoutAlign="start end">
                <mat-radio-button *ngFor="let option of confidentialities" [value]="option.id" fxFlex>
                    {{option.description}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div [formGroup]="patientForm" fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="4px">
        <mat-form-field appearance="standard">
            <mat-label>{{'CIN' | translate}}</mat-label>
            <input [placeholder]="'CIN' | translate" formControlName="cin" matInput>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>{{'PHONE' | translate}}</mat-label>
            <input [placeholder]="'PHONE' | translate" formControlName="phone" matInput type="tel">
        </mat-form-field>
        <mat-form-field appearance="standard" fxFlex>
            <mat-label>{{'EMAIL' | translate}}</mat-label>
            <input [placeholder]="'EMAIL' | translate" formControlName="email" matInput type="email">
        </mat-form-field>
    </div>

    <mat-divider style="border-top: 3px dotted #B0BEC5;margin: 12px 20%"></mat-divider>

    <div fxLayout="row" fxLayoutGap="8px">
        <mat-form-field appearance="standard" class="auto-comp" fxFlex>
            <mat-label>{{'APT_REASON' | translate}}</mat-label>
            <input [formControl]="procedureCodeControl" [matAutocomplete]="modelList"
                   [placeholder]="'APT_REASON' | translate"
                   matInput
                   required>
            <mat-autocomplete #modelList="matAutocomplete"
                              (opened)="selected = null"
                              (optionSelected)="onChangeProcedureCode($event)"
                              [autoActiveFirstOption]="true">
                <mat-option *ngFor="let pc of filteredProcedures" [value]="pc">
                    {{pc.description}}
                </mat-option>
            </mat-autocomplete>
            <button (click)="$event.stopImmediatePropagation();searchProcedureCode()" mat-icon-button
                    matSuffix>
                <mat-icon fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex>
            <mat-label>{{'REFERRING_PHYSICIAN' | translate}}</mat-label>
            <input [formControl]="referringPhysicianControl" [matAutocomplete]="refPhyList"
                   [placeholder]="'REFERRING_PHYSICIAN' | translate"
                   matInput>
            <mat-autocomplete #refPhyList="matAutocomplete"
                              (optionSelected)="onChangeRefPhy($event)"
                              [autoActiveFirstOption]="true">
                <ng-container>
                    <mat-option *ngFor="let user of filteredReferringPhysicians"
                                [value]="user" class="option-line">
                        <div [style.fontSize.px]="12" [style.lineHeight.px]="14" fxLayout="column"
                             fxLayoutAlign="center start">
                            <span class="option-line-1" fxFlex> {{ user.fullName }} </span>
                            <span *ngIf="user.codeAnam" class="option-line-2"
                                  fxFlex> Code: {{ user.codeAnam || '-'}} </span>
                        </div>
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
            <button (click)="$event.stopImmediatePropagation(); addReferringPhysician()" mat-icon-button
                    matSuffix>
                <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
            </button>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-form-field appearance="standard" formGroupName="aet" fxFlex>
            <mat-label>{{'AET' | translate}}</mat-label>
            <mat-select [placeholder]="'AET' | translate" formControlName="id">
                <mat-option *ngFor="let title of aetTitles" [value]="title.id">{{title.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard" formGroupName="physician" fxFlex>
            <mat-label>{{'PERFORMING_PHYSICIAN' | translate}}</mat-label>
            <mat-select [placeholder]="'PERFORMING_PHYSICIAN' | translate" formControlName="id">
                <mat-option *ngFor="let item of performingPhysicians" [value]="item.id">{{item.fullName}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-form-field appearance="standard" fxFlex="180px">
            <mat-label>{{'DATE' | translate}}</mat-label>
            <input [matDatepicker]="myDatepicker" [min]="minDate" [placeholder]="'DATE' | translate"
                   formControlName="startDate"
                   matInput required>
            <mat-datepicker-toggle [for]="myDatepicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex="108px">
            <mat-label>{{'TIME' | translate}}</mat-label>
            <input [placeholder]="'TIME' | translate" formControlName="startTime" matInput type="time">
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex="100px">
            <mat-label>{{'DURATION' | translate}}</mat-label>
            <input [placeholder]="'DURATION' | translate" formControlName="duration" inputmode="numeric" matInput
                   type="number">
        </mat-form-field>
    </div>


    <mat-form-field [style.width.%]="100">
        <mat-label>{{'COMMENTS' | translate}}</mat-label>
        <textarea [placeholder]="'COMMENTS' | translate" formControlName="comments" matInput name="comments"
                  rows="10"></textarea>
    </mat-form-field>


</div>
<div [align]="'end'" matDialogActions>
    <button (click)="createAppointment()"
            [disabled]="clicked || procedureCodeControl.invalid || appointmentForm.invalid || patientForm.invalid || demographicForm.invalid"
            color="primary" mat-button>{{'SAVE' | translate}}</button>
    <button [matDialogClose]="null" color="accent" mat-button>{{'CLOSE' | translate}}</button>
</div>
