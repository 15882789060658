import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Observable, of} from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _authService: AuthService
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        this._authService.redirectUrl = state.url;
        if (this._authService.isLoggedIn()) {
            return of(true);
        } else {
            this._authService.redirectUrl = state.url;
            return !this._router.navigate(['/login']);
        }
    }
}
