import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FileElement} from '../../model/file-element';
import {assign, remove} from 'lodash';
import {DomSanitizer} from '@angular/platform-browser';
import * as $ from 'jquery';

@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit, OnDestroy {

    current: FileElement;
    images: FileElement[];

    @ViewChild('image', { static: true }) image: ElementRef;
    private kos: FileElement[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer, private dialogRef: MatDialogRef<ImageViewerComponent>) {
        this.images = this.data.images.map(elm => assign(elm, {
            url: this.data.fromPacs ? elm.url : `/upload-dir/${elm.uuid}.${elm.fileType.toLowerCase()}`
        }));
        this.current = assign(this.data.selected, {
            url: this.data.fromPacs ? this.data.selected.url : `/upload-dir/${this.data.selected.uuid}.${this.data.selected.fileType.toLowerCase()}`
        });

        document.addEventListener('wheel', (evt) => {
            if (evt.deltaY < 0) this.next();
            else this.previous();
        }, {
            capture: true,
            passive: true
        });
    }


    getOriginalImage(url: string): string {
        // if (url && this.data.fromPacs) {
        //     let split = url.split('/');
        //     split.pop();
        //     return split.join('/');
        // }
        return url;
    }

    ngOnInit() {
        this.navigateImages();
    }

    setKOS(file: FileElement) {
        file.fav = !file.fav;
        if (file.fav) {
            this.kos.push(file)
        } else {
            this.kos = remove(this.kos, val => val === file);
        }
    }

    public zoomin(): any {
        let myImg = this.image.nativeElement;
        let currWidth = myImg.clientWidth;
        if (currWidth === 2500) return false;
        else {
            myImg.style.width = (currWidth + 100) + 'px';
        }
    }

    public zoomout(): any {
        let myImg = this.image.nativeElement;
        let currWidth = myImg.clientWidth;
        if (currWidth === 100) return false;
        else {
            myImg.style.width = (currWidth - 100) + 'px';
        }
    }

    public previous() {
        let idx = this.images.indexOf(this.current);
        this.current = idx !== 0 ? this.images[idx - 1] : this.images[0]
    }

    public next() {
        let idx = this.images.indexOf(this.current);
        this.current = idx !== this.images.length - 1 ? this.images[idx + 1] : this.images[this.images.length - 1]
    }

    download() {
        let filename = this.current.uuid + '.' + this.current.fileType.toLowerCase();
        window.open(`/api/documents/download/${filename}`, '_blank');
    }

    private navigateImages() {
        $(document).keydown(eventObject => {
            switch (eventObject.key) {
                case 'ArrowLeft':
                    this.previous();
                    break;
                case 'ArrowRight':
                    this.next();
                    break;
                case 'Escape':
                    this.dialogRef.close(null);
                    break;
            }
        });
    }

    ngOnDestroy(): void {
        this.dialogRef.close(this.kos)
    }
}
