import {Component, EventEmitter, Input, isDevMode, OnChanges, Output, SimpleChanges} from '@angular/core';

import {sortBy} from 'lodash';
import {ReportingService} from '../../reporting/reporting.service';
import {FileElement, ImageViewerComponent} from '../file-explorer';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';

const upFolder = {
    index: 0,
    fav: false,
    title: '...',
    instanceUID: 0,
    url: '../../../assets/images/svg/019-folder-2.svg',
    isFolder: false
};

@Component({
    selector: 'app-image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnChanges {

    imageArray: any[] = [];
    @Input() id: string;
    @Output() onSetFavorite = new EventEmitter<FileElement[]>();

    @Input() studyInstanceUID: string;
    @Input() patientID: string;
    fileElements: FileElement[] = [];
    series: Observable<any>;
    studySeries: any;
    private folderIcon = `../../../assets/images/svg/017-folder.svg`;

    constructor(private service: ReportingService, private dialog: MatDialog) {
    }

    showImage(image) {
        if (image.folder) {
            let seriesPath = `${this.studySeries.name}/${image.name}`;
            this.getImages(seriesPath);
        } else if (image.name === '...') {
            this.getStudySeries();
        } else this.openImage(image)
    }

    openImage(element: FileElement) {
        this.dialog.open(ImageViewerComponent, {
            data: {
                images: this.fileElements.filter(f => f.name !== '...'),
                selected: element,
                fromPacs: true
            },
            minWidth: '100%',
            height: '100%',
            panelClass: 'image-viewer'
        }).afterClosed().subscribe(kos => this.onSetFavorite.emit(kos));
    }

    getImages(seriesPath: string) {
        this.imageArray = [];
        this.fileElements = [];
        this.service.getSeriesImages(seriesPath, this.patientID.trim()).subscribe(data => {
            this.imageArray.push(upFolder);
            let idx = 1;
            data.forEach(file => {
                this.imageArray.push({
                    index: idx++,
                    fav: false,
                    title: file.name,
                    instanceUID: file.name,
                    url: isDevMode() ? file.path.replace('images/', 'http://localhost:8000/') : file.path,
                    isFolder: false
                });
            });

            this.imageArray = sortBy(this.imageArray, 'instanceUID');
            this.imageArray.forEach(image => this.fileElements.push({
                name: image.title,
                url: image.url,
                folder: image.isFolder,
                uuid: image.instanceUID
            }))
        })
    }

    getInstances(seriesInstanceUID: string) {
        this.imageArray = [];
        this.fileElements = [];
        this.service.getSeriesInstances(seriesInstanceUID).subscribe(instances => {
            this.imageArray.push(upFolder);
            instances.forEach(instance => {
                this.imageArray.push({
                    index: instance.id,
                    fav: false,
                    title: instance.id,
                    instanceUID: instance.referencedSOPInstanceUID,
                    url: instance.retrieveURI,
                    isFolder: false
                });
            });

            this.imageArray = sortBy(this.imageArray, 'instanceUID');
            this.imageArray.forEach(image => this.fileElements.push({
                name: image.title,
                url: image.url,
                folder: image.isFolder,
                uuid: image.instanceUID
            }))
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['studyInstanceUID'].currentValue && changes['patientID'].currentValue) {
            this.studyInstanceUID = changes['studyInstanceUID'].currentValue;
            this.patientID = changes['patientID'].currentValue;
            this.getStudySeries()
        }
    }

    private getSeries() {
        this.imageArray = [];
        this.fileElements = [];
        this.service.getStudySeries(this.studyInstanceUID).subscribe(series => {
            series.forEach(serie => {
                this.imageArray.push({
                    index: serie.id,
                    fav: false,
                    title: serie.id,
                    instanceUID: serie.seriesInstanceUID,
                    url: this.folderIcon,
                    isFolder: true
                });
            });

            this.imageArray.forEach(image => this.fileElements.push({
                name: image.title,
                url: image.url,
                folder: image.isFolder,
                uuid: image.instanceUID
            }))
        })
    }

    getAllImages() {
        this.series = this.service.getSeriesImages(this.studyInstanceUID, this.patientID)
    }

    private getStudySeries() {
        this.imageArray = [];
        this.fileElements = [];
        this.service.getStudiesSeries(this.studyInstanceUID, this.patientID.trim()).subscribe(data => {
            this.studySeries = data;
            let idx = 1;
            data.files.forEach(file => {
                this.imageArray.push({
                    index: idx++,
                    fav: false,
                    title: file.name,
                    instanceUID: file.name,
                    url: this.folderIcon,
                    isFolder: true
                });
            });

            this.imageArray.forEach(image => this.fileElements.push({
                name: image.title,
                url: image.url,
                folder: image.isFolder,
                uuid: image.instanceUID
            }))
        })
    }

}
