<mat-drawer-container [hasBackdrop]="false" fxFill>
    <mat-drawer #drawer [mode]="'over'" [style.maxWidth.px]="360" [style.minHeight.%]="100" [style.minWidth.px]="300">

        <button (click)="drawer.toggle()" class="drawer-close-btn" color="accent" mat-icon-button>
            <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
        </button>

        <ng-container [ngSwitch]="panel">
            <ng-container *ngSwitchCase="'patient'">
                <mat-card class="reports-card">
                    <mat-toolbar class="report-toolbar" style="min-height: 0 !important;max-height: 0 !important;">
                    </mat-toolbar>
                    <mat-card-content *ngIf="patient" style="margin: 8px">

                            <div fxFill fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">

                                <div class="image-place" fxFlex="90px">
                                    <img [src]="getPatientImage()" alt="">
                                </div>
                                <div fxFlex fxLayout="column" fxLayoutAlign="space-between" style="height: 90px;">
                                    <div class="patient-name"><a
                                            [routerLink]="'/patients/details/'+patient.id">{{patient.patientName}}</a></div>
                                    <div>
                                        <span style="font-size: 12px;color: #888888;">{{patient.patientID}}</span>
                                    </div>

                                    <div class="alerts" fxFlex>
                                        {{patient.alerts || ('NO_ALERTS' | translate)}}
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
                                <span class="ft-badge-i">{{patient.patientSex}}</span>
                                <span class="ft-badge-i">{{patient.patientAge}} {{'Y' | translate}}</span>
                                <span class="ft-badge-i">{{patient.imc}} kg/m²</span>
                                <span class="ft-badge-i">{{patient.sc}} m²</span>
                                <span fxFlex></span>
                            </div>

                            <div *ngIf="patientDetails">

                                <div class="detail-title">{{'ADDITIONAL_PATIENT_HISTORY' | translate}}</div>
                                <div class="detail-content">
                                    {{patientDetails.medicalHistory.additionalPatientHistory || '-'}}
                                </div>
                                <div class="detail-title">{{'ALLERGIES' | translate}}</div>
                                <div class="detail-content">
                                    {{patientDetails.medicalHistory.allergies || '-'}}
                                </div>
                                <div class="detail-title">{{'SPECIALNEEDS' | translate}}</div>
                                <div class="detail-content">
                                    {{patientDetails.medicalHistory?.specialNeeds || '-'}}
                                </div>

                                <mat-divider></mat-divider>
                                <div class="detail-title">{{'CONTACT' | translate}}</div>
                                <div class="detail-content">
                                    <div class="contact-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                        <mat-icon fontIcon="mdi-phone" fontSet="mdi"></mat-icon>
                                        <span>{{patientDetails.phone || '-'}}</span></div>
                                    <div class="contact-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                        <mat-icon fontIcon="mdi-email" fontSet="mdi"></mat-icon>
                                        <span>{{patientDetails.email || '-'}}</span></div>
                                    <div class="contact-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                        <mat-icon fontIcon="mdi-google-maps" fontSet="mdi"></mat-icon>
                                        <span>{{patientDetails.address?.street}} {{patientDetails.address?.city}} {{patientDetails.address?.country}}</span>
                                    </div>
                                </div>

                            </div>
                        </mat-card-content>
                    </mat-card>
            </ng-container>
            <ng-container *ngSwitchCase="'report'">

                    <mat-card class="reports-card" fxLayout="column">
                        <mat-toolbar class="report-toolbar" fxFlex="nogrow">
                            <span>{{'CURRENT_REPORT' | translate}}</span>
                        </mat-toolbar>

                        <div *ngIf="selectedReport" style="padding: 8px 16px; overflow: auto;">
                            <mat-form-field appearance="standard" class="full-width">
                                <mat-label>{{'TEMPLATEMODEL' | translate}}</mat-label>
                                <input [formControl]="templateModelControl"
                                       [matAutocomplete]="modelList"
                                       [placeholder]="'TEMPLATEMODEL' | translate"
                                       matInput>
                                <mat-autocomplete #modelList="matAutocomplete" class="auto-complete"
                                                  (optionSelected)="onSelectTemplate($event)"
                                                  [autoActiveFirstOption]="true">
                                    <mat-option *ngFor="let model of filteredTemplateModels" [value]="model.name">
                                        {{model.name}}
                                    </mat-option>
                                </mat-autocomplete>
                                <button (click)="$event.stopImmediatePropagation();searchReportTemplate()" [disabled]="selectedReport.locked" mat-icon-button
                                        matSuffix>
                                    <mat-icon fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
                                </button>
                            </mat-form-field>

                            <mat-form-field appearance="standard" class="full-width">
                                <mat-label>{{'TECHNIQUE' | translate}}</mat-label>
                                <input [formControl]="techniqueControl"
                                       [matAutocomplete]="technique"
                                       [placeholder]="'TECHNIQUE' | translate"
                                       matInput>
                                <mat-autocomplete #technique="matAutocomplete"
                                                  (optionSelected)="onChangeTechnique($event)"
                                                  [autoActiveFirstOption]="true">
                                    <mat-option *ngFor="let technique of filteredTechniques" [value]="technique">
                                        <div fxLayout="row">
                                            <span> {{technique.value}}</span>
                                            <span fxFlex></span>
                                            <button (click)="$event.stopImmediatePropagation(); editTechnique(technique)"
                                                    mat-icon-button>
                                                <mat-icon [style.color]="'#2196f3'" fontIcon="mdi-pencil"
                                                          fontSet="mdi"></mat-icon>
                                            </button>
                                            <button (click)="$event.stopImmediatePropagation(); deleteTechnique(technique)"
                                                    mat-icon-button>
                                                <mat-icon [style.color]="'#ff4f6c'" fontIcon="mdi-delete"
                                                          fontSet="mdi"></mat-icon>
                                            </button>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                                <button (click)="$event.stopImmediatePropagation();addTechnique()" [disabled]="selectedReport.locked" mat-icon-button
                                        matSuffix>
                                    <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                                </button>
                            </mat-form-field>
                            <mat-form-field appearance="standard" class="full-width">
                                <mat-label>{{'PATHOLOGY' | translate}}</mat-label>
                                <mat-select (selectionChange)="selectPathology()" [formControl]="pathologyControl"
                                            [multiple]="true" [placeholder]="'PATHOLOGY' | translate">
                                    <mat-select-trigger>
                                        {{pathologyControl.value ? pathologyControl.value[0] : ''}}
                                        <span *ngIf="pathologyControl.value?.length > 1" class="additional-selection">
                                        (+{{pathologyControl.value.length - 1}} {{'OTHER1' |
                                            translate}}{{pathologyControl.value?.length <= 2 ? '' : 's'}}) </span>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let pathology of pathologies" [value]="pathology.value">
                                        {{pathology.value}}
                                    </mat-option>
                                </mat-select>

                                <button (click)="$event.stopImmediatePropagation();addPathology()" [disabled]="selectedReport.locked" mat-icon-button
                                        matSuffix>
                                    <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                                </button>
                            </mat-form-field>

                        </div>
                    </mat-card>

            </ng-container>
            <ng-container *ngSwitchCase="'record'">

                    <mat-card class="reports-card" fxLayout="column">
                        <mat-toolbar class="report-toolbar" fxFlex="nogrow">
                            <span>{{'DICTATIONS' | translate}}</span>
                        </mat-toolbar>
                        <mat-card-content fxFlex style="overflow: auto; padding: 0 8px;">
                            <ng-container *ngIf="selectedReport">
                                <mat-nav-list style="max-height: 120px;">
                                    <mat-list-item (click)="showDictation(item)" *ngFor="let item of dictations"
                                                   style="height: 26px;margin-bottom: 2px;">
                                        <mat-icon fontIcon="mdi-play-circle" fontSet="mdi" matListIcon></mat-icon>
                                        <h4 matLine>{{item.recordingDate | date:'dd/MM/yyyy HH:mm'}}</h4>
                                        <button (click)="$event.stopPropagation(); deleteDictation(item);" mat-icon-button>
                                            <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                                        </button>
                                    </mat-list-item>
                                    <mat-list-item *ngIf="dictations.length === 0" style="height: 26px;margin-bottom: 2px;">
                                        <h4 [style.color]="'grey'" matLine>{{'NO_ITEM' | translate}}</h4>
                                    </mat-list-item>
                                </mat-nav-list>
                            </ng-container>
                        </mat-card-content>
                    </mat-card>

            </ng-container>
            <ng-container *ngSwitchCase="'key-images'">

                    <mat-card class="reports-card" fxLayout="column">
                        <mat-toolbar class="report-toolbar" fxFlex="nogrow">
                            <span>{{'KEYIMAGES' | translate}}</span>
                        </mat-toolbar>
                        <mat-card-content fxFlex style="overflow: auto; padding: 0 8px;">
                            <div *ngIf="reportingTask">
                                <mat-grid-list [cols]="3" rowHeight="1:1">
                                    <mat-grid-tile *ngFor="let image of selectedImages">
                                        <mat-grid-tile-header fxLayout="row" style="height: 24px; padding: 0 !important;">
                                            <span fxFlex></span>
                                            <button (click)="deleteKos(image)" color="accent" mat-icon-button>
                                                <mat-icon fontIcon="mdi-delete" fontSet="mdi" style="color: inherit"></mat-icon>
                                            </button>
                                        </mat-grid-tile-header>
                                        <img (click)="showImage(image)" [src]="image.url" height="100%" style="cursor:pointer;"
                                             width="100%">
                                    </mat-grid-tile>
                                </mat-grid-list>
                                <div *ngIf="selectedImages.length == 0"
                                     style="color: #666; padding: 12px;">{{'NO_ITEM' | translate}}</div>
                            </div>
                        </mat-card-content>

                    </mat-card>

            </ng-container>
            <ng-container *ngSwitchCase="'history'">

                    <mat-card class="reports-card" fxLayout="column">
                        <mat-toolbar class="report-toolbar" fxFlex="nogrow">
                            <span>{{'HISTORY' | translate}}</span>
                        </mat-toolbar>

                        <mat-card-content fxFlex style="overflow: auto; padding: 0 8px;">
                            <ng-container *ngIf="reportingTasks">
                                <mat-nav-list>
                                    <mat-list-item (click)="drawer.close();openSelectedReportingTask(item, false, true)" *ngFor="let item of reportingTasks"
                                                   [ngClass]="{'selected': item.id === reportingTask.id}" class="reports-list"
                                                   style="border-radius: 12px;">
                                        <mat-icon fontIcon="mdi-file-plus" fontSet="mdi" matListIcon></mat-icon>
                                        <h4 matLine style="max-width: 256px; text-overflow: ellipsis;">
                                            <span style="color: #E91E63;font-weight:  bold;font-size: 11px;margin-right:  4px;">{{item.report.lastModified| date: 'dd/MM/yyyy'}}</span>
                                            {{item.report.name}}
                                        </h4>
                                        <button mat-icon-button (click)="$event.stopPropagation();showReport(item, true)" (mouseenter)="showReport(item)" (mouseleave)="closeDialogReport()">
                                            <mat-icon fontSet="mdi" fontIcon="mdi-eye"></mat-icon>
                                        </button>
                                        <!-- <button (click)="$event.stopPropagation()" *ngIf="item.id !== reportingTask.id"
                                                class="list-btn" mat-button [matMenuTriggerFor]="reportMenu">
                                            <mat-icon fontSet="mdi" fontIcon="mdi-menu"></mat-icon>
                                        </button>
                                        <mat-menu #reportMenu="matMenu" [overlapTrigger]="true">
                                            <button mat-menu-item (click)="compareReport(item)">
                                                <mat-icon fontIcon="mdi-compare" fontSet="mdi"></mat-icon>
                                                {{'COMPARE_REPORT' | translate}}
                                            </button>
                                        </mat-menu> -->
                                    </mat-list-item>
                                    <mat-list-item *ngIf="reportingTasks.length === 0">
                                        <h4 matLine>{{'NO_ITEM' | translate}}</h4>
                                    </mat-list-item>
                                </mat-nav-list>
                            </ng-container>
                        </mat-card-content>

                    </mat-card>

            </ng-container>
            <ng-container *ngSwitchCase="'docs'">

                <mat-card class="reports-card" fxLayout="column">
                    <mat-toolbar class="report-toolbar" fxFlex="nogrow">
                        <span>{{'FILES_ATTACHED' | translate}}</span>
                    </mat-toolbar>

                    <mat-card-content fxFlex style="overflow: auto; padding: 0 8px;">
                        <div *ngIf="patientDetails" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="8px">
                            <div (click)="openElement(file)" *ngFor="let file of patientDetails.fileElements"
                                 class="file-element"
                                 fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                                <img [src]="getFileIcon(file.fileType)" alt="">
                                <div class="file-title">
                                    <span [style.fontWeight]="'bold'">{{file.name}}</span><br>
                                    <span style="font-size: 11px;font-style: italic;">{{file.createdDate | date: dateTimeFormat}}</span>
                                </div>
                                <span fxFlex></span>
                                <button (click)="$event.stopPropagation();downloadFile(file)" color="primary"
                                        mat-icon-button>
                                    <mat-icon fontIcon="mdi-download" fontSet="mdi"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

            </ng-container>
            <ng-container *ngSwitchDefault></ng-container>
        </ng-container>

    </mat-drawer>



    <mat-drawer-content>

        <div (click)="getPatient();panel='patient';drawer.toggle()" [matTooltip]="'PATIENT_DETAILS' | translate" class="details-btn patient" matTooltipPosition="right">
            <mat-icon fontIcon="mdi-card-account-details" fontSet="mdi" style="position: relative;top: 7px;right: 6px;"></mat-icon>
        </div>

        <div (click)="panel='report';drawer.toggle()" [matTooltip]="'CURRENT_REPORT' | translate" class="details-btn report" matTooltipPosition="right">
            <mat-icon fontIcon="mdi-file-cog" fontSet="mdi" style="position: relative;top: 7px;right: 6px;"></mat-icon>
        </div>

        <div (click)="panel='history';drawer.toggle()" [matTooltip]="'HISTORY' | translate" class="details-btn history" matTooltipPosition="right">
            <mat-icon [matBadge]="historyCount" fontIcon="mdi-file-restore" fontSet="mdi" matBadgeColor="warn"
                      matBadgeSize="small" style="position: relative;top: 7px;right: 6px;"></mat-icon>
        </div>

        <div (click)="panel='record';drawer.toggle()" [matTooltip]="'DICTATIONS' | translate" class="details-btn record"
             matTooltipPosition="right">
            <mat-icon [matBadge]="dictationsCount" fontIcon="mdi-record-player"
                      fontSet="mdi" matBadgeColor="accent" matBadgeSize="small"
                      style="position: relative;top: 7px;right: 6px;"></mat-icon>
        </div>

        <div (click)="getPatient();panel='docs';drawer.toggle()" [matTooltip]="'FILES_ATTACHED' | translate"
             class="details-btn docs" matTooltipPosition="right">
            <mat-icon [matBadge]="filesAttachedCount" fontIcon="mdi-folder-open"
                      fontSet="mdi" matBadgeColor="primary" matBadgeSize="small"
                      style="position: relative;top: 7px;right: 6px;"></mat-icon>
        </div>

        <div (click)="panel='key-images';drawer.toggle()" [matTooltip]="'KEYIMAGES' | translate"
             class="details-btn key-images" matTooltipPosition="right">
            <mat-icon [matBadge]="keyImagesCount" fontIcon="mdi-image-multiple"
                      fontSet="mdi" matBadgeColor="primary" matBadgeSize="small"
                      style="position: relative;top: 7px;right: 6px;"></mat-icon>
        </div>

        <div [class.expand]="patientExpand" fxFill fxLayout="row">
            <div fxFlex="grow" [ngStyle]="{borderRight: !isHide ? '1px solid lightgrey': 'none'}">

                <div class="nav">
                    <mat-toolbar class="sc-toolbar" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px" style="border-bottom: 1px solid #cbcbcb;">

                        <div class="title" fxFlex>
                            <mat-icon (click)="panel='report';drawer.toggle()" fontIcon="mdi-menu" fontSet="mdi"
                                      style="cursor:pointer;"></mat-icon>

                            <span *ngIf="reportingTask">{{reportingTask.patientName}}
                                : {{reportingTask.procedureCodes}}</span>
                        </div>
                        <div fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
                            <div *ngIf="selectedReport" fxLayout="row" fxLayoutAlign="end center"
                                 fxLayoutGap="8px">
                                <button (click)="startDictation()" [class.disabled]="reportingTask.reportingStatus === 'SIGNED'" [disabled]="selectedReport.locked"
                                        [matTooltip]="'DICTATE' | translate" [style.color]="selectedReport.locked ? '': '#e20303'" class="btn-top"
                                        mat-button>
                                    <mat-icon class="toolbar-icon-gradient-dictate" fontIcon="mdi-circle" fontSet="mdi"></mat-icon>
                                </button>
                                <mat-divider [vertical]="true" style="height: 28px;"></mat-divider>
                                <button (click)="assignPerformer(reportingTask)" *ngIf="canAssign" [class.disabled]="reportingTask.reportingStatus === 'SIGNED'" [disabled]="reportingTask.reportingStatus === 'SIGNED'"
                                        class="btn-top" mat-button>
                                    <mat-icon class="toolbar-icon-gradient" fontIcon="mdi-account-arrow-right" fontSet="mdi"></mat-icon>
                                    {{'DELEGATE_TASK' | translate}}
                                </button>
                                <button (click)="validateReport()" *ngIf="hasPermission('verifyReport')" [class.disabled]="selectedReport.locked" [disabled]="selectedReport.locked" [style.color]="selectedReport.locked ? '':'#ca1558'"
                                        class="btn-top" mat-button>
                                    <mat-icon class="toolbar-icon-gradient-validate" fontIcon="mdi-file-certificate" fontSet="mdi"></mat-icon>
                                    {{'VALIDATE_REPORT' | translate}}
                                </button>
                                <button (click)="signReport()" *ngIf="hasPermission('signReport')" [class.disabled]="selectedReport.locked"
                                        [disabled]="selectedReport.locked" [style.color]="selectedReport.locked ? '':'#079e35'" class="btn-top" mat-button>
                                    <mat-icon class="toolbar-icon-gradient-sign" fontIcon="mdi-file-check"  fontSet="mdi"></mat-icon>
                                    {{'SIGN_REPORT' | translate}}
                                </button>
                            </div>
                        </div>

                        <mat-divider [vertical]="true" style="height: 28px;"></mat-divider>
                        <div fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">

                            <div *ngIf="reportingTask" fxLayout="row" fxLayoutAlign="end center"
                                 fxLayoutGap="8px">

                                <button (click)="setToTranscribe()" class="btn-top" mat-button
                                        [class.disabled]="reportingTask.reportingStatus === 'SIGNED'" [disabled]="reportingTask.reportingStatus === 'SIGNED'"
                                        fxFlex>
                                    <mat-icon class="toolbar-icon-gradient-to-transcribe" fontIcon="mdi-file-edit-outline" fontSet="mdi"></mat-icon>
                                    {{'TO_TRANSCRIBE' | translate}}
                                </button>
                                <button (click)="setToReview()" class="btn-top" mat-button
                                        [class.disabled]="reportingTask.reportingStatus === 'SIGNED'" [disabled]="reportingTask.reportingStatus === 'SIGNED'"
                                        fxFlex>
                                    <mat-icon class="toolbar-icon-gradient-to-review" fontIcon="mdi-file-restore" fontSet="mdi"></mat-icon>
                                    {{'TO_REVIEW' | translate}}
                                </button>
                                <button (click)="setToValidate()" class="btn-top" mat-button
                                        [class.disabled]="reportingTask.reportingStatus === 'SIGNED'" [disabled]="reportingTask.reportingStatus === 'SIGNED'"
                                        fxFlex>
                                    <mat-icon class="toolbar-icon-gradient-to-validate" fontIcon="mdi-check" fontSet="mdi"></mat-icon>
                                    {{'TO_VALIDATE' | translate}}
                                </button>
                                <button (click)="setToSign()" class="btn-top" mat-button
                                        [class.disabled]="reportingTask.reportingStatus === 'SIGNED'" [disabled]="reportingTask.reportingStatus === 'SIGNED'"
                                        fxFlex>
                                    <mat-icon class="toolbar-icon-gradient-to-sign" fontIcon="mdi-gesture" fontSet="mdi"></mat-icon>
                                    {{'TO_SIGN' | translate}}
                                </button>

                                <button (click)="onClose(true)" [matTooltip]="'CLOSE' | translate" class="m80 btn-top" fxFlex mat-button style="color: red">
                                    <mat-icon class="toolbar-icon-gradient-close" fontIcon="mdi-exit-to-app" fontSet="mdi"></mat-icon>{{'CLOSE'| translate}}
                                </button>
                            </div>

                            <mat-divider [vertical]="true" style="height: 28px;"></mat-divider>

                            <div fxLayout="row" fxLayoutAlign="end center"
                                 fxLayoutGap="8px">
                                <button (click)="openViewer()" *ngIf="instancesAvailable && hasPermission('viewImages')" class="btn-top" mat-button
                                        matTooltip="{{'OPEN_IN_VIEWER' | translate}}">
                                    <mat-icon class="toolbar-icon-gradient-view" fontIcon="mdi-desktop-mac-dashboard" fontSet="mdi"></mat-icon>
                                </button>

                                <button (click)="printBooklet()" *ngIf="hasPermission('printReport')" class="btn-top" mat-button matTooltip="{{'PRINT_BOOKLET' | translate}}" style="color: #009688">
                                    <mat-icon class="toolbar-icon-gradient-booklet" fontIcon="mdi-book-open-blank-variant" fontSet="mdi"></mat-icon>
                                </button>
                                <ng-container *ngIf="hasPermission('printReport')" [ngSwitch]="generalSetting?.reportPrintMode">
                                    <button *ngSwitchCase="'CUPS'" [matMenuTriggerFor]="printersMenu" class="btn-top"
                                            mat-button style="color: #345E8C"
                                            matTooltip="{{'PRINT_REPORT' | translate}}">
                                        <mat-icon class="toolbar-icon-gradient-print" fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                    </button>
                                    <button (click)="printReport(null, 'CHROME')" *ngSwitchDefault class="btn-top"
                                            mat-button matTooltip="{{'PRINT_REPORT' | translate}}"
                                            style="color: #345E8C">
                                        <mat-icon class="toolbar-icon-gradient-print" fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                    </button>
                                </ng-container>

                                <button *ngIf="hasPermission('shareReport')" [matMenuTriggerFor]="shareMenu"
                                        class="btn-top" mat-button matTooltip="{{'SHARE' | translate}}"
                                        style="color: #07aae5">
                                    <mat-icon class="toolbar-icon-gradient-share" fontIcon="mdi-share-variant" fontSet="mdi"></mat-icon>
                                </button>

                                <mat-menu #shareMenu="matMenu" overlapTrigger="true">
                                    <button (click)="sendMail()" mat-menu-item>
                                        <mat-icon [style.color]="'#F44336'" fontIcon="mdi-gmail"
                                                  fontSet="mdi"></mat-icon>
                                        Email
                                    </button>
                                    <button (click)="sendSMS()" mat-menu-item>
                                        <mat-icon [style.color]="'#00BCD4'" fontIcon="mdi-message-text"
                                                  fontSet="mdi"></mat-icon>
                                        SMS
                                    </button>
                                </mat-menu>

                                <button (click)="openImageGallery()"
                                        *ngIf="instancesAvailable && hasPermission('viewImages')" class="btn-top"
                                        mat-button style="color: rgb(255,196,87)"
                                        matTooltip="{{'IMAGES' | translate}}">
                                    <mat-icon class="toolbar-icon-gradient-images" [fontIcon]="!isHide ? 'mdi-folder-open':'mdi-folder'"
                                              fontSet="mdi"></mat-icon>
                                </button>

                            </div>
                        </div>


                    </mat-toolbar>

                    <div *ngIf="isHide && !selectedReport?.locked">
                        <button [matMenuTriggerFor]="patientDataMenu" class="office-menu patient-menu" mat-button>
                            Patient
                        </button>

                        <button [matMenuTriggerFor]="examDataMenu" class="office-menu exam-menu" mat-button>
                            Exam
                        </button>
                    </div>
                </div>

                <mat-menu #patientDataMenu="matMenu">
                    <button (click)="insertVariable(item.key)" *ngFor="let item of patientVariables" mat-menu-item>{{item.label}}</button>
                </mat-menu>

                <mat-menu #examDataMenu="matMenu">
                    <button (click)="insertVariable(item.key)" *ngFor="let item of examVariables" mat-menu-item>{{item.label}}</button>
                </mat-menu>

                <mat-menu #printersMenu="matMenu">
                    <div (click)="printReport(printer['label'])" *ngFor="let printer of printers" fxLayout="row"
                         fxLayoutAlign="space-between center"
                         mat-menu-item>
                            <span fxFlex><mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                {{printer['label']}}</span>
                        <span (click)="$event.stopPropagation();editCount(printer['label'])"
                              class="print-count">{{countDefault[printer['label']]}}</span>
                    </div>
                </mat-menu>

                <div class="editor2">

                    <div *ngIf="selectedModel && selectedReport" class="form-mode">
                        <mat-card
                                [ngStyle]="{'display': (group === 'form'? 'block': 'none'), padding: '12px', height: '100%'}">
                            <div *ngFor="let section of templateSections">
                                <div class="subtitle-md">{{section.name}}</div>
                                <dynamic-form #dynamicForm (submit)="formSubmitted($event)"
                                              [config]="section.fields"></dynamic-form>
                            </div>
                        </mat-card>
                        <div [ngStyle]="{height: '100%','display': ((group === 'template') || (this.selectedModel?.sections?.length === 0)? 'block': 'none')}"
                             class="editor-parent"
                             fxLayout="row"
                             fxLayoutAlign="space-between"
                             fxLayoutGap="4px">


                            <ft-reporter (editorInitialized)="editorReady($event)"
                                         [studyInstanceUID]="reportingTask.studyInstanceUID"
                                         [documentTitle]="selectedReport.name"
                                         [emptyReport]="reportEmpty"
                                         [fileId]="selectedReport.id+'_'+selectedModel.id"
                                         [templateMode]="0"
                                         [editable]="!selectedReport.locked"
                                         [templateModel]="selectedModel.id"
                                         [procedureCode]="reportingTask.procedureCodes"
                                         [procedureType]="reportingTask.examCategory"
                                         [approved]="approved"
                                         [patientData]="patientData"
                                         [examData]="examData"
                                         fxFlex>
                            </ft-reporter>
                        </div>
                    </div>
                </div>

            </div>

            <div *ngIf="instancesAvailable" id="sidenav" class="image-container">
                <mat-toolbar class="report-toolbar" color="primary" style="margin-bottom: 6px;" fxLayout="row" fxLayoutAlign="space-between center">
                    <span fxFlex>{{'DICOM_DATA' | translate}}</span>
                    <button color="primary" [matTooltip]="'REFRESH' | translate" mat-icon-button (click)="cleanAndRetrieve()"><mat-icon [style.color]="'#11a0ac'" fontSet="mdi" fontIcon="mdi-refresh-circle"></mat-icon></button>
                    <button color="accent" [matTooltip]="'CLOSE' | translate" mat-icon-button (click)="closeImageGallery()"><mat-icon [style.color]="'#811040'" fontSet="mdi" fontIcon="mdi-close"></mat-icon></button>
                </mat-toolbar>
                <div *ngIf="studyInstanceUID && imagesAvailable; else loader" class="gallery-container">

                 <app-image-gallery (onSetFavorite)="onSetImageFavorite($event)" [id]="'images'" [patientID]="pacsPatientID" [studyInstanceUID]="studyInstanceUID"></app-image-gallery>

<!--                    <ft-study-explorer [pacsPatientID]="pacsPatientID" [patientID]="patientID"></ft-study-explorer>-->

                </div>
                <ng-template #loader>
                    <div [style.height.%]="100" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
                        <span style="font-size: 14px;font-weight: bold;">{{'RETRIEVING_IMAGES' | translate}}</span>
                        <mat-spinner [diameter]="100" [strokeWidth]="5" color="accent"></mat-spinner>
                    </div>
                </ng-template>
                <div style="display: flex; justify-content: start; padding: 6px">
                    <img (click)="openPdf(file.instanceUID)" *ngFor="let file of pdfFiles" [src]="file.url" alt="Encapsulated PDF"
                         class="pdf-tile">
                </div>
            </div>
        </div>


    </mat-drawer-content>
</mat-drawer-container>

<button (click)="openReportingTask(rt)" *ngFor="let rt of bubbles | async; let i = index"
        [matTooltip]="rt.patientName + '\n' + rt.procedureCodes"
        matTooltipClass="bubble-tooltip"
        [style.bottom.px]="88 + (i + 1)*46"
        class="bubble mat-elevation-z5"
        mat-mini-fab matTooltipPosition="left">
    <img [alt]="rt.patientSex"
         [src]="'../../assets/images/'+(rt.patientSex == 'M' ? 'man.png': rt.patientSex == 'F' ? 'woman.jpg': 'other.jpeg')"
         class="img-profile">
    <mat-icon (click)="$event.stopPropagation();closeReport(rt)" class="fab-badge" fontIcon="mdi-close"
              fontSet="mdi"></mat-icon>
    <mat-icon (click)="$event.stopPropagation();closeReport(rt)" *ngIf="isActive(rt)" class="fab-active"
              fontIcon="mdi-file-edit"
              fontSet="mdi"></mat-icon>
</button>
<button (click)="searchReport()"
        [matTooltip]="'OPEN_REPORT' | translate"
        class="mat-elevation-z5"
        color="accent"
        mat-mini-fab
        matTooltipPosition="left"
        style="position:absolute; bottom: 88px; right: 32px;">
    <mat-icon fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
</button>

<ng-container *ngIf="reportDialogVisible">
    <div class="report-dialog">
        <ft-report-dialog (onClose)="onCloseReportPreview($event)" [reportingTask]="reportDialogData"></ft-report-dialog>
    </div>
</ng-container>
