<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-calendar-plus"></mat-icon>
    <h3 mat-dialog-title>{{(order ? 'ADD_WAITING_ORDER' : 'SCHEDULE_EXAM') | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<div mat-dialog-content [formGroup]="patientForm">

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="4px">
        <div *ngIf="!order" fxFlex="180px">
            <mat-form-field appearance="standard">
                <mat-label>{{'PATIENT_ID' | translate}}</mat-label>
                <input matInput [placeholder]="'PATIENT_ID' | translate" formControlName="externalPatientID"
                       [required]="generalSetting && (generalSetting.patientIdGenerationMode === 'MANUAL')"
                       tabindex="-1">
            </mat-form-field>
            <div class="ft-error" *ngIf="patientIdExists && !patientSelected">
                <span>{{'ALREADY_USED' | translate}}</span>
            </div>
        </div>

        <mat-form-field appearance="standard" formGroupName="title">
            <mat-label>{{'TITLE' | translate}}</mat-label>
            <mat-select [placeholder]="'TITLE' | translate" formControlName="id" tabindex="-1">
                <mat-option *ngFor="let title of titles" [value]="title.id">{{title.value}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex>
            <mat-label>{{'LAST_NAME' | translate}}</mat-label>
            <input matInput [placeholder]="'LAST_NAME' | translate" formControlName="lastName"  required>
        </mat-form-field>

        <mat-form-field appearance="standard">
            <mat-label>{{'FIRST_NAME' | translate}}</mat-label>
            <input matInput [placeholder]="'FIRST_NAME' | translate" formControlName="firstName"  required>
        </mat-form-field>


        <button *ngIf="!data.patient" class="find-patient" mat-icon-button (click)="findPatient()">
            <mat-icon style="color: #FFFFFF;" fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
            <span class="badge" *ngIf="numOfDuplicatePatients && !patientSelected">{{numOfDuplicatePatients}}</span>
        </button>
    </div>


    <div formGroupName="demographic" fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="4px">
        <mat-form-field appearance="standard" fxFlex="180px">
            <mat-label>{{'DATEOFBIRTH' | translate}}</mat-label>
            <input matInput formControlName="dateOfBirth" [max]="minDate" [matDatepicker]="picker" (dateInput)="changeAge()"
                   placeholder="{{'DATEOFBIRTH' | translate}}">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker (closed)="changeAge()"></mat-datepicker>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="4px" [formGroup]="ageForm">
            <mat-form-field appearance="standard" fxFlex="80px">
                <mat-label>{{'AGE_YEARS' | translate}}</mat-label>
                <input type="number" matInput placeholder="{{'AGE_YEARS' | translate}}" formControlName="years"
                       (keyup)="changeDate()">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex="80px">
                <mat-label>{{'AGE_MONTHS' | translate}}</mat-label>
                <input type="number" matInput placeholder="{{'AGE_MONTHS' | translate}}" formControlName="months"
                       (keyup)="changeDate()">
            </mat-form-field>
        </div>

        <div fxLayout="column" formGroupName="gender">
            <div class="label-option">{{'GENDER' | translate}}</div>

            <mat-radio-group fxLayout="row" fxLayoutAlign="start end" formControlName="id">
                <mat-radio-button fxFlex *ngFor="let option of genders" [value]="option.id">
                    {{option.value}}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <span fxFlex></span>

        <mat-form-field appearance="standard" [formGroup]="patientForm">
            <mat-label>{{'DEBITER' | translate}}</mat-label>
            <mat-select [placeholder]="'DEBITER' | translate" formControlName="debiter">
                <mat-option *ngFor="let option of debiters" [value]="option">
                    {{(option === 'PATIENT' ? 'Payant': option) | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <div *ngIf="billingRequired" fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="4px"
         [formGroup]="patientForm">
        <mat-form-field fxFlex appearance="standard" formGroupName="insurance">
            <mat-label>{{'INS_NAME' | translate}}</mat-label>
            <mat-select [placeholder]="'INS_NAME' | translate" (selectionChange)="onSelectOrganism()"
                        formControlName="organismName">
                <mat-option *ngFor="let organism of organisms" [value]="organism">
                    {{splitter(organism, 0)}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex appearance="standard">
            <mat-label>{{'TARIFF' | translate}}</mat-label>
            <mat-select [disabled]="!notPaid()" [placeholder]="'TARIFF' | translate"
                        (selectionChange)="changeTariff($event)"
                        [(ngModel)]="tariffId"
                        [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let value of tariffs" [value]="splitter(value, 1)">
                    {{splitter(value, 0)}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <ng-container [ngSwitch]="convType">
            <ng-container *ngSwitchCase="'PEC'">
                <mat-form-field fxFlex appearance="standard" formGroupName="insurance"
                                *ngIf="patientSelected">
                    <mat-label>{{'PEC_REF' | translate}}</mat-label>
                    <mat-select [placeholder]="'PEC_REF' | translate"
                                (selectionChange)="onSelectPEC($event)"
                                formControlName="pecName">
                        <mat-option *ngFor="let pec of organismPecs" [value]="pec">
                            {{splitter(pec, 1)}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-container *ngSwitchCase="'THIRD_PARTY_PAYMENT'">
                <mat-form-field fxFlex appearance="standard" formGroupName="insurance">
                    <mat-label>{{'CONVENTION' | translate}}</mat-label>
                    <mat-select [placeholder]="'CONVENTION' | translate"
                                formControlName="conventionName">
                        <mat-option *ngFor="let convention of organismConventions" (onSelectionChange)="onSelectConvention(convention)"
                                    [value]="convention">
                            {{splitter(convention, 0)}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-container *ngSwitchDefault></ng-container>
        </ng-container>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="4px">
        <mat-form-field appearance="standard">
            <mat-label>{{'CIN' | translate}}</mat-label>
            <input matInput [placeholder]="'CIN' | translate" formControlName="cin">
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>{{'PHONE' | translate}}</mat-label>
            <input matInput [placeholder]="'PHONE' | translate" formControlName="phone">
        </mat-form-field>
        <mat-form-field appearance="standard" fxFlex>
            <mat-label>{{'EMAIL' | translate}}</mat-label>
            <input type="email" matInput [placeholder]="'EMAIL' | translate" formControlName="email">
        </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="4px">

        <div fxLayout="column" fxLayoutAlign="space-between" formGroupName="patientClass">
            <div class="label-option">{{'PATIENT_CLASS' | translate}}</div>

            <mat-radio-group fxLayout="row" fxLayoutAlign="start end" formControlName="id">
                <mat-radio-button fxFlex *ngFor="let option of patientClasses" [value]="option.id">
                    {{option.description}}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <span fxFlex></span>

        <div formGroupName="demographic">
            <div fxLayout="column" fxLayoutAlign="space-between" formGroupName="confidentiality">
                <div class="label-option">{{'CONFIDENTIALITY' | translate}}</div>

                <mat-radio-group fxLayout="row" fxLayoutAlign="start end" formControlName="id">
                    <mat-radio-button fxFlex *ngFor="let option of confidentialities" [value]="option.id">
                        {{option.description}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

        </div>
    </div>


    <mat-divider *ngIf="order" class="ft-divider"></mat-divider>

    <div fxLayout="row wrap" fxLayoutGap="4px" *ngIf="order" [formGroup]="orderForm">

        <mat-form-field appearance="standard">
            <mat-label>{{'APT_REASON' | translate}}</mat-label>
            <mat-select [placeholder]="'APT_REASON' | translate" formControlName="appointmentReason">
                <mat-option *ngFor="let reason of reasonForExams" [value]="reason">{{reason.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex="180px">
            <mat-label>{{'DATE' | translate}}</mat-label>
            <input [placeholder]="'DATE' | translate" matInput [matDatepicker]="myDatepicker"
                   formControlName="date" required>
            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex="108px">
            <mat-label>{{'TIME' | translate}}</mat-label>
            <input [placeholder]="'TIME' | translate" matInput type="time" formControlName="time" required>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex="100px">
            <mat-label>{{'DURATION' | translate}}</mat-label>
            <input [placeholder]="'DURATION' | translate" matInput formControlName="duration" type="number">
        </mat-form-field>
    </div>

    <div *ngIf="!order" [formGroup]="examForm">
        <div formArrayName="exams" *ngFor="let item of examForm.get('exams')['controls']; let i = index;">
            <div [formGroupName]="i" class="exam-box">

                <div fxLayout="row wrap" fxLayoutGap="4px">

                    <!--<div fxFlex="180px">
                        <mat-form-field appearance="standard" style="width: 100%;">
                            <mat-label>{{'ACCESSION_NUMBER' | translate}}</mat-label>
                            <input matInput [placeholder]="'ACCESSION_NUMBER' | translate"
                                   formControlName="accessionNumber">
                        </mat-form-field>
                        <div class="ft-error" *ngIf="anExists">
                            <span>{{'ALREADY_USED' | translate}}</span>
                        </div>
                    </div>-->

                    <mat-form-field fxFlex appearance="standard" class="auto-comp">
                        <mat-label>{{'PROCEDURE_CODE' | translate}}</mat-label>
                        <input matInput [placeholder]="'PROCEDURE_CODE' | translate" required
                               [formControl]="procedureCodeControl[i]"
                               [matAutocomplete]="modelList">
                        <mat-autocomplete #modelList="matAutocomplete"
                                          [autoActiveFirstOption]="true"
                                          (opened)="selected = null"
                                          (optionSelected)="onChangeProcedureCode($event, item, i)">
                            <mat-option *ngFor="let pc of filteredProcedures[i]" [value]="pc">
                                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="2px">
                                    <span>{{pc.code}}</span>
                                    <span fxFlex></span>
                                    <div class="select-badge" *ngIf="selected !== pc.id" [style.color]="'#353535'"
                                         fxFlex="46px">{{pc.billingCode?.price || 0.0}}</div>
                                    <div class="select-badge" *ngIf="selected !== pc.id"
                                         [style.background]="pc.reasonForExam?.color || '#444'"
                                         fxFlex="46px">{{pc.reasonForExam?.value || '-'}}</div>
                                </div>

                            </mat-option>
                        </mat-autocomplete>
                        <button mat-icon-button matSuffix
                                (click)="$event.stopImmediatePropagation();searchProcedureCode(item, i)">
                            <mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
                        </button>
                    </mat-form-field>

                    <div fxLayout="row nowrap" fxFlex="180px" fxLayoutGap="4px" *ngIf="this.patientForm.get('debiter').value === 'INSURED_PATIENT' && convType==='INSURED_PATIENT'">
                        <mat-form-field fxFlex="88px" appearance="standard" style="width: 100%;">
                            <mat-label>{{'PAT_PART' | translate}}</mat-label>
                            <input matInput [placeholder]="'PAT_PART' | translate"
                                   formControlName="patientPart">
                        </mat-form-field>
                        <mat-form-field fxFlex="88px" appearance="standard" style="width: 100%;">
                            <mat-label>{{'ORG_PART' | translate}}</mat-label>
                            <input matInput [placeholder]="'ORG_PART' | translate"
                                   formControlName="organismPart">
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row wrap" fxLayoutGap="4px">

                    <mat-form-field fxFlex="180px" appearance="standard" formGroupName="priority">
                        <mat-label>{{'PRIORITY' | translate}}</mat-label>
                        <mat-select formControlName="id" [placeholder]="'PRIORITY' | translate">
                            <mat-option *ngFor="let option of priorities" [value]="option.id">
                                {{option.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex appearance="standard" formGroupName="performingPhysician">
                        <mat-label>{{'PERFORMING_PHYSICIAN' | translate}}</mat-label>
                        <mat-select formControlName="id" [placeholder]="'PERFORMING_PHYSICIAN' | translate">
                            <mat-option *ngFor="let option of performingPhysicians" [value]="option.id">
                                {{option.fullName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex appearance="standard" class="auto-comp">
                        <mat-label>{{'REFERRING_PHYSICIAN' | translate}}</mat-label>
                        <input matInput [placeholder]="'REFERRING_PHYSICIAN' | translate" required
                               [formControl]="referringPhysicianControl[i]"
                               [matAutocomplete]="refPhyList">
                        <mat-autocomplete #refPhyList="matAutocomplete"
                                          [autoActiveFirstOption]="true"
                                          (optionSelected)="onChangeRefPhy($event, item, i)">
                            <ng-container>
                                <mat-option *ngFor="let user of filteredReferringPhysicians[i]" [value]="user">
                                    {{ user.fullName }}
                                </mat-option>
                            </ng-container>

                        </mat-autocomplete>
                        <button mat-icon-button matSuffix
                                (click)="$event.stopImmediatePropagation(); addReferringPhysician(item, i)">
                            <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="start center">
                    <mat-form-field appearance="standard" formGroupName="aet">
                        <mat-label>{{'AET' | translate}}</mat-label>
                        <mat-select [placeholder]="'AET' | translate" formControlName="id" [required]="!order">
                            <mat-option *ngFor="let aet of filteredAets[i]" [value]="aet.id">{{aet.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="standard" formGroupName="technician">
                        <mat-label>{{'TECHNICIAN' | translate}}</mat-label>
                        <mat-select [placeholder]="'TECHNICIAN' | translate" formControlName="id" required>
                            <mat-option *ngFor="let usr of technicians" [value]="usr.id">{{usr.fullName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex="180px">
                        <mat-label>{{'DATE' | translate}}</mat-label>
                        <input [placeholder]="'DATE' | translate" matInput [matDatepicker]="examDatePicker"
                               formControlName="date" required>
                        <mat-datepicker-toggle matSuffix [for]="examDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #examDatePicker [dateClass]="dateClass"></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex="100px">
                        <mat-label>{{'TIME' | translate}}</mat-label>
                        <input type="time" matInput [placeholder]="'TIME' | translate" formControlName="time" required>
                    </mat-form-field>
                    <mat-form-field appearance="standard" fxFlex="80px">
                        <mat-label>{{'DURATION' | translate}}</mat-label>
                        <input type="number" matInput [placeholder]="'DURATION' | translate" formControlName="duration"
                               required>
                    </mat-form-field>

                    <button class="find-patient" mat-icon-button [matTooltip]="'SEARCH_AVAILABILITY' | translate"
                            [disabled]="!data.editable"
                            (click)="checkAvailability(item)">
                        <mat-icon style="color: #FFFFFF;" fontIcon="mdi-calendar-text" fontSet="mdi"></mat-icon>
                    </button>

                </div>

            </div>
        </div>
        <div fxLayout="row" *ngIf="!data.ids">
            <span fxFlex></span>
            <button color="primary" mat-button (click)="addItem()" [disabled]="!data.editable || convType === 'PEC'">
                <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                {{'ADD_NEW_EXAM' | translate}}
            </button>
        </div>
    </div>

    <app-file-explorer [fileElements]="fileElements | async"
                       [path]="currentPath"
                       [patientID]="patientID"
                       [modalMode]="true"
                       [canNavigateUp]="canNavigateUp"
                       (folderAdded)="addFolder($event)"
                       (elementRemoved)="removeElement($event)"
                       (navigatedDown)="navigateToFolder($event)"
                       (navigatedUp)="navigateUp()"
                       (onUpload)="afterUpload($event)"
                       (elementRenamed)="renameElement($event)"
                       (elementMoved)="moveElement($event)">
    </app-file-explorer>

</div>

<div mat-dialog-actions fxLayout="row" fxLayoutGap="4px">
    <!--    <mat-slide-toggle tabindex="-1" [(ngModel)]="singleReport" *ngIf="!order" style="font-size: 12px;">
            {{'SINGLE_REPORT' | translate}}
        </mat-slide-toggle>-->
    <!--
        <mat-slide-toggle tabindex="-1" [(ngModel)]="order" *ngIf="!data.isr" style="font-size: 12px;">
            {{'MAKE_WAITING' | translate}}

        </maide-toggle>

        <mat-slide-toggle disabled tabindex="-1" *ngIf="!order" style="font-size: 12px;">
            {{'NOTIFY_BY_SMS' | translate}}
        </mat-slide-toggle>

        <mat-slide-toggle disabled tabindex="-1" *ngIf="!order" style="font-size: 12px;">
            {{'NOTIFY_BY_EMAIL' | translate}}

        </mat-slide-toggle>-->

    <span fxFlex></span>

    <div class="has-future-exam" *ngIf="hasFutureExams | async">
        <span>{{'HAS_FUTURE_EXAMS' | translate}}</span>
        <button (click)="showPatientFutureExams()">{{'SHOW' | translate}}</button>
    </div>

    <span fxFlex></span>

    <button (click)="cancel()" color="accent" mat-raised-button>{{'CANCEL' | translate}}</button>

    <button *ngIf="data.editable" color="primary" mat-raised-button
            (click)="printAndSave(patientForm.value, order ? orderForm.value: examForm.value)"
            [disabled]="order ? (patientForm.invalid || orderForm.invalid): (patientForm.invalid || examForm.invalid || anExists)">
        {{'PRINT_AND_SAVE' | translate}}
    </button>

    <button *ngIf="data.editable" color="primary" mat-raised-button
            (click)="save(patientForm.value, order ? orderForm.value: examForm.value)"
            [disabled]="order ? (patientForm.invalid || orderForm.invalid): (patientForm.invalid || examForm.invalid || anExists)">
        {{'SAVE' | translate}}
    </button>
</div>
