<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-eye"></mat-icon>
    <h3 mat-dialog-title>{{'VIEWER_SELECT' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content style="padding: 12px;">

    <div [formGroup]="viewerSelect">
        <mat-form-field appearance="standard" class="full-width">
            <mat-label>{{'VIEWER' | translate}}</mat-label>
            <mat-select [placeholder]="'VIEWER' | translate" formControlName="aETitle" required>
                <mat-option *ngFor="let viewer of viewers" [value]="viewer.aETitle">{{viewer.aETitle}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>


</mat-dialog-content>

<mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="accent" mat-raised-button>{{'CANCEL' | translate}}</button>
    <button [disabled]="viewerSelect.invalid" [mat-dialog-close]="viewerSelect.value" color="primary" mat-raised-button>
        {{'OK' | translate}}
    </button>
</mat-dialog-actions>
