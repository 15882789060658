import {AfterViewInit, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import * as Quill from 'quill';
import {Report} from "../../model";

@Component({
  selector: 'app-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.scss']
})
export class QuillEditorComponent implements AfterViewInit, OnChanges {

  private quill: Quill;

  @Input() report: Report;
  constructor() { }

  ngAfterViewInit(): void {
    this.quill = new Quill('#ft-editor', quillEditorConfig);
    this.setupQuillEditor(this.report);
  }

  setupQuillEditor(report: Report) {
    this.quill.clipboard.dangerouslyPasteHTML(report ? report.reportText : '');
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (!simpleChanges['report'].firstChange) this.setupQuillEditor(simpleChanges['report'].currentValue)
  }

}

// Editor config
const quillToolbarConfig = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
];

export const quillEditorConfig = {
  modules: {
    toolbar: quillToolbarConfig
  },
  theme: 'snow',
  placeholder: 'Ecrire votre email ici ...',
  readOnly: true
};
