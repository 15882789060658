import {BehaviorSubject, fromEvent as observableFromEvent, merge, of as observableOf} from 'rxjs';

import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {SettingService} from '../setting.service';
import {DeleteConfirmComponent, SharedService} from '../../shared';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {STAFF_HEADER_COLS, STAFF_TABLE_CONF} from './table-conf';
import {tableAnimation} from '../../animations';

@Component({
    selector: 'app-staff-contract',
    templateUrl: './staff-contract.component.html',
    styleUrls: ['./staff-contract.component.scss'],
    animations: [tableAnimation]
})
export class StaffContractComponent implements AfterViewInit {

    cols: any[];
    displayedColumns = [];

    dataSource = new MatTableDataSource();
    @ViewChild('filter', { static: true }) filter: ElementRef;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    filterChange = new BehaviorSubject('');
    trackById = (index: number, item: any): string => item.id;

    constructor(private service: SettingService,
                private sharedService: SharedService,
                public dialog: MatDialog) {

        this.displayedColumns = STAFF_TABLE_CONF;
        this.cols = STAFF_HEADER_COLS;
    }

    deleteStaffContract(row) {
        this.dialog.open(DeleteConfirmComponent).afterClosed().subscribe(ok => {
            if (ok) this.service.deleteStaffContract(row.id).subscribe(res => this.filterChange.next(''));
        });
    }

    ngAfterViewInit() {

        observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
            debounceTime(400),
            distinctUntilChanged(),)
            .subscribe(() => {
                if (!this.dataSource) return;
                this.paginator.pageIndex = 0;
                this.filterChange.next(this.filter.nativeElement.value);
            });

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        let observedFilters = [
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.filterChange.asObservable()
        ];

        merge(...observedFilters)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.sharedService.getStaffContracts(this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        this.filterChange.getValue());
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data['totalElements'];

                    return data['content']
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.dataSource.data = data);
    }
}
