import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ChatRoom, ChatUser, Message} from './models';
import {Observable} from 'rxjs';
import {MESSAGING_URL} from '../urls';

@Injectable({
    providedIn: 'root'
})
export class MessagingService {

    constructor(private _http: HttpClient) {
    }

    sendMessage(message: Message): Observable<any> {
        return this._http.post(`${MESSAGING_URL}/sendMessage`, message);
    }

    uploadFile(formData: FormData): Observable<any> {
        return this._http.post(`${MESSAGING_URL}/uploadFile`, formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    getContacts(userId: number): Observable<ChatUser[]> {
        return this._http.get<ChatUser[]>(`${MESSAGING_URL}/contacts`, {params: {userId}});
    }

    createChatRoom(chatRoom: ChatRoom): Observable<ChatRoom> {
        return this._http.post<ChatRoom>(`${MESSAGING_URL}/createChatRoom`, chatRoom);
    }

    getChatRooms(senderId: any): Observable<ChatRoom[]> {
        return this._http.get<ChatRoom[]>(`${MESSAGING_URL}/getChatRooms`, {params: {senderId}});
    }

    getRoomMessages(id: number, pageSize: number = 10, pageIndex: number = 0, filter: string = '', sort: string = 'sentDateTime', direction: string = 'desc'): Observable<any> {
        let params = {id, page: String(pageIndex), size: String(pageSize), sort: `${sort},${direction}`, filter};
        return this._http.get<Message[]>(`${MESSAGING_URL}/roomMessages`, {params});
    }

    getOrCreateChatUser(user: ChatUser): Observable<ChatUser> {
        return this._http.post<ChatUser>(`${MESSAGING_URL}/getOrCreateChatUser`, user);
    }

    updateMessagesSeenDate(id: number, userId: number, senderId: number, pageSize: number = 10, pageIndex: number = 0, sort: string = 'sentDateTime', direction: string = 'desc', filter: string = ''): Observable<any> {
        let params = {
            id,
            userId,
            senderId,
            page: String(pageIndex),
            size: String(pageSize),
            sort: `${sort},${direction}`,
            filter
        };
        return this._http.get(`${MESSAGING_URL}/updateMessagesSeenDate`, {params})
    }

    switchStatusTo(userId: number, status: string): Observable<any> {
        return this._http.get(`${MESSAGING_URL}/switchStatusTo`, {params: {userId, status}});
    }

    findRoom(roomId: number): Observable<ChatRoom> {
        return this._http.get<ChatRoom>(`${MESSAGING_URL}/findRoom`, {params: {roomId}});
    }

    sendTypingNotification(senderId: number, receiverId: number, typing: boolean): Observable<any> {
        return this._http.get(`${MESSAGING_URL}/typing`, {params: {senderId, receiverId, typing}});
    }

    messagePlayed(message: Message): Observable<any> {
        return this._http.post(`${MESSAGING_URL}/messagePlayed`, message);
    }

    messageSeen(message: Message): Observable<any> {
        return this._http.post(`${MESSAGING_URL}/messageSeen`, message);
    }
}
