import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PIE_CHART_COLORS, StateComponent, ViewQuery} from '../model';
import {StatisticService} from '../statistic.service';
import {Subscription} from 'rxjs';
import * as numeral from 'numeral';
import * as moment from 'moment';
import {ChartConfiguration, ChartOptions, ChartType} from 'chart.js';
import {StringUtils} from "../../utils";
import {BaseChartDirective} from "ng2-charts";
import DataLabelsPlugin from "chartjs-plugin-datalabels";

@Component({
    selector: 'app-aet-exam-state',
    templateUrl: './aet-exam-state.component.html',
    styleUrls: ['./aet-exam-state.component.scss']
})
export class AetExamStateComponent implements StateComponent, OnInit, OnDestroy {

    @Input() data: ViewQuery;

    @ViewChild(BaseChartDirective) baseChart: BaseChartDirective | undefined;

    public pieChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            }
        }
    };
    public pieChartPlugins = [
        DataLabelsPlugin
    ];

    aetExams: { aet: string, count: number, totalPaid: number }[] = [];
    private sub: Subscription;
    total: number;
    totalPaid: number;

    aet: any;
    aets: any;


    public pieChartLabels: any[] = [];
    public pieChartData: any = [];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartColors = PIE_CHART_COLORS;

    constructor(private service: StatisticService) {
    }

    formatNumeral(numValue: any): any {
        return numeral(numValue).format(`0,0`);
    }

    ngOnInit() {
        let start = moment(this.data.startDate).format('YYYYMMDD');
        let end = moment(this.data.endDate).format('YYYYMMDD');

        this.sub = this.service.getExamsByAet(`${start}-${end}`).subscribe(data => {

            this.aets = StringUtils.groupBy(data, 'aet');

            this.aet = Object.keys(this.aets);

            this.pieChartLabels = this.aet;

            this.aetExams = this.aet.map(p => {
                return {aet: p, count: this.aets[p].map(v => v.count).reduce((a, c) => a + c, 0), totalPaid: this.aets[p].map(v => v.totalPaid).reduce((a, c) => a + c, 0)}
            });

            let datasets =this.aet.map(v => this.aets[v].map(it => it.count).reduce((a, c) => a + c, 0));

            this.pieChartData = {
                labels: this.pieChartLabels,
                datasets: [{
                    data: datasets
                }]
            }

            this.total = this.aetExams.map(it => it.count).reduce((a, c) => a+c, 0);
            this.totalPaid = this.aetExams.map(it => it.totalPaid).reduce((a, c) => a+c, 0);
        });
    }

    changeGraphData(label) {
        let datasets =this.aet.map(v => this.aets[v].map(it => it[label]).reduce((a, c) => a + c, 0));

        this.pieChartData = {
            labels: this.pieChartLabels,
            datasets: [{
                data: datasets
            }]
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
