const quillToolbarConfig = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],           
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
];

export const quillEditorConfig = {
    modules: {
        toolbar: quillToolbarConfig
    },
    theme: 'snow',
    placeholder: 'Ecrire votre email ici ...',
    readOnly: false
};

