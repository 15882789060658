import {catchError, debounceTime, first, map, startWith, switchMap} from 'rxjs/operators';
import {AfterViewInit, Component, isDevMode, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Location} from '@angular/common';
import {ReportingService} from '../reporting.service';

import {assign, concat, difference, find, get, remove, reverse, sortBy} from 'lodash';
import * as $ from 'jquery';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
    CommandService,
    Dictation,
    Dictionary,
    GeneralSetting,
    Image,
    Pathology,
    Report,
    ReportingTask,
    Section,
    SRContentItem,
    Technique,
    TemplateModel,
    Viewer
} from '../../model';
import {FormControl} from '@angular/forms';
import {SettingService} from '../../setting/setting.service';
import {
    AudioPlayComponent,
    BookletPrintComponent, buildExamData, buildPatientDate,
    DeleteConfirmComponent,
    deleteItemFromArray,
    DynamicFormComponent,
    FieldConfig,
    FileElement,
    FileService,
    FirePdfComponent,
    ImageViewerComponent,
    LoEditorComponent,
    PerformerAssignComponent,
    PrintCountComponent,
    removeLocalPatientData,
    savePatientDataLocally,
    SharedService,
    SmsSenderComponent,
    SpeechNoteComponent,
    TechniqueEditComponent,
    TemplateSearchComponent,
    updateVariableValue
} from '../../shared';
import {EmailSendComponent} from '../email-send/email-send.component';
import {PathologyComponent} from '../pathology/pathology.component';
import {AudioRecorderComponent} from '../audio-recorder/audio-recorder.component';
import {AudioPlayerComponent} from '../audio-player/audio-player.component';
import {BehaviorSubject, forkJoin, of as observableOf, Subscription} from 'rxjs';
import * as moment from 'moment';
import {RouterExtService} from '../../router-ext.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {ReportingSearchComponent} from '../reporting-search/reporting-search.component';
import {TranslateService} from '@ngx-translate/core';
import {PatientService} from '../../patient/patient.service';
import {AppConfigService} from '../../app-config.service';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {DomSanitizer} from '@angular/platform-browser';
import {variables} from '../../utils/editor';
import {WsService} from '../../ws.service';
import {MatDrawer} from '@angular/material/sidenav';
import {SchedulerService} from '../../scheduler/scheduler.service';


interface TemplateSection {
    name: string;
    fields: FieldConfig[]
}

const PRINT_COUNT = 'print_counts';
const SELECTED_TASKS_KEY = 'selected_tasks';
const REPORTING_EDIT_URL = '/reporting/report-edition/';
const SVG_IMAGES = '../../assets/images/svg';
const IMAGES = '../../assets/images'
const PREV_URL = 'rep_pev_url';

@Component({
    selector: 'app-reporting-edit',
    templateUrl: './reporting-edit.component.html',
    styleUrls: ['./reporting-edit.component.scss']
})
export class ReportingEditComponent implements OnInit, AfterViewInit, OnDestroy {

    private _transformer = (node: SRContentItem, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
        };
    }

    treeFlattener = new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.children);

    treeControl = new FlatTreeControl<SRFlatNode>(
        node => node.level, node => node.expandable);
    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    dicomSr: SRContentItem;

    hasChild = (_: number, node: SRFlatNode) => node.expandable;
    private defaultViewer: Viewer;
    private username: any;
    private documentKey: string;
    public examVariables: any[];
    public patientVariables: any[];
    public reportEmpty: string;
    private reportSaved: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private reportSaveSub$: Subscription;
    @ViewChild('drawer') drawer: MatDrawer;
    private wsSub$: Subscription;
    private editorIsReady: boolean;
    reportDialogVisible: boolean;
    reportDialogData: ReportingTask;
    forcedDisplayDialog: boolean;
    public examData: any;
    public patientData: any;
    public patientStudies: any = [];

    loadDicomSRFile() {
        this.service.loadSRFile(this.reportingTask.studyInstanceUID).subscribe(res => {
            this.dicomSr = res;
            this.dataSource.data = [res];
        });
    }

    static count = 0;

    public templateModelControl = new FormControl('');
    options: string[] = ['One', 'Two', 'Three'];
    filteredTemplateModels: TemplateModel[] = [];

    // Comparing reports
    comparingReport: boolean;
    reportToCompare: Report;
    comparing: boolean;

    subs: Subscription[] = [];

    @ViewChild(DynamicFormComponent, {static: false}) dynamicForm: DynamicFormComponent;

    patient: any;
    procedure: any;

    isHide = true;

    templateSections: TemplateSection[] = [];

    selectedModel: TemplateModel;

    selectedReport: Report;
    reportingTask: ReportingTask;

    taskType: string;
    dictations: Dictation[] = [];

    pathologies: Pathology[] = [];
    images: Image[] = [];
    selectedImages: Image[] = [];
    pdfFiles: any[] = [];

    pathologyControl = new FormControl();

    multiSelectElts: any[] = [];
    private reportData: any[] | undefined;
    studyInstanceUID: string;
    instancesAvailable: boolean;
    profile: any;

    reportingTasks: ReportingTask[] = [];
    group = 'template';
    userFullName: string;
    canAssign: boolean;
    patientID: string;
    pacsPatientID: string;
    imagesAvailable: boolean = false;
    osirixAETitle: string;
    examDate: string;

    releaseDate: string = '-';
    printers: any[] = [];
    private templatesURL = '/templates';

    waitingTimeBeforeExit: number;
    private defaultExitTime = 60 * 60 * 1000; // 60min
    @ViewChild(LoEditorComponent, {static: false}) loEditor: LoEditorComponent;

    editorLoaded = false;
    generalSetting: GeneralSetting;
    bubbles = new BehaviorSubject<ReportingTask[]>([]);
    patientDetails: any;
    searchDialogOpen: boolean = false;

    private searchDialogRef: MatDialogRef<ReportingSearchComponent, any>;

    static imageToFileElement(image: Image): FileElement {
        let file = new FileElement();
        file.url = image.url;
        file.uuid = image.instanceUID;
        file.name = image.title;
        file.fav = image.fav;
        return file;
    }

    switchFormTemplate = (event) => this.group = event;

    deleteDictation(d) {
        this.dialog.open(DeleteConfirmComponent).afterClosed().subscribe(ok => {
            if (ok) {
                deleteItemFromArray(this.dictations, d);
                this.selectedReport.dictations = this.dictations;
            }
        })
    }

    compareReport(reportingTask) {
        return;
        // this.comparingReport = true;
        // this.reportToCompare = reportingTask.report;
    }

    public addPathology = () => this.dialog
        .open(PathologyComponent, {disableClose: true})
        .afterClosed()
        .subscribe(result => this.getPathologies());
    public selectPathology = () => this.reportingTask.pathology = this.pathologyControl.value.join(',');
    patientExpand: boolean = false;

    prodMode = !isDevMode();

    public onSelectTemplate(event) {

        this.modelChanged = true;

        let template = find(this.filteredTemplateModels, {name: event.option.value});

        this.selectedReport.templateModel = template;
        this.selectedReport.name = template.name;
        this.selectedModel = template;

        this.drawer.close().then(_ => this.selectReport(this.selectedReport));
    }

    constructor(private route: ActivatedRoute,
                private router: Router,
                private service: ReportingService,
                private location: Location,
                private snackBar: MatSnackBar,
                private setting: SettingService,
                private patientService: PatientService,
                private translate: TranslateService,
                private fileService: FileService,
                private _config: AppConfigService,
                private _sanitizer: DomSanitizer,
                private scheduleService: SchedulerService,
                private shared: SharedService,
                private routerExtService: RouterExtService,
                private _ws: WsService,
                private dialog: MatDialog) {
        removeLocalPatientData();

        setTimeout(() => {
            this.wsSub$ = this._ws.observeTopic('report').subscribe(res => {
                let key = res.response;

                if (key == this.documentKey) {
                    this.reportSaved.next(true);
                    this.snackBar.open(this.translate.instant('REPORT_SAVED'), '', {duration: 2000});
                }

                if (res.response == 'Error') {
                    this.reportSaved.next(false);
                    this.snackBar.open('Erreur: CR n\'est pas enregistré', 'Compris!', {duration: 3500})
                }
            })
        }, 2000);

        let user = JSON.parse(localStorage.getItem('user'));
        this.profile = user.profile;
        this.userFullName = user.fullName;
        this.username = user.username;

        this.waitingTimeBeforeExit = this.defaultExitTime;
        this.setting.getGeneralSetting().subscribe(res => this.generalSetting = res);

        this.service.reportSearch.asObservable().subscribe(data => {
            this.openSelectedReportingTask(data.report, data.waiting);
        });

        this.examVariables = variables("EXAM_VARIABLES", this);
        this.patientVariables = variables("PATIENT_VARIABLES", this);
    }

    private static showSavingMessage(msg: any): void {
        let message;
        switch (msg['error']) {
            case 0:
                message = 'Toutes les modification sont enregistées.';
                break;
            case 4:
                message = 'Aucune modification ajoutée.';
                break;
            default:
                message = 'Error.';
        }
        console.log(message);
    }

    panel: string;

    countDefault = {};

    techniqueControl = new FormControl();

    public startDictation = () => this.subs.push(this.dialog
        .open(AudioRecorderComponent, {disableClose: true, position: {top: '0px'}, hasBackdrop: false})
        .afterClosed()
        .subscribe(data => {
            if (data && data.file) {
                this.service.saveDictation({
                    id: 0,
                    recordingDate: new Date(),
                    duration: 0,
                    record: data.file, deleted: false
                }).subscribe(dictation => {
                    this.dictations.push(dictation);
                    this.dictations = reverse(sortBy(this.dictations, 'recordingDate'));
                    this.selectedReport.dictations = this.dictations;

                    this.saveReport();
                });
            }
        }));

    public get keyImagesCount(): number {
        return this.selectedImages && this.selectedImages.length != 0 ? this.selectedImages.length : null;
    }

    public showDictation(dictation: Dictation) {
        this.dialog.open(AudioPlayerComponent, {
            data: dictation,
            hasBackdrop: false,
            position: {left: '0'}
        });
    }

    searchReportTemplate() {
        this.dialog
            .open(TemplateSearchComponent, {minWidth: '50%', height: '500px'})
            .afterClosed()
            .subscribe(template => {
                if (template) {
                    this.templateModelControl.patchValue(template.name);
                    this.selectedReport.templateModel = template;
                    this.selectedReport.name = template.name;
                    this.selectedModel = template;

                    this.drawer.close().then(_ => this.selectReport(this.selectedReport));
                }
            });
    }

    public onSetImageFavorite(files: FileElement[]) {

        files.map(file => {
            return {
                index: file.id,
                fav: file.fav,
                instanceUID: file.uuid,
                title: file.name,
                url: file.url
            }
        }).forEach(image => {
            if (image.fav) {
                this.selectedImages.push(image);
            } else {
                deleteItemFromArray(this.selectedImages, image);
            }
        });

        this.selectedReport.selectedInstancesUIDs = '';

        this.selectedImages.forEach(img => this.selectedReport.selectedInstancesUIDs += img.url + ';');
    }

    public formSubmitted(value: Object) {

        for (let key in value) {
            let old = this.reportData.find(v => v.key === key);
            if (old) {
                assign(old, {
                    value: value[key]
                })
            } else {
                this.reportData.push({
                    id: '',
                    key: key,
                    value: value[key]
                })
            }
        }

        let obj = {};

        this.multiSelectElts.forEach(el => obj[el.name] = el.dictionary);

        this.reportData
            .filter(el => Object.keys(obj).indexOf(el.key) !== -1)
            .forEach(data => this.commitDictionary(data, obj));

        localStorage.setItem('reportData', JSON.stringify(this.reportData));

        assign(this.selectedReport, {
            reportData: this.reportData
        })
    }

    public commitDictionary(a: any, b: any) {

        let dict: Dictionary = b[a.key];

        let newValues = a.value.split(', ');
        let dictValues = dict.values.map(v => v.value);

        let diff = difference(newValues, dictValues);

        dict.values = concat(dict.values, diff.map(val => {
            return {
                id: null,
                key: null,
                value: val
            }
        }));

        this.subs.push(this.setting.saveDictionary(dict).subscribe());
    }

    public saveReportAndExit() {
        this.service.closeViewerStudy(this.reportingTask.studyInstanceUID);
        this.saveReport();
        setTimeout(() => this.onClose(), 2000);
    }

    public openImageGallery() {
        this.isHide = !this.isHide;

        document.getElementById('sidenav').classList.toggle('visible');

        this.retrieveImagesIfAvailable();
    }

    public closeImageGallery() {
        this.isHide = !this.isHide;

        document.getElementById('sidenav').classList.toggle('visible');
    }

    dateTimeFormat = this._config.dateTimeFormat;

    public get filesAttachedCount(): number {
        return this.patientDetails && this.patientDetails.fileElements && this.patientDetails.fileElements.length != 0 ? this.patientDetails.fileElements.length : null;
    }

    public get dictationsCount(): number {
        return this.dictations && this.dictations.length > 0 ? this.dictations.length : null;
    }

    onClose = (force: boolean = false) => {
        if (force) {
            this.dialog.open(DeleteConfirmComponent, {data: this.translate.instant('CLOSE_CONFIRM')}).afterClosed().subscribe(ok => {
                if (ok) this.router.navigate(['/workflow']).then(_ => localStorage.removeItem(PREV_URL));
            });
        } else this.router.navigate(['/workflow']).then(_ => localStorage.removeItem(PREV_URL));
    }

    public openPdf = (iuid) => this.subs.push(this.service.getInstance(iuid).subscribe(res => {
        let element = new FileElement();
        element.fileType = 'ENC_PDF';
        element.url = iuid;

        this.dialog.open(FirePdfComponent, {data: element, panelClass: 'pdf-viewer'});
    }));

    showImage = (image) => this.dialog.open(ImageViewerComponent, {
        data: {
            images: this.selectedImages.map(image => ReportingEditComponent.imageToFileElement(image)),
            selected: ReportingEditComponent.imageToFileElement(image),
            fromPacs: true
        },
        minWidth: '100%',
        height: '100%',
        panelClass: 'image-viewer'
    });

    deleteKos = (image: any) => deleteItemFromArray(this.selectedImages, image);

    private getPathologies = () => this.subs.push(this.shared.getPathologies().subscribe(data => this.pathologies = data));

    expanded: boolean = false;

    public createReport(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();

        let newReport: Report = {
            id: null,
            name: 'Rapport_' + ReportingEditComponent.count++,
            lastModified: new Date(),
            reportData: [],
            dictations: [],
            templateModel: this.filteredTemplateModels[0]
        };

        this.reportingTask.report = newReport;

        this.selectReport(newReport);
    }

    private getAllPatientReportingTasks = (patientID: string) =>
        this.subs.push(this.service.getAllPatientReportingTasks(patientID).subscribe(data => this.reportingTasks = reverse(sortBy(data, 'report.lastModified'))));
    modelChanged: boolean;

    filteredTechniques: Technique[] = [];

    public printBooklet() {

        this.service.sendCmd(new CommandService(this.documentKey)).subscribe(res => {
            let dialogRef = this.dialog.open(BookletPrintComponent, {
                data: {generalSetting: this.generalSetting, reportingTask: this.reportingTask},
                minWidth: '100vw',
                minHeight: '100vh',
                disableClose: true
            });

            dialogRef.afterOpened().subscribe(_ => this.retrieveImagesIfAvailable());
            dialogRef
                .afterClosed()
                .subscribe(res => {
                    let instancesUIDs = '';

                    if (res) {
                        this.reportingTask.numberOfImagesPerPage = res.numberOfImagesPerPage;
                        this.reportingTask.imageBackground = res.imageBackground;
                        this.reportingTask.printOption = res.printOption;
                        res.images.forEach(img => instancesUIDs += img.path + ';');
                    } else return;

                    this.selectedReport.selectedInstancesUIDs = instancesUIDs;

                    if (this.selectedReport.id === null) {
                        this.selectedReport.reportData = this.selectedReport.reportData.map(kv => {
                            return {key: kv.key, value: kv.value}
                        })
                    }

                    this.service.sendCmd(new CommandService(this.documentKey)).subscribe(res2 => {
                        ReportingEditComponent.showSavingMessage(res2);

                        if ([0, 4].includes(res2['error'])) {
                            this.service
                                .saveReportingTask(this.reportingTask)
                                .subscribe(reportingTask => {
                                    this.initViewData(reportingTask);

                                    let matSnackBarRef = this.snackBar.open(this.translate.instant('PRINTING_IN_PROGRESS'), '', {duration: 10000});

                                    if (this.generalSetting.reportPrintMode === 'CHROME') this.service.printReport(reportingTask.id).subscribe(_ => matSnackBarRef.dismiss());
                                    else {
                                        this.service
                                            .printCupsReport(reportingTask.id, res.printer, res.printCount)
                                            .subscribe(response => {
                                                if (response['status'] !== 'ok') alert('Cannot print the booklet');
                                                else {
                                                    matSnackBarRef.dismiss();
                                                    this.snackBar.open(this.translate.instant('FINALIZING_PRINTING'), '', {duration: 4000});
                                                }
                                            });
                                    }
                                });
                        }
                    });
                });
        });
    }

    public selectReport = (report: Report) => {
        if (report) {
            this.selectedReport = report;
            this.reportData = this.selectedReport.reportData;
            this.dictations = reverse(sortBy(this.selectedReport.dictations, 'recordingDate'));

            this.selectedModel = report.templateModel;
            this.templateModelControl.patchValue(report.templateModel.name);

            if (this.selectedModel) {

                this.templateSections = [];
                this.selectedModel.sections?.forEach(sec => {
                    this.templateSections?.push(this.buildTemplateSection(sec));

                    this.multiSelectElts = concat(this.multiSelectElts, sec.elements
                        .filter(el => el.elementType === 'MULTI')
                        .map(el => {
                            return {name: el.name, dictionary: el.dictionary}
                        }));
                });
            }

            this.selectedReport.locked = this.reportingTask.reportingStatus === 'SIGNED' && !this.hasPermission('editSignedReport');

            if (this.selectedReport.locked) {
                this.pathologyControl.disable();
                this.templateModelControl.disable();
                this.techniqueControl.disable();
            } else {
                this.pathologyControl.enable();
                this.templateModelControl.enable();
                this.techniqueControl.enable();
            }
        }
    };

    public get approved(): boolean {
        return this.editorIsReady && this.reportingTask && ['SIGNED', 'VALIDATED', 'VERIFIED'].includes(this.reportingTask.reportingStatus);
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.canAssign = this.reportingTask && (this.userFullName === this.reportingTask.performerName.fullName), 1000);

        this.shared.getPrinters().subscribe(data => {
            this.printers = data;
            this.getPrintCounts(data);
        });

        if (!localStorage.getItem(PREV_URL)) localStorage.setItem(PREV_URL, this.routerExtService.getPreviousUrl());

        // this.buildBubbles();

        this.templateModelControl.patchValue('');
    }

    public printReport(printer: string, mode: "CUPS" | "CHROME" = "CUPS") {
        let count = printer ? this.countDefault[printer] : 1;

        let instancesUIDs = '';
        this.selectedImages.forEach(img => instancesUIDs += img.url + ';');
        this.selectedReport.selectedInstancesUIDs = instancesUIDs;

        if (this.selectedReport.id === null) {
            this.selectedReport.reportData = this.selectedReport.reportData.map(kv => {
                return {key: kv.key, value: kv.value}
            });
        }

        this.service.sendCmd(new CommandService(this.documentKey)).subscribe(res0 => {
            ReportingEditComponent.showSavingMessage(res0);

            if ([0, 4].includes(res0['error'])) {

                this.service
                    .saveReportingTask(this.reportingTask)
                    .subscribe(reportingTask => {
                        this.initViewData(reportingTask);

                        let matSnackBarRef = this.snackBar.open(this.translate.instant('PRINTING_IN_PROGRESS'), '', {duration: 10000});

                        if (mode === 'CHROME') this.service.printSimpleReport(reportingTask.id, printer, count).subscribe(_ => matSnackBarRef.dismiss());
                        else {
                            this.service.printCupsSimpleReport(reportingTask.id, printer, count)
                                .subscribe(res => {
                                    matSnackBarRef.dismiss();
                                    if (res['status'] !== 'ok') alert('Cannot print the report');
                                    else this.snackBar.open(this.translate.instant('FINALIZING_PRINTING'), '', {duration: 3000});
                                });
                        }
                    });
            }
        });

    }

    ngOnInit() {
        this.getPathologies();
        this.getViewers();

        this.route
            .params.pipe(switchMap((params: Params) => this.service.getReportingTask(+params['id'])))
            .subscribe(reportingTask => this.initViewData(reportingTask));

        window.addEventListener('keydown', e => this.service.keyboardEventSubject.next(e), true);

        this.subs.push(this.techniqueControl.valueChanges
            .pipe(
                startWith(''),
                switchMap(() => {
                    let query = this.techniqueControl.value;
                    return this.shared.getPaginatedTechniques(10, 0, 'value', 'asc', query);
                }),
                map(data => data['content']),
                catchError(() => {
                    return observableOf([]);
                })
            ).subscribe(data => this.filteredTechniques = data));

        this.techniqueControl.patchValue('');
    }

    public sendMail() {
        let emailData = {
            id: this.selectedReport.id,
            patientName: this.reportingTask.patientName,
            patientEmail: this.reportingTask.patientEmail,
            referringPhysicianEmail: this.reportingTask.referringPhysicianEmail,
            reportTitle: this.selectedReport.name
        };

        this.dialog.open(EmailSendComponent, {
            width: '600px',
            data: emailData,
            hasBackdrop: false,
            disableClose: true,
            position: {bottom: '0', right: '80px'}
        }).afterClosed().subscribe(result => {
            if (result) {
                let status = get(result, 'service');
                if (status !== 'ERROR') {
                    this.snackBar.open(this.translate.instant('EMAIL_SENT'), '', {duration: 2500});
                    this.scheduleService
                        .updateReportingTaskStatus(this.reportingTask.id, 'DELIVERED')
                        .subscribe();
                }
            }
        })
    }

    public validateReport = (force_close: boolean = true) => {

        this.snackBar.open(this.translate.instant('SAVING'), '', {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top"
        });
        setTimeout(() => {
            this.service.sendCmd(new CommandService(this.documentKey)).subscribe(res => {
                ReportingEditComponent.showSavingMessage(res);

                if ([0, 4].includes(res['error'])) {
                    setTimeout(() => {
                        this.service
                            .validateReport(this.reportingTask)
                            .subscribe(res => {
                                this.reportingTask = res;
                                this.snackBar.open(this.translate.instant('REPORT_VALIDATED'), '', {duration: 2000});
                                this.reportSaveSub$ = this.reportSaved.asObservable().subscribe(ok => {
                                    if (ok && force_close) this.closeReport(res);
                                    else this.snackBar.open(this.translate.instant('NOT_SAVED_OR_NO_CHANGES'), '', {duration: 3000});
                                });
                            });
                    }, 1000);
                }
            });
        }, 2000);


    };

    private fetchSelectedImages(report: Report) {
        this.selectedImages = [];
        if (report.selectedInstancesUIDs) {
            let urls = report.selectedInstancesUIDs.split(';');
            let i = 1;
            urls.forEach(url => {
                if (url)
                    this.selectedImages.push({
                        index: i++,
                        fav: true,
                        title: url,
                        instanceUID: url,
                        url: `${url}`
                    })
            });
        }

    }

    public saveReport(same: boolean = true, aux: ReportingTask = null) {
        this.service.sendCmd(new CommandService(this.documentKey)).subscribe(res => {
            ReportingEditComponent.showSavingMessage(res);

            if ([0, 4].includes(res['error'])) {
                let instancesUIDs = '';
                this.selectedImages.forEach(img => instancesUIDs += img.url + ';');
                this.selectedReport.selectedInstancesUIDs = instancesUIDs;

                if (this.selectedReport.id === null) {
                    this.selectedReport.reportData = this.selectedReport.reportData.map(kv => {
                        return {key: kv.key, value: kv.value}
                    });
                }

                this.subs.push(this.service
                    .saveReportingTask(this.reportingTask)
                    .subscribe(reportingTask => {
                        this.snackBar.open(this.translate.instant('REPORT_SAVED'), '', {duration: 2000});
                        if (same) this.initViewData(reportingTask);
                        else {
                            this.selectedReport = null;
                            this.selectedModel = null;

                            this.navigateToTask(aux.id);
                        }
                    }));
            }
        });
    }

    openReportingTask(rt: ReportingTask) {
        this.openSelectedReportingTask(rt);
    }

    assignPerformer(row: ReportingTask) {
        this.dialog.open(PerformerAssignComponent, {data: {task: row, title: 'DELEGATE_TASK'}, minWidth: '380px'})
    }

    private buildTemplateSection(sec: Section): any {
        let fields: FieldConfig[] = [];

        let data = this.selectedReport.reportData;

        sec.elements.forEach(el => {
            fields.push({
                type: el.elementType,
                label: el.description,
                name: el.name,
                options: el.dictionary ? el.dictionary.values : null,
                placeholder: el.name,
                value: data ? get(data.find(v => v.key === el.name), 'value') : null,
                disabled: this.selectedReport.locked
            });
        });

        return {name: sec.title, fields: fields};
    }

    public setToReview = (force_close: boolean = true) => {
        this.service.sendCmd(new CommandService(this.documentKey)).subscribe(res => {
            ReportingEditComponent.showSavingMessage(res);
            if ([0, 4].includes(res['error'])) {
                this.subs.push(this.service
                    .toReview(this.reportingTask)
                    .subscribe(res => {
                        this.reportingTask = res;
                        this.reportSaveSub$ = this.reportSaved.asObservable().subscribe(ok => {
                            if (ok && force_close) this.closeReport(res);
                            else this.snackBar.open(this.translate.instant('NOT_SAVED_OR_NO_CHANGES'), '', {duration: 3000});
                        });
                    }));
            }

        });
    };

    editCount(printer: any) {
        this.dialog.open(PrintCountComponent, {
            disableClose: true,
            data: this.countDefault[printer]
        }).afterClosed().subscribe(count => {
            if (count != 0) {
                this.countDefault[printer] = count;
                localStorage.setItem(PRINT_COUNT, JSON.stringify(this.countDefault));
            }
        });
    }

    ngOnDestroy(): void {
        if (this.searchDialogRef) this.searchDialogRef.close();
        this.exitEditing();
        localStorage.removeItem(SELECTED_TASKS_KEY);
        localStorage.removeItem(PREV_URL);
        removeLocalPatientData();
        this.subs.forEach(sub => sub.unsubscribe());
        if (this.reportSaveSub$) this.reportSaveSub$.unsubscribe();
        if (this.wsSub$) this.wsSub$.unsubscribe();
    }

    private exitEditing() {
        return this.scheduleService.exitEditing(this.reportingTask.id).subscribe()
    }

    onChangeTechnique(event: MatAutocompleteSelectedEvent) {
        let technique = event.option.value;
        this.techniqueControl.patchValue(technique.value);

        this.reportingTask.technique = technique;

        this.updateLocalValue(technique);
    }

    private updateLocalValue(technique: Technique) {
        if (technique) updateVariableValue('%EXAM::TECHNIQUE%', technique.description);
    }

    editorFinishLoading($event: any) {
        if ($event) setTimeout(() => {
            this.editorLoaded = true;
        });
    }

    private getPrintCounts(data) {
        let printersObject = {};
        if (data && data.length !== 0) data.map(it => it.label).forEach(v => printersObject[v] = 1);
        this.countDefault = JSON.parse(localStorage.getItem(PRINT_COUNT)) || printersObject;
        localStorage.setItem(PRINT_COUNT, JSON.stringify(this.countDefault));
    }

    closeReport(rt: ReportingTask, forceClose: boolean = false) {

        this.service.closeViewerStudy(rt.studyInstanceUID).subscribe()

        let tasks = JSON.parse(localStorage.getItem(SELECTED_TASKS_KEY)) || [];
        remove(tasks, {id: rt.id});

        localStorage.setItem(SELECTED_TASKS_KEY, JSON.stringify(tasks));
        this.bubbles.next(tasks);

        if (this.reportingTask.id === rt.id && !!forceClose) {
            if (tasks.length !== 0) {
                this.openSelectedReportingTask(tasks[0])
            } else {
                this.saveReportAndExit();
            }
        } else this.onClose();
    }

    addTechnique() {
        this.dialog.open(TechniqueEditComponent).afterClosed().subscribe(res => {
            if (res) this.techniqueControl.patchValue(res.value);
        });
    }

    openViewer() {
        let params = `${this.defaultViewer.name}_${this.studyInstanceUID}`;

        if (window['viewerWindow'] && !window['viewerWindow'].closed) {
            window['viewerWindow'].focus();
            this.service.openStudy(this.studyInstanceUID, this.username, false).subscribe()
        } else {
            window['viewerWindow'] = window.open(`/external-viewer/study?param=${params}`, 'viewerWindow', 'toolbar=0,location=0,menubar=0,left');
            window['viewerWindow'].addEventListener('beforeunload', () => window['viewerWindow'] = null);
        }
    }

    editorReady(docKey: string) {
        this.editorIsReady = true;
        this.documentKey = docKey;
        this.selectReport(this.selectedReport)
    }

    getPatientImage() {
        let defaultImage: string;
        switch (this.patient.patientSex) {
            case 'M':
                defaultImage = `${IMAGES}/man.png`;
                break;
            case 'F':
                defaultImage = `${IMAGES}/woman.jpg`;
                break;
            default:
                defaultImage = `${IMAGES}/other.jpeg`;
                break;
        }

        return this._sanitizer.bypassSecurityTrustResourceUrl(this.patient.profilePicture || defaultImage);
    }

    searchReport() {
        if (this.searchDialogOpen) return;
        this.searchDialogRef = this.dialog.open(ReportingSearchComponent, {
            position: {
                bottom: '0', right: '100px'
            },
            panelClass: 'search-report',
            hasBackdrop: false
        });
        this.searchDialogRef.afterOpened().subscribe(res => {
            this.searchDialogOpen = true;
        });
        this.searchDialogRef.afterClosed().subscribe(res => {
            this.searchDialogOpen = false;
        });

    }

    // private buildBubbles() {
    //     let tasks = JSON.parse(localStorage.getItem(SELECTED_TASKS_KEY)) || [];
    //     this.bubbles.next(tasks);
    // }

    public setToSign = (force_close: boolean = true) => {
        this.service.sendCmd(new CommandService(this.documentKey)).subscribe(res => {
            ReportingEditComponent.showSavingMessage(res);
            if ([0, 4].includes(res['error'])) {
                this.subs.push(this.service
                    .toSign(this.reportingTask)
                    .subscribe(res => {
                        this.reportingTask = res;
                        this.reportSaveSub$ = this.reportSaved.asObservable().subscribe(ok => {
                            if (ok && force_close) this.closeReport(res);
                            else this.snackBar.open(this.translate.instant('NOT_SAVED_OR_NO_CHANGES'), '', {duration: 3000});
                        });
                    }));
            }

        });

    };

    getFileIcon(fileType: string): string {
        switch (fileType) {
            case 'WAV':
                return SVG_IMAGES + '/wav.svg';
            case 'PDF':
                return SVG_IMAGES + '/007-pdf-1.svg';
            case 'PNG':
                return SVG_IMAGES + '/011-png-1.svg';
            case 'JPG':
                return SVG_IMAGES + '/008-jpg.svg';
            case 'JPEG':
                return SVG_IMAGES + '/008-jpg.svg';
            case 'XLS':
                return SVG_IMAGES + '/004-excel.svg';
            case 'XLSX':
                return SVG_IMAGES + '/004-excel.svg';
            case 'TXT':
                return SVG_IMAGES + '/016-txt.svg';
            case 'DOC':
                return SVG_IMAGES + '/003-word.svg';
            case 'DOCX':
                return SVG_IMAGES + '/003-word.svg';
            default:
                return SVG_IMAGES + '/016-txt.svg';
        }
    }

    getPatient() {
        this.expanded = false;

        if (!this.patientDetails) forkJoin([
            this.patientService.getPatientByID(this.reportingTask.patientID),
            this.patientService.getPatientFiles(this.reportingTask.patientID),
        ]).subscribe(data => {
            [
                this.patientDetails,
                this.patientDetails.fileElements
            ] = data;
            this.expanded = true;
        });

        else if (this.patientExpand) this.expanded = false;
        else setTimeout(() => this.expanded = !this.expanded, 400);
    }

    public isActive(rt: ReportingTask): boolean {
        return this.reportingTask && rt.id === this.reportingTask.id;
    }

    downloadFile(file) {
        let filename = file.uuid + '.' + file.fileType.toLowerCase();
        window.open(`/api/documents/download/${filename}`, '_blank');
    }

    openElement(element: FileElement) {
        if (['PNG', 'JPG', 'JPEG'].includes(element.fileType)) {
            this.dialog.open(ImageViewerComponent, {
                data: {
                    images: this.patientDetails.fileElements.filter(elm => ['PNG', 'JPG', 'JPEG'].includes(elm.fileType)),
                    selected: element
                },
                minWidth: '100%',
                height: '100%',
                panelClass: 'image-viewer'
            });
        }

        if (['MP3', 'WAV', 'AAC'].includes(element.fileType)) {
            this.dialog.open(AudioPlayComponent, {
                data: {
                    audioFiles: this.patientDetails.fileElements.filter(elm => ['MP3', 'WAV', 'AAC'].includes(elm.fileType)),
                    selected: element
                },
                minWidth: '80%',
                height: '80%',
                panelClass: 'audio-play'
            }).afterClosed().subscribe(console.log);
        }

        if (element.fileType === 'TXT') this.dialog.open(SpeechNoteComponent, {
            data: element,
            panelClass: 'speech-panel',
            disableClose: true
        }).afterClosed().subscribe(res => {

        });

        if (element.fileType === 'PDF') this.dialog.open(FirePdfComponent, {data: element, panelClass: 'pdf-viewer'});
    }

    private getPatientInfos = (patientID: string) => this.subs.push(this.service
        .getPatientInfo(patientID)
        .subscribe({
            next: patientInfo => {

                // savePatientDataLocally(patientInfo, this.translate);
                // savePatientLocally(patientInfo);

                this.patientData = buildPatientDate(patientInfo);

                this.patient = assign({
                    patientID: patientInfo.patientID,
                    patientName: patientInfo.fullName,
                    patientAge: patientInfo.age,
                    patientSex: patientInfo.sex,
                    alerts: patientInfo.alerts,
                    imc: patientInfo.imc,
                    sc: patientInfo.sc,
                    id: patientInfo.id,
                    cin: patientInfo.cin,
                    phone: patientInfo.phone,
                    profilePicture: patientInfo.profilePicture,
                });
            },
            error: err => removeLocalPatientData()
        }));

    public setToTranscribe(force_close: boolean = true) {
        this.service.sendCmd(new CommandService(this.documentKey)).subscribe(res => {
            ReportingEditComponent.showSavingMessage(res);

            if ([0, 4].includes(res['error'])) {
                this.subs.push(this.service
                    .toTranscribe(this.reportingTask)
                    .subscribe(res => {
                        this.reportingTask = res;
                        this.reportSaveSub$ = this.reportSaved.asObservable().subscribe(ok => {
                            if (ok && force_close) this.closeReport(res);
                            else this.snackBar.open(this.translate.instant('NOT_SAVED_OR_NO_CHANGES'), '', {duration: 4000});
                        });
                    }));
            }
        });
    }

    public get historyCount(): number {
        if (this.reportingTasks && this.reportingTasks.length !== 0)
            return this.reportingTasks.length;
        return null;
    }

    public signReport(force_close: boolean = true) {

        this.snackBar.open(this.translate.instant('SAVING'), '', {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top"
        });

        setTimeout(() => {
            this.service.sendCmd(new CommandService(this.documentKey)).subscribe(res => {
                ReportingEditComponent.showSavingMessage(res);

                if ([0, 4].includes(res['error'])) {
                    setTimeout(() => {
                        this.subs.push(this.service
                            .signReport(this.reportingTask)
                            .subscribe(res => {
                                this.reportingTask = res;
                                this.snackBar.open(this.translate.instant('REPORT_SIGNED'), '', {duration: 2000});
                                this.reportSaveSub$ = this.reportSaved.asObservable().subscribe(ok => {
                                    if (ok && force_close) this.closeReport(this.reportingTask);
                                    else this.snackBar.open(this.translate.instant('NOT_SAVED_OR_NO_CHANGES'), '', {duration: 3000});
                                });
                            }));
                    }, 500);
                }
            });
        }, 2000);

    }

    public setToValidate = (force_close: boolean = true) => {
        this.service.sendCmd(new CommandService(this.documentKey)).subscribe(res => {
            ReportingEditComponent.showSavingMessage(res);
            if ([0, 4].includes(res['error'])) {
                this.subs.push(this.service
                    .toValidate(this.reportingTask)
                    .subscribe(res => {
                        this.reportingTask = res;
                        this.reportSaveSub$ = this.reportSaveSub$ = this.reportSaved.asObservable().subscribe(ok => {
                            if (ok && force_close) this.closeReport(res);
                            else this.snackBar.open(this.translate.instant('NOT_SAVED_OR_NO_CHANGES'), '', {duration: 3000});
                        });
                    }));
            }
        });

    };

    private initViewData(reportingTask: ReportingTask): void {

        this.subs.push(this.templateModelControl.valueChanges
            .pipe(
                debounceTime(400),
                startWith(''),
                switchMap(() => {
                    let query = /*(reportingTask.examCategory || '')+'@'+*/this.templateModelControl.value;
                    return this.shared.reportTemplateModels(10, 0, 'name', 'asc', query);
                }),
                map(data => data['content']),
                catchError(() => {
                    return observableOf([]);
                })
            ).subscribe(data => this.filteredTemplateModels = data));

        this.templateModelControl.patchValue('');


        this.reportingTask = reportingTask;
        this.taskType = reportingTask.reportingTaskType.value;

        this.reportEmpty = reportingTask.report.empty ? 'EMPTY' : 'NONE';

        if (reportingTask.technique) this.techniqueControl.patchValue(reportingTask.technique.value);

        this.scheduleService.startEditing(this.reportingTask.id).subscribe();
        setTimeout(() => {
            this.exitEditing()
        }, this.waitingTimeBeforeExit || this.defaultExitTime);

        let pathology = this.reportingTask.pathology;
        if (pathology) {
            this.pathologyControl.patchValue(this.reportingTask.pathology.split(','));
        } else this.pathologyControl.patchValue('');

        this.studyInstanceUID = reportingTask.studyInstanceUID;
        this.patientID = reportingTask.patientID;
        this.pacsPatientID = reportingTask.pacsPatientID;
        this.examDate = moment(reportingTask.scheduledProcedureStepStartDateTime).format('DD/MM/YYYY');
        this.releaseDate = reportingTask.completedReport.releaseDate ? moment(reportingTask.completedReport.releaseDate).format('DD/MM/YYYY') : '-';

        this.selectedReport = reportingTask.report;

        let examInfo = {
            studyInstanceUID: this.studyInstanceUID,
            pacsPatientID: this.pacsPatientID,
            examDate: this.examDate,
            signatureDate: this.releaseDate,
            code: reportingTask.procedureCodes,
            referringPhysician: reportingTask.referringPhysicianName,
            referringPhysicianAddress: reportingTask.referringPhysicianAddress,
            technique: reportingTask.technique ? reportingTask.technique.description : ''
        };

        // saveExamDataLocally(examData);

        this.examData = buildExamData(examInfo)

        if (this.selectedReport.locked) this.snackBar.open(this.translate.instant('CAN_NOT_MODIFY_DELIVERED'), '', {duration: 3000});

        this.getAllPatientReportingTasks(reportingTask.patientID);

        this.fetchSelectedImages(this.selectedReport);

        let templateModel = this.selectedReport.templateModel;

        this.selectReport(this.selectedReport);
        this.service.getSPSByInstances(this.studyInstanceUID).subscribe(sps => {
            if (sps) {

                this.getPatientInfos(sps.patientID);

                this.selectedModel = templateModel || sps.procedureCode.templateModel;
                this.selectedReport.templateModel = this.selectedModel;

                this.selectReport(this.selectedReport);

                this.instancesAvailable = sps.instancesAvailable;
            }
        });
    }


    private navigateToTask(id: number) {
        this.router.navigateByUrl(REPORTING_EDIT_URL + id).then(_ => console.log(id));
    }

    openSelectedReportingTask(rt: ReportingTask, waiting: boolean = false, history: boolean = false) {
        if (this.reportingTask.id === rt.id) return;

        if (!history) {
            let activeTasks = JSON.parse(localStorage.getItem(SELECTED_TASKS_KEY)) || [];

            if (!activeTasks.find(it => it.id === rt.id)) activeTasks.push(rt);

            this.bubbles.next(activeTasks);
            localStorage.setItem(SELECTED_TASKS_KEY, JSON.stringify(activeTasks));
        }

        if (!waiting) this.saveReport(false, rt);
    }

    editTechnique(technique: Technique) {
        this.dialog.open(TechniqueEditComponent, {data: technique}).afterClosed().subscribe(res => {
            if (res) this.techniqueControl.patchValue(res.value);
        });
    }

    deleteTechnique(technique: Technique) {
        this.dialog.open(DeleteConfirmComponent).afterClosed().subscribe(res => {
            if (res) this.shared.deleteTechnique(technique.id).subscribe(ok => this.techniqueControl.patchValue(''));
        });
    }

    private getViewers() {
        this.subs.push(this.setting.getViewers().subscribe(res => {
            this.osirixAETitle = res.filter(v => v.osirix).map(it => it.name)[0];
            this.defaultViewer = res.filter(v => v.defaultViewer)[0];
        }));
    }

    private retrieveImagesIfAvailable() {
        this.subs.push(this.service.isImagesAvailable((this.patientID == this.pacsPatientID ? this.patientID : this.pacsPatientID).trim(), this.studyInstanceUID)
            .subscribe(available => {
                this.imagesAvailable = available;
                if (!available) this.subs.push(this.service.retrieveStudy(this.studyInstanceUID).subscribe(res => this.imagesAvailable = res === 0))
            }));
    }

    insertVariable(key) {
        this.service.variableInsert.next(key);
    }

    hasPermission(permission: string): boolean {
        return get(this.profile, permission, 'NONE') != 'NONE'
    }

    sendSMS() {
        let {
            id,
            patientName,
            procedureCodes: procedureCode,
            patientPhone: patientPhoneNumber,
            scheduledProcedureStepStartDateTime: appointmentDateTime
        } = this.reportingTask;

        this.dialog.open(SmsSenderComponent, {
            data: {
                id,
                patientName,
                procedureCode,
                patientPhoneNumber,
                appointmentDateTime,
                source: 'REPORTING_TASK'
            }, minWidth: '360px'
        }).afterClosed().subscribe()
    }

    cleanAndRetrieve() {
        this.imagesAvailable = false;
        this.subs.push(this.service.cleanAndRetrieveStudy(this.studyInstanceUID, this.patientID).subscribe(res => this.imagesAvailable = res === 0))
    }

    showReport(item: ReportingTask, forced: boolean = false) {
        this.reportDialogVisible = true;
        this.reportDialogData = item;
        this.forcedDisplayDialog = forced;
    }

    closeDialogReport() {
        if (this.forcedDisplayDialog) return;
        this.reportDialogVisible = false;
    }

    onCloseReportPreview(ev: any) {
        this.reportDialogVisible = false;
    }
}


interface SRFlatNode {
    expandable: boolean;
    name: string;
    level: number;
}
