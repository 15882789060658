import {AfterViewInit, Component, Inject} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ReportingService} from '../../reporting/reporting.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {set} from 'lodash';
import {deleteItemFromArray} from "../shared-functions";
import {SharedService} from "../shared.service";
import {WsService} from "../../ws.service";
import {GeneralSetting} from "../../model";
import {forkJoin, Observable, of} from "rxjs";
import {FileService} from "../file-explorer";


@Component({
    selector: 'app-booklet-print',
    templateUrl: './booklet-print.component.html',
    styleUrls: ['./booklet-print.component.scss']
})
export class BookletPrintComponent implements AfterViewInit {
    layouts = ['1x1', '1x2', '2x2', '2x3', '2x4', '3x3', '3x4', '4x4', '4x5', '5x6'];

    form = new FormControl('1x1');
    selectedLayout: string = '1x1';

    studyInstanceUID: string;

    series = [];
    selectedImages = [];
    selectedImage: string;
    imagesArray: any[][] = [[]];
    private chunkSize: number = 1;
    imageBackground: string = 'BLACK';
    printOption: string = 'BOTH';
    numberOfCopies: number = 1;
    printers: any[] = [];
    generalSetting: Observable<GeneralSetting>;
    isAllLinked: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private service: ReportingService,
                private shared: SharedService,
                private _fileService: FileService,
                private wsService: WsService,
                private dialogRef: MatDialogRef<BookletPrintComponent>) {
        this.generalSetting = of(this.data.generalSetting);
        this.form.valueChanges.subscribe(value => {
            this.selectedLayout = value;

            if (value) {
                let sp = value.split('x');
                this.chunkSize = parseInt(sp[0], 0) * parseInt(sp[1], 0);

                this.imagesArray = BookletPrintComponent.chunkArray(this.selectedImages, this.chunkSize);
            }
        });

        let patientID = this.data.reportingTask.patientID;
        let pacsPatientID = this.data.reportingTask.pacsPatientID;

        this.studyInstanceUID = this.data.reportingTask.studyInstanceUID;
        this.chunkSize = this.data.reportingTask.numberOfImagesPerPage;
        this.printOption = this.data.reportingTask.printOption;

        this.getLayout(this.chunkSize);

        let selectedPaths = this.data.reportingTask.report.selectedInstancesUIDs ? this.data.reportingTask.report.selectedInstancesUIDs.split(';') : [];

        let patient_id = patientID == pacsPatientID ? patientID : pacsPatientID;
        this.service.getStudiesSeries(this.studyInstanceUID, patient_id.trim()).subscribe(res => {
            this.series = res.files;


            this.series.forEach(file => {
                file.images.forEach(it => {
                    if (selectedPaths.length) {
                        if (selectedPaths.find(x => x === it.path.replace('/thumb', ''))) {
                            set(it, 'path', it.path.replace('/thumb', ''));
                            this.selectedImages.push(it);
                        }
                    } else {
                        set(it, 'path', it.path.replace('/thumb', ''));
                        this.selectedImages.push(it);
                    }
                });
            });

            this.imagesArray = BookletPrintComponent.chunkArray(this.selectedImages, this.chunkSize);
        });
    }

    public get filteredSeries(): any[] {
        return this.series.filter(it => it.images.length != 0);
    }

    private static chunkArray(myArray, chunkSize): any {
        let arrayLength = myArray.length;
        let tempArray = [];

        for (let index = 0; index < arrayLength; index += chunkSize) {
            tempArray.push(myArray.slice(index, index + chunkSize));
        }

        return tempArray;
    }

    selectImage(image: any) {
        set(image, 'path', image.path.replace('/thumb', ''));
        if (this.selectedImages.includes(image)) deleteItemFromArray(this.selectedImages, image);
        else this.selectedImages.push(image);

        this.imagesArray = BookletPrintComponent.chunkArray(this.selectedImages, this.chunkSize);
    }

    isSelected(image: any) {
        return !!this.selectedImages.find(it => it.name === image.name && it.path === image.path);
    }

    validate(printer: any) {
        this.saveImages();
        this.dialogRef.close({
            layout: this.selectedLayout,
            numberOfImagesPerPage: this.chunkSize,
            images: this.selectedImages,
            imageBackground: this.imageBackground.toLowerCase(),
            printOption: this.printOption,
            printer,
            printCount: this.numberOfCopies
        });
    }

    private getLayout(chunkSize: number) {
        switch (chunkSize) {
            case 1:
                this.form.patchValue('1x1');
                break;
            case 2:
                this.form.patchValue('1x2');
                break;
            case 4:
                this.form.patchValue('2x2');
                break;
            case 6:
                this.form.patchValue('2x3');
                break;
            case 8:
                this.form.patchValue('2x4');
                break;
            case 9:
                this.form.patchValue('3x3');
                break;
            case 12:
                this.form.patchValue('3x4');
                break;
            case 15:
                this.form.patchValue('3x5');
                break;
            case 16:
                this.form.patchValue('4x4');
                break;
            case 20:
                this.form.patchValue('4x5');
                break;
            case 25:
                this.form.patchValue('5x5');
                break;
            default:
                this.form.patchValue('4x6');
                break;
        }
    }

    selectAll(file: any) {
        if (this.isAllSelected(file)) {
            file.images.forEach(img => {
                set(img, 'path', img.path.replace('/thumb', ''));
                if (this.selectedImages.includes(img)) deleteItemFromArray(this.selectedImages, img);
            });
            this.imagesArray = BookletPrintComponent.chunkArray(this.selectedImages, this.chunkSize);
        } else {
            file.images.forEach(img => {
                if (!this.selectedImages.includes(img)) this.selectImage(img);
            })
        }
    }

    isAllSelected(file: any): boolean {
        return file.images.map(it => this.selectedImages.includes(it)).reduce((p, c) => p && c, true);
    }

    ngAfterViewInit(): void {
        this.shared.getPrinters().subscribe(data => {
            this.printers = data;
        });
    }

    chromePrint() {
        this.saveImages();
        this.dialogRef.close({
            layout: this.selectedLayout,
            numberOfImagesPerPage: this.chunkSize,
            images: this.selectedImages,
            imageBackground: this.imageBackground.toLowerCase(),
            printOption: this.printOption,
            printCount: this.numberOfCopies
        });
    }

    private static dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {type: mimeString});
    }

    linkOrUnlinkAll() {
        this.selectedImages.forEach(it => it.synchronized = !this.isAllLinked);
        this.isAllLinked = !this.isAllLinked;
    }

    uploadImageFiles(files: File[]): Observable<any> {
        // start the upload and save the progress map
        let progress = this._fileService.uploadImageFiles(files);

        // convert the progress map into an array
        let allProgressObservables = [];
        for (let key in progress) allProgressObservables.push(progress[key]['progress']);

        // When all progress-observables are completed...
        return forkJoin(allProgressObservables);
    }


    saveImages() {
        let imagesArray:any[]=[];
        const canvas_list:any = document.querySelectorAll('.cornerstone-canvas canvas');
        canvas_list.forEach(canvas => imagesArray.push({blobData: BookletPrintComponent.dataURItoBlob(canvas.toDataURL()), filename: canvas.parentElement.id}));

        let images = imagesArray.map(img => new File([img.blobData], img.filename.replace('\\', '/'), {type: 'image/jpg'}));

        this.uploadImageFiles(images).subscribe(value => {
            if (value[value.length - 1] === 100) {
                console.log('upload done!');
            }
        });
    }
}
