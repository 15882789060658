import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, merge, of as observableOf, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {assign, get, sortBy, union} from 'lodash';
import * as $ from 'jquery';
import * as moment from 'moment';
import {EXTERNAL_APPOINTMENTS_TABLE_CONF} from './table-conf';
import {SchedulerService} from '../scheduler.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {catchError, debounceTime, filter, map, startWith, switchMap} from 'rxjs/operators';
import {DeleteConfirmComponent, LocalStorageService, SharedService} from '../../shared';
import {tableAnimation} from '../../animations';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {DateUtils} from '../../utils';
import {WsService} from '../../ws.service';
import {ExamAdvancedComponent} from '../exam-advanced/exam-advanced.component';
import {Appointment} from '../../model';
import {
    APPOINTMENT_STATUSES,
    AptStatus,
    getOrderStatusColor,
    getOrderStatusIcon,
    OrderFilter,
    SOURCES_COLORS
} from './util';
import {AppointmentEditComponent} from '../appointment-edit/appointment-edit.component';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-external-appointments',
    templateUrl: './external-appointments.component.html',
    styleUrls: ['./external-appointments.component.scss'],
    animations: [tableAnimation]
})
export class ExternalAppointmentsComponent implements AfterViewInit, OnInit, OnDestroy {

    cols = [];
    physicians: any[] = [];
    aets: any[] = [];
    modalities: any[] = [];
    displayedColumns = EXTERNAL_APPOINTMENTS_TABLE_CONF;
    columnsToDisplay: string[] = [];

    appointmentStatuses = APPOINTMENT_STATUSES;
    profile: any;

    canViewConfData: boolean;

    resultsLength = 0;
    isLoadingResults = true;

    isResultsLoaded = false;

    dataSource = new MatTableDataSource<Appointment>();

    filterForm: FormGroup;

    filterChange = new BehaviorSubject('');
    @ViewChild('filter', {static: true}) filter: ElementRef;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    private orderFilter = new OrderFilter();
    private orderFilterSubject = new BehaviorSubject<OrderFilter>(new OrderFilter());
    todayFormat = 'HH:mm';
    private query = new BehaviorSubject<string>(null);
    defaultPageSize = 50;
    private subs: Subscription[] = [];
    private reasonForExams = [];

    constructor(private service: SchedulerService,
                private shared: SharedService,
                private localStorage: LocalStorageService,
                private wsService: WsService,
                private dialog: MatDialog,
                private fb: FormBuilder,
                private snackBar: MatSnackBar,
                private router: Router,
                private _translate: TranslateService,
                private route: ActivatedRoute) {
        this.displayedColumns = EXTERNAL_APPOINTMENTS_TABLE_CONF;

        this.columnsToDisplay = sortBy(EXTERNAL_APPOINTMENTS_TABLE_CONF.filter(item => !item.hidden), 'order').map(it => it.label);
        this.columnsToDisplay = union(this.columnsToDisplay, ['action']);


        this.createFilterForm();
        this.shared.getReasonForExams().subscribe(res => this.reasonForExams = res);

        this.defaultPageSize = this.localStorage.getItem('order_page_size') || 50;

        const user = get(this.route.snapshot.data, 'user');
        this.profile = get(user, 'profile');
        this.canViewConfData = user.canViewConfidentialData;

        this.wsService.observeTopic('scheduler').subscribe(res => {
            if (res.topic === 'scheduler') this.orderFilterSubject.next(this.orderFilter)
        });
    }

    sourceColors = SOURCES_COLORS;

    ngAfterViewInit() {
        this.buildQuery();
        this.resetPaginator();

        let observedFilters = [
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.query.pipe(debounceTime(250))
        ];

        merge(...observedFilters)
            .pipe(
                startWith(''),
                filter(it => it !== ''),
                switchMap((query) => {

                    this.isLoadingResults = true;
                    this.localStorage.setItem('order_page_size', this.paginator.pageSize);
                    return this.service.getExternalAppointments(
                        this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        query);
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isResultsLoaded = false;
                    this.resultsLength = data.totalElements;
                    return data.content;
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isResultsLoaded = true;
                    return observableOf([]);
                })
            ).subscribe((data: Appointment[]) => {
            this.dataSource.data = data;
        });
    }

    editAppointment(apt) {
        this.subs.push(this.dialog.open(AppointmentEditComponent, {
            data: apt,
            disableClose: true
        }).afterClosed().subscribe(nx => {
            if (nx) this.service
                .updateAppointmentStatus(apt.id, AptStatus.planned)
                .subscribe(data => {
                    this.orderFilterSubject.next(this.orderFilter);
                    this.snackBar.open(this._translate.instant('APPOINTMENT_UPDATED'), '', {duration: 2000});
                });

        }));
    }

    trackById(index: number, item: any): string {
        return item.id;
    }

    private resetPaginator = () => this.query.subscribe(() => this.paginator.pageIndex = 0);

    filterData(filter: any): any {
        return assign(filter, {
            start: filter.start ? moment(filter.start).format('YYYYMMDD') : moment().startOf('year').format('YYYYMMDD'),
            end: filter.end ? moment(filter.end).format('YYYYMMDD') : moment().endOf('year').format('YYYYMMDD'),
        });
    }

    printAptsList() {
        this.service.printAppointments(this.filterData(this.filterForm.getRawValue())).subscribe(ok => {
            if (ok) this.showPreview(`/templates/appointment-table.html?v=${moment().format('YYYYMMDDHHmmssSSS')}`);
            else console.log('Cannot print the report');
        })
    }

    showPreview(url) {
        let $frame = $('<iframe />').attr('src', url).css({position: 'absolute', top: '-9999px'});
        $frame.on('load', () => setTimeout(() => $frame.remove(), 0));
        $(document.body).append($frame);
    }

    printRDV(sps) {
        this.service.printRDV(sps.id).subscribe(ok => {
            if (ok) {
                this.showPreview(`/templates/rdv.html?v=${moment().format('YYYYMMDDHHmmssSSS')}`);
            } else {
                this.snackBar.open('Warning: Can\'t print the RDV', 'Ok', {duration: 2000});
            }
        });
    }

    onScheduleAppointment(apt: Appointment) {
        this.dialog.open(ExamAdvancedComponent, {
            data: {
                spsStatus: 'ARRIVED',
                resource: 'n/a',
                patient: get(apt, 'patient'),
                isr: apt,
                selectedDateRange: {start: moment(), end: moment().add(15, 'h')},
                editable: true,
                queryParam: null,
                panelClass: 'exam-dialog',
                is_external: true
            },
            disableClose: true
        }).afterClosed().subscribe(res => {
            if (res) {
                this.service
                    .updateAppointmentStatus(apt.id, AptStatus.entered)
                    .subscribe();
            }
        });
    }

    openPatientFolder(row) {
        this.router.navigate(['/patients/details', row.patientId])
    }

    onCancelAppointment(apt) {
        this.subs.push(this.dialog
            .open(DeleteConfirmComponent)
            .afterClosed()
            .subscribe(ok => {
                if (ok) {
                    this.service
                        .updateAppointmentStatus(apt.id, AptStatus.canceled)
                        .subscribe(() => this.orderFilterSubject.next(this.orderFilter));
                }
            }));
    }

    _get = (row, value: string): any => get(row, value);

    isEditable(apt): boolean {
        return ['unplanned', 'waiting_list'].includes(apt.appointmentStatus)
    }

    deleteApt(apt) {
        this.subs.push(this.dialog
            .open(DeleteConfirmComponent)
            .afterClosed()
            .subscribe(ok => {
                if (ok) {
                    this.service
                        .deleteAppointment(apt.id)
                        .subscribe(() => this.orderFilterSubject.next(this.orderFilter));
                }
            }));
    }

    ngOnDestroy() {
        this.subs.forEach(it => it.unsubscribe());
    }

    exportXLS() {
        let ids = this.dataSource.data.map(sps => sps['id']).join('.') || 'none';
        window.open(`/api/shared/exportAppointments/${ids}/appointments.xls`, '_blank');
    }

    ngOnInit() {
        this.filter.nativeElement.focus();
    }

    isEventEditable(sps): boolean {
        return this.profile.updateOrder && (['STARTED', 'DEPARTED', 'FINISHED'].indexOf(sps.scheduledProcedureStepStatus) === -1);
    }

    filterByRange(event) {
        let today = moment().format('YYYYMMDD');
        let weekStart = moment().startOf('isoWeek').format('YYYYMMDD');
        let monthStart = moment().startOf('month').format('YYYYMMDD');
        let end;

        switch (event.value) {
            case 0:
                this.filterForm.patchValue({start: today, end: today});
                break;
            case 1:
                end = moment().startOf('isoWeek').add(7, 'd').format('YYYYMMDD');
                this.filterForm.patchValue({start: weekStart, end: end});
                break;
            case 2:
                end = moment().startOf('month').add(30, 'd').format('YYYYMMDD');
                this.filterForm.patchValue({start: monthStart, end: end});
                break;
            default:
                this.filterForm.patchValue({start: today, end: today});
        }
    }

    canOpenPatientFolder(sps): boolean {
        return this.profile && this.profile.updatePatient && (this.canViewConfData || !sps.confidential);
    }

    isPrintable(sps): boolean {
        return moment(sps.scheduledProcedureStepStartDate).isAfter(moment(), 'day');
    }

    showPatientName(row, label): string {
        return !row.confidential || this.canViewConfData ? row[label] : '**** ****';
    }

    getOrderStatusIcon = (status: string): string => getOrderStatusIcon(status);
    getOrderStatusColor = (status: string): string => getOrderStatusColor(status);

    changePeriod() {
        this.filterForm.get('period').patchValue('OT');
    }

    changeRange(e: MatButtonToggleChange) {
        let dateRange = DateUtils.PeriodDateRange(e.value);
        this.filterForm.patchValue(dateRange);
        this.orderFilter.dateRange = `${dateRange.startDate.format('YYYYMMDD')}-${dateRange.endDate.format('YYYYMMDD')}`;
        this.orderFilterSubject.next(this.orderFilter);
    }

    getExamColor(value: string) {
        let exam = this.reasonForExams.find(it => it.value === value);
        return exam ? exam.color : '';
    }

    private createFilterForm() {
        this.filterForm = this.fb.group({
            key: '',
            startDate: new Date(),
            endDate: new Date(),
            period: 'TODAY',
            physicianId: null,
            appointmentStatus: null,
        });

        this.filterForm.valueChanges.subscribe(value => this.buildOrderFilter(value));
    }

    private buildOrderFilter(value: any) {
        this.todayFormat = value.period === 'TODAY' ? 'HH:mm' : 'dd/MM/yyyy HH:mm';

        let startDate = moment(value.startDate).isValid() ? moment(value.startDate) : moment().subtract(10, 'year');
        let endDate = moment(value.endDate).isValid() ? moment(value.endDate) : moment().add(10, 'd');

        let start = startDate.format('YYYYMMDD');
        let end = endDate.format('YYYYMMDD');

        this.orderFilter.key = value.key;
        this.orderFilter.dateRange = `${start}-${end}`;
        let oStatus = value.appointmentStatus;
        this.orderFilter.appointmentStatus = oStatus && oStatus.length !== 0 ? oStatus.join('-') : 'ALL';
        this.orderFilter.physicianId = value.physicianId;

        this.orderFilterSubject.next(this.orderFilter);
    }

    private buildQuery() {
        this.orderFilterSubject
            .asObservable()
            .subscribe((orderFilter: OrderFilter) => {
                this.query.next([orderFilter.key.replace('@', ''), orderFilter.dateRange, orderFilter.physicianId, orderFilter.appointmentStatus].join('@'))
            });
    }
}
