import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PRINTER_URL, SCHEDULER_URL, STATISTIC_URL} from '../urls';
import {BillingState, ChartData, StatisticDataModel} from './model';
import {Payment, PaymentState} from '../model';
import {tap} from "rxjs/operators";
import {printBlob} from "../utils";
import * as moment from 'moment';

@Injectable()
export class StatisticService {

    constructor(private http: HttpClient) {
    }

    getExamsPerField(field: string): Observable<ChartData> {
        return this.http.get<ChartData>(`${STATISTIC_URL}/exams-per-${field}`);
    }

    exportToPdf(field: string, filter: string = 'none') {
        window.open(`/api/statistic/exams-${field}.pdf?filter=${filter}`, '_blank');
    }

    exportToCsv(field: string, filter: string = 'none') {
        window.open(`/api/statistic/exams-${field}.xls?filter=${filter}`, '_blank');
    }

    exportPatientsToCsv(field: string, filter: string = 'none') {
        window.open(`/api/statistic/patients-${field}.xls?filter=${filter}`, '_blank');
    }

    getPatientsPerField(field: string): Observable<ChartData> {
        return this.http.get<ChartData>(`${STATISTIC_URL}/patients-per-${field}`);
    }

    getGlobalStatistics(): Observable<StatisticDataModel[]> {
        return this.http.get<StatisticDataModel[]>(`${STATISTIC_URL}/global`);
    }

    getBillingState(dateRange?: string): Observable<BillingState> {
        let params = new HttpParams().set('dateRange', dateRange);
        return this.http.get<BillingState>(`${STATISTIC_URL}/billingState`, {params: params});
    }

    getPatientSummary(year: number, month: number): Observable<any> {
        let params = {year: String(year), month: String(month)};
        return this.http.get(`${STATISTIC_URL}/patientSummary`, {params})
    }

    getPhysicianExams(filter: string): Observable<any> {
        let params = {filter};
        return this.http.get(`${STATISTIC_URL}/physicianExams`, {params})
    }

    getTechniciansExams(filter: string): Observable<any> {
        let params = {filter};
        return this.http.get(`${STATISTIC_URL}/techniciansExams`, {params})
    }

    getExamsByAet(dateRange: string): Observable<any> {
        let params = {dateRange};
        return this.http.get(`${STATISTIC_URL}/examsByAet`, {params})
    }

    getExamsByType(dateRange: string): Observable<any> {
        let params = {dateRange};
        return this.http.get(`${STATISTIC_URL}/examsByType`, {params})
    }

    getDailyAetExams(year: number): Observable<any> {
        let params = {year: String(year)};
        return this.http.get(`${STATISTIC_URL}/dailyAetExams`, {params})
    }

    getReferringPhysiciansExams(year: number): Observable<any> {
        let params = {year: String(year)};
        return this.http.get(`${STATISTIC_URL}/referringPhysiciansExams`, {params})
    }

    getTodayPayments(date: string): Observable<Payment[]> {
        return this.http.get<Payment[]>(`${SCHEDULER_URL}/getTodayPayments`, {params: {date}})
    }

    todayPayments(date: string, filter: string): Observable<PaymentState> {
        return this.http.get<PaymentState>(`${SCHEDULER_URL}/todayPayments`, {params: {date, filter}})
    }

    todayExamsByType(): Observable<any> {
        return this.http.get(`${STATISTIC_URL}/todayExamsByType`)
    }

    getPatientStates(dateRange: string): Observable<any> {
        let params = {dateRange};
        return this.http.get(`${STATISTIC_URL}/patientStates`, {params});
    }

    getExamsPerReferringPhysicians(pageSize: number, pageIndex: number, sort: string, direction: string, dateRange: string, physicianId: string): Observable<any> {
        let params = {
            page: String(pageIndex),
            size: String(pageSize),
            sort: `${sort},${direction}`,
            dateRange,
            physicianId
        };
        return this.http.get(`${STATISTIC_URL}/examsPerReferringPhysician`, {params});
    }

    exportData(userType: string, userId: any, startDate: string, endDate: string, toType: 'PDF' | 'EXCEL'): Observable<any> {
        let params = {userType, userId, startDate, endDate, toType};
        return this.http.get(`${STATISTIC_URL}/export-data`, {params, responseType: "blob"})
    }

    printState(date: string, filter: string, withDetails: any): Observable<any> {
        return this.http.get(`${PRINTER_URL}/printState?v=${moment().format('YYYYMMDDHHmmssSSS')}`, {
            params: {
                date,
                filter,
                withDetails
            }, responseType: 'blob'
        }).pipe(tap(printBlob));
    }

    printAnteriorState(): Observable<any> {
        return this.http.get(`${PRINTER_URL}/printAnteriorState`, {responseType: 'blob'}).pipe(tap(printBlob));
    }
}
