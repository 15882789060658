import {BehaviorSubject, merge as observableMerge, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {SettingService} from '../setting.service';
import {deleteItemFromArray, SharedService} from '../../shared';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {PriorityEditComponent} from './priority-edit/priority-edit.component';

import * as _ from 'lodash';
import {PRIO_HEADER_COLS, PRIO_TABLE_CONF} from './table-conf';


import {DataSource} from '@angular/cdk/collections';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-order-priority-setting',
    templateUrl: './order-priority-setting.component.html',
    styleUrls: ['./order-priority-setting.component.scss'],
    animations: [
        trigger('state', [
            transition('void => processing',
                animate('525ms cubic-bezier(0.4, 0.0, 0.2, 1)', keyframes([
                    style({minHeight: '0px', overflow: 'hidden', height: '0px'}),
                    style({minHeight: '*', overflow: 'inherit', height: '*'})
                ])))
        ])
    ]
})
export class OrderPrioritySettingComponent implements OnInit {

    cols: any[];
    displayedColumns = [];

    dataSource: PriorityDataSource | null;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private service: SettingService, private sharedService: SharedService, public dialog: MatDialog) {
        this.sharedService.getPriorities().subscribe(
            data => {
                let items = _.map(data, tsk => {
                    return _.reduce(this.displayedColumns, (obj, field) => {
                        obj[field.label] = _.get(tsk, field.value, field.defaultValue);
                        obj['dateRange'] = _.get(tsk, 'dateRange');
                        return obj
                    }, {});
                });

                this.dataSource.dataChange.next(items);
            }
        );
    }

    editPriority(priority) {
        let dialogRef = this.dialog.open(PriorityEditComponent);
        dialogRef.componentInstance.selectedPriority = priority;

        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                if (priority.id) deleteItemFromArray(this.dataSource.data, priority);

                this.dataSource.data.push(res);
                this.dataSource.dataChange.next(this.dataSource.data);
            }
        });
    }

    ngOnInit() {

        this.displayedColumns = PRIO_TABLE_CONF;
        this.cols = PRIO_HEADER_COLS;

        this.dataSource = new PriorityDataSource(this.paginator, this.sort);
    }

}


export class PriorityDataSource extends DataSource<any> {

    filteredData: PriorityItem[] = [];
    renderedData: PriorityItem[] = [];

    /** Stream that emits whenever the data has been modified. */
    dataChange: BehaviorSubject<PriorityItem[]> = new BehaviorSubject<PriorityItem[]>([]);

    constructor(private _paginator: MatPaginator, private _sort: MatSort) {
        super();
    }

    get data(): PriorityItem[] {
        return this.dataChange.value;
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<PriorityItem[]> {
        const displayDataChanges = [
            this.dataChange,
            this._paginator.page,
            this._sort.sortChange,
        ];

        return observableMerge(...displayDataChanges).pipe(map(() => {


            // Filter data
            this.filteredData = this.data.slice().filter((item: PriorityItem) => {
                return true;
            });

            // Sort filtered data
            const sortedData = this.sortData(this.filteredData.slice());

            // Grab the page's slice of the filtered sorted data.
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
            return this.renderedData;
        }));
    }

    disconnect() {
    }

    /** Returns a sorted copy of the database data. */
    sortData(data: PriorityItem[]): PriorityItem[] {
        if (!this._sort.active || this._sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._sort.active) {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'value':
                    [propertyA, propertyB] = [a.value, b.value];
                    break;
                case 'description':
                    [propertyA, propertyB] = [a.description, b.description];
                    break;
                case 'startDate':
                    [propertyA, propertyB] = [a.startDate, b.startDate];
                    break;
                case 'endDate':
                    [propertyA, propertyB] = [a.endDate, b.endDate];
                    break;
            }

            let valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            let valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }


}


export interface PriorityItem {
    id: string;
    value: string;
    description: string;
    startDate: string;
    endDate: string;
    color: string;
    dateRange: DateRange;
}

export interface DateRange {
    id: string;
    startDate: string;
    endDate: string;
}
