<div fxLayout="column" fxLayoutAlign="center center">
    <div class="grid-parent">
        <div *ngFor="let state of dataMap" class="mat-elevation-z3">
            <div class="a-table">
                <div class="a-row a-header">
                    <div>{{state.label | uppercase | translate}}</div>
                    <div>{{'TOTAL' | translate}}</div>
                </div>
                <div *ngFor="let val of state.data" class="a-row">
                    <div>{{val.value}}</div>
                    <div style="text-align: center">{{val.count}}</div>
                </div>
            </div>
            <div>
                <canvas baseChart
                        [data]="state.chartData"
                        [options]="barChartOptions"
                        [plugins]="barChartPlugins"
                        [legend]="!state.chartType.endsWith('ar')"
                        [type]="state.chartType">
                </canvas>
            </div>
        </div>
    </div>
</div>



