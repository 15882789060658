<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-pencil-box-outline"></mat-icon>
    <h3 mat-dialog-title>{{'EDITSPS' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <div class="sps-form" [formGroup]="spsForm">

        <div class="exam-code">
            <div class="exam-label">
                Patient:
            </div>
            <div>
                {{spsForm.getRawValue().patientName || '-'}}
            </div>
            <div class="exam-label">
                {{'EXAM' | translate}}:
            </div>
            <div>
                {{spsForm.get('procedureCode').value.code || '-'}}
            </div>
        </div>

        <mat-divider></mat-divider>

        <div fxLayout="row" fxLayoutGap="4px" style="padding-top: 12px">

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'START_DATE' | translate}}</mat-label>
                <input matInput [matDatepicker]="myDatepicker" placeholder="{{'START_DATE' | translate}}"
                       formControlName="scheduledProcedureStepStartDate">
                <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'START_TIME' | translate}}</mat-label>
                <input matInput type="time" required placeholder="{{'START_TIME' | translate}}"
                       formControlName="scheduledProcedureStepStartTime">
            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutGap="4px">

            <mat-form-field appearance="standard" fxFlex formGroupName="scheduledPerformingPhysiciansName">
                <mat-label>{{'PERFORMING_RADIOLOGIST' | translate}}</mat-label>
                <mat-select placeholder="{{'PERFORMING_RADIOLOGIST' | translate}}" formControlName="id">
                    <mat-option *ngFor="let stf of staffList" [value]="stf.id">
                        {{stf.fullName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex formGroupName="scheduledStationAETitle">
                <mat-label>{{'AET' | translate}}</mat-label>
                <mat-select placeholder="{{'AET' | translate}}" formControlName="id">
                    <mat-option *ngFor="let aet of aets" [value]="aet.id">
                        {{aet.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <mat-form-field appearance="standard" fxFlexFill>
            <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
            <textarea matInput placeholder="{{'DESCRIPTION' | translate}}"
                      formControlName="scheduledProcedureStepDescription"></textarea>
        </mat-form-field>

    </div>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="start none" fxLayoutGap="4px">
    <button (click)="onSelectProcedure()" color="primary" mat-raised-button>
        <mat-icon fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
        {{'CHANGE_EXAM' | translate}}
    </button>

    <span class="fire-spacer"></span>

    <button [mat-dialog-close]="null" color="accent" mat-raised-button>{{'CANCEL' | translate}}</button>
    <button (click)="saveSPS()" mat-button color="primary" [disabled]="spsForm.invalid">
        {{'SAVE' | translate}}
    </button>
</mat-dialog-actions>
