<div fxLayout="column" fxFill>

    <div class="outer-container">
        <button (click)="close()" mat-icon-button color="accent">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
        </button>

        <div class="inner-container">
            <p class="text-p" *ngFor="let p of reportText">{{p}}</p>
        </div>

    </div>

</div>
