<input #fileUpload (change)="onFileSelected($event)" accept="image/*"
       class="file-input" type="file">
<div class="parent-container">
    <div class="messaging-container" fxLayout="row" fxLayoutAlign="space-between">
        <mat-menu #roomMenu="matMenu" overlapTrigger="true">
            <button (click)="deleteRoom(roomFromMenu)" mat-menu-item>{{'DELETE' | translate}}</button>
        </mat-menu>

        <!--    Side nav-->
        <div class="messaging-sidenav" fxFlex="20%" fxHide.lt-sm fxLayout="column" fxLayoutAlign="start stretch">
            <div fxFlex="48px" fxLayout="row" fxLayoutAlign="start center">
                <input (input)="filterRooms($event)" [placeholder]="'SEARCH' | translate" fxFlex type="search">
            </div>
            <div class="contacts" fxFlex>
                <div (click)="openRoom(room)" *ngFor="let room of filteredRooms"
                     [class.active-room]="room?.id == selectedRoom?.id"
                     class="contact" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <div [style.backgroundColor]="getContact(room)?.color"
                         [matBadge]="(selectedRoom?.id == room.id) || (room.lastMessage?.sender.id === me.id) ? null: room.unread"
                         class="chat-head"
                         matBadgeColor="accent"
                         matBadgeOverlap="true"
                         matBadgeSize="small">{{getContact(room)?.name | initials}}</div>
                    <div class="chat-text">
                        <div class="contact-name" fxLayout="row" fxLayoutAlign="space-between">
                            {{getContact(room)?.name}}
                            <span fxFlex></span>
                            <span class="last-update">{{room?.lastMessage?.sentDateTime | date: 'dd/MM/yyyy'}}</span>
                        </div>
                        <div class="room-last-message">

                            <ng-container *ngIf="!typing[getContact(room).userId]; else isTyping"
                                          [ngSwitch]="room.lastMessage?.deletedFor">
                                <div *ngSwitchCase="'ALL'" class="message-deleted">
                                    <mat-icon fontIcon="mdi-cancel" fontSet="mdi"></mat-icon>
                                    {{'MESSAGE_DELETED' | translate}}
                                </div>
                                <div *ngSwitchCase="me.userId" class="message-deleted">
                                    <mat-icon fontIcon="mdi-cancel" fontSet="mdi"></mat-icon>
                                    {{'MESSAGE_DELETED' | translate}}
                                </div>
                                <div *ngSwitchDefault fxLayout="row nowrap" fxLayoutAlign="start center">
                                    <mat-icon *ngIf="room.lastMessage && messageIsMine(room.lastMessage)"
                                              [class.seen]="room.lastMessage?.seenDateTime"
                                              [fontIcon]="room.lastMessage?.seenDateTime ? 'mdi-check-all': 'mdi-check'"
                                              class="message-status"
                                              fontSet="mdi"></mat-icon>

                                    <div [innerHTML]="room.lastMessage?.messageType === 'TEXT' ? (room.lastMessage?.text | emojisDisplay) : (room.lastMessage?.messageType | translate)"
                                         fxLayout="row nowrap" fxLayoutAlign="start center"></div>

                                </div>
                            </ng-container>
                            <ng-template #isTyping>
                                <div class="typing">{{'TYPING' | translate}}</div>
                            </ng-template>

                        </div>
                    </div>
                    <button (click)="$event.stopPropagation();roomFromMenu = room" [matMenuTriggerFor]="roomMenu"
                            mat-icon-button>
                        <mat-icon fontIcon="mdi-chevron-down" fontSet="mdi"></mat-icon>
                    </button>
                </div>
            </div>

            <div fxFlex="48px" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
                <button (click)="newMessage()" color="primary" mat-button>
                    <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                    {{'NEW_CHAT' | translate}}
                </button>
            </div>
        </div>

        <ng-template #emptyContainer>
            <div [style.height.%]="100" fxFlex fxLayout="column" fxLayoutAlign="center center">
                <button (click)="newMessage()" color="primary" mat-button>
                    <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                    {{'NEW_CHAT' | translate}}</button>
            </div>
        </ng-template>

        <!--    Container-->
        <div *ngIf="selectedRoom; else emptyContainer" class="messaging-content" fxFlex fxLayout="column">
            <div *ngIf="!searching; else searchTemplate" fxFlex="48px" fxLayout="row" fxLayoutAlign="start center"
                 fxLayoutGap="8px">
                <div [style.backgroundColor]="currentContact?.color"
                     class="chat-head">{{currentContact?.name | initials}}</div>
                <div class="chat-text">
                    <div [style.fontWeight]="'bold'" class="contact-name">{{currentContact?.name}}</div>
                    <div *ngIf="currentContact?.online && !typing[currentContact.userId]">
                        <mat-icon class="online-status" fontIcon="mdi-circle" fontSet="mdi"></mat-icon>
                        {{'ONLINE' | translate}}
                    </div>
                    <div *ngIf="typing[currentContact.userId]" class="typing">
                        {{'TYPING' | translate}}
                    </div>
                </div>
                <span fxFlex></span>
                <mat-menu #chatMenu="matMenu" overlapTrigger="true">
                    <button [disabled]="true" mat-menu-item>
                        {{'CHAT_MEDIA' | translate}}</button>
                    <button (click)="selectionActivated = !selectionActivated; selectedMessages = []" mat-menu-item>
                        {{(selectionActivated ? 'UNSELECT_ALL' : 'SELECT_MESSAGES') | translate}}
                    </button>
                </mat-menu>
                <button (click)="searching = true" [matTooltip]="'SEARCH' | translate" mat-icon-button>
                    <mat-icon fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
                </button>
                <button [matMenuTriggerFor]="chatMenu" mat-icon-button>
                    <mat-icon fontIcon="mdi-dots-vertical" fontSet="mdi"></mat-icon>
                </button>
            </div>

            <ng-template #searchTemplate>
                <div class="message-search-container" fxFlex="48px" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
                    <input (input)="filterMessages($event)" [placeholder]="'SEARCH' | translate" type="search">
                    <button (click)="searching = false" mat-icon-button>
                        <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                    </button>
                </div>
            </ng-template>

            <div class="messages-feed" fxFlex fxLayout="column" fxLayoutAlign="start" fxLayoutGap="4px" id="message-feed">

                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-spinner *ngIf="isLoading" [diameter]="30" [strokeWidth]="3" color="primary"></mat-spinner>
                </div>

                <div *ngFor="let group of  currentMessages | groupBy: 'sentDateTime'; trackBy: trackFn">

                    <div fxLayout="row" fxLayoutAlign="center center"><span
                            class="date-group">{{group.key | date: 'dd/MM/yyyy'}}</span></div>


                    <div *ngFor="let message of group?.values"
                         [fxLayoutAlign]="message.sender.id === me.id ? 'end': 'start'"
                         class="message-box" fxLayout="row">

                        <mat-checkbox (change)="selectMessage(message, $event.checked)"
                                      *ngIf="selectionActivated; else messageData"
                                      [labelPosition]="message?.sender.id === me.id ? 'before':'after'"
                                      color="primary" fxFlexAlign="center">
                            <!-- Message data -->
                            <div [class.right]="messageIsMine(message)" class="message">

                                <div *ngIf="message.replyTo && !message.deleted"
                                     [style.borderLeftColor]="'#35cd96'"
                                     class="reply-to-msg"
                                     fxLayout="row"
                                     fxLayoutAlign="start"
                                     fxLayoutGap="4px">

                                    <div [class.horizontal]="message.replyTo.messageType !== 'TEXT'">

                                        <div [style.color]="'#34b7f1'">
                                            <span [style.fontWeight]="'bold'">{{message.replyTo.sender.id === me.id ? ('Me' | translate) : message.replyTo.sender.name}}</span>
                                            <div *ngIf="message.replyTo.messageType !== 'TEXT'" class="msg-type">
                                                <mat-icon [fontIcon]="getIcon(message.replyTo.messageType)"
                                                          fontSet="mdi"></mat-icon>
                                                <span *ngIf="message.replyTo.messageType === 'AUDIO'; else otherRef">{{getAudioDuration(message.replyTo.duration)}}</span>
                                                <ng-template #otherRef>
                                                    <span>• {{message.replyTo.messageType | translate}}</span></ng-template>
                                            </div>
                                        </div>

                                        <ng-container [ngSwitch]="message.replyTo.messageType">
                                            <div (click)="openImage(message.replyTo)" *ngSwitchCase="'IMAGE'"
                                                 class="image-message">
                                                <img [src]="message.replyTo.data" alt="" height="40px" width="40px">
                                            </div>
                                            <div *ngSwitchCase="'AUDIO'" class="audio-message">

                                            </div>
                                            <div *ngSwitchCase="'EMOJI'" class="emoji-message">

                                            </div>
                                            <div *ngSwitchCase="'FILE'" class="file-message">

                                            </div>
                                            <div *ngSwitchCase="'VIDEO'" class="video-message">

                                            </div>
                                            <div *ngSwitchDefault class="text-message">{{message.replyTo.text}}</div>
                                        </ng-container>
                                    </div>

                                </div>

                                <ng-container [ngSwitch]="message.deletedFor">
                                    <div *ngSwitchCase="'ALL'" class="message-deleted">
                                        <mat-icon fontIcon="mdi-cancel" fontSet="mdi"></mat-icon>
                                        {{'MESSAGE_DELETED' | translate}}</div>
                                    <div *ngSwitchCase="me.userId" class="message-deleted">
                                        <mat-icon fontIcon="mdi-cancel" fontSet="mdi"></mat-icon>
                                        {{'MESSAGE_DELETED' | translate}}</div>

                                    <div *ngSwitchDefault>
                                        <ng-container [ngSwitch]="message?.messageType">
                                            <div *ngSwitchCase="'IMAGE'" class="image-message">
                                                <img [src]="message.data" alt="" width="140px">
                                            </div>
                                            <div *ngSwitchCase="'AUDIO'" class="audio-message">
                                                <ft-audio-message (played)="audioMessagePlayed($event)"
                                                                  [audioMessage]="message"></ft-audio-message>
                                            </div>
                                            <div *ngSwitchCase="'EMOJI'" class="emoji-message">

                                            </div>
                                            <div *ngSwitchCase="'FILE'" class="file-message">

                                            </div>
                                            <div *ngSwitchCase="'VIDEO'" class="video-message">

                                            </div>
                                            <div *ngSwitchDefault class="text-message">{{message.text}}</div>
                                        </ng-container>
                                    </div>

                                </ng-container>

                                <div *ngIf="message.messageType !== 'AUDIO'" class="time">

                                    {{message.sentDateTime | date: 'HH:mm'}}

                                    <mat-icon *ngIf="messageIsMine(message)" [class.seen]="message.seenDateTime"
                                              [fontIcon]="message.seenDateTime ? 'mdi-check-all': 'mdi-check'"
                                              class="message-status"
                                              fontSet="mdi"></mat-icon>
                                </div>
                            </div>
                        </mat-checkbox>

                        <ng-template #messageData>
                            <div [class.right]="messageIsMine(message)" class="message">
                                <div [class.left]="!messageIsMine(message)" [class.right]="messageIsMine(message)"
                                     class="tick"></div>

                                <div *ngIf="message.replyTo && !message.deleted"
                                     [style.borderLeftColor]="'#35cd96'"
                                     class="reply-to-msg"
                                     fxLayout="row"
                                     fxLayoutAlign="start"
                                     fxLayoutGap="4px">

                                    <div [class.horizontal]="message.replyTo.messageType !== 'TEXT'">

                                        <div [style.color]="'#34b7f1'">
                                            <span [style.fontWeight]="'bold'">{{message.replyTo.sender.id === me.id ? ('Me' | translate) : message.replyTo.sender.name}}</span>
                                            <div *ngIf="message.replyTo.messageType !== 'TEXT'" class="msg-type">
                                                <mat-icon [fontIcon]="getIcon(message.replyTo.messageType)"
                                                          fontSet="mdi"></mat-icon>
                                                <span *ngIf="message.replyTo.messageType === 'AUDIO'; else otherRef">{{getAudioDuration(message.replyTo.duration)}}</span>
                                                <ng-template #otherRef>
                                                    <span>• {{message.replyTo.messageType | translate}}</span></ng-template>
                                            </div>
                                        </div>

                                        <ng-container [ngSwitch]="message.replyTo.messageType">
                                            <div (click)="openImage(message.replyTo)" *ngSwitchCase="'IMAGE'"
                                                 class="image-message">
                                                <img [src]="message.replyTo.data" alt="" height="40px" width="40px">
                                            </div>
                                            <div *ngSwitchCase="'AUDIO'" class="audio-message">

                                            </div>
                                            <div *ngSwitchCase="'EMOJI'" class="emoji-message">

                                            </div>
                                            <div *ngSwitchCase="'FILE'" class="file-message">

                                            </div>
                                            <div *ngSwitchCase="'VIDEO'" class="video-message">

                                            </div>
                                            <div *ngSwitchDefault class="text-message">{{message.replyTo.text}}</div>
                                        </ng-container>
                                    </div>

                                </div>

                                <ng-container [ngSwitch]="message.deletedFor">
                                    <div *ngSwitchCase="'ALL'" class="message-deleted">
                                        <mat-icon fontIcon="mdi-cancel" fontSet="mdi"></mat-icon>
                                        {{'MESSAGE_DELETED' | translate}}</div>
                                    <div *ngSwitchCase="me.userId" class="message-deleted">
                                        <mat-icon fontIcon="mdi-cancel" fontSet="mdi"></mat-icon>
                                        {{'MESSAGE_DELETED' | translate}}</div>
                                    <div *ngSwitchDefault>

                                        <ng-container [ngSwitch]="message?.messageType">
                                            <div (click)="openImage(message)" *ngSwitchCase="'IMAGE'" class="image-message">
                                                <img [src]="message.data" alt="" width="140px">
                                            </div>
                                            <div *ngSwitchCase="'AUDIO'" class="audio-message">
                                                <ft-audio-message (played)="audioMessagePlayed($event)"
                                                                  [audioMessage]="message"></ft-audio-message>
                                            </div>
                                            <div *ngSwitchCase="'EMOJI'" class="emoji-message">

                                            </div>
                                            <div *ngSwitchCase="'FILE'" class="file-message">

                                            </div>
                                            <div *ngSwitchCase="'VIDEO'" class="video-message">

                                            </div>
                                            <div *ngSwitchDefault [innerHTML]="message.text | emojisDisplay"
                                                 class="text-message"></div>
                                        </ng-container>

                                    </div>
                                </ng-container>

                                <div *ngIf="message.messageType !== 'AUDIO'" class="time">
                                    {{message.sentDateTime | date: 'HH:mm'}}

                                    <mat-icon *ngIf="messageIsMine(message)" [class.seen]="message.seenDateTime"
                                              [fontIcon]="message.seenDateTime ? 'mdi-check-all': 'mdi-check'"
                                              class="message-status"
                                              fontSet="mdi"></mat-icon>
                                </div>
                            </div>
                        </ng-template>

                        <button (click)="replyToMessage = message" *ngIf="!message.deleted && !selectionActivated"
                                [fxFlexOrder]="message.sender.id === me.id? -1: 1" class="reply-btn"
                                fxFlexAlign="end" mat-icon-button>
                            <mat-icon fontIcon="mdi-reply" fontSet="mdi"></mat-icon>
                        </button>

                        <button *ngIf="!message.deleted && !selectionActivated"
                                [fxFlexOrder]="messageIsMine(message)? -2: 2"
                                [matMenuTriggerFor]="menu"
                                class="reply-btn"
                                fxFlexAlign="end" mat-icon-button>
                            <mat-icon fontIcon="mdi-dots-horizontal" fontSet="mdi"></mat-icon>
                        </button>

                        <mat-menu #menu="matMenu" overlapTrigger="true">
                            <button (click)="forwardMessage(message)" mat-menu-item>
                                <mat-icon fontIcon="mdi-share-outline" fontSet="mdi"></mat-icon>
                                {{'FORWARD' | translate}}</button>
                            <button (click)="deleteMessage(message)" mat-menu-item style="color: red">
                                <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                                {{'DELETE' | translate}}</button>
                        </mat-menu>

                    </div>
                </div>


                <div id="bottom"></div>

            </div>


            <!-- Progress -->
            <div *ngIf="uploadProgress" class="progress" fxFlexAlign="center" fxLayout="row" fxLayoutAlign="start center"
                 fxLayoutGap="4px">
                <mat-progress-bar [value]="uploadProgress" class="progress-bar" fxFlex
                                  mode="determinate"></mat-progress-bar>
                <button (click)="cancelUpload()" [matTooltip]="'CANCEL' | translate" mat-icon-button>
                    <mat-icon class="cancel-upload" fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </div>

            <div *ngIf="replyToMessage" [style.borderLeftColor]="replyToMessage.sender.color" class="reply-to-msg"
                 style="margin: 4px 48px !important; padding: 12px !important;"
                 fxLayout="row" fxLayoutAlign="start" fxLayoutGap="4px">
                <div>
                    <div [class.horizontal]="replyToMessage.messageType !== 'TEXT'">

                        <div [style.color]="'#34b7f1'">
                            <span [style.fontWeight]="'bold'">{{replyToMessage.sender.id === me.id ? ('Me' | translate) : replyToMessage.sender.name}}</span>
                            <div *ngIf="replyToMessage.messageType !== 'TEXT'" class="msg-type">
                                <mat-icon [fontIcon]="getIcon(replyToMessage.messageType)"
                                          fontSet="mdi"></mat-icon>
                                <span *ngIf="replyToMessage.messageType === 'AUDIO'; else otherRef">{{getAudioDuration(replyToMessage.duration)}}</span>
                                <ng-template #otherRef><span>• {{replyToMessage.messageType | translate}}</span>
                                </ng-template>
                            </div>
                        </div>

                        <ng-container [ngSwitch]="replyToMessage.messageType">
                            <div (click)="openImage(replyToMessage)" *ngSwitchCase="'IMAGE'"
                                 class="image-message">
                                <img [src]="replyToMessage.data" alt="" height="40px" width="40px">
                            </div>
                            <div *ngSwitchCase="'AUDIO'" class="audio-message">

                            </div>
                            <div *ngSwitchCase="'EMOJI'" class="emoji-message">

                            </div>
                            <div *ngSwitchCase="'FILE'" class="file-message">

                            </div>
                            <div *ngSwitchCase="'VIDEO'" class="video-message">

                            </div>
                            <div *ngSwitchDefault [innerHTML]="replyToMessage.text | emojisDisplay"
                                 class="text-message"></div>
                        </ng-container>
                    </div>
                </div>
                <span fxFlex></span>
                <button (click)="replyToMessage = null" color="accent" mat-icon-button>
                    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </div>

            <div *ngIf="showEmojis" class="emojis-container">
                <div style="width: 100%;text-align: end">
                    <button (click)="showEmojis = false" mat-icon-button>
                        <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                    </button>
                </div>
                <img (click)="selectEmoji(emoji)"
                     *ngFor="let emoji of emojis" [alt]="emoji"
                     [src]="emoji" class="emoji">
            </div>

            <!--        selection actions-->
            <ng-container *ngIf="selectionActivated; else defaultInput">
                <div class="selection-actions" fxFlex="48px" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    {{selectedMessages.length}} {{(selectedMessages.length > 1 ? 'MESSAGES_SELECTED' : 'MESSAGE_SELECTED') | translate}}
                    <span fxFlex></span>
                    <button (click)="deleteSelection()" [disabled]="selectedMessages.length === 0" color="accent"
                            mat-button>
                        <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                        {{'DELETE' | translate}}</button>
                    <button (click)="forwardSelection()" [disabled]="selectedMessages.length === 0" mat-button>
                        <mat-icon fontIcon="mdi-share-outline" fontSet="mdi"></mat-icon>
                        {{'FORWARD' | translate}}</button>
                    <button (click)="selectionActivated = false" mat-icon-button>
                        <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                    </button>
                </div>
            </ng-container>

            <ng-template #defaultInput>
                <!--        Input and buttons-->
                <div fxFlex="48px" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" style="z-index: 2;">
                    <button (click)="fileUpload.click()" [matTooltip]="'ATTACH_FILE' | translate" mat-icon-button>
                        <mat-icon [style.color]="'#03A9F4'" fontIcon="mdi-image-plus" fontSet="mdi"></mat-icon>
                    </button>
                    <button (click)="showEmojis = !showEmojis" mat-icon-button>
                        <mat-icon [style.color]="'#607D8B'" fontIcon="mdi-emoticon-happy-outline" fontSet="mdi"></mat-icon>
                    </button>

                    <div #message_box (keyup)="sendInput($event)" class="div-message" contenteditable="true"
                         data-placeholder="Message" fxFlex></div>


                    <ng-container *ngIf="!recordingActivated; else recordingRef">
                        <button (click)="recordingActivated = true" [matTooltip]="'RECORD_AUDIO' | translate"
                                mat-icon-button>
                            <mat-icon [style.color]="'#E91E63'" fontIcon="mdi-microphone" fontSet="mdi"></mat-icon>
                        </button>
                        <button (click)="sendOnClick(message_box.innerHTML)" [matTooltip]="'SEND' | translate"
                                mat-icon-button>
                            <mat-icon [style.color]="'#009688'" fontIcon="mdi-send" fontSet="mdi"></mat-icon>
                        </button>
                    </ng-container>
                    <ng-template #recordingRef>
                        <ft-message-recorder (onClose)="recordingActivated = false"
                                             (onConfirm)="onRecordFinish($event)"></ft-message-recorder>
                    </ng-template>
                </div>
            </ng-template>

        </div>
    </div>
</div>


<div *ngIf="selectedImage" class="image-previewer" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
    <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="8px">
        <button (click)="selectedImage = null" [matTooltip]="'CLOSE' | translate" class="close" color="warn"
                mat-icon-button>
            <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
        </button>
        <button (click)="saveImage(selectedImage)" [matTooltip]="'DOWNLOAD' | translate" class="download"
                color="primary" mat-icon-button>
            <mat-icon fontIcon="mdi-cloud-download" fontSet="mdi"></mat-icon>
        </button>
    </div>
    <img [alt]="selectedImage.text" [src]="selectedImage.data" style="max-height: 90%;max-width: 100%">
</div>
