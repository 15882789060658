import {Injectable} from '@angular/core';
import {LOGIN_URL, LOGOUT_URL, REFRESH_URL, USER_CHECK_URL} from '../../urls';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {catchError, first, mapTo, tap} from 'rxjs/operators';
import {get} from 'lodash';
import {MatDialog} from '@angular/material/dialog';
import {Observable, of, Subject} from 'rxjs';
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable()
export class AuthService {
    private readonly ACCESS_TOKEN = 'access_token';
    private readonly REFRESH_TOKEN = 'refresh_token';
    private readonly BEARER = 'Bearer ';

    public redirectUrl: string;

    private profileSource = new Subject<any>();
    profileChanged = this.profileSource.asObservable();
    private _profile: any;

    constructor(
        private _router: Router,
        private _http: HttpClient,
        private _dialog: MatDialog,
        private _jwtService: JwtHelperService
    ) {
        this.redirectUrl = this._profileDefaultUrl();
    }

    checkUser(): Observable<any> {
        return this._http.get(USER_CHECK_URL).pipe(tap(data => this._handleUser(data)));
    }

    login(user) {
        return this._http.post(LOGIN_URL, {}, {params: {username: user.username, password: user.password}})
            .pipe(
                tap((tokens) => this._storeTokens(tokens)),
                mapTo(true),
                catchError(err => {
                    alert(err.error);
                    return of(false);
                })
            );
    }

    logout(): Observable<any> {
        return this._http.post<any>(LOGOUT_URL, {})
            .pipe(
                first(),
                tap(() => this._doLogoutUser()),
                mapTo(true),
                catchError(_ => of(false))
            );
    }

    getAccessToken(): any {
        return localStorage.getItem(this.ACCESS_TOKEN);
    }

    isLoggedIn(): boolean {
        let token = this.getAccessToken();
        return !!token && !this._jwtService.isTokenExpired(token);
    }

    refreshTokenIsValid(): boolean {
        let token = this.getRefreshToken();
        return !!token && !this._jwtService.isTokenExpired(token);
    }

    private _profileDefaultUrl(): string {
        return get(this._profile, 'defaultRoute') || '/';
    }

    private _handleUser(user) {
        this._profile = user.profile;

        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('profile', JSON.stringify(user.profile))

        this.redirectUrl = this._profileDefaultUrl();
    }

    refreshToken(): Observable<any> {
        return this._http.post<any>(`${REFRESH_URL}`, {}, {headers: new HttpHeaders({'Authorization': this.BEARER + this.getRefreshToken()})})
            .pipe(tap((tokens) => this._storeTokens(tokens)));
    }

    private _storeTokens(tokens: any) {
        localStorage.setItem(this.ACCESS_TOKEN, tokens.access_token);
        localStorage.setItem(this.REFRESH_TOKEN, tokens.refresh_token);
    }

    public getRefreshToken(): string {
        return localStorage.getItem(this.REFRESH_TOKEN);
    }

    private _doLogoutUser() {
        this._router.navigateByUrl('/login');
        this._removeTokens();
    }

    private _removeTokens() {
        localStorage.removeItem(this.ACCESS_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN);
    }
}
