import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ReportingService} from '../reporting.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {template} from 'lodash';
import * as Quill from 'quill';

import {quillEditorConfig} from './quill-editor-config';

@Component({
    selector: 'app-email-send',
    templateUrl: './email-send.component.html',
    styleUrls: ['./email-send.component.scss']
})
export class EmailSendComponent implements OnInit, AfterViewInit {

    form: FormGroup;

    isCc: boolean;
    isBcc: boolean;
    sending: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
        private dialogRef: MatDialogRef<EmailSendComponent>,
        private fb: FormBuilder,
        private service: ReportingService) {
        this.form = this.fb.group({
            destAddress: ['', Validators.required],
            subject: '',
            cc: '',
            bcc: '',
            body: '',
            fileAttached: true
        });
    }

    sendMail() {
        this.sending = true;
        this.service
            .sendMail(this.form.getRawValue(), this.data.id)
            .subscribe(res => {
                this.sending = false;
                this.dialogRef.close(res);
            });
    }

    ngOnInit() {
        this.form.patchValue({
            destAddress: this.getDestAddress(),
            subject: this.data.procedureCode
        });
    }

    getDestAddress = (): string => [this.data.referringPhysicianEmail, this.data.patientEmail].filter(it => it !== '' && it != null).join(';');

    ngAfterViewInit() {
        this.setupQuillEditor();
    }

    setupQuillEditor() {

        let quill = new Quill('#editor', quillEditorConfig);

        let content = template(
            'Cher confrère,<br><br>' +
            'Veuillez trouvez ci-joint le compte-rendu <b>${ exam }</b> de <b>${ user }</b>.<br><br>' +
            'Cordialement.')({ user: this.data.patientName, exam: this.data.reportTitle });

        this.form.patchValue({ body: content });

        quill.clipboard.dangerouslyPasteHTML(content);

        quill.on('text-change',  (delta, oldDelta, source) => {
            if (source == 'api') {
                console.log('Changes from API');
            } else if (source == 'user') {
                this.form.patchValue({ body: quill.getText() });
            }
        });
    }
}
