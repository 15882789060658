import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
    transform(array: any[], ...args: any[]): any[] {
        let [filterByProp] = args;
        return array ? array.filter(it => it.targetColumn === filterByProp) : [];
    }
}
