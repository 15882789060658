import {ColumnDataType, TableColumn} from "../../model";

export const LOGS_COLS: TableColumn[] = [
    {
        label: 'id',
        value: 'id',
        hidden: true,
        header: 'ID',
        sortField: 'id',
        sortable: true,
        searchable: true,
        required: false,
        order: -1,
        id: 0,
        type: ColumnDataType.NUMERIC,
        defaultValue: '',
        selected: true,
        unit: ''
    }, {
        label: 'datetime',
        value: 'datetime',
        hidden: false,
        sortField: 'datetime',
        header: 'DATE',
        sortable: true,
        searchable: true,
        required: false,
        order: 0,
        id: 0,
        type: ColumnDataType.DATE,
        defaultValue: '',
        selected: true,
        unit: 'date'
    }, {
        label: 'targetModule',
        value: 'targetModule',
        hidden: false,
        sortField: 'target_module',
        header: 'MODULE',
        sortable: true,
        searchable: true,
        required: false,
        order: 2,
        id: 0,
        type: ColumnDataType.STRING,
        defaultValue: '',
        selected: true,
        unit: ''
    }, {
        label: 'targetValue',
        value: 'targetValue',
        hidden: false,
        sortField: 'target_value',
        header: 'TARGET_VALUE',
        sortable: true,
        searchable: true,
        required: false,
        order: 4,
        id: 0,
        type: ColumnDataType.STRING,
        defaultValue: '',
        selected: true,
        unit: ''
    }, {
        label: 'remoteAddress',
        value: 'remoteAddress',
        hidden: false,
        sortField: 'remote_address',
        header: 'REMOTE_ADDRESS',
        sortable: false,
        searchable: true,
        required: false,
        order: 5,
        id: 0,
        type: ColumnDataType.STRING,
        defaultValue: '',
        selected: true,
        unit: ''
    }, {
        label: 'remoteHost',
        value: 'remoteHost',
        hidden: false,
        sortField: 'remote_host',
        header: 'REMOTE_HOST',
        sortable: false,
        searchable: true,
        required: false,
        order: 6,
        id: 0,
        type: ColumnDataType.STRING,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'userAction',
        header: 'ACTION',
        value: 'action',
        sortField: 'action',
        sortable: false,
        hidden: false,
        searchable: true,
        required: false,
        order: 3,
        id: 0,
        type: ColumnDataType.STRING,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'user',
        header: 'USER',
        value: 'user',
        sortField: 'user',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.STRING,
        defaultValue: '',
        selected: true,
        unit: ''
    }
];