<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
    <h3 mat-dialog-title>{{'SEARCH' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content style="padding: 0 !important; overflow: hidden">


    <div fxLayout="row" fxLayoutAlign="start center" style="border-bottom: 2px solid #e5e5e5;">
        <div class="search-field">
            <mat-icon fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
            <input #filter placeholder="{{'SEARCH' | translate}}" class="search-field">
        </div>
        <mat-spinner *ngIf="isLoadingResults" mode="indeterminate" [strokeWidth]="2" [diameter]="20"
                     [value]="50"></mat-spinner>
    </div>


    <div class="example-loading-shade" *ngIf="isLoadingResults || isResultsLoaded">
        <div class="example-rate-limit-reached" *ngIf="isResultsLoaded">
            Server is not responding, It will be available in a few seconds. try to refresh your page
        </div>
    </div>

    <div class="mat-table" style="max-height: 400px" fxLayout="column">

        <mat-table style="overflow: auto;" [dataSource]="dataSource" class="fire-table" [@state]="dataSource.data.length"
                   [trackBy]="trackById"
                   matSort
                   matSortActive="createdDate"
                   matSortDirection="desc"
                   matSortDisableClear>

            <!--Table-->
            <ng-container *ngFor="let col of displayedColumns" [class.hidden]="col.hidden" matColumnDef="{{col.label}}">
                <mat-header-cell *matHeaderCellDef class="fire-header-cell" mat-sort-header> {{col.header | translate}}</mat-header-cell>

                <ng-container *ngIf="col.unit === 'conf_data'">
                    <mat-cell *matCellDef="let row" class="fire-cell"> {{can(row) ? row[col.label]: '****'}}</mat-cell>
                </ng-container>
                <ng-container *ngIf="col.unit === 'date'">
                    <mat-cell *matCellDef="let row" class="fire-cell"> {{row[col.label] | date:'dd/MM/yyyy'}}</mat-cell>
                </ng-container>
                <ng-container *ngIf="col.unit !== 'date' && col.unit !== 'conf_data'">
                    <mat-cell *matCellDef="let row" class="fire-cell"> {{row[col.label]}}</mat-cell>
                </ng-container>

            </ng-container>

            <!--header-->
            <mat-header-row *matHeaderRowDef="cols; sticky: true" class="fire-header-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: cols;"
                     (click)="onSelect(row)"
                     class="fire-row"></mat-row>

        </mat-table>

        <mat-paginator [length]="resultsLength"
                      [pageIndex]="0"
                      [pageSize]="10"
                      [pageSizeOptions]="[5, 10, 20, 25]" showFirstLastButtons>
        </mat-paginator>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button color="warn" mat-dialog-close mat-raised-button>{{'CANCEL' | translate}}</button>
</mat-dialog-actions>

