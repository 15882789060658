import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {get, isNil, noop} from 'lodash';

declare var MediaRecorder: any;

@Component({
    selector: 'app-audio-record',
    templateUrl: './audio-record.component.html',
    styleUrls: ['./audio-record.component.scss']
})
export class AudioRecordComponent implements OnInit, OnDestroy {

    record = null;
    stream = null;
    audioFile = null;
    audioChunks: any[] = [];

    isPlay = false;
    isStop = true;
    hasNoFile = true;

    showPreview = false;
    err = true;

    audio = null;

    @ViewChild('myAudio', { static: true }) myAudio: any;

    constructor(private dialogRef: MatDialogRef<AudioRecordComponent>) {
    }

    ngOnInit() {
        this.audio = this.myAudio.nativeElement;

        let getUserMedia = get(window, 'navigator.mediaDevices.getUserMedia', null);

        if (!isNil(getUserMedia)) {
            window.navigator['mediaDevices']['getUserMedia']({audio: true})
                .then((stm) => this.handleStream(stm), () => this.error());
        }


    }

    handleStream(stm) {
        this.stream = stm;

        this.record = new MediaRecorder(this.stream);
        this.record.ondataavailable = (e) => this.onDataAvailable(e);

        if (!this.isPlay) this.startRecording();
    }

    onDataAvailable(e) {
        this.audioChunks.push(e.data);

        if (this.record.state === 'inactive') {
            this.hasNoFile = false;

            this.audioFile = new Blob(this.audioChunks, {type: 'audio/wav'});
            this.audio.src = window.URL.createObjectURL(this.audioFile);
        }
    }

    startRecording() {
        if (this.record.state === 'inactive') this.record.start();

        this.isPlay = true;
        this.isStop = false;
        this.showPreview = false;
    }

    pauseRecording() {
        if (this.record.state !== 'inactive') this.record.stop();
        this.isPlay = this.isStop = false;
    }

    stopRecording() {
        if (this.record.state !== 'inactive') this.record.stop();

        this.isPlay = false;
        this.isStop = true;
        this.showPreview = true;

        this.audioChunks = [];
    }

    error() {
        this.err = true;
    }


    saveFile() {
        this.dialogRef.close(this.audioFile)
    }

    ngOnDestroy() {
        isNil(this.stream) ? noop() : this.stream['getAudioTracks']().forEach(function (track) {
            track.stop();
        })
    }

}
