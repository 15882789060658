<div class="grid-display">
    <mat-grid-list [cols]="cols" [gutterSize]="gutterSize" [rowHeight]="rowHeight" style="margin: 16px 8px">
        <mat-grid-tile *ngFor="let image of selectedImages" [id]="image.path"
                       [style.background]="imageBackground.toLowerCase()"
                       [images]="selectedImages"
                       [synchronized]="image['synchronized']"
                       [viewport]="image"
                       class="cornerstone-canvas" fxLayout="column" fxLayoutAlign="center center">
            <button (click)="toggleSynchronize(image)" fxFlexAlign="start start" mat-icon-button style="color: white;position: absolute;top: 0;left: 0">
                <mat-icon [fontIcon]="image['synchronized'] ? 'mdi-link': 'mdi-link-off'" fontSet="mdi"></mat-icon>
            </button>
        </mat-grid-tile>
    </mat-grid-list>
</div>
