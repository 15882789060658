import {humanizeDuration} from './timer';
import {get} from 'lodash';

export function getPatientStatusIcon(status): string {
    switch (status) {
        case 'EXAM_STARTED':
            return 'mdi-timelapse';
        case 'EXAM_FINISHED':
            return 'mdi-check-all';
        case 'WAITING':
            return 'mdi-emoticon-neutral';
        case 'LATE':
            return 'mdi-account-alert';
        case 'BEFORE_TIME':
            return 'mdi-progress-clock';
        case 'EXAM_MISSED':
            return 'mdi-call-missed';
        default:
            return 'mdi-timer-sand';
    }
}

export function getReportingStatusIcon(status): string {
    switch (status) {
        case 'WAITING':
            return 'mdi-file-outline';
        case 'IN_PROGRESS':
            return 'mdi-file-clock';
        case 'FINISHED':
            return 'mdi-file';
        case 'TO_REVIEW':
            return 'mdi-file-alert';
        case 'TO_SIGN':
            return 'mdi-file-edit';
        case 'SIGNED':
            return 'mdi-file-check';
        case 'DELIVERED':
            return 'mdi-file-lock';
        case 'VERIFIED':
            return 'mdi-file-certificate';
        default:
            return 'mdi-file-outline'
    }
}

export function getReportStatusIcon(status): string {
    switch (status) {
        case 'WAITING':
            return 'mdi-file-outline';
        case 'INPROGRESS':
            return 'mdi-file-clock';
        case 'READY':
            return 'mdi-file-check';
        case 'DELIVERED':
            return 'mdi-file-lock';
        default:
            return 'mdi-file-cancel-outline'
    }
}

export function getIconColor(status): string {
    switch (status) {
        case 'EXAM_STARTED':
            return '#00bcd4';
        case 'EXAM_FINISHED':
            return '#089219';
        case 'EXAM_MISSED':
            return '#ff0000';
        case 'WAITING':
            return '#FF9800';
        case 'LATE':
            return '#ff0000';
        case 'BEFORE_TIME':
            return '#ff00bc';
        default:
            return '#999';
    }
}

export function getReportIconColor(status): string {
    switch (status) {
        case 'WAITING':
            return '#999';
        case 'IN_PROGRESS':
            return '#1aa4c4';
        case 'FINISHED':
            return '#16a3f5';
        case 'VERIFIED':
            return '#ca1151';
        case 'TO_REVIEW':
            return '#ff0000';
        case 'TO_SIGN':
            return '#ffb364';
        case 'SIGNED':
            return '#169134';
        case 'DELIVERED':
            return '#917384';
        default:
            return ''
    }
}

export function getCompetedReportIconColor(status): string {
    switch (status) {
        case 'WAITING':
            return '#999';
        case 'INPROGRESS':
            return '#1aa4c4';
        case 'READY':
            return '#1bca68';
        case 'DELIVERED':
            return '#bd386d';
        default:
            return ''
    }
}


export function stringValue(value): boolean {
    return [
        'SPSSTATUS',
        'FULLNAME',
        'REPORTING_STATUS',
        'U.',
        'PATIENT_STATUS',
        'Conf.',
        'CONNECTED',
        'PROCEDURE_TYPE',
        'REPORT_COMPLETION_STATUS',
        'REPORT_SIGNATURE',
        'DELIVERY_DATE',
        'PATIENTARRIVAL',
        'PATIENTID',
        'PATIENT_CLASS',
        'PROCEDURE_CODE',
        'TOTAL_AMOUNT', 'PAID_AMOUNT', 'LEFT_AMOUNT', 'GLOBAL_DISCOUNT',
        'ACCESSIONNUMBER',
        'RDV_DATE',
        'EXAM_START',
        'ALLERGIES',
        'PERFORMING_RADIOLOGIST',
        'REFERRING_PHYSICIAN',
        'TECHNICIAN',
        'EXAM_FINISH'].indexOf(value) === -1;
}

export function getRowColor(status, settings) {
    switch (status) {
        case 'EXAM_FINISHED':
            return get(settings, 'wfExamFinished');
        case 'WAITING':
            return get(settings, 'wfWaiting');
        case 'LATE':
            return get(settings, 'wfLate');
        case 'EXAM_STARTED':
            return get(settings, 'wfExamStarted');
        case 'NOT_YET':
            return get(settings, 'wfNone');
        default:
            return '#ffffff';
    }
}

export function getDuration(row): any {
    switch (row['patientStatus']) {
        case 'EXAM_FINISHED':
            return humanizeDuration(row['effectiveExamDuration']).duration;
        case 'WAITING':
            let wait = humanizeDuration(row['waitingTime']);
            return wait.real ? wait.duration : 'WAITED_TOO_LONG';
        case 'LATE':
            let t = humanizeDuration(row['timeOfDelay']);
            return t.real ? t.duration : 'NOT_ARRIVED';
        case 'BEFORE_TIME':
            let d = humanizeDuration(row['durationBeforeRdv']);
            return d.real ? d.duration : 'BEFORE_RDV';
        case 'EXAM_STARTED':
            return humanizeDuration(row['examElapsedTime']).duration;
    }
}
