import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {SharedService} from '../shared.service';
import {BehaviorSubject, fromEvent as observableFromEvent, merge, of as observableOf} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {FireLog} from '../../model';
import {LOGS_COLS} from './table-conf';
import {map as _map, sortBy} from 'lodash';
import {tableAnimation} from '../../animations';
import {WsService} from '../../ws.service';
import {FormControl, FormGroup} from '@angular/forms';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {DateUtils} from '../../utils';
import * as moment from 'moment';

@Component({
    selector: 'ft-logs',
    templateUrl: './logs.component.html',
    styleUrls: ['./logs.component.scss'],
    animations: [tableAnimation]
})
export class LogsComponent implements AfterViewInit {

    dataSource = new MatTableDataSource();
    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    filterChange = new BehaviorSubject('');

    columnsToDisplay = [];
    availableColumns = [];

    @ViewChild('filter', {static: true}) filter: ElementRef;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    public filterForm = new FormGroup({
        startDate: new FormControl(new Date()),
        endDate: new FormControl(new Date()),
        period: new FormControl('TODAY'),
        user: new FormControl(''),
    });

    toppings = new FormControl();
    users: any[] = [];

    constructor(private _shared: SharedService, private _ws: WsService) {
        this.availableColumns = sortBy(LOGS_COLS.filter(item => !item.hidden), 'order');
        this.columnsToDisplay = _map(this.availableColumns, 'label');

        this._shared.getUsers().subscribe(data => this.users = data);

        this._ws.observeTopic('logger').subscribe(res => {
            this.filterChange.next(this.filterChange.value)
        });
    }

    trackByDatetime(index: number, item: any): string {
        return item.datetime;
    }

    ngAfterViewInit() {

        this.sort.sortChange.subscribe(_ => this.paginator.pageIndex = 0);

        let observedFilters = [
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.filterChange.asObservable(),
            this.filterForm.valueChanges.pipe(debounceTime(400))
        ];

        observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
            debounceTime(400),
            distinctUntilChanged(),)
            .subscribe(() => {
                if (!this.dataSource) return;
                this.paginator.pageIndex = 0;
                this.filterChange.next(this.filter.nativeElement.value);
            });

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(...observedFilters)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;

                    let {startDate, endDate, _, user} = this.filterForm.value;

                    return this._shared.loadLogs(this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        this.filterChange.value, user, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data['totalElements'];
                    return data['content'] as FireLog[];
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe((data: FireLog[]) => this.dataSource.data = data);
    }

    changeRange(e: MatButtonToggleChange) {
        let dateRange = DateUtils.PeriodDateRange(e.value);
        this.filterForm.patchValue(dateRange);
    }
}
