<mat-toolbar class="dialog-toolbar" [formGroup]="form">
    <mat-icon fontSet="mdi" fontIcon="mdi-currency-usd"></mat-icon>
    <h3 mat-dialog-title>{{'PAYMENT' | translate}} - {{form.get('patientName').valueChanges | async}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <div [formGroup]="form" fxLayout="column" class="payment-dialog">

        <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="4px">
            <mat-form-field appearance="standard" fxFlex="140px">
                <mat-label>{{'PAYMENT_DATE' | translate}}</mat-label>
                <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" formControlName="date" tabindex="-1">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex="140px">
                <mat-label>{{'DUE_DATE' | translate}}</mat-label>
                <input matInput [matDatepicker]="picker2" placeholder="DD/MM/YYYY" formControlName="dueDate"
                       tabindex="-1">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex="80px" disabled>
                <mat-label>{{'BASE_TARIF' | translate}}</mat-label>
                <input matInput [placeholder]="'BASE_TARIF' | translate" formControlName="baseAmount" type="number"
                       tabindex="-1">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex="80px" disabled>
                <mat-label>{{'DUE_AMOUNT' | translate}}</mat-label>
                <input matInput [placeholder]="'DUE_AMOUNT' | translate" formControlName="due" type="number"
                       tabindex="-1">
            </mat-form-field>
            <mat-form-field appearance="standard" fxFlex="68px">
                <mat-label>{{'DISCOUNT_PRICE' | translate: {currency: currencyFormat} }}</mat-label>
                <input matInput (keyup)="enterDiscount($event)" [placeholder]="'DISCOUNT_PRICE' | translate"
                       formControlName="discount" type="number">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex="80px" disabled>
                <mat-label>{{'TOTAL_AMOUNT' | translate: {currency: currencyFormat} }}</mat-label>
                <input matInput [placeholder]="'TOTAL_AMOUNT' | translate" formControlName="totalAfterDiscount"
                       type="number" tabindex="-1">
            </mat-form-field>

            <ng-container *ngIf="form.get('payer').value === 'THIRD_PAYER'">
                <mat-form-field appearance="standard" fxFlex="80px">
                    <mat-label>{{'ORG_PART' | translate}}({{currencyFormat}})</mat-label>
                    <input matInput [placeholder]="'ORG_PART' | translate" formControlName="organismPart"
                           type="number">
                </mat-form-field>

                <mat-form-field appearance="standard" fxFlex="80px">
                    <mat-label>{{'PAT_PART' | translate}}({{currencyFormat}})</mat-label>
                    <input matInput [placeholder]="'PAT_PART' | translate" formControlName="patientPart"
                           type="number">
                </mat-form-field>
            </ng-container>

            <mat-form-field appearance="standard" fxFlex="80px">
                <mat-label>{{'PAID_AMOUNT' | translate}}</mat-label>
                <input matInput [placeholder]="'PAID_AMOUNT' | translate" formControlName="enteredAmount"
                       (keyup)="enterAmount($event)"
                       type="number">
            </mat-form-field>
            <!-- <mat-form-field appearance="standard" fxFlex="120px">
                 <mat-label>{{'DISCOUNT_PERCENTAGE' | translate}}</mat-label>
                 <input matInput (keyup)="calculatePrice($event)" [placeholder]="'DISCOUNT_PERCENTAGE' | translate"
                        formControlName="discountPercentage" type="number">
             </mat-form-field>-->
            <mat-form-field appearance="standard" fxFlex="80px">
                <mat-label>{{'LEFT_AMOUNT' | translate}}</mat-label>
                <input matInput [ngModel]="leftAmount" [ngModelOptions]="{standalone: true}"
                       [placeholder]="'LEFT_AMOUNT' | translate" disabled>
            </mat-form-field>

        </div>

        <div fxLayout="row wrap" fxLayoutAlign="space-between end" fxLayoutGap="4px">
            <div fxLayout="column">
                <div class="label-option">{{'PAYER' | translate}}</div>

                <mat-radio-group fxLayout="row" fxLayoutAlign="start end" formControlName="payer">
                    <mat-radio-button fxFlex *ngFor="let option of ['PATIENT', 'THIRD_PAYER', 'OTHER']"
                                      [value]="option">
                        {{option | translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <span fxFlex></span>

            <div [ngSwitch]="form.get('payer').valueChanges | async" fxLayoutGap="4px">

                <ng-container *ngSwitchCase="'THIRD_PAYER'">
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4px">
                        <mat-form-field fxFlex="160px" appearance="standard">
                            <mat-label>{{'ORGANISM' | translate}}</mat-label>
                            <mat-select [placeholder]="'ORGANISM' | translate" formControlName="organismName"
                                        (selectionChange)="onSelectOrganism($event)" [compareWith]="compareOrganism">
                                <mat-option *ngFor="let item of organisms" [value]="item">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <ng-container *ngIf="selectedConvention">
                            <mat-form-field appearance="standard" fxFlex="160px">
                                <mat-label>{{'CONVENTION' | translate}}</mat-label>
                                <mat-select [placeholder]="'CONVENTION' | translate"
                                            [(ngModel)]="selectedConvention.name" [ngModelOptions]="{standalone: true}"
                                            (selectionChange)="onSelectConvention($event)">
                                    <mat-option *ngFor="let convention of conventions"
                                                [value]="convention">
                                        {{orgAttrByIdx(convention, 0)}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>


                            <mat-form-field fxFlex="80px" appearance="standard">
                                <mat-label>{{'ORG_PART' | translate}}(%)</mat-label>
                                <input matInput [placeholder]="'ORG_PART' | translate"
                                       [(ngModel)]="selectedConvention.organismPartPercentage"
                                       [ngModelOptions]="{standalone: true}">
                            </mat-form-field>

                            <mat-form-field fxFlex="80px" appearance="standard">
                                <mat-label>{{'PAT_PART' | translate}}(%)</mat-label>
                                <input matInput [placeholder]="'PAT_PART' | translate"
                                       [(ngModel)]="selectedConvention.patientPartPercentage"
                                       [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                        </ng-container>

                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'OTHER'">
                    <mat-form-field fxFlex="160px" appearance="standard" [formGroup]="form">
                        <mat-label>{{'PAYER_NAME' | translate}}</mat-label>
                        <input matInput [placeholder]="'PAYER_NAME' | translate" formControlName="otherPayer">
                    </mat-form-field>

                    <mat-form-field fxFlex="160px" appearance="standard" [formGroup]="form">
                        <mat-label>{{'PAYER_ID' | translate}}</mat-label>
                        <input matInput [placeholder]="'PAYER_ID' | translate" formControlName="payerID">
                    </mat-form-field>
                </ng-container>

            </div>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="space-between">
            <mat-form-field appearance="standard" fxFlex formGroupName="paymentMethod">
                <mat-label>{{'PAYMENT_METHOD' | translate}}</mat-label>
                <mat-select [placeholder]="'PAYMENT_METHOD' | translate" formControlName="id">
                    <mat-option *ngFor="let item of paymentMethods" [value]="item.id">
                        {{item.value}}
                    </mat-option>
                </mat-select>
                <!--                <button (click)="$event.stopImmediatePropagation(); addPaymentMethod()" [disabled]="form.get('paymentDispense').value" mat-icon-button
                                        matSuffix>
                                    <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                                </button>-->
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'REFERENCE' | translate}}</mat-label>
                <input matInput [placeholder]="'REFERENCE' | translate"
                       formControlName="reference">
            </mat-form-field>
            <mat-form-field appearance="standard" fxFlex formGroupName="bank">
                <mat-label>{{'BANK' | translate}}</mat-label>
                <mat-select [placeholder]="'BANK' | translate" formControlName="id">
                    <mat-option *ngFor="let item of banks" [value]="item.id">
                        {{item.value}}
                    </mat-option>
                </mat-select>
                <button matSuffix mat-icon-button [disabled]="form.get('paymentDispense').value"
                        (click)="$event.stopImmediatePropagation(); addBank()">
                    <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>
        </div>

        <mat-form-field appearance="outline">
            <mat-label>{{'COMMENT' | translate}}</mat-label>
            <textarea matInput [placeholder]="'COMMENT' | translate" formControlName="notes"></textarea>
        </mat-form-field>

        <div *ngIf="paymentItems" [ngStyle]="{border: '2px solid #f3f3f3'}">
            <div class="ft-row ft-header">
                <div class="ft-cell">{{'CODE' | translate}}</div>
                <div class="ft-cell">{{'DESIGNATION' | translate}}</div>
                <div class="ft-cell">{{'PRICE' | translate}}</div>
                <div class="ft-cell">{{'ORG_PART' | translate}}</div>
                <div class="ft-cell">{{'PAT_PART' | translate}}</div>
                <div class="ft-cell">{{'DISCOUNT' | translate}}</div>
            </div>
            <div class="ft-row" *ngFor="let item of paymentItems">
                <div class="ft-cell">{{item.accessionNumber}}</div>
                <div class="ft-cell" fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="4px">
                    <span>{{item.procedureCode}}</span>
                    <button (click)="editProcedureCode(item)" *ngIf="!performedPayment" mat-icon-button>
                        <mat-icon fontIcon="mdi-square-edit-outline"
                                  fontSet="mdi"
                                  style="font-size: 16px !important; color: #00AAFF;"></mat-icon>
                    </button>
                </div>
                <div (blur)="changeItemValue('price', item, $event)" [contentEditable]="!performedPayment"
                     class="ft-cell">{{item.price}}</div>
                <div class="ft-cell">{{item['organismPartPrice']}}</div>
                <div class="ft-cell">{{item['patientPartPrice']}}</div>
                <div (blur)="changeItemValue('discount', item, $event)" [contentEditable]="!performedPayment"
                     class="ft-cell">{{item.discount}}</div>
            </div>

        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end" [formGroup]="form">
    <mat-slide-toggle formControlName="paymentDispense" style="font-size: 12px; font-weight: 400;" tabindex="-1">
        {{'PAYMENT_DISPENSE' | translate}}
    </mat-slide-toggle>
    <span fxFlex></span>
    <button [mat-dialog-close]="null" color="accent" mat-raised-button>{{'EXIT' | translate}}</button>
    <button *ngIf="performedPayment && (profile && profile.canDeletePayment)" mat-raised-button
            color="warn" (click)="cancelPayment()">{{'CANCEL_PAYMENT' | translate}}</button>
    <button (click)="printPaymentReceipt()" *ngIf="performedPayment" color="primary" mat-raised-button>
        <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
        {{'PAYMENT_RECEIPT' | translate}}
    </button>
    <button (click)="createPayment()" *ngIf="!performedPayment" color="primary" mat-raised-button
            [disabled]="form.invalid">{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>
