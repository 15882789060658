import {forkJoin, Observable, of as observableOf} from 'rxjs';

import {debounceTime} from 'rxjs/operators';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
    addDays,
    FileElement,
    FileService,
    findFromArrayById,
    orderNullableValues,
    ReferringPhysicianAddComponent,
    SharedService
} from '../../shared';

import {PatientSearchComponent} from '../../patient/patient-search/patient-search.component';
import * as moment from 'moment';
import {assign, find as _find, get, set as _set} from 'lodash';
import {ADMISSIONS, DEBITERS, imagingServiceRequestEntity, initEntity, WsMessage} from '../../model';
import {SchedulerService} from '../scheduler.service';
import {AppConfigService} from "../../app-config.service";
import {PatientService} from "../../patient/patient.service";
import {SettingService} from "../../setting/setting.service";


@Component({
    selector: 'app-order-edit',
    templateUrl: './order-edit.component.html',
    styleUrls: ['./order-edit.component.scss']
})
export class OrderEditComponent implements OnInit, OnDestroy {

    patIdExists: boolean;
    anExists: boolean;
    patientSelected: boolean;
    defaultConfidentiality: any;
    patientForm: FormGroup;
    orderForm: FormGroup;
    isrForm: FormGroup;

    ageControl = new FormControl('');
    genders: any[];
    titles: any[];
    orderTypes: any[];
    hospitalServices: any[];
    trsArrangeds: any[];
    confidentialities: any[];
    referringPhysicians: any[];
    rfes: any[];
    debiters: string[];
    admissions: string[];
    approvers: any[];
    trsModes: any[];
    priorities: any[];
    orderStatus: any;

    searchKeys: any = {
        fn: '',
        ln: ''
    };
    selectedDateTime: any;
    private defaultPriority: any;

    private defaultOrderType: any;

    selectedPatient: any = null;
    numOfDuplicatePatients: number;

    now = new Date();
    fileElements: Observable<FileElement[]>;
    currentRoot: FileElement;
    currentPath: string;

    canNavigateUp = false;
    patientId: number;

    patientID: string;
    generalSetting: any;
    ageForm: FormGroup;
    private readonly user: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private sharedService: SharedService,
                private scheduleService: SchedulerService,
                private patientService: PatientService,
                private fb: FormBuilder,
                private fileService: FileService,
                private setting: SettingService,
                private _config: AppConfigService,
                private dialogRef: MatDialogRef<OrderEditComponent>,
                private dialog: MatDialog) {

        this.debiters = DEBITERS;
        this.admissions = ADMISSIONS;

        this.user = JSON.parse(localStorage.getItem('user'));

        this.orderForm = this.fb.group({
            id: '',
            callBackPhoneNumber: '',
            orderingProvider: '',
            referringPhysician: this.fb.group({id: ''}),
            relevantClinicalInfo: '',
            ResultCopiesTo: '',
            requestedDate: [null, Validators.required],
            requestedTime: ['09:00', Validators.required],
            reasonForStudy: '',
            orderTimeLine: this.fb.group({
                id: '',
                creation: new Date()
            }),
            enteredBy: this.user,
            priority: this.fb.group({
                id: '',
                value: '',
                dateRange: this.fb.group({
                    startDate: '',
                    endDate: ''
                })
            }),
            patient: null,
            orderType: this.fb.group(initEntity),
            orderControl: null,
            reasonForExam: this.fb.group(initEntity),
            requestingService: this.fb.group(initEntity),
            confidentiality: this.fb.group(initEntity),
        });

        this.isrForm = this.fb.group(assign(imagingServiceRequestEntity, {
            imagingServiceRequestComments: '',
            accessionNumber: ''
        }));

        this.patientForm = this.fb.group({
            id: 0,
            externalPatientID: null,
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            title: this.fb.group({id: ''}),
            demographic: this.fb.group({
                id: '',
                dateOfBirth: [null],
                gender: this.fb.group(initEntity),
                confidentiality: this.fb.group(initEntity),
            }),
            phone: '',
            cin: '',
            email: ''
        });
        this.createAgeForm();

        this.patientForm.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            if (value.firstName && value.lastName) this.patientService.countExistingPatients(value.firstName, value.lastName).subscribe(total => this.numOfDuplicatePatients = total);
        })
    }

    changeDate() {
        let age = this.ageForm.getRawValue();
        if (age.years !== null && age.months !== null && age.days !== null) {
            this.scheduleService.calculateDOB(`${age.years || '0'}-${age.months || '0'}-${age.days || '0'}`).subscribe((res: WsMessage) => {
                let dob = moment(res.data, this._config.momentDateFormat);
                setTimeout(() => this.patientForm.get('demographic').patchValue({dateOfBirth: dob}), 200);
            });
        }
    }

    changeAge() {
        let value = this.patientForm.get('demographic.dateOfBirth').value;
        this.scheduleService.calculateAge(moment(value).format('YYYYMMDD')).subscribe((res: WsMessage) => {
            if (res.data) {
                let age = res.data.split('-');

                this.ageForm.get('years').patchValue(age[0]);
                this.ageForm.get('months').patchValue(age[1]);
                this.ageForm.get('days').patchValue(age[2]);
            }
        });
    }

    addReferringPhysician() {
        this.dialog.open(ReferringPhysicianAddComponent)
            .afterClosed()
            .subscribe(physician => {
                if (physician) {
                    this.referringPhysicians.push(physician);
                }
            })
    }

    onSelectRefPhysician(event) {
        let phone = _find(this.referringPhysicians, {id: event.value}).phone;
        this.orderForm.patchValue({callBackPhoneNumber: phone})
    }

    onSave() {

        let order = this.orderForm.getRawValue();
        let patient = this.patientForm.getRawValue();

        let title = get(patient, 'title');

        patient.title = title.id !== '' ? title : null;

        order.enteredBy = this.user;
        order.orderStatus = this.orderStatus;
        order.requestedDate = moment(order.requestedDate).add(1, 'h');

        patient.demographic = assign(patient.demographic, {
            confidentiality: order.confidentiality
        });

        let isr = this.isrForm.value;
        isr.imagingOrder = orderNullableValues(order);

        isr.requestingService = order.requestingService ? findFromArrayById(this.hospitalServices, order.requestingService.id).description : 'RIS';

        isr.requestedProcedure = null;

        if (patient.demographic.gender && patient.demographic.gender.id === '') patient.demographic.gender = null;
        if (patient.demographic.gender && patient.demographic.gender.id === '') patient.demographic.gender = null;

        isr.patient = patient;

        this.dialogRef.close(isr);
    }


    ngOnInit() {

        this.selectedPatient = get(this.data, 'patient');

        if (this.selectedPatient) this.selectPatient(this.selectedPatient);

        this.initData();

        this.patientForm.get('externalPatientID').valueChanges.pipe(debounceTime(200)).subscribe(value => {
            if (String(value).length !== 0) this.sharedService.checkIdIfExists(value).subscribe(res => this.patIdExists = res)
        });

        this.isrForm.get('accessionNumber').valueChanges.pipe(debounceTime(200)).subscribe(value => {
            if (String(value).length !== 0) this.sharedService.checkAnIfExists(value).subscribe(res => this.anExists = res);
        });

        this.patientForm.get('demographic.gender').valueChanges.subscribe(gender => {
            let title = gender.id === 10 ? this.titles[0] : this.titles[1];
            this.patientForm.get('title').patchValue(title);
        });
    }

    onSearchPatient() {

        assign(this.searchKeys, {
            fn: this.patientForm.value.firstName,
            ln: this.patientForm.value.lastName
        });
        const dialogRef = this.dialog.open(PatientSearchComponent, {width: '640px', data: this.searchKeys});
        dialogRef.componentInstance.selectedPatient = {};
        dialogRef
            .afterClosed()
            .subscribe(selectedPatient => {
                if (selectedPatient) {
                    this.selectPatient(selectedPatient);
                }
            });
    }

    selectPatient(patient) {

        this.patientSelected = true;

        this.patientID = patient.externalPatientID;

        this.fileElements = this.fileService.getPatientDocuments(this.patientID, 'root');

        let dob = get(patient, 'demographic.dateOfBirth');
        let gender = get(patient, 'demographic.gender');
        let confidentiality = get(patient, 'demographic.confidentiality');
        let title = get(patient, 'title');

        let age = moment.duration(dob ? dob : new Date()).years();

        this.ageControl.patchValue(age);

        patient.demographic.confidentiality = (confidentiality && confidentiality.id !== '') ? confidentiality : this.defaultConfidentiality;
        patient.demographic.gender = gender || {id: ''};
        patient.demographic.dateOfBirth = dob || new Date();

        patient.title = title || {id: ''};

        this.patientForm.patchValue(patient);
    }

    initData() {

        forkJoin(
            this.sharedService.getTitles(),
            this.sharedService.getPriorities(),
            this.sharedService.getHospitalServices(),
            this.sharedService.getConfidentialities(),
            this.sharedService.getGenders(),
            this.sharedService.getOrderStatusByValue('US'),
            this.sharedService.getOrderTypes(),
            this.sharedService.getStaff(),
            this.sharedService.getTransportArranged(),
            this.sharedService.getTransportationModes(),
            this.sharedService.getReasonForExams(),
            this.sharedService.getReferringPhysicians(),
            this.setting.getGeneralSetting()
        ).subscribe(data => {
            [
                this.titles,
                this.priorities,
                this.hospitalServices,
                this.confidentialities,
                this.genders,
                this.orderStatus,
                this.orderTypes,
                this.approvers,
                this.trsArrangeds,
                this.trsModes,
                this.rfes,
                this.referringPhysicians,
                this.generalSetting
            ] = data;

            this.defaultConfidentiality = this.confidentialities[0];
            this.defaultOrderType = this.orderTypes[1];
            this.defaultPriority = this.priorities[2];

            this.buildForm();

            let isr = get(this.data, 'isr');
            if (isr) {
                let order = isr!.imagingOrder;
                this.isrForm.patchValue(isr);

                order = assign(order, {
                    reasonForExam: order.reasonForExam || {id: ''},
                    referringPhysician: order.referringPhysician || {id: ''},
                    requestingPhysician: order.requestingPhysician || {id: ''},
                    requestingService: order.requestingService || {id: ''},
                    confidentiality: order.confidentiality || {id: ''},
                    orderType: order.orderType || {id: ''},
                    priority: order.priority || {id: ''},
                });

                this.orderForm.patchValue(order);
            }

            if (!get(this.data, 'patient.externalPatientID')) {
                this.generatePatientID();
            }
        });

    }

    changePriority(selection) {
        let priority = _find(this.priorities, {id: selection.value});

        this.orderForm.patchValue({
            requestedDate: addDays(new Date(), get(priority, 'dateRange.startDate', 1)),
            requestedTime: moment().format('HH:mm'),
        });
    }

    private generatePatientID() {
        this.sharedService.generatePatientId().subscribe(res => {
            this.patientForm.get('externalPatientID').patchValue(res.id);
            this.patientID = res.id;
        });
    }

    private buildForm() {
        this.orderForm.get('priority').patchValue(this.defaultPriority);
        let start = get(this.defaultPriority, 'dateRange.startDate', 1);

        let now = new Date();

        this.orderForm.patchValue({
            requestedDate: addDays(now, start),
            requestedTime: moment().format('HH:mm'),
        });

        this.orderForm.get('orderType').patchValue(this.defaultOrderType);
        this.orderForm.get('confidentiality').patchValue(this.defaultConfidentiality);

        let patient = get(this.data, 'patient');

        if (patient) {
            this.patientForm.patchValue(patient);
            this.orderForm.patchValue({
                relevantClinicalInfo: get(patient, 'medicalHistory.additionalPatientHistory')
            });
        }

        if (this.selectedDateTime) this.orderForm.patchValue({
            requestedDate: this.selectedDateTime.requestedDate,
            requestedTime: this.selectedDateTime.requestedTime
        });

        if (this.selectedDateTime) {
            this.orderForm.patchValue({
                requestedDate: this.selectedDateTime.requestedDate,
                requestedTime: this.selectedDateTime.requestedTime,
            })
        }
    }


    // Attached documents

    addFolder(folder: { name: string }) {
        let file: FileElement = {
            folder: true,
            patientID: this.patientID,
            name: folder.name,
            parent: this.currentRoot ? this.currentRoot.uuid : 'root'
        };

        this.fileService.createFile(file).subscribe((res: FileElement) => {
            this.updateFileElementQuery();
        });
    }

    afterUpload(event) {
        if (event) this.updateFileElementQuery();
    }

    moveElement(event: { element: FileElement; moveTo: FileElement }) {

        let elt = event.element;
        _set(elt, 'parent', event.moveTo.uuid);

        this.fileService.updateFile(elt).subscribe(res => {
            this.updateFileElementQuery();
        });
    }

    removeElement(element: FileElement) {
        this.fileService.deleteFile(element).subscribe(ok => {
            if (ok) this.updateFileElementQuery();
        });
    }

    renameElement(element: FileElement) {
        _set(element, 'name', element.name);

        this.fileService.updateFile(element).subscribe(res => {
            this.updateFileElementQuery();
        });
    }

    updateFileElementQuery() {
        this.fileElements = this.fileService.getPatientDocuments(this.patientID, this.currentRoot ? this.currentRoot.uuid : 'root');
    }

    navigateUp() {
        if (this.currentRoot && this.currentRoot.parent === 'root') {
            this.currentRoot = null;
            this.canNavigateUp = false;
            this.updateFileElementQuery();
        } else {
            this.currentRoot = this.fileService.get(this.currentRoot.parent);
            this.updateFileElementQuery();
        }
        this.currentPath = this.popFromPath(this.currentPath);
    }

    navigateToFolder(element: FileElement) {
        this.currentRoot = element;
        this.updateFileElementQuery();
        this.currentPath = this.pushToPath(this.currentPath, element.name);
        this.canNavigateUp = true;
    }

    pushToPath(path: string, folderName: string) {
        let p = path ? path : '';
        p += `${folderName}/`;
        return p;
    }

    popFromPath(path: string) {
        let p = path ? path : '';
        let split = p.split('/');
        split.splice(split.length - 2, 1);
        p = split.join('/');
        return p;
    }

    ngOnDestroy() {
        this.currentPath = null;
        this.currentRoot = null;
        this.patientID = null;
        this.fileElements = observableOf([]);
    }

    private createAgeForm() {
        this.ageForm = this.fb.group({years: 0, months: 0, days: 0});
    }

}


