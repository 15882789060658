import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {AuthService} from "./auth";


@Injectable()
export class UserResolver implements Resolve<Observable<any>> {
    constructor(private _authService: AuthService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this._authService.checkUser();
    }
}
