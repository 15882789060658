<full-calendar #calendar [options]="calendarOptions"></full-calendar>

<mat-form-field style="display: none">
    <mat-label></mat-label>
    <input matInput [matDatepicker]="picker" (dateChange)="gotoDate($event)">
    <mat-datepicker #picker [touchUi]="true"></mat-datepicker>
</mat-form-field>


<ng-template #events let-event="event">
    <div class="fc-content">
        <span>{{event.title}}</span>
    </div>
</ng-template>

<div #vc></div>
