<div fxLayout="column">

    <input type="file" (change)="onFilesAdded()" style="display: none;" multiple #file>

    <mat-toolbar class="file-x-toolbar">
        <mat-icon *ngIf="canNavigateUp" class="pointer" (click)="navigateUp()" fontIcon="mdi-arrow-left"
                  fontSet="mdi"></mat-icon>
        <span class="path">{{path || ('FILES' | translate)}}</span>

        <span class="spacer"></span>

        <input type="search" class="search-file" [placeholder]="'SEARCH' | translate"
               (keyup)="searchFile($event?.target['value'])">

        <mat-icon class="file-x-icon pointer" [matTooltip]="'UPLOAD_FILE' | translate" (click)="uploadFile()"
                  fontIcon="mdi-cloud-upload"
                  fontSet="mdi"></mat-icon>
        <mat-icon class="file-x-icon pointer" [matTooltip]="'TAKE_SPEECH_NOTE' | translate" (click)="takeSpeechNote()"
                  fontIcon="mdi-note-plus"
                  fontSet="mdi"></mat-icon>
        <mat-icon class="file-x-icon pointer" [matTooltip]="'RECORD_AUDIO_FILE' | translate" (click)="recordAudioFile()"
                  fontIcon="mdi-microphone"
                  fontSet="mdi"></mat-icon>
        <mat-icon class="file-x-icon pointer" [matTooltip]="'TAKE_PICTURE' | translate" (click)="captureImage()"
                  fontIcon="mdi-camera" fontSet="mdi"></mat-icon>
        <mat-icon class="file-x-icon pointer" [matTooltip]="'CREATE_NEW_FOLDER' | translate"
                  (click)="createNewFileElement()" fontIcon="mdi-folder-plus"
                  fontSet="mdi"></mat-icon>
        <mat-icon class="file-x-icon pointer"
                  [matTooltip]="((gridView | async) ? 'LIST_VIEW': 'THUMBNAIL_VIEW') | translate" (click)="switchView()"
                  [fontIcon]="(gridView | async) ? 'mdi-view-list':'mdi-view-grid'" fontSet="mdi"></mat-icon>
    </mat-toolbar>

    <div fxFlex fxLayout="row" fxLayoutAlign="space-between stretch" class="file-container"
         [ngClass]="{'grid': modalMode, 'hidden': (!fileElements || fileElements.length === 0)}">

        <div [ngClass]="{'grid-view': gridView | async, 'modal': modalMode}" fxFlex>

            <div *ngIf="canNavigateUp" (click)="navigateUp()" class="list-view">
                <div [fxLayout]="(gridView | async) ? 'column': 'row'" fxLayoutAlign="start center" fxLayoutGap="4px">
                    <img class="file-or-folder-icon pointer fire-folder"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/019-folder-2.svg">
                    <div class="file-name"> ...</div>
                </div>
            </div>

            <div *ngFor="let element of fileElements" class="file-or-folder list-view"
                 [ngClass]="{'grid-item': modalMode && (gridView | async)}" id="{{element.uuid}}"
                 (click)="openElement(element)">

                <span [matMenuTriggerFor]="rootMenu"
                      [matMenuTriggerData]="{element: element}"
                      #menuTrigger="matMenuTrigger">
                </span>
                <div [fxLayout]="(gridView | async) ? 'column': 'row'"
                     fxLayoutAlign="start center" fxLayoutGap="4px"
                     (click)="navigate(element)"
                     (contextmenu)="openMenu($event, element, menuTrigger)">

                    <img *ngIf="element.folder" class="file-or-folder-icon pointer fire-folder"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/017-folder.svg">
                    <img class="file-or-folder-icon pointer fire-file"
                         *ngIf="!element.folder && element.fileType === 'WAV'"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/wav.svg">
                    <img class="file-or-folder-icon pointer fire-file"
                         *ngIf="!element.folder && element.fileType === 'PNG'"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/011-png-1.svg">
                    <img class="file-or-folder-icon pointer fire-file"
                         *ngIf="!element.folder && element.fileType === 'HTML'"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/006-html.svg">
                    <img class="file-or-folder-icon pointer fire-file"
                         *ngIf="!element.folder && element.fileType === 'PDF'"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/007-pdf-1.svg">
                    <img class="file-or-folder-icon pointer fire-file"
                         *ngIf="!element.folder && element.fileType === 'ZIP'"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/009-zip.svg">
                    <img class="file-or-folder-icon pointer fire-file"
                         *ngIf="!element.folder && element.fileType === 'MP3'"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/010-mp3.svg">
                    <img class="file-or-folder-icon pointer fire-file"
                         *ngIf="!element.folder && element.fileType === 'TXT'"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/016-txt.svg">
                    <img class="file-or-folder-icon pointer fire-file"
                         *ngIf="!element.folder && element.fileType === 'TORRENT'"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/torrent-file-format.svg">
                    <img class="file-or-folder-icon pointer fire-file"
                         *ngIf="!element.folder && (element.fileType === 'JPG' || element.fileType === 'JPEG')"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/008-jpg.svg">
                    <img class="file-or-folder-icon pointer fire-file"
                         *ngIf="!element.folder && (element.fileType === 'XLS' || element.fileType === 'XLSX')"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/004-excel.svg">
                    <img class="file-or-folder-icon pointer fire-file"
                         *ngIf="!element.folder && (element.fileType === 'PPT' || element.fileType === 'PPTX')"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/005-powerpoint.svg">
                    <img class="file-or-folder-icon pointer fire-file"
                         *ngIf="!element.folder && (element.fileType === 'DOC' || element.fileType === 'DOCX')"
                         [ngClass]="{'folder-icon-list': !(gridView | async)}"
                         src="../../../../assets/images/svg/003-word.svg">

                    <div (blur)="renameFile($event, element)" (keydown)="renameOnEnter($event, element)" #elt
                         (click)="$event.stopImmediatePropagation();"
                         contenteditable
                         class="file-name" [ngClass]="{'file-name-list': !(gridView | async)}">{{element.name}}
                    </div>

                    <span fxFlex="1 1 auto"></span>

                    <div fxFlex="60" fxLayout="row" fxLayoutAlign="end center" *ngIf="!(gridView | async)"
                         class="col-info">
                        <div fxFlex class="file-name" [ngClass]="{'file-name-list': !(gridView | async)}">
                            {{(element.createdDate || now) | date: 'dd/MM/yyyy'}}
                        </div>
                        <div fxFlex class="file-name" [ngClass]="{'file-name-list': !(gridView | async)}">
                            {{(element.fileType || 'FOLDER') | translate}}
                        </div>
                        <div fxFlex class="file-name" [ngClass]="{'file-name-list': !(gridView | async)}">
                            {{ (element.folder ? '': getHRFileSizeString(element.size)) || ''}}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <mat-menu #rootMenu="matMenu" [overlapTrigger]="false">
        <ng-template matMenuContent let-element="element">
            <button [disabled]="getFolders(fileElements, element).length === 0" mat-menu-item [matMenuTriggerFor]="moveToMenu" [matMenuTriggerData]="{self: element}">
                <mat-icon fontIcon="mdi-folder-move" fontSet="mdi"></mat-icon>
                <span>{{'MOVE_TO' | translate}}</span>
            </button>
            <button mat-menu-item *ngIf="!element.folder" (click)="downloadFile(element)">
                <mat-icon fontIcon="mdi-download" fontSet="mdi"></mat-icon>
                <span>{{'DOWNLOAD' | translate}}</span>
            </button>
            <button mat-menu-item (click)="deleteElement(element)">
                <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                <span>{{'DELETE' | translate}}</span>
            </button>
        </ng-template>
    </mat-menu>

    <mat-menu #moveToMenu="matMenu">
        <ng-template matMenuContent let-self="self">
            <ng-container *ngFor="let element of fileElements">
                <button *ngIf="element.folder && element.id !== self.id" mat-menu-item
                        (click)="moveElement(self, element)">{{element.name}}
                </button>
            </ng-container>
        </ng-template>
    </mat-menu>

</div>
