import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {chain} from 'lodash';
import {Observable, of, timer} from 'rxjs';
import {catchError, debounce, filter, map, tap} from 'rxjs/operators';

import {
    DefaultValues,
    Dictionary,
    GeneralSetting,
    Holiday,
    ImagingCenter,
    PrintingTemplateModel,
    ReportingConfig,
    TableConfig,
    TableView,
    TemplateModel,
    UploadInstance,
    Viewer
} from '../model';
import {SortDirection} from '@angular/material/sort';
import {DOCUMENT_URL, RESOURCE_URL, SETTING_URL, SHARED_URL} from '../urls';
import {CalendarSetting} from './schedule-setting/schedule-setting.component';

type ListType = 'STD' | 'NORMAL';

@Injectable()
export class SettingService {

    constructor(private http: HttpClient) {
    }

    saveHoliday(holiday: Holiday): Observable<Holiday> {
        return this.http.post<Holiday>(`${SETTING_URL}/create-holiday`, holiday);
    }

    deleteRoom(roomId: number): Observable<any> {
        return this.http.get(RESOURCE_URL + `/deleteRoom`, {params: {id: String(roomId)}});
    }

    saveRoom(room: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveRoom', room);
    }

    getAetList(): Observable<any> {
        return this.http.get(RESOURCE_URL + '/aets');
    }

    getResourceUnavailabilities(resourceID: number): Observable<any> {
        return this.http.get(RESOURCE_URL + `/unavailabilities/${resourceID}`);
    }

    saveUnavailability(unavailability: any, id: number): Observable<any> {
        return this.http.post(RESOURCE_URL + `/saveUnavailability/${id}`, unavailability);
    }

    saveModality(modality: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveModality', modality);
    }


    getDicomServer(service: string): Observable<any> {
        return this.http.get(SETTING_URL + `/dicomServer/${service}`);
    }

    saveDicomServer(server: any): Observable<any> {
        return this.http.post(SETTING_URL + '/saveDicomServer', server);
    }

    saveStaff(staff: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveStaff', staff);
    }

    deleteStaff(staff: any): Observable<any> {
        return this.http.delete(RESOURCE_URL + `/deleteStaff/${staff.id}`);
    }

    saveReportTemplate(template: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveReportTemplate', template);
    }

    saveProcedureCodeCatalog(catalog: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveProcedureCodeCatalog', catalog);
    }

    saveProcedureCode(code: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveProcedureCode', code);
    }

    saveBillingCode(code: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveBillingCode', code);
    }

    saveProtocolCode(protocol: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveProtocolCode', protocol);
    }

    saveAet(aet: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveAet', aet);
    }

    savePriority(priority: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/savePriority', priority);
    }

    saveOrderStatus(orderStatus: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveOrderStatus', orderStatus);
    }

    saveAptStatus(aptStatus: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveAptStatus', aptStatus);
    }

    saveImagingCenter(center: ImagingCenter): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveImagingCenter', center);
    }

    deleteUser(user: any): Observable<any> {
        return this.http.get(SETTING_URL + `/deleteUser`, {params: {id: String(user.id)}});
    }

    saveUser(user: any): Observable<any> {
        return this.http.post(SETTING_URL + '/saveUser', user);
    }

    updateUser(user: any): Observable<any> {
        return this.http.post(SETTING_URL + '/updateUser', user);
    }

    deleteProfile(id: any): Observable<any> {
        return this.http.get(SETTING_URL + `/deleteProfile`, {params: {id}});
    }

    saveProfile(profile: any): Observable<any> {
        return this.http.post(SETTING_URL + '/saveProfile', profile);
    }

    getDicomServerStatus(service: string): Observable<any> {
        return this.http.get(SETTING_URL + `/dicomServerStatus/${service}`);
    }

    stopDicomServer(service: string): Observable<any> {
        return this.http.get(SETTING_URL + `/stopDicomServer/${service}`);
    }


    restartDicomServer(service: string): Observable<any> {
        return this.http.get(SETTING_URL + '/restartDicomServer/' + service);
    }

    getCalendarSetting(id: number): Observable<CalendarSetting> {
        return this.http.get<CalendarSetting>(SETTING_URL + `/calendarSetting/${id}`);
    }

    saveCalendarSetting(calSetting: any): Observable<CalendarSetting> {
        return this.http.post<CalendarSetting>(SETTING_URL + '/saveCalendarSetting', calSetting);
    }

    deleteAet(aetId: number): Observable<any> {
        return this.http.get(RESOURCE_URL + `/deleteAET/${aetId}`);
    }

    deletePriority(priorityId: number): Observable<any> {
        return this.http.delete(RESOURCE_URL + `/deletePriority/${priorityId}`);
    }

    deleteProtocol(id: number): Observable<any> {
        return this.http.get(RESOURCE_URL + `/deleteProtocolCode/${id}`);
    }

    deleteProcedureCatalog(id: number): Observable<any> {
        return this.http.get(RESOURCE_URL + `/deleteProcedureCatalog/${id}`);
    }

    deleteProcedureCode(id: number): Observable<any> {
        return this.http.get(RESOURCE_URL + `/deleteProcedureCode/${id}`);
    }

    deleteUnavailability(id: number): Observable<any> {
        return this.http.delete(RESOURCE_URL + `/deleteUnavailability/${id}`);
    }

    deleteBillingCode(id: number): Observable<any> {
        return this.http.get(RESOURCE_URL + `/deleteBillingCode`, {params: {id: String(id)}})
    }

    isUserExists(username: string): Observable<boolean> {
        return this.http.get<boolean>(RESOURCE_URL + `/isExists/${username}`);
    }

    getTemplateModels(): Observable<TemplateModel[]> {
        return this.http.get<TemplateModel[]>(RESOURCE_URL + '/templateModels');
    }

    getReportTemplateModels(pageSize: number, pageIndex: number, sort: string, direction: SortDirection, searchKey): Observable<any> {
        let params = {page: String(pageIndex), size: String(pageSize), sort: `${sort},${direction}`, searchKey};
        return this.http.get(`${RESOURCE_URL}/report-template-models`, {params})
    }

    saveTemplateModel(tm: TemplateModel): Observable<TemplateModel> {
        return this.http.post<TemplateModel>(RESOURCE_URL + '/saveTemplateModel', tm);
    }

    getDictionaries(): Observable<Dictionary[]> {
        return this.http.get<Dictionary[]>(RESOURCE_URL + '/dictionaries');
    }

    saveDictionary(dictionary: Dictionary): Observable<Dictionary> {
        return this.http.post<Dictionary>(RESOURCE_URL + '/saveDictionary', dictionary);
    }

    uploadRadReport(formData: FormData): Observable<any> {
        return this.http.post(RESOURCE_URL + '/radReport', formData);
    }

    deleteReasonForExam(id: number): Observable<any> {
        return this.http.delete(RESOURCE_URL + `/deleteReasonForExam/${id}`);
    }

    saveReasonForExam(reasonForExam: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveReasonForExam', reasonForExam);
    }

    deleteSPSStatus(id): Observable<any> {
        return this.http.delete(RESOURCE_URL + `/deleteSpsStatus/${id}`);
    }

    saveSPSStatus(spsStatus: any): Observable<any> {
        return this.http.post(RESOURCE_URL + '/saveSPSStatus', spsStatus);
    }

    getReportingConfig(id: number): Observable<ReportingConfig> {
        return this.http.get<ReportingConfig>(SETTING_URL + `/reportingConfig/${id}`);
    }

    saveReportingConfig(reportingConfig: ReportingConfig): Observable<ReportingConfig> {
        return this.http.post<ReportingConfig>(SETTING_URL + '/saveReportingConfig', reportingConfig);
    }

    deleteTemplateModel(tm: TemplateModel): Observable<any> {
        return this.http.post(SETTING_URL + '/deleteTemplateModel', tm)
    }

    deleteDictionary(dic: Dictionary): Observable<any> {
        return this.http.post(RESOURCE_URL + '/deleteDictionary', dic);
    }

    getGeneralSetting(): Observable<GeneralSetting> {
        return this.http.get<GeneralSetting>(SETTING_URL + '/general-setting');
    }

    saveGeneralSetting(generalSetting: GeneralSetting): Observable<GeneralSetting> {
        return this.http.post<GeneralSetting>(SETTING_URL + '/general-setting-save', generalSetting);
    }

    getHolidays(pageSize: number, pageIndex: number, active: string, direction: SortDirection): Observable<any> {

        let params = new HttpParams()
            .set('page', String(pageIndex))
            .set('size', pageSize + ',' + direction);

        return this.http.get(`${SETTING_URL}/holidays`, {params: params});
    }

    deleteHoliday(holiday: Holiday): Observable<any> {
        let params = {id: String(holiday.id)};
        return this.http.get(`${SETTING_URL}/delete-holiday`, {params});
    }

    uploadCenterLogo(logo: FormData): Observable<any> {
        return this.http.post(`${SETTING_URL}/logo`, logo);
    }

    createTableView(view: TableView): Observable<TableView> {
        return this.http.post<TableView>(`${SETTING_URL}/createTableView`, view);
    }

    getTableViews(id: any, module: string): Observable<TableView[]> {
        return this.http.get<TableView[]>(`${SETTING_URL}/getTableViews`, {params: {id, module}});
    }

    deleteTableView(id: number): Observable<boolean> {
        return this.http.get<boolean>(`${SETTING_URL}/deleteTableView`, {params: {id: String(id)}});
    }

    deleteStaffContract(id: any): Observable<boolean> {
        let params = {id: String(id)};
        return this.http.get<boolean>(`${RESOURCE_URL}/deleteStaffContract`, {params})
    }

    getViewers(): Observable<Viewer[]> {
        return this.http.get<Viewer[]>(`${SHARED_URL}/viewers`);
    }

    createViewer(viewer: Viewer): Observable<Viewer> {
        return this.http.post<Viewer>(`${SHARED_URL}/createViewer`, viewer);
    }

    deleteViewer(viewer: Viewer): Observable<boolean> {
        return this.http.post<boolean>(`${SHARED_URL}/deleteViewer`, viewer);
    }

    getNormalTemplateModels(): Observable<any> {
        return this.getTemplateModelsData('NORMAL');
    }

    getStandardTemplateModels(): Observable<any> {
        return this.getTemplateModelsData('STD');
    }

    private getTemplateModelsData(listType: ListType): Observable<any> {
        let params = {listType};
        return this.http.get(`${SETTING_URL}/templateModels`, {params});
    }

    getPrintingTemplateModels(): Observable<PrintingTemplateModel[]> {
        return this.http.get<PrintingTemplateModel[]>(`${SETTING_URL}/printingTemplateModels`);
    }

    getPrintingModels(): Observable<any> {
        return this.http.get<any>(`${SETTING_URL}/printingModels`);
    }

    savePrintingTemplateFile(file: PrintingTemplateModel): Observable<boolean> {
        return this.http.post<boolean>(`${SETTING_URL}/savePrintingTemplateFile`, file);
    }

    applyTemplateHeader(): Observable<any> {
        return this.http.get(`${SETTING_URL}/applyTemplateHeader`);
    }

    getViewer(viewerName: string): Observable<Viewer> {
        let params = {viewerName};
        return this.http.get<Viewer>(`${SHARED_URL}/viewer`, {params});
    }

    getTableConfig(name: string, userId: any): Observable<any> {
        return this.http.get(`${SETTING_URL}/tableConfig`, {params: {name, userId}});
    }

    saveTableConfig(tableConfig: TableConfig): Observable<TableConfig> {
        return this.http.post<TableConfig>(`${SETTING_URL}/saveTableConfig`, tableConfig);
    }

    public uploadFiles(files: UploadInstance[]): Observable<UploadInstance> {
        const observables = chain(files)
            .map(file => new Object({file, subject: this._uploadFileItem(file)}))
            .value();

        return new Observable(subject => this._uploadChunk(0, observables, subject));
    }

    private _uploadChunk(index, chunks, subject) {
        if (index === chunks.length) {
            subject.complete();
        } else {
            subject.next(chunks[index].file);
            chunks[index].subject.subscribe(data => subject.next(data), err => subject.error(err), () => {
                this._uploadChunk(index += 1, chunks, subject);
            });
        }
    }


    createTemplates(): Observable<any> {
        return this.http.post(`${DOCUMENT_URL}/create-templates`, null);
    }

    uploadProceduresFile(file: File) {
        let instance = new UploadInstance(file, 0, file.name);
        return this._uploadFileItem(instance);
    }

    private _uploadFileItem(item: UploadInstance) {
        const data: FormData = new FormData();
        data.append('file', item.file, item.fullPath);

        const config = new HttpRequest('POST', `${DOCUMENT_URL}/files-upload`, data, {
            reportProgress: true
        });

        return this.http.request(config)
            .pipe(
                tap(event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        item.progress = (event.loaded / event.total) * 100;
                    }
                }),
                filter(event => event.type === HttpEventType.Response),
                map((response: HttpResponse<any>) => response.body),
                debounce(() => timer(50)),
                catchError(() => of(null))
            );
    }

    updateTemplateCategory(category: string, updatedCategory: any): Observable<any> {
        return this.http.post(`${SETTING_URL}/updateTemplateCategory/${category}`, updatedCategory);
    }

    deleteTemplateByCategory(category: string): Observable<any> {
        return this.http.get(`${SETTING_URL}/deleteTemplateByCategory`, {params: {category}});
    }

    deleteAllProcedureCodes(): Observable<any> {
        return this.http.delete(`${RESOURCE_URL}/deleteAllProcedureCodes`)
    }

    saveDefaultValues(defaultValues: DefaultValues): Observable<DefaultValues> {
        return this.http.post<DefaultValues>(`${SETTING_URL}/save-default-values`, defaultValues);
    }

    getDefaultValues(): Observable<DefaultValues> {
        return this.http.get<DefaultValues>(`${SETTING_URL}/default-values`);
    }
}
