import {Message} from './message';
import {ChatUser} from './chat-user';

export class ChatRoom {
    constructor(public id: number,
                public user1: ChatUser,
                public user2: ChatUser,
                public lastMessage: Message = null,
                public unread: number = null,
                public deleted: boolean = false,
                public deletedFor: number = null,
    ) {
    }
}
