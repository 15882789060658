export const KV_TABLE_CONF = [
    {
        "label": "id",
        "header": "ID",
        "value": "id",
        "type": "string",
        "unit": null,
        "defaultValue": "-",
        "sortable": true,
        "hidden": true,
        "searchable": true,
        "required": false
    }, {
        "label": "value",
        "header": "VALUE",
        "value": "value",
        "type": "string",
        "unit": null,
        "defaultValue": "-",
        "sortable": true,
        "hidden": false,
        "searchable": true,
        "required": false
    }
];

export const KV_HEADER_COLS = [
    // 'id',
    'value',
    'action'
];