import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {KV_HEADER_COLS, KV_TABLE_CONF} from './table-config';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {Dictionary, KeyValue} from '../../model';
import {deleteItemFromArray} from '../../shared';
import {SettingService} from "../setting.service";

@Component({
    selector: 'app-dictionary',
    templateUrl: './dictionary.component.html',
    styleUrls: ['./dictionary.component.scss']
})
export class DictionaryComponent implements OnInit {

    static count = 0;
    dictionaries: Dictionary[] = [];
    selectedDictionary: Dictionary;
    selectedItem: KeyValue;
    selectedValues: KeyValue[] = [];

    editFormActive = false;

    cols: any[];
    displayedColumns = [];

    @ViewChild('filter', { static: false }) filter: ElementRef;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    constructor(private service: SettingService, private snack: MatSnackBar) {
        this.service.getDictionaries().subscribe(data => this.dictionaries = data);
    }

    addDictionary() {
        this.dictionaries.push({
            id: 0,
            name: 'Dict_' + DictionaryComponent.count++,
            values: []
        })
    }

    showDictionary(dic: Dictionary) {
        this.selectedDictionary = dic;

    }

    saveDictionary(dic) {
        this.service.saveDictionary(dic).subscribe(dict => {
            deleteItemFromArray(this.dictionaries, dic);
            this.dictionaries.push(dict);
            this.selectedDictionary = dict;
        });
    }

    deleteDictionary(dic: Dictionary) {
        deleteItemFromArray(this.dictionaries, dic);
        this.service.deleteDictionary(dic).subscribe(res => this.snack.open('Le dictionnaire a été supprimé', 'Ok', {duration: 3000}))
    }


    onSelectOption(event) {
        if (event.option.selected) this.selectedValues.push(event.option.value);
        else deleteItemFromArray(this.selectedValues, event.option.value);
    }


    deleteItems() {
        this.selectedValues.forEach(item => deleteItemFromArray(this.selectedDictionary.values, item));
        this.selectedValues = []
    }

    addNewItem() {
        this.editFormActive = true;
        this.selectedItem = {
            id: '',
            value: ''
        }
    }

    saveItem(item: KeyValue) {
        if (item.id.length !== 0) deleteItemFromArray(this.selectedDictionary.values, this.selectedItem);
        this.selectedDictionary.values.push(item);
        this.selectedItem = {id: '', value: ''};

        this.saveDictionary(this.selectedDictionary);

    }

    ngOnInit() {
        this.displayedColumns = KV_TABLE_CONF;
        this.cols = KV_HEADER_COLS;

        this.selectedDictionary = this.dictionaries[0] || null;
    }

}
