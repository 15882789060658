/**
 * Created by MBX on 7/20/2017.
 */
export const PAT_TABLE_CONF = [
    {
        'label': 'id',
        'header': 'ID',
        'value': 'id',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': true,
        'searchable': true,
        'required': false
    },{
        'label': 'externalPatientID',
        'header': 'PatientID',
        'value': 'externalPatientID',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': false
    },{
        'label': 'title_value',
        'header': 'Title',
        'value': 'title.value',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': false
    },{
        'label': 'demographic_gender_description',
        'header': 'Sexe',
        'value': 'demographic.gender.description',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': false
    },{
        'label': 'demographic_dateOfBirth',
        'header': 'DOB',
        'value': 'demographic.dateOfBirth',
        'type': 'date',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': false
    }, {
        'label': 'firstName',
        'header': 'FIRST_NAME',
        'value': 'firstName',
        'type': 'string',
        'unit': 'conf_data',
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': false
    }, {
        'label': 'lastName',
        'header': 'LAST_NAME',
        'value': 'lastName',
        'type': 'string',
        'unit': 'conf_data',
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': false
    }, {
        'label': 'cin',
        'header': 'CIN',
        'value': 'cin',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': false
    }, {
        'label': 'email',
        'header': 'EMAIL',
        'value': 'email',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': false
    }
];

export const PAT_HEADER_COLS = [
    'externalPatientID',
    'title_value',
    'firstName',
    'lastName',
    'demographic_gender_description',
    'demographic_dateOfBirth',
    'cin'
];
