<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-pencil-box-outline"></mat-icon>
    <h3 mat-dialog-title>{{'ROOM' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <form [formGroup]="form">

        <div>
            <mat-form-field appearance="standard" class="full-width">
                <mat-label>{{'NAME' | translate}}</mat-label>
                <input matInput placeholder="{{'NAME' | translate}}" formControlName="name">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="standard" class="padded" fxFlex>
                <mat-label>{{'EXAMTYPE' | translate}}</mat-label>
                <mat-select placeholder="{{'EXAMTYPE' | translate}}" formControlName="examType" class="full-width">
                    <mat-option value="INPATIENT" class="option">
                        {{'INPATIENT' | translate}}
                    </mat-option>
                    <mat-option value="OUTPATIENT" class="option">
                        {{'OUTPATIENT' | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


    </form>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="warn" [mat-dialog-close]="null">{{'CANCEL' | translate}}</button>
    <button mat-button color="primary" (click)="onSave(form.value)" [disabled]="form.invalid">{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>