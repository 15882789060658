import {BehaviorSubject, merge as observableMerge, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DataSource} from '@angular/cdk/collections';
import {SS_HEADER_COLS, SS_TABLE_CONF} from './table-conf';
import {DeleteConfirmComponent, deleteItemFromArray, SharedService} from '../../shared';
import {StaticResourceEditComponent} from '../static-resource-edit/static-resource-edit.component';
import {SettingService} from '../setting.service';

import * as _ from 'lodash';

@Component({
    selector: 'app-sps-status',
    templateUrl: './sps-status.component.html',
    styleUrls: ['./sps-status.component.scss']
})
export class SpsStatusComponent implements OnInit {

    cols: any[];
    displayedColumns = [];

    dataSource: SPSStatusDataSource | null;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private settingService: SettingService, private sharedService: SharedService, public dialog: MatDialog) {
        this.sharedService.getSPSStatuses().subscribe(data => {

                let items = _.map(data, tsk => {
                    return _.reduce(this.displayedColumns, (obj, field) => {
                        obj[field.label] = _.get(tsk, field.value, field.defaultValue);
                        return obj
                    }, {});
                });

                this.dataSource.dataChange.next(items);
            }
        );
    }

    editSPSStatus(spsStatus) {

        const dialogRef = this.dialog.open(StaticResourceEditComponent);
        const instance = dialogRef.componentInstance;
        instance.selectedItem = spsStatus;
        instance.itemType = 'spsStatus';

        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                if (spsStatus.id) deleteItemFromArray(this.dataSource.data, spsStatus);

                this.dataSource.data.push(res);
                this.dataSource.dataChange.next(this.dataSource.data);
            }

        });
    }

    deleteSPSStatus(orderStatus) {
        const dialogRef = this.dialog.open(DeleteConfirmComponent);
        dialogRef.afterClosed().subscribe(ok => {
            if (ok) {
                this.settingService.deleteSPSStatus(orderStatus.id).subscribe(res => {
                    deleteItemFromArray(this.dataSource.data, orderStatus);
                    this.dataSource.dataChange.next(this.dataSource.data);
                });
            }
        });
    }


    toggleColumn(col) {
        event.stopImmediatePropagation();
        const col2 = col;
        col2.hidden = !col.hidden;
        let index = this.displayedColumns.indexOf(col);
        this.displayedColumns.splice(index, 1, col2);

        this.cols = _.concat([], _.chain(this.displayedColumns)
            .filter(['hidden', false])
            .map('label')
            .value(), 'action');
    }


    ngOnInit() {

        this.displayedColumns = SS_TABLE_CONF;
        this.cols = SS_HEADER_COLS;

        this.dataSource = new SPSStatusDataSource(this.paginator, this.sort);
    }

}


export class SPSStatusDataSource extends DataSource<any> {

    filteredData: SPSStatusItem[] = [];
    renderedData: SPSStatusItem[] = [];

    /** Stream that emits whenever the data has been modified. */
    dataChange: BehaviorSubject<SPSStatusItem[]> = new BehaviorSubject<SPSStatusItem[]>([]);

    constructor(private _paginator: MatPaginator, private _sort: MatSort) {
        super();
    }

    get data(): SPSStatusItem[] {
        return this.dataChange.value;
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<SPSStatusItem[]> {
        const displayDataChanges = [
            this.dataChange,
            this._paginator.page,
            this._sort.sortChange,
        ];

        return observableMerge(...displayDataChanges).pipe(map(() => {


            // Filter data
            this.filteredData = this.data.slice().filter((item: SPSStatusItem) => {
                return true;
            });

            // Sort filtered data
            const sortedData = this.sortData(this.filteredData.slice());

            // Grab the page's slice of the filtered sorted data.
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
            return this.renderedData;
        }));
    }

    disconnect() {
    }

    /** Returns a sorted copy of the database data. */
    sortData(data: SPSStatusItem[]): SPSStatusItem[] {
        if (!this._sort.active || this._sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._sort.active) {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'value':
                    [propertyA, propertyB] = [a.value, b.value];
                    break;
                case 'description':
                    [propertyA, propertyB] = [a.description, b.description];
                    break;
            }

            let valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            let valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }


}


export interface SPSStatusItem {
    id: string;
    value: string;
    description: string;
    color: string;
}
