<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-calendar-text"></mat-icon>
    <h3 mat-dialog-title>{{'APPOINTMENTDETAIL' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content id="form">
    <ng-template #spinner>
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-spinner [strokeWidth]="3" color="accent"></mat-spinner>
        </div>
    </ng-template>
    <div fxLayout="column" *ngIf="currentEvent; else spinner">
        <!--Patient details-->
        <div class="subtitle">{{'PATIENT_TITLE' | translate}}</div>
        <div fxLayout="row" fxLayoutAlign="start stretch">

            <div fxFlex>
                <caption-value [caption]="'PATIENTID' | translate" [value]="currentEvent.patientID"></caption-value>
            </div>
            <div fxFlex>
                <caption-value [caption]="'ACCESSIONNUMBER' | translate" [value]="currentEvent.accessionNumber"></caption-value>
            </div>
            <div fxFlex>
                <caption-value [caption]="'FULLNAME' | translate" [value]="currentEvent.patientName"></caption-value>
            </div>
            <div fxFlex>
                <caption-value [caption]="'GENDER' | translate" [value]="currentEvent.gender"></caption-value>
            </div>
            <div fxFlex>
                <caption-value [caption]="'AGE' | translate" [value]="currentEvent.age" [unit]="'years'"></caption-value>
            </div>
            <div fxFlex>
                <caption-value [caption]="'PHONE' | translate" [value]="currentEvent.phoneNumber"></caption-value>
            </div>

        </div>

        <mat-divider class="divider-padding"></mat-divider>

        <div class="subtitle">{{'ORDERDETAILS' | translate}}</div>

        <div fxLayout="row" fxLayoutAlign="start stretch">

            <div fxFlex>
                <caption-value [caption]="'PROCEDURE_TYPE' | translate"
                               [value]="currentEvent.reasonForExam"></caption-value>
            </div>
            <div fxFlex>
                <caption-value [caption]="'MODALITY' | translate" [value]="currentEvent.modality"></caption-value>
            </div>
            <div fxFlex>
                <caption-value [caption]="'SCHEDULEDAET' | translate" [value]="currentEvent.scheduledAET"></caption-value>
            </div>
            <div fxFlex>
                <caption-value [caption]="'REQUESTING_PHYSICIAN' | translate"
                               [value]="currentEvent.requestingPhysician  || '-'"></caption-value>
            </div>
            <div fxFlex>
                <caption-value [caption]="'PERFORMING_PHYSICIAN' | translate"
                               [value]="currentEvent.performingPhysician  || '-'"></caption-value>
            </div>
            <div fxFlex>
                <caption-value [caption]="'TECHNICIAN' | translate"
                               [value]="currentEvent.technician  || '-'"></caption-value>
            </div>

        </div>

    </div>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row">
    <button mat-button color="primary" tabindex="-1" disabled (click)="addPayment()">{{'ADD_PAYMENT' | translate}}</button>
    <span fxFlex></span>
    <button mat-button color="accent" mat-dialog-close>{{'CLOSE' | translate}}</button>
</mat-dialog-actions>

