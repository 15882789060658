import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent {

    dateRangeForm: FormGroup;

    constructor(private fb: FormBuilder) {
        this.dateRangeForm = this.fb.group({
            startDate: new Date(),
            endDate: new Date()
        });

        this.dateRangeForm.get('startDate').valueChanges.subscribe(value => {
            let endField = this.dateRangeForm.get('endDate');
            if (moment(value).isAfter(moment(endField.value))) {
                endField.patchValue(value);
            }
        });

        this.dateRangeForm.get('endDate').valueChanges.subscribe(value => {
            let startField = this.dateRangeForm.get('startDate');
            if (moment(value).isBefore(moment(startField.value))) {
                startField.patchValue(value);
            }
        });
    }
}
