<div id="ft-recorder">
    <mat-toolbar class="dialog-toolbar">
        <mat-icon fontSet="mdi" fontIcon="mdi-voice"></mat-icon>&nbsp;
        <span fxFlex></span>
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
        </button>
    </mat-toolbar>

    <mat-dialog-content>

        <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-around start" [style.marginBottom.px]="12">
                <button id="start" [disabled]="isPlay" style="padding: 0;" mat-button color="primary" tabindex="-1"
                        (click)="startRecording()">
                    <mat-icon fontIcon="mdi-record-rec" fontSet="mdi"></mat-icon>
                    {{'RECORD' | translate}}</button>
                <button id="stop" [disabled]="isStop" style="padding: 0;" mat-button color="accent" tabindex="-1"
                        (click)="pauseRecording()">
                    <mat-icon fontIcon="mdi-pause" fontSet="mdi"></mat-icon>
                    {{'PAUSE' | translate}}</button>
            </div>

            <div class="timer"
                 [style.color]="isPlay ? 'green': isStop ? 'dimgrey':'red'">{{getTimerString(timer)}}</div>
            <div *ngFor="let audio of audioFiles">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <app-player [audio]="audio"></app-player>
                    <button mat-icon-button (click)="deleteRecord(audio)">
                        <mat-icon [style.color]="'red'" fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
                    </button>
                </div>
            </div>
        </div>

    </mat-dialog-content>
    <mat-progress-bar *ngIf="saving" mode="indeterminate" color="accent"></mat-progress-bar>
    <mat-dialog-actions align="end">
        <button mat-button color="accent" [mat-dialog-close]="null">{{'CANCEL' | translate}}</button>
        <button *ngIf="audioFiles.length" mat-button color="primary"
                (click)="saving = true;saveFile()" [disabled]="saving">{{'SAVE' | translate}}</button>
    </mat-dialog-actions>

</div>
