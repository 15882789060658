import {
    animate,
    AnimationTriggerMetadata,
    keyframes,
    query,
    sequence,
    style,
    transition,
    trigger
} from '@angular/animations';

export const TRANSITION = '300ms cubic-bezier(0.4, 0, 0.2, 1)';

const CLOSED_STATE = {height: 0, opacity: 0};
const OPENED_STATE = {height: '*', opacity: 1};


const CLOSE_TO_OPEN = keyframes([
    style([CLOSED_STATE, {offset: 0, 'overflow-y': 'hidden'}]),
    style({opacity: 0, height: '*', offset: .75, 'overflow-y': 'hidden'}),
    style([OPENED_STATE, {offset: 1, 'overflow-y': 'hidden'}]),
]);

const OPEN_TO_CLOSE = keyframes([
    style([OPENED_STATE, {offset: 0, 'overflow-y': 'hidden'}]),
    style({opacity: 1, height: '*', offset: .25, 'overflow-y': 'hidden'}),
    style([CLOSED_STATE, {offset: 1, 'overflow-y': 'hidden'}]),
]);


export const listAnimation: AnimationTriggerMetadata =  trigger('state', [
    transition(':enter', [style(CLOSED_STATE), animate(TRANSITION, CLOSE_TO_OPEN)]),
    transition(':leave', [style(OPENED_STATE), animate(TRANSITION, OPEN_TO_CLOSE)]),
]);


export const tableAnimation: AnimationTriggerMetadata = trigger('state', [
        transition('* => *', [

            query(':enter', style({height: 0, opacity: 1}), {optional: true}),
            query(':leave', style({height: '*', opacity: 0.5}), {optional: true}),

            query(':enter',
                sequence([
                    animate(TRANSITION, keyframes([
                        style({minHeight: '0px', overflow: 'hidden', height: '0px'}),
                        style({minHeight: '*', overflow: 'inherit', height: '*'})
                    ]))]),
                {optional: true}
            ),

            query(':leave',
                sequence([
                    animate(TRANSITION, keyframes([
                        style({minHeight: '*', overflow: 'inherit', height: '*'}),
                        style({minHeight: '0px', overflow: 'hidden', height: '0px'})
                    ]))]),
                {optional: true}
            )
        ])
    ]);


export const UPLOAD_ENTER_STEPS = [
    style({transform: 'translate3d(0, 100%, 0)', opacity: '.5'}),
    animate(`400ms 450ms cubic-bezier(0.35, 0, 0.25, 1)`, keyframes([
        style({transform: 'translate3d(0, 100%, 0)', opacity: '.5', offset: 0}),
        style({transform: 'translate3d(0, 25%, 0)', opacity: '1', offset: .75}),
        style({transform: 'translate3d(0, 0, 0)', opacity: '1', offset: 1}),
    ]))
];

export const UPLOAD_LEAVE_STEPS = [
    style({transform: 'translate3d(0, 0, 0)', opacity: '1'}),
    animate(`400ms cubic-bezier(0.35, 0, 0.25, 1)`, keyframes([
        style({transform: 'translate3d(0, 0, 0)', opacity: '1', offset: 0}),
        style({transform: 'translate3d(0, -25%, 0)', opacity: '.5', offset: .75}),
        style({transform: 'translate3d(0, -100%, 0)', opacity: '0', offset: 1}),
    ]))
];

export const UPLOAD_ITEM_ANIMATION: AnimationTriggerMetadata = trigger(
    'uploadItemTransition',
    [
        transition(':enter', UPLOAD_ENTER_STEPS),
        transition(':leave', UPLOAD_LEAVE_STEPS)
    ]
);
