import {Component, Input, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {StateComponent, ViewQuery} from '../model';
import {StatisticService} from '../statistic.service';
import {TranslateService} from "@ngx-translate/core";
import * as WebDataRocks from "webdatarocks";
import {WorkflowService} from "../../workflow/workflow.service";
import {pivotConfig, pivotHeader} from "./pivot-utils";
import {AppConfigService} from "../../app-config.service";
import * as Flexmonster from 'flexmonster';

@Component({
    selector: 'ft-pivot-table',
    templateUrl: './ft-pivot-table.component.html',
    styleUrls: ['./ft-pivot-table.component.scss']
})
export class FtPivotTableComponent implements StateComponent {

    @Input() data: ViewQuery;
    pvTable: 'wdr' | 'fm' = 'wdr';

    @ViewChild('pivot') pivot: any;

    dataset: any = [];
    headers = pivotHeader(this);
    config: WebDataRocks.Report | Flexmonster.Report = pivotConfig(this);
    private toolbar: any;

    public flexMonsterLicenseKey: string;

    constructor(private service: StatisticService,
                private translate: TranslateService,
                private _config: AppConfigService,
                private workflowService: WorkflowService) {
        this.flexMonsterLicenseKey = this._config.flexMonsterLicense;
        if (this.flexMonsterLicenseKey && this.flexMonsterLicenseKey !== '') this.pvTable = 'fm';
    }

    loadData() {
        let start = moment(this.data.startDate).format('YYYYMMDD');
        let end = moment(this.data.endDate).format('YYYYMMDD');

        this.workflowService.getWorkflowItems(`${start}-${end}`).subscribe(data => {
            if (data && data.length != 0) this.pivot[this.pvTable === 'fm' ? 'flexmonster' : 'webDataRocks'].updateData({data});
        });
    }

    onPivotReady(pivot: WebDataRocks.Pivot | Flexmonster.Pivot): void {
        setTimeout(() => this.loadData());
    }

    onCustomizeCell(cell: WebDataRocks.CellBuilder | Flexmonster.CellBuilder, data: WebDataRocks.CellData | Flexmonster.CellData): void {

        const column = data.measure || null;

        if (data.isGrandTotalRow && data.type !== 'header') cell.addClass("ft-grand-total-r");
        if (!data.isGrandTotalRow && data.isTotal && data.type !== 'header' && column && column['uniqueName'] === 'paidAmount')
            cell.addClass('ft-total-classic-r');

        if (!data.isGrandTotalRow && data.isTotal && data.type !== 'header' && column && column['uniqueName'] === 'totalAmount')
            cell.addClass('ft-total-classic-rt');

        if (!data.isGrandTotalRow && data.isTotal && data.type !== 'header' && column && column['uniqueName'] === 'leftAmount')
            cell.addClass('ft-total-classic-rl');

    }

    findChart = (c1: any, c2: any) => c1 && c2 ? c1.name == c2.name : c1 == c2;

    beforeToolbarCreated(toolbar: any): void {
        this.toolbar = toolbar;
    }

    saveReport() {
        this.pivot[this.pvTable === 'fm' ? 'flexmonster' : 'webDataRocks'].save('FireRIS_stats_report.json', 'file');
    }

    exportTo(type) {
        let ext = type === 'excel' ? 'xlsx' : type;
        this.pivot[this.pvTable === 'fm' ? 'flexmonster' : 'webDataRocks'].exportTo(type, {
            destinationType: 'file',
            filename: 'FireRIS_stats_report.' + ext,
            excelSheetName: 'FireRIS_stats_report',
            pageOrientation: 'portrait',
            header: '<div style="color: #990633; text-align: center">FireRIS - Solution de gestion des centres de radiologie</div>',
            footer: '<div>www.fireris.ma</div>'
        });
    }

    openFields() {
        this.pivot[this.pvTable === 'fm' ? 'flexmonster' : 'webDataRocks'].openFieldsList();
    }

    openOptions() {
        this.toolbar.optionsHandler();
    }

    onReportComplete(): void {
        this.pivot[this.pvTable === 'fm' ? 'flexmonster' : 'webDataRocks'].off("reportcomplete");
        //this.pivot[this.pvTable === 'fm' ? 'flexmonster': 'webDataRocks'].setReport(this.config);
    }

    openFormatting() {
        this.toolbar.conditionalFormattingHandler();
        this.pivot[this.pvTable === 'fm' ? 'flexmonster' : 'webDataRocks'].refresh();
    }

    printReport() {
        this.toolbar.printHandler();
    }

    createFormattingItem() {
        this.toolbar.formatCellsHandler();
    }

    goFullScreen() {
        this.toolbar.fullscreenHandler()
    }
}

