<div class="login-container" fxLayout="column" fxLayoutAlign="center center" id="login">
    <ft-loader [while]="submitted"></ft-loader>

    <div *ngIf="!submitted" class="login-card" fxLayout="column" fxLayoutAlign="center center">
        <div class="logo-container">
            <img alt="FireRIS"
                 src="../../assets/images/{{logo}}">
        </div>

        <form [formGroup]="form" class="log-form" fxLayout="column" fxLayoutAlign="center center">
            <!--username field-->
            <mat-form-field [style.width.px]="360" appearance="outline" fxFlex>
                <mat-label>{{'USERNAME' | translate}}</mat-label>
                <input [placeholder]="'USERNAME' | translate" formControlName="username" matInput required>
            </mat-form-field>

            <!--password field-->
            <mat-form-field [style.width.px]="360" appearance="outline" fxFlex>
                <mat-label>{{'PASSWORD' | translate}}</mat-label>
                <input [placeholder]="'PASSWORD' | translate" [type]="hide ? 'password' : 'text'" formControlName="password" matInput
                       required>
                <mat-icon (click)="hide = !hide" [fontIcon]="hide ? 'mdi-eye-off' : 'mdi-eye'" fontSet="mdi"
                          matSuffix></mat-icon>
            </mat-form-field>

            <mat-form-field [style.width.px]="360" appearance="outline" fxFlex>
                <mat-label>{{'IMAGING_CENTER' | translate}}</mat-label>
                <mat-select [(ngModel)]="selectedCenter" [ngModelOptions]="{standalone: true}"
                            [placeholder]="'IMAGING_CENTER' | translate">
                    <mat-option *ngFor="let center of centers" [value]="center"> {{center.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <p *ngIf="errorMsg" class="error">{{errorMsg}}</p>

            <button (click)="onSubmit()" [disabled]="form.invalid" color="accent" mat-raised-button
                    style="margin-top: 24px" type="submit">
                {{'LOGIN' | translate}}
            </button>
        </form>
    </div>


    <div class="circle1"></div>
    <div class="circle2"></div>
    <div class="circle3"></div>
    <div class="circle4"></div>

</div>

