export class ContractField {
constructor(
    public name?: string,
    public id?: string,
    public parentFieldId?: number,
    public calculationBase: string = 'EXAM_PRICE',
    public partPrice?: number,
    public partPercentage?: number,
    public partType: string = 'PERCENTAGE',
    public minPrice?: number,
    public maxPrice?: number) {}
}
