import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileElement} from '../model/file-element';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import {BehaviorSubject, forkJoin} from 'rxjs';
import {
    AudioPlayComponent,
    AudioRecordComponent,
    CameraComponent,
    ImageViewerComponent,
    SpeechNoteComponent
} from '../modals';
import * as moment from 'moment';
import {FirePdfComponent} from '../../fire-pdf/fire-pdf.component';
import {FileService} from '../service/file.service';

@Component({
    selector: 'app-file-explorer',
    templateUrl: './file-explorer.component.html',
    styleUrls: ['./file-explorer.component.scss']
})
export class FileExplorerComponent implements OnInit {
    now = new Date();

    public gridView = new BehaviorSubject(true);

    progress;
    canBeClosed = true;
    showCancelButton = true;
    uploading = false;
    uploadSuccessful = false;

    @Input() fileElements: FileElement[];
    @Input() canNavigateUp: boolean;
    @Input() path: string;
    @Input() patientID: string;
    @Input() modalMode: boolean;

    @ViewChild('file', { static: true }) file;
    public files: Set<File> = new Set();

    sourceUUID: string;
    targetUUID: string;

    @Output() folderAdded = new EventEmitter<{ name: string }>();
    @Output() elementRemoved = new EventEmitter<FileElement>();
    @Output() elementRenamed = new EventEmitter<FileElement>();
    @Output() elementMoved = new EventEmitter<{ element: FileElement; moveTo: FileElement }>();
    @Output() navigatedDown = new EventEmitter<FileElement>();
    @Output() navigatedUp = new EventEmitter();
    @Output() onUpload = new EventEmitter<boolean>();
    private currentFolder: FileElement;

    constructor(private dialog: MatDialog, private fileService: FileService) {}

    searchFile(fileName) {

    }

    logDrag(event) {

        if (event.target.parentNode.id) {
            if (!this.sourceUUID) this.sourceUUID = event.target.parentNode.id;
            this.targetUUID = event.target.parentNode.id;
        }
    }

    logDragEnd(event) {
        if (this.targetUUID === this.sourceUUID || !this.targetUUID) {
            this.resetDnD();
            return;
        }

        let source = this.fileElements.find(file => file.uuid === this.sourceUUID);
        let target = this.fileElements.find(file => file.uuid === this.targetUUID);

        if (!target.folder) return;

        this.moveElement(source, target);

        this.resetDnD();
    }

    resetDnD() {
        this.sourceUUID = null;
        this.targetUUID = null;
    }

    getFolders = (fileElements: FileElement[], current: FileElement): FileElement[] =>
        fileElements.filter(file => file.folder && file !== current);

    ngOnInit() {
    }

    openElement(element: FileElement) {
        if (['PNG', 'JPG', 'JPEG'].includes(element.fileType)) {
            this.dialog.open(ImageViewerComponent, {
                data: {
                    images: this.fileElements.filter(elm => ['PNG', 'JPG', 'JPEG'].includes(elm.fileType)),
                    selected: element
                },
                minWidth: '100%',
                height: '100%',
                panelClass: 'image-viewer'
            });
        }

        if (['MP3', 'WAV', 'AAC'].includes(element.fileType)) {
            this.dialog.open(AudioPlayComponent, {
                data: {
                    audioFiles: this.fileElements.filter(elm => ['MP3', 'WAV', 'AAC'].includes(elm.fileType)),
                    selected: element
                },
                minWidth: '80%',
                height: '80%',
                panelClass: 'audio-play'
            }).afterClosed().subscribe(console.log);
        }

        if (element.fileType === 'TXT') this.dialog.open(SpeechNoteComponent, {
            data: element,
            panelClass: 'speech-panel',
            disableClose: true
        }).afterClosed().subscribe(res => {

        });

        if (element.fileType === 'PDF') this.dialog.open(FirePdfComponent, {data: element, panelClass: 'pdf-viewer'});
    }

    deleteElement(element: FileElement) {
        this.elementRemoved.emit(element);
    }

    renameFile(ev, element) {
        element.name = ev.target.textContent;
        this.elementRenamed.emit(element);
    }

    renameOnEnter(ev, element) {
        if (ev.code === 'Enter') this.renameFile(ev, element);
    }

    navigate(element: FileElement) {
        if (element.folder) {
            this.currentFolder = element;
            this.navigatedDown.emit(element);
        }
    }

    navigateUp() {
        this.currentFolder = this.fileElements.find(value => value.uuid === this.currentFolder.parent);
        console.log(this.currentFolder);
        this.navigatedUp.emit();
    }

    moveElement(element: FileElement, moveTo: FileElement) {
        this.elementMoved.emit({element: element, moveTo: moveTo});
    }

    createNewFileElement() {
        this.folderAdded.emit({name: moment().format('YYYYMMDDHHmmss')});
    }

    uploadFile() {this.file.nativeElement.click();}

    takeSpeechNote() {
        this.dialog.open(SpeechNoteComponent, {panelClass: 'speech-panel', disableClose: true})
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    let notes = new Set<any>();
                    let blob = new Blob([result.note], {type: 'text/plan;charset=utf-8'});
                    notes.add(new File([blob], (result.noteName || 'Note_'+moment().format('YYYYMMDDHHmmss')) + '.txt', {type: 'text/plain;charset=utf-8'}));
                    this.startUpload(notes);
                }
            })
    }

    onFilesAdded() {
        const files: { [key: string]: File } = this.file.nativeElement.files;
        for (let key in files) {
            if (!isNaN(parseInt(key, 0))) {
                this.files.add(files[key]);
            }
        }

        setTimeout(() => this.startUpload(this.files), 100);
    }

    downloadFile(file) {
        let filename = file.uuid + '.' + file.fileType.toLowerCase();
        window.open(`/api/documents/download/${filename}`, '_blank');
    }

    recordAudioFile() {
        this.dialog.open(AudioRecordComponent).afterClosed().subscribe(blob => {
            if (blob) {
                let audios: Set<any> = new Set<any>();
                audios.add(new File([blob], (moment().format('YYYYMMDDHHmmss')) + '.wav', {type: 'audio/wav'}));
                this.startUpload(audios);
            }
        })
    }

    captureImage() {
        this.dialog.open(CameraComponent).afterClosed().subscribe(files => {
            if (files) this.startUpload(files);
        })
    }

    switchView() {
        this.gridView.next(!this.gridView.getValue());
    }

    openMenu(event: MouseEvent, element: FileElement, viewChild: MatMenuTrigger) {
        event.preventDefault();
        viewChild.openMenu();
    }

    getHRFileSizeString(fileSizeInBytes) {
        let i = -1;
        let byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };

    startUpload(files: Set<File>) {
        // set the component state to "uploading"
        this.uploading = true;

        // start the upload and save the progress map
        this.progress = this.fileService.uploadFiles(files, this.patientID, this.currentFolder ? this.currentFolder.uuid : 'root');

        // convert the progress map into an array
        let allProgressObservables = [];
        for (let key in this.progress) {
            allProgressObservables.push(this.progress[key].progress);
        }

        // Adjust the state variables

        // The dialog should not be closed while uploading
        this.canBeClosed = false;

        this.showCancelButton = true;

        // When all progress-observables are completed...
        forkJoin(allProgressObservables).subscribe(end => {
            // ... the dialog can be closed again...
            this.canBeClosed = true;

            // ... the upload was successful...
            this.uploadSuccessful = true;

            // ... and the component is no longer uploading
            this.uploading = false;

            this.showCancelButton = false;

            this.onUpload.emit(this.uploadSuccessful);
            this.files.clear();
        });
    }
}
