<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
    <h3 mat-dialog-title>{{'SEARCH' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content style="padding: 0 !important; overflow: hidden;min-height: 426px !important;">


    <div class="mat-table" fxLayout="column">
        <div class="search-area" fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center"
             style="padding-right: 8px;">
            <mat-form-field floatLabel="never" fxFlex="40%">
            <span matPrefix><mat-icon fontIcon="mdi-magnify" fontSet="mdi" class="prefix-icon" style="top: -3px;"
                                      tabindex="-1"></mat-icon>&nbsp;
            </span>
                <input matInput #filter [placeholder]="'SEARCH' | translate" [autofocus]="true">
            </mat-form-field>

            <mat-spinner *ngIf="isLoadingResults || isRateLimitReached" mode="indeterminate" [strokeWidth]="2"
                         [diameter]="20"></mat-spinner>

        </div>

        <div fxFlex="334px" fxLayout="column" style="overflow: auto;">
            <mat-table style="overflow: auto" [dataSource]="dataSource" class="fire-table" [@state]="dataSource.data.length"
                       [trackBy]="trackById"
                       matSort
                       matSortActive="code"
                       matSortDirection="asc"
                       matSortDisableClear>

                <!--Table-->
                <ng-container *ngFor="let col of displayedColumns" cdkColumnDef="{{col.label}}" [class.hidden]="col.hidden">
                    <mat-header-cell *cdkHeaderCellDef class="fire-header-cell" mat-sort-header="{{col.value}}" [disabled]="!col.sortable"> {{col.header | translate}}</mat-header-cell>

                    <ng-container>
                        <mat-cell *cdkCellDef="let row" class="fire-cell"> {{row[col.label]}}</mat-cell>
                    </ng-container>

                </ng-container>

                <!--header-->
                <mat-header-row *cdkHeaderRowDef="cols; sticky:true" class="fire-header-row"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: cols;" class="fire-row" (click)="selectCode(row)">
                </mat-row>
            </mat-table>
        </div>

        <mat-paginator [length]="resultsLength"
                       [pageIndex]="0"
                       [pageSize]="10"
                       [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons>
        </mat-paginator>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button [mat-dialog-close]="null" color="accent" mat-raised-button>{{'CANCEL' | translate}}</button>
</mat-dialog-actions>
