<div class="card-view-parent" fxLayout="column">

    <mat-toolbar class="sc-toolbar2" color="primary" fxLayout="row" fxLayoutAlign="space-between center"
                 fxLayoutGap="12px" tabindex="-1">
        <div class="title">
            <mat-icon fontIcon="mdi-contacts" fontSet="mdi"></mat-icon>
            <span>{{title}}</span>
        </div>

        <span *ngIf="patientService.patientSelected">{{patientService.patientSelected | async}}</span>

        <div fxLayout="row" fxLayoutAlign="center start">
            <button (click)="createNewPatient()"
                    *ngIf="profile && (profile.createPatient !== 'NONE' || profile.patientModule) && !(patientService.patientSelected | async)"
                    [matTooltip]="'ADD' | translate"
                    class="btn-top"
                    mat-button>
                <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                {{'ADD' | translate}}
            </button>
        </div>
    </mat-toolbar>

    <div fxFlex class="patient-container">
        <router-outlet></router-outlet>
    </div>
</div>
