import {forkJoin, Observable, of as observableOf, Subscription} from 'rxjs';

import {catchError, debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {assign, filter as _filter, find as _find, get, set as _set, xor} from 'lodash';
import {AvailabilityCheckComponent} from '../availability-check/availability-check.component';
import {
    AVSC,
    DEBITERS,
    Demographic,
    GeneralSetting,
    Insurance,
    PaymentItem,
    ProcedureCode,
    ThermalPrintModel,
    WorkflowItem,
    WsMessage
} from '../../model';
import {SettingService} from '../../setting/setting.service';
import {PatientSearchComponent} from '../../patient/patient-search/patient-search.component';
import * as moment from 'moment';
import {Moment} from 'moment';
import {buildISR, buildOrder} from '../../utils';
import {SchedulerService} from '../scheduler.service';
import {
    FileElement,
    FileService,
    PatientScheduledExamsComponent,
    ReferringPhysicianAddComponent,
    SharedService
} from '../../shared';
import {ProcedureCodeSearchComponent} from '../procedure-code-search/procedure-code-search.component';
import * as $ from 'jquery';
import {AppConfigService} from "../../app-config.service";
import {PatientService} from "../../patient/patient.service";

@Component({
    selector: 'app-exam-add',
    templateUrl: './exam-add.component.html',
    styleUrls: ['./exam-add.component.scss']
})
export class ExamAddComponent implements OnInit, OnDestroy {
    order = false;
    singleReport = false;
    anExists = false;
    patientIdExists: boolean;
    patientSelected = false;
    searchKeys = {fn: '', ln: ''};
    age: number;

    patientForm: FormGroup;
    examForm: FormGroup;
    orderForm: FormGroup;

    exams: FormArray;

    titles: any[];
    genders: any[];
    priorities: any[];
    appointmentReasons: any[];
    reasonForExams: any[];
    patientClasses: any[];
    aets: any[];
    technicians: any[];
    confidentialities: any[];
    debiters: string[];

    generalSetting: GeneralSetting;
    numOfDuplicatePatients: number;

    resId: number;
    aet: any;
    scheduledPerfPhysician: any;

    selectedRange: any;
    hasFutureExams: Observable<boolean>;

    fileElements: Observable<FileElement[]>;
    currentRoot: FileElement;
    currentPath: string;
    canNavigateUp = false;

    patientID: string;
    filteredAets: Array<any[]> = [];
    performingPhysicians: any[];
    subs: Subscription[] = [];
    private accessionNumber: any;
    ageForm: FormGroup;
    private workflowItemToUpdate: WorkflowItem;
    private paymentItemToUpdate: PaymentItem;

    selected: number;

    convType: string;
    procedureCodeControl = [new FormControl()];
    referringPhysicianControl = [new FormControl()];
    filteredProcedures: Array<ProcedureCode[]> = [];
    filteredReferringPhysicians: Array<any[]> = [];
    private counts: WsMessage[];
    minDate = new Date();

    organismPecs: any[] = [];
    organismConventions: any[] = [];

    organisms: any[] = [];
    organismSelection: boolean;
    private exceptions: { procedureCode: string, patientPart: number, organismPart: number }[] = [];

    private selectedConvention: { conventionName: string, patientPart: number, organismPart: number };
    private studyInstanceUID: string;
    private attachedAccessionNumbers: string;
    private isr: any;
    isLoadingPhysicians: boolean = true;
    private tariffLines = [];
    tariffs: any[] = [];
    tariffId: number;
    private selectedOrganism: any;
    private selectedPecID: string;
    private selectedPecRef: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<ExamAddComponent>,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private snack: MatSnackBar,
                private patientService: PatientService,
                private setting: SettingService,
                private _config: AppConfigService,
                private fileService: FileService,
                private service: SchedulerService,
                private shared: SharedService) {
        this.resId = data.resource ? data.resource.id : 0;
        this.debiters = DEBITERS;

        this.createForm();
        this.checkAnExists();
        this.checkExistingPatientsCount();
        this.checkPatientIdExists();
        this.checkForPatientFutureAppointment();
        this.setAnonymousData();
        this.createAgeForm();

        this.countExams();
    }

    dateClass = (d: Moment) => {
        let item = _find(this.counts, {text: d.format('YYYYMMDD')});
        let count = item ? item.data : 0;
        return (count > 0) ? `full-day-${count}` : undefined;
    };

    printAndSave(patientData, data) {
        let procedureCodes = data.exams.map(e => e.procedureCode.description).join(', ');
        let accessionNumbers = data.exams.map(e => e.accessionNumber).join(' ');
        let physician = this.referringPhysicianControl[0].value;
        let patientID = patientData.externalPatientID;
        let patientName = patientData.lastName + ' ' + patientData.firstName;
        let date = moment(data.exams[0].date).format('DD/MM/YYYY');

        let printable = new ThermalPrintModel(patientID, patientName, date, physician, accessionNumbers, procedureCodes);

        if (this.generalSetting.ticketPrintMode === 'CHROME') this.service.printTicket(printable).subscribe();
        else {
            this.service.printCupsTicket(printable).subscribe(ok => {
                if (ok['status'] !== 'ok') console.log('Cannot print the ticket');
                else this.snack.open('Printing in progress', '', {duration: 3000});
            });
        }
    }

    showPreview(url) {
        let $frame = $('<iframe />').attr('src', url).css({position: 'absolute', top: '-9999px'});
        $frame.on('load', () => setTimeout(() => $frame.remove(), 0));
        $(document.body).append($frame);
    }

    save(patientData, data) {
        if (this.order) {
            let order = buildOrder(patientData, data, this.reasonForExams);
            this.dialogRef.close({action: 'createNewISR', data: order})
        } else if (this.data.ids) {

            let isrs = buildISR(patientData, data, this.reasonForExams, this.aets, this.data.spsStatus, this.data.ids, this.data.isr);

            let accessionNumbers = isrs.map(isr => isr.accessionNumber);

            isrs.forEach(isr => {
                isr.requestedProcedure.scheduledProcedureSteps[0].attachedAccessionNumbers = xor(accessionNumbers, [isr.accessionNumber]).join(',');
                isr.requestedProcedure.scheduledProcedureSteps[0].singleReport = this.singleReport;
                isr.requestedProcedure.scheduledProcedureSteps[0].workflowItem = this.workflowItemToUpdate;
                isr.requestedProcedure.scheduledProcedureSteps[0].paymentItem = this.paymentItemToUpdate;
                isr.requestedProcedure.scheduledProcedureSteps[0].studyInstanceUID = this.studyInstanceUID;
                isr.requestedProcedure.scheduledProcedureSteps[0].attachedAccessionNumbers = this.attachedAccessionNumbers;
                isr.requestedProcedure.scheduledProcedureSteps[0].tariffId = this.tariffId;


                isr.requestedProcedure.scheduledProcedureSteps[0].totalAmount = this.paymentItemToUpdate.price;
                isr.requestedProcedure.scheduledProcedureSteps[0].patientPart = this.paymentItemToUpdate.patientPart;
                isr.requestedProcedure.scheduledProcedureSteps[0].organismPart = this.paymentItemToUpdate.organismPart;
            });

            this.dialogRef.close({action: 'updateISR', data: isrs});

        } else {
            let isrs = buildISR(patientData, data, this.reasonForExams, this.aets, this.data.spsStatus, null, null, this.performingPhysicians);

            let accessionNumbers = isrs.map(isr => isr.accessionNumber);

            isrs.forEach(isr => {
                isr.requestedProcedure.scheduledProcedureSteps[0].attachedAccessionNumbers = xor(accessionNumbers, [isr.accessionNumber]).join(',');
                isr.requestedProcedure.scheduledProcedureSteps[0].singleReport = this.singleReport;
            });

            this.dialogRef.close({action: 'quickScheduleISR', data: isrs});
        }
    }

    checkAvailability(fg) {

        let value = fg.getRawValue();

        const dialogRef = this.dialog.open(AvailabilityCheckComponent, {data: true});
        dialogRef.componentInstance.avsc = new AVSC(get(value, 'technician.id'), get(value, 'aet.id'), get(value, 'date'), get(value, 'time'), get(value, 'duration', 15));

        this.subs.push(dialogRef
            .afterClosed()
            .subscribe(val => {
                if (val) fg.patchValue({
                    date: new Date(moment(val.date).format()),
                    time: val.start
                });
            }));
    }

    showPatientFutureExams() {
        this.subs.push(this.dialog
            .open(PatientScheduledExamsComponent, {data: this.patientForm.get('externalPatientID').value})
            .afterClosed()
            .subscribe(console.log))
    }

    findPatient() {
        assign(this.searchKeys, {
            fn: this.patientForm.get('firstName').value,
            ln: this.patientForm.get('lastName').value
        });

        let dialogRef = this.dialog.open(PatientSearchComponent, {
            width: '600px',
            data: this.searchKeys
        });

        dialogRef.componentInstance.selectedPatient = {};

        this.subs.push(dialogRef
            .afterClosed()
            .subscribe(selectedPatient => {
                if (selectedPatient) {
                    selectedPatient.title = _find(this.titles, {value: selectedPatient.title_value});
                    this.selectPatient(selectedPatient);
                }
            }));
    }

    addReferringPhysician(fg, idx) {
        this.subs.push(this.dialog.open(ReferringPhysicianAddComponent)
            .afterClosed()
            .subscribe(physician => {
                if (physician) {
                    this.filteredReferringPhysicians[idx].push(physician);
                    fg.patchValue({referringPhysician: physician});
                    this.referringPhysicianControl[idx].patchValue(physician.fullName);
                }
            }))
    }

    selectPatient(patient: any) {
        if (patient) {

            this.patientSelected = true;

            this.patientID = patient.externalPatientID;

            this.fileElements = this.fileService.getPatientDocuments(this.patientID, 'root');

            let dob = patient.demographic.dateOfBirth;

            let gender = patient.demographic.gender || {id: ''};
            let patientClass = patient.patientClass || this.patientClasses[2];
            let title = patient.title || {id: ''};
            let debiter = patient.debiter || 'PATIENT';
            let confidentiality = patient.demographic.confidentiality || this.confidentialities[0];

            _set(patient, 'title', title);
            _set(patient, 'debiter', debiter);
            _set(patient, 'patientClass', patientClass);
            _set(patient, 'demographic.gender', gender);
            _set(patient, 'demographic.dateOfBirth', dob ? dob : new Date());
            _set(patient, 'demographic.confidentiality', confidentiality);

            this.patientForm.patchValue(patient);

            if (!this.order && !patient.externalPatientID) this.generatePatientID();
        }
    }

    billingRequired: boolean;

    changeAge() {
        let value = this.patientForm.get('demographic.dateOfBirth').value;
        this.service.calculateAge(moment(value).format('YYYYMMDD')).subscribe((res: WsMessage) => {
            if (res.data) {
                let age = res.data.split('-');

                this.ageForm.get('years').patchValue(age[0]);
                this.ageForm.get('months').patchValue(age[1]);
                this.ageForm.get('days').patchValue(age[2]);
            }
        });
    }

    private createAgeForm() {
        this.ageForm = this.fb.group({years: 0, months: 0, days: 0});
    }

    changeDate() {
        let age = this.ageForm.getRawValue();
        if (age.years !== null && age.months !== null && age.days !== null) {
            this.service.calculateDOB(`${age.years || '0'}-${age.months || '0'}-${age.days || '0'}`).subscribe((res: WsMessage) => {
                let dob = moment(res.data, this._config.momentDateFormat);
                setTimeout(() => this.patientForm.get('demographic').patchValue({dateOfBirth: dob}), 200);
            });
        }
    }

    createExamForm(): FormGroup {
        return this.fb.group({
            orderId: '',
            reasonForExam: this.fb.group({id: ''}),
            procedureCode: [null, Validators.required],
            aet: this.fb.group({id: ['', Validators.required]}),
            technician: this.fb.group({id: ''}),
            referringPhysician: this.fb.group({id: '', firstName: '', lastName: '', phone: '', fullName: ''}),
            performingPhysician: this.fb.group({id: ''}),
            priority: this.fb.group({id: 262}),
            date: [moment(), Validators.required],
            time: [moment().format('HH:mm'), Validators.required],
            duration: ['10', Validators.required],
            accessionNumber: '',
            patientPart: 100.0,
            organismPart: 0.0,
            paymentType: 'PATIENT',
            pecId: null,
            pecRef: '',
            notes: '',
        });
    }

    createForm() {

        this.patientForm = this.fb.group({
            id: '',
            externalPatientID: '',
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.email],
            phone: '',
            cin: '',
            debiter: 'PATIENT',
            insurance: this.fb.group(new Insurance()),
            title: this.fb.group({id: ''}),
            patientClass: this.fb.group({id: ''}),
            demographic: this.fb.group(assign(new Demographic(), {
                gender: this.fb.group({id: ''}),
                confidentiality: this.fb.group({id: ''}),
            }))
        });

        this.examForm = this.fb.group({
            exams: this.fb.array([this.createExamForm()])
        });

        this.orderForm = this.fb.group({
            appointmentReason: null,
            date: [new Date(), Validators.required],
            time: [moment().format('HH:mm'), Validators.required],
            duration: ['10', Validators.required],
        });
    }

    addItem(): void {
        this.exams = this.examForm.get('exams') as FormArray;
        this.procedureCodeControl.push(new FormControl());
        this.referringPhysicianControl.push(new FormControl());

        let exams = this.exams['controls'] as any[];
        let l = exams.length;

        let date = exams[l - 1].get('date').value;
        let time = exams[l - 1].get('time').value;
        let duration = exams[l - 1].get('duration').value;

        let exForm = this.createExamForm();

        exForm.patchValue({
            priority: this.priorities[2],
            performingPhysician: this.performingPhysicians[0],
            date: date,
            time: moment(time, 'HH:mm').add(duration, 'm').format('HH:mm'),
            accessionNumber: this.incrementAccessionNumber()
        });

        // this.filteredProcedures[l] = this.procedureCodeControl[l].valueChanges.pipe(startWith(''), map(value => this._filter(value)));
        this.procedureCodeControl[l].valueChanges
            .pipe(
                startWith(''),
                switchMap(() => {
                    let query = this.procedureCodeControl[l].value;
                    return this.shared.getPaginatedProcedureCodes(10, 0, 'code', 'asc', query);
                }),
                map(data => data['content']),
                catchError(() => {
                    return observableOf([]);
                })
            ).subscribe(data => this.filteredProcedures[l] = data);
        this.procedureCodeControl[l].patchValue('');

        this.referringPhysicianControl[l].valueChanges
            .pipe(
                startWith(''),
                switchMap(() => {
                    let query = this.referringPhysicianControl[0].value;
                    console.log(query);
                    return this.shared.getPaginatedReferringPhysicians(10, 0, 'lastName', 'asc', query);
                }),
                map(data => {
                    this.isLoadingPhysicians = false;
                    return data['content'];
                }),
                catchError(() => {
                    this.isLoadingPhysicians = false;
                    return observableOf([]);
                })
            ).subscribe(data => this.filteredReferringPhysicians[l] = data);

        this.exams.push(exForm);
    }

    incrementAccessionNumber(): any {
        if (this.accessionNumber.length > 2 && this.generalSetting.accessionNumberPrefix) {
            let anSuffix = parseFloat(this.accessionNumber.replace(new RegExp(this.generalSetting.accessionNumberPrefix), ''));
            return this.accessionNumber = `${this.generalSetting.accessionNumberPrefix}${++anSuffix}`;
        }
    }

    notPaid(): boolean {
        return this.workflowItemToUpdate ? ['NOT_PAID', 'EXEMPT'].includes(this.workflowItemToUpdate.paymentStatus) : true;
    }

    onChangeProcedureCode(event, fg, idx) {
        let code = event.option.value;
        this.selected = code;

        let exCode = _find(this.exceptions, {procedureCode: code.code});

        if (exCode) fg.patchValue({
            patientPart: exCode.patientPart,
            organismPart: exCode.organismPart
        });

        // Update payment item
        let price = get(code, 'billingCode.price', 0);

        this.paymentItemToUpdate.initialPrice = price;
        this.paymentItemToUpdate.price = price;
        this.paymentItemToUpdate.leftAmount = price;

        let oldPatientPart = this.paymentItemToUpdate.patientPart;
        let oldOrgPart = this.paymentItemToUpdate.organismPart;

        let patPartPer = oldPatientPart / (oldPatientPart + oldOrgPart);

        this.paymentItemToUpdate.patientPart = price * patPartPer;
        this.paymentItemToUpdate.organismPart = price * (1 - patPartPer);

        this.selectProcedure(fg, code, idx);
    }

    onChangeRefPhy(event, fg, idx) {
        let fullName = event.option.value;
        fg.patchValue({referringPhysician: fullName});
        this.referringPhysicianControl[idx].patchValue(fullName.fullName);
    }

    searchProcedureCode(fg, idx) {
        if (this.notPaid()) {
            this.dialog.open(ProcedureCodeSearchComponent, {minWidth: '600px'})
                .afterClosed()
                .subscribe(code => {
                    if (code) this.selectProcedure(fg, code, idx)
                });
        }
    }

    setDate(range: any) {
        this.selectedRange = {
            date: new Date(moment(range.start).format('YYYY-MM-DD')),
            time: moment(range.start).format('HH:mm'),
            duration: 10
        };

        this.orderForm.patchValue(this.selectedRange);
        this.examForm.get('exams')['controls'].forEach(c => {
            c.patchValue(this.selectedRange);
            c.patchValue({priority: this.priorities[2], performingPhysician: this.performingPhysicians[0]});
        });
    }

    addFolder(folder: { name: string }) {
        let file: FileElement = {
            folder: true,
            patientID: this.patientID,
            name: folder.name,
            parent: this.currentRoot ? this.currentRoot.uuid : 'root'
        };

        this.subs.push(this.fileService.createFile(file).subscribe((res: FileElement) => {
            this.updateFileElementQuery();
        }));
    }

    moveElement(event: { element: FileElement; moveTo: FileElement }) {

        let elt = event.element;
        _set(elt, 'parent', event.moveTo.uuid);

        this.subs.push(this.fileService.updateFile(elt).subscribe(res => {
            this.updateFileElementQuery();
        }));
    }

    afterUpload(event) {
        if (event) this.updateFileElementQuery();
    }

    renameElement(element: FileElement) {
        _set(element, 'name', element.name);

        this.subs.push(this.fileService.updateFile(element).subscribe(res => {
            this.updateFileElementQuery();
        }));
    }

    removeElement(element: FileElement) {
        this.subs.push(this.fileService.deleteFile(element).subscribe(ok => {
            if (ok) this.updateFileElementQuery();
        }));
    }

    ngOnDestroy() {
        this.patientID = null;
        this.currentRoot = null;
        this.currentPath = null;
        this.fileElements = observableOf([]);
        this.subs.forEach(sub => sub.unsubscribe());
    }

    private setResource(resource: any) {
        switch (resource.resourceName) {
            case 'aet':
                this.subs.push(this.shared.getAetList().subscribe(res => {
                    this.aet = res.find(val => val.id === this.resId);
                }));
                break;
            case 'physician':
                this.subs.push(this.shared.getPerformingPhysicians().subscribe(res => {
                    this.scheduledPerfPhysician = res.find(val => val.id === this.resId);
                }));
        }
    }

    private checkAnExists() {
        if (!this.data.ids) this.examForm.get('exams')['controls'].forEach(c => c.get('accessionNumber').valueChanges.subscribe(value => {
            if (String(value).length !== 0) this.subs.push(this.shared.checkAnIfExists(value).subscribe(res => this.anExists = res));
        }));
    }

    private checkForPatientFutureAppointment() {
        if (!this.order && !this.data.patient) this.patientForm.get('externalPatientID').valueChanges.subscribe(value => {
            if (String(value).length !== 0) this.hasFutureExams = this.shared.checkForPatientFutureAppointment(value);
        });
    }

    private setAnonymousData() {
        if (!this.order && !this.data.patient) this.patientForm.get('patientClass').valueChanges.subscribe(value => {
            if (!this.patientForm.get('firstName').value && !this.patientForm.get('lastName').value) this.setAnon(value['id']);
        });
    }


    private setAnon(id: number) {
        let date = moment().format('YYMDHms');
        if (id === 1) this.patientForm.patchValue({
            firstName: 'Anon_' + date,
            lastName: 'Anon_' + date
        });
    }

    // Attached documents
    updateFileElementQuery() {
        this.fileElements = this.fileService.getPatientDocuments(this.patientID, this.currentRoot ? this.currentRoot.uuid : 'root');
    }

    navigateUp() {
        if (this.currentRoot && this.currentRoot.parent === 'root') {
            this.currentRoot = null;
            this.canNavigateUp = false;
            this.updateFileElementQuery();
        } else {
            this.currentRoot = this.fileService.get(this.currentRoot.parent);
            this.updateFileElementQuery();
        }
        this.currentPath = this.popFromPath(this.currentPath);
    }

    navigateToFolder(element: FileElement) {
        this.currentRoot = element;
        this.updateFileElementQuery();
        this.currentPath = this.pushToPath(this.currentPath, element.name);
        this.canNavigateUp = true;
    }

    pushToPath(path: string, folderName: string) {
        let p = path ? path : '';
        p += `${folderName}/`;
        return p;
    }

    popFromPath(path: string) {
        let p = path ? path : '';
        let split = p.split('/');
        split.splice(split.length - 2, 1);
        p = split.join('/');
        return p;
    }

    private checkPatientIdExists() {
        if (!this.order && !this.data.patient) this.patientForm.get('externalPatientID').valueChanges.subscribe(value => {
            if (String(value).length !== 0) this.subs.push(this.shared.checkIdIfExists(value).subscribe(res => this.patientIdExists = res));
        });
    }

    cancel() {
        if (!this.patientSelected) this.service.deleteFiles(this.patientID).subscribe();
        this.dialogRef.close(null)
    }

    private checkExistingPatientsCount() {
        this.patientForm.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            if (value.firstName && value.lastName)
                this.subs.push(this.patientService
                    .countExistingPatients(value.firstName, value.lastName)
                    .subscribe(total => this.numOfDuplicatePatients = total));

        });
    }

    private generatePatientID() {
        this.subs.push(this.shared.generatePatientId().subscribe(res => {
            this.patientForm.get('externalPatientID').patchValue(res.id);
            this.patientID = res.id;
        }));
    }

    private selectISR(isr: any) {
        if (isr) {
            this.isr = isr;
            let order = get(isr, 'imagingOrder');
            let organism = get(isr, 'patient.insurance.organismName');

            if (organism) this.shared.getOrganismConventions(this.splitter(organism, 1)).subscribe(data => this.organismConventions = data);

            this.singleReport = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].singleReport');
            this.workflowItemToUpdate = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].workflowItem');
            this.paymentItemToUpdate = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].paymentItem');
            this.studyInstanceUID = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].studyInstanceUID');
            this.attachedAccessionNumbers = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].attachedAccessionNumbers');
            this.tariffId = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].tariffId');

            this.convType = this.paymentItemToUpdate['paymentType'];
            if (organism) this.shared.getOrganismPECS(this.patientID, this.splitter(organism, 1)).subscribe(data => this.organismPecs = data);

            let code = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].procedureCode');

            let startDate = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].scheduledProcedureStepStartDate');
            let startTime = get(isr, 'requestedProcedure.scheduledProcedureSteps[0].scheduledProcedureStepStartTime');

            this.accessionNumber = get(isr, 'accessionNumber');

            let form = this.examForm.get('exams')['controls'][0];

            form.patchValue({
                orderId: order.id,
                reasonForExam: get(order, 'reasonForExam') || {id: ''},
                priority: get(order, 'priority') || this.priorities[2] || {id: ''},
                performingPhysician: this.performingPhysicians[0] || {id: ''},
                date: startDate || get(order, 'requestedDate'),
                time: startTime || get(order, 'requestedTime'),
                referringPhysician: get(order, 'referringPhysician') || {id: ''},
                accessionNumber: this.accessionNumber,
                notes: this.data.patient ? get(this.data.patient, 'medicalHistory.additionalPatientHistory') : '',
                paymentType: this.paymentItemToUpdate['paymentType'],
                patientPart: this.paymentItemToUpdate['patientPart'],
                organismPart: this.paymentItemToUpdate['organismPart'],
                pecId: this.paymentItemToUpdate['pecId'],
                pecRef: this.paymentItemToUpdate['pecRef'],
            });

            let p = get(order, 'referringPhysician');
            if (p !== null) this.referringPhysicianControl[0].patchValue(p.fullName);
            this.selectProcedure(form, code, 0);
        }
    }

    private countExams() {
        this.service.countExamsPerDate().subscribe(res => this.counts = res);
    }

    ngOnInit() {
        if (this.data.resource !== 'n/a') this.setResource(this.data.resource);

        if (this.billingRequired) this.subs.push(this.shared.getOrganismsList().subscribe(data => this.organisms = data));

        this.patientForm.get('debiter').valueChanges.subscribe(value => {
            if (value === 'PATIENT') this.patientForm.get('insurance.organismName').disable();
            else this.patientForm.get('insurance.organismName').enable();

            this.convType = value;
            this.organismSelection = value !== 'PATIENT';

            if (value !== 'PATIENT') {
                this.onSelectOrganism();
            } else {
                this.patientForm.get('insurance.conventionName').patchValue('');
            }
        });

        this.subs.push(forkJoin(
            [
                this.shared.getPriorities(),
                this.shared.getAppointmentReasons(),
                this.shared.getReasonForExams(),
                this.shared.getAetList(),
                this.shared.getTechnicians(),
                this.shared.getPerformingPhysicians(),
                this.shared.getConfidentialities(),
                this.shared.getGenders(),
                this.shared.getTitles(),
                this.shared.getPatientClasses(),
                this.setting.getGeneralSetting()
            ]
        ).subscribe(data => {
            [
                this.priorities,
                this.appointmentReasons,
                this.reasonForExams,
                this.aets,
                this.technicians,
                this.performingPhysicians,
                this.confidentialities,
                this.genders,
                this.titles,
                this.patientClasses,
                this.generalSetting
            ] = data;

            this.billingRequired = this.generalSetting && this.generalSetting.billingRequired;

            if (this.billingRequired) this.subs.push(this.shared.getTariffs().subscribe(data => this.tariffs = data));

            this.filteredAets[0] = this.aets;
            this.singleReport = this.generalSetting && this.generalSetting.singleReport;

            if (this.generalSetting && this.generalSetting.patientIdGenerationMode === 'AUTOMATIC' && !get(this.data, 'patient.externalPatientID'))
                this.generatePatientID();


            if (this.generalSetting && this.generalSetting.accessionIdGenerationMode === 'AUTOMATIC' && !this.data.ids) {
                this.shared.generateAccessionNumber().subscribe(res => {
                    this.accessionNumber = res.id;
                    this.examForm.get('exams')['controls'][0].get('accessionNumber').patchValue(res.id);
                });
            }

            this.procedureCodeControl[0].valueChanges
                .pipe(
                    startWith(''),
                    switchMap(() => {
                        let query = this.procedureCodeControl[0].value;
                        return this.shared.getPaginatedProcedureCodes(10, 0, 'code', 'asc', query);
                    }),
                    map(data => data['content']),
                    catchError(() => {
                        return observableOf([]);
                    })
                ).subscribe(data => this.filteredProcedures[0] = data);
            this.procedureCodeControl[0].patchValue('');

            this.referringPhysicianControl[0].valueChanges
                .pipe(
                    startWith(''),
                    switchMap(() => {
                        let query = this.referringPhysicianControl[0].value;
                        return this.shared.getPaginatedReferringPhysicians(10, 0, 'lastName', 'asc', query);
                    }),
                    map(data => data['content']),
                    catchError(() => {
                        return observableOf([]);
                    })
                ).subscribe(data => this.filteredReferringPhysicians[0] = data);
            this.referringPhysicianControl[0].patchValue('');

            this.patientForm.get('demographic.confidentiality').patchValue(this.confidentialities[0]);
            this.patientForm.get('patientClass').patchValue(this.patientClasses[2]);

            this.setDate(this.data.selectedDateRange);

            this.selectPatient(this.data.patient);

            this.selectISR(this.data.isr);

            this.patientForm.get('demographic.gender').valueChanges.subscribe(gender => {
                let title = gender.id === 10 ? this.titles[0] : this.titles[1];
                this.patientForm.get('title').patchValue(title);
            });

            if (!this.data.editable) {
                this.patientForm.disable();
                this.orderForm.disable();
                this.examForm.disable();
                this.orderForm.disable();
            }
        }));
    }

    private findConventions(organismId: any) {
        this.organismConventions = [];
        this.shared.getOrganismConventions(organismId).subscribe(res => {
            this.organismConventions = res;
        });
    }

    private findOrganismPECs(organismId: any) {
        this.organismPecs = [];
        this.shared.getOrganismPECS(this.patientID, organismId).subscribe(data => {
            this.organismPecs = data
        });
    }

    onSelectConvention(convention: any) {

        let cnv = convention.split('@');

        this.selectedConvention = {conventionName: cnv[0], patientPart: Number(cnv[2]), organismPart: Number(cnv[3])};

        let form = this.examForm.get('exams')['controls'][0];
        form.patchValue({
            patientPart: this.selectedConvention.patientPart,
            organismPart: this.selectedConvention.organismPart
        });

        let procedureCode = form.get('procedureCode').value;

        let examCode = procedureCode ? procedureCode.code : null;

        this.shared.getConventionException(this.splitter(convention, 1)).subscribe(data => {
            if (data) {
                this.exceptions = data.map(v => {
                    let ex = v.split('@');
                    return {procedureCode: ex[0], patientPart: Number(ex[1]), organismPart: Number(ex[2])};
                });

                if (examCode) {
                    let code = _find(this.exceptions, {procedureCode: examCode});

                    if (code) form.patchValue({
                        patientPart: code.patientPart,
                        organismPart: code.organismPart
                    });
                }
            }
        });
    }

    onSelectPEC(pec: MatSelectChange) {
        let pecId = this.splitter(pec.value, 0);
        let pecRef = this.splitter(pec.value, 1);

        this.selectedPecID = pecId;
        this.selectedPecRef = pecRef;

        let forms = this.examForm.get('exams')['controls'] as any[];
        forms.forEach(fg => {
            fg.patchValue({
                pecId: pecId,
                pecRef: pecRef
            })
        });

    }

    private selectProcedure(fg: FormGroup, procedure: ProcedureCode, idx: number) {

        this.selected = procedure.id;
        fg.patchValue({procedureCode: procedure});
        this.procedureCodeControl[idx].patchValue(procedure.code);
        if (!this.notPaid()) {
            this.procedureCodeControl[idx].disable();
            this.patientForm.get('debiter').disable();
            this.patientForm.get('insurance.organismName').disable();
        }

        this.subs.push(this.shared
            .getAetList()
            .subscribe(aets => {
                let scheduledStationAETitle, physician, technician;

                this.filteredAets[idx] = procedure.modality ? _filter(aets, ae => ae.modality.id === procedure.modality.id) : aets;

                let defaultPhysician = procedure.defaultPerformingPhysician ? _find(this.performingPhysicians, {id: procedure.defaultPerformingPhysician.id}) : this.performingPhysicians[0];

                if (this.filteredAets[idx].length === 0) {
                    this.snack.open('Aucun appareil lié à cet examen', 'OK', {duration: 10000});
                    return;
                }

                if (this.data.ids) {
                    scheduledStationAETitle = get(this.isr, 'requestedProcedure.scheduledProcedureSteps[0].scheduledStationAETitle');
                    physician = get(this.isr, 'requestedProcedure.scheduledProcedureSteps[0].scheduledPerformingPhysiciansName');
                    technician = get(this.isr, 'requestedProcedure.scheduledProcedureSteps[0].technician');
                }

                fg.patchValue({
                    aet: scheduledStationAETitle || this.filteredAets[idx][0] || {id: ''},
                    technician: technician || this.filteredAets[idx][0].defaultTechnician || {id: ''},
                    performingPhysician: physician || defaultPhysician,
                    reasonForExam: procedure.reasonForExam
                });
            }));
    }

    splitter = (str: string, idx: number): string => str.split('@')[idx];

    onSelectOrganism() {
        let organism = this.patientForm.get('insurance.organismName').value;
        if (!organism) return;


        this.selectedOrganism = organism;

        let organismId = this.splitter(organism, 1);
        this.patientForm.get('insurance.conventionName').patchValue('none@0@100@0');
        this.patientForm.get('insurance.organismName').patchValue(organism);

        let form = this.examForm.get('exams')['controls'][0];

        if (this.billingRequired) {
            if (this.convType === 'THIRD_PARTY_PAYMENT') {
                this.findConventions(organismId);
            } else if (this.convType === 'PEC') {
                this.findOrganismPECs(organismId);
            }
        }

        form.get('paymentType').patchValue(this.convType)
    }

    changeTariff(selection: MatSelectChange) {
        if (this.billingRequired) this.subs.push(this.shared.getTariffLines(selection.value).subscribe(data => {
            if (data) {
                let tariffLines = data.map(it => {
                    let line = it.split('@');
                    return {code: line[0], price: Number(line[1])}
                });
                this.tariffLines = tariffLines;
            }
        }));
    }
}
