import * as moment from 'moment';
import {assign, difference, get, uniq} from 'lodash';
import {
    Appointment,
    ColumnDataType,
    FormatRule,
    FormattingStyle,
    initStaff,
    PatientWorkflow,
    WorkflowItem
} from '../model';
import {FileElement} from './file-explorer';
import * as $ from 'jquery';

const emptyObj = {
    id: '',
    value: '',
    description: ''
};

export function showPrintPreview(url) {
    let $iframe = $('<iframe />').attr('src', url).css({position: 'absolute', top: '-9999px'});
    $iframe.on('load', () => setTimeout(() => $iframe.remove(), 0));
    $(document.body).append($iframe);
}

export function patientWorkItemsTotalAmount(patientWorkflow: PatientWorkflow): any {
    return patientWorkflow.workflowItems.map(it => it.totalAmount - it.discount).reduce((acc, d) => acc + d, 0);
}

export function sumPatientWorkItemsBy(patientWorkflow: PatientWorkflow, attr: string): any {
    return patientWorkflow.workflowItems.map(it => it[attr]).reduce((acc, d) => acc + d, 0);
}

export function waitingDuration(row: WorkflowItem): number {
    let patientArrival = moment(row.patientArrival).utc(true);
    return moment().diff(patientArrival, 'minute');
}

export function getDateTimeFormat(lang: string = 'fr'): string {
    return lang === 'en' ? 'dd MM yyyy HH:mm' : 'dd/MM/yyyy HH:mm';
}

export function getDateFormat(lang: string = 'fr'): string {
    return lang === 'en' ? 'dd MM yyyy' : 'dd/MM/yyyy';
}

export function getDisplayStyle(style: FormattingStyle): any {
    return style ? {
        color: style.color,
        backgroundColor: style.backgroundColor === '#ffffff' ? 'transparent' : style.backgroundColor,
        borderBottomColor: style.backgroundColor === '#ffffff' ? 'transparent' : style.backgroundColor,
        fontWeight: style.bold ? 'bold' : 400,
        textDecoration: (style.underlined ? 'underline ' : '') + (style.strikethrough ? 'line-through ' : ''),
        fontStyle: style.italic ? 'italic' : 'normal'
    } : {};
}

export function isStringConditionFilled(formatRule: FormatRule, data: string, testValue: string): any {
    switch (formatRule) {
        case FormatRule.IS_EMPTY:
            return !data;
        case FormatRule.IS_NOT_EMPTY:
            return data && data.length != 0;
        case FormatRule.TEXT_NOT_CONTAINS:
            return data && !data.toLocaleLowerCase().includes(testValue.toLocaleLowerCase());
        case FormatRule.TEXT_STARTS_WITH:
            return data && data.toLocaleLowerCase().startsWith(testValue.toLocaleLowerCase());
        case FormatRule.TEXT_ENDS_WITH:
            return data && data.toLocaleLowerCase().endsWith(testValue.toLocaleLowerCase());
        case FormatRule.TEXT_IS_EXACTLY:
            return data == testValue;
        case FormatRule.TEXT_CONTAINS:
            return data && data.toLocaleLowerCase().includes(testValue.toLocaleLowerCase());
    }
}

export function isStatusConditionFilled(formatRule: FormatRule, data: string, testValue: string): any {
    switch (formatRule) {
        case FormatRule.TEXT_IS_EXACTLY:
            return data == testValue;
        case FormatRule.IS_EMPTY:
            return !data;
        case FormatRule.IS_NOT_EMPTY:
            return data && data.length != 0;
    }
}


export function isDateConditionFilled(formatRule: FormatRule, data: any, testValue: string): any {
    switch (formatRule) {
        case FormatRule.IS_EMPTY:
            return !data;
        case FormatRule.IS_NOT_EMPTY:
            return data && data.length != 0;
        case FormatRule.DATE_IS:
            return dateIsEqual(data, testValue);
    }
}

function dateIsEqual(data: any, testValue: string): boolean {
    switch (testValue) {
        case 'TODAY':
            return moment(data).isSame(moment(), 'd');
        case 'TOMORROW':
            return moment(data).isSame(moment().add(1, 'd'), 'd');
        case 'YESTERDAY':
            return moment(data).isSame(moment().subtract(1, 'd'), 'd');
        case 'PAST_WEEK':
            return moment(data).isBetween(moment().subtract(14, 'd'), moment().subtract(6, 'd'), 'd', '()');
        case 'PAST_MONTH':
            return moment(data).isBetween(moment().subtract(2, 'month'), moment().subtract(1, 'month'), 'd', '()');
        case 'PAST_YEAR':
            return moment(data).isBetween(moment().subtract(2, 'year'), moment().subtract(1, 'year'), 'd', '()');
        default:
            return false;
    }
}

export function checkCondition(colDataType: ColumnDataType, formatRule: FormatRule, data: any, firstValue: any, secondValue: any): boolean {
    switch (colDataType) {
        case ColumnDataType.DATE:
            return isDateConditionFilled(formatRule, data, firstValue);
        case ColumnDataType.NUMERIC:
            return isNumericConditionFilled(formatRule, data, firstValue, secondValue);
        case ColumnDataType.BOOLEAN:
            return isBooleanConditionFilled(formatRule, data, firstValue);
        case ColumnDataType.STATUS:
            return isStatusConditionFilled(formatRule, data, firstValue);
        case ColumnDataType.STRING:
            return isStringConditionFilled(formatRule, data, firstValue);
        default:
            return false;
    }
}

export function isNumericConditionFilled(formatRule: FormatRule, data: number, firstValue: string, secondValue: string): any {
    switch (formatRule) {
        case FormatRule.GREATER_THAN:
            return data > parseFloat(firstValue);
        case FormatRule.GREATER_THAN_OR_EQUAL:
            return data >= parseFloat(firstValue);
        case FormatRule.LESS_THAN:
            return data < parseFloat(firstValue);
        case FormatRule.LESS_THAN_OR_EQUAL:
            return data <= parseFloat(firstValue);
        case FormatRule.IS_BETWEEN:
            return (data >= parseFloat(firstValue) && data <= parseFloat(secondValue));
        case FormatRule.IS_NOT_BETWEEN:
            return !(data >= parseFloat(firstValue) && data <= parseFloat(secondValue));
    }
}

export function isBooleanConditionFilled(formatRule: FormatRule, data: boolean, testValue: string): any {
    if (formatRule === FormatRule.TEXT_IS_EXACTLY) {
        return data.toString() == testValue.toString();
    }
    return false
}

export function paymentColor(status: string, transparent: boolean = false): string {
    switch (status) {
        case 'PAID':
            return '#00FF00' + (transparent ? '60' : '');
        case 'NOT_PAID':
            return '#FF0000' + (transparent ? '60' : '');
        case 'PAID_PARTIALLY':
            return '#e6c34c' + (transparent ? '60' : '');
        case 'EXEMPT':
            return '#b1aaaa' + (transparent ? '60' : '');
        default:
            return ''
    }
}

export function getPatientClassIcon(patientClass: string): any {
    switch (patientClass) {
        case 'E':
            return 'mdi-human-wheelchair';
        case 'I':
            return 'mdi-account-clock';
        case 'O':
            return 'mdi-account-arrow-left';
    }
}

export function getPatientClassColor(patientClass: string): any {
    switch (patientClass) {
        case 'E':
            return '#ff5522';
        case 'I':
            return '#5582ff';
        case 'O':
            return 'rgba(35,135,72,0.81)';
    }
}

export function shortName(name: string): string {
    if (name) {
        let tokens = name.split(' ');
        let fn = tokens[tokens.length - 1];
        let ln = tokens[0];

        return (ln.charAt(0) + '' + fn.charAt(0)).toUpperCase()
    } else return '-'
}

export function groupWorkflowData(data: WorkflowItem[]): PatientWorkflow[] {
    let patientData: PatientWorkflow[] = [];
    data.forEach(wi => {
        wi.count = 1;
        let data = patientData.find(it => (it.patientID === wi.patientID) && moment(it.patientArrival).isSame(moment(wi.patientArrival), 'day'));
        // let data = patientData.find(it => it.patientID === wi.patientID);
        if (data) {
            data.workflowItems.push(wi);
        } else {
            let d = Object.assign({}, wi as PatientWorkflow);
            d.workflowItems = [wi];
            patientData.push(d);
        }
    });

    return patientData.map(it => {
        // it.totalAmount = patientWorkItemsTotalAmount(it);
        // it.paidAmount = sumPatientWorkItemsBy(it, 'paidAmount');
        it.reportingStatus = calculateReportingStatus(it.workflowItems.map(w => w.reportingStatus));
        it.sent = it.workflowItems.map(it => it.sent).reduce((b, acc) => acc || b, false);
        it.imagesAvailables = it.workflowItems.map(it => it.imagesAvailables).reduce((b, acc) => acc || b, false);
        it.billed = it.workflowItems.map(it => it.billed).reduce((b, acc) => acc || b, false);
        it.editingState = it.workflowItems.map(it => it.editingState).reduce((b, acc) => acc + b, '');
        it.examType = uniq(it.workflowItems.map(it => it.examType)).reduce((b, acc) => {
            return b == '' ? acc : `${acc}/${b}`;
        }, '');

        let codes = it.workflowItems.map(w => w.procedureCode);
        it.procedureCode = codes.join('/ ');
        it.count = codes.length;
        return it;
    });
}

export function calculatePaymentStatus(itemsStatuses: string[]): string {
    if (itemsStatuses.includes('PAID_PARTIALLY')) return 'PAID_PARTIALLY';
    else if (itemsStatuses.includes('PAID') && itemsStatuses.includes('NOT_PAID')) return 'PAID_PARTIALLY';
    else if (itemsStatuses.includes('PAID') && !itemsStatuses.includes('PAID_PARTIALLY') && !itemsStatuses.includes('NOT_PAID')) return 'PAID';
    else if (itemsStatuses.includes('NOT_PAID')) return 'NOT_PAID';
    else return 'EXEMPT';
}

export function calculateReportingStatus(itemsStatuses: string[]): string {
    if (itemsStatuses.includes('SIGNED')) return 'SIGNED';
    else if (itemsStatuses.includes('FINISHED')) return 'FINISHED';
    else if (itemsStatuses.includes('VERIFIED')) return 'VERIFIED';
    else if (itemsStatuses.includes('TO_REVIEW')) return 'TO_REVIEW';
    else if (itemsStatuses.includes('TO_SIGN')) return 'TO_SIGN';
    else if (itemsStatuses.includes('TO_VERIFY')) return 'TO_VERIFY';
    else if (itemsStatuses.includes('DELIVERED')) return 'DELIVERED';
    else if (itemsStatuses.includes('IN_PROGRESS')) return 'IN_PROGRESS';
    else return 'WAITING'
}

export function bitmapToBlob(imageBitmap) {
    let canvas = document.createElement('canvas');

    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;

    let ctx = canvas.getContext('2d');
    ctx.drawImage(imageBitmap, 0, 0);

    return new Promise(resolve => canvas.toBlob(resolve));
}

export function hasPermission(status: string, row: WorkflowItem): boolean {
    switch (status) {
        case 'authorizeExam':
            return !row.patientArrival;
        case 'startExam':
            return row.patientArrival && !row.examinationStarted && !row.patientLeaving;
        case 'finishExam':
            return row.patientArrival && row.examinationStarted && !row.examinationFinished;
        case 'completeExam':
            return row.examinationStarted && !row.examinationCompleted;
        case 'exitPatient':
            return (row.examinationFinished || (row.patientArrival && !row.examinationStarted)) && !row.patientLeaving;
        case 'printReport':
            return ['FINISHED', 'SIGNED', 'VERIFIED'].includes(row.reportingStatus);
        case 'printBooklet':
            return ['FINISHED', 'SIGNED', 'VERIFIED'].includes(row.reportingStatus);
        case 'createRdvForResult':
            return row.examinationFinished && !row.patientLeaving && row.reportingStatus !== 'S_CM';
        case 'editReport':
            return true;
        default:
            return false;
    }
}

export function getColumnStatuses(columnHeader: string): string[] {
    switch (columnHeader) {
        case 'PAYMENT_STATUS':
            return ['PAID', 'NOT_PAID', 'PAID_PARTIALLY', 'EXEMPT'];
        case 'REPORT_COMPLETION_STATUS':
            return ['WAITING', 'INPROGRESS', 'READY', 'DELIVERED'];
        case 'REPORTING_STATUS':
            return ["IN_PROGRESS", "TO_SIGN", "TO_REVIEW", "TO_TRANSCRIBE", "FINISHED", "VERIFIED", "SIGNED", "WAITING", "DELIVERED"];
        case 'PATIENT_STATUS':
            return ['NOT_YET', 'BEFORE_TIME', 'WAITING', 'EXAM_STARTED', 'EXAM_FINISHED'];
        case 'PATIENT_CLASS':
            return ['E', 'I', 'O'];
        default:
            return [];
    }
}

export function specialAttributes(header): boolean {
    return ['TOTAL_AMOUNT',
        'PAID_AMOUNT',
        'DISCOUNT',
        'LEFT_AMOUNT',
        'PAYMENT_STATUS',
        'PATIENTARRIVAL',
        'PROCEDURE_TYPE',
        'EXAM_START',
        'EXAM_FINISH',
        'PERFORMING_PHYSICIAN',
        'TECHNICIAN',
        'REPORTING_STATUS',
        'REPORT_COMPLETION_STATUS',
        'SPSSTATUS'].includes(header);
}

export function getConfidentialityColor(conf: string): string {
    switch (conf) {
        case 'U':
            return '#999';
        case 'V':
            return '#e5783b';
        case 'VIP':
            return '#11e596';
        default:
            return '#999';
    }
}

export function createOrderDetails(order: any): any {

    let patient = order.patient || {};
    let medHistory = order.patient.medicalHistory || {};
    let demographic = patient.demographic || {};

    return assign({}, {
        patientID: patient.externalPatientID,
        patientName: (patient.title ? patient.title.value : '') + ' ' + patient.firstName + ' ' + patient.lastName,
        gender: patient.demographic.gender ? patient.demographic.value : '-',
        age: moment().diff(patient.demographic.dateOfBirth, 'years'),
        dangerCode: medHistory.dangerCode ? medHistory.dangerCode.value : '-',
        ambulatoryStatus: demographic.ambulatoryStatus ? demographic.ambulatoryStatus.description : '-',
        patientState: medHistory.patientState ? medHistory.patientState.description : '-',
        alerts: medHistory.medicalAlerts || '-',
        weight: medHistory.patientWeight || '-',
        specialNeeds: medHistory.specialNeeds || '-',
        additionalHistory: medHistory.additionalPatientHistory || '-',
        allergies: medHistory.allergies || '-',
        createdBy: order.enteredBy ? order.enteredBy.lastName : '-',
        createdOn: order.orderTimeLine.creation || '-',
        requestingPhysician: order.requestingPhysician || '-',
        organization: order.enteringOrganization || '-',
        orderingProvider: order.orderingProvider || '-',
        phoneNumber: order.callBackPhoneNumber || '-',
        reasonForExam: order.reasonForExam,
        relevantClinicalInfo: order.relevantClinicalInfo || '-',
        date: order.requestedDate,
        startTime: order.requestedTime
    });
}


export function buildPatientDate(patientInfo: any): any {
    let patient = [
        {key: '%PATIENT::ID%', value: get(patientInfo, 'patientID')},
        {key: '%PATIENT::FULL_NAME%', value: get(patientInfo, 'fullName')},
        {key: '%PATIENT::AGE%', value: get(patientInfo, 'age')},
        {key: '%PATIENT::DOB%', value: get(patientInfo, 'dateOfBirth')},
        {key: '%PATIENT::HEIGHT%', value: get(patientInfo, 'height')},
        {key: '%PATIENT::WEIGHT%', value: get(patientInfo, 'weight')},
        {key: '%PATIENT::GENDER%', value: get(patientInfo, 'sex')},
        {key: '%PATIENT::BMI%', value: get(patientInfo, 'imc')},
        {key: '%PATIENT::BS%', value: get(patientInfo, 'sc')},
        {key: '%PATIENT::ALERTS%', value: get(patientInfo, 'alerts')},
        {key: '%PATIENT::CIN%', value: get(patientInfo, 'cin')},
        {key: '%PATIENT::PHONE%', value: get(patientInfo, 'phone')},
        {key: '%PATIENT::ADDRESS%', value: get(patientInfo, 'street')},
        {key: '%PATIENT::CITY%', value: get(patientInfo, 'city')},
        {key: '%PATIENT::POSTAL_CODE%', value: get(patientInfo, 'postalCode')},
        {key: '%PATIENT::COUNTRY%', value: get(patientInfo, 'country')}
    ];

    // localStorage.setItem('PATIENT_DATA', JSON.stringify(patient));

    return patient;
}

export function buildExamData(examData: any): any {
    let exam = [
        {key: '%EXAM::CODE%', value: get(examData, 'code')},
        {key: '%EXAM::TECHNIQUE%', value: get(examData, 'technique')},
        {key: '%EXAM::REFERRING_PHYSICIAN%', value: get(examData, 'referringPhysician')},
        {key: '%EXAM::REFERRING_PHYSICIAN_ADDRESS%', value: get(examData, 'referringPhysicianAddress')},
        {key: '%EXAM::DATE%', value: get(examData, 'examDate')},
        {key: '%EXAM::SIGNATURE_DATE%', value: get(examData, 'signatureDate')}
    ];

    // localStorage.setItem('EXAM_DATA', JSON.stringify(exam));

    return exam;
}

export function updateVariableValue(variable: string, value: string): any {
    let examVariables: { key: string, value: string }[] = JSON.parse(localStorage.getItem('EXAM_DATA'));
    let exams = examVariables.map(it => {
        if (it.key == variable) it.value = value;
        return it;
    });
    localStorage.setItem('EXAM_DATA', JSON.stringify(exams));
}

export function savePatientDataLocally(patientInfo: any, translate) {

    let patient = [
        {key: translate.instant('PATIENT_ID'), value: get(patientInfo, 'patientID')},
        {key: translate.instant('PATIENT_NAME'), value: get(patientInfo, 'fullName')},
        {key: translate.instant('AGE'), value: get(patientInfo, 'age')},
        {key: translate.instant('DATE_OF_BIRTH'), value: get(patientInfo, 'dateOfBirth')},
        {key: translate.instant('BMI'), value: get(patientInfo, 'imc')},
        {key: translate.instant('HEIGHT'), value: get(patientInfo, 'height')},
        {key: translate.instant('WEIGHT'), value: get(patientInfo, 'weight')},
        {key: translate.instant('SEX'), value: get(patientInfo, 'sex')},
        {key: translate.instant('BSA'), value: get(patientInfo, 'sc')},
        {key: translate.instant('ALERTS'), value: get(patientInfo, 'alerts')},
        {key: translate.instant('CIN'), value: get(patientInfo, 'cin')},
        {key: translate.instant('PHONE'), value: get(patientInfo, 'phone')}
    ];

    localStorage.setItem('patient', JSON.stringify(patient));
}

export function removeLocalPatientData() {
    localStorage.removeItem('patient');
    localStorage.removeItem('PATIENT_DATA');
    localStorage.removeItem('EXAM_DATA');
}

export function createDemographic(data: any): any {
    return assign(data, {
        maritalStatus: data.maritalStatus.id === '' ? null : data.maritalStatus,
        gender: data.gender.id === '' ? null : data.gender,
        confidentiality: data.confidentiality.id === '' ? null : data.confidentiality,
    });
}

export function getFileUrl(file: FileElement): string {
    return ['/upload-dir/', file.uuid + '.' + file.fileType.toLowerCase()].join('');
}

export function getReportStatus(status: string): string {
    switch (status) {
        case 'I_CL':
            return 'WAITING';
        case 'I_IP':
            return 'I_IN_PROGRESS';
        case 'I_CM':
            return 'INTERPRETED';
        case 'T_IP':
            return 'T_IN_PROGRESS';
        case 'T_CL':
            return 'T_WAITING';
        case 'T_CM':
            return 'TRANSCRIBED';
        case 'V_IP':
            return 'V_IN_PROGRESS';
        case 'V_CL':
            return 'V_WAITING';
        case 'V_CM':
            return 'VALIDATED';
        case 'R_IP':
            return 'R_IN_PROGRESS';
        case 'R_CL':
            return 'R_WAITING';
        case 'R_CM':
            return 'REVIEWED';
        case 'S_CL':
            return 'S_WAITING';
        case 'S_IP':
            return 'S_IN_PROGRESS';
        case 'S_CM':
            return 'SIGNED';
        case 'None':
            return 'NO_REPORT';
        default:
            return 'WAITING';
    }
}

export function getStatusColor(status: string): string {
    switch (status.split('_')[1]) {
        case 'CL':
            return '#484848';
        case 'CM':
            return '#016704';
        case 'IP':
            return '#9c0000';
        default:
            return '#484848';
    }
}

export function createMedicalHistory(data: any): any {
    return assign(data, {
        dangerCode: data.dangerCode.id === '' ? null : data.dangerCode,
        patientState: data.patientState.id === '' ? null : data.patientState,
    })
}

export function buildDemographics(patient: any): any {
    let dmg = patient.demographic;
    return assign(patient.demographic, {
        gender: dmg && dmg.gender ? dmg.gender : emptyObj,
        maritalStatus: dmg && dmg.maritalStatus ? dmg.maritalStatus : emptyObj,
        ambulatoryStatus: dmg && dmg.ambulatoryStatus ? dmg.ambulatoryStatus : emptyObj,
        ethnicGroup: dmg && dmg.ethnicGroup ? dmg.ethnicGroup : emptyObj,
        confidentiality: dmg && dmg.confidentiality ? dmg.confidentiality : emptyObj,
        dateOfBirth: dmg.dateOfBirth ? moment(dmg.dateOfBirth).format('YYYY-MM-DD') : null
    })
}

export function buildInsurance(insurance: any): any {
    return insurance === null ? {id: '', organism: {id: ''}} : assign(insurance, {
        organism: insurance.organism || emptyObj,
        organismName: insurance.organismName ? insurance.organismName.split('@')[0] : ''
    })
}

export function escapeDemographicNullValues(dmg: any): any {
    return assign(dmg, {
        gender: dmg.gender.id !== '' ? dmg.gender : null,
        maritalStatus: dmg.maritalStatus.id !== '' ? dmg.maritalStatus : null,
        ambulatoryStatus: dmg.ambulatoryStatus.id !== '' ? dmg.ambulatoryStatus : null,
        ethnicGroup: dmg.ethnicGroup.id !== '' ? dmg.ethnicGroup : null,
        confidentiality: dmg.confidentiality.id !== '' ? dmg.confidentiality : null,
        dateOfBirth: dmg.dateOfBirth ? moment(dmg.dateOfBirth).format('YYYY-MM-DD') : null
    })
}

export function escapeInsuranceNullValues(insurance: any): any {
    return assign(insurance, {
        organism: insurance.organism && insurance.organism.id !== '' ? insurance.organism : null
    })
}

export function buildMedical(patient: any): any {
    let mh = patient.medicalHistory;

    if (mh !== null) {
        return assign(mh, {
            id: mh.id,
            dangerCode: mh.dangerCode && mh.dangerCode.id !== '' ? mh.dangerCode : emptyObj,
            patientState: mh.patientState && mh.patientState.id !== '' ? mh.patientState : emptyObj,
            allergies: mh.allergies.split(',')
        })
    } else {
        return {
            id: '',
            dangerCode: emptyObj,
            patientState: emptyObj
        }
    }
}

export function escapeMedicalNullValues(mh: any): any {
    return assign(mh, {
        dangerCode: mh.dangerCode.id !== '' ? mh.dangerCode : null,
        patientState: mh.patientState.id !== '' ? mh.patientState : null,
        allergies: mh.allergies ? mh.allergies.join(',') : ''
    })
}

export function buildDateRange(option: string): string {

    let start, end, dateFormat = 'YYYYMMDDHHmm';
    switch (option) {
        case 'TODAY':
            start = moment().startOf('day').format(dateFormat);
            end = moment().endOf('day').format(dateFormat);
            break;
        case 'THIS_WEEK':
            start = moment().startOf('week').format(dateFormat);
            end = moment().endOf('week').format(dateFormat);
            break;
        case 'THIS_MONTH':
            start = moment().startOf('month').format(dateFormat);
            end = moment().endOf('month').format(dateFormat);
            break;
        case 'THIS_YEAR':
            start = moment().startOf('year').format(dateFormat);
            end = moment().endOf('year').format(dateFormat);
            break;
    }

    return start + '-' + end;

}


export function orderNullableValues(order: any): any {
    return assign(order, {
        confidentiality: order.confidentiality.id === '' ? null : order.confidentiality,
        referringPhysician: order.referringPhysician.id === '' ? null : order.referringPhysician,
        orderType: order.orderType.id === '' ? null : order.orderType,
        reasonForExam: order.reasonForExam.id === '' ? null : order.reasonForExam,
        requestingService: order.requestingService.id === '' ? null : order.requestingService,
    })
}

export function handlePatchNullValues(order: any): any {
    return assign(order, {
        orderType: order.orderType || emptyObj,
        transportArrangement: order.transportArrangement || emptyObj,
        transportationMode: order.transportationMode || emptyObj,
        enteredBy: order.enteredBy || initStaff,
        transcriptionist: order.transcriptionist || initStaff,
        verifiedBy: order.verifiedBy || initStaff,
        assistantResultInterpreter: order.assistantResultInterpreter || initStaff,
        principalResultInterpreter: order.principalResultInterpreter || initStaff,
        orderControl: order.orderControl || emptyObj,
        orderStatus: order.orderStatus || emptyObj,
        reasonForExam: order.reasonForExam || emptyObj,
        rejectionReason: order.rejectionReason || emptyObj,
        billingStatus: order.billingStatus || emptyObj,
        requestingPhysician: order.requestingPhysician || initStaff,
        requestingService: order.requestingService || emptyObj
    })
}

export function handleNullValues(center: any): any {
    return assign(center, {
        schedule: center.schedule || emptyObj,
        defaultProcedureCalatlog: center.defaultProcedureCalatlog || emptyObj,
        defaultProcedureReportTemplateCatalog: center.defaultProcedureReportTemplateCatalog || emptyObj,
        tax: center.tax || emptyObj,
    });
}

export function calculateSlotDuration(duration): string {
    if (duration < 10) {
        return '00:0' + duration;
    } else if (duration < 60) {
        return '00:' + duration;
    } else return '01:00';
}

export function deleteItemFromArray(arr: any[], item: any): any[] {
    arr.splice(arr.indexOf(item), 1);
    return arr;
}

export function parseOrderData(event: any): any {
    return {
        notBeforeDate: event.start,
        notAfterDate: event.end,
        notBeforeTime: moment(event.start).format('HH:mm'),
        notAfterTime: moment(event.end).format('HH:mm'),
        radiologyOrder: event.order,
    }
}


export function buildClosingDays(arr: any[], ev: any): any[] {
    let source = ev.source;
    let selected = {
        name: source.value,
        checked: source.checked
    };

    let day = arr.find(d => d.name === selected.name);

    day.checked = !!selected.checked;

    return arr


}

export function patchClosingDays(arr: any[], strDays: string): any {
    let closingDays = strDays.split(';');

    if (closingDays.length > 0) closingDays.forEach(dk => {
        if (arr.find(d => d.key === dk)) arr.find(d => d.key === dk).checked = true
    });
}

export function getDays(lang: string): any[] {

    if (lang === 'en') {
        return [
            {key: 'D1', name: 'Mon', checked: false},
            {key: 'D2', name: 'Tue', checked: false},
            {key: 'D3', name: 'Wed', checked: false},
            {key: 'D4', name: 'Thu', checked: false},
            {key: 'D5', name: 'Fri', checked: false},
            {key: 'D6', name: 'Sat', checked: false},
            {key: 'D0', name: 'Sun', checked: false},
        ]
    } else {
        return [
            {key: 'D1', name: 'Lun', checked: false},
            {key: 'D2', name: 'Mar', checked: false},
            {key: 'D3', name: 'Mer', checked: false},
            {key: 'D4', name: 'Jeu', checked: false},
            {key: 'D5', name: 'Ven', checked: false},
            {key: 'D6', name: 'Sam', checked: false},
            {key: 'D0', name: 'Dim', checked: false},
        ]
    }
}

export function getCalendarViews(lang: string): any[] {

    if (lang === 'en') {
        return [
            {id: 1, key: 'M', name: 'Month'},
            {id: 2, key: 'W', name: 'Week'},
            {id: 3, key: 'D', name: 'Day'},
            {id: 4, key: 'L', name: 'List'},
            {id: 5, key: 'P', name: 'Planning'},
            {id: 6, key: 'G', name: 'Grid'},
            {id: 7, key: 'G2', name: 'Grid 2 days'},
        ]
    } else {
        return [
            {id: 1, key: 'M', name: 'Mois'},
            {id: 2, key: 'W', name: 'Semaine'},
            {id: 3, key: 'D', name: 'Jour'},
            {id: 4, key: 'L', name: 'Liste'},
            {id: 5, key: 'P', name: 'Planning'},
            {id: 6, key: 'G', name: 'Grille'},
            {id: 7, key: 'G2', name: 'Grille 2 jours'},
        ]
    }
}

export function getTimeSlots(): any[] {
    return [
        {id: 1, value: '5'},
        {id: 2, value: '10'},
        {id: 3, value: '15'},
        {id: 4, value: '20'},
        {id: 5, value: '30'},
        {id: 6, value: '45'},
        {id: 7, value: '60'},
    ]
}

export function getMomentTime(timeString: string, format: string): any {

    let hour = timeString.split(':')[0];
    let min = timeString.split(':')[1];

    return moment({hour: Number(hour), minute: Number(min)}).format(format)
}

export function targetView(defaultView): string {
    switch (defaultView) {
        case 'D':
            return 'timeGridDay';
        case 'W':
            return 'timeGridWeek';
        case 'M':
            return 'dayGridMonth';
        case 'L':
            return 'listWeek';
        case 'P':
            return 'resourceTimelineDay';
        case 'G':
            return 'resourceTimeGridDay';
        case 'G2':
            return 'resourceTimeGrid2Day';
    }
}

function getWorkingDays(cd: string): number[] {
    let workingDays = [1, 2, 3, 4, 5, 6, 0];
    if (cd.length === 0) return workingDays;

    let closingDays = [];

    let days = cd.split(';');

    days.forEach(value => {
        closingDays.push(Number(value.substr(1, 1)))
    });

    return difference(workingDays, closingDays);
}

export function getCalSetting(): any {

    if (localStorage.getItem('calSetting') !== null) {
        let cs = JSON.parse(localStorage.getItem('calSetting')) as CalSet;
        return {
            defaultView: getDefaultView(cs.defaultView),
            minTimeSlot: cs.minTimeSlot,
            openingTime: cs.openingTime,
            closingTime: cs.closingTime,
            workingDays: getWorkingDays(cs.closingDays)
        }
    } else {
        return {
            defaultView: 'agendaWeek',
            minTimeSlot: 20,
            openingTime: '08:00',
            closingTime: '19:00',
            workingDays: [1, 2, 3, 4, 5, 6]
        }
    }

}

function getDefaultView(v: string): string {
    switch (v) {
        case 'W':
            return 'agendaWeek';
        case 'M':
            return 'month';
        case 'D':
            return 'agendaDay';
        default:
            return 'agendaWeek';
    }
}

export interface CalSet {
    id: number;
    defaultView: string;
    minTimeSlot: number;
    openingTime: string;
    closingTime: string;
    closingDays: string;
}

export function addDays(date: Date, days: number): Date {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function calculateDOBFromAge(age: number): Date {
    let result = new Date();
    result.setDate(result.getDate() - age * 365);
    return result;
}

export function handleProcedureCodeNullValues(value: any): any {

    return assign(value, {
        modality: value.modality || {id: ''},
        bodyRegion: value.bodyRegion || {id: ''},
        billingCode: value.billingCode || {id: ''},
        reasonForExam: value.reasonForExam || {id: ''},
        defaultPerformingPhysician: value.defaultPerformingPhysician || {id: ''}
    });
}

export function findFromArrayById(arr: any[], id: number): any {
    return arr.find(value => value.id === id);
}

export function createAppointment(patient: any, aptData: any): Appointment {

    return new Appointment(0, '', false, false,
        true,
        false,
        aptData.startTime,
        aptData.endTime,
        aptData.startDate,
        aptData.endDate,
        10,
        null,
        new Date(),
        null,
        aptData.appointmentReason.id === 0 ? null : aptData.appointmentReason.value,
        aptData.appointmentReason.id === 0 ? null : aptData.appointmentReason.id,
        'waiting_list',
        '',
        'RIS', aptData.physician.id === '' ? null : aptData.physician, null, patient);
}

export function buildProcedureAttributes(aets: any[], procedureCode: any): any {

    return {
        procedureCode: procedureCode,
        modality: procedureCode.modality,
        scheduledStationAETitle: aets[0],
        defaultTemplateModel: procedureCode.templateModel,
        scheduledProcedureStepStatus: 'SCHEDULED',
        anatomicalOrientationType: 'BIPED',
        reasonForExam: procedureCode.reasonForExam,
        scheduledProcedureStepDescription: procedureCode.code
    };
}

export function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done

    return new Blob([ab], {type: 'audio.wav'});
}
