import {DisplayMode, FormatRule, FormattingRule} from "../model";

export class ArrayUtils {
    static updateItemInArray(item, array): void {
        if (array.includes(item)) array.splice(array.indexOf(item), 1);
        else array.push(item);
    }
}

export function sumBy(prop: string, data: any[]): number {
    return data.map(it => it[prop]).reduce((a, c) => a + c, 0);
}

export const defaultWorkflowFormatting: FormattingRule[] = [{
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PATIENT_STATUS",
    primaryFormatValue: "EXAM_FINISHED",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#00a962",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-check-all",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PROCEDURE_TYPE",
    primaryFormatValue: "TDM",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: true,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#a9430f",
        backgroundColor: "#f6dbc8",
        displayMode: DisplayMode.BADGE,
        icon: "mdi-file-move",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PATIENT_CLASS",
    primaryFormatValue: "E",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#b50918",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-alarm-light",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PATIENT_STATUS",
    primaryFormatValue: "EXAM_STARTED",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#00a9a2",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-circle-slice-5",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PATIENT_STATUS",
    primaryFormatValue: "WAITING",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#ea8a00",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-emoticon-neutral",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PROCEDURE_TYPE",
    primaryFormatValue: "XSP",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: true,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#0fa982",
        backgroundColor: "#c8f6d9",
        displayMode: DisplayMode.BADGE,
        icon: "mdi-file-move",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "U.",
    primaryFormatValue: "true",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#cb1351",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-ambulance",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "#",
    primaryFormatValue: "0",
    secondaryFormatValue: "",
    formatRule: FormatRule.GREATER_THAN,
    formattingStyle: {
        id: 0,
        bold: true,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#9e093b",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-numeric-2-circle",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "REPORT_COMPLETION_STATUS",
    primaryFormatValue: "DELIVERED",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#13ab64",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-file-move",
        badge: ""
    },
    order: 0
},{
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "REPORTING_STATUS",
    primaryFormatValue: "DELIVERED",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#917384",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-file-lock",
        badge: ""
    },
    order: 0
},{
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "REPORTING_STATUS",
    primaryFormatValue: "TO_REVIEW",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#ff0000",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-file-alert",
        badge: ""
    },
    order: 0
},{
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "REPORTING_STATUS",
    primaryFormatValue: "TO_SIGN",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#ffb364",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-file-edit",
        badge: ""
    },
    order: 0
},{
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "REPORTING_STATUS",
    primaryFormatValue: "SIGNED",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#169134",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-file-check",
        badge: ""
    },
    order: 0
},{
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "REPORTING_STATUS",
    primaryFormatValue: "FINISHED",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#16a3f5",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-file",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PAYMENT_STATUS",
    primaryFormatValue: "PAID_PARTIALLY",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#f87605",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-alpha-p-circle-outline",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "REPORT_COMPLETION_STATUS",
    primaryFormatValue: "WAITING",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#136ecb",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-file-outline",
        badge: ""
    },
    order: 0
},{
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "REPORTING_STATUS",
    primaryFormatValue: "WAITING",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#136ecb",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-file-outline",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PROCEDURE_TYPE",
    primaryFormatValue: "ECHO",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: true,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#a90f57",
        backgroundColor: "#f6c8e5",
        displayMode: DisplayMode.BADGE,
        icon: "mdi-file-move",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "REPORT_COMPLETION_STATUS",
    primaryFormatValue: "READY",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#ee27cf",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-file-check",
        badge: ""
    },
    order: 0
},{
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "REPORTING_STATUS",
    primaryFormatValue: "VERIFIED",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#ca1151",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-file-certificate",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PROCEDURE_TYPE",
    primaryFormatValue: "IRM",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: true,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#0fa9a9",
        backgroundColor: "#c8eef6",
        displayMode: DisplayMode.BADGE,
        icon: "mdi-file-move",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PAYMENT_STATUS",
    primaryFormatValue: "EXEMPT",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#767674",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-alpha-d-circle",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "REPORT_COMPLETION_STATUS",
    primaryFormatValue: "INPROGRESS",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#cb1378",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-file-clock",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "REPORTING_STATUS",
    primaryFormatValue: "IN_PROGRESS",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#1aa4c4",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-file-clock",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PROCEDURE_TYPE",
    primaryFormatValue: "TDM",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: true,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#a90f77",
        backgroundColor: "#f6c8e3",
        displayMode: DisplayMode.BADGE,
        icon: "mdi-file-move",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PAYMENT_STATUS",
    primaryFormatValue: "PAID",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#098416",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-alpha-p-circle",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "BILLED",
    primaryFormatValue: "true",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#c51e56",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-check-decagram",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PATIENT_CLASS",
    primaryFormatValue: "O",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#0b5880",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-account-arrow-left",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PROCEDURE_TYPE",
    primaryFormatValue: "XRAY",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: true,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#247650",
        backgroundColor: "#c8f6cd",
        displayMode: DisplayMode.BADGE,
        icon: "mdi-file-move",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PROCEDURE_TYPE",
    primaryFormatValue: "ECG",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: true,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#6c0fa9",
        backgroundColor: "#e6c8f6",
        displayMode: DisplayMode.BADGE,
        icon: "mdi-file-move",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PROCEDURE_TYPE",
    primaryFormatValue: "RX",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: true,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#0f6ea9",
        backgroundColor: "#c8e3f6",
        displayMode: DisplayMode.BADGE,
        icon: "mdi-file-move",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PATIENT_STATUS",
    primaryFormatValue: "NOT_YET",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#ea9400",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-account-alert-outline",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PATIENT_CLASS",
    primaryFormatValue: "I",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#0b8018",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-account-lock",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PATIENT_STATUS",
    primaryFormatValue: "BEFORE_TIME",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#8400ea",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-account-question",
        badge: ""
    },
    order: 0
}, {
    id: 0,
    appliedTo: "COLUMN",
    targetColumn: "PAYMENT_STATUS",
    primaryFormatValue: "NOT_PAID",
    secondaryFormatValue: "",
    formatRule: FormatRule.TEXT_IS_EXACTLY,
    formattingStyle: {
        id: 0,
        bold: false,
        italic: false,
        underlined: false,
        strikethrough: false,
        color: "#fe002b",
        backgroundColor: "#ffffff",
        displayMode: DisplayMode.ICON,
        icon: "mdi-alpha-n-circle",
        badge: ""
    },
    order: 0
}
];