import {Component, Inject, OnInit} from '@angular/core';
import {FileElement} from '../file-explorer';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-fire-pdf',
    templateUrl: './fire-pdf.component.html',
    styleUrls: ['./fire-pdf.component.scss']
})
export class FirePdfComponent implements OnInit {
    public file: string;

    page: number = 1;
    totalPages: number;
    isLoaded: boolean = false;

    afterLoadComplete(pdfData: any) {
        this.totalPages = pdfData.numPages;
        this.isLoaded = true;
    }

    nextPage() {
        this.page++;
    }

    prevPage() {
        this.page--;
    }

    constructor(@Inject(MAT_DIALOG_DATA) public element: FileElement) {
        this.file = this.element.fileType === 'ENC_PDF' ? this.element.url : `/upload-dir/${this.element.uuid}.${this.element.fileType.toLowerCase()}`
    }

    ngOnInit() {
    }
}
