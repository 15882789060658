export class ViewQuery {
    constructor(public startDate: any = new Date(),
                public endDate: any = new Date(),
                public period: 'YESTERDAY' | 'TODAY' | 'ONE_WEEK' | 'ONE_MONTH' | 'SIX_MONTHS' | 'ONE_YEAR' | 'OT' = 'TODAY',
                public filter: string = '',
                public filter2: string = 'BOX') {
    }

    static create(startDate: any, endDate?: any, period?: 'YESTERDAY' | 'TODAY' | 'ONE_WEEK' | 'ONE_MONTH' | 'SIX_MONTHS' | 'ONE_YEAR' | 'OT', filter?: string) {
        return new ViewQuery(startDate, endDate, period, filter)
    }
}
