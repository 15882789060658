<div fxLayout="row wrap" fxLayoutGap="12px" fxLayoutAlign="start" *ngIf="state; else noData" style="height: 76vh">
    <div class="payment-table" fxFlex="400px">
        <div class="payment-caisse">
            <div class="header">Caisse</div>
            <div class="payment-grid">
                <div>Total du jours</div>
                <div [style.textAlign]="'right'">{{formatNumeral(state.daily_paid_amount)}}</div>
                <div>Total antérieurs</div>
                <div [style.textAlign]="'right'">{{formatNumeral(state.anterior_paid_amount)}}</div>
                <div class="border-top">Solde Final</div>
                <div [style.textAlign]="'right'" class="border-top feat-number"
                     style="font-weight: bold;">{{formatNumeral(finalSold)}}</div>
            </div>
        </div>

        <div class="payment-caisse">
            <div class="header">Valeurs</div>
            <div class="payment-grid">
                <div>Valeur</div>
                <div [style.textAlign]="'right'">{{formatNumeral(state.daily_taxed_amount) }}</div>
                <div>Facturé</div>
                <div [style.textAlign]="'right'">{{formatNumeral(state.daily_paid_amount) }}</div>
                <div>Réglé</div>
                <div [style.textAlign]="'right'">{{formatNumeral(state.daily_paid_amount)}}</div>
                <div>Impayé</div>
                <div [style.textAlign]="'right'">{{formatNumeral(notPaid) }}</div>
            </div>
            <div class="footer">Soit: {{state.nb_patient_visits}} patient(s)</div>
        </div>

        <div class="payment-caisse">
            <div class="header">Réglements par type de prise en charge</div>
            <div class="payment-grid">
                <div>{{'PATIENT' | translate}}</div>
                <div fxLayout="row" class="calcul" fxLayoutAlign="space-between">
                    <div class="count" fxFlex>{{state.nb_patient_visits || 0}}</div>
                    <div fxFlex>{{formatNumeral(totalPatientAmount) }}</div>
                </div>
            </div>
            <div class="payment-grid">
                <div>{{'THIRD_PAYER' | translate}}</div>
                <div fxLayout="row" class="calcul" fxLayoutAlign="space-between">
                    <div class="count" fxFlex>{{state.nb_organization_visits || 0}}</div>
                    <div fxFlex>0.00</div>
                </div>
            </div>
            <div class="payment-grid">
                <div>{{'OTHER' | translate}}</div>
                <div fxLayout="row" class="calcul" fxLayoutAlign="space-between">
                    <div class="count" fxFlex>0</div>
                    <div fxFlex>0.00</div>
                </div>
            </div>

            <div class="payment-grid border-top">
                <div>TOTAL</div>
                <div class="calcul" fxLayout="row" fxLayoutAlign="space-between" style="padding: 0!important;">
                    <div class="count feat-number" fxFlex>{{state.nb_patient_visits || 0}}</div>
                    <div class="feat-number" fxFlex>{{ formatNumeral(totalPatientAmount) }}</div>
                </div>
            </div>
        </div>
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
        <div class="payment-caisse auto">
            <div class="header">Réglements par mode de paiement</div>

            <div class="payment-grid col-4" style="line-height: 28px; font-weight: bold;">
                <div></div>
                <div class="calcul title-h">Réglts. Immédiats</div>
                <div class="calcul title-h">Dossiers Antérieurs</div>
                <div class="calcul title-h">CUMUL des Mvts</div>
            </div>

            <div class="payment-grid col-4">
                <div>Espèce</div>
                <div class="calcul" fxLayout="row" fxLayoutAlign="space-between">
                    <div class="count" fxFlex>{{state.cash_daily_paid_amount_nbr || 0}}</div>
                    <div fxFlex>{{ formatNumeral(state.cash_daily_paid_amount) }}</div>
                </div>
                <div class="calcul" fxLayout="row" fxLayoutAlign="space-between">
                    <div class="count" fxFlex>{{state.cash_anterior_paid_amount_nbr || 0}}</div>
                    <div fxFlex>{{ formatNumeral(state.cash_anterior_paid_amount) }}</div>
                </div>
                <div class="calcul" fxLayout="row" fxLayoutAlign="space-between">
                    <div class="count" fxFlex>{{cashDayAntNbr || 0}}</div>
                    <div fxFlex>{{ formatNumeral(cashDayAntAmount) }}</div>
                </div>
            </div>

            <div class="payment-grid col-4">
                <div>Chèque</div>
                <div class="calcul" fxLayout="row" fxLayoutAlign="space-between">
                    <div class="count" fxFlex>{{state.check_daily_paid_amount_nbr || 0}}</div>
                    <div fxFlex>{{ formatNumeral(state.check_daily_paid_amount) }}</div>
                </div>
                <div class="calcul" fxLayout="row" fxLayoutAlign="space-between">
                    <div class="count" fxFlex>{{state.check_anterior_paid_amount_nbr || 0}}</div>
                    <div fxFlex>{{formatNumeral(state.check_anterior_paid_amount) }}</div>
                </div>
                <div class="calcul" fxLayout="row" fxLayoutAlign="space-between">
                    <div class="count" fxFlex>{{checkDayAntNbr || 0}}</div>
                    <div fxFlex>{{formatNumeral(checkDayAntAmount) }}</div>
                </div>
            </div>

            <div class="payment-grid col-4">
                <div>TPE/Carte crédit</div>
                <div class="calcul" fxLayout="row" fxLayoutAlign="space-between">
                    <div class="count" fxFlex>{{state.credit_daily_card_paid_amount_nbr || 0}}</div>
                    <div fxFlex>{{formatNumeral(state.credit_daily_card_paid_amount) }}</div>
                </div>
                <div class="calcul" fxLayout="row" fxLayoutAlign="space-between">
                    <div class="count" fxFlex>{{state.credit_card_anterior_paid_amount_nbr || 0}}</div>
                    <div fxFlex>{{formatNumeral(state.credit_card_anterior_paid_amount) }}</div>
                </div>
                <div class="calcul" fxLayout="row" fxLayoutAlign="space-between">
                    <div class="count" fxFlex>{{cardDayAntNbr || 0}}</div>
                    <div fxFlex>{{formatNumeral(cardDayAntAmount) }}</div>
                </div>
            </div>

            <div class="payment-grid col-4 border-top">
                <div style="grid-column: 1 / 4; text-align: right">Total (Immédiats + Antérieurs) :</div>
                <div class="calcul feat-number" style="font-weight: bold;">{{formatNumeral(totalMode) }}</div>
            </div>
        </div>

        <div class="payment-caisse auto">
            <div class="header">Trésorerie</div>

            <div class="payment-grid col-4" style="line-height: 28px; font-weight: bold;">
                <div>Chiffre d'affaire du centre</div>
                <div class="feat-number"
                     style="grid-column: 2 / 5; text-align: center">{{formatNumeral(turnover) }}</div>
                <div>Dossiers antérieurs encaissés</div>
                <div class="calcul border-top"
                     style="border-left: none">{{formatNumeral(state.anterior_paid_amount) }}</div>
                <div class="calcul border-top" style="border-left: none">Soit</div>
                <div class="calcul border-top" style="border-left: none">{{state.nb_anterior_visits}} patient(s)</div>
            </div>
        </div>
    </div>
</div>

<ng-template #noData>
    <div class="no-data" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
        <mat-icon class="alert-icon-gradient" fontIcon="mdi-alert" fontSet="mdi" style="font-size: 48px !important;"></mat-icon>
        <span>{{'NO_DATA' | translate}}</span>
    </div>
</ng-template>

<button [class.printState-icon-gradient]="state" (click)="printState()" [disabled]="!state" [matTooltip]="'PRINT_DAY_STATE' | translate" class="print-btn"
        mat-mini-fab>
    <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
</button>

<button [class.printAntState-icon-gradient]="state" (click)="printAnteriorState()" [disabled]="!state" [matTooltip]="'PRINT_ANTERIOR_STATE' | translate"
        class="print-btn left"
        color="primary" mat-mini-fab>
    <mat-icon fontSet="mdi" fontIcon="mdi-cloud-print"></mat-icon>
</button>

<button [class.printPatient-icon-gradient]="state" class="print-btn left-2" mat-mini-fab [matTooltip]="'STATE_PER_PATIENT' | translate" (click)="exportToPdf()">
    <mat-icon fontIcon="mdi-cloud-download" fontSet="mdi"></mat-icon>
</button>