<div *ngIf="physician; else noPhysician" fxLayout="column" style="height: calc(100vh - 180px);">
    <div *ngIf="exams && !loading; else loader" fxLayout="column" style="height: calc(100vh - 120px);">
        <div class="feat-card-lg" fxLayout="row nowrap" fxLayoutAlign="space-evenly center" fxLayoutGap="12px">
            <div style="transform: scale(1.6)">
                <div class="header">Total ({{currencyFormat}})</div>
                <div class="body feat-text">{{formatNumeral(calculateTotalAmount(exams))}}</div>
            </div>

            <div fxLayout="column" fxLayoutGap="16px">
                <div>
                    <div class="header">Nombre de jours travaillés</div>
                    <div class="body">{{days}}</div>
                </div>
                <div>
                    <div class="header">Moyenne journalière ({{currencyFormat}})</div>
                    <div class="body">{{formatNumeral(calculateDailyAvg(exams, days))}}</div>
                </div>
            </div>

            <div fxLayout="column" fxLayoutGap="16px">
                <div>
                    <div class="header">Nombre des examens</div>
                    <div class="body">{{totalExams}}</div>
                </div>
                <div>
                    <div class="header">Moyenne par examen ({{currencyFormat}})</div>
                    <div class="body">{{formatNumeral(calculateExamsAvg(exams, totalExams))}}</div>
                </div>
            </div>
        </div>

        <div class="grid-exams" fxFlex style="overflow: auto;">
            <div class="cell day" fxLayout="row nowrap" style="font-weight: bold;">
                <div [style.paddingLeft.px]="24" [style.textAlign]="'left'" fxFlex>Date</div>
                <div [style.textAlign]="'left'" fxFlex>Examen</div>
                <div *ngIf="feeType === 'EXAM'" fxFlex>Tarif</div>
                <div fxFlex>Nbre</div>
                <div *ngIf="feeType === 'EXAM'" fxFlex>Total({{currencyFormat}})</div>
            </div>
            <div *ngFor="let e of exams">
                <div class="cell day" fxLayout="row nowrap">
                    <div [style.paddingLeft.px]="24" [style.textAlign]="'left'"
                         fxFlex>{{e.date | date: 'dd/MM/yyyy'}}</div>
                    <div [style.textAlign]="'left'" fxFlex>{{e.exam}}</div>
                    <div *ngIf="feeType === 'EXAM'" fxFlex>{{formatNumeral(e.tariff)}}</div>
                    <div fxFlex>{{e.count}}</div>
                    <div *ngIf="feeType === 'EXAM'" fxFlex>{{formatNumeral(e.total)}}</div>
                </div>
            </div>
        </div>

    </div>

    <ng-template #loader>
        <div fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
            <mat-spinner [diameter]="42" [strokeWidth]="2"></mat-spinner>
            <div style="font-size: 16px;font-weight: bold;">Chargement...</div>
        </div>
    </ng-template>

</div>

<ng-template #noPhysician>
    <div fxLayout="row" fxLayoutAlign="center center" style="font-style: italic;height: 80vh">
        <h3> {{'PLEASE_SELECT_PHYSICIAN' | translate}}</h3>
    </div>
</ng-template>
