<div fxLayout="column" fxLayoutGap="12px" fxLayoutAlign="start center">

    <div class="pdf-container">
        <pdf-viewer (after-load-complete)="afterLoadComplete($event)"
                    [fit-to-page]="true"
                    [page]="page"
                    [show-all]="false"
                    [src]="file"></pdf-viewer>
    </div>


    <!--actions-->
    <div fxLayout="row" fxLayoutGap="4px" class="actions">
        <button (click)="prevPage()" [disabled]="page === 1" mat-icon-button>
            <mat-icon fontIcon="mdi-arrow-left" fontSet="mdi"></mat-icon>
        </button>
        <span style="font-size: 12px; font-weight: bold;line-height: 28px;">{{ page }} / {{ totalPages }}</span>
        <button (click)="nextPage()" [disabled]="page === totalPages" mat-icon-button>
            <mat-icon fontIcon="mdi-arrow-right" fontSet="mdi"></mat-icon>
        </button>
    </div>
</div>
