<mat-toolbar class="dialog-toolbar">
    <mat-icon fontIcon="mdi-pencil-box-outline" fontSet="mdi"></mat-icon>
    <h3 mat-dialog-title>{{'EDIT_USER' | translate}}</h3>
    <span fxFlex></span>
    <button mat-dialog-close mat-icon-button tabindex="-1">
        <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <div [formGroup]="form">

        <div fxLayout="row" fxLayoutGap="4px">

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'CLINICALID' | translate}}</mat-label>
                <input formControlName="clinicalID" matInput placeholder="{{'CLINICALID' | translate}}" tabindex="-1">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'LASTNAME' | translate}}</mat-label>
                <input autocomplete="off" formControlName="lastName" matInput placeholder="{{'LASTNAME' | translate}}"
                       required>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'FIRSTNAME' | translate}}</mat-label>
                <input autocomplete="off" formControlName="firstName" matInput placeholder="{{'FIRSTNAME' | translate}}"
                       required>
            </mat-form-field>

        </div>

        <div fxLayout="row" fxLayoutGap="4px">

            <div fxFlex>
                <mat-form-field appearance="standard" style="width: 100%">
                    <mat-label>{{'USERNAME' | translate}}</mat-label>
                    <input autocomplete="off" formControlName="username" matInput
                           placeholder="{{'USERNAME' | translate}}" required>
                </mat-form-field>
                <span *ngIf="exists | async" class="ft-error">{{'USERNAME_EXIST' | translate}}</span>
            </div>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'PASSWORD' | translate}}</mat-label>
                <input autocomplete="off" formControlName="password" matInput placeholder="{{'PASSWORD' | translate}}"
                       type="password">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'CONFIRMPASSWORD' | translate}}</mat-label>
                <input formControlName="confirmPassword" matInput placeholder="{{'CONFIRMPASSWORD' | translate}}"
                       type="password">
            </mat-form-field>

        </div>


        <div class="space-top" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
            <div fxFlex="100px">
                {{'COLOR' | translate}}: <span [(colorPicker)]="color" [style.background]="color"
                                               class="color-area">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </div>
        </div>


    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button color="warn" mat-dialog-close mat-raised-button>{{'CANCEL' | translate}}</button>
    <button (click)="onSave(form.value)" [disabled]="!form.valid" color="primary"
            mat-raised-button>{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>
