import {BehaviorSubject, merge as observableMerge, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UnavailabilityEditComponent} from './unavailability-edit/unavailability-edit.component';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {SettingService} from '../setting.service';
import {UNV_HEADER_COLS, UNV_TABLE_CONF} from './table-conf';


import {get, map as _map, reduce} from 'lodash';
import {DataSource} from '@angular/cdk/collections';
import {deleteItemFromArray} from '../../shared';

@Component({
    selector: 'app-unavailability',
    templateUrl: './unavailability.component.html',
    styleUrls: ['./unavailability.component.scss']
})
export class UnavailabilityComponent implements OnInit {

    cols: any[];
    displayedColumns = [];

    dataSource: UnavailabilityDataSource | null;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private settingService: SettingService, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public _data: any) {

        this.settingService.getResourceUnavailabilities(_data.id).subscribe(data => {

            let items = _map(data, tsk => {
                return reduce(this.displayedColumns, (obj, field) => {
                    obj[field.label] = get(tsk, field.value, field.defaultValue);
                    obj['unavailabilityReason'] = get(tsk, 'unavailabilityReason');
                    obj['allDay'] = get(tsk, 'allDay', false);
                        return obj
                    }, {});
                });

                this.dataSource.dataChange.next(items);
            }
        );
    }

    editUnavailability(unavailability) {
        const dialogRef = this.dialog.open(UnavailabilityEditComponent);
        const instance = dialogRef.componentInstance;
        instance.selectedUnavailability = unavailability;
        instance.resourceID = this._data.id;

        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                if (unavailability.id) deleteItemFromArray(this.dataSource.data, unavailability);
                this.dataSource.data.push(res);
                this.dataSource.dataChange.next(this.dataSource.data);
            }
        });
    }

    deleteUnavailability(unavailability) {
        this.settingService.deleteUnavailability(unavailability.id).subscribe(res => {
            deleteItemFromArray(this.dataSource.data, unavailability);
            this.dataSource.dataChange.next(this.dataSource.data);
        });
    }

    ngOnInit() {
        this.displayedColumns = UNV_TABLE_CONF;
        this.cols = UNV_HEADER_COLS;

        this.dataSource = new UnavailabilityDataSource(this.paginator, this.sort);
    }

}


export class UnavailabilityDataSource extends DataSource<any> {

    filteredData: UnavailabilityItem[] = [];
    renderedData: UnavailabilityItem[] = [];

    /** Stream that emits whenever the data has been modified. */
    dataChange: BehaviorSubject<UnavailabilityItem[]> = new BehaviorSubject<UnavailabilityItem[]>([]);

    constructor(private _paginator: MatPaginator, private _sort: MatSort) {
        super();
    }

    get data(): UnavailabilityItem[] {
        return this.dataChange.value;
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<UnavailabilityItem[]> {
        const displayDataChanges = [
            this.dataChange,
            this._paginator.page,
            this._sort.sortChange,
        ];

        return observableMerge(...displayDataChanges).pipe(map(() => {


            // Filter data
            this.filteredData = this.data.slice().filter((item: UnavailabilityItem) => {
                return true;
            });

            // Sort filtered data
            const sortedData = this.sortData(this.filteredData.slice());

            // Grab the page's slice of the filtered sorted data.
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
            return this.renderedData;
        }));
    }

    disconnect() {
    }

    /** Returns a sorted copy of the database data. */
    sortData(data: UnavailabilityItem[]): UnavailabilityItem[] {
        if (!this._sort.active || this._sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._sort.active) {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'startTime':
                    [propertyA, propertyB] = [a.startTime, b.startTime];
                    break;
                case 'endDate':
                    [propertyA, propertyB] = [a.endDate, b.endDate];
                    break;
                case 'startDate':
                    [propertyA, propertyB] = [a.startDate, b.startDate];
                    break;
                case 'endTime':
                    [propertyA, propertyB] = [a.endTime, b.endTime];
                    break;
                case 'unavailableReason':
                    [propertyA, propertyB] = [a.unavailableReason, b.unavailableReason];
                    break;
                case 'otherReason':
                    [propertyA, propertyB] = [a.otherReason, b.otherReason];
                    break;
            }

            let valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            let valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }


}


export interface UnavailabilityItem {
    id: string;
    startTime: string;
    endTime: string;
    startDate: string;
    endDate: string;
    description: string;
    unavailableReason: string;
    otherReason: string;
}
