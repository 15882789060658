<mat-toolbar color="primary">
    <mat-icon fontIcon="mdi-test-tube" fontSet="mdi"></mat-icon>
    {{'CREATE_PRESCRIPTION' | translate}}
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close>
        <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
    </button>
</mat-toolbar>
<div class="prescription" fxLayout="column" id="printable">

    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="6px">
        <mat-form-field appearance="outline">
            <mat-label>{{'PATIENT_NAME' | translate}}</mat-label>
            <input matInput [placeholder]="'PATIENT_NAME' | translate"  [ngModel]="data.patient.patientName">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{'PHYSICIAN' | translate}}</mat-label>
            <input matInput [placeholder]="'PHYSICIAN' | translate" [ngModel]="data.physician.physician">
        </mat-form-field>
    </div>


    <div class="tree-container">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
                              (change)="prescriptionItemSelectionToggle(node)">{{node.name}}
                </mat-checkbox>
            </mat-tree-node>

            <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <mat-form-field>
                    <input matInput #itemValue [placeholder]="'NEW_ITEM' | translate">
                </mat-form-field>
                <button mat-button (click)="saveNode(node, itemValue.value)">{{'SAVE' | translate}}</button>
            </mat-tree-node>

            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
                    <mat-icon class="mat-icon-rtl-mirror" fontSet="mdi"
                              [fontIcon]="treeControl.isExpanded(node) ? 'mdi-chevron-down': 'mdi-chevron-right'"></mat-icon>
                </button>
                <mat-checkbox [checked]="descendantsAllSelected(node)"
                              [indeterminate]="descendantsPartiallySelected(node)"
                              (change)="prescriptionItemSelectionToggle(node)">{{node.name}}
                </mat-checkbox>
                <button mat-icon-button (click)="addNewItem(node)">
                    <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
                </button>
            </mat-tree-node>
        </mat-tree>
    </div>
</div>

<mat-dialog-actions align="end">
    <button color="accent" mat-dialog-close mat-raised-button>{{'CLOSE' | translate}}</button>
    <button (click)="generatePrescription()" color="primary" mat-raised-button>{{'CREATE_PRESCRIPTION' | translate}}</button>
</mat-dialog-actions>
