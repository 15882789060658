import {BehaviorSubject, fromEvent as observableFromEvent, merge, of as observableOf} from 'rxjs';

import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {SharedService} from '../../shared';
import {MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {PCODE_HEADER_COLS, PCODE_TABLE_CONF} from './table-conf';
import {get, map as _map, reduce} from 'lodash';
import {tableAnimation} from '../../animations';
import {SettingService} from '../../setting/setting.service';


@Component({
    selector: 'app-procedure-code-search',
    templateUrl: './procedure-code-search.component.html',
    styleUrls: ['./procedure-code-search.component.scss'],
    animations: [tableAnimation]
})
export class ProcedureCodeSearchComponent implements AfterViewInit {

    cols: any[];
    displayedColumns = [];

    dataSource = new MatTableDataSource();
    @ViewChild('filter', { static: true }) filter: ElementRef;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    filterChange = new BehaviorSubject('');
    trackById = (index: number, item: any): string => item.id;

    constructor(private service: SettingService,
                private sharedService: SharedService,
                public dialogRef: MatDialogRef<ProcedureCodeSearchComponent>) {

        this.displayedColumns = PCODE_TABLE_CONF;
        this.cols = PCODE_HEADER_COLS;
    }

    selectCode = (row) => {if (row) this.dialogRef.close(row)};

    ngAfterViewInit() {
        observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
            debounceTime(400),
            distinctUntilChanged(),)
            .subscribe(() => {
                if (!this.dataSource) return;
                this.paginator.pageIndex = 0;
                this.filterChange.next(this.filter.nativeElement.value);
            });

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        let observedFilters = [
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.filterChange.asObservable()
        ];

        merge(...observedFilters)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.sharedService.getPaginatedProcedureCodes(this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        this.filterChange.getValue());
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data['totalElements'];

                    return _map(data['content'], item => {
                        return reduce(this.displayedColumns, (obj, field) => {
                            obj[field.label] = get(item, field.value, field.defaultValue);
                            obj['billingCode'] = get(item, 'billingCode');
                            obj['reasonForExam'] = get(item, 'reasonForExam');
                            obj['defaultPerformingPhysician'] = get(item, 'defaultPerformingPhysician');
                            obj['templateModel'] = get(item, 'templateModel');
                            obj['modality'] = get(item, 'modality');
                            return obj
                        }, {});
                    });
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.dataSource.data = data);
    }
}
