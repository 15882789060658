<div fxLayout="column" fxLayoutAlign="space-between" fxFill>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="player-bar">
        <mat-icon fontIcon="mdi-play" fontSet="mdi"></mat-icon>
        <h4>{{'AUDIO_PLAY' | translate}}</h4>
        <span fxFlex></span>
        <button mat-icon-button mat-dialog-close>
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
        </button>
    </div>

    <div fxFlex fxLayout="row" fxLayoutAlign="space-between">

        <div fxFlex class="capture" fxLayout="column" fxLayoutAlign="center center">
            <div class="current-audio">{{current.name}}</div>
        </div>

        <div fxFlex class="audio-list" fxLayout="column" fxLayoutAlign="start stretch">
            <div class="list-title">
                <mat-icon fontSet="mdi" fontIcon="mdi-playlist-play"></mat-icon>
                {{'PLAYLIST' | translate}}
            </div>
            <div *ngFor="let audio of audioFiles" class="audio-item" (click)="current = audio"
                 [ngClass]="{'selected': current === audio}">{{audio.name}}
            </div>
        </div>
    </div>
    <div>
        <audio class="audio-player" [src]="current.url" autoplay controls preload="auto"></audio>
    </div>

</div>