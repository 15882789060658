<div class="main-container" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-toolbar class="toolbar" fxFlex="46px" tabindex="-1">

        <button class="logo" mat-button tabindex="-1">
            <img alt="Fire(R|CV|H|L|D)IS" class="app-angular-logo" src="../assets/images/{{logo}}">
        </button>

        <a (click)="setTitle('Admission');" *ngIf="profile && profile.schedulerModule"
           mat-button ngClass.lt-md="small-btn"
           routerLink="/scheduler" routerLinkActive="active"
           tabindex="-1">
            <mat-icon [ngStyle.gt-sm]="{display: 'none'}" fontIcon="mdi-calendar-clock"
                      fontSet="mdi"></mat-icon>
            <span class="menu-btn" fxHide.sm fxHide.xs>{{'SCHEDULE.NAME' | translate}}</span>
        </a>

        <a (click)="setTitle('Workflow')" *ngIf="profile && profile.workflowModule" mat-button ngClass.lt-md="small-btn"
           routerLink="/workflow" routerLinkActive="active"
           tabindex="-1">
            <mat-icon [ngStyle.gt-sm]="{display: 'none'}" fontIcon="mdi-clipboard-flow"
                      fontSet="mdi"></mat-icon>
            <span class="menu-btn" fxHide.sm fxHide.xs>{{'WORKFLOW.NAME' | translate}}</span>
        </a>

        <a (click)="setTitle('Reporting')" *ngIf="profile && profile.reportingModule" mat-button
           ngClass.lt-md="small-btn"
           routerLink="/reporting" routerLinkActive="active"
           tabindex="-1">
            <mat-icon [ngStyle.gt-sm]="{display: 'none'}" fontIcon="mdi-book-open" fontSet="mdi"></mat-icon>
            <span class="menu-btn" fxHide.sm fxHide.xs>{{'REPORTING.NAME' | translate}}</span>
        </a>

        <a (click)="setTitle('Patients')" *ngIf="profile && profile.patientModule" mat-button ngClass.lt-md="small-btn"
           routerLink="/patients" routerLinkActive="active"
           tabindex="-1">
            <mat-icon [ngStyle.gt-sm]="{display: 'none'}" fontIcon="mdi-contacts" fontSet="mdi"></mat-icon>
            <span class="menu-btn" fxHide.sm fxHide.xs>{{'PATIENTS' | translate}}</span>
        </a>

        <a (click)="setTitle('Statistic')" *ngIf="profile && profile.statisticModule" mat-button
           ngClass.lt-md="small-btn"
           routerLink="/statistic" routerLinkActive="active"
           tabindex="-1">
            <mat-icon [ngStyle.gt-sm]="{display: 'none'}" fontIcon="mdi-chart-line" fontSet="mdi"></mat-icon>
            <span class="menu-btn" fxHide.sm fxHide.xs>{{'STATISTIC' | translate}}</span>
        </a>

        <span class="fire-spacer"></span>

        <div class="username" fxHide.sm fxHide.xs>
            <span><b>{{'HELLO' | translate}}</b> {{userName}}</span>
        </div>

        <a (click)="setTitle('Logs')" *ngIf="profile && (profile.loggingModule)" mat-icon-button
           matTooltip="{{'LOGS' | translate}}"
           routerLink="/logs" routerLinkActive="active"
           style="line-height: 40px;" tabindex="-1">
            <mat-icon class="logs-icon" fontIcon="mdi-database-clock" fontSet="mdi"></mat-icon>
        </a>

        <button (click)="showAppInfo()" mat-icon-button matTooltip="{{'APP_INFO' | translate}}" tabindex="-1">
            <mat-icon class="about-icon" fontIcon="mdi-information" fontSet="mdi" style="color: #00BCD4;"></mat-icon>
        </button>

        <a (click)="setTitle('Messaging');totalNotifs = null" *ngIf="profile" mat-icon-button
           matTooltip="{{'MESSAGING' | translate}}"
           routerLink="/messaging" routerLinkActive="active"
           style="line-height: 40px;" tabindex="-1">
            <mat-icon class="chat-icon" [matBadge]="totalNotifs" fontIcon="mdi-forum" fontSet="mdi" matBadgeColor="warn"
                      matBadgeSize="small"></mat-icon>
        </a>


        <button (click)="toggleFullScreen()" mat-icon-button matTooltip="{{'TOGGLE_FULLSCREEN' | translate}}"
                tabindex="-1">
            <mat-icon fontIcon="{{fullScreen ? 'mdi-fullscreen-exit':'mdi-fullscreen'}}"
                      fontSet="mdi"></mat-icon>
        </button>

        <button [matTooltip]="'THEME' | translate" [matMenuTriggerFor]="themeMenu" [color]="currentTheme !== 'normal' ? 'accent': 'primary'"
                mat-icon-button tabindex="-1">
            <mat-icon class="theme-icon" fontIcon="mdi-brightness-4" fontSet="mdi"></mat-icon>
        </button>

        <mat-menu #themeMenu="matMenu">
            <button (click)="toggleGlassyTheme('normal')" mat-menu-item><mat-icon [ngStyle]="{background: 'linear-gradient(45deg, cyan, white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}" fontIcon="mdi-circle" fontSet="mdi"></mat-icon> {{'BASIC_THEME' | translate}}</button>
<!--            <button (click)="toggleGlassyTheme('basic-dark')" mat-menu-item><mat-icon [ngStyle]="{background: 'linear-gradient(45deg, black, white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}" fontIcon="mdi-circle" fontSet="mdi"></mat-icon> {{'BASIC_DARK' | translate}}</button>-->
            <button (click)="toggleGlassyTheme('glassy-light')" mat-menu-item><mat-icon [ngStyle]="{background: 'linear-gradient(45deg, black, orange, white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}" class="light-theme-icon" fontIcon="mdi-circle" fontSet="mdi"></mat-icon> {{'GLASSY_LIGHT_THEME' | translate}}</button>
            <button (click)="toggleGlassyTheme('glassy-dark')" mat-menu-item><mat-icon [ngStyle]="{background: 'linear-gradient(45deg, black, purple, white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}" class="dark-theme-icon" fontIcon="mdi-circle" fontSet="mdi"></mat-icon> {{'GLASSY_DARK_THEME' | translate}}</button>
        </mat-menu>

        <a (click)="setTitle('Settings')" *ngIf="profile && (profile.settingModule)" mat-icon-button
           matTooltip="{{'SETTINGS' | translate}}"
           routerLink="/setting" style="line-height: 40px;"
           routerLinkActive="active" tabindex="-1">
            <mat-icon class="setting-icon" fontIcon="mdi-cog" fontSet="mdi"></mat-icon>
        </a>

        <button (click)="updateUser()" mat-icon-button matTooltip="{{'PROFILE' | translate}}" tabindex="-1">
            <mat-icon class="user-setting-icon" fontIcon="mdi-account-cog" fontSet="mdi"></mat-icon>
        </button>

        <button (click)="logout()" class="logout" mat-icon-button matTooltip="{{'LOGOUT' | translate}}"
                tabindex="-1">
            <mat-icon class="logout-icon" fontIcon="mdi-power" fontSet="mdi"
                      style="color: #f44336;"></mat-icon>
        </button>

    </mat-toolbar>

    <div class="container" fxFlex="calc(100vh - 58px)">
        <router-outlet></router-outlet>
    </div>

    <audio #sound_player class="sound-player" src="assets/newmessage_sound.mp3"></audio>
</div>

<div class="circle1"></div>
<div class="circle2"></div>
<div class="circle3"></div>
<div class="circle4"></div>
<div class="circle5"></div>
<div class="circle6"></div>
<div class="circle7"></div>


