<div class="mat-table" fxLayout="column" style="min-height: calc(100vh - 204px); border: 1px solid #E0E0E0;">

    <mat-table [dataSource]="dataSource" class="fire-table expandable-rows-table" [@state]="dataSource.data.length"
               multiTemplateDataRows
               [trackBy]="trackById"
               matSort
               [matSortActive]="'referringPhysician'"
               [matSortDirection]="'asc'"
               matSortDisableClear>

        <!-- Nested row-->
        <ng-container matColumnDef="expandedRow">
            <mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplay.length">
                <div class="example-element-detail"
                     [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'">
                    <ng-container *ngIf="expandedElement">
                        <div fxLayout="row nowrap" fxLayoutAlign="start stretch" fxLayoutGap="8px" class="n-table">

                            <div class="patients-table" fxFlex="75%">
                                <div fxLayout="row nowrap" class="header">
                                    <div fxFlex="85%">{{'PATIENTS' | translate}}</div>
                                    <div fxFlex>{{'COUNT' | translate}}</div>
                                </div>
                                <div fxLayout="row nowrap" class="body">
                                    <div fxFlex="85%" class="ft-grid">
                                        <div *ngFor="let p of row['patients']"><span>{{p}}</span></div>
                                    </div>
                                    <div fxFlex>{{row['patients'].length}}</div>
                                </div>
                            </div>

                            <div class="exams-table" fxFlex>
                                <div fxLayout="row nowrap" class="header">
                                    <div fxFlex="40%">{{'EXAM_TYPES' | translate}}</div>
                                    <div fxFlex>{{'COUNT' | translate}}</div>
                                    <div fxFlex="40%" style="text-align: right">{{'TOTAL_FROM_PHYSICIAN' | translate}}</div>
                                </div>
                                <div class="body">
                                    <div fxLayout="row nowrap" *ngFor="let exam of getExams(row)">
                                        <div fxFlex="40%">
                                            <span>{{exam.exam}}</span>
                                        </div>
                                        <div fxFlex>{{exam.count}}</div>
                                        <div fxFlex="40%" style="text-align: right">{{formatNumeral(exam.totalPaid)}}</div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </ng-container>
                </div>
            </mat-cell>
        </ng-container>

        <!--Table-->
        <ng-container *ngFor="let col of displayedColumns" cdkColumnDef="{{col.label}}" [class.hidden]="col.hidden">

            <mat-header-cell *cdkHeaderCellDef class="fire-header-cell" mat-sort-header="{{col.value}}"
                             [disabled]="!col['sortable']">
                {{col.header | translate}}
            </mat-header-cell>

            <ng-container *ngIf="col.header !== 'TOTAL_PAID'">
                <mat-cell *cdkCellDef="let row" class="fire-cell">
                    {{ row[col.label]}}
                </mat-cell>
            </ng-container>

            <ng-container *ngIf="col.header === 'TOTAL_PAID'">
                <mat-cell *cdkCellDef="let row" class="fire-cell" style="text-align: right;font-weight: bold;">
                    {{ formatNumeral(row[col.label])}}
                </mat-cell>
            </ng-container>

        </ng-container>

        <!--header-->
        <mat-header-row *cdkHeaderRowDef="columnsToDisplay; sticky: true" class="fire-header-row"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: columnsToDisplay;" [ngClass]="{'fire-row': true, 'hide': row.hidden}"
                 (click)="expandRow(row)"></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedRow']" class="example-detail-row"
                 [class.visible]="row == expandedElement"></mat-row>
    </mat-table>
    <div class="example-no-results" [style.display]="resultsLength === 0 ? '' : 'none'">
        <div *ngIf="isLoadingResults; else noItem">
            <mat-spinner color="accent" [diameter]="50" [strokeWidth]="3"></mat-spinner>
        </div>
        <ng-template #noItem>
            {{'NOITEMSFOUND' | translate}}
        </ng-template>
    </div>


    <mat-paginator [length]="resultsLength"
                   [pageIndex]="0"
                   [pageSize]="20"
                   [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons>
    </mat-paginator>

</div>


