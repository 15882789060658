<div *ngIf="message" class="audio-container">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
        <button (click)="playPause()" mat-icon-button style="display: flex">
            <mat-icon [class.seen]="!!message.seenDateTime" [fontIcon]="playing ? 'mdi-pause':'mdi-play'" fontSet="mdi"
                      style="font-size: 32px !important;"></mat-icon>
        </button>
        <mat-slider (change)="setProgress($event)" [max]="audioDuration" [min]="0" [value]="currentTime" color="primary"
                    step="0.1" style="width: 180px"></mat-slider>
        <div [style.backgroundColor]="message.sender.color" class="audio-head">{{ message.sender.name | initials}}</div>
        <mat-icon [class.seen]="!!message.seenDateTime" class="seen-status" fontIcon="mdi-microphone"
                  fontSet="mdi"></mat-icon>
    </div>
    <div class="message-time" fxLayout="row" fxLayoutAlign="space-between">
        <span>{{audioLength}}</span>
        <span>{{message.sentDateTime | date: 'HH:mm'}}</span>
    </div>
</div>
