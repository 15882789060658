import {
    Component,
    EventEmitter,
    Input,
    isDevMode,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {ReportingService} from '../../reporting/reporting.service';
import {ClosePlugin, InsertVariable, PLUGINS_GUIDS, SearchAndReplace} from './utils';
import {SharedService} from '../shared.service';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";
import {ReportParagraph} from "../../model";

declare var DocsAPI: any;

@Component({
    selector: 'ft-reporter',
    templateUrl: 'reporter.component.html',
    styleUrls: ['reporter.component.scss']
})
export class ReporterComponent implements OnInit, OnChanges, OnDestroy {

    private editor: any;
    @Output('editorInitialized') editorInitialized: EventEmitter<string> = new EventEmitter<string>();
    private static isCompile: boolean;
    @Input() emptyReport?: string;
    @Input() fileId?: string;
    @Input() templateModel?: number;
    @Input() documentTitle?: string;
    @Input() editable?: boolean;
    @Input() studyInstanceUID: string;
    @Input() procedureType: string;
    @Input() procedureCode: string;
    @Input() approved: boolean;
    @Input() patientData: any;
    @Input() examData: any;

    private documentKey: string;
    private users = {};
    @Input() templateMode?: number;
    private readonly subs: Subscription;
    private reportId: any;
    searchingParagraphs: boolean;
    private readonly devHost: string = "http://192.168.1.74:8000";

    constructor(private service: ReportingService, private shared: SharedService, private _translate: TranslateService) {
        this.subs = this.service.variableInsert.asObservable().pipe(debounceTime(250)).subscribe(this.insertVariables.bind(this));
        this.shared.getUsers().subscribe(data => {
            this.users['users'] = data.map(it => {
                let usr = {};
                usr['name'] = it.fullName;
                usr['email'] = it.username.toUpperCase();

                return usr;
            });
        });
    }

    private insertVariables(variable: string) {
        document['frameEditor'].postMessage(InsertVariable(variable), '*');
        if (variable.includes('QR_CODE')) {
            this.insertQrCode(variable);
        }
    }

    ngOnInit() {
        ReporterComponent.isCompile = this.templateMode === 0;
    }

    searchParagraphs() {
        document.getElementById('p-sidenav').classList.toggle('visible');
        this.searchingParagraphs = !this.searchingParagraphs;
    }

    ngOnChanges(changes: SimpleChanges) {
        const {fileId, templateModel, emptyReport} = changes;

        let user = JSON.parse(localStorage.getItem('user'));

        if (fileId && user) {
            let split = fileId.currentValue.split("_");
            this.reportId = split[0]

            if (split[0] == 'P') {
                let filename = split.length > 2 ? `${split[1]}_${split[2]}` : split[1];
                this.service.getFileModel('0', filename, 'P', user.id, user.fullName, "{}").subscribe(value => this.openFile(value));
            } else if (split[0] != 'T') this.service.getFileModel(split[0], split[1], templateModel && templateModel.firstChange && (emptyReport && emptyReport.currentValue !== 'EMPTY') ? 'R' : 'T', user.id, user.fullName, "{}").subscribe(value => this.openFile(value));
            else this.service.getFileModel('0', split[1], split[0], user.id, user.fullName, "{}").subscribe(value => this.openFile(value));
        }
    }

    public openFile(config: any) {

        if (this.editor) this.editor.destroyEditor();

        const onOutdatedVersion = (event) => {
            console.debug('Outdated version', event);
            location.reload()
        };

        const onError = (event) => {
            if (event) console.log(event.data);
        };

        const onRequestEditRights = () => {
            console.log("Request edit rights ..")
        };

        const onAppReady = () => {
            this.editorInitialized.emit(this.documentKey);
            setTimeout(() => {
                if (ReporterComponent.isCompile) this.compileVariables();
            }, 3000);
        };

        const onDocumentStateChange = (event) => {
            if (ReporterComponent.isCompile) this.compileVariables();
        };
        const onInfo = (data) => {
            if (data && data.data && data.data.getConfig) {
                console.log("Getting config...")
                this.editor.serviceCommand('getConfig', config.document);
            }
        };
        const onRequestUsers = () => {
            this.editor.setUsers(this.users);
        };
        const onRequestHistory = () => this.service.getReportHistory(this.reportId).subscribe(history => {
            console.log(history);
            this.editor.refreshHistory(history)
        });
        const onRequestRestore = (event) => {
            let version = event.data.version;
            this.service.restoreVersion(this.reportId, version).subscribe(onRequestHistoryClose);
        }
        const onRequestHistoryData = (event) => {
            let ver = event.data;
            this.service.getReportHistoryData(ver).subscribe(data => this.editor.setHistoryData(data))
        };
        const onRequestHistoryClose = () => document.location.reload();
        const onRequestCreateNew = (ev) => {
            console.log(ev);
        };

        config.events = {
            onAppReady: onAppReady,
            onDocumentStateChange: onDocumentStateChange,
            onRequestEditRights: onRequestEditRights,
            onError: onError,
            onOutdatedVersion: onOutdatedVersion,
            onInfo: onInfo,
            onRequestUsers: onRequestUsers,
            // onRequestHistory: onRequestHistory,
            // onRequestHistoryData: onRequestHistoryData,
            // onRequestHistoryClose: onRequestHistoryClose,
            onRequestRestore: onRequestRestore,
            onRequestCreateNew: onRequestCreateNew,
            // onPluginsReady: () => {
            //     setTimeout(() => document['frameEditor'].postMessage(InitAutocompleteDictionary(['@tdm', '@echo']), '*'), 3000);
            // }
        };

        this.documentKey = config.document.key;

        this.documentTitle && (config.document.title = this.documentTitle);

        config.mode = this.editable ? 'edit' : 'view';
        config.editorConfig.mode = config.mode

        config.editorConfig.customization.uiTheme = this.uiTheme?.includes('dark') ? 'default-dark': 'default-light';

        config.document.url = config.document.url.replace('localhost', isDevMode() ? this.devHost : 'http://' + location.host);
        config.editorConfig.callbackUrl = config.editorConfig.callbackUrl.replace('localhost', isDevMode() ? this.devHost : 'http://' + location.host);

        this.editor = new DocsAPI.DocEditor("reporter", config);
    }

    private get uiTheme(): string {
        return JSON.parse(localStorage.getItem('glassyTheme')) || '';
    }

    ngOnDestroy() {
        if (this.subs) this.subs.unsubscribe();
        this.closePlugins();
        this.editor.destroyEditor();
    }

    private compileVariables() {
        if (ReporterComponent.isCompile) {
            let patientData = this.patientData;//JSON.parse(localStorage.getItem('PATIENT_DATA'));
            let examData = this.examData;//JSON.parse(localStorage.getItem('EXAM_DATA'));

            if (patientData && examData) {
                let variablesData = [...patientData, ...examData];

                variablesData.forEach(it => document['frameEditor'].postMessage(SearchAndReplace(it.key, it.value), '*'));
            }
        }
    }

    private closePlugins() {
        Object.keys(PLUGINS_GUIDS).forEach(key => document['frameEditor'].postMessage(ClosePlugin(key), '*'));
    }

    private insertQrCode(variable) {
        this.service.getQrCode(this.studyInstanceUID).subscribe(value => {

            this.editor.insertImage({
                c: "add",
                fileType: "png",
                url: value.qr_code
            });

            setTimeout(() => document['frameEditor'].postMessage(
                SearchAndReplace(variable,
                    `${this._translate.instant('LINK')}: ${value.external_url} ▬ ${this._translate.instant('PASSWORD')}: ${value.password}`), '*'));
        });
    }

    insertParagraph(paragraph: ReportParagraph) {
        document['frameEditor'].postMessage(InsertVariable(paragraph.text), '*');
    }
}
