<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
    <h3 mat-dialog-title>{{'PROFILE' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content style="max-height: 90vh;">
    <div fxLayout="row" fxLayoutAlign="space-between stretch" [formGroup]="profileForm">
        <div fxFlex class="profile-side">
            <mat-form-field appearance="standard" class="full-width">
                <mat-label>{{'NAME' | translate}}</mat-label>
                <input matInput [placeholder]="'NAME' | translate" formControlName="name">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full-width">
                <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
                <textarea matInput [placeholder]="'DESCRIPTION' | translate" formControlName="description"></textarea>
            </mat-form-field>

            <section style="margin-top: 20px;">
                <mat-checkbox color="primary" formControlName="admin">
                    {{'ADMIN' | translate}}
                </mat-checkbox>
            </section>

            <section style="margin-top: 20px;">
                <mat-checkbox color="primary" formControlName="managePayment">
                    {{'MANAGE_PAYMENT' | translate}}
                </mat-checkbox>
            </section>

            <section style="margin-top: 20px;">
                <mat-checkbox color="primary" formControlName="canDeletePayment">
                    {{'CAN_DELETE_PAYMENT' | translate}}
                </mat-checkbox>
            </section>

            <div style="font-size: 14px;margin: 24px 0 12px;">{{'DEFAULT_ROUTE' | translate}}</div>
            <mat-radio-group fxLayout="column" formControlName="defaultRoute">
                <mat-radio-button fxFlex
                                  *ngFor="let route of ['patient', 'scheduler', 'reporting', 'workflow', 'setting']"
                                  [value]="route">
                    {{route | translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="grid-layout" fxLayout="row wrap" fxLayoutGap="8px">
            <div fxFlex *ngFor="let module of modules" class="module-layout {{module.module}}">
                <div class="module-header">
                    <mat-slide-toggle formControlName="{{module.module}}">
                        {{module.name | translate}}
                    </mat-slide-toggle>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between" *ngFor="let field of module.fields">
                    <div style="font-size:14px;">{{ field | translate}}</div>
                    <mat-radio-group formControlName="{{field}}">
                        <mat-radio-button color="primary" fxFlex *ngFor="let level of ['ALL', 'OWN', 'NONE']"
                                          [value]="level">
                            {{level | translate}}
                        </mat-radio-button>
                    </mat-radio-group>                    
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button [mat-dialog-close]="null" color="warn" mat-raised-button>{{'CANCEL' | translate}}</button>
    <button (click)="onSave(profileForm.value)" [disabled]="profileForm.invalid" color="primary" mat-raised-button
            style="font-weight: bold;">{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>
