export class FileElement {
    id?: number;
    uuid?: string;
    folder?: boolean;
    name?: string;
    parent?: string;
    patientID?: string;
    createdDate?: any;
    modifiedDate?: any;
    fileType?: string;
    size?: number;
    url?: string;
    patientPhoto?: boolean;
    fav?: boolean;
}
