<mat-toolbar class="dialog-toolbar" style="background-color: #009688;">
    <mat-icon fontIcon="mdi-store" fontSet="mdi"></mat-icon>
    <h3 mat-dialog-title>{{'PHARMACY' | translate}}</h3>
    <span fxFlex></span>
    <button mat-dialog-close mat-icon-button tabindex="-1">
        <mat-icon [style.color]="'white'" fontIcon="mdi-close" fontSet="mdi"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>


    <form *ngIf="workflowItem" [formGroup]="stockForm">

        <div class="row2" fxLayout="row">
            <div fxFlex="180px">Patient</div>
            <div fxFlex>{{workflowItem.patientName}}</div>
        </div>
        <div class="row2" fxLayout="row">
            <div fxFlex="180px">{{'PROCEDURE_CODE' | translate}}</div>
            <div fxFlex>{{workflowItem.procedureCode}}</div>
        </div>

        <div [style.marginTop.px]="16" fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="12px">
            <mat-form-field [appearance]="'standard'">
                <mat-label>{{'STORE' | translate}}</mat-label>
                <mat-select [placeholder]="'STORE' | translate" formControlName="store">
                    <mat-option *ngFor="let store of stores" [value]="store.id">{{store.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="radio" fxLayout="column">
                <div class="label-option">{{'PRICE_MODE' | translate}}</div>

                <mat-radio-group color="primary" formControlName="priceMode" fxLayout="row" fxLayoutAlign="start end">
                    <mat-radio-button *ngFor="let option of ['HT', 'TTC']" [value]="option" fxFlex>
                        {{option}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>


            <span fxFlex></span>
            <button (click)="addNewArticle()" [disabled]="formDisabled" color="primary" mat-button>
                <mat-icon fontIcon="mdi-text-box-plus" fontSet="mdi"></mat-icon>
                {{'ARTICLE' | translate}}
            </button>
        </div>

        <!--        Table-->
        <div [style.marginTop.px]="4" class="limiter">
            <div class="container-table">

                <div class="table">
                    <div class="row header">
                        <div class="cell">
                            {{'CODE' | translate}}
                        </div>
                        <div class="cell">
                            {{'DESIGNATION' | translate}}
                        </div>
                        <div class="cell">
                            {{'PRICE' | translate}}
                        </div>
                        <div class="cell">
                            {{'QUANTITY' | translate}}
                        </div>
                        <div class="cell">
                            {{'VAT' | translate}}
                        </div>
                        <div class="cell">
                            {{'STORE' | translate}}
                        </div>
                        <div class="cell">
                            {{'SUB_TOTAL' | translate}}
                        </div>
                        <div class="cell">
                        </div>
                    </div>

                    <div *ngFor="let article of articles" class="row">
                        <div class="cell">{{article.code}}</div>
                        <div class="cell">{{article.description}}</div>
                        <div class="cell">{{formatNumeral(article.price)}}</div>
                        <div class="cell">
                            <mat-form-field>
                                <input (keyup)="updateArticle(article)" [(ngModel)]="article.quantity"
                                       [disabled]="formDisabled"
                                       [ngModelOptions]="{standalone: true}" matInput
                                       type="number">
                            </mat-form-field>
                        </div>
                        <div class="cell">
                            <mat-form-field [appearance]="'standard'" style="width: 50px;">
                                <mat-select (selectionChange)="updateArticle(article)" [(ngModel)]="article.vatAmount"
                                            [disabled]="formDisabled"
                                            [ngModelOptions]="{standalone: true}"
                                            [placeholder]="'TVA' | translate">
                                    <mat-option *ngFor="let vatAmount of vatAmounts"
                                                [value]="vatAmount">{{vatAmount}}%
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="cell">
                            <mat-form-field [appearance]="'standard'" style="width: 120px;">
                                <mat-select (selectionChange)="updateArticle(article)" [(ngModel)]="article.store"
                                            [disabled]="formDisabled"
                                            [ngModelOptions]="{standalone: true}"
                                            [placeholder]="'STORE' | translate">
                                    <mat-option *ngFor="let store of stores"
                                                [value]="store.id">{{store.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="cell">{{formatNumeral(article.totalPrice)}}</div>
                        <div [style.textAlign]="'right'" class="cell">
                            <button (click)="selectLotNumbers(article)" *ngIf="article.hasLots"
                                    [disabled]="formDisabled" [style.lineHeight.px]="18"
                                    mat-icon-button>
                                <mat-icon
                                        [style.color]="article.lots == null || lotsTotalQuantity(article.lots) !== article.quantity ? '#ff1111': formDisabled ? '#e0e0e0': '#238748'"
                                        fontIcon="mdi-alpha-l-box" fontSet="mdi"></mat-icon>
                            </button>

                            <button (click)="selectSerialNumbers(article)" *ngIf="article.hasSerials"
                                    [disabled]="formDisabled"
                                    [style.lineHeight.px]="18" mat-icon-button>
                                <mat-icon
                                        [style.color]="article.serials == null || article.serials.split(',').length !== article.quantity ? '#ff1111': formDisabled ? '#e0e0e0': '#238748'"
                                        fontIcon="mdi-barcode" fontSet="mdi"></mat-icon>
                            </button>


                            <button (click)="removeArticle(article)" [disabled]="formDisabled"
                                    [style.lineHeight.px]="18" mat-icon-button>
                                <mat-icon [style.color]="formDisabled ? '#e0e0e0': '#fa4d77'"
                                          fontIcon="mdi-close-circle"
                                          fontSet="mdi"></mat-icon>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="!articles.length" class="row">
                        <div class="no-cell">
                            <button (click)="addNewArticle()" [disabled]="formDisabled" color="primary" mat-button>
                                <mat-icon fontIcon="mdi-text-box-plus" fontSet="mdi"></mat-icon>
                                Article
                            </button>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div fxLayout="row nowrap" fxLayoutAlign="end start" fxLayoutGap="4px">
            <mat-form-field appearance="standard" fxFlex="120px">
                <mat-label>{{'DISCOUNT_PERCENTAGE' | translate}}</mat-label>
                <input (keyup)="changeDiscountPrice($event)" [max]="100"
                       [placeholder]="'DISCOUNT_PERCENTAGE' | translate"
                       formControlName="globalDiscountPercentage" matInput type="number">
            </mat-form-field>
            <mat-form-field appearance="standard" fxFlex="96px">
                <mat-label>{{'DISCOUNT_PRICE' | translate: {currency: currencyFormat} }}</mat-label>
                <input (keyup)="changeDiscountPercentage($event)" [placeholder]="'DISCOUNT_PRICE' | translate"
                       formControlName="globalDiscount" matInput type="number">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px">
            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'COMMENT' | translate}}</mat-label>
                <textarea [placeholder]="'COMMENT' | translate" [style.height.px]="62" formControlName="comment"
                          matInput maxLength="200"></textarea>
                <mat-hint [style.fontWeight]="'bold'" [style.paddingTop.px]="4"
                          align="end">{{stockForm.value.comment?.length || 0}} / 200
                </mat-hint>
            </mat-form-field>

            <div fxFlex="220px">
                <div class="row2 right-align">
                    <div fxFlex="120px">{{'TOTAL_HT' | translate}}</div>
                    <div fxFlex="100px">{{formatNumeral(totalHT)}}</div>
                </div>
                <div class="row2 right-align">
                    <div fxFlex="120px">{{'TOTAL_VAT' | translate}}</div>
                    <div fxFlex="100px">{{formatNumeral(totalVAT)}}</div>
                </div>
                <div class="row2 right-align">
                    <div fxFlex="120px">{{'TOTAL_TTC' | translate}}</div>
                    <div fxFlex="100px">{{formatNumeral(totalTTC)}}</div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>


<ng-container *ngIf="stockForm.value.valid">
    <div [class.approved]="true">
        <img alt="Validé" height="120px" src="../../../assets/images/valid.png">
    </div>
</ng-container>

<mat-dialog-actions fxLayout="row" fxLayoutGap="4px">
    <button (click)="save(stockForm.value, true)" [disabled]="formDisabled" color="primary" mat-raised-button
            style="border: none !important;">{{'SAVE_DRAFT' | translate}}</button>
    <span fxFlex></span>
    <button color="accent" mat-dialog-close mat-raised-button>{{'CLOSE' | translate}}</button>
    <button (click)="unValidate(stockForm.value)" *ngIf="formDisabled" color="warn"
            mat-raised-button>{{'UN_VALIDATE' | translate}}</button>
    <button (click)="validate(stockForm.value)" [disabled]="!isFormValid || formDisabled" color="primary"
            mat-raised-button>{{'VALIDATE' | translate}}
    </button>
</mat-dialog-actions>

