import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {ExternalViewerComponent} from "./external-viewer.component";
import {ViewerComponent} from "./viewer/viewer.component";


const viewerRoutes: Routes = [
  {
    path: 'external-viewer', component: ViewerComponent,
    children: [
      {
        path: 'study',
        component: ExternalViewerComponent,
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(viewerRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ViewerRoutingModule { }
