import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {sortBy, remove, find} from 'lodash';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TableView} from '../../model';
import {BehaviorSubject, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {SettingService} from '../../setting/setting.service';

class ColumnItem {
    constructor(public label: string,
                public header?: string,
                public selected?: boolean) {
    }
}

@Component({
    selector: 'app-table-view',
    templateUrl: './table-view.component.html',
    styleUrls: ['./table-view.component.scss']
})
export class TableViewComponent implements OnInit {

    cols = [];
    columns = of([]);

    form: FormGroup;
    selection = new BehaviorSubject<ColumnItem[]>([]);

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private setting: SettingService,
                private dialogRef: MatDialogRef<TableViewComponent>) {
        this.createForm();
        this.cols = sortBy(data.cols.filter(item => !item.hidden), 'order');
        this.columns = of(this.cols.map(c => new ColumnItem(c.label, c.header)));
    }

    ngOnInit() {
        if (this.data.tableView) {
            this.form.patchValue(this.data.tableView);
            this.data.tableView.columns.split(',').forEach(label => this.columnSelectionToggle({label: label, selected: true}))
        }
    }

    drop(event: CdkDragDrop<any[]>) {
        this.columns.subscribe(cols => {
            moveItemInArray(cols, event.previousIndex, event.currentIndex);

            this.selection.next(cols.filter(c => c.selected));
        });
    }

    save() {
        let cols = this.selection.getValue().map(it => it.label);
        this.form.get('columns').patchValue(cols.join(','));

        this.setting.createTableView(this.form.getRawValue()).subscribe(res => {
            if (res) this.dialogRef.close(res);
        });
    }

    private createForm = () => this.form = this.fb.group(new TableView());

    columnSelectionToggle(col: ColumnItem): void {
        this.columns.pipe(map(it => {
            it = it.map(co => {
                if(co.label === col.label) co.selected = !co.selected;
                return co;
            });
            return it;
        })).subscribe(cols => {
            this.selection.next(cols.filter(c => c.selected));
            setTimeout(() => this.columns = of(cols));
        })
    }
}
