import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

import {
    Appointment,
    Availability,
    AVSC,
    CalendarEvent,
    EventDetail,
    Payment,
    PaymentItem,
    PaymentOrder,
    ThermalPrintModel,
    WorkflowItem,
    WsMessage
} from '../model';
import {SortDirection} from '@angular/material/sort';
import {DOCUMENT_URL, PATIENT_URL, PRINTER_URL, REPORTING_URL, SCHEDULER_URL, WORKFLOW_URL} from '../urls';
import {tap} from "rxjs/operators";
import {printBlob} from "../utils";


@Injectable()
export class SchedulerService {

    private broadcastSource = new Subject<any>();
    broadcast = this.broadcastSource.asObservable();
    scanIdCard: Subject<boolean> = new Subject<boolean>();
    beidCardData: Subject<any> = new Subject<any>();

    getPaymentDetails(spsId: any): Observable<Payment> {
        return this.http.get<Payment>(`${SCHEDULER_URL}/payment-details`, {params: {spsId}})
    }

    exitEditing(taskId: number): Observable<any> {
        let params = {taskId: String(taskId)};
        return this.http.get(`${SCHEDULER_URL}/exitEditing`, {params})
    }

    startEditing(taskId: number): Observable<any> {
        let params = {taskId: String(taskId)};
        return this.http.get(`${SCHEDULER_URL}/startEditing`, {params})
    }

    printData = (filter: any): Observable<Blob> => this.http.post(`${SCHEDULER_URL}/print`, filter, {responseType: 'blob'});

    createNewISR = (order): Observable<any> => this.http.post(`${SCHEDULER_URL}/createNewISR`, order);

    getScheduledAppointments(res: string, start: string, end: string): Observable<CalendarEvent[]> {
        let params = new HttpParams()
            .set('res', res)
            .set('start', start)
            .set('end', end);
        return this.http.get<CalendarEvent[]>(`${SCHEDULER_URL}/calendar-events`, {params: params});
    }

    getExternalAppointments(pageSize: number, pageIndex: number, sort: any, direction: any, key: any): Observable<any> {
        let params = {page: String(pageIndex), size: String(pageSize), sort: `${sort},${direction}`, key};
        return this.http.get(`${SCHEDULER_URL}/external-appointments`, {params: params});
    }

    findISRsByOrderStatus = (orderStatus: string): Observable<any> => this.http.get(`${SCHEDULER_URL}/isrsByOrderStatus/${orderStatus}`);

    getImagingServiceRequests(pageSize: number, pageIndex: number, sort: string, direction: string, value: string): Observable<WorkflowItem[]> {
        let params = {page: String(pageIndex), size: String(pageSize), sort: `${sort},${direction}`, value};
        return this.http.get<WorkflowItem[]>(`${WORKFLOW_URL}/imagingServiceRequests`, {params});
    }

    scheduleSPS = (sps: any): Observable<any> => this.http.post(`${SCHEDULER_URL}/scheduleSPS`, sps);

    getPatientScheduledProcedures = (patientId: string): Observable<any> => this.http.get(`${SCHEDULER_URL}/patientSPSs/${patientId}`);

    getEventDetail = (ids: any): Observable<EventDetail> => this.http.get<EventDetail>(`${SCHEDULER_URL}/eventDetail/${ids.spsId}`);

    rejectOrder = (id: number, reasonId: number, other: string): Observable<any> => this.http.get(`${SCHEDULER_URL}/rejectISR/${id}/${reasonId}/${other}`);

    cancelOrder = (id: number): Observable<any> => this.http.get(`${SCHEDULER_URL}/cancelOrder/${id}`);

    updateAppointmentStatus = (id: number, status: string): Observable<any> => this.http.get(`${SCHEDULER_URL}/updateAppointmentStatus/${id}`, {params: {status}});

    updateSPS = (calEvent): Observable<any> => this.http.post(`${SCHEDULER_URL}/updateCalEvent`, calEvent);

    updateAppointment = (calEvent): Observable<any> => this.http.post(`${SCHEDULER_URL}/updateAppointment`, calEvent);

    deleteEvent = (spsId: number): Observable<any> => this.http.get(`${SCHEDULER_URL}/deleteCalEvent/${spsId}`);

    markPatientAsArrived = (isr: any): Observable<any> => this.http.post(`${SCHEDULER_URL}/patientArrived`, isr);

    startExam = (isrId: number, spsId: number): Observable<any> => this.http.get(`${SCHEDULER_URL}/startExam/${isrId}/${spsId}`);

    startExamByAN = (accessionNumber: string): Observable<any> => this.http.get(`${SCHEDULER_URL}/start-exam-an`, {params: new HttpParams().set('accessionNumber', accessionNumber)});

    finishExam = (isrId: number): Observable<any> => this.http.get(`${SCHEDULER_URL}/finishExam/${isrId}`);

    findSPSById = (spsId: number): Observable<any> => this.http.get(`${SCHEDULER_URL}/spsById/${spsId}`);

    restoreISR = (isrId: number): Observable<any> => this.http.get(`${SCHEDULER_URL}/restoreISR/${isrId}`);

    findAvailabilities = (avsc: AVSC): Observable<Availability> => this.http.post<Availability>(`${SCHEDULER_URL}/findAvailabilities`, avsc);

    printRDV = (spsId: number): Observable<boolean> => this.http.get<boolean>(`${PRINTER_URL}/print-rdv/${spsId}`);
    printAppointment = (spsId: number): Observable<boolean> => this.http.get<boolean>(`${PRINTER_URL}/print-appointment/${spsId}`);

    printRdvForResult = (rdv: any): Observable<boolean> => this.http.post<boolean>(`${PRINTER_URL}/print-rdv-for-result`, rdv);

    getSPSByISRID = (id: any): Observable<any> => this.http.get<any>(`${SCHEDULER_URL}/sps-by-isr/${id}`);

    saveSPS = (value: any, isrId: number): Observable<any> => this.http.post<any>(`${SCHEDULER_URL}/save-sps/${isrId}`, value);

    getISR = (isrId: any): Observable<any> => this.http.get(`${SCHEDULER_URL}/get-isr/${isrId}`);

    getISRByRpID = (requestedProcedureId: any): Observable<any> => this.http.get(`${SCHEDULER_URL}/isr-by-rp/${requestedProcedureId}`);

    updateISR = (data: any[]): Observable<CalendarEvent[]> => this.http.post<CalendarEvent[]>(`${SCHEDULER_URL}/updateISR`, data);

    countReadyReports = (): Observable<number> => this.http.get<number>(`${REPORTING_URL}/countReadyReports`);

    getCompletedReports(active: string, direction: SortDirection, pageIndex: number, pageSize: number, query: { value: string; range: string }, filter: string): Observable<any> {
        let value = `${query.value}_${query.range}`;
        let params = {
            page: String(pageIndex),
            size: String(pageSize),
            sort: `${active},${direction}`,
            value,
            filter: filter || 'NONE'
        };
        return this.http.get(`${REPORTING_URL}/completed-reports`, {params});
    }

    findPatientFutureAppointments = (patientID: string): Observable<CalendarEvent[]> => this.http.get<CalendarEvent[]>(`${SCHEDULER_URL}/patientAppointments/${patientID}`);

    getTodayFinishedOrders = (): Observable<number> => this.http.get<number>(`${SCHEDULER_URL}/today-finished-orders`);

    orderPayment = (order: PaymentOrder): Observable<any> => this.http.post(`${SCHEDULER_URL}/orderPayment`, order);

    createPayment = (payment: Payment): Observable<Payment> => this.http.post<Payment>(`${SCHEDULER_URL}/createPayment`, payment);

    getPaymentOrderDetails(spsId: number): Observable<PaymentItem[]> {
        return this.http.get<PaymentItem[]>(`${SCHEDULER_URL}/payment-order-details`, {params: {spsId: String(spsId)}});
    }

    getISRByAN(accessionNumber: string): Observable<any> {
        let params = {accessionNumber};
        return this.http.get(`${SCHEDULER_URL}/isr-by-an`, {params});
    }

    exitPatient(accessionNumber: any): Observable<any> {
        let params = {accessionNumber};
        return this.http.get(`${SCHEDULER_URL}/exitPatient`, {params});
    }

    constructor(private http: HttpClient) {
    }

    updateReportingTaskStatus(id: number, status: string) {
        let params = new HttpParams().set('reportingStatus', status).set('id', String(id));
        return this.http.get(`${REPORTING_URL}/updateReportingStatus`, {params});
    }

    printTicket(printable: ThermalPrintModel): Observable<any> {
        return this.http.post(`${PRINTER_URL}/printTicket`, printable, {responseType: 'blob'}).pipe(tap(printBlob));
    }

    printCupsTicket(printable: ThermalPrintModel): Observable<any> {
        return this.http.post(`${PRINTER_URL}/printCupsTicket`, printable);
    }

    printPaymentReceipt(payment: Payment): Observable<any> {
        return this.http.post(`${PRINTER_URL}/printPaymentReceipt`, payment, {responseType: 'blob'}).pipe(tap(printBlob));
    }

    printCupsPaymentReceipt(payment: Payment): Observable<any> {
        return this.http.post(`${PRINTER_URL}/printCupsPaymentReceipt`, payment);
    }

    updateOrder = (imagingOrder: any): Observable<any> => this.http.post(`${SCHEDULER_URL}/updateOrder`, imagingOrder);

    cancelPayment(paymentID: string): Observable<any> {
        let params = {paymentID};
        return this.http.get(`${SCHEDULER_URL}/cancelPayment`, {params});
    }

    printAppointments = (filter: any): Observable<boolean> => this.http.post<boolean>(`${SCHEDULER_URL}/printAptsList`, filter);

    countExamsPerDate(): Observable<WsMessage[]> {
        return this.http.get<WsMessage[]>(`${SCHEDULER_URL}/countExams`)
    }

    calculateAge = (dob: string): Observable<WsMessage> => this.http.get<WsMessage>(`${SCHEDULER_URL}/calculateAge`, {params: {dob}});
    calculateDOB = (age: string): Observable<WsMessage> => this.http.get<WsMessage>(`${SCHEDULER_URL}/calculateDOB`, {params: {age}});

    getTodayExams(): Observable<number> {
        return this.http.get<number>(`${WORKFLOW_URL}/count-today-exams`);
    }

    deleteExam(isrId: any): Observable<boolean> {
        return this.http.get<boolean>(`${SCHEDULER_URL}/deleteExam`, {params: {isrId}});
    }

    deleteFiles(patientID: string): Observable<any> {
        let params = {patientID};
        return this.http.get(`${DOCUMENT_URL}/deleteFiles`, {params});
    }

    scheduleISRWithPayment = (data: any): Observable<any> => this.http.post(`${SCHEDULER_URL}/scheduleISRWithPayment`, data);

    getPatientById(patientId: string): Observable<any> {
        return this.http.get(`${PATIENT_URL}/getone/${patientId}`)
    }

    getAzExams(query: string): Observable<any> {
        return this.http.get(`${SCHEDULER_URL}/findAZExams`, {params: {minutesBefore: query}})
    }

    createAppointment(appointment: Appointment): Observable<Appointment> {
        return this.http.post<Appointment>(`${SCHEDULER_URL}/create-appointment`, appointment);
    }

    deleteAppointment(id: any): Observable<any> {
        return this.http.get(`${SCHEDULER_URL}/delete-appointment`, {params: {id}});
    }

    getAppointment(id: number): Observable<Appointment> {
        return this.http.get<Appointment>(`${SCHEDULER_URL}/get-appointment/${id}`);
    }

    getPatientAppointments(id: any): Observable<Appointment[]> {
        return this.http.get<Appointment[]>(`${SCHEDULER_URL}/patient-appointments`, {params: {id}})
    }
}
