<div class="mat-table">

    <mat-toolbar class="sc-toolbar" color="primary">
        <div class="title">
            <span>{{'PATHOLOGIES' | translate}}</span>
        </div>

        <span class="fire-spacer"></span>

        <button [matTooltip]="'ADD_NEW' | translate" mat-button (click)="editPathology(null)">
            <mat-icon class="add-icon-gradient" fontIcon="mdi-plus-box" fontSet="mdi"></mat-icon>
        </button>
    </mat-toolbar>

    <div class="search-area">
        <mat-form-field floatLabel="never">
            <span matPrefix><mat-icon fontIcon="mdi-magnify" fontSet="mdi" style="vertical-align: baseline;"></mat-icon> &nbsp;</span>
            <input matInput #filter placeholder="{{'SEARCHING' | translate}}">
        </mat-form-field>

    </div>

    <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
        <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
            Server is not responding, It will be available in a few seconds. try to refresh your page
        </div>
    </div>

    <mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>

    <mat-table #table [dataSource]="dataSource" class="fire-table" matSort matSortActive="value"
               matSortDisableClear matSortDirection="desc">

        <!--Table-->
        <ng-container cdkColumnDef="value">

            <mat-header-cell *cdkHeaderCellDef class="fire-header-cell" mat-sort-header> {{"PATHOLOGY" |
                translate}}
            </mat-header-cell>

            <ng-container>
                <mat-cell *cdkCellDef="let row" class="fire-cell"> {{row.value}}</mat-cell>
            </ng-container>

        </ng-container>

        <!--actions-->
        <ng-container cdkColumnDef="action">
            <mat-header-cell *cdkHeaderCellDef class="fire-header-cell"></mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="fire-cell" style="text-align: right">
                <button mat-icon-button [matTooltip]="'EDIT' | translate" (click)="editPathology(row)">
                    <mat-icon class="edit-icon-gradient" fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
                </button>
                <button mat-icon-button [matTooltip]="'DELETE' | translate" (click)="deletePathology(row)">
                    <mat-icon class="delete-icon-gradient" fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <!--header-->
        <mat-header-row *cdkHeaderRowDef="['value', 'action']" class="fire-header-row"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: ['value', 'action'];" class="fire-row"></mat-row>
    </mat-table>

    <div class="example-no-results"
         [style.display]="resultsLength === 0 ? '' : 'none'">
        {{'NOITEMSFOUND' | translate}}
    </div>

    <mat-paginator
            [length]="resultsLength"
            [pageIndex]="0"
            [pageSize]="20"
            [pageSizeOptions]="[5, 10, 15, 20, 50]" showFirstLastButtons>
    </mat-paginator>
</div>



