<mat-toolbar class="dialog-toolbar" style="background: #3F51B5;">
    <h3 mat-dialog-title>{{'SEND_MAIL' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button [mat-dialog-close]="false" tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>

    <ng-container *ngIf="sending">
        <mat-progress-bar color="accent" mode="indeterminate" style="top: -12px"></mat-progress-bar>
    </ng-container>

    <div [formGroup]="form" fxLayout="column" class="email-input">

        <div fxLayout="row" fxLayoutGap="2px">

            <mat-form-field fxFlex>
                <span matPrefix class="ft-prefix">{{'TO' | translate}}&nbsp;</span>
                <input matInput formControlName="destAddress" tabindex="-1">
                <mat-error [hidden]="form.untouched" style="padding-top: 5px;">Adresse email invalide</mat-error>
            </mat-form-field>

            <span class="email-btn-extra" (click)="isCc=true" *ngIf="!isCc">Cc</span>
            <span class="email-btn-extra" (click)="isBcc=true" *ngIf="!isBcc">{{'BCC' | translate}}</span>

        </div>

        <mat-form-field *ngIf="isCc">

            <span matPrefix class="ft-prefix">Cc&nbsp;</span>
            <input matInput formControlName="cc" tabindex="-1">

        </mat-form-field>

        <mat-form-field *ngIf="isBcc">

            <span matPrefix class="ft-prefix">{{'BCC' | translate}}&nbsp;</span>
            <input matInput formControlName="bcc" tabindex="-1">

        </mat-form-field>

        <mat-form-field appearance="standard">

            <span matPrefix class="ft-prefix">{{'SUBJECT' | translate}}&nbsp;</span>
            <input matInput formControlName="subject" tabindex="-1">

        </mat-form-field>

        <div id="editor"></div>

    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="sendMail()" [disabled]="form.invalid" [ngClass]="{'btn-valid': form.valid}" mat-button>
        {{'SEND' | translate}}
    </button>
</mat-dialog-actions>
