export function variables(label: 'EXAM_VARIABLES' | 'PATIENT_VARIABLES', cmp: any): any[] {
    switch (label) {
        case "PATIENT_VARIABLES":
            return [
                {key: '%PATIENT::ID%', label: cmp.translate.instant('PATIENT_ID')},
                {key: '%PATIENT::FULL_NAME%', label: cmp.translate.instant('PATIENT_NAME')},
                {key: '%PATIENT::DOB%', label: cmp.translate.instant('DATE_OF_BIRTH')},
                {key: '%PATIENT::AGE%', label: cmp.translate.instant('AGE')},
                {key: '%PATIENT::GENDER%', label: cmp.translate.instant('SEX')},
                {key: '%PATIENT::HEIGHT%', label: cmp.translate.instant('HEIGHT')},
                {key: '%PATIENT::WEIGHT%', label: cmp.translate.instant('WEIGHT')},
                {key: '%PATIENT::BS%', label: cmp.translate.instant('BSA')},
                {key: '%PATIENT::BMI%', label: cmp.translate.instant('BMI')},
                {key: '%PATIENT::ALERTS%', label: cmp.translate.instant('ALERTS')},
                {key: '%PATIENT::CIN%', label: cmp.translate.instant('CIN')},
                {key: '%PATIENT::PHONE%', label: cmp.translate.instant('PHONE')},
                {key: '%PATIENT::ADDRESS%', label: cmp.translate.instant('ADDRESS')},
                {key: '%PATIENT::POSTAL_CODE%', label: cmp.translate.instant('POSTAL_CODE')},
                {key: '%PATIENT::CITY%', label: cmp.translate.instant('CITY')},
                {key: '%PATIENT::COUNTRY%', label: cmp.translate.instant('COUNTRY')},
            ];
        case "EXAM_VARIABLES":
            return [
                {key: '%EXAM::CODE%', label: cmp.translate.instant('PROCEDURE_CODE')},
                {key: '%EXAM::TECHNIQUE%', label: cmp.translate.instant('TECHNIQUE')},
                {key: '%EXAM::REFERRING_PHYSICIAN%', label: cmp.translate.instant('REFERRING_PHYSICIAN')},
                {key: '%EXAM::REFERRING_PHYSICIAN_ADDRESS%', label: cmp.translate.instant('REFERRING_PHYSICIAN_ADDRESS')},
                {key: '%EXAM::DATE%', label: cmp.translate.instant('DATE_OF_EXAM')},
                {key: '%EXAM::SIGNATURE_DATE%', label: cmp.translate.instant('SIGNATURE_DATE')},
                {key: '%EXAM::QR_CODE%', label: cmp.translate.instant('QR_CODE')}
            ];
    }
}

export function editorMenu(cmp: any)  {
    return `<li id="menu-patient">
        <a>Patient<span class="sub-arrow">›</span></a>
        <ul>${variables('PATIENT_VARIABLES', cmp).map(el => `<li><a variable="${el.key}">${el.label}</a></li>`).reduce((p, c) => p + c, '')}</ul>
    </li>
    <li id="menu-exam">
        <a>Exam<span class="sub-arrow">›</span></a>
        <ul>${variables('EXAM_VARIABLES', cmp).map(el => `<li><a variable="${el.key}">${el.label}</a></li>`).reduce((p, c) => p + c, '')}</ul>
    </li>
`;
}
