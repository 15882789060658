import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {
    DEFAULT_DATE_OPTIONS,
    defaultFormattingRule,
    DISPLAY_MODES,
    FORMAT_RULES,
    FormatRule,
    FormattingRule,
    TableColumn
} from "../../model";
import {MatDialog} from "@angular/material/dialog";
import {IconSelectComponent} from "../icon-select/icon-select.component";
import {getColumnStatuses} from "../shared-functions";
import {SharedService} from "../shared.service";

@Component({
    selector: 'formatting-rule',
    templateUrl: './formatting-rule.component.html',
    styleUrls: ['./formatting-rule.component.scss']
})
export class FormattingRuleComponent implements OnChanges {
    @Input() rule: FormattingRule;
    @Input() columns: TableColumn[] = [];
    @Output() onApplyChanges = new EventEmitter<FormattingRule>();
    @Output() onCancel = new EventEmitter<boolean>();

    formatRules = FORMAT_RULES;

    selectedIcon: string;
    filteredRules: FormatRule[] = FORMAT_RULES;
    filteredType: 'DATE' | 'STRING' | 'NUMERIC' | 'BOOLEAN' | 'STATUS';
    dateOptions = DEFAULT_DATE_OPTIONS;
    displayModes = DISPLAY_MODES;
    private examTypes: any[] = [];

    constructor(private dialog: MatDialog, private shared: SharedService) {
        this.shared.getReasonForExams().subscribe(value => this.examTypes = value.map(it => it.value))
    }

    selectIcon() {
        this.dialog.open(IconSelectComponent, {data: this.selectedIcon}).afterClosed().subscribe(icon => {
            if (icon) {
                this.selectedIcon = icon;
                this.rule.formattingStyle.icon = icon;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.rule = changes['rule'].currentValue || defaultFormattingRule;
        this.columns = changes['columns'].currentValue;
        this.selectedIcon = this.rule.formattingStyle.icon || 'mdi-cog';
        let col = this.columns.find(it => it.header == this.rule.targetColumn);
        this.filteredType = col ? col.type : 'STRING';
    }

    applyRule() {
        this.onApplyChanges.emit(this.rule);
    }

    cancel() {
        this.onCancel.emit(true);
    }

    filterRules(colHeader: string) {
        let col = this.columns.find(it => it.header === colHeader);
        this.filteredType = col ? col.type: 'STRING';

        this.filteredRules = this.formatRules.filter(it => {
            switch (this.filteredType) {
                case "DATE": return it.toString().startsWith('DATE') || it.toString().includes('EMPTY');
                case "NUMERIC": return it.toString().includes('THAN') ||  it.toString().includes('BETWEEN') || it.toString().includes('EMPTY');
                case "STATUS": return it.toString().includes('EXACT') || it.toString().includes('EMPTY');
                case "BOOLEAN": return it.toString().includes('EXACT');
                default: return it.toString().startsWith('TEXT') || it.toString().includes('EMPTY');
            }
        });
    }

    getColumnStatuses(targetColumn: string): string[] {
        if (targetColumn === 'PROCEDURE_TYPE') return this.examTypes;
        return getColumnStatuses(targetColumn)
    }

    previewStyle(rule: FormattingRule): any {
        return {
            color: rule.formattingStyle.color,
            backgroundColor: rule.formattingStyle.backgroundColor,
            fontWeight: rule.formattingStyle.bold ? 'bold': 400,
            textDecoration: (rule.formattingStyle.underlined ? 'underline ': '')+(rule.formattingStyle.strikethrough ? 'line-through ': ''),
            fontStyle: rule.formattingStyle.italic ? 'italic': 'normal'
        };
    }

    filteredColumns(appliedTo: 'ROW' | 'COLUMN') {
        if (appliedTo === 'ROW') return this.columns.filter(it => ['STATUS', 'BOOLEAN', 'DATE'].includes(it.type));
        return this.columns;
    }
}
