import {BehaviorSubject, merge as observableMerge, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {RFE_HEADER_COLS, RFE_TABLE_CONF} from './table-conf';
import {DeleteConfirmComponent, deleteItemFromArray, SharedService} from '../../shared';
import {DataSource} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';

import {SettingService} from '../setting.service';
import {get, map as _map, reduce} from 'lodash';
import {ReasonForExamEditComponent} from './reason-for-exam-edit/reason-for-exam-edit.component';
import {ReasonForExam} from '../../model';

@Component({
    selector: 'app-reason-for-exam',
    templateUrl: './reason-for-exam.component.html',
    styleUrls: ['./reason-for-exam.component.scss']
})
export class ReasonForExamComponent implements OnInit {

    cols: any[];
    displayedColumns = [];

    dataSource: RFEDataSource | null;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private settingService: SettingService, private sharedService: SharedService,
                public dialog: MatDialog, private snack: MatSnackBar) {
        this.sharedService.getReasonForExams().subscribe(data => {

            let items = _map(data, tsk => {
                return reduce(this.displayedColumns, (obj, field) => {
                    obj[field.label] = get(tsk, field.value, field.defaultValue);
                        return obj
                    }, {});
                });

                this.dataSource.dataChange.next(items);
            }
        );
    }

    editReasonForExam(code) {
        const dialogRef = this.dialog.open(ReasonForExamEditComponent, {width: '400px'});
        const instance = dialogRef.componentInstance;
        instance.selectedItem = code || new ReasonForExam(0, '', '', 'WITH');

        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                if (code.id) deleteItemFromArray(this.dataSource.data, code);
                this.dataSource.data.push(res);
                this.dataSource.dataChange.next(this.dataSource.data);
            }
        });
    }

    deleteReasonForExam(code) {
        this.dialog.open(DeleteConfirmComponent)
            .afterClosed().subscribe(ok => {
            if (ok) {
                this.settingService.deleteReasonForExam(code.id).subscribe(res => {
                    if (res) {
                        deleteItemFromArray(this.dataSource.data, code);
                        this.dataSource.dataChange.next(this.dataSource.data);
                        this.snack.open('Ligne supprimé avec succès!', 'Ok', {duration: 1500})
                    } else {
                        this.snack.open(
                            'Vous ne pouvez pas supprimer ce motif, car il est assossié à des ordres ou des codes d\'examens', 'Ok', {
                                duration: 3000
                            })
                    }
                });
            }
        });
    }

    ngOnInit() {

        this.displayedColumns = RFE_TABLE_CONF;
        this.cols = RFE_HEADER_COLS;

        this.dataSource = new RFEDataSource(this.paginator, this.sort);
    }

}


export class RFEDataSource extends DataSource<any> {

    filteredData: ReasonForExamItem[] = [];
    renderedData: ReasonForExamItem[] = [];

    /** Stream that emits whenever the data has been modified. */
    dataChange: BehaviorSubject<ReasonForExamItem[]> = new BehaviorSubject<ReasonForExamItem[]>([]);

    constructor(private _paginator: MatPaginator, private _sort: MatSort) {
        super();
    }

    get data(): ReasonForExamItem[] {
        return this.dataChange.value;
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<ReasonForExamItem[]> {
        const displayDataChanges = [
            this.dataChange,
            this._paginator.page,
            this._sort.sortChange,
        ];

        return observableMerge(...displayDataChanges).pipe(map(() => {


            // Filter data
            this.filteredData = this.data.slice().filter((item: ReasonForExamItem) => {
                return true;
            });

            // Sort filtered data
            const sortedData = this.sortData(this.filteredData.slice());

            // Grab the page's slice of the filtered sorted data.
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
            return this.renderedData;
        }));
    }

    disconnect() {
    }

    /** Returns a sorted copy of the database data. */
    sortData(data: ReasonForExamItem[]): ReasonForExamItem[] {
        if (!this._sort.active || this._sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._sort.active) {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'value':
                    [propertyA, propertyB] = [a.value, b.value];
                    break;
                case 'description':
                    [propertyA, propertyB] = [a.description, b.description];
                    break;
                case 'withoutReport':
                    [propertyA, propertyB] = [a.withoutReport, b.withoutReport];
                    break;
            }

            let valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            let valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }


}


export interface ReasonForExamItem {
    id: string;
    value: string;
    description: string;
    withoutReport: string;
}
