import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {SettingService} from './setting/setting.service';

@Injectable()
export class GeneralSettingResolver implements Resolve<Observable<any>> {
    constructor(private setting: SettingService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.setting.getGeneralSetting();
    }
}
