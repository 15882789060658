import {Component, Inject, OnDestroy} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FileElement} from '../../model/file-element';
import {assign} from 'lodash';

@Component({
    selector: 'app-audio-play',
    templateUrl: './audio-play.component.html',
    styleUrls: ['./audio-play.component.scss']
})
export class AudioPlayComponent implements OnDestroy {

    audioFiles: FileElement[];
    current: FileElement;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.audioFiles = this.data.audioFiles.map(elm => assign(elm, {
            url: `/upload-dir/${elm.uuid}.${elm.fileType.toLowerCase()}`
        }));
        this.current = assign(this.data.selected, {
            url: `/upload-dir/${this.data.selected.uuid}.${this.data.selected.fileType.toLowerCase()}`
        });
    }

    ngOnDestroy(): void {
        this.audioFiles = [];
        this.current = null;
    }
}
