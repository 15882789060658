<div class="mat-table">
  <mat-toolbar class="sc-toolbar" color="primary">
    <div class="title">
      <span>{{'BILLING_CODES' | translate}}</span>
    </div>

    <span class="fire-spacer"></span>

    <button [matTooltip]="'ADD_NEW' | translate" mat-button (click)="editBillingCode({})">
      <mat-icon class="add-icon-gradient" fontIcon="mdi-plus-box" fontSet="mdi"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="search-area" fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center"
       style="padding-right: 8px;">
    <mat-form-field floatLabel="never" fxFlex="40%">
            <span matPrefix><mat-icon fontIcon="mdi-magnify" fontSet="mdi" class="prefix-icon" style="top: -3px;"
                                      tabindex="-1"></mat-icon>&nbsp;
            </span>
      <input matInput #filter [placeholder]="'SEARCH' | translate" [autofocus]="true">
    </mat-form-field>

    <mat-spinner *ngIf="isLoadingResults || isRateLimitReached" mode="indeterminate" [strokeWidth]="2"
                 [diameter]="20"></mat-spinner>

  </div>

  <mat-table [dataSource]="dataSource" class="fire-table" [@state]="dataSource.data.length"
             [trackBy]="trackById"
             matSort
             matSortActive="code"
             matSortDirection="asc"
             matSortDisableClear>

    <!--Table-->
    <ng-container *ngFor="let col of displayedColumns" cdkColumnDef="{{col.label}}" [class.hidden]="col.hidden">
      <mat-header-cell *cdkHeaderCellDef class="fire-header-cell" mat-sort-header="{{col.value}}" [disabled]="!col.sortable"> {{col.header | translate}}</mat-header-cell>

      <ng-container>
        <mat-cell *cdkCellDef="let row" class="fire-cell"> {{row[col.label]}}</mat-cell>
      </ng-container>

    </ng-container>

    <!--actions-->
    <ng-container cdkColumnDef="action">
      <mat-header-cell *cdkHeaderCellDef class="fire-header-cell"></mat-header-cell>
      <mat-cell *cdkCellDef="let row" class="fire-cell">
        <button mat-icon-button (click)="$event.stopPropagation(); editBillingCode(row)">
          <mat-icon class="edit-icon-gradient" fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
        </button>
        <button mat-icon-button (click)="$event.stopPropagation(); deleteBillingCode(row)">
          <mat-icon class="delete-icon-gradient" fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <!--header-->
    <mat-header-row *cdkHeaderRowDef="cols; sticky:true" class="fire-header-row"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: cols;"
             class="fire-row">
    </mat-row>
  </mat-table>

  <mat-paginator [length]="resultsLength"
                 [pageIndex]="0"
                 [pageSize]="25"
                 [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
  </mat-paginator>
</div>
