import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FtSpeechRecognition} from '../../../ft-speech-recognition';
import {get} from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {FileElement} from '../../model/file-element';
import {FileService} from '../../service/file.service';

@Component({
    selector: 'app-speech-note',
    templateUrl: './speech-note.component.html',
    styleUrls: ['./speech-note.component.scss']
})
export class SpeechNoteComponent implements OnInit, OnDestroy, AfterViewInit {

    recognition: any;
    words;
    result;
    recording = new BehaviorSubject(false);
    @ViewChild('noteName', { static: true }) noteName: ElementRef;

    constructor(@Inject(MAT_DIALOG_DATA) public data: FileElement,
                private fileService: FileService,
                private dialogRef: MatDialogRef<SpeechNoteComponent>) {
        this.recognition = new FtSpeechRecognition()
    }

    ngOnInit() {
        this.result = $('#result');
    }

    clearInput(e) {
        $('.placeholder').css('display', 'none');
    }

    startSpeak() {
        this.recording.next(true);
        this.recognition.start();
        this.recognition.subject.subscribe(results => {
            let final = get(results, 'results.0.isFinal');
            let transcript = get(results, 'results.0.0.transcript') + (final ? '': ' ');

            transcript = transcript.replace('point', '. ');
            transcript = transcript.replace('virgule', ', ');
            transcript = transcript.replace('espace', ' ');

            if (final) this.result.text(this.result.text() + transcript)

        });
    }

    save() {
        this.recording.next(false);
        this.recognition.stop();
        this.dialogRef.close({noteName: this.noteName.nativeElement.value, note: this.result.text()});
    }

    ngAfterViewInit(): void {
        if (this.data) {
            this.noteName.nativeElement.value = this.data.name;
            this.fetchText(`/upload-dir/${this.data.uuid}.txt`)
        }
    }

    fetchText(file) {
        let self = this;
        fetch(file, {mode: 'cors'})
            .then(function(response) {
                return response.text();
            })
            .then(function(text) {
                self.result.text(text);
            })
            .catch(function(error) {
                console.log(error)
            });
    }

    ngOnDestroy() {
        this.recording.next(false);
        this.recognition.stop();
    }

}
