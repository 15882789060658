<mat-toolbar color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-calendar"></mat-icon>
  {{'DATE_RANGE' | translate}}
  <span fxFlex></span>
  <button mat-icon-button mat-dialog-close="null">
    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
  </button>
</mat-toolbar>

<div fxLayout="column" [formGroup]="dateRangeForm" style="padding: 12px;">

  <mat-form-field appearance="outline">
    <mat-label>{{'FROM' | translate}}</mat-label>
    <input matInput [matDatepicker]="pickerStart" [placeholder]="'START_DATE' | translate" formControlName="startDate">
    <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
    <mat-datepicker #pickerStart></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'TO' | translate}}</mat-label>
    <input matInput [min]="dateRangeForm.get('startDate').valueChanges | async" [matDatepicker]="pickerEnd"
           [placeholder]="'END_DATE' | translate" formControlName="endDate">
    <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
    <mat-datepicker #pickerEnd></mat-datepicker>
  </mat-form-field>

</div>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="dateRangeForm.value">
    {{'OK' | translate}}
  </button>
</mat-dialog-actions>
