import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {HDR_COLS, TBL_CONFS} from './table-conf';
import {SettingService} from '../setting.service';
import {BehaviorSubject, merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {CalendarEvent} from '../../model';
import {HolidayEditComponent} from './holiday-edit/holiday-edit.component';

@Component({
    selector: 'app-holidays-setting',
    templateUrl: './holidays-setting.component.html'
})
export class HolidaysSettingComponent implements AfterViewInit {

    cols: any[];
    displayedColumns = [];
    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    filterChange = new BehaviorSubject('');

    dataSource = new MatTableDataSource();
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private settingService: SettingService, public dialog: MatDialog) {
        this.displayedColumns = TBL_CONFS;
        this.cols = HDR_COLS;
    }

    editHoliday(holiday) {
        this.dialog.open(HolidayEditComponent, {data: holiday || new CalendarEvent()})
            .afterClosed()
            .subscribe(data => {
                if (data) {
                    this.filterChange.next('changed')
                }
            })
    }

    deleteHoliday(holiday) {
        this.settingService.deleteHoliday(holiday).subscribe(res => {
            this.filterChange.next('changed')
        });
    }

    ngAfterViewInit() {

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange.asObservable(), this.paginator.page.asObservable(), this.filterChange)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.settingService.getHolidays(this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction);
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data['totalElements'];
                    return data['content'] as CalendarEvent[];
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.dataSource.data = data);
    }
}
