export const RFE_TABLE_CONF = [
    {
        'label': 'id',
        'header': 'ID',
        'value': 'id',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': true,
        'searchable': true,
        'required': true
    }, {
        'label': 'value',
        'header': 'VALUE',
        'value': 'value',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }, {
        'label': 'description',
        'header': 'DESCRIPTION',
        'value': 'description',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }, {
        'label': 'reportOption',
        'header': 'REPORT_OPTION',
        'value': 'reportOption',
        'type': 'string',
        'unit': 'OPTION',
        'defaultValue': '',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }, {
        'label': 'color',
        'header': 'COLOR',
        'value': 'color',
        'type': 'string',
        'unit': 'color',
        'defaultValue': '#E2E9EE',
        'sortable': false,
        'hidden': false,
        'searchable': true,
        'required': true
    }, {
        'label': 'daysBeforeExamResult',
        'header': 'DAYS_BEFORE_RESULT',
        'value': 'daysBeforeExamResult',
        'type': 'number',
        'unit': null,
        'defaultValue': '#E2E9EE',
        'sortable': false,
        'hidden': false,
        'searchable': true,
        'required': true
    }
];

export const RFE_HEADER_COLS = [
    'value',
    'description',
    'color',
    'reportOption',
    'action'
];
