import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SettingService} from '../setting.service';
import {CommandService, Dictionary, Element, Section, TemplateModel} from '../../model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {flatMap, get, groupBy, sortBy} from 'lodash';
import {DeleteConfirmComponent, deleteItemFromArray, ExcelExamComponent, SharedService} from "../../shared";
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {LOGO} from "../../global-variables";
import * as moment from 'moment';
import {ReportingService} from "../../reporting/reporting.service";
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from "rxjs";
import {variables} from "../../utils/editor";


type ListType = 'STD' | 'NORMAL';

interface TemplateNode {
    name: string;
    children?: TemplateNode[];
}

export class Template {
    constructor(public id: number,
                public name: string,
                public reportModel: string = '',
                public sections: any[] = [],
                public deleted?: boolean,
                public category: string = 'NONE',
                public listType: ListType = 'STD') {
    }
}

interface FlatTemplateNode {
    expandable: boolean;
    name: string;
    level: number;
}

@Component({
    selector: 'app-report-template-setting',
    templateUrl: './report-template-setting.component.html',
    styleUrls: ['./report-template-setting.component.scss']
})
export class ReportTemplateSettingComponent implements OnInit {

    static count: number = 0;

    @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
    // @ViewChild(LoEditorComponent, {static: false}) loEditor: LoEditorComponent;

    sections: Section[] = [];
    dictionaries: Dictionary[] = [];

    selectedTemplate: TemplateModel;
    searchTemplateControl = new FormControl('');

    normalReports: any[];
    standardReports: any[];

    elementTypes: ElementType[];

    input = new FormControl('Input value.');
    selected: any;
    categories: string[] = [];

    private _transformer = (node: TemplateNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
        };
    };

    treeControl = new FlatTreeControl<FlatTemplateNode>(
        node => node.level, node => node.expandable);

    treeControl2 = new FlatTreeControl<FlatTemplateNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    dataSource2 = new MatTreeFlatDataSource(this.treeControl2, this.treeFlattener);

    logo = LOGO;
    public patientVariables: any[];
    public examVariables: any[];


    hasChild = (_: number, node: FlatTemplateNode) => node.expandable;

    group = 'template';
    private documentKey: string;
    private allTemplates: any;


    constructor(private settingService: SettingService,
                private reportingService: ReportingService,
                private shared: SharedService,
                private translate: TranslateService,
                private dialog: MatDialog,
                private _bs: MatBottomSheet,
                private snack: MatSnackBar) {

        this.updateNormalData();
        this.updateStandardData();
        this.elementTypes = ELEMENTTYPES;

        this.patientVariables = variables("PATIENT_VARIABLES", this);
        this.examVariables = variables("EXAM_VARIABLES", this);

        this.searchTemplateControl.valueChanges.subscribe(key => {
            if (key) {
                let templates = this.allTemplates.filter(it => it.name.toLowerCase().includes(key.toLowerCase()));

                let builtTemplates = this.buildTemplateStructure(templates)

                this.dataSource.data = this.adjustTemplates(builtTemplates);

                let reports = groupBy(templates, 'category');
                let categories = Object.keys(reports);
                this.treeControl.dataNodes.filter(it => it.expandable).forEach(node => {
                    if (categories.includes(node.name)) {
                        this.treeControl.expand(node)
                    } else this.treeControl.collapse(node);
                });
            } else this.treeControl.collapseAll();
        });
    }

    selectedItem: TemplateModel;

    showTemplateModel(node: TemplateNode, listType: ListType) {
        this.selectedTemplate = this.selectTemplate(node.name, listType);
        this.sections = this.selectedTemplate ? this.selectedTemplate.sections : [];
    }

    addModel(name: string, listType: ListType) {
        let model = new Template(0, name+'_'+moment().format('HHmmss'));
        model.category = name;
        model.listType = listType;

        this.settingService
            .saveTemplateModel(model)
            .subscribe(template => {
                if (template.listType === 'STD') this.updateStandardData();
                else this.updateNormalData();

                this.selectedTemplate = template;

                this.saveTemplateModel();
            });
    }

    saveTemplateModel() {
        this.reportingService.sendCmd(new CommandService(this.documentKey)).subscribe(res => {
            console.log(res);

            setTimeout(() => {
                this.settingService
                    .saveTemplateModel(this.selectedTemplate)
                    .subscribe(template => {
                        if (template.listType === 'STD') this.updateStandardData();
                        else this.updateNormalData();

                        this.selectedTemplate = template;
                    });
            },500);
        });
    }

    deleteTemplateModel(tm: TemplateModel) {
        this.settingService
            .deleteTemplateModel(tm)
            .subscribe(res => {
                if (tm.listType === 'STD') this.updateStandardData();
                else this.updateNormalData();
                this.snack.open(tm.name + ' a été supprimé avec succès!', 'ok', {duration: 3000})
            })
    }

    compareFn(c1: Dictionary, c2: Dictionary): boolean {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }

    onAddSection() {
        this.sections.push({
            id: 0,
            title: '',
            elements: []
        });

        this.updateTemplate();
    }

    onAddField(section: Section): void {
        section.elements.push({
            id: 0,
            name: '',
            elementType: 'TEXTAREA',
            dictionary: null,
            description: '',
            placeholder: '',
            required: true
        });

        this.updateTemplate();
    }

    deleteSection(sec) {
        deleteItemFromArray(this.sections, sec);
        this.updateTemplate();
    }


    deleteElement(elements, elm) {
        deleteItemFromArray(elements, elm);
        this.updateTemplate();
    }

    updateTemplate() {
        this.shared.changeTemplate(this.selectedTemplate);
    }

    ngOnInit() {
        this.settingService.getDictionaries().subscribe(data => this.dictionaries = data)
    }

    private getDuplicateSections(sections: Section[]): Section[] {
        return sections.map(sec => {
            return {
                id: null,
                title: sec.title,
                elements: this.duplicateElements(sec.elements)
            }
        })
    }

    private duplicateElements(elements: Element[]): Element[] {
        return elements.map(elm => {
            return {
                id: null,
                name: get(elm, 'name'),
                dictionary: get(elm, 'dictionary'),
                description: get(elm, 'description'),
                placeholder: get(elm, 'placeholder'),
                required: get(elm, 'required'),
                elementType: get(elm, 'elementType'),
            }
        })
    }

    moveHere(tm: TemplateModel, cat: any) {
        tm.category = cat;
        this.settingService.saveTemplateModel(tm).subscribe(res => {
            if (tm.listType === 'STD') this.updateStandardData();
            else this.updateNormalData();
        });
    }

    deleteCategory(category:string) {
        this.dialog.open(DeleteConfirmComponent).afterClosed().subscribe(ok => {
            if (ok) {
                this.settingService.deleteTemplateByCategory(category).subscribe(res => {
                    if (res) {
                        this.snack.open(this.translate.instant('DELETE_DONE'), 'OK', {duration:2000});
                        this.updateStandardData();
                    }

                })
            }
        })
    }

    private updateStandardData() {
        this.settingService.getStandardTemplateModels().subscribe(data => {
            this.allTemplates = data;

            this.selected = this.selectedTemplate || data[0];
            this.sections = this.selected.sections;

            this.standardReports = this.buildTemplateStructure(data);

            this.dataSource.data = this.adjustTemplates(this.standardReports);
        });
    }

    private adjustTemplates(templates: { key: string, data: TemplateModel[] }[]): TemplateNode[] {
        return sortBy(templates.map(value => {
            return {
                name: value.key,
                children: sortBy(value.data.map(report => {
                    return {
                        name: report.name,
                        children: []
                    }
                }), 'name')
            };
        }), 'name');
    }

    private updateNormalData() {
        this.settingService.getNormalTemplateModels().subscribe(data => {
            //this.templateModels = data;
            let reports = groupBy(data, 'category');
            this.categories = Object.keys(reports);
            this.normalReports = this.categories.map(key => {
                return {key: key, data: reports[key]}
            });

            this.dataSource2.data = this.adjustTemplates(this.normalReports);


        });
    }

    filterCategories(category: any): string[] {
        return this.categories.filter(value => value !== category)
    }

    newCategory(e) {
        let top = e.clientY + 10;
        e.stopImmediatePropagation();
        this.dialog.open(NewCategoryComponent, {
            position: {top: top + 'px', left: (e.clientX - 180) + 'px'}
        }).afterClosed().subscribe(value => {
            if (value) {
                this.categories.push(value);
                this.selectedTemplate.category = value;
            }
        })
    }

    addNewModel(listType: ListType) {
        let model = new Template(0, 'Model');
        model.listType = listType;

        this.settingService
            .saveTemplateModel(model)
            .subscribe(template => {
                this.selectedTemplate = template;
                if (listType === 'STD') this.standardReports.find(v => v.key === name).data.push(template);
                else this.normalReports.find(v => v.key === name).data.push(template);
                this.showTemplateModel(template, listType);

                this.shared.changeTemplate(this.selectedTemplate);
            });
    }

    private _templates(listType: ListType): any[] {
        if (listType === 'STD') return this.standardReports;
        else return this.normalReports;
    }

    selectTemplate(name: string, listType: ListType): TemplateModel {
        let templates = this._templates(listType);
        return flatMap(templates, 'data').find(value => value.name === name);
    }
    applyHeader() {
        this.settingService
            .saveTemplateModel(this.selectedTemplate)
            .subscribe(template => {
                if (template.listType === 'STD') this.updateStandardData();
                else this.updateNormalData();

                this.selectedTemplate = template;
                this.selected = template;

                this.settingService.applyTemplateHeader().subscribe(res => {
                    if (res) this.snack.open('Header applied successfully!', 'OK', {duration: 2000});
                    else this.snack.open('Failed, either the header file not created or or the name is different from \'header\', try again!', 'OK', {duration: 2000});
                })
            });
    }
    onSaveDocument = (isSaved: boolean) => isSaved && this.saveTemplateModel()

    uploadFiles() {
        this._bs.open(ExcelExamComponent, {
            hasBackdrop: false,
            closeOnNavigation: false,
            disableClose: true
        }).afterDismissed().subscribe(res => {
            if (res) this.saveTemplateModel();
        });
    }

    switchTemplateMode = (event: any) => this.group = event;

    duplicateTemplateModel(node: TemplateNode, listType: ListType) {
        ReportTemplateSettingComponent.count++;

        let tm = this.selectTemplate(node.name, listType);

        let newTemplate =
            new Template(0,
                tm.name + '_' + moment().format('HHmmss'),
                tm.reportModel,
                this.getDuplicateSections(tm.sections),
                false);

        if (tm.listType === 'STD') this.standardReports.push(newTemplate);
        else this.normalReports.push(newTemplate);

        let templateNode = {name: newTemplate.name, children: []};

        this.showTemplateModel(node, tm.listType);
    }

    selectTemplateItem(node: any) {
        this.selectedItem = this.selectTemplate(node.name, 'STD');
    }

    editCategory(node: any) {
        let category = node.name;
        let tm = this.allTemplates.find(it => it.category == category);

        let data = {category, examCategory: tm.examCategory}

        this.dialog.open(NewCategoryComponent, {data}).afterClosed().subscribe(updatedCategory => {
            if (updatedCategory) {
                let {cat, eType} = updatedCategory;
                if (cat == tm.category && eType == tm.examCategory) return;

                this.settingService.updateTemplateCategory(category, updatedCategory).subscribe(res => {
                    this.updateStandardData();
                })
            }
        })
    }

    editorReady(docKey: string) {
        this.documentKey = docKey;
    }


    insertVariable(key) {
        this.reportingService.variableInsert.next(key);
    }

    private buildTemplateStructure(data:any):any {

        let reports = groupBy(data, 'category');
        this.categories = Object.keys(reports);
        return this.categories.map(key => {
            return {key: key, data: reports[key]}
        });
    }
}


export interface ElementType {
    id: string,
    name: string
}

export const ELEMENTTYPES: ElementType[] = [
    {id: '1', name: 'TEXTAREA'},
    {id: '2', name: 'TEXTFIELD'},
    {id: '3', name: 'SELECT'},
    {id: '4', name: 'RADIO'},
    {id: '5', name: 'MULTI'},
];

@Component({
    selector: 'app-new-category',
    template: `
        <mat-toolbar>
            <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
            <span fxFlex></span>
            <button mat-icon-button [mat-dialog-close]="null">
                <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
        </mat-toolbar>
        <mat-dialog-content fxLayout="column" [formGroup]="form">
            <mat-form-field appearance="standard">
                <mat-label>{{'FAMILY' | translate}}</mat-label>
                <input matInput [placeholder]="'FAMILY' | translate" formControlName="category">
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>{{'EXAM_CATEGORY' | translate}}</mat-label>
                <mat-select [placeholder]="'EXAM_CATEGORY' | translate"  formControlName="examCategory">
                    <mat-option *ngFor="let ex of examTypes" [value]="ex">{{ex}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-button color="primary" (click)="save()">{{'SAVE' | translate}}</button>
        </mat-dialog-content>`
})
export class NewCategoryComponent implements OnInit, OnDestroy {
    form: FormGroup;
    examTypes: any;
    private readonly sub$: Subscription;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _fb: FormBuilder, private _shared: SharedService, private dialogRef: MatDialogRef<NewCategoryComponent>) {
        this.sub$ = this._shared.getExamTypes().subscribe(res => this.examTypes = res);
        this.createForm();
    }

    ngOnInit() {
        this.form.patchValue(this.data);
    }

    save() {
        this.dialogRef.close(this.form.value)
    }

    ngOnDestroy() {
        if (this.sub$) this.sub$.unsubscribe();
    }

    private createForm() {
        this.form = this._fb.group({
            category: '', examCategory: ''
        });
    }
}
