import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PatientService} from '../patient.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
    buildDemographics,
    buildInsurance,
    buildMedical,
    CameraComponent,
    DeleteConfirmComponent,
    deleteItemFromArray,
    escapeDemographicNullValues,
    escapeInsuranceNullValues,
    escapeMedicalNullValues,
    FileElement,
    FileService,
    getFileUrl,
    getReportStatus,
    getStatusColor,
    PatientArrivedComponent,
    ReferringPhysicianAddComponent, ReferringPhysiciansSearchComponent,
    SharedService,
    ViewerSelectComponent
} from '../../shared';
import {Location} from '@angular/common';
import {
    addressEntity,
    ADMISSIONS,
    DEBITERS, DefaultValues,
    GeneralSetting,
    Hl7IdName,
    Insurance, Patient, PostalCode,
    WorkflowItem,
    WsMessage
} from '../../model';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {forkJoin, Observable, of as observableOf, of, Subscription} from 'rxjs'

import {assign, find, get, reverse, set as _set, sortBy} from 'lodash';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import * as $ from 'jquery';
import {WorkflowService} from '../../workflow/workflow.service';
import {ReportingService} from '../../reporting/reporting.service';
import {v4} from 'uuid';
import {PathologyEditComponent} from '../../setting/pathology-setting/pathology-edit/pathology-edit.component';
import {SettingService} from '../../setting/setting.service';
import {AppConfigService} from '../../app-config.service';
import {PROFILE_PLACEHOLDER} from '../../global-variables';
import {SchedulerService} from '../../scheduler/scheduler.service';
import {AppointmentEditComponent} from '../../scheduler/appointment-edit/appointment-edit.component';
import {ExamAdvancedComponent} from '../../scheduler/exam-advanced/exam-advanced.component';
import {EventEditComponent} from '../../scheduler/event-edit/event-edit.component';
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

const initCfg = {
    id: '',
    value: '',
    description: ''
};

@Component({
    selector: 'app-patient-detail',
    templateUrl: './patient-detail.component.html',
    styleUrls: ['./patient-detail.component.scss']
})
export class PatientDetailComponent implements OnInit, OnDestroy {

    patient: any;
    genders: any[];
    countries: any[];
    provinces: any[] = [];
    cities: any[] = [];
    maritalStatuses: any[];
    patientStates: any[];
    dangerCodes: any[];
    titles: any[];
    ambulatoryStatuses: any[];
    ethnicGroups: any[];
    reports: any[] = [];
    confidentialities: any[] = [];
    patientClasses: any[];
    form: FormGroup;
    demographicForm: FormGroup;

    medicalForm: FormGroup;
    addressForm: FormGroup;
    appointments: any[];
    debiters: string[];
    admissions: string[];
    sideEffectsList: any[];
    scheduledProcedures: any[];
    orderCancellationReasons: any[];
    organisms: Hl7IdName[];
    conventions: any[];

    profile: any;
    patientPhotoUrl: SafeResourceUrl = PROFILE_PLACEHOLDER;

    @Input() patID: number;
    patientId: number;

    filteredCountries: Observable<any[]>;
    filteredProvinces: Observable<any[]>;
    filteredCities: Observable<any[]>;

    patientExams: WorkflowItem[];

    ageForm: FormGroup;
    age: number;

    fileElements: Observable<FileElement[]>;
    currentRoot: FileElement;
    currentPath: string;
    canNavigateUp = false;
    patientID: string;
    private confidentiality: any;
    private generalSetting: GeneralSetting;
    private subs: Subscription[] = [];
    private readonly dateFormat: string;
    // Attached documents
    referringPhysicianControl = new FormControl('');
    filteredReferringPhysicians: any[] = [];
    filteredPostalCodes: PostalCode[] = [];
    cityControl = new FormControl('');
    postalCodeControl = new FormControl('');
    private readonly momentDatetimeFormat: string;
    filteredOrganisms: Observable<Hl7IdName[]>;

    defaultValues: DefaultValues;
    splitter = (str: string, idx: number): string => str.split('@')[idx];

    constructor(private route: ActivatedRoute,
                private location: Location,
                private _config: AppConfigService,
                private sharedService: SharedService,
                private settingService: SettingService,
                private scheduleService: SchedulerService,
                private service: PatientService,
                private fileService: FileService,
                private workflowService: WorkflowService,
                private reportingService: ReportingService,
                private dialog: MatDialog,
                private router: Router,
                private snackBar: MatSnackBar,
                private sanitizer: DomSanitizer,
                private fb: FormBuilder) {

        this.profile = JSON.parse(localStorage.getItem('profile'));
        this.dateFormat = this._config.dateFormat;
        this.momentDatetimeFormat = this._config.momentDateTimeFormat;
        this.debiters = DEBITERS;
        this.admissions = ADMISSIONS;
        this.createForm();
        this.changeAge();
        this.createAgeForm();

        this.subs.push(this.referringPhysicianControl.valueChanges
            .pipe(
                startWith(''),
                switchMap(() => {
                    let query = this.referringPhysicianControl.value;
                    return this.sharedService.getPaginatedReferringPhysicians(10, 0, 'lastName', 'asc', query);
                }),
                map(data => data['content']),
                catchError(() => {
                    return observableOf([]);
                })
            ).subscribe(data => this.filteredReferringPhysicians = data));
        this.referringPhysicianControl.patchValue('');
    }

    isEditable(sps: any): boolean {
        return this.profile && this.profile.updateOrder && (sps.scheduledProcedureStepStatus === 'SCHEDULED');
    }

    isFuture(sps: any): boolean {
        return moment(sps.scheduledProcedureStepStartDate).isAfter(moment().startOf('day'));
    }

    isSameDate(sps: any): boolean {
        let isSameDay = moment(sps.scheduledProcedureStepStartDate).isSame(moment(), 'day');
        return (sps.scheduledProcedureStepStatus !== 'ARRIVED' && sps.scheduledProcedureStepStatus !== 'STARTED' && isSameDay)
    }

    onSelectCountry(event) {
        let id = find(this.countries, {'value': event.option.value}).id;
        this.getProvinces(id);
    }


    calculateIMCAndSC() {
        let _w = this.form.get('weight').value;
        let _h = this.form.get('height').value / 100;

        let imc = parseFloat((_w / (_h * _h)).toFixed(2));
        this.form.get('imc').patchValue(imc);

        let sc = parseFloat((Math.sqrt(_h * _w) / 6).toFixed(2));
        this.form.get('sc').patchValue(sc);
    }

    createNewAppointment() {
        this.dialog.open(AppointmentEditComponent, {
            data: {
                patient: this.patient
            }
        }).afterClosed()
            .subscribe(ok => {
                if (ok) {
                    this.scheduleService.getPatientAppointments(this.patientId).subscribe(data2 => {
                        this.appointments = data2;

                        this.snackBar.open('Ordre créé avec succès!', 'OK', {
                            duration: 2000,
                        });
                    });
                }
            });
    }


    changeDate() {
        let age = this.ageForm.getRawValue();
        if (age.years !== null && age.months !== null && age.days !== null) {
            this.scheduleService.calculateDOB(`${age.years || '0'}-${age.months || '0'}-${age.days || '0'}`).subscribe((res: WsMessage) => {

                let dob = moment(res.data, this._config.momentDateFormat);
                setTimeout(() => this.demographicForm.patchValue({dateOfBirth: dob}), 200);
            });
        }
    }

    changeAge() {
        let value = this.demographicForm.get('dateOfBirth').value;

        if (value) this.scheduleService.calculateAge(moment(value).format('YYYYMMDD')).subscribe((res: WsMessage) => {
            if (res.data) {
                let age = res.data.split('-');

                this.ageForm.get('years').patchValue(age[0]);
                this.ageForm.get('months').patchValue(age[1]);
                this.ageForm.get('days').patchValue(age[2]);
            }
        });
    }

    onSelectOrganism() {
        let organismName = this.form.get('insurance.organismName').value;
        if (!organismName) return;

        let org = this.organisms.find(it => it.name.trim() == organismName.trim());
        this.form.get('insurance.organismId').patchValue(org.id);
        this.form.get('insurance.code').patchValue(org.code);

        this.findConventions(org.id);
    }

    scheduleNewOrder() {
        this.dialog.open(ExamAdvancedComponent, {
            data: {
                spsStatus: 'ARRIVED',
                resource: 'n/a',
                patient: this.patient,
                isr: null,
                selectedDateRange: {start: moment(), end: moment().add(15, 'h')},
                editable: true,
                queryParam: null,
                panelClass: 'exam-dialog'
            },
            disableClose: true
        }).afterClosed().subscribe(res => {
            this.scheduleService.getPatientScheduledProcedures(String(this.patientId)).subscribe(value => this.scheduledProcedures = value);
        });
    }

    onSelectProvince(event) {
        let id = find(this.provinces, {'value': event.option.value}).id;
        this.getCities(id);
    }

    editImage() {

        this.dialog.open(CameraComponent)
            .afterClosed()
            .subscribe(imageFile => {
                if (imageFile) {
                    const fileUUID = v4();

                    let progress = this.fileService.uploadFiles(imageFile, this.patientID, 'root', fileUUID, true);

                    // convert the progress map into an array
                    let allProgressObservables = [];
                    for (let key in progress) {
                        allProgressObservables.push(progress[key]['progress']);
                    }

                    forkJoin(allProgressObservables).subscribe(end => {
                        this.updateFileElementQuery();
                        this.fileElements.subscribe(data => this.patientPhotoUrl = getFileUrl(data.find(file => file.uuid === fileUUID)));
                        this.form.get('patientPhotoUUID').patchValue(fileUUID);
                    });
                }
            })
    }

    ngOnInit() {
        forkJoin([
            this.sharedService.getGenders(),
            this.sharedService.getCountries(),
            this.sharedService.getMaritalStatuses(),
            this.sharedService.getTitles(),
            this.sharedService.getPatientStats(),
            this.sharedService.getEthnicGroups(),
            this.sharedService.getDangerCodes(),
            this.sharedService.getAmbulatoryStatuses(),
            this.sharedService.getOrderCancellationReasons(),
            this.sharedService.getConfidentialities(),
            this.sharedService.getPatientClasses(),
            this.sharedService.getAllergies(),
            this.settingService.getGeneralSetting(),
            this.sharedService.getOrganismsList(),
            this.settingService.getDefaultValues(),
        ]).subscribe(data => {
            [
                this.genders,
                this.countries,
                this.maritalStatuses,
                this.titles,
                this.patientStates,
                this.ethnicGroups,
                this.dangerCodes,
                this.ambulatoryStatuses,
                this.orderCancellationReasons,
                this.confidentialities,
                this.patientClasses,
                this.sideEffectsList,
                this.generalSetting,
                this.organisms,
                this.defaultValues,
            ] = data;

            this.countrySelect();

            this.filteredOrganisms = this.form.get('insurance.organismName').valueChanges.pipe(
                startWith(''),
                map(value => this._filter(value))
            );

            this.form.controls['externalPatientID'].disable();
            this.form.controls['imc'].disable();
            this.form.controls['sc'].disable();

            let id;

            this.route.params.pipe(
                switchMap((params: Params) => {
                    id = +params['id'] || this.patID;
                    this.patientId = id;
                    return id ? this.service.getPatient(id): of(new Patient());
                })).subscribe(patient => this.setPatientDetails(this.patientId, patient));

            this.demographicForm.get('confidentiality').valueChanges.subscribe(confidentiality => {
                this.confidentiality = this.confidentialities.find(c => c.id === confidentiality.id)
            });

            this.subs.push(this.cityControl.valueChanges
                .pipe(
                    startWith(''),
                    switchMap(query => this.sharedService.getPaginatedPostalCodes(10, 0, 'code', 'asc', query)),
                    map(data => data['content']),
                    catchError(() => observableOf([]))
                ).subscribe(data => this.filteredPostalCodes = data));
            this.cityControl.patchValue('');

            this.subs.push(this.postalCodeControl.valueChanges
                .pipe(
                    startWith(''),
                    switchMap(query => this.sharedService.getPaginatedPostalCodes(10, 0, 'code', 'asc', query)),
                    map(data => data['content']),
                    catchError(() => observableOf([]))
                ).subscribe(data => this.filteredPostalCodes = data));
            this.postalCodeControl.patchValue('');
        });
    }

    private _filter(value: string): Hl7IdName[] {
        const filterValue = value ? value.toLowerCase() : '';

        return this.organisms.filter(it => (it.name + it.code).toLowerCase().includes(filterValue));
    }

    addAllergy(event) {
        event.stopPropagation();
        this.dialog.open(PathologyEditComponent, {
            data: {type: 'external', icon: 'plus', title: 'NEW_ALLERGY'},
            disableClose: true
        })
            .afterClosed()
            .subscribe(data => {
                if (data) {
                    this.sharedService.createAllergy(data).subscribe(res => {
                        this.sideEffectsList.push(res);
                        let allergies = this.medicalForm.get('allergies').value;
                        allergies.push(res.value);
                        this.medicalForm.get('allergies').patchValue(allergies);
                    });
                }
            });
    }

    private countrySelect() {
        this.filteredCountries = this.addressForm.controls['country'].valueChanges.pipe(
            map(country => country && this.countries && this.countries.length !== 0 ? this.filterList(country, this.countries) : this.countries.slice()),);


        this.filteredProvinces = this.addressForm.controls['province'].valueChanges.pipe(
            map(province => province ? this.filterList(province, this.provinces) : this.provinces.slice()),);

        this.filteredCities = this.addressForm.controls['city'].valueChanges.pipe(
            map(city => city ? this.filterList(city, this.cities) : this.cities.slice()),);
    }

    savePatient(patient: any): void {
        let pat = this.preparePatient(patient);
        assign(pat.demographic, {confidentiality: this.confidentiality});
        assign(pat.address, {city: this.cityControl.value, postalCode: this.postalCodeControl.value});

        if (patient.id) {

            this.service.savePatient(pat).subscribe(patient => {
                this.setPatientDetails(this.patientId, patient);
                this.service.patientSelected.next(patient.fullName);
                this.snackBar.open('Modifications enregistrées avec succès!', 'Ok', {duration: 2000});
            });

        } else {
            this.service.preparePatient(pat).subscribe(patient => {
                this.setPatientDetails(this.patientId, patient);
                this.service.patientSelected.next(patient.fullName);
                this.snackBar.open('Modifications enregistrées avec succès!', 'Ok', {duration: 2000});
            });
        }
    }

    goBack(): void {
        this.service.patientSelected.next(null);
        this.location.back();
    }

    savePatientAndBack(patient: any): void {

        let pat = this.preparePatient(patient);

        if (patient.id) {
            this.service.savePatient(pat).subscribe({
                next: patient => {
                    this.setPatientDetails(this.patientId, patient);
                },
                error: err => {
                    console.log(err)
                },
                complete: () => this.goBack()
            });

        } else {
            this.service.preparePatient(pat).subscribe( {
                next: patient => {
                    this.setPatientDetails(this.patientId, patient);
                },
                error: err => {
                    console.log(err)
                },
                complete: () => this.goBack()
            })
        }

    }

    editSPS(sps) {
        this.dialog.open(EventEditComponent, {
            data: {
                spsId: sps.id,
                rpId: sps.requestedProcedureId
            },
        }).afterClosed().subscribe(res => {
            if (res) {
                deleteItemFromArray(this.scheduledProcedures, sps);
                this.scheduledProcedures.push(res);
            }
        });
    }

    onScheduleAppointment(apt) {
        this.dialog.open(ExamAdvancedComponent, {
            data: {
                spsStatus: 'ARRIVED',
                resource: 'n/a',
                patient: get(apt, 'patient'),
                isr: apt,
                selectedDateRange: {start: moment(), end: moment().add(15, 'h')},
                editable: true,
                queryParam: null,
                panelClass: 'exam-dialog'
            },
            disableClose: true
        }).afterClosed().subscribe(value => {
            if (value) {
                deleteItemFromArray(this.appointments, apt);
                this.scheduleService.getPatientScheduledProcedures(String(this.patientId)).subscribe(value => this.scheduledProcedures = reverse(sortBy(value, 'scheduledProcedureStartDate')))
            }
        });
    }

    onDeleteAppointments(apt) {
        this.dialog
            .open(DeleteConfirmComponent)
            .afterClosed()
            .subscribe(ok => {
                if (ok) {
                    deleteItemFromArray(this.appointments, apt);
                    this.scheduleService
                        .deleteAppointment(apt.id)
                        .subscribe();
                }
            });
    }

    deleteSPS(sps) {
        this.dialog.open(DeleteConfirmComponent).afterClosed().subscribe(ok => {
            if (ok) this.scheduleService.deleteEvent(sps.id).subscribe(_ => deleteItemFromArray(this.scheduledProcedures, sps));
        });
    }

    enterPatient(sps: any) {

        this.scheduleService.getISRByRpID(sps.requestedProcedureId).subscribe(isr => {
            this.dialog
                .open(PatientArrivedComponent, {data: {isr: isr}, width: '600px'})
                .afterClosed()
                .subscribe(isr => {
                    if (isr) {
                        this.scheduleService.markPatientAsArrived(isr)
                            .subscribe(next => {
                                sps.scheduledProcedureStepStatus = 'ARRIVED';
                                this.snackBar.open('Patient marqué arrivé', 'Ok', {duration: 2000});
                            });
                    }
                });
        });

    }

    printRDV(sps: any) {
        this.scheduleService.printRDV(sps.id).subscribe(ok => {
            if (ok) {
                this.showPreview(`/templates/rdv.html?v=${moment().format('YYYYMMDDHHmmssSSS')}`);
            } else console.log('Can\'t print rdv');
        });
    }

    showPreview(url) {
        let $iframe = $('<iframe />').attr('src', url).css({position: 'absolute', top: '-9999px'});
        $iframe.on('load', () => setTimeout(() => $iframe.remove(), 0));
        $(document.body).append($iframe);
    }

    canPrint(row: any): boolean {
        let status = this.getReportStatus(row.reportStatus);
        return (status === 'VALIDATED' || status === 'SIGNED');
    }

    private filterList(value: any, list: any[]) {
        return list.filter(item =>
            item.value.toLowerCase().indexOf(value.toLowerCase()) === 0);

    }


    private preparePatient(patient: any): any {
        return assign(patient, {
            title: patient.title?.id === '' ? null : patient.title,
            patientClass: patient.patientClass?.id === '' ? null : patient.patientClass,
            externalPatientID: this.form.controls['externalPatientID'].value,
            imc: this.form.controls['imc'].value,
            sc: this.form.controls['sc'].value,
            demographic: escapeDemographicNullValues(this.demographicForm.getRawValue()),
            medicalHistory: escapeMedicalNullValues(this.medicalForm.getRawValue()),
            address: this.addressForm.value,
            confidential: this.demographicForm.get('confidentiality.id').value === 3,
            insurance: escapeInsuranceNullValues(patient.insurance)
        });
    }

    downloadReport(row) {
        this.reportingService.printReportingTask(row.reportingTaskId).subscribe(res => {
            let mediaType = 'application/pdf';
            let blob = new Blob([res], {type: mediaType});
            let filename = 'Report_' + moment().format('YYYYMMDDHHmmss') + '.pdf';
            FileSaver.saveAs(blob, filename);
        });
    }

    openReportingTask(row) {
        this.router.navigate(['/reporting/report-edition', row.reportingTaskId]);
    }

    openInOsirix(row) {
        this.dialog.open(ViewerSelectComponent, {width: '400px'})
            .afterClosed().subscribe(res => {
            if (res) {
                this.reportingService.openOsirix(row.studyInstanceUID, res.aETitle)
                    .subscribe(res => {
                        if (!res) this.snackBar.open('Les images ne sont pas en ligne', 'Ok', {duration: 2000})
                    });
            }
        });
    }

    openInViewer(studyInstanceUID: string) {
        this.reportingService.openWeasis(studyInstanceUID).subscribe();
    }

    getColor(status: string = '_'): string {
        return getStatusColor(status);
    }

    getReportStatus(status: string): string {
        return getReportStatus(status);
    }

    removeElement(element: FileElement) {
        this.fileService.deleteFile(element).subscribe(ok => {
            if (ok) this.updateFileElementQuery();
        });
    }

    getProvinces(countryId: number) {
        this.provinces = [];
        this.sharedService.getProvinces(countryId).subscribe(data => {
            this.provinces = data;
            this.addressForm.controls['province'].setValue(data.length !== 0 ? data[0].value : '');
            this.filteredProvinces = this.addressForm.controls['province'].valueChanges.pipe(
                startWith(null),
                map(province => province ? this.filterList(province, this.provinces) : this.provinces.slice()),);
        });
    }

    getCities(provinceId: number) {
        this.cities = [];
        this.sharedService.getCities(provinceId).subscribe(data => {
            this.cities = data;
            this.addressForm.controls['city'].setValue(data.length !== 0 ? data[0].value : '');
            this.filteredCities = this.addressForm.controls['city'].valueChanges.pipe(
                startWith(null),
                map(city => city ? this.filterList(city, this.cities) : this.cities.slice()),);
        });
    }

    createForm() {

        this.form = this.fb.group({
            id: '',
            externalPatientID: new FormControl(''),
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.email],
            phone: ['', [Validators.maxLength(10), Validators.pattern(new RegExp('\\d'))]],
            cin: ['', Validators.maxLength(12)],
            nationalNumber: ['', Validators.maxLength(20)],
            debiter: 'PATIENT',
            admissionNumber: '',
            createdDate: [new Date()],
            weight: 0,
            height: 0,
            imc: 0.00,
            sc: 0.00,
            patientPhotoUUID: '',
            consultingDoctor: null,
            fileElements: [],
            insurance: this.fb.group(new Insurance()),
            title: this.fb.group({id: ''}),
            patientClass: this.fb.group({id: ''}),
            imageBase64: '',
            confidential: false
        });

        this.addressForm = this.fb.group(addressEntity);

        this.demographicForm = this.fb.group({
            id: '',
            dateOfBirth: [null],
            patientComments: '',
            confidentialityConstraintOnPatientData: '',
            gender: this.fb.group({id: ''}),
            maritalStatus: this.fb.group(initCfg),
            ambulatoryStatus: this.fb.group(initCfg),
            ethnicGroup: this.fb.group(initCfg),
            confidentiality: this.fb.group(initCfg)
        });

        this.medicalForm = this.fb.group({
            id: '',
            medicalAlerts: '',
            specialNeeds: '',
            additionalPatientHistory: '',
            allergies: '',
            observations: '',
            patientState: this.fb.group(initCfg),
            dangerCode: this.fb.group(initCfg),
        });

        this.form.get('confidential').valueChanges.subscribe(value => {
            if (value) this.demographicForm.get('confidentiality').patchValue(this.confidentialities.find(c => c.value === 'VIP'));
            else this.demographicForm.get('confidentiality').patchValue(this.confidentialities.find(c => c.value === 'U'));
        });
    }

    addReferringPhysician() {
        this.subs.push(this.dialog.open(ReferringPhysicianAddComponent)
            .afterClosed()
            .subscribe(physician => {
                if (physician) {
                    this.filteredReferringPhysicians.push(physician);
                    this.referringPhysicianControl.patchValue(physician.fullName);
                    this.form.get('consultingDoctor').patchValue(physician);
                }
            }))
    }

    onChangeRefPhy(event) {
        let physician = event.option.value;
        this.form.get('consultingDoctor').patchValue(physician);
        this.referringPhysicianControl.patchValue(physician.fullName);
    }

    compareConvention(c1: string, c2: string): boolean {
        return c1 && c2 ? c1.split('@')[0] == c2.split('@')[0] : c1 == c2;
    }

    formatDate(date: any, arr = false) {
        if (arr) {
            return date ? moment(date, 'YYYY-MM-DDTHH:mm').format(this.momentDatetimeFormat) : '-';
        }
        return date ? moment(date, 'YYYY-MM-DD').format(this.dateFormat) : '-';
    }

    addFolder(folder: { name: string }) {
        let file: FileElement = {
            folder: true,
            patientID: this.patientID,
            name: folder.name,
            parent: this.currentRoot ? this.currentRoot.uuid : 'root'
        };

        this.fileService.createFile(file).subscribe((res: FileElement) => {
            this.updateFileElementQuery();
        });
    }

    afterUpload(event) {
        if (event) this.updateFileElementQuery();
    }

    moveElement(event: { element: FileElement; moveTo: FileElement }) {

        let elt = event.element;
        _set(elt, 'parent', event.moveTo.uuid);

        this.fileService.updateFile(elt).subscribe(res => {
            this.updateFileElementQuery();
        });
    }

    renameElement(element: FileElement) {
        _set(element, 'name', element.name);

        this.fileService.updateFile(element).subscribe(res => {
            this.updateFileElementQuery();
        });
    }

    updateFileElementQuery() {
        this.fileElements = this.fileService.getPatientDocuments(this.patientID, this.currentRoot ? this.currentRoot.uuid : 'root');
    }

    private findConventions(organismId: any) {
        this.subs
            .push(this.sharedService.getOrganismConventions(organismId)
                .subscribe(res => {
                    if (res) this.conventions = res.map(it => it.split('@')[0]);
                }));
    }

    navigateUp() {
        if (this.currentRoot && this.currentRoot.parent === 'root') {
            this.currentRoot = null;
            this.canNavigateUp = false;
            this.updateFileElementQuery();
        } else {
            this.currentRoot = this.fileService.get(this.currentRoot.parent);
            this.updateFileElementQuery();
        }
        this.currentPath = this.popFromPath(this.currentPath);
    }

    navigateToFolder(element: FileElement) {
        this.currentRoot = element;
        this.updateFileElementQuery();
        this.currentPath = this.pushToPath(this.currentPath, element.name);
        this.canNavigateUp = true;
    }

    pushToPath(path: string, folderName: string) {
        let p = path ? path : '';
        p += `${folderName}/`;
        return p;
    }

    popFromPath(path: string) {
        let p = path ? path : '';
        let split = p.split('/');
        split.splice(split.length - 2, 1);
        p = split.join('/');
        return p;
    }

    ngOnDestroy(): void {
        this.service.patientSelected.next(null)
        this.subs.forEach(it => it.unsubscribe());
    }

    private createAgeForm() {
        this.ageForm = this.fb.group({years: '', months: '', days: ''});
    }

    private setPatientDetails(id: any, patient: any) {
        this.service.patientSelected.next(patient.fullName);
        this.patient = patient;
        this.patientID = patient.externalPatientID;

        this.form.patchValue(assign(patient, {
            title: patient.title || initCfg,
            patientClass: patient.patientClass || initCfg,
            insurance: buildInsurance(patient.insurance)
        }));

        this.onSelectOrganism();

        this.referringPhysicianControl.patchValue(patient.consultingDoctor ? patient.consultingDoctor.fullName : '');
        this.demographicForm.patchValue(buildDemographics(patient));

        this.medicalForm.patchValue(buildMedical(patient));
        this.addressForm.patchValue(patient.address || {id: ''});
        this.cityControl.patchValue(patient.address ? patient.address.city : '');
        this.postalCodeControl.patchValue(patient.address ? patient.address.postalCode : '');

        let dob = moment(this.demographicForm.get('dateOfBirth').value);
        this.age = moment.duration(moment().diff(dob)).years();

        if (id) {
            forkJoin([this.scheduleService.getPatientAppointments(id),
                this.scheduleService.getPatientScheduledProcedures(id),
                this.workflowService.getPatientWorkflow(20, 0, '', 'desc', patient.externalPatientID)
            ]).subscribe(data => {
                this.appointments = data[0];
                this.scheduledProcedures = reverse(sortBy(data[1], 'scheduledProcedureStartDate'));
                this.patientExams = data[2]['content'] as WorkflowItem[];
            });
        } else {
            let patientClass = this.patientClasses.find(it => it.value === this.defaultValues.defaultPatientClass);
            let maritalStatus = this.maritalStatuses.find(it => it.value === this.defaultValues.defaultMaritalStatus);
            let gender = this.genders.find(it => it.value === this.defaultValues.defaultGender);
            let title = this.titles.find(it => it.value === this.defaultValues.defaultTitle);
            let country = this.defaultValues.defaultCountry;
            let city = this.defaultValues.defaultCity;
            let confidentiality = this.confidentialities.find(it => it.value === this.defaultValues.defaultConfidentiality);
            let debiter = DEBITERS.find(it => it === this.defaultValues.defaultPaymentModality);
            let relationWithInsured = ['HIMSELF', 'PARTNER', 'CHILD'].find(it => it === this.defaultValues.defaultRelationWithInsured);

            this.form.patchValue({title, patientClass, debiter});
            this.form.get('insurance.relationWithInsured').patchValue(relationWithInsured);

            this.addressForm.patchValue({city, country});
            setTimeout(() => this.cityControl.patchValue(city));

            this.demographicForm.patchValue({confidentiality, maritalStatus, gender});
        }

        this.fileService.getPatientDocuments(this.patientID, 'root').subscribe(files => {
            this.fileElements = of(files);

            const photo = files.find(file => file.patientPhoto && file.uuid === this.patient.patientPhotoUUID);
            if (photo) this.patientPhotoUrl = getFileUrl(photo);
        });

        if (patient.imageBase64) this.patientPhotoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(patient.imageBase64 || PROFILE_PLACEHOLDER);

        this.changeAge();
    }

    findReferringPhysician() {
        this.subs.push(this.dialog.open(ReferringPhysiciansSearchComponent, {
            minWidth: '600px',
            minHeight: '60vh',
            disableClose: true
        }).afterClosed().subscribe(physician => {
            if (physician) {
                this.filteredReferringPhysicians.push(physician);
                this.referringPhysicianControl.patchValue(physician.fullName);
                this.form.get('consultingDoctor').patchValue(physician);
            }
        }))
    }

    onChangePostalCode(event: MatAutocompleteSelectedEvent) {
        let postalCode = event.option.value;

        this.addressForm.get('city').patchValue(postalCode.location);
        this.addressForm.get('postalCode').patchValue(postalCode.code);
        this.cityControl.patchValue(postalCode.location);
        this.postalCodeControl.patchValue(postalCode.code);
    }
}
