export const BCODE_TABLE_CONF = [
    {
        label: 'id',
        header: 'ID',
        value: 'id',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: true,
        searchable: true,
        required: true
    },{
        label: 'code',
        header: 'CODE',
        value: 'code',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: 'description',
        header: 'CODE_NGAP',
        value: 'description',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: 'price',
        header: 'PRICE',
        value: 'price',
        type: 'string',
        unit: null,
        defaultValue: '0',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    }
];

export const BCODE_HEADER_COLS = [
    'description',
    'code',
    'price',
    'action'
];
