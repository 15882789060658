export const ICONS = [
        'mdi-ab-testing',
        'mdi-abjad-arabic',
        'mdi-abjad-hebrew',
        'mdi-abugida-devanagari',
        'mdi-abugida-thai',
        'mdi-access-point',
        'mdi-access-point-network',
        'mdi-access-point-network-off',
        'mdi-account',
        'mdi-account-alert',
        'mdi-account-alert-outline',
        'mdi-account-arrow-left',
        'mdi-account-arrow-left-outline',
        'mdi-account-arrow-right',
        'mdi-account-arrow-right-outline',
        'mdi-account-box',
        'mdi-account-box-multiple',
        'mdi-account-box-multiple-outline',
        'mdi-account-box-outline',
        'mdi-account-cancel',
        'mdi-account-cancel-outline',
        'mdi-account-cash',
        'mdi-account-cash-outline',
        'mdi-account-check',
        'mdi-account-check-outline',
        'mdi-account-child',
        'mdi-account-child-circle',
        'mdi-account-child-outline',
        'mdi-account-circle',
        'mdi-account-circle-outline',
        'mdi-account-clock',
        'mdi-account-clock-outline',
        'mdi-account-cog',
        'mdi-account-cog-outline',
        'mdi-account-convert',
        'mdi-account-convert-outline',
        'mdi-account-cowboy-hat',
        'mdi-account-details',
        'mdi-account-details-outline',
        'mdi-account-edit',
        'mdi-account-edit-outline',
        'mdi-account-group',
        'mdi-account-group-outline',
        'mdi-account-hard-hat',
        'mdi-account-heart',
        'mdi-account-heart-outline',
        'mdi-account-key',
        'mdi-account-key-outline',
        'mdi-account-lock',
        'mdi-account-lock-outline',
        'mdi-account-minus',
        'mdi-account-minus-outline',
        'mdi-account-multiple',
        'mdi-account-multiple-check',
        'mdi-account-multiple-check-outline',
        'mdi-account-multiple-minus',
        'mdi-account-multiple-minus-outline',
        'mdi-account-multiple-outline',
        'mdi-account-multiple-plus',
        'mdi-account-multiple-plus-outline',
        'mdi-account-multiple-remove',
        'mdi-account-multiple-remove-outline',
        'mdi-account-music',
        'mdi-account-music-outline',
        'mdi-account-network',
        'mdi-account-network-outline',
        'mdi-account-off',
        'mdi-account-off-outline',
        'mdi-account-outline',
        'mdi-account-plus',
        'mdi-account-plus-outline',
        'mdi-account-question',
        'mdi-account-question-outline',
        'mdi-account-remove',
        'mdi-account-remove-outline',
        'mdi-account-search',
        'mdi-account-search-outline',
        'mdi-account-settings',
        'mdi-account-settings-outline',
        'mdi-account-star',
        'mdi-account-star-outline',
        'mdi-account-supervisor',
        'mdi-account-supervisor-circle',
        'mdi-account-supervisor-outline',
        'mdi-account-switch',
        'mdi-account-switch-outline',
        'mdi-account-tie',
        'mdi-account-tie-outline',
        'mdi-account-tie-voice',
        'mdi-account-tie-voice-off',
        'mdi-account-tie-voice-off-outline',
        'mdi-account-tie-voice-outline',
        'mdi-account-voice',
        'mdi-adjust',
        'mdi-adobe',
        'mdi-adobe-acrobat',
        'mdi-air-conditioner',
        'mdi-air-filter',
        'mdi-air-horn',
        'mdi-air-humidifier',
        'mdi-air-humidifier-off',
        'mdi-air-purifier',
        'mdi-airbag',
        'mdi-airballoon',
        'mdi-airballoon-outline',
        'mdi-airplane',
        'mdi-airplane-landing',
        'mdi-airplane-off',
        'mdi-airplane-takeoff',
        'mdi-airport',
        'mdi-alarm',
        'mdi-alarm-bell',
        'mdi-alarm-check',
        'mdi-alarm-light',
        'mdi-alarm-light-outline',
        'mdi-alarm-multiple',
        'mdi-alarm-note',
        'mdi-alarm-note-off',
        'mdi-alarm-off',
        'mdi-alarm-plus',
        'mdi-alarm-snooze',
        'mdi-album',
        'mdi-alert',
        'mdi-alert-box',
        'mdi-alert-box-outline',
        'mdi-alert-circle',
        'mdi-alert-circle-check',
        'mdi-alert-circle-check-outline',
        'mdi-alert-circle-outline',
        'mdi-alert-decagram',
        'mdi-alert-decagram-outline',
        'mdi-alert-minus',
        'mdi-alert-minus-outline',
        'mdi-alert-octagon',
        'mdi-alert-octagon-outline',
        'mdi-alert-octagram',
        'mdi-alert-octagram-outline',
        'mdi-alert-outline',
        'mdi-alert-plus',
        'mdi-alert-plus-outline',
        'mdi-alert-remove',
        'mdi-alert-remove-outline',
        'mdi-alert-rhombus',
        'mdi-alert-rhombus-outline',
        'mdi-alien',
        'mdi-alien-outline',
        'mdi-align-horizontal-center',
        'mdi-align-horizontal-left',
        'mdi-align-horizontal-right',
        'mdi-align-vertical-bottom',
        'mdi-align-vertical-center',
        'mdi-align-vertical-top',
        'mdi-all-inclusive',
        'mdi-allergy',
        'mdi-alpha',
        'mdi-alpha-a',
        'mdi-alpha-a-box',
        'mdi-alpha-a-box-outline',
        'mdi-alpha-a-circle',
        'mdi-alpha-a-circle-outline',
        'mdi-alpha-b',
        'mdi-alpha-b-box',
        'mdi-alpha-b-box-outline',
        'mdi-alpha-b-circle',
        'mdi-alpha-b-circle-outline',
        'mdi-alpha-c',
        'mdi-alpha-c-box',
        'mdi-alpha-c-box-outline',
        'mdi-alpha-c-circle',
        'mdi-alpha-c-circle-outline',
        'mdi-alpha-d',
        'mdi-alpha-d-box',
        'mdi-alpha-d-box-outline',
        'mdi-alpha-d-circle',
        'mdi-alpha-d-circle-outline',
        'mdi-alpha-e',
        'mdi-alpha-e-box',
        'mdi-alpha-e-box-outline',
        'mdi-alpha-e-circle',
        'mdi-alpha-e-circle-outline',
        'mdi-alpha-f',
        'mdi-alpha-f-box',
        'mdi-alpha-f-box-outline',
        'mdi-alpha-f-circle',
        'mdi-alpha-f-circle-outline',
        'mdi-alpha-g',
        'mdi-alpha-g-box',
        'mdi-alpha-g-box-outline',
        'mdi-alpha-g-circle',
        'mdi-alpha-g-circle-outline',
        'mdi-alpha-h',
        'mdi-alpha-h-box',
        'mdi-alpha-h-box-outline',
        'mdi-alpha-h-circle',
        'mdi-alpha-h-circle-outline',
        'mdi-alpha-i',
        'mdi-alpha-i-box',
        'mdi-alpha-i-box-outline',
        'mdi-alpha-i-circle',
        'mdi-alpha-i-circle-outline',
        'mdi-alpha-j',
        'mdi-alpha-j-box',
        'mdi-alpha-j-box-outline',
        'mdi-alpha-j-circle',
        'mdi-alpha-j-circle-outline',
        'mdi-alpha-k',
        'mdi-alpha-k-box',
        'mdi-alpha-k-box-outline',
        'mdi-alpha-k-circle',
        'mdi-alpha-k-circle-outline',
        'mdi-alpha-l',
        'mdi-alpha-l-box',
        'mdi-alpha-l-box-outline',
        'mdi-alpha-l-circle',
        'mdi-alpha-l-circle-outline',
        'mdi-alpha-m',
        'mdi-alpha-m-box',
        'mdi-alpha-m-box-outline',
        'mdi-alpha-m-circle',
        'mdi-alpha-m-circle-outline',
        'mdi-alpha-n',
        'mdi-alpha-n-box',
        'mdi-alpha-n-box-outline',
        'mdi-alpha-n-circle',
        'mdi-alpha-n-circle-outline',
        'mdi-alpha-o',
        'mdi-alpha-o-box',
        'mdi-alpha-o-box-outline',
        'mdi-alpha-o-circle',
        'mdi-alpha-o-circle-outline',
        'mdi-alpha-p',
        'mdi-alpha-p-box',
        'mdi-alpha-p-box-outline',
        'mdi-alpha-p-circle',
        'mdi-alpha-p-circle-outline',
        'mdi-alpha-q',
        'mdi-alpha-q-box',
        'mdi-alpha-q-box-outline',
        'mdi-alpha-q-circle',
        'mdi-alpha-q-circle-outline',
        'mdi-alpha-r',
        'mdi-alpha-r-box',
        'mdi-alpha-r-box-outline',
        'mdi-alpha-r-circle',
        'mdi-alpha-r-circle-outline',
        'mdi-alpha-s',
        'mdi-alpha-s-box',
        'mdi-alpha-s-box-outline',
        'mdi-alpha-s-circle',
        'mdi-alpha-s-circle-outline',
        'mdi-alpha-t',
        'mdi-alpha-t-box',
        'mdi-alpha-t-box-outline',
        'mdi-alpha-t-circle',
        'mdi-alpha-t-circle-outline',
        'mdi-alpha-u',
        'mdi-alpha-u-box',
        'mdi-alpha-u-box-outline',
        'mdi-alpha-u-circle',
        'mdi-alpha-u-circle-outline',
        'mdi-alpha-v',
        'mdi-alpha-v-box',
        'mdi-alpha-v-box-outline',
        'mdi-alpha-v-circle',
        'mdi-alpha-v-circle-outline',
        'mdi-alpha-w',
        'mdi-alpha-w-box',
        'mdi-alpha-w-box-outline',
        'mdi-alpha-w-circle',
        'mdi-alpha-w-circle-outline',
        'mdi-alpha-x',
        'mdi-alpha-x-box',
        'mdi-alpha-x-box-outline',
        'mdi-alpha-x-circle',
        'mdi-alpha-x-circle-outline',
        'mdi-alpha-y',
        'mdi-alpha-y-box',
        'mdi-alpha-y-box-outline',
        'mdi-alpha-y-circle',
        'mdi-alpha-y-circle-outline',
        'mdi-alpha-z',
        'mdi-alpha-z-box',
        'mdi-alpha-z-box-outline',
        'mdi-alpha-z-circle',
        'mdi-alpha-z-circle-outline',
        'mdi-alphabet-aurebesh',
        'mdi-alphabet-cyrillic',
        'mdi-alphabet-greek',
        'mdi-alphabet-latin',
        'mdi-alphabet-piqad',
        'mdi-alphabet-tengwar',
        'mdi-alphabetical',
        'mdi-alphabetical-off',
        'mdi-alphabetical-variant',
        'mdi-alphabetical-variant-off',
        'mdi-altimeter',
        'mdi-amazon',
        'mdi-amazon-alexa',
        'mdi-ambulance',
        'mdi-ammunition',
        'mdi-ampersand',
        'mdi-amplifier',
        'mdi-amplifier-off',
        'mdi-anchor',
        'mdi-android',
        'mdi-android-auto',
        'mdi-android-debug-bridge',
        'mdi-android-messages',
        'mdi-android-studio',
        'mdi-angle-acute',
        'mdi-angle-obtuse',
        'mdi-angle-right',
        'mdi-angular',
        'mdi-angularjs',
        'mdi-animation',
        'mdi-animation-outline',
        'mdi-animation-play',
        'mdi-animation-play-outline',
        'mdi-ansible',
        'mdi-antenna',
        'mdi-anvil',
        'mdi-apache-kafka',
        'mdi-api',
        'mdi-api-off',
        'mdi-apple',
        'mdi-apple-airplay',
        'mdi-apple-finder',
        'mdi-apple-icloud',
        'mdi-apple-ios',
        'mdi-apple-keyboard-caps',
        'mdi-apple-keyboard-command',
        'mdi-apple-keyboard-control',
        'mdi-apple-keyboard-option',
        'mdi-apple-keyboard-shift',
        'mdi-apple-safari',
        'mdi-application',
        'mdi-application-export',
        'mdi-application-import',
        'mdi-approximately-equal',
        'mdi-approximately-equal-box',
        'mdi-apps',
        'mdi-apps-box',
        'mdi-arch',
        'mdi-archive',
        'mdi-archive-arrow-down',
        'mdi-archive-arrow-down-outline',
        'mdi-archive-arrow-up',
        'mdi-archive-arrow-up-outline',
        'mdi-archive-outline',
        'mdi-arm-flex',
        'mdi-arm-flex-outline',
        'mdi-arrange-bring-forward',
        'mdi-arrange-bring-to-front',
        'mdi-arrange-send-backward',
        'mdi-arrange-send-to-back',
        'mdi-arrow-all',
        'mdi-arrow-bottom-left',
        'mdi-arrow-bottom-left-bold-outline',
        'mdi-arrow-bottom-left-thick',
        'mdi-arrow-bottom-right',
        'mdi-arrow-bottom-right-bold-outline',
        'mdi-arrow-bottom-right-thick',
        'mdi-arrow-collapse',
        'mdi-arrow-collapse-all',
        'mdi-arrow-collapse-down',
        'mdi-arrow-collapse-horizontal',
        'mdi-arrow-collapse-left',
        'mdi-arrow-collapse-right',
        'mdi-arrow-collapse-up',
        'mdi-arrow-collapse-vertical',
        'mdi-arrow-decision',
        'mdi-arrow-decision-auto',
        'mdi-arrow-decision-auto-outline',
        'mdi-arrow-decision-outline',
        'mdi-arrow-down',
        'mdi-arrow-down-bold',
        'mdi-arrow-down-bold-box',
        'mdi-arrow-down-bold-box-outline',
        'mdi-arrow-down-bold-circle',
        'mdi-arrow-down-bold-circle-outline',
        'mdi-arrow-down-bold-hexagon-outline',
        'mdi-arrow-down-bold-outline',
        'mdi-arrow-down-box',
        'mdi-arrow-down-circle',
        'mdi-arrow-down-circle-outline',
        'mdi-arrow-down-drop-circle',
        'mdi-arrow-down-drop-circle-outline',
        'mdi-arrow-down-thick',
        'mdi-arrow-expand',
        'mdi-arrow-expand-all',
        'mdi-arrow-expand-down',
        'mdi-arrow-expand-horizontal',
        'mdi-arrow-expand-left',
        'mdi-arrow-expand-right',
        'mdi-arrow-expand-up',
        'mdi-arrow-expand-vertical',
        'mdi-arrow-horizontal-lock',
        'mdi-arrow-left',
        'mdi-arrow-left-bold',
        'mdi-arrow-left-bold-box',
        'mdi-arrow-left-bold-box-outline',
        'mdi-arrow-left-bold-circle',
        'mdi-arrow-left-bold-circle-outline',
        'mdi-arrow-left-bold-hexagon-outline',
        'mdi-arrow-left-bold-outline',
        'mdi-arrow-left-box',
        'mdi-arrow-left-circle',
        'mdi-arrow-left-circle-outline',
        'mdi-arrow-left-drop-circle',
        'mdi-arrow-left-drop-circle-outline',
        'mdi-arrow-left-right',
        'mdi-arrow-left-right-bold',
        'mdi-arrow-left-right-bold-outline',
        'mdi-arrow-left-thick',
        'mdi-arrow-right',
        'mdi-arrow-right-bold',
        'mdi-arrow-right-bold-box',
        'mdi-arrow-right-bold-box-outline',
        'mdi-arrow-right-bold-circle',
        'mdi-arrow-right-bold-circle-outline',
        'mdi-arrow-right-bold-hexagon-outline',
        'mdi-arrow-right-bold-outline',
        'mdi-arrow-right-box',
        'mdi-arrow-right-circle',
        'mdi-arrow-right-circle-outline',
        'mdi-arrow-right-drop-circle',
        'mdi-arrow-right-drop-circle-outline',
        'mdi-arrow-right-thick',
        'mdi-arrow-split-horizontal',
        'mdi-arrow-split-vertical',
        'mdi-arrow-top-left',
        'mdi-arrow-top-left-bold-outline',
        'mdi-arrow-top-left-bottom-right',
        'mdi-arrow-top-left-bottom-right-bold',
        'mdi-arrow-top-left-thick',
        'mdi-arrow-top-right',
        'mdi-arrow-top-right-bold-outline',
        'mdi-arrow-top-right-bottom-left',
        'mdi-arrow-top-right-bottom-left-bold',
        'mdi-arrow-top-right-thick',
        'mdi-arrow-up',
        'mdi-arrow-up-bold',
        'mdi-arrow-up-bold-box',
        'mdi-arrow-up-bold-box-outline',
        'mdi-arrow-up-bold-circle',
        'mdi-arrow-up-bold-circle-outline',
        'mdi-arrow-up-bold-hexagon-outline',
        'mdi-arrow-up-bold-outline',
        'mdi-arrow-up-box',
        'mdi-arrow-up-circle',
        'mdi-arrow-up-circle-outline',
        'mdi-arrow-up-down',
        'mdi-arrow-up-down-bold',
        'mdi-arrow-up-down-bold-outline',
        'mdi-arrow-up-drop-circle',
        'mdi-arrow-up-drop-circle-outline',
        'mdi-arrow-up-thick',
        'mdi-arrow-vertical-lock',
        'mdi-artstation',
        'mdi-aspect-ratio',
        'mdi-assistant',
        'mdi-asterisk',
        'mdi-at',
        'mdi-atlassian',
        'mdi-atm',
        'mdi-atom',
        'mdi-atom-variant',
        'mdi-attachment',
        'mdi-audio-video',
        'mdi-audio-video-off',
        'mdi-augmented-reality',
        'mdi-auto-download',
        'mdi-auto-fix',
        'mdi-auto-upload',
        'mdi-autorenew',
        'mdi-av-timer',
        'mdi-aws',
        'mdi-axe',
        'mdi-axis',
        'mdi-axis-arrow',
        'mdi-axis-arrow-info',
        'mdi-axis-arrow-lock',
        'mdi-axis-lock',
        'mdi-axis-x-arrow',
        'mdi-axis-x-arrow-lock',
        'mdi-axis-x-rotate-clockwise',
        'mdi-axis-x-rotate-counterclockwise',
        'mdi-axis-x-y-arrow-lock',
        'mdi-axis-y-arrow',
        'mdi-axis-y-arrow-lock',
        'mdi-axis-y-rotate-clockwise',
        'mdi-axis-y-rotate-counterclockwise',
        'mdi-axis-z-arrow',
        'mdi-axis-z-arrow-lock',
        'mdi-axis-z-rotate-clockwise',
        'mdi-axis-z-rotate-counterclockwise',
        'mdi-babel',
        'mdi-baby',
        'mdi-baby-bottle',
        'mdi-baby-bottle-outline',
        'mdi-baby-buggy',
        'mdi-baby-carriage',
        'mdi-baby-carriage-off',
        'mdi-baby-face',
        'mdi-baby-face-outline',
        'mdi-backburger',
        'mdi-backspace',
        'mdi-backspace-outline',
        'mdi-backspace-reverse',
        'mdi-backspace-reverse-outline',
        'mdi-backup-restore',
        'mdi-bacteria',
        'mdi-bacteria-outline',
        'mdi-badge-account',
        'mdi-badge-account-alert',
        'mdi-badge-account-alert-outline',
        'mdi-badge-account-horizontal',
        'mdi-badge-account-horizontal-outline',
        'mdi-badge-account-outline',
        'mdi-badminton',
        'mdi-bag-carry-on',
        'mdi-bag-carry-on-check',
        'mdi-bag-carry-on-off',
        'mdi-bag-checked',
        'mdi-bag-personal',
        'mdi-bag-personal-off',
        'mdi-bag-personal-off-outline',
        'mdi-bag-personal-outline',
        'mdi-baguette',
        'mdi-balloon',
        'mdi-ballot',
        'mdi-ballot-outline',
        'mdi-ballot-recount',
        'mdi-ballot-recount-outline',
        'mdi-bandage',
        'mdi-bandcamp',
        'mdi-bank',
        'mdi-bank-minus',
        'mdi-bank-outline',
        'mdi-bank-plus',
        'mdi-bank-remove',
        'mdi-bank-transfer',
        'mdi-barcode',
        'mdi-barcode-off',
        'mdi-barcode-scan',
        'mdi-barley',
        'mdi-barley-off',
        'mdi-barn',
        'mdi-barrel',
        'mdi-baseball',
        'mdi-baseball-bat',
        'mdi-bash',
        'mdi-basket',
        'mdi-basket-fill',
        'mdi-basket-outline',
        'mdi-basket-unfill',
        'mdi-basketball',
        'mdi-basketball-hoop',
        'mdi-basketball-hoop-outline',
        'mdi-bat',
        'mdi-battery',
        'mdi-battery-10',
        'mdi-battery-10-bluetooth',
        'mdi-battery-20',
        'mdi-battery-20-bluetooth',
        'mdi-battery-30',
        'mdi-battery-30-bluetooth',
        'mdi-battery-40',
        'mdi-battery-40-bluetooth',
        'mdi-battery-50',
        'mdi-battery-50-bluetooth',
        'mdi-battery-60',
        'mdi-battery-60-bluetooth',
        'mdi-battery-70',
        'mdi-battery-70-bluetooth',
        'mdi-battery-80',
        'mdi-battery-80-bluetooth',
        'mdi-battery-90',
        'mdi-battery-90-bluetooth',
        'mdi-battery-alert',
        'mdi-battery-alert-bluetooth',
        'mdi-battery-alert-variant',
        'mdi-battery-alert-variant-outline',
        'mdi-battery-bluetooth',
        'mdi-battery-bluetooth-variant',
        'mdi-battery-charging',
        'mdi-battery-charging-10',
        'mdi-battery-charging-100',
        'mdi-battery-charging-20',
        'mdi-battery-charging-30',
        'mdi-battery-charging-40',
        'mdi-battery-charging-50',
        'mdi-battery-charging-60',
        'mdi-battery-charging-70',
        'mdi-battery-charging-80',
        'mdi-battery-charging-90',
        'mdi-battery-charging-high',
        'mdi-battery-charging-low',
        'mdi-battery-charging-medium',
        'mdi-battery-charging-outline',
        'mdi-battery-charging-wireless',
        'mdi-battery-charging-wireless-10',
        'mdi-battery-charging-wireless-20',
        'mdi-battery-charging-wireless-30',
        'mdi-battery-charging-wireless-40',
        'mdi-battery-charging-wireless-50',
        'mdi-battery-charging-wireless-60',
        'mdi-battery-charging-wireless-70',
        'mdi-battery-charging-wireless-80',
        'mdi-battery-charging-wireless-90',
        'mdi-battery-charging-wireless-alert',
        'mdi-battery-charging-wireless-outline',
        'mdi-battery-heart',
        'mdi-battery-heart-outline',
        'mdi-battery-heart-variant',
        'mdi-battery-high',
        'mdi-battery-low',
        'mdi-battery-medium',
        'mdi-battery-minus',
        'mdi-battery-negative',
        'mdi-battery-off',
        'mdi-battery-off-outline',
        'mdi-battery-outline',
        'mdi-battery-plus',
        'mdi-battery-positive',
        'mdi-battery-unknown',
        'mdi-battery-unknown-bluetooth',
        'mdi-battlenet',
        'mdi-beach',
        'mdi-beaker',
        'mdi-beaker-alert',
        'mdi-beaker-alert-outline',
        'mdi-beaker-check',
        'mdi-beaker-check-outline',
        'mdi-beaker-minus',
        'mdi-beaker-minus-outline',
        'mdi-beaker-outline',
        'mdi-beaker-plus',
        'mdi-beaker-plus-outline',
        'mdi-beaker-question',
        'mdi-beaker-question-outline',
        'mdi-beaker-remove',
        'mdi-beaker-remove-outline',
        'mdi-bed',
        'mdi-bed-double',
        'mdi-bed-double-outline',
        'mdi-bed-empty',
        'mdi-bed-king',
        'mdi-bed-king-outline',
        'mdi-bed-outline',
        'mdi-bed-queen',
        'mdi-bed-queen-outline',
        'mdi-bed-single',
        'mdi-bed-single-outline',
        'mdi-bee',
        'mdi-bee-flower',
        'mdi-beehive-off-outline',
        'mdi-beehive-outline',
        'mdi-beer',
        'mdi-beer-outline',
        'mdi-bell',
        'mdi-bell-alert',
        'mdi-bell-alert-outline',
        'mdi-bell-cancel',
        'mdi-bell-cancel-outline',
        'mdi-bell-check',
        'mdi-bell-check-outline',
        'mdi-bell-circle',
        'mdi-bell-circle-outline',
        'mdi-bell-minus',
        'mdi-bell-minus-outline',
        'mdi-bell-off',
        'mdi-bell-off-outline',
        'mdi-bell-outline',
        'mdi-bell-plus',
        'mdi-bell-plus-outline',
        'mdi-bell-remove',
        'mdi-bell-remove-outline',
        'mdi-bell-ring',
        'mdi-bell-ring-outline',
        'mdi-bell-sleep',
        'mdi-bell-sleep-outline',
        'mdi-beta',
        'mdi-betamax',
        'mdi-biathlon',
        'mdi-bicycle',
        'mdi-bicycle-basket',
        'mdi-bike',
        'mdi-bike-fast',
        'mdi-billboard',
        'mdi-billiards',
        'mdi-billiards-rack',
        'mdi-binoculars',
        'mdi-bio',
        'mdi-biohazard',
        'mdi-bitbucket',
        'mdi-bitcoin',
        'mdi-black-mesa',
        'mdi-blender',
        'mdi-blender-software',
        'mdi-blinds',
        'mdi-blinds-open',
        'mdi-block-helper',
        'mdi-blogger',
        'mdi-blood-bag',
        'mdi-bluetooth',
        'mdi-bluetooth-audio',
        'mdi-bluetooth-connect',
        'mdi-bluetooth-off',
        'mdi-bluetooth-settings',
        'mdi-bluetooth-transfer',
        'mdi-blur',
        'mdi-blur-linear',
        'mdi-blur-off',
        'mdi-blur-radial',
        'mdi-bolnisi-cross',
        'mdi-bolt',
        'mdi-bomb',
        'mdi-bomb-off',
        'mdi-bone',
        'mdi-book',
        'mdi-book-account',
        'mdi-book-account-outline',
        'mdi-book-alphabet',
        'mdi-book-cross',
        'mdi-book-information-variant',
        'mdi-book-lock',
        'mdi-book-lock-open',
        'mdi-book-minus',
        'mdi-book-minus-multiple',
        'mdi-book-minus-multiple-outline',
        'mdi-book-multiple',
        'mdi-book-multiple-outline',
        'mdi-book-music',
        'mdi-book-open',
        'mdi-book-open-outline',
        'mdi-book-open-page-variant',
        'mdi-book-open-variant',
        'mdi-book-outline',
        'mdi-book-play',
        'mdi-book-play-outline',
        'mdi-book-plus',
        'mdi-book-plus-multiple',
        'mdi-book-plus-multiple-outline',
        'mdi-book-remove',
        'mdi-book-remove-multiple',
        'mdi-book-remove-multiple-outline',
        'mdi-book-search',
        'mdi-book-search-outline',
        'mdi-book-variant',
        'mdi-book-variant-multiple',
        'mdi-bookmark',
        'mdi-bookmark-check',
        'mdi-bookmark-check-outline',
        'mdi-bookmark-minus',
        'mdi-bookmark-minus-outline',
        'mdi-bookmark-multiple',
        'mdi-bookmark-multiple-outline',
        'mdi-bookmark-music',
        'mdi-bookmark-music-outline',
        'mdi-bookmark-off',
        'mdi-bookmark-off-outline',
        'mdi-bookmark-outline',
        'mdi-bookmark-plus',
        'mdi-bookmark-plus-outline',
        'mdi-bookmark-remove',
        'mdi-bookmark-remove-outline',
        'mdi-bookshelf',
        'mdi-boom-gate',
        'mdi-boom-gate-alert',
        'mdi-boom-gate-alert-outline',
        'mdi-boom-gate-down',
        'mdi-boom-gate-outline',
        'mdi-boom-gate-up',
        'mdi-boom-gate-up-outline',
        'mdi-boombox',
        'mdi-boomerangootstrap',
        'mdi-border-all',
        'mdi-border-all-variant',
        'mdi-border-bottom-variant',
        'mdi-border',
        'mdi-border-horizontal',
        'mdi-border-inside',
        'mdi-border-left',
        'mdi-border-left-variant',
        'mdi-border-none',
        'mdi-border-none-variant',
        'mdi-border-outside',
        'mdi-border-right',
        'mdi-border-right-variant',
'mdi-style',
        'mdi-border-top',
        'mdi-border-top-variant',
        'mdi-border-vertical',
        'mdi-bottle-soda',
        'mdi-bottle-soda-classic',
        'mdi-bottle-soda-classic-outline',
        'mdi-bottle-soda-outline',
        'mdi-bottle-tonic',
        'mdi-bottle-tonic-outline',
        'mdi-bottle-tonic-plus',
        'mdi-bottle-tonic-plus-outline',
        'mdi-bottle-tonic-skull',
        'mdi-bottle-tonic-skull-outline',
        'mdi-bottle-wine',
        'mdi-bottle-wine-outline',
        'mdi-bow-tie',
'mdi-bowl',
        'mdi-bowl-mix',
        'mdi-bowl-mix-outline',
        'mdi-bowl-outline',
        'mdi-bowling',
        'mdi-box',
        'mdi-box-cutter',
        'mdi-box-cutter-off',
        'mdi-box-shadow',
        'mdi-boxing-glove',
        'mdi-brain',
        'mdi-bread-slice',
        'mdi-bread-slice-outline',
        'mdi-bridge',
        'mdi-briefcase',
        'mdi-briefcase-account',
        'mdi-briefcase-outline',
        'mdi-briefcase-check',
        'mdi-briefcase-check-outline',
        'mdi-briefcase-clock',
        'mdi-briefcase-clock-outline',
        'mdi-briefcase-download',
        'mdi-briefcase-download-outline',
        'mdi-briefcase-edit',
        'mdi-briefcase-edit-outline',
        'mdi-briefcase-minus',
        'mdi-briefcase-minus-outline',
        'mdi-briefcase-outline',
        'mdi-briefcase-plus',
        'mdi-briefcase-plus-outline',
        'mdi-briefcase-remove',
        'mdi-briefcase-remove-outline',
        'fcase-search',
    'mdi-briefcase-search',
        'mdi-briefcase-upload',
        'mdi-se-upload-outline',
        'mdi-briefcase-variant',
        'mdi-briefcase-variant-outline',
        'mdi-brightness-1',
        'mdi-brightness-2',
        'mdi-brightness-3',
        'mdi-brightness-4',
        'mdi-brightness-5',
        'mdi-brightness-6',
        'mdi-brightness-7',
        'mdi-brightness-auto',
        'mdi-brightness-percent',
        'mdi-mdi-brush',
        'mdi-bucket',
        'mdi-bucket-outline',
        'mdi-buddhism',
        'mdi-buffer',
        'mdi-buffet',
        'mdi-bug',
        'mdi-bug-check',
        'mdi-bug-check-outline',
        'mdi-bug-outline',
        'mdi-bugle',
        'mdi-bulldozer',
        'mdi-bullet',
        'mdi-bulletin-board',
        'mdi-bullhorn',
        'mdi--outline',
        'mdi-bullseyeullseye-arrow',
        'mdi-bulma',
        'mdi-bunk-bed',
        'mdi-bunk-bed-outline',
        'mdi-bus',
        'mdi-bus-alert',
        'mdi-bus-articulated-end',
        'mdi-bus-articulated-front',
        'mdi-bus-clock',
        'mdi-bus-double-decker',
        'mdi-bus-marker',
        'mdi-bus-multiple',
        'mdi-bus-school',
        'mdi-bus mdi-bus-stop',
        'mdi-bus-stop-covered',
        'mdi-bus-stop-uncovered',
        'mdi-cable-data',
        'mdi-cached',
        'mdi-cactus',
        'mdi-cake',
        'mdi-cake-layered',
        'mdi-cake-variant',
        'mdi-calculator',
        'mdi-calculator-variant',
        'mdi-calendar',
        'ndar-account',
        'mdi-calendar-account-outline',
        'mdi-calendar-alert',
        'mdi-calendar-arrow-left',
        'mdi-calendar-arrow',
        'mdi-calendar-blank',
        'ndar-blank-multiple',
    'mdi-calendar-blank-outline',
        'mdi-calendar-check',
        'mdi-calendar-check-outlinealendar-clock',
        'mdi-calendar-edit',
        'mdi-calendar-export',
        'mdi-calendar-import',
        'mdi-calendar-minus',
        'mdi-calendar-month',
        'mdi-calendar-month-outline',
        'mdi-calendar-multiple',
'mdi-calendar-multiple-check',
        'mdi-calendar-multiselect',
        'mdi-calendar-plus',
        'mdi-calendar-question',
'mdi-calendar-range',
        'mdi-calendar-range-outline',
        'mdi-r-refresh',
        'mdi--refresh-outline',
        'mdi-calendar-remove',
        'mdi-calendar-remove-outline',
        'mdi-calendar-search',
        'mdi-calendar-sync',
        'mdi-calendar-sync-outline',
        'mdi-calendar-text',
        'mdi-calendar-text ',
        'mdi-calendar-today',
        'mdi-calendar-week',
        'mdi-calendar-week-begin',
        'mdi-calendar-weekend',
        'mdi-call-missed',
        'mdi-call-split',
        'mdi-camcorder',
        'mdi-camcorder-off',
        'mdi-camera-account',
        'mdi-camera-burst',
        'mdi-camera-control',
        'mdi-camera-enhance',
        'mdi-camera-enhance-outline',
        'mdi-camera-front',
        'mdi-camera-front-variant',
        'mdi-camera-gopro',
        'mdi-camera-image',
        'mdi-camera-iris',
        'mdi-camera-metering-center',
        'mdi-camera-metering-matrix',
        'mdi-camera-metering-partial',
        'mdi-camera-metering-spot',
        'mdi-camera-offamera-outline',
        'mdi-camera-party-modeamera-plus',
        'mdi-camera-plus-outline',
        'mdi-camera-rear',
'mdi-camera-rear-variant',
        'mdi-camera-retake',
        'mdi-camera-switch',
        'mdi-camera-switch-outline',
        'mdi-camera-timer',
        'mdi-camera-wireless',
        'mdi-camera-wireless-outline',
        'mdi-campfire',
        'mdi-cancel',
        'mdi-candle',
        'mdi-candycane',
        'mdi-cannabis',
        'mdi-caps-lock',
        'mdi-car',
        'mdi-car-2-plus',
        'mdi-car-3-plus',
        'mdi-car arrow-left',
        'mdi-car-arrow-right',
        'mdi-car-back',
        'mdi-car-battery',
        'mdi-car-brake-abs',
        'mdi-car-brake-alert',
        'mdi-car-brake-hold',
        'mdi-car brake-parking',
        'mdi-car-brake-retarder',
        'mdi-car-child-seat',
        'mdi-car-clutch',
        'mdi-car-cog',
        'mdi-car-connected',
        'mdi-car-convertible',
        'mdi-car-coolant-level',
        'mdi-car-cruise-control',
        'mdi-car-defrost-rear',
        'mdi-car-door',
        'mdi-car-door-lock',
        'mdi-car-electric',
        'mdi-car-esp',
        'mdi-car-estate',
        'mdi-car-hatchback',
        'mdi-car-info',
        'mdi-car-key',
        'mdi-car-light-dimmed',
        'mdi-car-light-fo',
        'mdi-car-light-high',
        'mdi-car-limousine',
        'mdi-car-multiple',
        'mdi-car-off',
        'mdi-car-parking-lights',
        'mdi-car-pickup',
        'mdi-car-seat',
        'mdi-car-seat-cooler',
        'mdi-ca-seat-heater',
        'mdi-car-settings',
        'mdi-car-shift-pattern',
        'mdi-car-side',
        'mdi-car-sports',
        'mdi-car-tire-alert',
        'mdi-car-traction-control',
        'mdi-car-turbocharger',
        'mdi-car-wash',
        'mdi-car-windshield',
        'mdi-car-windshield-outline',
        'mdi-carabiner',
        'mdi-caravan',
        'mdi-card',
        'mdi-card-account-details',
        'mdi-card-account-details-outline',
        'mdi-card-account-details-star',
        'mdi-card-accoun-details-star-outline',
        'mdi-card-account-mail',
        'mdi-card-account-mail-outline',
        'mdi-card-account-phone',
        'mdi-card-account-phone-outline',
        'mdi-card-bulleted',
        'mdi-card-bulleted-off',
        'mdi-card-bulleted-off-outline',
        'mdi-card-bulleted-outline',
        'mdi-card-bulleted-settings',
        'mdi-card-bulleted-settings-outline',
        'mdi-card-outline',
        'mdi-card-plus',
        'mdi-card-plus-outline',
        'mdi-card-search',
        'mdi-card-search-outline',
        'mdi-card-text',
        'mdi-card-text-outline',
        'mdi-cards',
        'mdi-cards-club',
        'mdi-cards-diamond',
        'mdi-cards-diamond-outline',
        'mdi-cards heart',
        'mdi-cards-outline',
        'mdi-cards-playing-outline',
        'mdi-cards-spade',
        'mdi-cards-variant',
        'mdi-carrot',
        'mdi-cart',
        'mdi-cart-arrow-down',
        'mdi-cart-arrow-right',
        'mdi-cart-arrow-up',
        'mdi-cart-minus',
        'mdi-cart-off',
        'mdi-cart-outline',
        'mdi-cart-plus',
        'mdi-cart-remove',
        'mdi-case-sensitive-alt',
        'mdi-cash',
        'mdi-cash-100',
        'mdi-cash-marker',
        'mdi-cash-minus',
        'mdi-cash-multiple',
        'mdi-cash-plus',
        'mdi-cash-refund',
        'mdi-cash-register',
        'mdi-cash-remove',
        'mdi-cash-usd',
        'mdi-cash-usd-outline',
        'mdi-cassette',
        'mdi-cast',
        'mdi-cast-audio',
        'mdi-cast-connected',
        'mdi-cast-education',
        'mdi-cast-off',
        'mdi-castle',
        'mdi-cat',
        'mdi-cctv',
        'mdi-ceiling-light',
        'mdi-cellphone',
        'mdi-cellphone-android',
        'mdi-cellphone-arrow-down',
        'mdi-cellphone-basic',
        'mdi-cellphone-charging',
        'mdi-cellphone-cog',
        'mdi-cellphone-dock',
        'mdi-cellphone-erase',
        'mdi-cellphone-information',
        'mdi-cellphone-iphone',
        'mdi-cellphone-key',
        'mdi-cellphone-link',
        'mdi-cellphone-link-off',
        'mdi-cellphone-lock',
        'mdi-cellphone-message',
        'mdi-cellphone-message-off',
        'mdi-cellphone-nfc',
        'mdi-cellphone-nfc-off',
        'mdi-cellphone-off',
        'mdi-cellphone-play',
        'mdi-cellphone-screenshot',
        'mdi-cellphone-settings',
        'mdi-cellphone-sound',
        'mdi-cellphone-text',
        'mdi-cellphone-wireless',
        'mdi-celtic-cross',
        'mdi-centos',
        'mdi-certificate',
        'mdi-certificate-outline',
        'mdi-chair-rolling',
        'mdi-chair-school',
        'mdi-charity',
        'mdi-chart-arc',
        'mdi-chart-areaspline',
        'mdi-chart-areaspline-variant',
        'mdi-chart-bar',
        'mdi-chart-bar-stacked',
        'mdi-chart-bell-curve',
        'mdi-chart-bell-curve-cumulative',
        'mdi-chart-bubble',
        'mdi-chart-donut',
        'mdi-chart-donut-variant',
        'mdi-chart-gantt',
        'mdi-chart-histogram',
        'mdi-chart-line',
        'mdi-chart-line-stacked',
        'mdi-chart-line-variant',
        'mdi-chart-multiline',
        'mdi-chart-multiple',
        'mdi-chart-pie',
        'mdi-chart-ppf',
        'mdi-chart-sankey',
        'mdi-chart-sankey-variant',
        'mdi-chart-scatter-plot',
        'mdi-chart-scatter-plot-hexbin',
        'mdi-chart-timeline',
        'mdi-chart-timeline-variant',
        'mdi-chart-tree',
        'mdi-chat',
        'mdi-chat-alert',
        'mdi-chat-alert-outline',
        'mdi-chat-minus',
        'mdi-chat-minus-outline',
        'mdi-chat-outline',
        'mdi-chat-plus',
        'mdi-chat-plus-outline',
        'mdi-chat-processing',
        'mdi-chat-processing-outline',
        'mdi-chat-remove',
        'mdi-chat-remove-outline',
        'mdi-chat-sleep',
        'mdi-chat-sleep-outline',
        'mdi-check',
        'mdi-check-all',
        'mdi-check-bold',
        'mdi-check-box-multiple-outline',
        'mdi-check-box-outline',
        'mdi-check-circle',
        'mdi-check-circle-outline',
        'mdi-check-decagram',
        'mdi-check-network',
        'mdi-check-network-outline',
        'mdi-check-outline',
        'mdi-check-underline',
        'mdi-check-underline-circle',
        'mdi-check-underline-circle-outline',
        'mdi-checkbook',
        'mdi-checkbox-blank',
        'mdi-checkbox-blank-circle',
        'mdi-checkbox-blank-circle-outline',
        'mdi-checkbox-blank-off',
        'mdi-checkbox-blank-off-outline',
        'mdi-checkbox-blank-outline',
        'mdi-checkbox-intermediate',
        'mdi-checkbox-marked',
        'mdi-checkbox-marked-circle',
        'mdi-checkbox-marked-circle-outline',
        'mdi-checkbox-marked-outline',
        'mdi-checkbox-multiple-blank',
        'mdi-checkbox-multiple-blank-circle',
        'mdi-checkbox-multiple-blank-circle-outline',
        'mdi-checkbox-multiple-blank-outline',
        'mdi-checkbox-multiple-marked',
        'mdi-checkbox-multiple-marked-circle',
        'mdi-checkbox-multiple-marked-circle-outline',
        'mdi-checkbox-multiple-marked-outline',
        'mdi-checkerboard',
        'mdi-checkerboard-minus',
        'mdi-checkerboard-plus',
        'mdi-checkerboard-remove',
        'mdi-cheese',
        'mdi-cheese-off',
        'mdi-chef-hat',
        'mdi-chemical-weapon',
        'mdi-chess-bishop',
        'mdi-chess-king',
        'mdi-chess-knight',
        'mdi-chess-pawn',
        'mdi-chess-queen',
        'mdi-chess-rook',
        'mdi-chevron-double-down',
        'mdi-chevron-double-left',
        'mdi-chevron-double-right',
        'mdi-chevron-double-up',
        'mdi-chevron-down',
        'mdi-chevron-down-box',
        'mdi-chevron-down-box-outline',
        'mdi-chevron-down-circle',
        'mdi-chevron-down-circle-outline',
        'mdi-chevron-left',
        'mdi-chevron-left-box',
        'mdi-chevron-left-box-outline',
        'mdi-chevron-left-circle',
        'mdi-chevron-left-circle-outline',
        'mdi-chevron-right',
        'mdi-chevron-right-box',
        'mdi-chevron-right-box-outline',
        'mdi-chevron-right-circle',
        'mdi-chevron-right-circle-outline',
        'mdi-chevron-triple-down',
        'mdi-chevron-triple-left',
        'mdi-chevron-triple-right',
        'mdi-chevron-triple-up',
        'mdi-chevron-up',
        'mdi-chevron-up-box',
        'mdi-chevron-up-box-outline',
        'mdi-chevron-up-circle',
        'mdi-chevron-up-circle-outline',
        'mdi-chili-hot',
        'mdi-chili-medium',
        'mdi-chili-mild',
        'mdi-chili-off',
        'mdi-chip',
        'mdi-christianity',
        'mdi-christianity-outline',
        'mdi-church',
        'mdi-cigar',
        'mdi-cigar-off',
        'mdi-circle',
        'mdi-circle-double',
        'mdi-circle-edit-outline',
        'mdi-circle-expand',
        'mdi-circle-half',
        'mdi-circle-half-full',
        'mdi-circle-medium',
        'mdi-circle-multiple',
        'mdi-circle-multiple-outline',
        'mdi-circle-off-outline',
        'mdi-circle-outline',
        'mdi-circle-slice-1',
        'mdi-circle-slice-2',
        'mdi-circle-slice-3',
        'mdi-circle-slice-4',
        'mdi-circle-slice-5',
        'mdi-circle-slice-6',
        'mdi-circle-slice-7',
        'mdi-circle-slice-8',
        'mdi-circle-small',
        'mdi-circular-saw',
        'mdi-city',
        'mdi-city-variant',
        'mdi-city-variant-outline',
        'mdi-clipboard',
        'mdi-clipboard-account',
        'mdi-clipboard-account-outline',
        'mdi-clipboard-alert',
        'mdi-clipboard-alert-outline',
        'mdi-clipboard-arrow-down',
        'mdi-clipboard-arrow-down-outline',
        'mdi-clipboard-arrow-left',
        'mdi-clipboard-arrow-left-outline',
        'mdi-clipboard-arrow-right',
        'mdi-clipboard-arrow-right-outline',
        'mdi-clipboard-arrow-up',
        'mdi-clipboard-arrow-up-outline',
        'mdi-clipboard-check',
        'mdi-clipboard-check-multiple',
        'mdi-clipboard-check-multiple-outline',
        'mdi-clipboard-check-outline',
        'mdi-clipboard-file',
        'mdi-clipboard-file-outline',
        'mdi-clipboard-flow',
        'mdi-clipboard-flow-outline',
        'mdi-clipboard-list',
        'mdi-clipboard-list-outline',
        'mdi-clipboard-multiple',
        'mdi-clipboard-multiple-outline',
        'mdi-clipboard-outline',
        'mdi-clipboard-play',
        'mdi-clipboard-play-multiple',
        'mdi-clipboard-play-multiple-outline',
        'mdi-clipboard-play-outline',
        'mdi-clipboard-plus',
        'mdi-clipboard-plus-outline',
        'mdi-clipboard-pulse',
        'mdi-clipboard-pulse-outline',
        'mdi-clipboard-text',
        'mdi-clipboard-text-multiple',
        'mdi-clipboard-text-multiple-outline',
        'mdi-clipboard-text-outline',
        'mdi-clipboard-text-play',
        'mdi-clipboard-text-play-outline',
        'mdi-clippy',
        'mdi-clock',
        'mdi-clock-alert',
        'mdi-clock-alert-outline',
        'mdi-clock-check',
        'mdi-clock-check-outline',
        'mdi-clock-digital',
        'mdi-clock-end',
        'mdi-clock-fast',
        'mdi-clock-in',
        'mdi-clock-out',
        'mdi-clock-outline',
        'mdi-clock-start',
        'mdi-clock-time-eight',
        'mdi-clock-time-eight-outline',
        'mdi-clock-time-eleven',
        'mdi-clock-time-eleven-outline',
        'mdi-clock-time-five',
        'mdi-clock-time-five-outline',
        'mdi-clock-time-four',
        'mdi-clock-time-four-outline',
        'mdi-clock-time-nine',
        'mdi-clock-time-nine-outline',
        'mdi-clock-time-one',
        'mdi-clock-time-one-outline',
        'mdi-clock-time-seven',
        'mdi-clock-time-seven-outline',
        'mdi-clock-time-six',
        'mdi-clock-time-six-outline',
        'mdi-clock-time-ten',
        'mdi-clock-time-ten-outline',
        'mdi-clock-time-three',
        'mdi-clock-time-three-outline',
        'mdi-clock-time-twelve',
        'mdi-clock-time-twelve-outline',
        'mdi-clock-time-two',
        'mdi-clock-time-two-outline',
        'mdi-close',
        'mdi-close-box',
        'mdi-close-box-multiple',
        'mdi-close-box-multiple-outline',
        'mdi-close-box-outline',
        'mdi-close-circle',
        'mdi-close-circle-multiple',
        'mdi-close-circle-multiple-outline',
        'mdi-close-circle-outline',
        'mdi-close-network',
        'mdi-close-network-outline',
        'mdi-close-octagon',
        'mdi-close-octagon-outline',
        'mdi-close-outline',
        'mdi-close-thick',
        'mdi-closed-caption',
        'mdi-closed-caption-outline',
        'mdi-cloud',
        'mdi-cloud-alert',
        'mdi-cloud-braces',
        'mdi-cloud-check',
        'mdi-cloud-check-outline',
        'mdi-cloud-circle',
        'mdi-cloud-download',
        'mdi-cloud-download-outline',
        'mdi-cloud-lock',
        'mdi-cloud-lock-outline',
        'mdi-cloud-off-outline',
        'mdi-cloud-outline',
        'mdi-cloud-print',
        'mdi-cloud-print-outline',
        'mdi-cloud-question',
        'mdi-cloud-refresh',
        'mdi-cloud-search',
        'mdi-cloud-search-outline',
        'mdi-cloud-sync',
        'mdi-cloud-sync-outline',
        'mdi-cloud-tags',
        'mdi-cloud-upload',
        'mdi-cloud-upload-outline',
        'mdi-clover',
        'mdi-coach-lamp',
        'mdi-coat-rack',
        'mdi-code-array',
        'mdi-code-braces',
        'mdi-code-braces-box',
        'mdi-code-brackets',
        'mdi-code-equal',
        'mdi-code-greater-than',
        'mdi-code-greater-than-or-equal',
        'mdi-code-json',
        'mdi-code-less-than',
        'mdi-code-less-than-or-equal',
        'mdi-code-not-equal',
        'mdi-code-not-equal-variant',
        'mdi-code-parentheses',
        'mdi-code-parentheses-box',
        'mdi-code-string',
        'mdi-code-tags',
        'mdi-code-tags-check',
        'mdi-codepen',
        'mdi-coffee',
        'mdi-coffee-maker',
        'mdi-coffee-off',
        'mdi-coffee-off-outline',
        'mdi-coffee-outline',
        'mdi-coffee-to-go',
        'mdi-coffee-to-go-outline',
        'mdi-coffin',
        'mdi-cog',
        'mdi-cog-box',
        'mdi-cog-clockwise',
        'mdi-cog-counterclockwise',
        'mdi-cog-off',
        'mdi-cog-off-outline',
        'mdi-cog-outline',
        'mdi-cog-refresh',
        'mdi-cog-refresh-outline',
        'mdi-cog-sync',
        'mdi-cog-sync-outline',
        'mdi-cog-transfer',
        'mdi-cog-transfer-outline',
        'mdi-cogs',
        'mdi-collage',
        'mdi-collapse-all',
        'mdi-collapse-all-outline',
        'mdi-color-helper',
        'mdi-comma',
        'mdi-comma-box',
        'mdi-comma-box-outline',
        'mdi-comma-circle',
        'mdi-comma-circle-outline',
        'mdi-comment',
        'mdi-comment-account',
        'mdi-comment-account-outline',
        'mdi-comment-alert',
        'mdi-comment-alert-outline',
        'mdi-comment-arrow-left',
        'mdi-comment-arrow-left-outline',
        'mdi-comment-arrow-right',
        'mdi-comment-arrow-right-outline',
        'mdi-comment-check',
        'mdi-comment-check-outline',
        'mdi-comment-edit',
        'mdi-comment-edit-outline',
        'mdi-comment-eye',
        'mdi-comment-eye-outline',
        'mdi-comment-multiple',
        'mdi-comment-multiple-outline',
        'mdi-comment-outline',
        'mdi-comment-plus',
        'mdi-comment-plus-outline',
        'mdi-comment-processing',
        'mdi-comment-processing-outline',
        'mdi-comment-question',
        'mdi-comment-question-outline',
        'mdi-comment-quote',
        'mdi-comment-quote-outline',
        'mdi-comment-remove',
        'mdi-comment-remove-outline',
        'mdi-comment-search',
        'mdi-comment-search-outline',
        'mdi-comment-text',
        'mdi-comment-text-multiple',
        'mdi-comment-text multiple-outline',
        'mdi-comment-text-outline',
        'mdi-compare',
        'mdi-compare-horizontal',
        'mdi-compare-vertical',
        'mdi-compass',
        'mdi-compass-off',
        'mdi-compas-off-outline',
        'mdi-compass-outline',
        'mdi-compass-rose',
        'mdi-concourse-ci',
        'mdi-console',
        'mdi-console-line',
        'mdi-console-network',
        'mdi-console-network-outline',
        'mdi-consolidate',
        'mdi-contactless-payment',
        'mdi-contactless-payment-circle',
        'mdi-contactless-payment-circle outline',
        'mdi-contacts',
        'mdi-contacts-outline',
        'mdi-contain',
        'mdi-contain-end',
        'mdi-contain-start',
        'mdi-content-copy',
        'mdi-content-cut',
        'mdi-content-duplicate',
        'mdi-content-paste',
        'mdi-content-save',
        'mdi-content-save-alert',
        'mdi-content-save-alert-outline',
        'mdi-content-save-all',
        'mdi-content-save-all-outline',
        'mdi-content-save-cog',
        'mdi-content-save-cog-outline',
        'mdi-content-save-edit',
        'mdi-content-save-edit-outline',
        'mdi-content-save-move',
        'mdi-content-save-move-outline',
        'mdi-content-save-outline',
        'mdi-content-save-settings',
        'mdi-content-save-settings-outline',
        'mdi-contrast',
        'mdi-contrast-box',
        'mdi-contrast-circle',
        'mdi-controller-classic',
        'mdi-controller-classic-outline',
        'mdi-cookie',
        'mdi-coolant-temperature',
        'mdi-copyright',
        'mdi-cordova',
        'mdi-corn',
        'mdi-corn-off',
        'mdi-cosine-wave',
        'mdi-counter',
        'mdi-cow',
        'mdi-cpu-32-bit',
        'mdi-cpu-64-bit',
        'mdi-crane',
        'mdi-creation',
        'mdi-creative-commons',
        'mdi-credit-card',
        'mdi-credit-card-check',
        'mdi-credit-car-check-outline',
        'mdi-credit-card-clock',
        'mdi-credit-card-clock-outline',
        'mdi-credit-card-marker',
        'mdi-credit-card-marker-outline',
        'mdi-credit-card-minus',
        'mdi-credit-card-minus-outline',
        'mdi-credit-card multiple',
        'mdi-credit-card-multiple-outline',
        'mdi-credit-card-off',
        'mdi-credit-card-off-outline',
        'mdi-credit-card-outline',
        'mdi-credit-card-plus',
        'mdi-credit-card-plus-outline',
        'mdi-credit-card-refund',
        'mdi-credit-card-refund-outline',
        'mdi-credit-card-remove',
        'mdi-credit-card-remove-outline',
        'mdi-credit-card-scan',
        'mdi-credit-card-scan-outline',
        'mdi-credit-card-setting',
        'mdi-credit-card-settings-outline',
        'mdi-credit-card-wireless',
        'mdi-credit-card-wireless-off',
        'mdi-credit-card-wireless-off-outline',
        'mdi-credit-card-wireless-outline',
        'mdi-cricket',
        'mdi-crop',
        'mdi-crop-free',
        'mdi-crop-landscape',
        'mdi-crop-portrait',
        'mdi-crop-rotate',
        'mdi-crop-square',
        'mdi-crosshairs',
        'mdi-crosshairs gps',
        'mdi-crosshairs-off',
        'mdi-crosshairs-question',
        'mdi-crown',
        'mdi-crown-outline',
        'mdi-cryengine',
        'mdi-crystal-ball',
        'mdi-cube',
        'mdi-cube-off',
        'mdi-cube-off-outline',
        'mdi-cube-outline',
        'mdi-cube-scan',
        'mdi-cube-send',
        'mdi-cube-unfolded',
        'mdi-cup',
        'mdi-cup-off',
        'mdi-cup-off-outline',
        'mdi-cup-outline',
        'mdi-cup-water',
        'mdi-cupboard',
        'mdi-cupboard-outline',
        'mdi-cupcake',
        'mdi-curling',
        'mdi-currency-bdt',
        'mdi-currency-brl',
        'mdi-currency-btc',
        'mdi-currency-cny',
        'mdi-currency-eth',
        'mdi-currency-eur',
        'mdi-currency-eur-off',
        'mdi-currency-gbp',
        'mdi-currency-ils',
        'mdi-currency-inr',
        'mdi-currency-jpy',
        'mdi-currency-krw',
        'mdi-currency-kzt',
        'mdi-currency-ngn',
        'mdi-currency-php',
        'mdi-currency-rial',
        'mdi-currency-rub',
        'mdi-currency-sign',
        'mdi-currency-try',
        'mdi-currenc-twd',
        'mdi-currency-usd',
        'mdi-currency-usd',
        'mdi-currency-usd-outline',
        'mdi-currency-usd-off',
        'mdi-current-ac',
        'mdi-current-dc',
        'mdi-cursor-default',
        'mdi-cursor-default-click',
        'mdi-cursor-default-click-outline',
        'mdi-cursor-default-gesture',
        'mdi-cursor-default-gesture-outline',
        'mdi-cursor-default-outline',
        'mdi-cursor-move',
        'mdi-cursor-pointer',
        'mdi-cursor-text',
        'mdi-database',
        'mdi-database-check',
        'mdi-database-edit',
        'mdi-database-export',
        'mdi-database-import ',
        'mdi-database-lock',
        'mdi-database-marker',
        'mdi-database-minus',
        'mdi-database-plus',
        'mdi-database-refresh',
        'mdi-database-remove',
        'mdi-database-search',
        'mdi-database-settings',
        'mdi-database-sync',
        'mdi-death-star',
        'mdi-death-star-variant',
        'mdi-deathly-hallows',
        'mdi-debian',
        'mdi-debug-step-into',
        'mdi-debug-step-out',
        'mdi-debug-step-over',
        'mdi-decagram',
        'mdi-decagram-outline',
        'mdi-decimal',
        'mdi-decimal-comma',
        'mdi-decimal-comma-decrease',
        'mdi-decimal-comma-increase',
        'mdi-decimal-decrease',
        'mdi-decimal-increase',
        'mdi-delete',
        'mdi-delete-alert',
        'mdi-delete-alert-outline',
        'mdi-delete-circle',
        'mdi-delete-circle-outline',
        'mdi-delete-empty',
        'mdi-delete-empty-outline',
        'mdi-delete-forever',
        'mdi-delete-forever-outline',
        'mdi-delete-off',
        'mdi-delete-off-outline',
        'mdi-delete-outline',
        'mdi-delete-restore',
        'mdi-delete-sweep',
        'mdi-delete-sweep-outline',
        'mdi-delete-variant',
        'mdi-delta',
        'mdi-desk',
        'mdi-desk-lamp',
        'mdi-deskphone',
        'mdi-desktop-classic',
        'mdi-desktop-mac',
        'mdi-desktop-mac-dashboard',
        'mdi-desktop-tower',
        'mdi-desktop-tower-monitor',
        'mdi-details',
        'mdi-dev-to',
        'mdi-developer-board',
        'mdi-deviantart',
        'mdi-devices',
        'mdi-diabetes',
        'mdi-dialpad',
        'mdi-diameter',
        'mdi-diameter-outline',
        'mdi-diameter-variant',
        'mdi-diamond',
        'mdi-diamond-outline',
        'mdi-diamond-stone',
        'mdi-dice-1',
        'mdi-dice-1-outline',
        'mdi-dice-2',
        'mdi-dice-2-outline',
        'mdi-dice-3',
        'mdi-dice-3-outline',
        'mdi-dice-4',
        'mdi-dice-4-outline',
        'mdi-dice-5',
        'mdi-dice-5-outline',
        'mdi-dice-6',
        'mdi-dice-6-outline',
        'mdi-dice-d10',
        'mdi-dice-d10-outline',
        'mdi-dice-d12',
        'mdi-dice-d12-outline',
        'mdi-dice-d20',
        'mdi-dice-d20-outline',
        'mdi-dice-d4',
        'mdi-dice-d4-outline',
        'mdi-dice-d6',
        'mdi-dice-d6-outline',
        'mdi-dice-d8',
        'mdi-dice-d8-outline',
        'mdi-dice-multiple',
        'mdi-dice-multiple-outline',
        'mdi-digital-ocean',
        'mdi-dip-switch',
        'mdi-directions',
        'mdi-directions-fork',
        'mdi-disc',
        'mdi-disc-alert',
        'mdi-disc-player',
        'mdi-discord',
        'mdi-dishwasher',
        'mdi-dishwasher-alert',
        'mdi-dishwasher-off',
        'mdi-disqus',
        'mdi-distribute-horizontal-center',
        'mdi-distribute-horizontal-left',
        'mdi-distribute-horizontal-right',
        'mdi-distribute-vertical-bottom',
        'mdi-distribute-vertical-center',
        'mdi-distribute-vertical-top',
        'mdi-diving-flippers',
        'mdi-diving-helmet',
        'mdi-diving-scuba',
        'mdi-divingscuba-flag',
        'mdi-diving-scuba-tank',
        'mdi-diving-scuba-tank-multiple',
        'mdi-diving-snorkel',
        'mdi-division',
        'mdi-division-box',
        'mdi-dlna',
        'mdi-dna',
        'mdi-dns',
        'mdi-dns-outline',
        'mdi-do-not-disturb',
        'mdi-do-not-disturb-off',
        'mdi-dock-bottom',
        'mdi-dock-left',
        'mdi-dock-right',
        'mdi-dock-window',
        'mdi-docker',
        'mdi-doctor',
        'mdi-dog',
        'mdi-dog-service',
        'mdi-dog-side',
        'mdi-dolby',
        'mdi-dolly',
        'mdi-domain',
        'mdi-domain-off',
        'mdi-domain-plus',
        'mdi-domain-remove',
        'mdi-dome-light',
        'mdi-domino-mask',
        'mdi-donkey',
        'mdi-door',
        'mdi-door-closed',
        'mdi-door-closed-lock',
        'mdi-door-open',
        'mdi-doorbell',
        'mdi-doorbell-video',
        'mdi-dot-net',
        'mdi-dots-horizontal',
        'mdi-dots-horizontal-circle',
        'mdi-dot-horizontal-circle-outline',
        'mdi-dots-vertical',
        'mdi-dots-vertical-circle',
        'mdi-dots-vertical-circle-outline',
        'mdi-douban',
        'mdi-download',
        'mdi-download-box',
        'mdi-download-box-outline',
        'mdi-download-circle',
        'mdi-download-circle-outline',
        'mdi-download-lock',
        'mdi-download-lock-outline',
        'mdi-download-multiple',
        'mdi-download-network',
        'mdi-download-network-outline',
        'mdi-download-off',
        'mdi-download-off-outline',
        'mdi-download-outline',
        'mdi-drag',
        'mdi-drag-horizontal',
        'mdi-drag-horizontal-variant',
        'mdi-drag-variant',
        'mdi-drag-vertical',
        'mdi-drag-vertical-variant',
        'mdi-drama-masks',
        'mdi-draw',
        'mdi-drawing',
        'mdi-drawing-box',
        'mdi-dresser',
        'mdi-dresser-outline',
        'mdi-drone',
        'mdi-dropbox',
        'mdi-drupal',
        'mdi-duck',
        'mdi-dumbbell',
        'mdi-dump-truck',
        'mdi-ear-hearing',
        'mdi-ear-hearing-off',
        'mdi-earth',
        'mdi-earth-arrow-right',
        'mdi-earth-box',
        'mdi-earth-box-minus',
        'mdi-earth-box-off',
        'mdi-earth-box-plus',
        'mdi-earth-box-remove',
        'mdi-earth-minus                                                ',
        'mdi-earth-off',
        'mdi-earth-plus',
        'mdi-earth-remove',
        'mdi-egg',
        'mdi-egg-easter',
        'mdi-egg-off',
        'mdi-egg-off-outline',
        'mdi-egg-outline',
        'mdi-eight-track',
        'mdi-eject',
        'mdi-eject-outline',
        'mdi-electric-switch',
        'mdi-electric-switch-closed',
        'mdi-electron-framework',
        'mdi-elephant',
        'mdi-elevation-decline',
        'mdi-elevation-rise',
        'mdi-elevator',
        'mdi-elevator-down',
        'mdi-elevator-passenger',
        'mdi-elevator-up',
        'mdi-ellipse',
        'mdi-ellipse-outline',
        'mdi-email',
        'mdi-email-alert',
        'mdi-email-alert-outline',
        'mdi-email-box',
        'mdi-email-check',
        'mdi-email-check-outline',
        'mdi-email-edit',
        'mdi-email edit-outline',
        'mdi-email lock',
        'mdi-email-mark-as-unread',
        'mdi-email-minus',
        'mdi-email-minus-outline',
        'mdi-email-multiple',
        'mdi-email-multiple-outline',
        'mdi-email-newsletter',
        'mdi-email-off',
        'mdi-email-off-outline',
        'mdi-email-open',
        'mdi-email-open-multiple',
        'mdi-email-open-multiple-outline',
        'mdi-email-open-outline                                                                ',
        'mdi-email-outline',
        'mdi-email-plus',
        'mdi-email-plus-outline',
        'mdi-email-receive',
        'mdi-email-receive-outline',
        'mdi-email-search',
        'mdi-email-search-outline',
        'mdi-email-send',
        'mdi-email-send-outline',
        'mdi-email-sync',
        'mdi-email-sync-outline',
        'mdi-email-variant',
        'mdi-ember',
        'mdi-emby',
        'mdi-emoticon',
        'mdi-emoticon-angry',
        'mdi-emoticon-angry-outline',
        'mdi-emoticon-confused',
        'mdi-emoticon-confused-outline',
        'mdi-emoticon-cool',
        'mdi-emoticon-cool outline',
        'mdi-emoticon-cry',
        'mdi-emoticon-cry-outline',
        'mdi-emoticon-dead',
        'mdi-emoticon-dead-outline',
        'mdi-emoticon-devil',
        'mdi-emoticon-devil-outline',
        'mdi-emoticon-excited',
        'mdi-emoticon-excited-outline',
        'mdi-emoticon-frown',
        'mdi-emoticon-frown-outline',
        'mdi-emoticon-happy',
        'mdi-emoticon-happy-outline',
        'mdi-emoticon-kiss',
        'mdi-emoticon-kiss-outline',
        'mdi-emoticon-lol',
        'mdi-emoticon-lol-outline',
        'mdi-emoticon-neutral',
        'mdi-emoticon-neutral-outline',
        'mdi-emoticon-outline',
        'mdi-emoticon-poop',
        'mdi-emoticon-poop-outline',
        'mdi-emoticon-sad',
        'mdi-emoticon sad-outline',
        'mdi-emoticon-tongue',
        'mdi-emoticon-tongue-outline',
        'mdi-emoticon-wink',
        'mdi-emoticon-wink-outline',
        'mdi-engine',
        'mdi-engine-off',
        'mdi-engine-off-outline',
        'mdi-engine-outline',
        'mdi-epsilon',
        'mdi-equal',
        'mdi-equal-box',
        'mdi-equalize',
        'mdi-equalizer-outline',
        'mdi-eraser',
        'mdi-eraser-variant',
        'mdi-escalator',
        'mdi-escalator-box',
        'mdi-escalator-down',
        'mdi-escalator-up',
        'mdi-eslint',
        'mdi-et',
        'mdi-ethereum',
        'mdi-ethernet',
        'mdi-ethernet-cable',
        'mdi-ethernet-cable-off',
        'mdi-ev-station',
        'mdi-evernote',
        'mdi-excavator',
        'mdi-exclamation',
        'mdi-exclamation-thick',
        'mdi-exit-run',
        'mdi-exit-to-app',
        'mdi-expand-all',
        'mdi-expand-all-outline',
        'mdi-expansion-card',
        'mdi-expansion-card-variant',
        'mdi-exponent',
        'mdi-exponent-box',
        'mdi-export',
        'mdi-export-variant',
        'mdi-eye',
        'mdi-eye-check',
        'mdi-eye-check-outline',
        'mdi-eye-circle',
        'mdi-eye-circle-outline',
        'mdi-eye-minus',
        'mdi-eye-minus-outline',
        'mdi-eye-off',
        'mdi-eye-off-outline',
        'mdi-eye-outline',
        'mdi-eye-plus',
        'mdi-eye-plus-outline',
        'mdi-eye-settings',
        'mdi-eye-settings-outline',
        'mdi-eyedropper',
        'mdi-eyedropper-minus',
        'mdi-eyedropper-off',
        'mdi-eyedropper-plus',
        'mdi-eyedropper-remove',
        'mdi-eyedropper-variant',
        'mdi-face',
        'mdi-face-agent',
        'mdi-face-outline',
        'mdi-face-profile',
        'mdi-face-profile-woman',
        'mdi-face-recognition',
        'mdi-face-woman',
        'mdi-face-woman-outline',
        'mdi-facebook',
        'mdi-facebook-messenger',
        'mdi-facebook-workplace',
        'mdi-factory',
        'mdi-fan',
        'mdi-fan-alert',
        'mdi-fan-chevron-down',
        'mdi-fan-chevron-up',
        'mdi-fan-minus',
        'mdi-fan-off',
        'mdi-fan-plus',
        'mdi-fan-remove',
        'mdi-fan-speed-1',
        'mdi-fan-speed-2',
        'mdi-fan-speed-3',
        'mdi-fast-forward',
        'mdi-fast-forward-10',
        'mdi-fast-forward-30',
        'mdi-fast-forward-5',
        'mdi-fast-forward-outline',
        'mdi-fax',
        'mdi-feather',
        'mdi-feature-search',
        'mdi-feature-search-outline',
        'mdi-fedora',
        'mdi-fencing',
        'mdi-ferris-wheel',
        'mdi-ferry',
        'mdi-file',
        'mdi-file-account',
        'mdi-file-account-outline',
        'mdi-file-alert',
        'mdi-file-alert-outline',
        'mdi-file-cabinet',
        'mdi-file-cad',
        'mdi-file-cad-box',
        'mdi-file-cancel',
        'mdi-file-cancel-outline',
        'mdi-file-certificate',
        'mdi-file-certificate-outline',
        'mdi-file-chart',
        'mdi-file-chart-outline',
        'mdi-file-check',
        'mdi-file-check-outline',
        'mdi-file-clock',
        'mdi-file-clock-outline',
        'mdi-file-cloud',
        'mdi-file-cloud-outline',
        'mdi-file-code',
        'mdi-file-code-outline',
        'mdi-file-cog',
        'mdi-file-cog-outline',
        'mdi-file-compare',
        'mdi-file-delimited',
        'mdi-file-delimited-outline',
        'mdi-file-document',
        'mdi-file-document-edit',
        'mdi-file-document edit-outline',
        'mdi-file-document-outline',
        'mdi-file-download',
        'mdi-file-download-outline',
        'mdi-file-edit',
        'mdi-file-edit-outline',
        'mdi-file-excel',
        'mdi-file-excel-box',
        'mdi-file-excel-box-outline',
        'mdi-file-excel-outline',
        'mdi-file-export',
        'mdi-file-export-outline',
        'mdi-file-eye',
        'mdi-file-eye-outline',
        'mdi-file-find',
        'mdi-fil-find-outline',
        'mdi-file-hidden',
        'mdi-file-image',
        'mdi-file-image-outline',
        'mdi-file-import ',
        'mdi-file-import-outline',
        'mdi-file-key',
        'mdi-file-key-outline',
        'mdi-file-link',
        'mdi-file-link-outline',
        'mdi-file-lock',
        'mdi-file-lock-outline',
        'mdi-file-move',
        'mdi-file-move-outline',
        'mdi-file-multiple',
        'mdi-file-multiple-outline',
        'mdi-file-music',
        'mdi-file-music-outline',
        'mdi-file-outline',
        'mdi-file-pdf',
        'mdi-file-pdf box',
        'mdi-file-pdf-box-outline',
        'mdi-file-pdf-outline',
        'mdi-file-percent',
        'mdi-file-percent-outline',
        'mdi-file-phone',
        'mdi-file-phone-outline',
        'mdi-file-plus',
        'mdi-file-plus-outline',
        'mdi-file-powerpoint',
        'mdi-file-powerpoint-box',
        'mdi-file-powerpoint-box-outline',
        'mdi-file-powerpoint-outline',
        'mdi-file-presentation-box',
        'mdi-file-question',
        'mdi-file-question-outline',
        'mdi-file-refresh',
        'mdi-file-refresh-outline',
        'mdi-file-remove',
        'mdi-file-remove-outline',
        'mdi-file-replace',
        'mdi-file-replace-outline',
        'mdi-file-restore',
        'mdi-file-restore-outline',
        'mdi-file-search',
        'mdi-file-search-outline',
        'mdi-file-send',
        'mdi-file-send-outline',
        'mdi-file-settings',
        'mdi-file-settings-outline',
        'mdi-file-star',
        'mdi-file-star-outline',
        'mdi-file-swap',
        'mdi-file-swap-outline',
        'mdi-file-sync',
        'mdi-file-sync-outline',
        'mdi-file-table',
        'mdi-file-table-box',
        'mdi-file-table-box-multiple',
        'mdi-file-table-box-multiple-outline',
        'mdi-file-table-box-outline',
        'mdi-file-table-outline',
        'mdi-file-tree',
        'mdi-file-tree-outline',
        'mdi-file-undo',
        'mdi-file-undo-outline',
        'mdi-file-upload',
        'mdi-file-upload-outline',
        'mdi-file-video',
        'mdi-file-video-outline',
        'mdi-file-word',
        'mdi-file-word-box',
        'mdi-file-word-box-outline',
        'mdi-file-word-outline',
        'mdi-film',
        'mdi-filmstrip',
        'mdi-filmstrip-box',
        'mdi-filmstrip-box-multiple',
        'mdi-filmstrip-off',
        'mdi-filter',
        'mdi-filter-menu',
        'mdi-filter-menu-outline',
        'mdi-filter-minus',
        'mdi-filter-minus-outline',
        'mdi-filter-outline',
        'mdi-filter-plus',
        'mdi-filter-plus-outline',
        'mdi-filter-remove',
        'mdi-filter-remove-outline',
        'mdi-filter-variant',
        'mdi-filter-variant-minus',
        'mdi-filter-variant-plus',
        'mdi-filter-variant-remove',
        'mdi-finance',
        'mdi-find-replace',
        'mdi-fingerprint',
        'mdi-fingerprint-off',
        'mdi-fire',
        'mdi-fire-extinguisher',
        'mdi-fire-hydrant',
        'mdi-fire-hydrant-alert',
        'mdi-fire-hydrant-off',
        'mdi-fire-truck',
        'mdi-firebase',
        'mdi-firefox',
        'mdi-fireplace',
        'mdi-fireplace-off',
        'mdi-firework',
        'mdi-fish',
        'mdi-fish-off',
        'mdi-fishbowl',
        'mdi-fishbowl-outline',
        'mdi-fit-to-page',
        'mdi-fi-to-page-outline',
        'mdi-flag',
        'mdi-flag-checkered',
        'mdi-flag-minus',
        'mdi-flag-minus-outline',
        'mdi-flag-outline',
        'mdi-flag-plus',
        'mdi-flag-plus-outline',
        'mdi-flag-remove',
        'mdi-flag-remove-outline',
        'mdi-flag-triangle',
        'mdi-flag-variant',
        'mdi-flag-variant-outline',
        'mdi-flare',
        'mdi-flash',
        'mdi-flash-alert',
        'mdi-flash-alert-outline',
        'mdi-flash-auto',
        'mdi-flash-circle',
        'mdi-flash-off',
        'mdi-flash-outline',
        'mdi-flash-red-eye',
        'mdi-flashlight',
        'mdi-flashlight-off',
        'mdi-flask',
        'mdi-flask-empty',
        'mdi-flask-empty-minus',
        'mdi-flask-empty-minus-outline',
        'mdi-flask-empty-off',
        'mdi-flask-empty-off-outline',
        'mdi-flask-empty-outline',
        'mdi-flask-empty-plus',
        'mdi-flask-empty-plus-outline',
        'mdi-flask-empty-remove',
        'mdi-flask-empty-remove-outline',
        'mdi-flask-minus',
        'mdi-flask-minus-outline',
        'mdi-flask-off',
        'mdi-flask-off-outline',
        'mdi-flask-outline',
        'mdi-flask-plus',
        'mdi-flask-plus-outline',
        'mdi-flask-remove',
        'mdi-flask-remove-outline',
        'mdi-flask-round-bottom',
        'mdi-flask-round-bottom-empty',
        'mdi-flask-round-bottom-empty-outline',
        'mdi-flask-round-bottom-outline',
        'mdi-fleur-de-lis',
        'mdi-flip-horizontal',
        'mdi-flip-to-back',
        'mdi-flip-to-front',
        'mdi-flip-vertical',
        'mdi-floor-lamp',
        'mdi-floor-lamp-dual',
        'mdi-floor-lamp-variant',
        'mdi-floor-plan',
        'mdi-floppy',
        'mdi-floppy-variant',
        'mdi-flower',
        'mdi-flower-outline',
        'mdi-flower-poppy',
        'mdi-flower-tulip',
        'mdi-flower-tulip-outline',
        'mdi-focus-auto',
        'mdi-focus-field',
        'mdi-focus-field-horizontal',
        'mdi-focus-field-vertical',
        'mdi-folder',
        'mdi-folder-account',
        'mdi-folder-account-outline',
        'mdi-folder-alert',
        'mdi-folder-alert-outline',
        'mdi-folder-clock',
        'mdi-folder-clock-outline',
        'mdi-folder-cog',
        'mdi-folder-cog-outline',
        'mdi-folder-download',
        'mdi-folder-download-outline',
        'mdi-folder-edit',
        'mdi-folder-edit-outline',
        'mdi-folder-google-drive',
        'mdi-folder-heart',
        'mdi-folder-heart-outline',
        'mdi-folder-home',
        'mdi-folder-home-outline',
        'mdi-folder-image',
        'mdi-folder-information',
        'mdi-folder-information-outline',
        'mdi-folder-key',
        'mdi-folder-key-network',
        'mdi-folder-key-network-outline',
        'mdi-folder-key-outline',
        'mdi-folder-lock',
        'mdi-folder-lock-open',
        'mdi-folder-marker',
        'mdi-folder-marker-outline',
        'mdi-folder-move',
        'mdi-folder-move-outline',
        'mdi-folder-multiple',
        'mdi-folder-multiple-image',
        'mdi-folder-multiple-outline',
        'mdi-folder-multiple-plus',
        'mdi-folder-multiple-plus-outline',
        'mdi-folder-music',
        'mdi-folder-music-outline',
        'mdi-folder-network',
        'mdi-folder-network-outline',
        'mdi-folder-open',
        'mdi-folder-open-outline',
        'mdi-folder-outline',
        'mdi-folder-plus',
        'mdi-folder-plus-outline',
        'mdi-folder-pound',
        'mdi-folder-pound-outline',
        'mdi-folder-refresh',
        'mdi-folder-refresh-outline',
        'mdi-folder-remove',
        'mdi-folder-remove-outline',
        'mdi-folder-search',
        'mdi-folder-search-outline',
        'mdi-folder-settings',
        'mdi-folder-settings-outline',
        'mdi-folder-star',
        'mdi-folder-star-multiple',
        'mdi-folder-star-multiple-outline',
        'mdi-folder-star-outline',
        'mdi-folder-swap',
        'mdi-folder-swap-outline',
        'mdi-folder-sync',
        'mdi-folder-sync-outline',
        'mdi-folder-table',
        'mdi-folder-table-outline',
        'mdi-folder-text',
        'mdi-folder-text-outline',
        'mdi-folder-upload',
        'mdi-folder-upload-outline',
        'mdi-folder-zip',
        'mdi-folder-zip-outline',
        'mdi-font-awesome',
        'mdi-food',
        'mdi-food-apple',
        'mdi-food-apple-outline',
        'mdi-food-croissant',
        'mdi-food-drumstick',
        'mdi-food-drumstick-off',
        'mdi-food-drumstick-off-outline',
        'mdi-food-drumstick-outline',
        'mdi-food-fork-drink',
        'mdi-food-off',
        'mdi-food-steak',
        'mdi-food-steak-off',
        'mdi-food-variant',
        'mdi-food-variant-off',
        'mdi-foot-print',
        'mdi-football',
        'mdi-football-australian',
        'mdi-football-helmet',
        'mdi-forklift',
        'mdi-form-dropdown',
        'mdi-form-select',
        'mdi-form-textarea',
        'mdi-form-textbox',
        'mdi-form-textbox-lock',
        'mdi-form-textbox-password',
        'mdi-format-align-bottom',
        'mdi-format-align-center',
        'mdi-format-align-justify',
        'mdi-format-align-left',
        'mdi-format-align-middle',
        'mdi-format-align-right',
        'mdi-format-align-top',
        'mdi-format-annotation-minus',
        'mdi-format-annotation-plus',
        'mdi-format-bold',
        'mdi-format-clear',
        'mdi-format-color-fill',
        'mdi-format-color-highlight',
        'mdi-format-color-marker-cancel',
        'mdi-format-color-text',
        'mdi-format-columns',
        'mdi-format-float-center',
        'mdi-format-float-left',
        'mdi-format-float-none',
        'mdi-format-float-right',
        'mdi-format-font',
        'mdi-format-font-size-decrease',
        'mdi-format-font-size-increase',
        'mdi-format-header-1',
        'mdi-format-header-2',
        'mdi-format-header-3',
        'mdi-format-header-4',
        'mdi-format-header-5',
        'mdi-format-header-6',
        'mdi-format-header-decrease',
        'mdi-format-header-equal',
        'mdi-format-header-increase',
        'mdi-format-header-pound',
        'mdi-format-horizontal-align-center',
        'mdi-format-horizontal-align-left',
        'mdi-format-horizontal-align-right',
        'mdi-format-indent-decrease',
        'mdi-format-indent-increase',
        'mdi-format-italic',
        'mdi-format-letter-case',
        'mdi-format-letter-case-lower',
        'mdi-format-letter-case-upper',
        'mdi-format-letter-ends-with',
        'mdi-format-letter-matches',
        'mdi-format-letter-starts-with',
        'mdi-format-line-spacing',
        'mdi-format-line-style',
        'mdi-format-line-weight',
        'mdi-format-list-bulleted',
        'mdi-format-list-bulleted-square',
        'mdi-format-list-bulleted-triangle',
        'mdi-format-list-bulleted-type',
        'mdi-format-list-checkbox',
        'mdi-format-list-checks',
        'mdi-format-list-numbered',
        'mdi-format-list-numbered-rtl',
        'mdi-format-list-text',
        'mdi-format-overline',
        'mdi-format-page-break',
        'mdi-format-paint',
        'mdi-format-paragraph',
        'mdi-format-pilcrow',
        'mdi-format-quote-close',
        'mdi-format-quote-close-outline',
        'mdi-format-quote-open',
        'mdi-format-quote-open-outline',
        'mdi-format-rotate-90',
        'mdi-format-section',
        'mdi-format-size',
        'mdi-format-strikethrough',
        'mdi-format-strikethrough-variant',
        'mdi-format-subscript',
        'mdi-format-superscript',
        'mdi-format-text',
        'mdi-format-text-rotation-angle-down',
        'mdi-format-text-rotation-angle-up',
        'mdi-format-text-rotation-down',
        'mdi-format-text-rotation-down-vertical',
        'mdi-format-text-rotation-none',
        'mdi-format-text-rotation-up',
        'mdi-format-text-rotation-vertical',
        'mdi-format-text-variant',
        'mdi-format-text-wrapping-clip',
        'mdi-format-text-wrapping-overflow',
        'mdi-format-text-wrapping-wrap',
        'mdi-format-textbox',
        'mdi-format-textdirection-l-to-r',
        'mdi-format-textdirection-r-to-l',
        'mdi-format-title',
        'mdi-format-underline',
        'mdi-format-vertical-align-bottom',
        'mdi-format-vertical-align-center',
        'mdi-format-vertical-align-top',
        'mdi-format-wrap-inline',
        'mdi-format-wrap-square',
        'mdi-format-wrap-tight',
        'mdi-format-wrap-top-bottom',
        'mdi-forum',
        'mdi-forum-outline',
        'mdi-forward',
        'mdi-forwardburger',
        'mdi-fountain',
        'mdi-fountain-pen',
        'mdi-fountain-pen-tip',
        'mdi-freebsd',
        'mdi-frequently-asked-questions',
        'mdi-fridge',
        'mdi-fridge-alert',
        'mdi-fridge-alert-outline',
        'mdi-fridge-bottom',
        'mdi-fridge-off',
        'mdi-fridge-off-outline',
        'mdi-fridge-outline',
        'mdi-fridge-top',
        'mdi-fruit-cherries',
        'mdi-fruit-cherries-off',
        'mdi-fruit-citrus',
        'mdi-fruit-citrus-off',
        'mdi-fruit-grapes',
        'mdi-fruit-grapes-outline',
        'mdi-fruit-pineapple',
        'mdi-fruit-watermelon',
        'mdi-fuel',
        'mdi-fullscreen',
        'mdi-fullscreen-exit',
        'mdi-function',
        'mdi-function-variant',
        'mdi-furigana-horizontal',
        'mdi-furigana-vertical',
        'mdi-fuse',
        'mdi-fuse-alert',
        'mdi-fuse-blade',
        'mdi-fuse-off',
        'mdi-gamepad',
        'mdi-gamepad-circle',
        'mdi-gamepad-circle-down',
        'mdi-gamepad-circle-left',
        'mdi-gamepad-circle-outline',
        'mdi-gamepad-circle-right',
        'mdi-gamepad-circle-up',
        'mdi-gamepad-down',
        'mdi-gamepad-left',
        'mdi-gamepad-right',
        'mdi-gamepad-round',
        'mdi-gamepad-round-down',
        'mdi-gamepad-round-left',
        'mdi-gamepad-round-outline',
        'mdi-gamepad-round-right',
        'mdi-gamepad-round-up',
        'mdi-gamepad-square',
        'mdi-gamepad-square-outline',
        'mdi-gamepad-up',
        'mdi-gamepad-variant',
        'mdi-gamepad-variant-outline',
        'mdi-gamma',
        'mdi-gantry-crane',
        'mdi-garage',
        'mdi-garage-alert',
        'mdi-garage-alert-variant',
        'mdi-garage-open',
        'mdi-garage-open-variant',
        'mdi-garage-variant',
        'mdi-gas-cylinder',
        'mdi-gas-station',
        'mdi-gas-station-off',
        'mdi-gas-station-off-outline',
        'mdi-gas-station-outline',
        'mdi-gate',
        'mdi-gate-and',
        'mdi-gate-arrow-right',
        'mdi-gate-nand',
        'mdi-gate-nor',
        'mdi-gate-not',
        'mdi-gate-open',
        'mdi-gate-or',
        'mdi-gate-xnor',
        'mdi-gate-xor',
        'mdi-gatsby',
        'mdi-gauge',
        'mdi-gauge-empty',
        'mdi-gauge-full',
        'mdi-gauge-low',
        'mdi-gavel',
        'mdi-gender-female',
        'mdi-gender-male',
        'mdi-gender-male-female',
        'mdi-gender-male-female-variant',
        'mdi-gender-non-binary',
        'mdi-gender-transgender',
        'mdi-gentoo',
        'mdi-gesture',
        'mdi-gesture-double-tap',
        'mdi-gesture-pinch',
        'mdi-gesture-spread',
        'mdi-gesture-swipe',
        'mdi-gesture-swipe-down',
        'mdi-gesture-swipe-horizontal',
        'mdi-gesture-swipe-left',
        'mdi-gesture-swipe-right',
        'mdi-gesture-swipe-up',
        'mdi-gesture-swipe-vertical',
        'mdi-gesture-tap',
        'mdi-gesture-tap-box',
        'mdi-gesture-tap-button',
        'mdi-gesture-tap-hold',
        'mdi-gesture-two-double-tap',
        'mdi-gesture-two-tap',
        'mdi-ghost',
        'mdi-ghost-off',
        'mdi-gif',
        'mdi-gift',
        'mdi-gift-outline',
        'mdi-git',
        'mdi-github',
        'mdi-gitlab',
        'mdi-glass-cocktail',
        'mdi-glass-flute',
        'mdi-glass-mug',
        'mdi-glass-mug-variant',
        'mdi-glass-pint-outline',
        'mdi-glass-stange',
        'mdi-glass-tulip',
        'mdi-glass-wine',
        'mdi-glasses',
        'mdi-globe-light',
        'mdi-globe-model',
        'mdi-gmail',
        'mdi-gnome',
        'mdi-go-kart',
        'mdi-go-kart-track',
        'mdi-gog',
        'mdi-gold',
        'mdi-golf',
        'mdi-golf-cart',
        'mdi-golf-tee',
        'mdi-gondola',
        'mdi-goodreads',
        'mdi-google',
        'mdi-google-ads',
        'mdi-google-analytics',
        'mdi-google-assistant',
        'mdi-google-cardboard',
        'mdi-google-chrome',
        'mdi-google-circles',
        'mdi-google-circles-communities',
        'mdi-google-circles-extended',
        'mdi-google-circles-group',
        'mdi-google-classroom',
        'mdi-google-cloud',
        'mdi-google-controller',
        'mdi-google-controller-of',
        'mdi-google-downasaur',
        'mdi-google-drive',
        'mdi-google-earth',
        'mdi-google-fit',
        'mdi-google-glass',
        'mdi-google-hangouts',
        'mdi-google-home',
        'mdi-google-keep',
        'mdi-google-lens',
        'mdi-google-maps',
        'mdi-google-my-business',
        'mdi-google-nearby',
        'mdi-google-photos',
        'mdi-google-play',
        'mdi-google-plus',
        'mdi-google-podcast',
        'mdi-google-spreadsheet',
        'mdi-google-street-view',
        'mdi-google-translate',
        'mdi-gradient',
        'mdi-grai',
        'mdi-graph',
        'mdi-graph-outline',
        'mdi-graphql',
        'mdi-grave-stone',
        'mdi-grease-pencil',
        'mdi-greater-than',
        'mdi-greater-than-or-equal',
        'mdi-grid',
        'mdi-grid-large',
        'mdi-grid-off',
        'mdi-grill',
        'mdi-grill-outline',
        'mdi-group',
        'mdi-guitar-acoustic',
        'mdi-guitar-electric',
        'mdi-guitar-pick',
        'mdi-guitar-pick-outline',
        'mdi-guy-fawkes-mask',
        'mdi-hail',
        'mdi-hair-dryer',
        'mdi-hair-dryer-outline',
        'mdi-halloween',
        'mdi-hamburger',
        'mdi-hammer',
        'mdi-hammer-screwdriver',
        'mdi-hammer-wrench',
        'mdi-hand',
        'mdi-hand-heart',
        'mdi-hand-left',
        'mdi-hand-oka',
        'mdi-hand-peace',
        'mdi-hand-peace-variant',
        'mdi-hand-pointing-down',
        'mdi-hand-pointing-left',
        'mdi-hand-pointing-right',
        'mdi-hand-pointing-up',
        'mdi-hand-right',
        'mdi-hand-saw',
        'mdi-hand-water',
        'mdi-handball',
        'mdi-handcuffs',
        'mdi-handshake',
        'mdi-hanger',
        'mdi-hard-hat',
        'mdi-harddisk',
        'mdi-harddisk-plus',
        'mdi-harddisk-remove',
        'mdi-hat-fedora',
        'mdi-hazard-lights',
        'mdi-hdr',
        'mdi-hdr-off',
        'mdi-head',
        'mdi-head-alert',
        'mdi-head-alert-outline',
        'mdi-head-check',
        'mdi-head-check-outline',
        'mdi-head-cog',
        'mdi-head-cog-outline',
        'mdi-head-dots-horizontal',
        'mdi-head-dots-horizontal-outline',
        'mdi-head-flash',
        'mdi-head-flash-outline',
        'mdi-head-heart',
        'mdi-head-heart-outline',
        'mdi-head-lightbulb',
        'mdi-head-lightbulb-outline',
        'mdi-head-minus',
        'mdi-head-minus-outline',
        'mdi-head-outline',
        'mdi-head-plus',
        'mdi-head-plus-outline',
        'mdi-head-question',
        'mdi-head-question-outline',
        'mdi-head-remove',
        'mdi-head-remove-outline',
        'mdi-head-snowflake',
        'mdi-head-snowflake-outline',
        'mdi-head-sync',
        'mdi-head-sync-outline',
        'mdi-headphones',
        'mdi-headphones-bluetooth',
        'mdi-headphones-box',
        'mdi-headphones-off',
        'mdi-headphones-settings',
        'mdi-headset',
        'mdi-headset-dock',
        'mdi-headset-off',
        'mdi-heart',
        'mdi-heart-box',
        'mdi-heart-box-outline',
        'mdi-heart-broken',
        'mdi-heart-broken-outline',
        'mdi-heart-circle',
        'mdi-heart-circle-outline',
        'mdi-heart-flash',
        'mdi-heart-half',
        'mdi-heart-half-full',
        'mdi-heart-half-outline',
        'mdi-heart-minus',
        'mdi-heart-minus-outline',
        'mdi-heart-multiple',
        'mdi-heart-multiple-outline',
        'mdi-heart-off',
        'mdi-heart-off-outline',
        'mdi-heart-outline',
        'mdi-heart-plus',
        'mdi-heart-plus-outline',
        'mdi-heart-pulse',
        'mdi-heart-remove',
        'mdi-heart-remove-outline',
        'mdi-helicopter',
        'mdi-help',
        'mdi-help-box',
        'mdi-help-circle',
        'mdi-help-circle-outline',
        'mdi-help-network',
        'mdi-help-network-outline',
        'mdi-help-rhombus',
        'mdi-help-rhombus-outline',
        'mdi-hexadecimal',
        'mdi-hexagon',
        'mdi-hexagon-multiple',
        'mdi-hexagon-multiple-outline',
        'mdi-hexagon-outline',
        'mdi-hexagon-slice-1',
        'mdi-hexagon-slice-2',
        'mdi-hexagon-slice-3',
        'mdi-hexagon-slice-4',
        'mdi-hexagon-slice-5',
        'mdi-hexagon-slice-6',
        'mdi-hexagram',
        'mdi-hexagram-outline',
        'mdi-high-definition',
        'mdi-high-definition-box',
        'mdi-highway',
        'mdi-hiking',
        'mdi-hinduism',
        'mdi-history',
        'mdi-hockey-puck',
        'mdi-hockey-sticks',
        'mdi-hololens',
        'mdi-home',
        'mdi-home-account',
        'mdi-home-alert',
        'mdi-home-analytics',
        'mdi-home-assistant',
        'mdi-home-automation',
        'mdi-home-circle',
        'mdi-home-circle-outline',
        'mdi-home-city',
        'mdi-home-city-outline',
        'mdi-home-currency-usd',
        'mdi-home-edit',
        'mdi-home-edit-outline',
        'mdi-home-export-outline',
        'mdi-home-flood',
        'mdi-home-floor-0',
        'mdi-home-floor-1',
        'mdi-home-floor-2',
        'mdi-home-floor-3',
        'mdi-home-floor-a',
        'mdi-home-floor-b',
        'mdi-home-floor-g',
        'mdi-home-floor-l',
        'mdi-home-floor-negative-1',
        'mdi-home-group',
        'mdi-home-heart',
        'mdi-home-import-outline',
        'mdi-home-lightbulb',
        'mdi-home-lightbulb-outline',
        'mdi-home-lock',
        'mdi-home-lock-open',
        'mdi-home-map-marker',
        'mdi-home-minus',
        'mdi-home-minus-outline',
        'mdi-home-modern',
        'mdi-home-outline',
        'mdi-home-plus',
        'mdi-home-plus-outline',
        'mdi-home-remove',
        'mdi-home-remove-outline',
        'mdi-home-roof',
        'mdi-home-search',
        'mdi-home-search-outline',
        'mdi-home-thermometer',
        'mdi-home-thermometer-outline',
        'mdi-home-variant',
        'mdi-home-variant-outline',
        'mdi-hook',
        'mdi-hook-off',
        'mdi-hops',
        'mdi-horizontal-rotate-clockwise',
        'mdi-horizontal-rotate-counterclockwise',
        'mdi-horseshoe',
        'mdi-hospital',
        'mdi-hospital-box',
        'mdi-hospital-box-outline',
        'mdi-hospital-building',
        'mdi-hospital-marker',
        'mdi-hot-tub',
        'mdi-hours-24',
        'mdi-hubspot',
        'mdi-hulu',
        'mdi-human',
        'mdi-human-baby-changing-table',
        'mdi-human-child',
        'mdi-human-female',
        'mdi-human-female-boy',
        'mdi-human-female-female',
        'mdi-human-female-girl',
        'mdi-human-greeting',
        'mdi-human-handsdown',
        'mdi-human-handsup',
        'mdi-human-male',
        'mdi-human-male-boy',
        'mdi-human-male-child',
        'mdi-human-male-female',
        'mdi-human-male-girl',
        'mdi-human-male-height',
        'mdi-human-male-height-variant',
        'mdi-human-male-male',
        'mdi-human-pregnant',
        'mdi-human-wheelchair',
        'mdi-humble-bundle',
        'mdi-hvac',
        'mdi-hydraulic-oil-level',
        'mdi-hydraulic-oil-temperature',
        'mdi-hydro-power',
        'mdi-ice-cream',
        'mdi-ice-cream-off',
        'mdi-ice-pop',
        'mdi-id-card',
        'mdi-identifier',
        'mdi-ideogram-cjk',
        'mdi-ideogram-cjk-variant',
        'mdi-iframe',
        'mdi-iframe-array',
        'mdi-iframe-array-outline',
        'mdi-iframe-braces',
        'mdi-iframe-braces-outline',
        'mdi-iframe-outline',
        'mdi-iframe-parentheses',
        'mdi-iframe-parentheses-outline',
        'mdi-iframe-variable',
        'mdi-iframe-variable-outline',
        'mdi-image',
        'mdi-image-album',
        'mdi-image-area',
        'mdi-image-area-close',
        'mdi-image-auto-adjust',
        'mdi-image-broken',
        'mdi-image-broken-variant',
        'mdi-image-edit',
        'mdi-image-edit-outline',
        'mdi-image-filter-black-white',
        'mdi-image-filter-center-focus',
        'mdi-image-filter-center-focus-strong',
        'mdi-image-filter-center-focus-strong-outline',
        'mdi-image-filter-center-focus-weak',
        'mdi-image-filter-drama',
        'mdi-image-filter-frames',
        'mdi-image-filter-hdr',
        'mdi-image-filter-none',
        'mdi-image-filter-tilt-shift',
        'mdi-image-filter-vintage',
        'mdi-image-frame',
        'mdi-image-minus',
        'mdi-image-move',
        'mdi-image-multiple',
        'mdi-image-multiple-outline',
        'mdi-image-off',
        'mdi-image-off-outline',
        'mdi-image-outline',
        'mdi-image-plus',
        'mdi-image-remove',
        'mdi-image-search',
        'mdi-image-search-outline',
        'mdi-image-size-select-actual',
        'mdi-image-size-select-large',
        'mdi-image-size-select-small',
        'mdi-import',
        'mdi-inbox',
        'mdi-inbox-arrow-down',
        'mdi-inbox-arrow-down-outline',
        'mdi-inbox-arrow-up',
        'mdi-inbox-arrow-up-outline',
        'mdi-inbox-full',
        'mdi-inbox-full-outline',
        'mdi-inbox-multiple',
        'mdi-inbox-multiple-outline',
        'mdi-inbox-outline',
        'mdi-incognito',
        'mdi-incognito-circle',
        'mdi-incognito-circle-of',
        'mdi-incognito-off',
        'mdi-infinity',
        'mdi-information',
        'mdi-information-outline',
        'mdi-information-variant',
        'mdi-instagram',
        'mdi-instrument-triangle',
        'mdi-invert-colors',
        'mdi-invert-color-off',
        'mdi-iobroker',
        'mdi-ip',
        'mdi-ip-network',
        'mdi-ip-network-outline',
        'mdi-ipod',
        'mdi-islam',
        'mdi-island',
        'mdi-iv-bag',
        'mdi-jabber',
        'mdi-jeepney',
        'mdi-jellyfish',
        'mdi-jellyfish-outline',
        'mdi-jira',
        'mdi-jquery',
        'mdi-jsfiddle',
        'mdi-judaism',
        'mdi-jump-rope',
        'mdi-kabaddi',
        'mdi-karate',
        'mdi-keg',
        'mdi-kettle',
        'mdi-kettle-alert',
        'mdi-kettle-alert-outline',
        'mdi-kettle-off',
        'mdi-kettle-off outline',
        'mdi-kettle-outline',
        'mdi-kettle-steam',
        'mdi-kettle-steam-outline',
        'mdi-kettlebell',
        'mdi-key',
        'mdi-key-arrow-right',
        'mdi-key-change',
        'mdi-key-link',
        'mdi-key-minus',
        'mdi-key-outline',
        'mdi-key-plus',
        'mdi-key-remove',
        'mdi-key-star',
        'mdi-key-variant',
        'mdi-key-wireless',
        'mdi-keyboard',
        'mdi-keyboard-backspace',
        'mdi-keyboard-caps',
        'mdi-keyboard-close',
        'mdi-keyboard-es',
        'mdi-keyboard-f1',
        'mdi-keyboard-f10',
        'mdi-keyboard-f11',
        'mdi-keyboard-f12',
        'mdi-keyboard-f2',
        'mdi-keyboard-f3',
        'mdi-keyboard-f4',
        'mdi-keyboard-f5',
        'mdi-keyboard-f6',
        'mdi-keyboard-f7',
        'mdi-keyboard-f8',
        'mdi-keyboard-f9',
        'mdi-keyboard-off',
        'mdi-keyboard-off-outline',
        'mdi-keyboard-outline',
        'mdi-keyboard-return',
        'mdi-keyboard-settings',
        'mdi-keyboard-settings-outline',
        'mdi-keyboard-space',
        'mdi-keyboard-ta',
        'mdi-keyboard-variant',
        'mdi-khanda',
        'mdi-kickstarter',
        'mdi-klingon',
        'mdi-knife',
        'mdi-knife-military',
        'mdi-kod',
        'mdi-kubernetes',
        'mdi-label',
        'mdi-label-multiple',
        'mdi-label-multiple-outline',
        'mdi-label-off',
        'mdi-label-off-outline',
        'mdi-label-outline',
        'mdi-label-percent',
        'mdi-label-percent-outline',
        'mdi-label-variant',
        'mdi-label-variant-outline',
        'mdi-ladybug',
        'mdi-lambda',
        'mdi-lamp',
        'mdi-lan',
        'mdi-lan-check',
        'mdi-lan-connect',
        'mdi-lan-disconnect',
        'mdi-lan-pending',
        'mdi-language-c',
        'mdi-language-cpp',
        'mdi-language-csharp',
        'mdi-language-css3',
        'mdi-language-fortran',
        'mdi-language-go',
        'mdi-language-haskell',
        'mdi-language-html5',
        'mdi-language-java',
        'mdi-language-javascript',
        'mdi-language-kotlin',
        'mdi-language-lua',
        'mdi-language-markdown',
        'mdi-language-markdown-outline',
        'mdi-language-php',
        'mdi-language-python',
        'mdi-language-r',
        'mdi-language-ruby',
        'mdi-language-ruby-on-rails',
        'mdi-language-swift',
        'mdi-language-typescript',
        'mdi-language-xaml',
        'mdi-laptop',
        'mdi-laptop-chromebook',
        'mdi-laptop-mac',
        'mdi-laptop-off',
        'mdi-laptop-windows',
        'mdi-laravel',
        'mdi-laser-pointer',
        'mdi-lasso',
        'mdi-lastpass',
        'mdi-latitude',
        'mdi-launch',
        'mdi-lava-lamp',
        'mdi-layers',
        'mdi-layers-minus',
        'mdi-layers-off',
        'mdi-layers-off-outline',
        'mdi-layers-outline',
        'mdi-layer-plus',
        'mdi-layers-remove',
        'mdi-layers-search',
        'mdi-layers-search-outline',
        'mdi-layers-triple',
        'mdi-layers-triple-outline',
        'mdi-lead-pencil',
        'mdi-leaf',
        'mdi-leaf-maple',
        'mdi-leaf-maple-off',
        'mdi-leaf-off',
        'mdi-leak',
        'mdi-leak-off',
        'mdi-led-off',
        'mdi-led-on',
        'mdi-led outline',
        'mdi-led-strip',
        'mdi-led-strip-variant',
        'mdi-led-variant-off',
        'mdi-led-variant-on',
        'mdi-led-variant-outline',
        'mdi-leek',
        'mdi-less-than',
        'mdi-less-than-or-equal',
        'mdi-library',
        'mdi-library-shelves',
        'mdi-license',
        'mdi-lifebuoy',
        'mdi-light-switch',
        'mdi-lightbulb',
        'mdi-lightbulb-cfl',
        'mdi-lightbulb-cfl-off',
        'mdi-lightbulb-cfl-spiral',
        'mdi-lightbulb-cfl-spiral-off',
        'mdi-lightbulb-group',
        'mdi-lightbulb-group-off',
        'mdi-lightbulb-group-off-outline',
        'mdi-lightbulb-group-outline',
        'mdi-lightbulb-multiple',
        'mdi-lightbulb-multiple-off',
        'mdi-lightbulb-multiple-off-outline',
        'mdi-lightbulb-multiple-outline',
        'mdi-lightbulb-off',
        'mdi-lightbulb-off-outline',
        'mdi-lightbulb-on',
        'mdi-lightbulb-on-outline',
        'mdi-lightbulb-outline',
        'mdi-lighthouse',
        'mdi-lighthouse-on',
        'mdi-lightning-bolt',
        'mdi-lightning-bolt-outline',
        'mdi-lingerie',
        'mdi-link',
        'mdi-link-box',
        'mdi-link-box-outline',
        'mdi-link-box-variant',
        'mdi-link-box-variant-outline',
        'mdi-link-lock',
        'mdi-link-off',
        'mdi-link-plus',
        'mdi-link-variant',
        'mdi-link-variant-minus',
        'mdi-link-variant-off',
        'mdi-link-variant-plus',
        'mdi-link-variant-remove',
        'mdi-linkedin',
        'mdi-linux',
        'mdi-linux-mint',
        'mdi-lipstick',
        'mdi-litecoin',
        'mdi-loading',
        'mdi-location-enter',
        'mdi-location-exit',
        'mdi-lock',
        'mdi-lock-alert',
        'mdi-lock-check',
        'mdi-lock-clock',
        'mdi-lock-open',
        'mdi-lock-open-alert',
        'mdi-lock-open-check',
        'mdi-lock-open-outline',
        'mdi-lock-open-variant',
        'mdi-lock-open-variant-outline',
        'mdi-lock-outline',
        'mdi-lock-pattern',
        'mdi-lock-plus',
        'mdi-lock-question',
        'mdi-lock-reset',
        'mdi-lock-smart',
        'mdi-locker',
        'mdi-locker-multiple',
        'mdi-login',
        'mdi-login-variant',
        'mdi-logout',
        'mdi-logout-variant',
        'mdi-longitude',
        'mdi-looks',
        'mdi-loupe',
        'mdi-lumx',
        'mdi-lungs',
        'mdi-magnet',
        'mdi-magnet-on',
        'mdi-magnify',
        'mdi-magnify-close',
        'mdi-magnify-minus',
        'mdi-magnify-minus-cursor',
        'mdi-magnify-minus-outline',
        'mdi-magnify-plus',
        'mdi-magnify-plus-cursor',
        'mdi-magnify-plus-outline',
        'mdi-magnify-remove-cursor',
        'mdi-magnify-remove-outline',
        'mdi-magnify-scan',
        'mdi-mail',
        'mdi-mailbox',
        'mdi-mailbox-open',
        'mdi-mailbox-open-outline',
        'mdi-mailbox-open-up',
        'mdi-mailbox-open-up-outline',
        'mdi-mailbox-outline',
        'mdi-mailbox-up',
        'mdi-mailbox-up-outline',
        'mdi-map',
        'mdi-map-check',
        'mdi-map-check-outline',
        'mdi-map-clock',
        'mdi-map-clock-outline',
        'mdi-map-legend',
        'mdi-map-marker',
        'mdi-map-marker-alert',
        'mdi-map-marker-alert-outline',
        'mdi-map-marker-check',
        'mdi-map-marker-check-outline',
        'mdi-map-marker-circle',
        'mdi-map-marker-distance',
        'mdi-map-marker-down',
        'mdi-map-marker-left',
        'mdi-map-marker-left-outline',
        'mdi-map-marker-minus',
        'mdi-map-marker-minus-outline',
        'mdi-map-marker-multiple',
        'mdi-map-marker-multiple-outline',
        'mdi-map-marker-off',
        'mdi-map-marker-off-outline',
        'mdi-map-marker-outline',
        'mdi-map-marker-path',
        'mdi-map-marker-plus',
        'mdi-map-marker-plus-outline',
        'mdi-map-marker-question',
        'mdi-map-marker-question-outline',
        'mdi-map-marker-radius',
        'mdi-map-marker-radius-outline',
        'mdi-map-marker-remove',
        'mdi-map-marker-remove-outline',
        'mdi-map-marker-remove-variant',
        'mdi-map-marker-right',
        'mdi-map-marker-right-outline',
        'mdi-map-marker-up',
        'mdi-map-minus',
        'mdi-map-outline',
        'mdi-map-plus',
        'mdi-map-search',
        'mdi-map-search-outline',
        'mdi-mapbox',
        'mdi-margin',
        'mdi-marker',
        'mdi-marker-cancel',
        'mdi-marker-check',
        'mdi-mastodon',
        'mdi-material-design',
        'mdi-material-ui',
        'mdi-math-compass',
        'mdi-math-cos',
        'mdi-math-integral',
        'mdi-math-integral-box',
        'mdi-math-log',
        'mdi-math-norm',
        'mdi-math-norm-box',
        'mdi-math-sin',
        'mdi-math-tan',
        'mdi-matrix',
        'mdi-medal',
        'mdi-medal-outline',
        'mdi-medical-bag',
        'mdi-meditation',
        'mdi-memory',
        'mdi-menu',
        'mdi-menu-down',
        'mdi-menu-down-outline',
        'mdi-menu-left',
        'mdi-menu-left-outline',
        'mdi-menu-open',
        'mdi-menu-right',
        'mdi-menu-right-outline',
        'mdi-menu-swap',
        'mdi-menu-swap-outline',
        'mdi-menu-up',
        'mdi-menu-up-outline',
        'mdi-merge',
        'mdi-message',
        'mdi-message-alert',
        'mdi-message-alert-outline',
        'mdi-message-arrow-left',
        'mdi-message-arrow-left-outline',
        'mdi-message-arrow-right',
        'mdi-message-arrow-right-outline',
        'mdi-message-bulleted',
        'mdi-message-bulleted-off',
        'mdi-message-cog',
        'mdi-message-cog-outline',
        'mdi-message-draw',
        'mdi-message-image',
        'mdi-message-image-outline',
        'mdi-message-lock',
        'mdi-message-lock-outline',
        'mdi-message-minus',
        'mdi-message-minus-outline',
        'mdi-message-outline',
        'mdi-message-plus',
        'mdi-message-plus-outline',
        'mdi-message-processing',
        'mdi-message processing-outline',
        'mdi-message-reply',
        'mdi-message-reply-tex',
        'mdi-message-settings',
        'mdi-message-settings-outline',
        'mdi-message-text',
        'mdi-message-text-clock',
        'mdi-message-text-clock-outline',
        'mdi-message-text-loc',
        'mdi-message-text-lock-outline',
        'mdi-message-text-outline',
        'mdi-message-video',
        'mdi-meteor',
        'mdi-metronome',
        'mdi-metronome-tick',
        'mdi-micro sd',
        'mdi-microphone',
        'mdi-microphone-minus',
        'mdi-microphone-off',
        'mdi-microphone-outline',
        'mdi-microphone-plus',
        'mdi-microphone-settings',
        'mdi-microphone-variant',
        'mdi-microphone-variant-off',
        'mdi-microscope',
        'mdi-microsoft',
        'mdi-microsoft-access',
        'mdi-microsoft-azure',
        'mdi-microsoft-azure-devops',
        'mdi-microsoft-bing',
        'mdi-microsoft-dynamics-365',
        'mdi-microsoft-edge',
        'mdi-microsoft-edge-legacy',
        'mdi-microsoft-excel',
        'mdi-microsoft-internet-explorer',
        'mdi-microsoft-office',
        'mdi-microsoft-onedrive',
        'mdi-microsoft-onenote',
        'mdi-microsoft-outlook',
        'mdi-microsoft-powerpoint',
        'mdi-microsoft-sharepoint',
        'mdi-microsoft-teams',
        'mdi-microsoft-visual-studio',
        'mdi-microsoft-visual-studio-code',
        'mdi-microsoft-windows',
        'mdi-microsoft-windows classic',
        'mdi-microsoft-word',
        'mdi-microsoft-xbox',
        'mdi-microsoft-xbox-controller',
        'mdi-microsoft-xbox-controller-battery-alert',
        'mdi-microsoft-xbox controller-battery-charging',
        'mdi-microsoft-xbo-controller-battery-empty',
        'mdi-microsoft-xbox-controller-battery-full',
        'mdi-microsoft-xbox-controller-battery-low',
        'mdi-microsoft-xbox-controller-battery-medium',
        'mdi-microsoft-xbox-controller-battery-unknown',
        'mdi-microsoft-xbox-controller-menu',
        'mdi-microsoft-xbox-controller-off',
        'mdi-microsoft-xbox-controller-view',
        'mdi-microsoft-yammer',
        'mdi-microwave',
        'mdi-microwave-off',
        'mdi-middleware',
        'mdi-middleware-outline',
        'mdi-midi',
        'mdi-midi-port',
        'mdi-mine',
        'mdi-minecraft',
        'mdi-mini-sd',
        'mdi-minidisc',
        'mdi-minus',
        'mdi-minus-box',
        'mdi-minus-box-multiple',
        'mdi-minus-box-multiple-outline',
        'mdi-minus-box-outline',
        'mdi-minus-circle',
        'mdi-minus-circle-multiple',
        'mdi-minus-circle-multipl-outline',
        'mdi-minus-circle-off',
        'mdi-minus-circle-off-outline',
        'mdi-minus-circle-outline',
        'mdi-minus-network',
        'mdi-minus-network-outline',
        'mdi-mirror',
        'mdi-mixed-martial-arts',
        'mdi-mixed-reality',
        'mdi-mixer',
        'mdi-molecule',
        'mdi-molecule-co',
        'mdi-molecule-co2',
        'mdi-monitor',
        'mdi-monitor-cellphone',
        'mdi-monitor-cellphone-star',
        'mdi-monitor-clean',
        'mdi-monitor-dashboard',
        'mdi-monitor-edit',
        'mdi-monitor-eye',
        'mdi-monitor-lock',
        'mdi-monitor-multiple',
        'mdi-monitor-off',
        'mdi-monitor-screenshot',
        'mdi-monitor-share',
        'mdi-monitor-speaker',
        'mdi-monitor-speaker-off',
        'mdi-monitor-star',
        'mdi-moon-first-quarter',
        'mdi-moon-full',
        'mdi-moon-last-quarter',
        'mdi-moon-new',
        'mdi-moon-waning-crescent',
        'mdi-moon-waning-gibbous',
        'mdi-moon-waxing-crescent',
        'mdi-moon-waxing-gibbous',
        'mdi-moped',
        'mdi-more',
        'mdi-mother-heart',
        'mdi-mother-nurse',
        'mdi-motion-sensor',
        'mdi-motion-sensor-off',
        'mdi-motorbike',
        'mdi-mouse',
        'mdi-mouse-bluetooth',
        'mdi-mouse-off',
        'mdi-mouse-variant',
        'mdi-mouse-variant-off',
        'mdi-move-resize',
        'mdi-move-resize-variant',
        'mdi-movie',
        'mdi-movie-edit',
        'mdi-movie-edit-outline',
        'mdi-movie-filter',
        'mdi-movie-filter-outline',
        'mdi-movie-open',
        'mdi-movie-open-outline',
        'mdi-movie-outline',
        'mdi-movie-roll',
        'mdi-movie-search',
        'mdi-movie-search-outline',
        'mdi-muffin',
        'mdi-multiplication',
        'mdi-multiplication-box',
        'mdi-mushroom',
        'mdi-mushroom-off',
        'mdi-mushroom-off-outline',
        'mdi-mushroom-outline',
        'mdi-music',
        'mdi-music-accidental-double-flat',
        'mdi-music-accidental-double-sharp',
        'mdi-music-accidental-flat',
        'mdi-music-accidental-natural',
        'mdi-music-accidental-sharp',
        'mdi-music-box',
        'mdi-music-box-multiple',
        'mdi-music-box-multiple-outline',
        'mdi-music-box-outline',
        'mdi-music-circle',
        'mdi-music-circle-outline',
        'mdi-music-clef-alto',
        'mdi-music-clef-bass',
        'mdi-music-clef-treble',
        'mdi-music-note',
        'mdi-music-note-bluetooth',
        'mdi-musi-note-bluetooth-off',
        'mdi-music-note-eighth',
        'mdi-music-note-eighth-dotted',
        'mdi-music-note-half',
        'mdi-music-note-half-dotted',
        'mdi-music-note-off',
        'mdi-music-note-off-outline',
        'mdi-music-note-outline',
        'mdi-music-note-plus',
        'mdi-music-note-quarter',
        'mdi-music-note-quarter-dotted',
        'mdi-musi-note-sixteenth',
        'mdi-music-note-sixteenth-dotted',
        'mdi-music-note-whole',
        'mdi-music-note-whole-dotted',
        'mdi-music-off',
        'mdi-music-rest-eighth',
        'mdi-music-rest-half',
        'mdi-music rest-quarter',
        'mdi-music-rest-sixteenth',
        'mdi-music-rest-whole',
        'mdi-nail',
        'mdi-nas',
        'mdi-nativescript',
        'mdi-nature',
        'mdi-nature-people',
        'mdi-navigation',
        'mdi-near-me',
        'mdi-necklace',
        'mdi-needle',
        'mdi-netflix',
        'mdi-network',
        'mdi-network-off',
        'mdi-network-off-outline',
        'mdi-network-outline',
        'mdi-network-strength-1',
        'mdi-network-strength-1-alert',
        'mdi-network-strength-2',
        'mdi-network-strength-2-alert',
        'mdi-network-strength-3',
        'mdi-network-strength-3-alert',
        'mdi-network-strength-4',
        'mdi-network-strength-4-alert',
        'mdi-network-strength-off',
        'mdi-network-strength-off-outline',
        'mdi-network-strength-outline',
        'mdi-new-box',
        'mdi-newspaper',
        'mdi-newspaper-minus',
        'mdi-newspaper-plus',
        'mdi-newspaper-variant',
        'mdi-newspaper-variant-multiple',
        'mdi-newspaper-variant-multiple-outline',
        'mdi-newspaper-variant-outline',
        'mdi-nfc',
        'mdi-nfc-search-variant',
        'mdi-nfc-tap',
        'mdi-nfc-variant',
        'mdi-nfc-variant-off',
        'mdi-ninja',
        'mdi-nintendo-game-boy',
        'mdi-nintendo-switch',
        'mdi-nintendo-wii',
        'mdi-nintendo-wiiu',
        'mdi-nix',
        'mdi-nodejs',
        'mdi-noodles',
        'mdi-not-equal',
        'mdi-not-equal-variant',
        'mdi-note',
        'mdi-note-multiple',
        'mdi-note-multiple-outline',
        'mdi-note-outline',
        'mdi-note-plus',
        'mdi-note-plus-outlin',
        'mdi-note-text',
        'mdi-note-text-outline',
        'mdi-notebook',
        'mdi-notebook-multiple',
        'mdi-notebook-outline',
        'mdi-notification-clear-all',
        'mdi-npm',
        'mdi-nuke',
        'mdi-null',
        'mdi-numeric',
        'mdi-numeric-0',
        'mdi-numeric-0-box',
        'mdi-numeric-0-box-multiple',
        'mdi-numeric-0-box-multiple-outline',
        'mdi-numeric-0-box-outline',
        'mdi-numeric-0-circle',
        'mdi-numeric-0-circle-outline',
        'mdi-numeric-1',
        'mdi-numeric-1-box',
        'mdi-numeric-1-box-multiple',
        'mdi-numeric-1-box-multiple-outline',
        'mdi-numeric-1-box-outline',
        'mdi-numeric-1-circle',
        'mdi-numeric-1-circle-outline',
        'mdi-numeric-10',
        'mdi-numeric-10-box',
        'mdi-numeric-10-box-multiple',
        'mdi-numeric-10-box-multiple-outline',
        'mdi-numeric-10-box-outline',
        'mdi-numeric-10-circle',
        'mdi-numeric-10-circle-outline',
        'mdi-numeric-2',
        'mdi-numeric-2-box',
        'mdi-numeric-2-box-multiple',
        'mdi-numeric-2-box-multiple-outline',
        'mdi-numeric-2-box-outline',
        'mdi-numeric-2-circle',
        'mdi-numeric-2-circle-outline',
        'mdi-numeric-3',
        'mdi-numeric-3-box',
        'mdi-numeric-3-box-multiple',
        'mdi-numeric-3-box-multiple-outline',
        'mdi-numeric-3-box-outline',
        'mdi-numeric-3-circle',
        'mdi-numeric-3-circle-outline',
        'mdi-numeric-4',
        'mdi-numeric-4-box',
        'mdi-numeric-4-box-multiple',
        'mdi-numeric-4-box-multiple-outline',
        'mdi-numeric-4-box-outline',
        'mdi-numeric-4-circle',
        'mdi-numeric-4-circle-outline',
        'mdi-numeric-5',
        'mdi-numeric-5-box',
        'mdi-numeric-5-box-multiple',
        'mdi-numeric-5-box-multiple-outline',
        'mdi-numeric-5-box-outline',
        'mdi-numeric-5-circle',
        'mdi-numeric-5-circle-outline',
        'mdi-numeric-6',
        'mdi-numeric-6-box',
        'mdi-numeric-6-box-multiple',
        'mdi-numeric-6-box-multiple-outline',
        'mdi-numeric-6-box-outline',
        'mdi-numeric-6-circle',
        'mdi-numeric-6-circle-outline',
        'mdi-numeric-7',
        'mdi-numeric-7-box',
        'mdi-numeric-7-box-multiple',
        'mdi-numeric-7-box-multiple-outline',
        'mdi-numeric-7-box-outline',
        'mdi-numeric-7-circle',
        'mdi-numeric-7-circle-outline',
        'mdi-numeric-8',
        'mdi-numeric-8-box',
        'mdi-numeric-8-box-multiple',
        'mdi-numeric-8-box-multiple-outline',
        'mdi-numeric-8-box-outline',
        'mdi-numeric-8-circle',
        'mdi-numeric-8-circle-outline',
        'mdi-numeric-9',
        'mdi-numeric-9-box',
        'mdi-numeric-9-box-multiple',
        'mdi-numeric-9-box-multiple-outline',
        'mdi-numeric-9-box-outline',
        'mdi-numeric-9-circle',
        'mdi-numeric-9-circle-outline',
        'mdi-numeric-9-plus',
        'mdi-numeric-9-plus-box',
        'mdi-numeric-9-plus-box-multiple',
        'mdi-numeric-9-plus-box-multiple-outline',
        'mdi-numeric-9-plus-box-outline',
        'mdi-numeric-9-plus-circle',
        'mdi-numeric-9-plus-circle-outline',
        'mdi-numeric-negative-1',
        'mdi-nut',
        'mdi-nutrition',
        'mdi-nuxt',
        'mdi-oar',
        'mdi-ocarina',
        'mdi-oci',
        'mdi-ocr',
        'mdi-octagon',
        'mdi-octagon-outline',
        'mdi-octagram',
        'mdi-octagram-outline',
        'mdi-odnoklassniki',
        'mdi-offer',
        'mdi-office-building',
        'mdi-oil',
        'mdi-oil-lamp',
        'mdi-oil-level',
        'mdi-oil-temperature',
        'mdi-omega',
        'mdi-one-up',
        'mdi-onepassword',
        'mdi-opacity',
        'mdi-open-in-app',
        'mdi-open-in-new',
        'mdi-open-source-initiative',
        'mdi-openid',
        'mdi-opera',
        'mdi-orbit',
        'mdi-order-alphabetical-ascending',
        'mdi-order-alphabetical-descending',
        'mdi-order-bool-ascending',
        'mdi-order-bool-ascending-variant',
        'mdi-order-bool-descending',
        'mdi-order-bool-descending-varian',
        'mdi-order-numeric-ascending',
        'mdi-order-numeric-descending',
        'mdi-origin',
        'mdi-ornament',
        'mdi-ornament-variant',
        'mdi-outdoor-lamp',
        'mdi-overscan',
        'mdi-owl',
        'mdi-pac-man',
        'mdi-package',
        'mdi-package-down',
        'mdi-package-up',
        'mdi-package-variant',
        'mdi-package-variant-closed',
        'mdi-page-first',
        'mdi-page-last',
        'mdi-page-layout-body',
        'mdi-page-layout-footer',
        'mdi-page-layout-header',
        'mdi-page-layout-header-footer',
        'mdi-page-layout-sidebar-left',
        'mdi-page-layout-sidebar-right',
        'mdi-page-next',
        'mdi-page-next-outline',
        'mdi-page-previous',
        'mdi-page-previous-outline',
        'mdi-pail',
        'mdi-pail-minus',
        'mdi-pail-minus-outline',
        'mdi-pail-off',
        'mdi-pail-off-outline',
        'mdi-pail-outline',
        'mdi-pail-plus',
        'mdi-pail-plus-outline',
        'mdi-pail-remove',
        'mdi-pail-remove-outline',
        'mdi-palette',
        'mdi-palette-advanced',
        'mdi-palette-outline',
        'mdi-palette-swatch',
        'mdi-palette-swatch-outline',
        'mdi-palm-tree',
        'mdi-pan',
        'mdi-pan-bottom-left',
        'mdi-pan-bottom-right',
        'mdi-pan-down',
        'mdi-pan-horizontal',
        'mdi-pan-left',
        'mdi-pan-right',
        'mdi-pan-top-left',
        'mdi-pan-top-right',
        'mdi-pan-up',
        'mdi-pan-vertical',
        'mdi-panda',
        'mdi-pandora',
        'mdi-panorama',
        'mdi-panorama-fisheye',
        'mdi-panorama-horizontal',
        'mdi-panorama-vertical',
        'mdi-panorama-wide-angle',
        'mdi-paper-cut-vertical',
        'mdi-paper-roll',
        'mdi-paper-roll-outline',
        'mdi-paperclip',
        'mdi-parachute',
        'mdi-parachute-outline',
        'mdi-parking',
        'mdi-party-popper',
        'mdi-passport',
        'mdi-passport-biometric',
        'mdi-pasta',
        'mdi-patio-heater',
        'mdi-patreon',
        'mdi-pause',
        'mdi-pause-circle',
        'mdi-pause-circle-outline',
        'mdi-pause-octagon',
        'mdi-pause-octagon-outline',
        'mdi-paw',
        'mdi-paw-off',
        'mdi-pdf-box',
        'mdi-peace',
        'mdi-peanut',
        'mdi-peanut-off',
        'mdi-peanut-off-outline',
        'mdi-peanut-outline',
        'mdi-pen',
        'mdi-pen lock',
        'mdi-pen-minus',
        'mdi-pen-off',
        'mdi-pen-plus',
        'mdi-pen-remove',
        'mdi-pencil',
        'mdi-pencil-box',
        'mdi-pencil-box-multiple',
        'mdi-pencil-box-multiple-outline',
        'mdi-pencil-box-outline',
        'mdi-pencil-circle',
        'mdi-pencil-circle-outline',
        'mdi-pencil-lock',
        'mdi-pencil-lock-outline',
        'mdi-pencil-minus',
        'mdi-pencil-minus-outline',
        'mdi-pencil-off',
        'mdi-pencil-off-outline',
        'mdi-pencil-outline',
        'mdi-pencil-plus',
        'mdi-pencil-plus-outline',
        'mdi-pencil-remove',
        'mdi-pencil-remove-outline',
        'mdi-pencil-ruler',
        'mdi-penguin',
        'mdi-pentagon',
        'mdi-pentagon-outline',
        'mdi-percent',
        'mdi-percent-outline',
        'mdi-periodic-table',
        'mdi-perspective-less',
        'mdi-perspective-more',
        'mdi-store',
        'mdi-phone',
        'mdi-phone-alert',
        'mdi-phone-alert-outline',
        'mdi-phone-bluetooth',
        'mdi-phone-bluetooth-outline',
        'mdi-phone-cancel',
        'mdi-phone-cancel-outline',
        'mdi-phone-check',
        'mdi-phone-check-outline',
        'mdi-phone-classic',
        'mdi-phone-classic-off',
        'mdi-phone-forward',
        'mdi-phone-forward-outline',
        'mdi-phone-hangup',
        'mdi-phone-hangup-outline',
        'mdi-phone-in-talk',
        'mdi-phone-in-talk-outline',
        'mdi-phone-incoming',
        'mdi-phone-incoming-outline',
        'mdi-phone-lock',
        'mdi-phone-lock-outline',
        'mdi-phone-log',
        'mdi-phone-log-outline',
        'mdi-phone-message',
        'mdi-phone-message-outline',
        'mdi-phone-minus',
        'mdi-phone-minus-outline',
        'mdi-phone-missed',
        'mdi-phone-missed-outline',
        'mdi-phone-off',
        'mdi-phone-off-outline',
        'mdi-phone-outgoing',
        'mdi-phone-outgoing-outline',
        'mdi-phone-outline',
        'mdi-phone-paused',
        'mdi-phone-paused-outline',
        'mdi-phone-plus',
        'mdi-phone-plus-outline',
        'mdi-phone-return',
        'mdi-phone-return-outline',
        'mdi-phone-ring',
        'mdi-phone-ring-outline',
        'mdi-phone-rotate-landscape',
        'mdi-phone-rotate-portrait',
        'mdi-phone-settings',
        'mdi-phone settings-outline',
        'mdi-phone-voip',
        'mdi-pi',
        'mdi-pi-box',
        'mdi-pi-hole',
        'mdi-piano',
        'mdi-pickaxe',
        'mdi-picture-in-picture-bottom-right',
        'mdi-picture-in-picture-bottom-right-outline',
        'mdi-picture-in-picture-top-right',
        'mdi-picture-in-picture-top-right-outline',
        'mdi-pier',
        'mdi-pier-crane',
        'mdi-pig',
        'mdi-pig-variant',
        'mdi-piggy-bank',
        'mdi-pill',
        'mdi-pillar',
        'mdi-pin',
        'mdi-pin-off',
        'mdi-pin-off-outline',
        'mdi-pin-outline',
        'mdi-pine-tree',
        'mdi-pine-tree-box',
        'mdi-pine-tree-fire',
        'mdi-pinterest',
        'mdi-pinwheel',
        'mdi-pinwheel-outline',
        'mdi-pipe',
        'mdi-pipe-disconnected',
        'mdi-pipe-leak',
        'mdi-pipe-wrench',
        'mdi-pirate',
        'mdi-pistol',
        'mdi-piston',
        'mdi-pizza',
        'mdi-play',
        'mdi-play-box',
        'mdi-play-box-multiple',
        'mdi-play-box-multiple-outline',
        'mdi-play-box-outline',
        'mdi-play-circle',
        'mdi-play-circle-outline',
        'mdi-play-network',
        'mdi-play-network-outline',
        'mdi-play-outline',
        'mdi-play-pause',
        'mdi-play-protected-content',
        'mdi-play-speed',
        'mdi-playlist-check',
        'mdi-playlist-edit',
        'mdi-playlist-minus',
        'mdi-playlist-music',
        'mdi-playlist-music-outline',
        'mdi-playlist-play',
        'mdi-playlist-plus',
        'mdi-playlist-remove',
        'mdi-playlist-star',
        'mdi-plex',
        'mdi-plus',
        'mdi-plus-box',
        'mdi-plus-box-multiple',
        'mdi-plus-box-multiple-outline',
        'mdi-plus-box-outline',
        'mdi-plus-circle',
        'mdi-plus-circle-multiple',
        'mdi-plus-circle-multiple-outline',
        'mdi-plus-circle-outline',
        'mdi-plus-minus',
        'mdi-plus-minus-box',
        'mdi-plus-minus-variant',
        'mdi-plus-network',
        'mdi-plus-network-outline',
        'mdi-plus-one',
        'mdi-plus-outline',
        'mdi-plus-thick',
        'mdi-podcast',
        'mdi-podium',
        'mdi-podium-bronze',
        'mdi-podium-gold',
        'mdi-podium-silver',
        'mdi-point-of-sale',
        'mdi-pokeball',
        'mdi-pokemon-go',
        'mdi-poker-chip',
        'mdi-polaroid',
        'mdi-police-badge',
        'mdi-police-badge-outline',
        'mdi-poll',
        'mdi-poll-box',
        'mdi-poll-box-outline',
        'mdi-polo',
        'mdi-polymer',
        'mdi-pool',
        'mdi-popcorn',
        'mdi-post',
        'mdi-post-outline',
        'mdi-postage-stamp',
        'mdi-pot',
        'mdi-pot-mix',
        'mdi-pot-mix-outline',
        'mdi-pot-outline',
        'mdi-pot-steam',
        'mdi-pot-steam-outline',
        'mdi-pound',
        'mdi-pound-box',
        'mdi-pound-box-outline',
        'mdi-power',
        'mdi-power-cycle',
        'mdi-power-off',
        'mdi-power-on',
        'mdi-power-plug',
        'mdi-power-plug-off',
        'mdi-power-plug-off-outline',
        'mdi-power-plug-outline',
        'mdi-power-settings',
        'mdi-power-sleep',
        'mdi-power-socke',
        'mdi-power-socket-au',
        'mdi-power-socket-de',
        'mdi-power-socket-eu',
        'mdi-power-socket-fr',
        'mdi-power-socket-jp',
        'mdi-power-socket-uk',
        'mdi-power-socket-us',
        'mdi-power-standby',
        'mdi-powershell',
        'mdi-prescription',
        'mdi-presentation',
        'mdi-presentation-play',
        'mdi-printer',
        'mdi-printer-3d',
        'mdi-printer-3d-nozzle',
        'mdi-printer-3d-nozzle-alert',
        'mdi-printer-3d-nozzle-alert-outline',
        'mdi-printer-3d-nozzle-outline',
        'mdi-printer-alert',
        'mdi-printer-check',
        'mdi-printer-eye',
        'mdi-printer-off',
        'mdi-printer-pos',
        'mdi-printer-search',
        'mdi-printer-settings',
        'mdi-printer-wireless',
        'mdi-priority-high',
        'mdi-priority-low',
        'mdi-professional-hexagon',
        'mdi-progress-alert',
        'mdi-progress-check',
        'mdi-progress-clock',
        'mdi-progress-close',
        'mdi-progress-download',
        'mdi-progress-upload',
        'mdi-progress-wrench',
        'mdi-projector',
        'mdi-projector-screen',
        'mdi-propane-tank',
        'mdi-propane-tank-outline',
        'mdi-protocol',
        'mdi-publish',
        'mdi-puls',
        'mdi-pump',
        'mdi-pumpkin',
        'mdi-purse',
        'mdi-purse-outline',
        'mdi-puzzle',
        'mdi-puzzle-check',
        'mdi-puzzle-check-outline',
        'mdi-puzzle-edit',
        'mdi-puzzle-edit-outline',
        'mdi-puzzle-heart',
        'mdi-puzzle-heart-outline',
        'mdi-puzzle-minus',
        'mdi-puzzle-minus-outline',
        'mdi-puzzle-outline',
        'mdi-puzzle-plus',
        'mdi-puzzle-plus-outline',
        'mdi-puzzle-remove',
        'mdi-puzzle-remove-outline',
        'mdi-puzzle-star',
        'mdi-puzzle-star-outline',
        'mdi-qi',
        'mdi-qqchat',
        'mdi-qrcode',
        'mdi-qrcode-edit',
        'mdi-qrcode-minus',
        'mdi-qrcode-plus',
        'mdi-qrcode-remove',
        'mdi-qrcode-scan',
        'mdi-quadcopter',
        'mdi-quality-high',
        'mdi-quality-low',
        'mdi-quality-medium',
        'mdi-quora',
        'mdi-rabbit',
        'mdi-racing-helmet',
        'mdi-racquetball',
        'mdi-radar',
        'mdi-radiator',
        'mdi-radiator-disabled',
        'mdi-radiator-off',
        'mdi-radio',
        'mdi-radio-am',
        'mdi-radio-fm',
        'mdi-radio-handheld',
        'mdi-radio-off',
        'mdi-radio-tower',
        'mdi-radioactive',
        'mdi-radioactive-off',
        'mdi-radiobox-blank',
        'mdi-radiobox-marked',
        'mdi-radiology-box',
        'mdi-radiology-box-outline',
        'mdi-radius',
        'mdi-radius-outline',
        'mdi-railroad-light',
        'mdi-raspberry-pi',
        'mdi-ray-end',
        'mdi-ray-end-arrow',
        'mdi-ray-start',
        'mdi-ray-start-arrow',
        'mdi-ray-start-end',
        'mdi-ray-vertex',
        'mdi-react',
        'mdi-read',
        'mdi-receipt',
        'mdi-record',
        'mdi-record-circle',
        'mdi-record-circle-outline',
        'mdi-record-player',
        'mdi-record-rec',
        'mdi-rectangle',
        'mdi-rectangle-outline',
        'mdi-recycle',
        'mdi-recycle-variant',
        'mdi-reddit',
        'mdi-redhat',
        'mdi-redo',
        'mdi-redo-variant',
        'mdi-reflect-horizontal',
        'mdi-reflect-vertical',
        'mdi-refresh',
        'mdi-refresh-circle',
        'mdi-regex',
        'mdi-registered-trademark',
        'mdi-relation-many-to-many',
        'mdi-relation-many-to-one',
        'mdi-relation-many-to-one-or-many',
        'mdi-relation-many-t-only-one',
        'mdi-relation-many-to-zero-or-many',
        'mdi-relation-many-to-zero-or-one',
        'mdi-relation-one-or-many-to-many',
        'mdi-relation-one-or-many-to-one',
        'mdi-relation-one-or-many-to-one-or-many',
        'mdi-relation-one-or-many-to-only-one',
        'mdi-relation-one-or-many-to-zero-or-many',
        'mdi-relation-one-or-many-to-zero-or-one',
        'mdi-relation-one-to-many',
        'mdi-relation-one-to-one',
        'mdi-relation-one-to-one-or-many',
        'mdi-relation-one-to-only-one',
        'mdi-relation-one-to-zero-or-many',
        'mdi-relation-one-to-zero-or-one',
        'mdi-relation-only-one-to-many',
        'mdi-relation-only-one-to-one',
        'mdi-relation-only-one-to-one-or-many',
        'mdi-relation-only-one-to-only-one',
        'mdi-relation-only-one to-zero-or-many',
        'mdi-relation-only-one-to-zero-or-one',
        'mdi-relation-zero-or-many-to-many',
        'mdi-relation-zero-or-many-to-one',
        'mdi-relation-zero-or-many-to-one-or-many',
        'mdi-relation-zero-or-many-to-only-one',
        'mdi-relation-zero-or-many-to-zero-or-many',
        'mdi-relation-zero-or-many-to-zero-or-one',
        'mdi-relation-zero-or-one-to-many',
        'mdi-relation-zero-or-one-to-one',
        'mdi-relation-zero-or-one-to-one-or-many',
        'mdi-relation-zero-or-one-to-only-one',
        'mdi-relation-zero-or-one-to-zero-or-many',
        'mdi-relation-zero-or-one-to-zero-or-one',
        'mdi-relative-scale',
        'mdi-reload',
        'mdi-reload-alert',
        'mdi-reminder',
        'mdi-remote',
        'mdi-remote-desktop',
        'mdi-remote-off',
        'mdi-remote-tv',
        'mdi-remote-tv-off',
        'mdi-rename-box',
        'mdi-reorder-horizontal',
        'mdi-reorder-vertical',
        'mdi-repeat',
        'mdi-repeat-off',
        'mdi-repeat-once',
        'mdi-replay',
        'mdi-reply',
        'mdi-reply-all',
        'mdi-reply-all-outline',
        'mdi-reply-circle',
        'mdi-reply-outline',
        'mdi-reproduction',
        'mdi-resistor',
        'mdi-resistor-nodes',
        'mdi-resize',
        'mdi-resize-bottom-right',
        'mdi-responsive',
        'mdi-restart',
        'mdi-restart-alert',
        'mdi-restart-off',
        'mdi-restore',
        'mdi-restore-alert',
        'mdi-rewind',
        'mdi-rewind-10',
        'mdi-rewind-30',
        'mdi-rewind-5',
        'mdi-rewin-outline',
        'mdi-rhombus',
        'mdi-rhombus-medium',
        'mdi-rhombus-medium-outline',
        'mdi-rhombus-outline',
        'mdi-rhombus-split',
        'mdi-rhombus-split-outline',
        'mdi-ribbon',
        'mdi-rice',
        'mdi-ring',
        'mdi-rivet',
        'mdi-road',
        'mdi-road-variant',
        'mdi-robber',
        'mdi-robot',
        'mdi-robot-industrial',
        'mdi-robot-mower',
        'mdi-robot-mower-outline',
        'mdi-robot-vacuum',
        'mdi-robot-vacuum-variant',
        'mdi-rocket',
        'mdi-rocket-launch',
        'mdi-rocket-launch-outline',
        'mdi-rocket-outline',
        'mdi-rodent',
        'mdi-roller-skate',
        'mdi-roller-skate-off',
        'mdi-rollerblade',
        'mdi-rollerblade-off',
        'mdi-rollupjs',
        'mdi-roman-numeral-1',
        'mdi-roman-numeral-10',
        'mdi-roman-numeral-2',
        'mdi-roman-numeral-3',
        'mdi-roman-numeral-4',
        'mdi-roman-numeral-5',
        'mdi-roman-numeral-6',
        'mdi-roman-numeral-7',
        'mdi-roman-numeral-8',
        'mdi-roman-numeral-9',
        'mdi-room-service',
        'mdi-room-service-outline',
        'mdi-rotate-3d',
        'mdi-rotate-3d-variant',
        'mdi-rotate-left',
        'mdi-rotate-left-variant',
        'mdi-rotate-orbit',
        'mdi-rotate-right',
        'mdi-rotate-right-variant',
        'mdi-rounded-corner',
        'mdi-router',
        'mdi-router-network',
        'mdi-router-wireless',
        'mdi-router-wireless-settings',
        'mdi-routes',
        'mdi-routes-clock',
        'mdi-rowing',
        'mdi-rss',
        'mdi-rss-box',
        'mdi-rss-off',
        'mdi-rug',
        'mdi-rugby',
        'mdi-ruler',
        'mdi-ruler-square',
        'mdi-ruler-square-compass',
        'mdi-run',
        'mdi-run-fast',
        'mdi-rv-truck',
        'mdi-sack',
        'mdi-sack-percent',
        'mdi-safe',
        'mdi-safe-square',
        'mdi-safe-square-outline',
        'mdi-safety-goggles',
        'mdi-sail-boat',
        'mdi-sale',
        'mdi-salesforce',
        'mdi-sass',
        'mdi-satellite',
        'mdi-satellite-uplink',
        'mdi-satellite-variant',
        'mdi-sausage',
        'mdi-saw-blade',
        'mdi-sawtooth-wave',
        'mdi-saxophone',
        'mdi-scale',
        'mdi-scale-balance',
        'mdi-scale-bathroom',
        'mdi-scale-off',
        'mdi-scan-helper',
        'mdi-scanner',
        'mdi-scanner-off',
        'mdi-scatter-plot',
        'mdi-scatter-plot-outline',
        'mdi-school',
        'mdi-school-outline',
        'mdi-scissors-cutting',
        'mdi-scooter',
        'mdi-scoreboard',
        'mdi-scoreboard-outline',
        'mdi-screen-rotation',
        'mdi-screen-rotation-lock',
        'mdi-screw-flat-top',
        'mdi-screw-lag',
        'mdi-screw-machine-flat-top',
        'mdi-screw-machine-round-top',
        'mdi-screw-round-top',
        'mdi-screwdriver',
        'mdi-script',
        'mdi-script-outline',
        'mdi-script-text',
        'mdi-script-text-outline',
        'mdi-sd',
        'mdi-seal',
        'mdi-seal-variant',
        'mdi-search-web',
        'mdi-seat',
        'mdi-seat-flat',
        'mdi-seat-flat-angled',
        'mdi-seat-individual-suite',
        'mdi-seat-legroom-extra',
        'mdi-seat-legroom-normal',
        'mdi-seat-legroom-reduced',
        'mdi-seat-outline',
        'mdi-seat-passenger',
        'mdi-seat-recline-extra',
        'mdi-seat-recline-normal',
        'mdi-seatbelt',
        'mdi-security',
        'mdi-security-network',
        'mdi-seed',
        'mdi-seed-off',
        'mdi-seed-off-outline',
        'mdi-seed-outline',
        'mdi-segment',
        'mdi-select',
        'mdi-select-all',
        'mdi-select-color',
        'mdi-select-compare',
        'mdi-select-drag',
        'mdi-select-group',
        'mdi-select-inverse',
        'mdi-select-marker',
        'mdi-select-multiple',
        'mdi-select-multiple-marker',
        'mdi-select-off',
        'mdi-select-place',
        'mdi-select-search',
        'mdi-selection',
        'mdi-selection-drag',
        'mdi-selection-ellipse',
        'mdi-selection-ellipse-arrow-inside',
        'mdi-selection-marker',
        'mdi-selection-multiple',
        'mdi-selection-multiple-marke',
        'mdi-selection-off',
        'mdi-selection-search',
        'mdi-semantic-web',
        'mdi-send',
        'mdi-send-check',
        'mdi-send-check-outline',
        'mdi-send-circle',
        'mdi-send-circle-outline',
        'mdi-send-clock',
        'mdi-send-clock-outline',
        'mdi-send-lock',
        'mdi-send-lock-outline',
        'mdi-send-outline',
        'mdi-serial-port',
        'mdi-server',
        'mdi-server-minus',
        'mdi-server-network',
        'mdi-server-network-off',
        'mdi-server-off',
        'mdi-server-plus',
        'mdi-server-remove',
        'mdi-server-security',
        'mdi-set-all',
        'mdi-set-center',
        'mdi-set-center-right',
        'mdi-set-left',
        'mdi-set-left-center',
        'mdi-set-left-right',
        'mdi-set-merge',
        'mdi-set-none',
        'mdi-set-right',
        'mdi-set-split',
        'mdi-set-square',
        'mdi-set-top-box',
        'mdi-settings-helper',
        'mdi-shaker',
        'mdi-shaker-outline',
        'mdi-shape',
        'mdi-shape-circle-plus',
        'mdi-shape-outline',
        'mdi-shape-oval-plus',
        'mdi-shape-plus',
        'mdi-shape-polygon-plus',
        'mdi-shape-rectangle-plus',
        'mdi-shape-square-plus',
        'mdi-share',
        'mdi-share-all',
        'mdi-share-all-outline',
        'mdi-share-circle',
        'mdi-share-off',
        'mdi-share-off-outline',
        'mdi-share-outline',
        'mdi-share-variant',
        'mdi-sheep',
        'mdi-shield',
        'mdi-shield-account',
        'mdi-shield-account-outline',
        'mdi-shield-airplane',
        'mdi-shield-airplane-outline',
        'mdi-shield-alert',
        'mdi-shield-alert-outline',
        'mdi-shield-bug',
        'mdi-shield-bug-outline',
        'mdi-shield-car',
        'mdi-shield-check',
        'mdi-shield-check-outline',
        'mdi-shield-cross',
        'mdi-shield-cross-outline',
        'mdi-shield-edit',
        'mdi-shield-edit-outline',
        'mdi-shield-half',
        'mdi-shield-half-full',
        'mdi-shield-home',
        'mdi-shield-hom-outline',
        'mdi-shield-key',
        'mdi-shield-key-outline',
        'mdi-shield-link-variant',
        'mdi-shield-link-variant-outline',
        'mdi-shield-lock',
        'mdi-shield-lock-outline',
        'mdi-shield-off',
        'mdi-shield-off-outline',
        'mdi-shield-outline',
        'mdi-shield-plus',
        'mdi-shield-plus-outline',
        'mdi-shield-refresh',
        'mdi-shield-refresh-outline',
        'mdi-shield-remove',
        'mdi-shield-remove-outline',
        'mdi-shield-search',
        'mdi-shield-star',
        'mdi-shield-star-outline',
        'mdi-shield-sun',
        'mdi-shield-sun-outline',
        'mdi-shield-sync',
        'mdi-shield-sync-outline',
        'mdi-ship-wheel',
        'mdi-shoe-formal',
        'mdi-shoe-heel',
        'mdi-shoe-print',
        'mdi-shopping',
        'mdi-shopping-music',
        'mdi-shopping-outline',
        'mdi-shopping-search',
        'mdi-shovel',
        'mdi-shovel-off',
        'mdi-shower',
        'mdi-shower-head',
        'mdi-shredder',
        'mdi-shuffle',
        'mdi-shuffle-disabled',
        'mdi-shuffle-variant',
        'mdi-shuriken',
        'mdi-sigma',
        'mdi-sigma-lower',
        'mdi-sign-caution',
        'mdi-sign-direction',
        'mdi-sign-direction-minus',
        'mdi-sign-direction-plus',
        'mdi-sign-direction-remove',
        'mdi-sign-real-estate',
        'mdi-sign-text',
        'mdi-signal',
        'mdi-signal-2g',
        'mdi-signal-3g',
        'mdi-signal-4g',
        'mdi-signal-5g',
        'mdi-signal-cellular-1',
        'mdi-signal-cellular-2',
        'mdi-signal-cellular-3',
        'mdi-signal-cellular-outline',
        'mdi-signal-distance-variant',
        'mdi-signal-hspa',
        'mdi-signal-hspa-plus',
        'mdi-signal-off',
        'mdi-signal-variant',
        'mdi-signature',
        'mdi-signature-freehand',
        'mdi-signature-image',
        'mdi-signature-text',
        'mdi-silo',
        'mdi-silverware',
        'mdi-silverware-clean',
        'mdi-silverware-fork',
        'mdi-silverware-fork-knife',
        'mdi-silverware-spoon',
        'mdi-silverware-variant',
        'mdi-sim',
        'mdi-sim-alert',
        'mdi-sim-off',
        'mdi-simple-icons',
        'mdi-sina-weibo',
        'mdi-sine-wave',
        'mdi-sitemap',
        'mdi-size-l',
        'mdi-size-m',
        'mdi-size-s',
        'mdi-size-xl',
        'mdi-size-xs',
        'mdi-size-xxl',
        'mdi-size-xxs',
        'mdi-size-xxxl',
        'mdi-skate',
        'mdi-skateboard',
        'mdi-skew-less',
        'mdi-skew-more',
        'mdi-ski',
        'mdi-ski-cross-country',
        'mdi-ski-water',
        'mdi-skip-backward',
        'mdi-skip-backward-outline',
        'mdi-skip-forward',
        'mdi-skip-forward-outline',
        'mdi-skip-next',
        'mdi-skip-next-circle',
        'mdi-skip-next-circle-outline',
        'mdi-skip-next-outline',
        'mdi-skip-previous',
        'mdi-skip-previous-circle',
        'mdi-skip-previous-circle-outline',
        'mdi-skip-previous-outline',
        'mdi-skull',
        'mdi-skull-crossbones',
        'mdi-skull-crossbones-outline',
        'mdi-skull-outline',
        'mdi-skull-scan',
        'mdi-skull-scan-outline',
        'mdi-skype',
        'mdi-skype-business',
        'mdi-slack',
        'mdi-slash-forward',
        'mdi-slash-forward-box',
        'mdi-sleep',
        'mdi-sleep-off',
        'mdi-slope-downhill',
        'mdi-slope-uphill',
        'mdi-slot-machine',
        'mdi-slot-machine-outline',
        'mdi-smart-card',
        'mdi-smart-card-outline',
        'mdi-smart-card-reader',
        'mdi-smart-card-reader-outline',
        'mdi-smog',
        'mdi-smoke-detector',
        'mdi-smoking',
        'mdi-smoking-off',
        'mdi-smoking-pipe',
        'mdi-smoking-pipe-off',
        'mdi-snapchat',
        'mdi-snowboard',
        'mdi-snowflake',
        'mdi-snowflake-alert',
        'mdi-snowflake-melt',
        'mdi-snowflake-variant',
        'mdi-snowman',
        'mdi-soccer',
        'mdi-soccer-field',
        'mdi-sofa',
        'mdi-solar-panel',
        'mdi-solar-panel-large',
        'mdi-solar-power',
        'mdi-soldering-iron',
        'mdi-solid',
        'mdi-sony-playstation',
        'mdi-sort',
        'mdi-sort-alphabetical-ascending',
        'mdi-sort-alphabetical-ascending-variant',
        'mdi-sort-alphabetical-descending',
        'mdi-sort-alphabetical-descending-variant',
        'mdi-sort-alphabetical-variant',
        'mdi-sort-ascending',
        'mdi-sort-bool-ascending',
        'mdi-sort-bool-ascending-variant',
        'mdi-sort-bool-descending',
        'mdi-sort-bool-descending-variant',
        'mdi-sort-descending',
        'mdi-sort-numeric-ascending',
        'mdi-sort-numeric-ascending-variant',
        'mdi-sort-numeric-descending',
        'mdi-sort-numeric-descending-variant',
        'mdi-sort-numeric-variant',
        'mdi-sort-reverse-variant',
        'mdi-sort-variant',
        'mdi-sort-variant-lock',
        'mdi-sort-variant-lock-open',
        'mdi-sort-variant-remove',
        'mdi-soundcloud',
        'mdi-source-branch',
        'mdi-source-branch-check',
        'mdi-source-branch-minus',
        'mdi-source-branch-plus',
        'mdi-source-branch-refresh',
        'mdi-source-branch-remove',
        'mdi-source-branch-sync',
        'mdi-source-commit',
        'mdi-source-commit-end',
        'mdi-source-commit-end-local',
        'mdi-source-commit-local',
        'mdi-source-commit-next-local',
        'mdi-source-commit-start',
        'mdi-source-commit-start-next-local',
        'mdi-source-fork',
        'mdi-source-merge',
        'mdi-source-pull',
        'mdi-source-repository',
        'mdi-source-repository-multiple',
        'mdi-soy-sauce',
        'mdi-soy-sauce-off',
        'mdi-spa',
        'mdi-spa-outline',
        'mdi-space-invaders',
        'mdi-space-station',
        'mdi-spade',
        'mdi-speaker',
        'mdi-speaker-bluetooth',
        'mdi-speaker-multiple',
        'mdi-speaker-off',
        'mdi-speaker-wireless',
        'mdi-speedometer',
        'mdi-speedometer-medium',
        'mdi-speedometer-slow',
        'mdi-spellcheck',
        'mdi-spider',
        'mdi-spider-thread',
        'mdi-spider-web',
        'mdi-spoon-sugar',
        'mdi-spotify',
        'mdi-spotlight',
        'mdi-spotlight-beam',
        'mdi-spray',
        'mdi-spray-bottle',
        'mdi-sprinkler',
        'mdi-sprinkler-variant',
        'mdi-sprout',
        'mdi-sprout-outline',
        'mdi-square',
        'mdi-square-edit-outline',
        'mdi-square-medium',
        'mdi-square-medium-outline',
        'mdi-square-off',
        'mdi-square-off-outline',
        'mdi-square-outline',
        'mdi-square-root',
        'mdi-square-root-box',
        'mdi-square-small',
        'mdi-square-wave',
        'mdi-squeegee',
        'mdi-ssh',
        'mdi-stack-exchange',
        'mdi-stack-overflow',
        'mdi-stackpath',
        'mdi-stadium',
        'mdi-stadium-variant',
        'mdi-stair',
        'mdi-stairs-box',
        'mdi-stairs-down',
        'mdi-stairs-up',
        'mdi-stamper',
        'mdi-standard-definition',
        'mdi-star',
        'mdi-star-box',
        'mdi-star-box-multiple',
        'mdi-star-box-multiple-outline',
        'mdi-star-box-outlin',
        'mdi-star-circle',
        'mdi-star-circle-outline',
        'mdi-star-face',
        'mdi-star-four-points',
        'mdi-star-four-points-outline',
        'mdi-star-half',
        'mdi-star-half-full',
        'mdi-star-off',
        'mdi-star-outline',
        'mdi-star-three-points',
        'mdi-star-three-points-outline',
        'mdi-state-machine',
        'mdi-steam',
        'mdi-steering',
        'mdi-steering-off',
        'mdi-step-backward',
        'mdi-step-backward-2',
        'mdi-step-forward',
        'mdi-step-forward-2',
        'mdi-stethoscope',
        'mdi-sticker',
        'mdi-sticker-alert',
        'mdi-sticker-alert-outline',
        'mdi-sticker-check',
        'mdi-sticker-check-outline',
        'mdi-sticker-circle-outline',
        'mdi-sticker-emoji',
        'mdi-sticker-minus',
        'mdi-sticker-minus-outline',
        'mdi-sticker-outline',
        'mdi-sticker-plus',
        'mdi-sticker-plus-outline',
        'mdi-sticker-remove',
        'mdi-sticker-remove-outline',
        'mdi-stocking',
        'mdi-stomach',
        'mdi-stop',
        'mdi-stop-circle',
        'mdi-stop-circle-outline',
        'mdi-store',
        'mdi-store-24-hour',
        'mdi-store-outline',
        'mdi-storefront',
        'mdi-storefront-outline',
        'mdi-stove',
        'mdi-strategy',
        'mdi-stretch-to-page',
        'mdi-stretch-to-page-outline',
        'mdi-string-lights',
        'mdi-string-lights-off',
        'mdi-subdirectory-arrow-left',
        'mdi-subdirectory-arrow-right',
        'mdi-subtitles',
        'mdi-subtitles-outline',
        'mdi-subway',
        'mdi-subway-alert-variant',
        'mdi-subway-variant',
        'mdi-summit',
        'mdi-sunglasses',
        'mdi-surround-sound',
        'mdi-surround-sound-2-0',
        'mdi-surround-sound-3-1',
        'mdi-surround-sound-5-1',
        'mdi-surround-sound-7-1',
        'mdi-svg',
        'mdi-swap-horizontal',
        'mdi-swap-horizontal-bold',
        'mdi-swap-horizontal-circle',
        'mdi-swap-horizontal-circle-outline',
        'mdi-swap-horizontal-variant',
        'mdi-swap-vertical',
        'mdi-swap-vertical-bold',
        'mdi-swap-vertical-circle',
        'mdi-swap-vertical-circle-outline',
        'mdi-swap-vertical-variant',
        'mdi-swim',
        'mdi-switch',
        'mdi-sword',
        'mdi-sword-cross',
        'mdi-syllabary-hangul',
        'mdi-syllabary-hiragana',
        'mdi-syllabary-katakana',
        'mdi-syllabary-katakana-halfwidth',
        'mdi-symfony',
        'mdi-sync',
        'mdi-sync-alert',
        'mdi-sync-circle',
        'mdi-sync-off',
        'mdi-tab',
        'mdi-tab-minus',
        'mdi-tab-plus',
        'mdi-tab-remove',
        'mdi-tab-unselected',
        'mdi-table',
        'mdi-table-account',
        'mdi-table-alert',
        'mdi-table-arrow-down',
        'mdi-table-arrow-left',
        'mdi-table-arrow-right',
        'mdi-table-arrow-up',
        'mdi-table-border',
        'mdi-table-cancel',
        'mdi-table-chair',
        'mdi-table-check',
        'mdi-table-clock',
        'mdi-table-cog',
        'mdi-table-column',
        'mdi-table-column-plus-after',
        'mdi-table-column-plus-before',
        'mdi-table-column-remove',
        'mdi-table-column-width',
        'mdi-table-edit',
        'mdi-table-eye',
        'mdi-table-eye-off',
        'mdi-table-furniture',
        'mdi-table-headers-eye',
        'mdi-table-headers-eye-off',
        'mdi-table-heart',
        'mdi-table-key',
        'mdi-table-large',
        'mdi-table-large-plus',
        'mdi-table-large-remove',
        'mdi-table-lock',
        'mdi-table-merge-cells',
        'mdi-table-minus',
        'mdi-table-multiple',
        'mdi-table-network',
        'mdi-table-of-contents',
        'mdi-table-off',
        'mdi-table-plus',
        'mdi-table-refresh',
        'mdi-table-remove',
        'mdi-table-row',
        'mdi-table-row-height',
        'mdi-table-row-plus-after',
        'mdi-table-row-plus-before',
        'mdi-table-row-remove',
        'mdi-table-search',
        'mdi-table-settings',
        'mdi-table-split-cell',
        'mdi-table-star',
        'mdi-table-sync',
        'mdi-table-tennis',
        'mdi-tablet',
        'mdi-tablet-android',
        'mdi-tablet-cellphone',
        'mdi-tablet-dashboard',
        'mdi-tablet-ipad',
        'mdi-taco',
        'mdi-tag',
        'mdi-tag-faces',
        'mdi-tag-heart',
        'mdi-tag-heart-outline',
        'mdi-tag-minus',
        'mdi-tag-minus-outline',
        'mdi-tag-multiple',
        'mdi-tag-multiple-outline',
        'mdi-tag-off',
        'mdi-tag-off-outline',
        'mdi-tag-outline',
        'mdi-tag-plus',
        'mdi-tag-plus-outline',
        'mdi-tag-remove',
        'mdi-tag-remove-outline',
        'mdi-tag-text',
        'mdi-tag-text-outline',
        'mdi-tailwind',
        'mdi-tank',
        'mdi-tanker-truck',
        'mdi-tape-measure',
        'mdi-target',
        'mdi-target-account',
        'mdi-target-variant',
        'mdi-taxi',
        'mdi-tea',
        'mdi-tea-outline',
        'mdi-teach',
        'mdi-teamviewer',
        'mdi-telegram',
        'mdi-telescope',
        'mdi-television',
        'mdi-television-ambient-light',
        'mdi-television-bo',
        'mdi-television-classic',
        'mdi-television-classic-off',
        'mdi-television-clean',
        'mdi-television-guide',
        'mdi-television-off',
        'mdi-television-pause',
        'mdi-television-play',
        'mdi-television-stop',
        'mdi-temperature-celsius',
        'mdi-temperature-fahrenheit',
        'mdi-temperature-kelvin',
        'mdi-tennis',
        'mdi-tennis-ball',
        'mdi-tent',
        'mdi-terraform',
        'mdi-terrain',
        'mdi-test-tube',
        'mdi-test-tube-empty',
        'mdi-test-tube-off',
        'mdi-text',
        'mdi-text-box',
        'mdi-text-box-check',
        'mdi-text-box-check-outline',
        'mdi-text-box-minus',
        'mdi-text-box-minus-outline',
        'mdi-text-box-multiple',
        'mdi-text-box-multiple-outline',
        'mdi-text-box-outline',
        'mdi-text-box-plus',
        'mdi-text-box-plus-outline',
        'mdi-text-box-remove',
        'mdi-text-box-remov-outline',
        'mdi-text-box-search',
        'mdi-text-box-search-outline',
        'mdi-text-recognition',
        'mdi-text-search',
        'mdi-text-shadow',
        'mdi-text-short',
        'mdi-text-subject',
        'mdi-text-to-speech',
        'mdi-text-to-speech-off',
        'mdi-texture',
        'mdi-texture-box',
        'mdi-theater',
        'mdi-theme-light-dark',
        'mdi-thermometer',
        'mdi-thermometer-alert',
        'mdi-thermometer-chevron-down',
        'mdi-thermometer-chevron-up',
        'mdi-thermometer high',
        'mdi-thermometer-lines',
        'mdi-thermometer-low',
        'mdi-thermometer-minus',
        'mdi-thermometer-plus',
        'mdi-thermostat',
        'mdi-thermostat-box',
        'mdi-thought-bubble',
        'mdi-thought-bubble-outline',
        'mdi-thumb-down',
        'mdi-thumb-down-outline',
        'mdi-thumb-up',
        'mdi-thumb-up-outline',
        'mdi-thumbs-up-down',
        'mdi-ticket',
        'mdi-ticket-account',
        'mdi-ticket-confirmation',
        'mdi-ticket-confirmation-outline',
        'mdi-ticket-outline',
        'mdi-ticket-percent',
        'mdi-ticket-percent-outline',
        'mdi-tie',
        'mdi-tilde',
        'mdi-timelapse',
        'mdi-timeline',
        'mdi-timeline-alert',
        'mdi-timeline-alert-outline',
        'mdi-timeline-clock',
        'mdi-timeline-clock-outlin',
        'mdi-timeline-help',
        'mdi-timeline help-outline',
        'mdi-timeline-outlin',
        'mdi-timeline-plus  mdi-timeline-plus-outline',
        'mdi-timeline-text',
        'mdi-timeline-text-outline',
        'mdi-timer',
        'mdi-timer-10',
        'mdi-timer-3',
        'mdi-timer-off',
        'mdi-timer-off-outline',
        'mdi-timer-outline',
        'mdi-timer-sand',
        'mdi-timer-sand-empty',
        'mdi-timer-sand-full',
        'mdi-timetable',
        'mdi-toaster',
        'mdi-toaster-off',
        'mdi-toaster-oven',
        'mdi-toggle-switch',
        'mdi-toggle-switch-off',
        'mdi-toggle-switch-off-outline',
        'mdi-toggle-switch-outline',
        'mdi-toilet',
        'mdi-toolbox',
        'mdi-toolbox-outline',
        'mdi-tools',
        'mdi-tooltip',
        'mdi-tooltip-account',
        'mdi-tooltip-edit',
        'mdi-tooltip-edit-outline',
        'mdi-tooltip-image',
        'mdi-tooltip-image-outline',
        'mdi-tooltip-outline',
        'mdi-tooltip-plus',
        'mdi-tooltip-plus-outline',
        'mdi-tooltip-text',
        'mdi-tooltip-text-outline',
        'mdi-tooth',
        'mdi-tooth-outline',
        'mdi-toothbrush',
        'mdi-toothbrush-electric',
        'mdi-toothbrush-paste',
        'mdi-tortoise',
        'mdi-toslink',
        'mdi-tournament',
        'mdi-tow-truck',
        'mdi-tower-beach',
        'mdi-tower-fire',
        'mdi-toy-brick',
        'mdi-toy-brick-marker',
        'mdi-toy-brick-marker-outline',
        'mdi-toy-brick-minus',
        'mdi-toy-brick-minus-outline',
        'mdi-toy-brick-outline',
        'mdi-toy-brick-plus',
        'mdi-toy-brick-plus-outline',
        'mdi-toy-brick-remove',
        'mdi-toy-brick-remove-outline',
        'mdi-toy-brick-search',
        'mdi-toy-brick-search-outline',
        'mdi-track-light',
        'mdi-trackpad',
        'mdi-trackpad-lock',
        'mdi-tractor',
        'mdi-tractor-variant',
        'mdi-trademark',
        'mdi-traffic-cone',
        'mdi-traffic light',
        'mdi-train',
        'mdi-train-car',
        'mdi-train-variant',
        'mdi-tram',
        'mdi-tram-side',
        'mdi-transcribe',
        'mdi-transcribe-close',
        'mdi-transfer',
        'mdi-transfer-down',
        'mdi-transfer-left',
        'mdi-transfer-right',
        'mdi-transfer-up',
        'mdi-transit-connection',
        'mdi-transit-connection-variant',
        'mdi-transit-detour',
        'mdi-transit-transfer',
        'mdi-transition',
        'mdi-transition-masked',
        'mdi-translate',
        'mdi-translate-off',
        'mdi-transmission-tower',
        'mdi-trash-can',
        'mdi-trash-can-outline',
        'mdi-tray',
        'mdi-tray-alert',
        'mdi-tray-full',
        'mdi-tray-minus',
        'mdi-tray-plus',
        'mdi-tray-remove',
        'mdi-treasure-chest',
        'mdi-tree',
        'mdi-tree-outline',
        'mdi-trello',
        'mdi-trending-down',
        'mdi-trending-neutral',
        'mdi-trending-up',
        'mdi-triangle',
        'mdi-triangle-outline',
        'mdi-triangle-wave',
        'mdi-triforce',
        'mdi-trophy',
        'mdi-trophy-award',
        'mdi-trophy-broken',
        'mdi-trophy-outline',
        'mdi-trophy-variant',
        'mdi-trophy-variant-outline',
        'mdi-truck',
        'mdi-truck-check',
        'mdi-truck-check-outline',
        'mdi-truck-delivery',
        'mdi-truck-delivery-outline',
        'mdi-truck-fast',
        'mdi-truck-fast-outline',
        'mdi-truck-outline',
        'mdi-truck-trailer',
        'mdi-trumpet',
        'mdi-tshirt-crew',
        'mdi-tshirt-crew-outline',
        'mdi-tshirt-v',
        'mdi-tshirt-v-outline',
        'mdi-tumble-dryer',
        'mdi-tumble-dryer-alert',
        'mdi-tumble-dryer-off',
        'mdi-tune',
        'mdi-tune-vertical',
        'mdi-turnstile',
        'mdi-turnstile-outline',
        'mdi-turtle',
        'mdi-twitch',
        'mdi-twitter',
        'mdi-twitter-retweet',
        'mdi-two-factor-authentication',
        'mdi-typewriter',
        'mdi-ubisoft',
        'mdi-ubuntu',
        'mdi-ufo',
        'mdi-ufo-outline',
        'mdi-ultra-high-definition',
        'mdi-umbraco',
        'mdi-umbrella',
        'mdi-umbrella-closed',
        'mdi-umbrella-closed-outline',
        'mdi-umbrella-closed-variant',
        'mdi-umbrella-outline',
        'mdi-undo',
        'mdi-undo-variant',
        'mdi-unfold-less-horizontal',
        'mdi-unfold-less-vertical',
        'mdi-unfold-more-horizontal',
        'mdi-unfold-more-vertical',
        'mdi-ungroup',
        'mdi-unicode',
        'mdi-unity',
        'mdi-unreal',
        'mdi-untappd',
        'mdi-update',
        'mdi-upload',
        'mdi-upload-lock',
        'mdi-upload-lock-outline',
        'mdi-upload-multiple',
        'mdi-upload-network',
        'mdi-upload-networ-outline',
        'mdi-upload-off',
        'mdi-upload-off-outline',
        'mdi-upload-outline',
        'mdi-usb',
        'mdi-usb-flash-drive',
        'mdi-usb-flash-drive-outline',
        'mdi-usb-port',
        'mdi-valve',
        'mdi-valve-closed',
        'mdi-valve-open',
        'mdi-van-passenger',
        'mdi-van-utility',
        'mdi-vanish',
        'mdi-vanity-light',
        'mdi-variable',
        'mdi-variable-box',
        'mdi-vector-arrange-above',
        'mdi-vector-arrange-below',
        'mdi-vector-bezier',
        'mdi-vector-circle',
        'mdi-vector-circle-variant',
        'mdi-vector-combine',
        'mdi-vector-curve',
        'mdi-vector-difference',
        'mdi-vector-differenc-ab',
        'mdi-vector-difference-ba',
        'mdi-vector-ellipse',
        'mdi-vector-intersection',
        'mdi-vector-line',
        'mdi-vector-link',
        'mdi-vector-point',
        'mdi-vector-polygon',
        'mdi-vector-polyline',
        'mdi-vector-polyline-edit',
        'mdi-vector-polyline-minus',
        'mdi-vector-polyline-plus',
        'mdi-vector-polyline-remove',
        'mdi-vector-radius',
        'mdi-vector-rectangle',
        'mdi-vector-selection',
        'mdi-vector-square',
        'mdi-vector-triangle',
        'mdi-vector-union',
        'mdi-vhs',
        'mdi-vibrate',
        'mdi-vibrate-off',
        'mdi-video',
        'mdi-video-3d',
        'mdi-video-3d-off',
        'mdi-video-3d-variant',
        'mdi-video-4k-box',
        'mdi-video-account',
        'mdi-video-box',
        'mdi-video-box-off',
        'mdi-video-check',
        'mdi-video-check-outline',
        'mdi-video-image',
        'mdi-video-input-antenna',
        'mdi-video-input-component',
        'mdi-video-input-hdmi',
        'mdi-video-input-scart',
        'mdi-video-input-svideo',
        'mdi-video-minus',
        'mdi-video-minus-outline',
        'mdi-video-off',
        'mdi-video-off-outline',
        'mdi-video-outline',
        'mdi-video-plus',
        'mdi-video-plus-outline',
        'mdi-video-stabilization',
        'mdi-video-switch',
        'mdi-video-switch-outline',
        'mdi-video-vintage',
        'mdi-video-wireless',
        'mdi-video-wireless-outline',
        'mdi-view-agenda',
        'mdi-view-agenda-outline',
        'mdi-view-array',
        'mdi-view-array-outline',
        'mdi-view-carousel',
        'mdi-view-carousel-outline',
        'mdi-view-column',
        'mdi-view-column-outline',
        'mdi-view-comfy',
        'mdi-view-comfy-outline',
        'mdi-view-compact',
        'mdi-view-compact-outline',
        'mdi-view-dashboard',
        'mdi-view-dashboard-outline',
        'mdi-view-dashboard-variant',
        'mdi-view-dashboard-variant-outline',
        'mdi-view-day',
        'mdi-view-day-outline',
        'mdi-view-grid',
        'mdi-view-grid-outline',
        'mdi-view-grid-plus',
        'mdi-view-grid-plus-outline',
        'mdi-view-headline',
        'mdi-view-list',
        'mdi-view-list-outline',
        'mdi-view-module',
        'mdi-view-module-outline',
        'mdi-view-parallel',
        'mdi-view-parallel-outline',
        'mdi-view-quilt',
        'mdi-view-quilt-outline',
        'mdi-view-sequential',
        'mdi-view-sequential-outline',
        'mdi-view-split-horizontal',
        'mdi-view-split-vertical',
        'mdi-view-stream',
        'mdi-view-stream-outline',
        'mdi-view-week',
        'mdi-view-week-outline',
        'mdi-vimeo',
        'mdi-violin',
        'mdi-virtual-reality',
        'mdi-virus',
        'mdi-virus-outline',
        'mdi-vk',
        'mdi-vlc',
        'mdi-voice-off',
        'mdi-voicemail',
        'mdi-volleyball',
        'mdi-volume-high',
        'mdi-volume-low',
        'mdi-volume-medium',
        'mdi-volume-minus',
        'mdi-volume-mute',
        'mdi-volume-off',
        'mdi-volume-plus',
        'mdi-volume-source',
        'mdi-volume-variant-off',
        'mdi-volume-vibrate',
        'mdi-vote',
        'mdi-vote-outline',
        'mdi-vpn',
        'mdi-vuejs',
        'mdi-vuetify',
        'mdi-walk',
        'mdi-wall',
        'mdi-wall-sconce',
        'mdi-wall-sconce-flat',
        'mdi-wall-sconce-flat-variant',
        'mdi-wall-sconce-round',
        'mdi-wall-sconce-round-variant',
        'mdi-wallet',
        'mdi-wallet-giftcard',
        'mdi-wallet-membership',
        'mdi-wallet-outline',
        'mdi-wallet-plus',
        'mdi-wallet-plus-outline',
        'mdi-wallet-travel',
        'mdi-wallpaper',
        'mdi-wan',
        'mdi-wardrobe',
        'mdi-wardrobe-outline',
        'mdi-warehouse',
        'mdi-washing-machine',
        'mdi-washing-machine-alert',
        'mdi-washing-machine-off',
        'mdi-watch',
        'mdi-watch-export',
        'mdi-watch-export-variant',
        'mdi-watch-import',
        'mdi-watch-import-variant',
        'mdi-watch-variant',
        'mdi-watch-vibrate',
        'mdi-watch-vibrate-off',
        'mdi-water',
        'mdi-water-boiler',
        'mdi-water-boiler-alert',
        'mdi-water-boiler-off',
        'mdi-water-off',
        'mdi-water-outline',
        'mdi-water-percent',
        'mdi-water-polo',
        'mdi-water-pump',
        'mdi-water-pump-off',
        'mdi-water-well',
        'mdi-water-well-outline',
        'mdi-watering-can',
        'mdi-watering-can-outline',
        'mdi-watermark',
        'mdi-wave',
        'mdi-waveform',
        'mdi-waves',
        'mdi-waze',
        'mdi-weather-cloudy',
        'mdi-weather-cloudy-alert',
        'mdi-weather-cloudy-arrow-right',
        'mdi-weather-fog',
        'mdi-weather-hail',
        'mdi-weather-hazy',
        'mdi-weather-hurricane',
        'mdi-weather-lightning',
        'mdi-weather-lightning-rainy',
        'mdi-weather-night',
        'mdi-weather-night-partly-cloudy',
        'mdi-weather-partly-cloudy',
        'mdi-weather-partly-lightning',
        'mdi-weather-partly-rainy',
        'mdi-weather-partly-snowy',
        'mdi-weather-partly-snowy-rainy',
        'mdi-weather-pouring',
        'mdi-weather-rainy',
        'mdi-weather-snowy',
        'mdi-weather-snowy-heavy',
        'mdi-weather-snowy-rainy',
        'mdi-weather-sunny',
        'mdi-weather-sunny-alert',
        'mdi-weather-sunset',
        'mdi-weather-sunset-down',
        'mdi-weather-sunset-up',
        'mdi-weather-tornado',
        'mdi-weather-windy',
        'mdi-weather-windy-variant',
        'mdi-web',
        'mdi-web-box',
        'mdi-web-clock',
        'mdi-webcam',
        'mdi-webhook',
        'mdi-webpack',
        'mdi-webrtc',
        'mdi-wechat',
        'mdi-weight',
        'mdi-weight-gram',
        'mdi-weight-kilogram',
        'mdi-weight-lifter',
        'mdi-weight-pound',
        'mdi-whatsapp',
        'mdi-wheelchair-accessibility',
        'mdi-whistle',
        'mdi-whistle-outline',
        'mdi-white-balance-auto',
        'mdi-white-balance-incandescent',
        'mdi-white-balance-iridescent',
        'mdi-white-balance-sunny',
        'mdi-widgets',
        'mdi-widgets-outline',
        'mdi-wifi',
        'mdi-wifi-off',
        'mdi-wifi-star',
        'mdi-wifi-strength-1',
        'mdi-wifi-strength-1-alert',
        'mdi-wifi-strength-1-lock',
        'mdi-wifi-strength-2',
        'mdi-wifi-strength-2-alert',
        'mdi-wifi-strength-2-lock',
        'mdi-wifi-strength-3',
        'mdi-wifi-strength-3-alert',
        'mdi-wifi-strength-3-lock',
        'mdi-wifi-strength-4',
        'mdi-wifi-strength-4-alert',
        'mdi-wifi-strength-4-lock',
        'mdi-wifi-strength-alert-outline',
        'mdi-wifi-strength-lock-outline',
        'mdi-wifi-strength-off',
        'mdi-wifi-strength-off-outline',
        'mdi-wifi-strength-outline',
        'mdi-wikipedia',
        'mdi-wind-turbine',
        'mdi-window-close',
        'mdi-window-closed',
        'mdi-window-closed-variant',
        'mdi-window-maximize',
        'mdi-window-minimize',
        'mdi-window-open',
        'mdi-window-open-variant',
        'mdi-window-restore',
        'mdi-window-shutter',
        'mdi-window-shutter-alert',
        'mdi-window-shutter-open',
        'mdi-wiper',
        'mdi-wiper-wash',
        'mdi-wizard-hat',
        'mdi-wordpress',
        'mdi-wrap',
        'mdi-wrap-disabled',
        'mdi-wrench',
        'mdi-wrench-outline',
        'mdi-xamarin',
        'mdi-xamarin-outline',
        'mdi-xing',
        'mdi-xml',
        'mdi-xmpp',
        'mdi-y-combinator',
        'mdi-yahoo',
        'mdi-yeast',
        'mdi-yin-yang',
        'mdi-yoga',
        'mdi-youtube',
        'mdi-youtube-gaming',
        'mdi-youtube-studio',
        'mdi-youtube-subscription',
        'mdi-youtube-tv',
        'mdi-z-wave',
        'mdi-zend',
        'mdi-zigbee',
        'mdi-zip-box',
        'mdi-zip-box-outline',
        'mdi-zip-disk',
        'mdi-zodiac-aquarius',
        'mdi-zodiac-aries',
        'mdi-zodiac-cancer',
        'mdi-zodiac-capricorn',
        'mdi-zodiac-gemini',
        'mdi-zodiac-leo',
        'mdi-zodiac-libra',
        'mdi-zodiac-pisces',
        'mdi-zodiac-sagittarius',
        'mdi-zodiac-scorpio',
        'mdi-zodiac-taurus',
        'mdi-zodiac-virgo',
        'mdi-blank'

];