<div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="4px" style="height: 100%">

    <!--template list-->
    <div fxFlex="20%" fxLayout="column" fxLayoutAlign="space-between" style="border-right: 2px solid #f5f5f5;">
        <div fxFlex>
            <mat-toolbar style="height: 32px !important;">
                <span style="color: #01565f; font-size: 11pt; margin-left: -6px;">{{'TEMPLATE_MODELS' | translate}}</span>
                <span class="fire-spacer"></span>
            </mat-toolbar>
            <div style="height: 87vh;overflow: auto;">
                <mat-form-field appearance="standard" style="width: 100%">
                    <input [formControl]="searchTemplateControl" matInput placeholder="{{'SEARCH' | translate}}" style="padding: 0 10px">
                </mat-form-field>
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                    <!-- This is the tree node template for leaf nodes -->
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <!-- use a disabled button to provide padding for tree leaf -->
                        <button disabled mat-icon-button>
                            <mat-icon class="word-icon-gradient" fontIcon="mdi-file-word" fontSet="mdi"></mat-icon>
                        </button>
                        <span (click)="showTemplateModel(node, 'STD')"
                              [ngClass]="{'node-name': true, 'selected': node?.name === selectedTemplate?.name}"
                              [ngStyle]="{'fontWeight': (node?.name === selectedTemplate?.name ? 'bold': '400'), 'cursor': 'pointer'}">{{node.name}}</span>

                        <span fxFlex></span>
                        <button (click)="selectTemplateItem(node)" [matMenuTriggerFor]="tmMenu" mat-icon-button>
                            <mat-icon fontIcon="mdi-dots-vertical" fontSet="mdi"></mat-icon>
                        </button>

                        <mat-menu #tmMenu="matMenu">
                            <button (click)="duplicateTemplateModel(selectedItem, 'STD')" disabled
                                    mat-menu-item> {{'DUPLICATE' | translate}}
                            </button>
                            <button [disabled]="!filterCategories(selectedItem?.category).length" [matMenuTriggerFor]="catMenu"
                                    mat-menu-item> {{'MOVE_TO' | translate}}</button>
                            <button (click)="deleteTemplateModel(selectedItem)"
                                    mat-menu-item> {{'DELETE' | translate}}</button>
                        </mat-menu>

                        <mat-menu #catMenu="matMenu">
                            <button (click)="moveHere(selectedItem, cat)"
                                    *ngFor="let cat of filterCategories(selectedItem?.category)" mat-menu-item>{{cat}}</button>
                        </mat-menu>
                    </mat-tree-node>
                    <!-- This is the tree node template for expandable nodes -->
                    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" class="ft-tree" matTreeNodePadding matTreeNodeToggle>
                        <button [attr.aria-label]="'toggle ' + node.name" mat-icon-button>
                            <mat-icon class="folder-icon-gradient" [fontIcon]="treeControl.isExpanded(node) ? 'mdi-folder-open' : 'mdi-folder'"
                                      fontSet="mdi"></mat-icon>
                        </button>
                        {{node.name}}
                        <span fxFlex></span>
                        <button (click)="$event.stopPropagation();deleteCategory(node.name)" [matTooltip]="'DELETE' | translate" class="action-icon" mat-icon-button>
                            <mat-icon class="delete-icon-gradient" fontIcon="mdi-delete" fontSet="mdi" style="color: #ba1e5c"></mat-icon>
                        </button>
                        <button (click)="$event.stopPropagation();editCategory(node)" [matTooltip]="'EDIT' | translate" class="action-icon" mat-icon-button>
                            <mat-icon class="edit-icon-gradient" fontIcon="mdi-pencil" fontSet="mdi" style="color: deepskyblue"></mat-icon>
                        </button>
                        <button (click)="$event.stopPropagation();addModel(node.name, 'STD')" [matTooltip]="'ADD_MODEL' | translate" class="action-icon" mat-icon-button>
                            <mat-icon class="add-icon-gradient" fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                        </button>
                    </mat-tree-node>
                </mat-tree>
            </div>

        </div>
        <!--   <div fxFlex>
               <mat-toolbar color="primary" style="height: 32px !important; border-radius: 4px 4px 0 0;">
                   <span style="color: #ffffff; font-size: 11pt; margin-left: -6px;">{{'NORMAL_MODELS' | translate}}</span>
                   <span class="fire-spacer"></span>
                   <button mat-icon-button (click)="addNewModel('NORMAL')">
                       <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                   </button>
               </mat-toolbar>
               <div style="height: calc(50vh - 36px);overflow: auto;">
                   <mat-tree [dataSource]="dataSource2" [treeControl]="treeControl2">
                       &lt;!&ndash; This is the tree node template for leaf nodes &ndash;&gt;
                       <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                           &lt;!&ndash; use a disabled button to provide padding for tree leaf &ndash;&gt;
                           <button mat-icon-button disabled>
                               <mat-icon [style.color]="'#0c91cb'" fontSet="mdi" fontIcon="mdi-file-word"></mat-icon>
                           </button>
                           <span [ngStyle]="{'fontWeight': (node?.name === selectedTemplate?.name ? 'bold': '400'), 'cursor': 'pointer'}"
                                 [ngClass]="{'node-name': true, 'selected': node?.name === selectedTemplate?.name}"
                                 (click)="showTemplateModel(node, 'NORMAL')">{{node.name}}</span>

                           <span fxFlex></span>
                           <button mat-icon-button [matMenuTriggerFor]="tmMenu"
                                   (click)="$event.stopPropagation(); selectedTemplate = selectTemplate(node.name, 'NORMAL')">
                               <mat-icon fontIcon="mdi-dots-vertical" fontSet="mdi"></mat-icon>
                           </button>

                           <mat-menu #tmMenu="matMenu">
                               <button mat-menu-item disabled
                                       (click)="duplicateTemplateModel(selectedTemplate, 'NORMAL')"> {{'DUPLICATE' | translate}}
                               </button>
                               <button mat-menu-item [disabled]="!filterCategories(selectedTemplate?.category).length"
                                       [matMenuTriggerFor]="catMenu"> {{'MOVE_TO' | translate}}</button>
                               <button mat-menu-item
                                       (click)="deleteTemplateModel(selectedTemplate)"> {{'DELETE' | translate}}</button>
                           </mat-menu>

                           <mat-menu #catMenu="matMenu">
                               <button mat-menu-item *ngFor="let cat of filterCategories(selectedTemplate?.category)"
                                       (click)="moveHere(selectedTemplate, cat)">{{cat}}</button>
                           </mat-menu>
                       </mat-tree-node>
                       &lt;!&ndash; This is the tree node template for expandable nodes &ndash;&gt;
                       <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                           <button mat-icon-button matTreeNodeToggle
                                   [attr.aria-label]="'toggle ' + node.name">
                               <mat-icon [style.color]="'#f7b74c'" fontSet="mdi"
                                         [fontIcon]="treeControl2.isExpanded(node) ? 'mdi-folder-open' : 'mdi-folder'"></mat-icon>
                           </button>
                           {{node.name}}
                           <span fxFlex></span>
                           <button (click)="addModel(node.name, 'NORMAL')" mat-icon-button>
                               <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                           </button>
                       </mat-tree-node>
                   </mat-tree>
               </div>

           </div>-->
    </div>

    <!--TemplateContainer-->
    <div fxFlex="grow" fxLayout="column" fxLayoutAlign="end">
        <ng-container *ngIf="selectedTemplate">
            <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="space-between center" style="padding: 12px;">
                <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
                    <mat-form-field [floatLabel]="'never'" appearance="standard" fxFlex="312px">
                        <mat-label>{{'TEMPLATENAME' | translate}}</mat-label>
                        <input [(ngModel)]="selectedTemplate.name" [disabled]="selectedTemplate.name === 'default'"
                               matInput
                               placeholder="{{'TEMPLATENAME' | translate}}">
                    </mat-form-field>
                    <mat-form-field [floatLabel]="'never'" appearance="standard">
                        <mat-label>{{'FAMILY' | translate}}</mat-label>

                        <mat-select [(ngModel)]="selectedTemplate.category" placeholder="{{'FAMILY' | translate}}">
                            <mat-option *ngFor="let cat of categories" [value]="cat">{{cat}}</mat-option>
                        </mat-select>

                        <button (click)="newCategory($event)" mat-icon-button
                                matSuffix>
                            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                        </button>
                    </mat-form-field>
                </div>


                <mat-button-toggle-group (valueChange)="switchTemplateMode($event)" *ngIf="logo.includes('cvis')">
                    <mat-button-toggle value="form">
                        <mat-icon fontIcon="mdi-file-question" fontSet="mdi"></mat-icon>
                        {{'FORM' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle [checked]="true" value="template">
                        <mat-icon fontIcon="mdi-note-text" fontSet="mdi"></mat-icon>
                        {{'EDITOR' | translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>


                <button (click)="onAddSection()" *ngIf="logo.includes('cvis')" [disabled]="selectedTemplate.name === 'default'"
                        [matTooltip]="'ADD_SECTION' | translate"
                        mat-icon-button style="color: #1540bf">
                    <mat-icon fontIcon="mdi-shape-rectangle-plus" fontSet="mdi" style="color: inherit;"></mat-icon>
                </button>

            </div>

            <div [ngStyle]="{'display': (group === 'form'? 'block': 'none'), 'overflow': 'auto'}" fxFlex="grow">
                <div style="padding: 12px; overflow: auto;">

                    <div *ngFor="let section of sections" class="draggable-cmp mat-elevation-z1">

                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">

                            <mat-form-field appearance="standard" class="full-width">
                                <mat-label>{{'SECTIONTITLE' | translate}}</mat-label>
                                <input matInput placeholder="{{'SECTIONTITLE' | translate}}" [(ngModel)]="section.title"
                                       [disabled]="selectedTemplate.name === 'default'" (change)="updateTemplate()">
                            </mat-form-field>

                            <button [matTooltip]="'ADD_FIELD' | translate" color="primary" mat-icon-button
                                    (click)="onAddField(section)"
                                    [disabled]="selectedTemplate.name === 'default'">
                                <mat-icon style="color: inherit;" fontIcon="mdi-table-column-plus-before"
                                          fontSet="mdi"></mat-icon>
                            </button>

                            <button [matTooltip]="'DELETE_SECTION' | translate" style="color: #e81000" mat-icon-button
                                    (click)="deleteSection(section)"
                                    [disabled]="selectedTemplate.name === 'default'">
                                <mat-icon style="color: inherit" fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                            </button>
                        </div>


                        <div *ngFor="let element of section.elements" fxLayout="row"
                             fxLayoutAlign="space-between center"
                             fxLayoutGap="8px">
                            <mat-form-field appearance="standard" fxFlex>
                                <mat-label>{{'ELEMENTNAME' | translate}}</mat-label>
                                <input matInput placeholder="{{'ELEMENTNAME' | translate}}" [(ngModel)]="element.name"
                                       [disabled]="selectedTemplate.name === 'default'" (change)="updateTemplate()">
                            </mat-form-field>
                            <mat-form-field appearance="standard" fxFlex>
                                <mat-label>{{'FIELDTYPE' | translate}}</mat-label>
                                <mat-select placeholder="{{'FIELDTYPE' | translate}}" [(ngModel)]="element.elementType"
                                            [disabled]="selectedTemplate.name === 'default'">
                                    <mat-option *ngFor="let elm of elementTypes"
                                                [value]="elm.name">{{elm.name | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="standard" fxFlex
                                            *ngIf="element.elementType === 'SELECT' || element.elementType === 'RADIO' ||
                        element.elementType === 'MULTI'">
                                <mat-label>{{'FIELDDICTIONARY' | translate}}</mat-label>
                                <mat-select placeholder="{{'FIELDDICTIONARY' | translate}}"
                                            [compareWith]="compareFn"
                                            [(ngModel)]="element.dictionary">
                                    <mat-option *ngFor="let dic of dictionaries" [value]="dic">{{ dic.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <button [matTooltip]="'DELETE_FIELD' | translate" style="color: #e81000" mat-icon-button
                                    (click)="deleteElement(section.elements, element)"
                                    [disabled]="selectedTemplate.name === 'default'">
                                <mat-icon style="color: inherit" fontIcon="mdi-minus-box" fontSet="mdi"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngStyle]="{'display': (group === 'form'? 'none': 'block'), 'overflow': 'auto'}" fxFlex="grow">


                <!--<lo-editor #loEditor (onSaveDocument)="onSaveDocument($event)"
                           [disableAutoCompile]="true" [fileId]="'T' + selectedTemplate.id"
                           [templateModel]="selectedTemplate.id"></lo-editor>-->

                <div fxFill>
                    <mat-menu #patientDataMenu="matMenu">
                        <button (click)="insertVariable(item.key)" *ngFor="let item of patientVariables"
                                mat-menu-item>{{item.label}}</button>
                    </mat-menu>

                    <mat-menu #examDataMenu="matMenu">
                        <button (click)="insertVariable(item.key)" *ngFor="let item of examVariables"
                                mat-menu-item>{{item.label}}</button>
                    </mat-menu>

                    <button [matMenuTriggerFor]="patientDataMenu" class="o-patient" color="primary" mat-button>Patient
                    </button>
                    <button [matMenuTriggerFor]="examDataMenu" class="o-exam" color="primary" mat-button>Examen</button>


                    <ft-reporter (editorInitialized)="editorReady($event)"
                                 [documentTitle]="selectedTemplate.name"
                                 [fileId]="'T_' + selectedTemplate.id"
                                 [templateMode]="1"
                                 [editable]="true"
                                 [templateModel]="selectedTemplate.id" fxFlex>
                    </ft-reporter>

                </div>


            </div>
        </ng-container>
        <div class="btn-actions" fxFlex="42px" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px"
             style="padding-right: 12px">
            <button (click)="uploadFiles()" color="accent" mat-raised-button>
                {{ 'UPLOAD_FILES' | translate }}
            </button>
            <button (click)="saveTemplateModel()" color="primary" mat-raised-button>
                {{ 'SAVEMODEL' | translate }}
            </button>
        </div>
    </div>
</div>
