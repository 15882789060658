import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard, LoginComponent, LoginGuard} from './auth';
import {ExternalViewerComponent} from './external-viewer/external-viewer.component';
import {MainComponent} from './main.component';
import {LogsComponent, QueueComponent} from './shared';


const routes: Routes = [
    {path: '', pathMatch: 'full', redirectTo: '/scheduler/schedule-manager',},
    {path: 'ft-queue', component: QueueComponent},
    {path: 'login', canActivate: [LoginGuard], component: LoginComponent},
    {
        path: '', canActivate: [AuthGuard], component: MainComponent,
        children: [
            {
                path: 'scheduler',
                loadChildren: () => import('./scheduler/scheduler-routing.module').then(m => m.SchedulerRoutingModule)
            },
            {
                path: 'workflow',
                loadChildren: () => import('./workflow/workflow-routing.module').then(m => m.WorkflowRoutingModule)
            },
            {
                path: 'patients',
                loadChildren: () => import('./patient/patient-routing.module').then(m => m.PatientRoutingModule)
            },
            {
                path: 'reporting',
                loadChildren: () => import('./reporting/reporting-routing.module').then(m => m.ReportingRoutingModule)
            },
            {
                path: 'setting',
                loadChildren: () => import('./setting/setting-routing.module').then(m => m.SettingRoutingModule)
            },
            {
                path: 'statistic',
                loadChildren: () => import('./statistic/statistic-routing.module').then(m => m.StatisticRoutingModule)
            },
            {
                path: 'messaging',
                loadChildren: () => import('./messaging/messaging-routing.module').then(m => m.MessagingRoutingModule)
            },
            {
                path: 'logs',
                component: LogsComponent,
                pathMatch: 'full'
            }
        ]
    },
    {path: 'external-viewer/:study', canActivate: [AuthGuard], component: ExternalViewerComponent},
    {path: '**', pathMatch: 'full', redirectTo: '/scheduler/schedule-manager'},
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
