import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-delete-confirm',
    templateUrl: './delete-confirm.component.html',
    styleUrls: ['./delete-confirm.component.scss']
})
export class DeleteConfirmComponent {

    selectedItem: any;

    constructor(@Inject(MAT_DIALOG_DATA) public message: string, private dialogRef: MatDialogRef<DeleteConfirmComponent>) {
    }

    onYes() {
        this.dialogRef.close(true);
    }

    onNo() {
        this.dialogRef.close(false);
    }
}
