import {Component, OnInit} from '@angular/core';
import {SettingService} from '../setting.service';
import {ReportingConfig} from '../../model';
import {SharedService} from '../../shared';

@Component({
    selector: 'app-reporting-config',
    templateUrl: './reporting-config.component.html'
})
export class ReportingConfigComponent implements OnInit {

    reportingConfig: ReportingConfig;
    taskListOption: any;
    staffList: any[];
    tasks: any[] = [
        'ONETASKLIST', 'MANYTASKLISTS'
    ];
    userOption: any;
    assigningOptions: any[] = [
        'SELFASSIGNED', 'MULTIPLEUSERS'
    ];

    constructor(private settingService: SettingService, private sharedService: SharedService) {
    }

    ngOnInit() {

        this.sharedService.getStaff().subscribe(res => this.staffList = res);

        this.settingService
            .getReportingConfig(1)
            .subscribe(rc => {
                this.reportingConfig = rc;
                this.taskListOption = rc.oneTaskList ? this.tasks[0] : this.tasks[1];
                this.userOption = rc.selfAssigned ? this.assigningOptions[0] : this.assigningOptions[1];
            });
    }

    saveReportingConfig(reportingConfig) {
        this.settingService.saveReportingConfig(this.reportingConfig).subscribe();
        localStorage.setItem('reportingConfig', JSON.stringify(this.reportingConfig));
    }

    onSelectTaskList(event) {
        if (event.value === this.tasks[0]) {
            this.reportingConfig.oneTaskList = true;
            this.reportingConfig.manyTaskLists = false;
        } else {
            this.reportingConfig.oneTaskList = false;
            this.reportingConfig.manyTaskLists = true;
        }
    }

    onSelectAssignOption(event) {
        if (event.value === this.assigningOptions[0]) {
            this.reportingConfig.selfAssigned = true;
            this.reportingConfig.multipleUsers = false;
        } else {
            this.reportingConfig.selfAssigned = false;
            this.reportingConfig.multipleUsers = true;
        }
    }

}
