import {Component, OnInit} from '@angular/core';
import {ReportingService} from "../reporting.service";
import {Viewer, WorkflowItem} from "../../model";
import {SharedService} from "../../shared";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SettingService} from "../../setting/setting.service";

@Component({
    selector: 'ft-reporting-search',
    templateUrl: './reporting-search.component.html',
    styleUrls: ['./reporting-search.component.scss']
})
export class ReportingSearchComponent implements OnInit {

    dataSource: WorkflowItem[] = [];
    reasonForExams = [];
    allTypes = [];

    searchForm: FormGroup;
    defaultViewer: Viewer;
    private currentUser: any;

    constructor(private reportingService: ReportingService,
                private shared: SharedService,
                private setting: SettingService,
                private fb: FormBuilder) {
        this.searchForm = this.fb.group({searchKey: '', examType: []});
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.getViewers();
    }


    ngOnInit(): void {
        this.searchForm.valueChanges.subscribe(this.searchReports.bind(this));

        this.shared.getReasonForExams().subscribe(value => {
            this.reasonForExams = value;
            this.allTypes = value.map(it => it.value);

            setTimeout(() => this.searchForm.patchValue({examType: []}));
        });

        this.initScrollExams();
    }

    selectReport(item: WorkflowItem) {
        this.reportingService.getReportingTask(item.reportingTaskId).subscribe(value => this.reportingService.openReport(value));
    }

    openReportAndViewer(row: WorkflowItem, replace: boolean = true) {
        this.reportingService.getReportingTask(row.reportingTaskId).subscribe(value => this.reportingService.openReport(value));

        let params = `${this.defaultViewer.name}_${row.studyInstanceUID}`;

        if (window['viewerWindow'] && !window['viewerWindow'].closed) {
            window['viewerWindow'].focus();
            this.reportingService.openStudy(row.studyInstanceUID, this.currentUser.username, replace).subscribe()
        } else {
            window['viewerWindow'] = window.open(`/external-viewer/study?param=${params}`, 'viewerWindow', 'toolbar=0,location=0,menubar=0,left');
            window['viewerWindow'].addEventListener('beforeunload', () => window['viewerWindow'] = null);
        }
    }

    private getViewers() {
        this.setting.getViewers().subscribe(res => {
            this.defaultViewer = res.filter(v => v.defaultViewer)[0];
        });
    }

    private searchReports(query: any) {
        let key = [query.searchKey, (query.examType && query.examType.length !== 0 ? query.examType : this.allTypes).join("-")].join('@');

        this.reportingService.searchReportTasks(20, 0, 'patientArrival', 'asc', key).subscribe(value => {
            this.dataSource = value['content'];
        });
    }

    addToWaitingList(item: WorkflowItem) {
        this.reportingService.getReportingTask(item.reportingTaskId).subscribe(value => this.reportingService.openReport(value, true));
    }

    private initScrollExams() {
        const buttonRight = document.getElementById('nav-right');
        const buttonLeft = document.getElementById('nav-left');
        const scrollContainer = document.getElementById('toggle-group');

        buttonRight.onclick = () =>  scrollContainer.scrollLeft += 240;
        buttonLeft.onclick =  () => scrollContainer.scrollLeft -= 240;
    }
}
