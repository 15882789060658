<div mat-dialog-title></div>
<div mat-dialog-content>

    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="4px">
        <mat-icon (click)="selectedIcon = icon" *ngFor="let icon of icons" [class.selected]="icon == selectedIcon"
                  [fontIcon]="icon" [matTooltip]="icon" fontSet="mdi"></mat-icon>
    </div>
</div>
<div align="end" mat-dialog-actions>
    <button [matDialogClose]="null" color="accent" mat-stroked-button>{{'CANCEL' | translate}}</button>
    <button [matDialogClose]="selectedIcon" color="primary" mat-stroked-button><b>{{'APPLY' | translate}}</b></button>
</div>