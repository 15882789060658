import {Component, ElementRef, Inject, isDevMode, OnInit, ViewChild} from '@angular/core';
import {Profile, WsMessage} from './model';
import {LOGO} from './global-variables';
import {Title} from '@angular/platform-browser';
import {AuthService} from './auth';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {LocalStorageService, SharedService, UserUpdateComponent} from './shared';
import {AppInfoComponent} from './app-info/app-info.component';
import * as $ from 'jquery';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {WsService} from './ws.service';
import {Message, MessagePopupComponent} from './messaging';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SchedulerService} from './scheduler/scheduler.service';
import {SettingService} from "./setting/setting.service";
import {logProductName} from "./utils";


@Component({
    selector: 'ft-main',
    host: {
        '[class.glassy-theme]': '( currentTheme === \'glassy-light\')',
        '[class.glassy-theme-dark]': '( currentTheme === \'glassy-dark\')',
        '[class.dark-theme]':'( currentTheme === \'basic-dark\')',
    },
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

    username: string;
    profile: Profile;
    user: any;
    fullScreen = false;
    isDarkTheme = false;
    glassyTheme: string = 'glassy-light';

    logo = LOGO;
    public mobile: boolean;
    public cvis: boolean = LOGO.includes('cvis');
    public totalNotifs: number = null;
    @ViewChild('sound_player', {static: false}) soundPlayer: ElementRef;
    private curl: any;
    public readonly isDev: boolean;

    public get userName(): string {
        return this.user ? this.user.lastName + ' ' + this.user.firstName : 'Anonymous';
    }

    public constructor(@Inject(DOCUMENT) public _document: any,
                       private titleService: Title,
                       private translate: TranslateService,
                       private _authService: AuthService,
                       private dialog: MatDialog,
                       private _platform: Platform,
                       private _ws: WsService,
                       private _router: Router,
                       private _localStorage: LocalStorageService,
                       private _scheduler: SchedulerService,
                       private _setting: SettingService,
                       private _snack: MatSnackBar) {
        logProductName();

        this.isDev = isDevMode();

        this._scheduler.scanIdCard.subscribe(_ => {
            const eid_url = localStorage.getItem('eid_api_url');
            let url = JSON.parse(eid_url) + `/eid/scan_eid/${this.user.id}`;
            console.log(`%c${url}`, "color:#8888ff;font-weight:bold;border:2px solid grey;padding:8px;border-radius:6px;")

            window.open(url);
        });

        this.mobile = this._platform.IOS || this._platform.ANDROID;

        this.isDarkTheme = this._localStorage.getItem('isDarkTheme') || false;

        this.user = this._localStorage.getItem('user');
        this.profile = this.user.profile;

        let chat_topic = 'chat/' + this.user.id;

        this._ws.observeTopic(chat_topic).subscribe(data => {
            if (data) {
                if (data.topic !== chat_topic) return;

                let message = JSON.parse(data.response) as WsMessage;
                if ('newMessage' === message.text) {
                    let msg = message.data as Message;
                    if (msg.sender.id !== this.user.id && !this.curl.startsWith('/messaging')) {
                        this.totalNotifs += 1;
                        this.playSound();
                        this.showMessagePopup(msg);
                    }
                }

                this._ws.chatUpdate.next(message);
            }
        });


        this._setting.getGeneralSetting().subscribe(res => {
            if (res.idScannerConfigured && res.scannerIdUrl.startsWith('beid')) {
                let eid_card_topic = 'eid-reader/'+this.user.id;
                this._ws.observeExternalTopic(eid_card_topic).subscribe(data => {
                    if (data) {
                        let message = JSON.parse(data.response) as WsMessage;
                        let msg = message.data;
                        this._scheduler.beidCardData.next(msg);
                    }
                });
            }
        });

        this._router.events
            .pipe(filter(e1 => e1 instanceof NavigationEnd))
            .subscribe(e2 => this.curl = e2['url']);

        this._ws.soundPlayer.asObservable().subscribe(value => {
            if (value) this.playSound();
        });
    }

    public get currentTheme(): string {
        return JSON.parse(this._localStorage.getItem('glassyTheme')) || 'glassy-light';
    }

    private playSound() {
        if (this.soundPlayer) this.soundPlayer.nativeElement.play();
    }

    showAppInfo = () => this.dialog.open(AppInfoComponent, {width: '400px'});

    public toggleGlassyTheme(theme: string): void {
        this.glassyTheme = theme;
        this._localStorage.setItem('glassyTheme', JSON.stringify(this.glassyTheme));
    }

    ngOnInit(): void {

        let focusedElement;
        $(document).on('dragover dragleave drop', 'input.mat-input-element', false);

        $(document).on('focus', 'input.mat-input-element', function () {
            if (focusedElement === this) return;
            focusedElement = this;

            setTimeout(function () {
                focusedElement.select();
            }, 50);
        });
    }

    private showMessagePopup(message: Message): void {
        this._snack.openFromComponent(MessagePopupComponent, {
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            data: message,
            duration: 10000
        }).afterDismissed().subscribe(value => {
            if (value.dismissedByAction) {
                this.totalNotifs = null;
                this._router.navigate(['/messaging'], {queryParams: {roomId: message.roomId}});
            }
        });
    }

    public toggleFullScreen(): void {
        let element = this._document.documentElement;

        this.fullScreen = this._document.webkitIsFullScreen || this._document.mozFullScreen || false;

        element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function () {
            return false;
        };
        this._document.cancelFullScreen = this._document.cancelFullScreen || this._document.webkitCancelFullScreen || this._document.mozCancelFullScreen || function () {
            return false;
        };

        this.fullScreen ? this._document.cancelFullScreen() : element.requestFullScreen();
        this.fullScreen = screen.availHeight - 30 >= window.innerHeight;
    }

    public setTitle(newTitle: string): void {
        this.titleService.setTitle(newTitle);
    }

    public logout(): void {
        this._authService.logout().subscribe(res => {
            this._router.navigate(['/login']).then();
        });
    }

    updateUser() {
        this.dialog.open(UserUpdateComponent, {data: this.user}).afterClosed().subscribe(res => {
            if (res) this.logout();
        });
    }

}
