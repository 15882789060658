<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
    <h3 mat-dialog-title>{{'UNAVAILABILITIES' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button (click)="editUnavailability({})">
        <mat-icon fontIcon="mdi-plus-circle" fontSet="mdi"></mat-icon>
    </button>&nbsp;
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>


    <div class="mat-elevation-z1 mat-table">

        <mat-table #table [dataSource]="dataSource" class="fire-table" matSort style="min-height: 200px;">


            <!--Table-->
            <ng-container *ngFor="let col of displayedColumns" cdkColumnDef="{{col.label}}" [class.hidden]="col.hidden">
                <mat-header-cell *cdkHeaderCellDef class="fire-header-cell" mat-sort-header> {{col.header | translate}}
                    <span *ngIf="col.unit !== 'date' && col.unit !== 'color'">{{col.unit}}</span></mat-header-cell>

                <ng-container *ngIf="col.unit === 'date'">
                    <mat-cell *cdkCellDef="let row" class="fire-cell"> {{row[col.label] | date:'dd/MM/yyyy'}}</mat-cell>
                </ng-container>
                <ng-container *ngIf="col.unit !== 'date'">
                    <mat-cell *cdkCellDef="let row" class="fire-cell"> {{row[col.label]}}</mat-cell>
                </ng-container>

            </ng-container>

            <!--actions-->
            <ng-container cdkColumnDef="action">
                <mat-header-cell *cdkHeaderCellDef class="fire-header-cell"></mat-header-cell>
                <mat-cell *cdkCellDef="let row" class="fire-cell">
                    <button mat-icon-button (click)="$event.stopPropagation(); editUnavailability(row)">
                        <mat-icon fontIcon="mdi-pencil-box-outline" fontSet="mdi"></mat-icon>
                    </button>
                    <button mat-icon-button (click)="$event.stopPropagation(); deleteUnavailability(row)">
                        <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <!--header-->
            <mat-header-row *cdkHeaderRowDef="cols; sticky:true" class="fire-header-row"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: cols;"
                     class="fire-row">
            </mat-row>
        </mat-table>

        <mat-paginator #paginator
                       [length]="dataSource.filteredData.length"
                       [pageIndex]="0"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
        </mat-paginator>
    </div>

</mat-dialog-content>


