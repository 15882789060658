export const STAFF_TABLE_CONF = [
    {
        label: 'id',
        header: 'ID',
        value: 'id',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: true,
        searchable: true,
        required: true
    },{
        label: 'name',
        header: 'NAME',
        value: 'name',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: 'feeType',
        header: 'FEE_TYPE',
        value: 'feeType',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    }
];

export const STAFF_HEADER_COLS = [
    'name',
    'feeType',
    'action'
];
