export const PCODE_TABLE_CONF = [
    {
        label: 'id',
        header: 'ID',
        value: 'id',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: true,
        searchable: true,
        required: true
    },{
        label: 'code',
        header: 'CODE',
        value: 'code',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: 'description',
        header: 'DESCRIPTION',
        value: 'description',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: 'billingCode_code',
        header: 'BILLING_CODE',
        value: 'billingCode.code',
        type: 'string',
        unit: null,
        defaultValue: '0',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    }, {
        label: 'duration',
        header: 'DURATION',
        value: 'duration',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: 'modality_value',
        header: 'MODALITY',
        value: 'modality.value',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: 'pharmaceutical',
        header: 'PHARMACEUTICAL',
        value: 'pharmaceutical',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: 'reasonForExam_value',
        header: 'PROCEDURE_TYPE',
        value: 'reasonForExam.value',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    }
];

export const PCODE_HEADER_COLS = [
    'code',
    'description',
    'reasonForExam_value',
    'modality_value',
];
