<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-pencil-box-outline"></mat-icon>
    <h3 mat-dialog-title>{{'EDIT_USER' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <div [formGroup]="form">

        <div fxLayout="row" fxLayoutGap="4px">

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'LASTNAME' | translate}}</mat-label>
                <input matInput placeholder="{{'LASTNAME' | translate}}" formControlName="lastName" required autocomplete="off">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'FIRSTNAME' | translate}}</mat-label>
                <input matInput placeholder="{{'FIRSTNAME' | translate}}" formControlName="firstName" required autocomplete="off">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex formGroupName="contract">
                <mat-label>{{'STAFF_CONTRACT' | translate}}</mat-label>
                <mat-select placeholder="{{'STAFF_CONTRACT' | translate}}" formControlName="id" class="full-width">
                    <mat-option *ngFor="let contract of contracts | async" [value]="contract.id" class="option">
                        {{contract.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex formGroupName="box">
                <mat-label>{{'BOX' | translate}}</mat-label>
                <mat-select placeholder="{{'BOX' | translate}}" formControlName="id" class="full-width">
                    <mat-option *ngFor="let box of boxes" [value]="box.id" class="option">
                        <div fxLayout="row">
                            <span>{{box.name}}</span>
                            <span fxFlex></span>
                            <button mat-icon-button
                                    (click)="$event.stopImmediatePropagation(); editBox(box)">
                                <mat-icon fontSet="mdi" fontIcon="mdi-pencil" [style.color]="'#454545'"></mat-icon>
                            </button>
                            <button mat-icon-button
                                    (click)="$event.stopImmediatePropagation(); deleteBox(box)">
                                <mat-icon fontSet="mdi" fontIcon="mdi-delete" [style.color]="'#883946'"></mat-icon>
                            </button>
                        </div>
                    </mat-option>
                </mat-select>
                <button mat-icon-button matSuffix
                        (click)="$event.stopImmediatePropagation(); createBox()">
                    <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="4px">

            <div fxFlex>
                <mat-form-field appearance="standard" style="width: 100%">
                    <mat-label>{{'USERNAME' | translate}}</mat-label>
                    <input matInput autocomplete="off" placeholder="{{'USERNAME' | translate}}" formControlName="username" required>
                </mat-form-field>
                <span class="ft-error" *ngIf="exists | async">{{'USERNAME_EXIST' | translate}}</span>
            </div>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'PASSWORD' | translate}}</mat-label>
                <input matInput autocomplete="off" placeholder="{{'PASSWORD' | translate}}" formControlName="password" type="password"
                    required>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'CONFIRMPASSWORD' | translate}}</mat-label>
                <input matInput placeholder="{{'CONFIRMPASSWORD' | translate}}" formControlName="confirmPassword"
                    required type="password">
            </mat-form-field>

        </div>

        <div fxLayout="row" fxLayoutGap="4px">

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'CLINICALID' | translate}}</mat-label>
                <input matInput placeholder="{{'CLINICALID' | translate}}" formControlName="clinicalID">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex formGroupName="profile">
                <mat-label>{{'PROFILE' | translate}}</mat-label>
                <mat-select placeholder="{{'PROFILE' | translate}}" formControlName="id" class="full-width" required>
                    <mat-option *ngFor="let fn of profiles" [value]="fn.id" class="option">
                        {{fn.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex formGroupName="function">
                <mat-label>{{'FUNCTION' | translate}}</mat-label>
                <mat-select placeholder="{{'FUNCTION' | translate}}" formControlName="id" required>
                    <mat-option *ngFor="let fn of functions" [value]="fn.id" class="option">
                        {{fn.description | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" class="space-top" fxLayoutAlign="space-between center" fxLayoutGap="4px">
            <mat-slide-toggle fxFlex formControlName="canViewConfidentialData" [color]="'warn'">
                {{'CAN_VIEW_CONFIDENTIAL_DATA' | translate}}
            </mat-slide-toggle>

            <div fxFlex="100px">
                {{'COLOR' | translate}}: <span class="color-area" [(colorPicker)]="color"
                    [style.background]="color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </div>
        </div>


    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button color="warn" mat-dialog-close mat-raised-button>{{'CANCEL' | translate}}</button>
    <button (click)="onSave(form.value)" [disabled]="!form.valid" color="primary" mat-raised-button>{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>
