import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {BehaviorSubject, forkJoin, fromEvent as observableFromEvent, merge, of as observableOf} from "rxjs";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {SharedService} from "../shared.service";
import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap} from "rxjs/operators";
import {tableAnimation} from "../../animations";
import {REF_PH_HEADER_COLS, REF_PH_TABLE_CONF} from "./table-conf";
import {MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SettingService} from "../../setting/setting.service";
import {assign} from "lodash";

@Component({
    selector: 'ft-referring-physicians-search',
    templateUrl: './referring-physicians-search.component.html',
    styleUrls: ['./referring-physicians-search.component.scss'],
    animations: [tableAnimation]
})
export class ReferringPhysiciansSearchComponent implements OnInit, AfterViewInit {

    dataSource = new MatTableDataSource();
    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    filterChange = new BehaviorSubject('');

    cols: any[];
    displayedColumns = [];


    referringForm: FormGroup;

    titles: any[] = [];
    specialities: any[] = [];

    @ViewChild('filter', {static: true}) filter: ElementRef;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    editing: boolean;

    constructor(private shared: SharedService,
                private _fb: FormBuilder,
                private _setting: SettingService,
                private _dialogRef: MatDialogRef<ReferringPhysiciansSearchComponent>) {
        this.cols = REF_PH_HEADER_COLS;
        this.displayedColumns = REF_PH_TABLE_CONF;

        this.createForm();
    }

    private createForm() {
        this.referringForm = this._fb.group({
            id: '',
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            title: this._fb.group({id: ''}),
            speciality: this._fb.group({id: ''}),
            facility: this._fb.group({id: ''}),
            phone: '',
            codeAnam: '',
            email: '',
            address: '',
            function: this._fb.group({id: 130}),
            profile: null
        });
    }

    ngOnInit() {
        this.filter.nativeElement.focus();
    }

    trackByLastName(index: number, item: any): string {
        return item.lastName;
    }

    selectPhysician(row) {
        this._dialogRef.close(row);
    }

    ngAfterViewInit() {

        observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
            debounceTime(400),
            distinctUntilChanged(),)
            .subscribe(() => {
                if (!this.dataSource) return;
                this.paginator.pageIndex = 0;
                this.filterChange.next(this.filter.nativeElement.value);
            });

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange.asObservable(), this.paginator.page.asObservable(), this.filterChange)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.shared.getPaginatedReferringPhysicians(this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        this.filter.nativeElement.value);
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data['totalElements'];
                    let physicians = data['content'];
                    return physicians.filter(val => val.id !== 420);
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.dataSource.data = data);
    }

    editReferringPhysician(row) {
        let sidenav = document.getElementById('sidenav');
        if (!sidenav.classList.contains('visible')) this.toggleSidenav();

        if (this.titles.length == 0) forkJoin([this.shared.getTitles(), this.shared.getSpecialities()])
            .subscribe(data => {
                [this.titles, this.specialities] = data;

                this.referringForm.patchValue(row);
            });
        else this.referringForm.patchValue(row);
    }

    toggleSidenav() {
        document.getElementById('sidenav').classList.toggle('visible');
    }

    saveReferringPhysician(value: any) {
        this._setting
            .saveUser(ReferringPhysiciansSearchComponent.escapeNullData(value))
            .subscribe(_ => {
                this.toggleSidenav();
                this.filterChange.next('');
            });
    }

    private static escapeNullData(data: any): any {
        return assign(data, {
            title: data.title.id === '' ? null : data.title,
            speciality: data.speciality.id === '' ? null : data.speciality
        });
    }
}
