import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StatisticComponent} from './statistic.component';
import {ExamPerMonthComponent} from './exam-per-month/exam-per-month.component';
import {UserResolver} from "../user.resolver";

const routes: Routes = [
    {
        path: '', component: StatisticComponent,
        resolve: {user: UserResolver},
        children: [
            {path: '', component: ExamPerMonthComponent, pathMatch: 'full'},
            {path: 'exams-per-month', component: ExamPerMonthComponent},
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class StatisticRoutingModule {
}
