import {Component, Inject, OnInit} from '@angular/core';
import {SharedService} from '../shared.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {SchedulerService} from '../../scheduler/scheduler.service';
import {assign} from 'lodash';
import {forkJoin} from 'rxjs';
import {PathologyEditComponent} from '../../setting/pathology-setting/pathology-edit/pathology-edit.component';

@Component({
    selector: 'app-patient-arrived',
    templateUrl: './patient-arrived.component.html',
    styleUrls: ['./patient-arrived.component.scss']
})
export class PatientArrivedComponent implements OnInit {

    sideEffectsList: any[];
    radiologists: any[];
    assistantRadiologists: any[] = [];

    form: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private shared: SharedService,
                private service: SchedulerService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private dialogRef: MatDialogRef<PatientArrivedComponent>) {

        this.form = this.fb.group({
            patientID: '',
            patientName: '',
            reasonForExam: '',
            procedureCode: '',
            performingPhysician: '',
            assistantPhysicians: '',
            sideEffects: '',
            observation: '',
            clinicalInfos: ''
        });

        this.form.get('performingPhysician').valueChanges.subscribe(value => {
            setTimeout(() => this.assistantRadiologists = this.assistantRadiologists.filter(val => val.id !== value), 200);
        });

        this.form.get('patientID').disable();
        this.form.get('patientName').disable();
        this.form.get('reasonForExam').disable();
        this.form.get('procedureCode').disable();

    }

    onSave(value) {

        value.sideEffects = this.sideEffectsList
            .filter(val => value.sideEffects.indexOf(val.value) !== -1)
            .map(v => v.value);

        let patient = this.data.isr.patient;
        let rp = this.data.isr.requestedProcedure;
        let sps = rp.scheduledProcedureSteps[0];
        let mh = patient.medicalHistory;

        let perfPhys = this.radiologists.filter(val => val.id === value.performingPhysician)[0];

        let isr = assign(this.data.isr, {
            patient: assign(patient, {
                medicalHistory: assign(mh, {
                    additionalPatientHistory: value.clinicalInfos,
                    observations: value.observation,
                    allergies: value.sideEffects.join(',')
                })
            }),
            requestedProcedure: assign(rp, {
                scheduledProcedureSteps: [assign(sps, {
                    scheduledPerformingPhysiciansName: perfPhys,
                    assistantPhysicians: value.assistantPhysicians ? value.assistantPhysicians.join(',') : ''
                })]
            })
        });

        this.dialogRef.close(isr);
    }

    addAllergy(event) {
        event.stopPropagation();
        this.dialog.open(PathologyEditComponent, {data: {type: 'external', icon: 'plus', title: 'NEW_ALLERGY'}, disableClose: true})
            .afterClosed()
            .subscribe(data => {
                if (data) {
                    this.shared.createAllergy(data).subscribe(res => {
                        this.sideEffectsList.push(res);
                        let allergies = this.form.get('sideEffects').value;
                        allergies.push(res.value);
                        this.form.get('sideEffects').patchValue(allergies);
                    });
                }
            });
    }

    ngOnInit() {

        forkJoin([
            this.shared.getPerformingPhysicians(),
            this.shared.getAllergies()
        ]).subscribe(data => {

            [this.radiologists, this.sideEffectsList] = data;

            this.assistantRadiologists = data[0];

            let patient = this.data.isr.patient;
            let rp = this.data.isr.requestedProcedure;
            let sps = rp ? rp.scheduledProcedureSteps[0] : null;

            this.form.patchValue({
                patientID: patient.externalPatientID,
                patientName: !patient.confidential || this.data.canViewConfData ? patient.fullName : '**** ****',
                reasonForExam: rp ? rp.reasonForRequestedProcedure.value : '-',
                procedureCode: sps.procedureCode.code,
                performingPhysician: sps.scheduledPerformingPhysiciansName ? sps.scheduledPerformingPhysiciansName.id : null,
                clinicalInfos: patient.medicalHistory ? patient.medicalHistory.additionalPatientHistory : '',
                observation: patient.medicalHistory ? patient.medicalHistory.observations : '',
                sideEffects: patient.medicalHistory ? patient.medicalHistory.allergies.split(',') : ''
            });

        });




    }

}
