import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {BillingCode} from '../../../model';
import {SettingService} from '../../setting.service';
import { MatDialogRef } from '@angular/material/dialog';
import {assign} from 'lodash';

@Component({
  selector: 'app-billing-code-edit',
  templateUrl: './billing-code-edit.component.html',
  styleUrls: ['./billing-code-edit.component.scss']
})
export class BillingCodeEditComponent implements OnInit {

  billingCodeForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public billingCode: BillingCode,
              private setting: SettingService,
              private fb: FormBuilder,
              private dialogRef: MatDialogRef<BillingCodeEditComponent>) {
    this.billingCodeForm = this.fb.group(assign(new BillingCode(), {code: ['', Validators.required]}));
  }

  ngOnInit() {
    if (this.billingCode) this.billingCodeForm.patchValue(this.billingCode);
  }

  saveBillingCode(code) {
    this.setting.saveBillingCode(code).subscribe(res => {
      this.dialogRef.close(res)
    })
  }

}
