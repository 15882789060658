<div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="4px" style="height: 100%;" class="setting-container">
    <div fxFlex="260px">
        <mat-card class="sidenav">

            <mat-list class="setting-list">
                <h3 matSubheader>{{'SYSTEM' | translate}}</h3>
                <mat-list-item *ngFor="let item of systemItems" routerLink="{{item.route}}" routerLinkActive="active"
                               [ngStyle]="{'display': (profile && profile[item.authLabel] !== 'NONE') ? 'block': 'none'}"
                               (click)="setHeaderTitle(item)">
                    <mat-icon mat-list-icon fontIcon="{{item.icon}}" fontSet="mdi"
                              [ngStyle]="{background: 'linear-gradient(45deg, black, '+item.iconColor+', white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}"></mat-icon>
                    <h4 mat-line style="font-size: 12px;">{{item.title | translate}}</h4>
                </mat-list-item>

                <h3 matSubheader>{{'EXAM_SETTING' | translate}}</h3>
                <mat-list-item *ngFor="let item of procedureItems" routerLink="{{item.route}}" routerLinkActive="active"
                               [ngStyle]="{'display': (profile && profile[item.authLabel] !== 'NONE') ? 'block': 'none'}"
                               (click)="setHeaderTitle(item)">
                    <mat-icon mat-list-icon fontIcon="{{item.icon}}" fontSet="mdi"
                              [ngStyle]="{background: 'linear-gradient(45deg, black, '+item.iconColor+', white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}"></mat-icon>
                    <h4 mat-line style="font-size: 12px;">{{item.title | translate}}</h4>
                </mat-list-item>

                <h3 matSubheader
                    *ngIf="profile && (profile.manageUsers !== 'NONE' || profile.manageProfile !== 'NONE')">
                    {{'USER_AND_PROFILE' | translate}}
                </h3>
                <mat-list-item *ngFor="let item of securityItems" routerLink="{{item.route}}" routerLinkActive="active"
                               [ngStyle]="{'display': (profile && profile[item.authLabel] !== 'NONE') ? 'block': 'none'}"
                               (click)="setHeaderTitle(item)">
                    <mat-icon mat-list-icon fontIcon="{{item.icon}}" fontSet="mdi"
                              [ngStyle]="{background: 'linear-gradient(45deg, black, '+item.iconColor+', white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}"></mat-icon>
                    <h4 mat-line style="font-size: 12px;">{{item.title | translate}}</h4>
                </mat-list-item>
            </mat-list>

        </mat-card>
    </div>

    <mat-card fxFlex="grow" fxLayout="column" style="padding: 0; border-radius: 4px">
        <mat-card-content fxFlex="grow" style="overflow: auto;">
            <router-outlet></router-outlet>
        </mat-card-content>
    </mat-card>
</div>
