<div *ngIf="tableConfig" class="formatting-card">
    <div class="formatting-card-header" fxLayout="row" fxLayoutAlign="space-between center">
        <span>{{'FORMATTING_RULE' | translate}}</span>
        <button (click)="close.emit(true)" mat-icon-button>
            <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
        </button>
    </div>

    <div (cdkDropListDropped)="drop($event)" *ngIf="!selectedRule" cdkDropList>
        <formatting-style-line (click)="editRule(rule)" (onDelete)="deleteRule(rule)"
                               *ngFor="let rule of getFormattingRules()"
                               [formattingRule]="rule"
                               cdkDrag>
        </formatting-style-line>
    </div>


    <formatting-rule (onApplyChanges)="saveChanges($event)"
                     (onCancel)="selectedRule = null"
                     *ngIf="selectedRule"
                     [columns]="tableConfig.tableColumns"
                     [rule]="selectedRule"></formatting-rule>

</div>



<button (click)="addNewRule()" *ngIf="!selectedRule" [matTooltip]="'ADD_ANOTHER_RULE' | translate" class="add-new" color="accent" mat-mini-fab>
        <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon></button>

