import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SharedService} from '../../../shared';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-pathology-edit',
    templateUrl: './pathology-edit.component.html',
    styleUrls: ['./pathology-edit.component.scss']
})
export class PathologyEditComponent implements OnInit {

    form: FormGroup;
    title = 'PATHOLOGY';
    headerIcon = 'dna';

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private service: SharedService,
                private fb: FormBuilder,
                private dialogRef: MatDialogRef<PathologyEditComponent>) {
        this.form = this.fb.group({
            id: 0,
            value: ['', Validators.required]
        })
    }

    ngOnInit() {
        if (this.data && this.data.type === 'external') {
            this.title = this.data.title;
            this.headerIcon = this.data.icon;
        }
        this.form.patchValue(this.data || {})
    }

    saveData(data) {
        if (this.data && this.data.type === 'external') this.dialogRef.close(data);
        else this.service.savePathology(data).subscribe(res => this.dialogRef.close(res));
    }

}
