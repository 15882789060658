import * as moment from 'moment';
import frLocale from '@fullcalendar/core/locales/fr';

export function calendarPresets(cmp): any {
    return {
        headerToolbar: {
            left: 'prev,dateSelect,next today',
            center: 'title',
            right: 'resourceSelect dayGridMonth,timeGridWeek,timeGridDay,listWeek,resourceTimelineDay,resourceTimeGridDay,resourceTimeGrid2Day'
        },
        views: {
            resourceTimeGrid2Day: {
                type: 'resourceTimeGridDay',
                duration: {days: 2},
                buttonText: cmp.translate.instant('GRID_2D')
            }
        },
        buttonText: {
            listWeek: cmp.translate.instant('LIST'),
            resourceTimelineDay: 'Planning',
            resourceTimeGridDay: cmp.translate.instant('GRID')
        },
        allDaySlot: false,
        datesAboveResources: true,
        initialView: 'timeGridWeek',
        slotMinTime: '01:00',
        slotMaxTime: '23:00',
        contentHeight: 'calc(100vh - 172px)',
        slotDuration: '00:10',
        snapDuration: '00:05',
        timeZone: 'UTC',
        slotLabelFormat: {
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false
        },
        titleFormat: {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            omitZeroMinute: false
        },
        slotEventOverlap: false,
        scrollTime: moment().subtract(2, 'h').format('HH:mm:ss'),
        locales: [frLocale],
        locale: cmp._config.appLang,
        allDayText: cmp.translate.instant('ALL_DAY'),
        dragRevertDuration: 1000,
        droppable: true,
        dragScroll: true,
        eventDurationEditable: true,
        nowIndicator: true,
        navLinks: true,
        weekends: true,
        editable: true,
        selectable: true,
        selectMirror: false,
        selectConstraint: 'businessHours',
        eventConstraint: 'businessHours',
        dayMaxEvents: true,
        dropAccept: true,
        businessHours: cmp.businessHours,
        forceEventDuration: true,
        refetchResourcesOnNavigate: true,
        resourceGroupField: 'resourceName',
        resourceAreaWidth: 300,
        resourceAreaColumns: [
            {
                headerContent: 'Ressource',
            }
        ],
        eventContent(info: any): any {
            if (['timeGridWeek', 'timeGridDay', 'resourceTimeGridDay', 'resourceTimeGrid2Day', 'resourceTimelineDay'].includes(info.view.type)) return '';
        },
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    };
}
