<mat-form-field appearance="standard" [formGroup]="group" class="full-width">

    <mat-label>{{config.placeholder}}</mat-label>
    <!--chip list-->
    <mat-chip-list #chipList [formControlName]="config.name">

        <mat-chip *ngFor="let option of _value" [selectable]="true" [removable]="true" (removed)="remove(option)"
                  class="chip">
            {{option.value}}
            <mat-icon matChipRemove fontIcon="mdi-close" fontSet="mdi"></mat-icon>
        </mat-chip>

        <input placeholder="{{ config.name }}"
               [matChipInputFor]="chipList"
               [matChipInputAddOnBlur]="true"
               (matChipInputTokenEnd)="add($event)"
               [matAutocomplete]="auto"
               #chipInput/>
    </mat-chip-list>

    <!--autocomplete-->
    <mat-autocomplete #auto="matAutocomplete"
                      [displayWith]="displayFn"
                      (optionSelected)="addSelected($event)">

        <mat-option *ngFor="let val of sourceFiltered()" [value]="val">
            <span>{{ val.value }}</span>
        </mat-option>

    </mat-autocomplete>

</mat-form-field>
