import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {remove} from 'lodash';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {


    comments: Comment[] = [];

    @ViewChild('editArea', { static: true }) editArea: ElementRef;
    private commentToEdit: Comment;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<CommentsComponent>) {
    }

    saveOnEnter(event) {
        if (event.keyCode === 13) {
            if (this.commentToEdit) {
                this.comments = this.comments.map(c => {
                    if (c === this.commentToEdit) c.comment = event.target.value;
                    return c;
                });
                this.commentToEdit = null
            } else this.comments.push(new Comment(this.data.username, event.target.value));

            this.editArea.nativeElement.value = '';
        }
    }

    editComment(c: Comment) {
        this.commentToEdit = c;
        this.editArea.nativeElement.value = c?.comment?.replace('\n', '');
        this.editArea.nativeElement.focus()
    }

    deleteComment(c: Comment) {
        remove(this.comments, c);
    }

    saveComments() {
        if (this.editArea?.nativeElement?.value) {
            if (this.commentToEdit) {
                this.comments = this.comments.map(c => {
                    if (c === this.commentToEdit) c.comment = this.editArea.nativeElement.value;
                    return c;
                });
                this.commentToEdit = null
            } else this.comments.push(new Comment(this.data.username, this.editArea.nativeElement.value));
        }

        let noteAlert = this.comments.filter(it => it !== undefined && it.comment !== '').map(c => `${c.datetime};${c.username};${c.comment.replace(';', '')}`).reduce((acc, c) => `${acc}|${c}`, '');
        this.dialogRef.close(noteAlert);
    }

    ngOnInit() {
        if (this.data.comment) this.getComments(this.data.comment);
    }

    private getComments(text: string) {
        let comments = text.split('|')
        this.comments = comments.filter(it => it != undefined && it !== '').map(c => {
            let tokens = c.split(';');
            return new Comment(tokens[1], tokens[2], tokens[0])
        });
    }
}

export class Comment {
    constructor(public username?: string, public comment?: string, public datetime: string = moment().format('DD/MM/YYYY HH:mm')) {
    }
}
