import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {PHARMACY_URL, WORKFLOW_URL} from '../urls';
import {StockMovement} from "../model";

@Injectable()
export class WorkflowService {

    constructor(private http: HttpClient) {
    }

    getWorkflow(pageSize: number, pageIndex: number, sort: string, direction: string, filter: string): Observable<any> {
        let params = {page: String(pageIndex), size: String(pageSize), sort: `${sort},${direction}`, filter};
        return this.http.get(`${WORKFLOW_URL}/all`, {params});
    }

    getPatientWorkflow(pageSize: number, pageIndex: number, sort: string, direction: string, patientID: string): Observable<any> {
        let params = {page: String(pageIndex), size: String(pageSize), patientID};
        return this.http.get(`${WORKFLOW_URL}/patient`, {params});
    }

    exportToExcel(query: string): Observable<Blob> {
        let params = {keys: query};
        return this.http.get(`${WORKFLOW_URL}/exportToExcel`, {params: params, responseType: 'blob'});
    }

    hideExams(ids: string): Observable<boolean> {
        let params = {ids};
        return this.http.get<boolean>(`${WORKFLOW_URL}/hideExams`, {params});
    }

    hideExam(ids: string): Observable<boolean> {
        let params = {ids};
        return this.http.get<boolean>(`${WORKFLOW_URL}/hideExams`, {params});
    }

    calculateCA(dateRange: string): Observable<any> {
        let params = {dateRange};
        return this.http.get(`${WORKFLOW_URL}/calculateCA`, {params});
    }

    syncWithBilling(accessionNumber: string) {
        let params = {accessionNumber};
        return this.http.get(`${WORKFLOW_URL}/sync-billing`, {params});
    }

    saveStockMovement(entity: StockMovement): Observable<StockMovement> {
        return this.http.post<StockMovement>(`${PHARMACY_URL}/saveStockMovement`, entity);
    }

    getWorkflowItems(dateRange: string): Observable<any> {
        return this.http.get(`${WORKFLOW_URL}/json`, {params: {dateRange}});
    }

    findExamsHistory(patientID: any): Observable<any> {
        return this.http.get(`${WORKFLOW_URL}/examsHistory`, {params: {patientID}});
    }

    openSyngo(studyInstanceUIDs: any): Observable<any> {
        return this.http.get(`${WORKFLOW_URL}/syngo`, {params:{studyInstanceUIDs}});
    }

    calculateSubTotals(filter: string): Observable<any> {
        return this.http.get(`${WORKFLOW_URL}/calculate-sub-total`, {params:{filter}})
    }
}
