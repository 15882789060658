<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-timer-sand"></mat-icon>
    <h3 mat-dialog-title>{{'ADD_WAITING_ORDER' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content id="form">
    <form [formGroup]="orderForm">

        <div *ngIf="!selectedPatient || data.isr" [formGroup]="patientForm">
            <div fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="start center">

                <mat-form-field appearance="standard" formGroupName="title">
                    <mat-label>{{'TITLE' | translate}}</mat-label>
                    <mat-select [placeholder]="'TITLE' | translate" formControlName="id" tabindex="-1">
                        <mat-option *ngFor="let title of titles" [value]="title.id">{{title.value}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="standard" fxFlex>
                    <mat-label>{{'LASTNAME' | translate}}</mat-label>
                    <input matInput formControlName="lastName"  required [placeholder]="'LASTNAME' | translate">
                </mat-form-field>

                <mat-form-field appearance="standard" fxFlex>
                    <mat-label>{{'FIRSTNAME' | translate}}</mat-label>
                    <input matInput formControlName="firstName"  required [placeholder]="'FIRSTNAME' | translate">
                </mat-form-field>


                <button class="find-patient" mat-icon-button color="primary" (click)="onSearchPatient()">
                    <mat-icon style="color: #FFFFFF;" fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
                    <span class="badge"
                          *ngIf="numOfDuplicatePatients && !patientSelected">{{numOfDuplicatePatients}}</span>
                </button>

            </div>

            <div fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="start end">

                <mat-form-field appearance="standard" formGroupName="demographic" fxFlex="180px">
                    <mat-label>{{'DATEOFBIRTH' | translate}}</mat-label>
                    <input matInput formControlName="dateOfBirth" [matDatepicker]="picker" [max]="now" (dateInput)="changeAge()"
                           [placeholder]="'DATEOFBIRTH' | translate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker (closed)="changeAge()"></mat-datepicker>
                </mat-form-field>

                <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="4px" [formGroup]="ageForm">
                    <mat-form-field appearance="standard" fxFlex="80px">
                        <mat-label>{{'AGE_YEARS' | translate}}</mat-label>
                        <input type="number" matInput placeholder="{{'AGE_YEARS' | translate}}" formControlName="years" (keyup)="changeDate()">
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex="80px">
                        <mat-label>{{'AGE_MONTHS' | translate}}</mat-label>
                        <input type="number" matInput placeholder="{{'AGE_MONTHS' | translate}}" formControlName="months" (keyup)="changeDate()">
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex="80px">
                        <mat-label>{{'AGE_DAYS' | translate}}</mat-label>
                        <input type="number" matInput placeholder="{{'AGE_DAYS' | translate}}" formControlName="days" (keyup)="changeDate()">
                    </mat-form-field>
                </div>

                <div formGroupName="demographic" style="padding-left: 8px">
                    <div fxLayout="column" formGroupName="gender">
                        <div class="label-option">{{'GENDER' | translate}}</div>

                        <mat-radio-group fxLayout="row" fxLayoutAlign="start end" formControlName="id">
                            <mat-radio-button fxFlex *ngFor="let option of genders" [value]="option.id">
                                {{option.value}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

            </div>

            <div fxLayout="row wrap" fxLayoutGap="4px">
                <mat-form-field appearance="standard">
                    <mat-label>{{'CIN' | translate}}</mat-label>
                    <input matInput formControlName="cin" [placeholder]="'CIN' | translate">
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>{{'PHONE' | translate}}</mat-label>
                    <input matInput formControlName="phone" [placeholder]="'PHONE' | translate">
                </mat-form-field>

                <mat-form-field appearance="standard" fxFlex>
                    <mat-label>{{'EMAIL' | translate}}</mat-label>
                    <input matInput formControlName="email" [placeholder]="'EMAIL' | translate">
                </mat-form-field>
            </div>

            <div [formGroup]="orderForm">

                <div fxLayout="row wrap" fxLayoutAlign="space-between end" fxLayoutGap="8px">
                    <div fxLayout="column" fxLayoutAlign="space-between" formGroupName="confidentiality"
                         style="width: 200px;">
                        <div class="label-option">{{'CONFIDENTIALITY' | translate}}</div>

                        <mat-radio-group fxLayout="row" fxLayoutAlign="start end" formControlName="id">
                            <mat-radio-button fxFlex *ngFor="let option of confidentialities" [value]="option.id">
                                {{option.description | translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <mat-form-field appearance="standard" formGroupName="priority">
                        <mat-label>{{'PRIORITY' | translate}}</mat-label>
                        <mat-select formControlName="id" [placeholder]="'PRIORITY' | translate" (selectionChange)="changePriority($event)">
                            <mat-option *ngFor="let priority of priorities" [value]="priority.id">
                                {{priority.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="standard" fxFlexFill>
                    <mat-label>{{'RELEVANTCLINICALINFO' | translate}}</mat-label>
                    <textarea matInput placeholder="{{'RELEVANTCLINICALINFO' | translate}}"
                              formControlName="relevantClinicalInfo"></textarea>
                </mat-form-field>

            </div>
        </div>

        <mat-divider *ngIf="!selectedPatient" class="ft-divider"></mat-divider>

        <div fxLayout="row wrap" fxLayoutGap="4px">
            <mat-form-field appearance="standard" fxFlex formGroupName="reasonForExam">
                <mat-label>{{'REASON_FOR_EXAM' | translate}}</mat-label>
                <mat-select placeholder="{{'REASON_FOR_EXAM' | translate}}" formControlName="id">
                    <mat-option *ngFor="let rfe of rfes" [value]="rfe.id">
                        {{rfe.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'DATE' | translate}}</mat-label>
                <input matInput [matDatepicker]="startpicker" placeholder="{{'DATE' | translate}}" [min]="now"
                       formControlName="requestedDate" required>
                <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
                <mat-datepicker #startpicker [startView]="'year'"></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'TIME' | translate}}</mat-label>
                <input matInput type="time" required placeholder="{{'TIME' | translate}}"
                       formControlName="requestedTime">
            </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="4px">
            <mat-form-field appearance="standard" fxFlex formGroupName="requestingService">
                <mat-label>{{'REQUESTINGSERVICE' | translate}}</mat-label>
                <mat-select placeholder="{{'REQUESTINGSERVICE' | translate}}"
                            formControlName="id">
                    <mat-option *ngFor="let rs of hospitalServices" [value]="rs.id">
                        {{rs.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex formGroupName="referringPhysician">
                <mat-label>{{'REFERRING_PHYSICIAN' | translate}}</mat-label>
                <mat-select [placeholder]="'REFERRING_PHYSICIAN' | translate" formControlName="id"
                            (selectionChange)="onSelectRefPhysician($event)">
                    <mat-option *ngFor="let staff of referringPhysicians" [value]="staff.id">{{staff.lastName}}
                        {{staff.firstName}}
                    </mat-option>
                </mat-select>


                <button mat-icon-button matSuffix
                        (click)="$event.stopImmediatePropagation(); addReferringPhysician()">
                    <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'PHONE' | translate}}</mat-label>
                <input matInput placeholder="{{'PHONE' | translate}}"
                       formControlName="callBackPhoneNumber">
            </mat-form-field>
        </div>

    </form>

    <app-file-explorer [fileElements]="fileElements | async"
                       [path]="currentPath"
                       [patientID]="patientID"
                       [modalMode]="true"
                       [canNavigateUp]="canNavigateUp"
                       (folderAdded)="addFolder($event)"
                       (elementRemoved)="removeElement($event)"
                       (navigatedDown)="navigateToFolder($event)"
                       (navigatedUp)="navigateUp()"
                       (onUpload)="afterUpload($event)"
                       (elementRenamed)="renameElement($event)"
                       (elementMoved)="moveElement($event)">
    </app-file-explorer>


</mat-dialog-content>

<mat-dialog-actions align="end">
    <button [mat-dialog-close]="null" color="accent" mat-raised-button>{{'CANCEL' | translate}}</button>
    <button (click)="onSave()" color="primary" mat-raised-button
            [disabled]="isrForm.invalid || orderForm.invalid || patientForm.invalid || (patIdExists && (patientForm.get('id').value === 0))">
        {{'SAVE' | translate}}
    </button>
</mat-dialog-actions>

