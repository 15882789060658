import {Component, OnInit} from '@angular/core';
import {
    buildClosingDays,
    getCalendarViews,
    getDays,
    getMomentTime,
    getTimeSlots,
    patchClosingDays,
    SharedService
} from '../../shared';
import * as _ from 'lodash';
import {SettingService} from '../setting.service';
import * as moment from 'moment';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {GeneralSetting} from '../../model';
import {Observable} from 'rxjs';
import {AppConfigService} from "../../app-config.service";
import {STATISTIC_VIEWS} from "../../utils";


@Component({
    selector: 'app-schedule-setting',
    templateUrl: './schedule-setting.component.html',
    styleUrls: ['./schedule-setting.component.scss']
})
export class ScheduleSettingComponent implements OnInit {


    calendarSetting = new CalendarSetting();
    generalSetting: GeneralSetting = null;

    days: any[];
    slots = [];
    views: any[];
    exams: Observable<any[]>;

    idGenerationModes = [{name: 'MANUAL', checked: true}, {name: 'AUTOMATIC', checked: false}];
    printingModes = [{name: 'CHROME', checked: true}, {name: 'CUPS', checked: false}];
    calFormGroup: FormGroup;
    statisticViews = STATISTIC_VIEWS;


    constructor(public sharedService: SharedService,
                private settingService: SettingService,
                private translate: TranslateService,
                private appConfig: AppConfigService,
                private fb: FormBuilder) {
        this.calFormGroup = this.fb.group({
            id: '',
            defaultView: this.fb.group({id: 2}),
            minTimeSlot: this.fb.group({id: 3}),
            openingTime: '',
            closingTime: ''
        });

    }

    onSelectView(e) {

        let dv = this.calFormGroup.controls['defaultView'].get('id');

        this.calendarSetting.defaultView = this.views.find(v => v.id === dv.value).key;

        this.saveCalendarSetting(this.calendarSetting);
    }

    onSelectSchedulerView(e) {
        this.generalSetting.schedulerDefaultView = e.value;
        this.saveGeneralSetting(this.generalSetting);
    }

    onSelectTime(e) {
        let slot = this.calFormGroup.value.minTimeSlot;

        this.calendarSetting.minTimeSlot = _.find(this.slots, slot).value;

        this.calendarSetting.openingTime = getMomentTime(this.calFormGroup.value.openingTime, 'HH:mm');
        this.calendarSetting.closingTime = getMomentTime(this.calFormGroup.value.closingTime, 'HH:mm');

        this.saveCalendarSetting(this.calendarSetting);
    }

    onSelectDay(ev) {
        this.calendarSetting.closingDays = _.filter(buildClosingDays(this.days, ev), d => d.checked).map(d => d.key).join(';');
        this.saveCalendarSetting(this.calendarSetting);
    }

    saveCalendarSetting(calSetting): void {

        this.settingService.saveCalendarSetting(calSetting).subscribe();

        localStorage.setItem('calSetting', JSON.stringify(calSetting));
    }

    ngOnInit() {
        this.slots = getTimeSlots();
        this.days = getDays(this.appConfig.appLang);
        this.views = getCalendarViews(this.appConfig.appLang);
        this.exams = this.sharedService.getReasonForExams();

        this.getCalendarSetting();
        this.settingService.getGeneralSetting().subscribe(res => this.generalSetting = res);
    }


    getCalendarSetting() {
        this.settingService.getCalendarSetting(1).subscribe(calSetting => {
            this.calendarSetting = calSetting;

            this.calFormGroup.patchValue(calSetting);

            patchClosingDays(this.days, calSetting.closingDays);

            let view = this.views.find(v => v.key === calSetting.defaultView);
            let slot = this.slots.find(val => Number(val.value) === calSetting.minTimeSlot);

            this.calFormGroup.controls['defaultView'].patchValue(view);
            this.calFormGroup.controls['minTimeSlot'].patchValue(slot ? slot : 20);
        })
    }

    saveGeneralSetting(generalSetting: GeneralSetting) {
        this.settingService
            .saveGeneralSetting(generalSetting)
            .subscribe(result => localStorage.setItem('generalSetting', JSON.stringify(result)))
    }
}

export class CalendarSetting {
    id: number = 0;
    openingTime: any = moment({hour: 8, minute: 0}).format('HH:mm');
    closingTime: any = moment({hour: 18, minute: 0}).format('HH:mm');
    closingDays: string = 'D7';
    defaultView: string = 'W';
    minTimeSlot: number = 15;

    constructor() {
    }
}
