import {AfterViewInit, Component} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {SettingService} from '../../setting/setting.service';
import {tableAnimation} from '../../animations';
import {groupBy, sortBy} from 'lodash';

@Component({
    selector: 'app-template-search',
    templateUrl: './template-search.component.html',
    styleUrls: ['./template-search.component.scss'],
    animations: [tableAnimation]
})
export class TemplateSearchComponent implements AfterViewInit {

    reports: { data: any; key: string }[] = [];

    selected: { data: any; key: string };
    constructor(private setting: SettingService, private dialogRef: MatDialogRef<TemplateSearchComponent>) {
    }
    ngAfterViewInit(): void {
        this.setting.getTemplateModels().subscribe(data => {
            let reports = groupBy(sortBy(data, 'category'), 'category');
            this.reports = Object.keys(reports).map(key => {
                return {key: key, data: sortBy(reports[key], 'name')}
            });

            this.selected = this.reports[0]
        });
    }
    selectRow = (row) => {
        this.dialogRef.close(row);
    }
}
