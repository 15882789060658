import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-print-layout',
    templateUrl: './print-layout.component.html',
    styleUrls: ['./print-layout.component.scss']
})
export class PrintLayoutComponent implements OnChanges {
    tiles = [];
    rowHeight: string = 'fit';
    cols: number = 1;
    gutterSize: string = '2';

    @Input() printLayout: string = '1x1';
    @Input() imageBackground: string = 'black';
    @Input() selectedImages: any[] = [];

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes['printLayout']) {
            let layout = changes['printLayout'].currentValue;

            this.tiles = [];

            if (!layout) return;
            let sp = layout.split('x');
            let rows = parseInt(sp[1], 0);
            this.cols = parseInt(sp[0], 0);
            this.rowHeight = `${297 / rows}mm`;
            for (let i = 0; i < rows * this.cols; i++) {
                this.tiles.push('A' + i);
            }
        }

    }
    toggleSynchronize(image: any) {
        image.synchronized = !image.synchronized;
    }
}
