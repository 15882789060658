<mat-toolbar color="primary">
    <mat-icon fontSet="mdi" fontIcon="mdi-comment-text-outline"></mat-icon>&nbsp;
    {{'MESSAGE' | translate}}
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close>
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<div style="padding: 12px;" fxLayout="column" class="message">

    <mat-form-field appearance="standard">
        <mat-label>{{'FROM' | translate}}</mat-label>
        <input matInput [ngModel]="data.performerName_lastName" disabled>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>{{'CONTENT' | translate}}</mat-label>
        <textarea matInput [ngModel]="data.assigningComment" disabled></textarea>
    </mat-form-field>

</div>

<mat-dialog-actions align="end">
    <button mat-dialog-close mat-raised-button>
        {{'CLOSE' | translate}}
    </button>
</mat-dialog-actions>
