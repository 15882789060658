import {Component, OnInit} from '@angular/core';
import {StatisticService} from './statistic.service';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';
import * as numeral from 'numeral';
import {set} from 'lodash';
import {BillingState, StatisticDataModel} from './model';
import {MatDialog} from '@angular/material/dialog';
import {DateRangeComponent} from './date-range/date-range.component';
import * as moment from 'moment';
import {PhysicianExamStateComponent} from './physician-exam-state/physician-exam-state.component';
import {ReferringExamStateComponent} from './referring-exam-state/referring-exam-state.component';
import {AetExamStateComponent} from './aet-exam-state/aet-exam-state.component';
import {TechnicianExamStateComponent} from './technician-exam-state/technician-exam-state.component';
import {FtPivotTableComponent} from './ft-pivot-table/ft-pivot-table.component';
import {ReasonForExamStateComponent} from './reason-for-exam-state/reason-for-exam-state.component';
import {PaymentDateStateComponent} from './payment-date-state/payment-date-state.component';
import {PatientStateComponent} from './patient-state/patient-state.component';
import {GeneralSetting} from "../model";
import {SettingService} from "../setting/setting.service";

@Component({
    selector: 'app-statistic',
    templateUrl: './statistic.component.html',
    styleUrls: ['./statistic.component.scss']
})
export class StatisticComponent implements OnInit {

    static COMPONENTS = {
        'EXAM': {
            'GENERAL_VIEW': FtPivotTableComponent,
            'PERFORMING_RADIOLOGIST': PhysicianExamStateComponent,
            'REFERRING_PHYSICIAN': ReferringExamStateComponent,
            'TECHNICIANS': TechnicianExamStateComponent,
            'AET_S': AetExamStateComponent,
            'REASONS_FOR_EXAM': ReasonForExamStateComponent,
        },
        'PATIENT': {
            'ALL': PatientStateComponent,
        },
        'PAYMENT': {
            'DATE': PaymentDateStateComponent
        },
    };

    expand = false;
    dataset: Observable<StatisticDataModel[]>;

    chartOptions = {
        responsive: true,
        legend: {
            position: 'bottom',
        }
    };

    colors = [{
        backgroundColor: [
            '#00b171',
            '#ff266a',
            '#e6c34c'
        ]
    }];

    pieChartLabels: string[] = [];
    pieChartData: number[] = [];

    paymentStateDate: string = 'TODAY';
    billingState: Observable<BillingState>;
    generalSetting: GeneralSetting;

    query = new BehaviorSubject(null);


    constructor(private service: StatisticService, private setting: SettingService, private dialog: MatDialog) {

        this.setting.getGeneralSetting().subscribe(value => this.generalSetting = value);

        this.billingState = merge(this.query)
            .pipe(
                startWith({}),
                switchMap(() => {
                    let query = this.query.getValue();
                    if (query) this.buildDateLabel(query);
                    return this.service.getBillingState(query);
                }),
                map(bs => {
                    let [...data] = bs.tableData;
                    data.pop();
                    this.pieChartData = data.map(value => Number(value.value));
                    this.pieChartLabels = data.map(value => value.elementType);
                    bs.tableData = bs.tableData.map(it => set(it, 'value', numeral(it.value).format('0,0 $')));
                    set(bs, 'dataSum', numeral(bs.dataSum).format('0,0 $'));
                    return bs;
                })
            );
    }

    selectDateRange() {
        this.query.next('custom');
        this.dialog.open(DateRangeComponent, {width: '320px'})
            .afterClosed()
            .subscribe(range => {
                if (range) this.query.next(`${moment(range.startDate).format('YYYYMMDD')}-${moment(range.endDate).format('YYYYMMDD')}`)
            })
    }

    ngOnInit() {
        this.dataset = this.service.getGlobalStatistics().pipe(
            map(v => v.map(it => new StatisticDataModel(it.title, numeral(it.count).format(`0,0`), it.icon)))
        );
    }

    private buildDateLabel(query: string) {
        moment.locale('fr');
        if (query.includes('-')) {
            let start = query.split('-')[0];
            let end = query.split('-')[1];
            let startStr = moment(start, 'YYYYMMDD').format(`${start === end ? 'DD MMMM YYYY' : 'DD'}`);
            let endStr = moment(end, 'YYYYMMDD').format('DD MMMM YYYY');
            this.paymentStateDate = start === end ? startStr : `${startStr} - ${endStr}`;
            return;
        }

        switch (query) {
            case 'day':
                this.paymentStateDate = 'TODAY';
                break;
            case 'month':
                this.paymentStateDate = 'THIS_MONTH';
                break;
            case 'year':
                this.paymentStateDate = 'THIS_YEAR';
                break;
            case 'custom':
                this.paymentStateDate = 'TODAY';
                break;
        }
    }
}
