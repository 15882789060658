import {Component, isDevMode, OnInit} from '@angular/core';
import * as moment from 'moment';
import {AppConfigService} from './app-config.service';
import {loadJSScript} from './utils';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {
    public constructor(private _config: AppConfigService) {
        moment.locale(this._config.appLang);
    }

    ngOnInit() {
        if (!document.getElementById('only-office-api') && isDevMode())
            loadJSScript('http://127.0.0.1:1500/web-apps/apps/api/documents/api.js', 'only-office-api')
    }
}

