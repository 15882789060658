<ng-container [ngSwitch]="viewType">
    <ng-container *ngSwitchCase="'resourceTimelineDay'">
        <div class="fc-content-timeline" fxLayout="row nowrap" fxLayoutAlign="space-between start" fxFill style="overflow: hidden" [style.borderLeftColor]="event.examColor || '#e0e0e0'">
            <div fxFlex="calc(100% - 30px)" fxLayout="column" class="event-text">
                <div style="text-shadow: 1px 1px 2px black;">
                    <ng-container *ngIf="event.urgent">
                        <mat-icon style="color: #ff4f01;" fontSet="mdi" fontIcon="mdi-ambulance"></mat-icon>
                    </ng-container>
                    {{ event.patientName }}
                </div>
                <div>{{ event.timeText }}</div>
                <div>{{ event.title }}</div>
                <div>{{ event.performingPhysician }}</div>
            </div>
            <button fxFlex="26px" (click)="$event.stopPropagation();"
                    mat-icon-button [matMenuTriggerFor]="menu" aria-label="Event menu">
                <mat-icon fontSet="mdi" fontIcon="mdi-dots-vertical"></mat-icon>
            </button>
        </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <div class="fc-content" fxLayout="row nowrap" fxLayoutAlign="space-between start" fxFill style="overflow: hidden" [style.borderLeftColor]="event.examColor || '#e0e0e0'">
            <div fxFlex="calc(100% - 30px)" fxLayout="column" class="event-text">
                <div style="text-shadow: 1px 1px 2px black;">
                    <ng-container *ngIf="event.urgent">
                        <mat-icon style="color: #ff4f01;" fontSet="mdi" fontIcon="mdi-ambulance"></mat-icon>
                    </ng-container>
                    {{ event.patientName }}
                </div>
                <div>{{ event.timeText }}</div>
                <div>{{ event.title }}</div>
                <div>{{ event.performingPhysician }}</div>
            </div>
            <button fxFlex="26px" (click)="$event.stopPropagation();"
                    mat-icon-button [matMenuTriggerFor]="menu" aria-label="Event menu">
                <mat-icon fontSet="mdi" fontIcon="mdi-dots-vertical"></mat-icon>
            </button>
        </div>
    </ng-container>

</ng-container>



<!--    Event menu -->
<mat-menu #menu="matMenu" xPosition="before" [overlapTrigger]="true">
    <button (click)="onSchedule.next(event)"
            [disabled]="user.profile['scheduleExam'] === 'NONE' || ['entered', 'planned'].includes(event.spsStatus)"
            mat-menu-item>
        <mat-icon fontIcon="mdi-calendar" fontSet="mdi"></mat-icon>
        <span>{{'SCHEDULE_EXAM' | translate}}</span>
    </button>
    <button (click)="onEventEdit.next(event)"
            [disabled]="user.profile['editExam'] === 'NONE' || ['entered', 'planned'].includes(event.spsStatus)"
            mat-menu-item>
        <mat-icon fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
        <span>{{'EDIT' | translate}}</span>
    </button>
    <button (click)="onPatientEdit.next(event)" [disabled]="user.profile['viewPatientFolderCalendar'] === 'NONE'"
            mat-menu-item>
        <mat-icon fontIcon="mdi-folder-account" fontSet="mdi"></mat-icon>
        <span>{{'PATIENT_FOLDER' | translate}}</span>
    </button>
    <button (click)="onPrint.next(event)" [disabled]="user.profile['printRdvForExam'] === 'NONE'" mat-menu-item>
        <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
        <span>{{'PRINT_RDV' | translate}}</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item [disabled]="user.profile['cancelOrder'] === 'NONE'" (click)="onDelete.next(event)">
        <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
        <span>{{'DELETE' | translate}}</span>
    </button>

</mat-menu>
