/**
 * Created by MBX on 7/6/2017.
 */
import * as moment from 'moment';

export class ReportFilter {
    constructor(public key: string = '',
                public dateRange: string = '',
                public pathology: string = '') {
        let today = moment().format('YYYYMMDD');
        this.dateRange = `${today}-${today}`
    }
}
