import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SchedulerService} from './scheduler.service';
import {SchedulerComponent} from './scheduler.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FireSharedModule, FtCalendarComponent, SharedService} from '../shared';
import {SchedulerRoutingModule} from './scheduler-routing.module';
import {OrderEditComponent} from './order-edit/order-edit.component';
import {ProcedureCodeSearchComponent} from './procedure-code-search/procedure-code-search.component';
import {FireCalendarComponent} from './fire-calendar/fire-calendar.component';
import {ExternalAppointmentsComponent} from './external-appointments';
import {EventDetailComponent} from './event-detail/event-detail.component';
import {EventEditComponent} from './event-edit/event-edit.component';
import {AvailabilityCheckComponent} from './availability-check/availability-check.component';
import {ExamAddComponent} from './exam-add/exam-add.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatStepperModule} from '@angular/material/stepper';
import {ExamAdvancedComponent} from './exam-advanced/exam-advanced.component';
import {ScheduleManagerComponent} from './schedule-manager/schedule-manager.component';
import {TableSettingComponent} from './schedule-manager/table-setting.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import {AppointmentEditComponent} from "./appointment-edit/appointment-edit.component";


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        MatCardModule,
        FireSharedModule,
        MatGridListModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatOptionModule,
        MatSelectModule,
        MatRadioModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatTabsModule,
        MatListModule,
        MatChipsModule,
        SchedulerRoutingModule,
        MatExpansionModule,
        MatStepperModule,
        FullCalendarModule
    ],
    declarations: [
        SchedulerComponent,
        OrderEditComponent,
        ProcedureCodeSearchComponent,
        FireCalendarComponent,
        ExternalAppointmentsComponent,
        EventDetailComponent,
        EventEditComponent,
        AvailabilityCheckComponent,
        ExamAddComponent,
        ExamAdvancedComponent,
        ScheduleManagerComponent,
        TableSettingComponent,
        FtCalendarComponent,
        AppointmentEditComponent
    ],
    providers: [SchedulerService, SharedService]
})
export class SchedulerModule {
}
