/**
 * Created by MBX on 7/18/2017.
 */

export const AET_TABLE_CONF = [
    {
        label: 'id',
        header: 'ID',
        value: 'id',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: true,
        searchable: true,
        required: true
    },{
        label: 'name',
        header: 'AET_NAME',
        value: 'name',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    }, {
        label: 'title',
        header: 'AETITLE',
        value: 'title',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: 'hostname',
        header: 'HOSTNAME',
        value: 'hostname',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: 'installer',
        header: 'INSTALLER',
        value: 'installer',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: 'installationDate',
        header: 'INSTALLATIONDATE',
        value: 'installationDate',
        type: 'string',
        unit: 'date',
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: 'technician',
        header: 'TECHNICIAN',
        value: 'defaultTechnician.lastName',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: '_room',
        header: 'ROOM',
        value: 'room.name',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    }, {
        label: 'color',
        header: 'COLOR',
        value: 'color',
        type: 'string',
        unit: 'color',
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    }, {
        label: 'hasMPPS',
        header: 'HAS_MPPS',
        value: 'hasMPPS',
        type: 'string',
        unit: null,
        defaultValue: false,
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    }

];

export const AET_HEADER_COLS = [
    'name',
    'title',
    'installer',
    'hostname',
    'installationDate',
    '_room',
    'color',
    'action'
];
