import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    isDevMode,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {FtSpeechRecognition} from "../ft-speech-recognition";
import {get} from 'lodash';
import {AppConfigService} from "../../app-config.service";
import {TranslateService} from "@ngx-translate/core";
import {editorMenu, variables} from "../../utils/editor";

@Component({
    selector: 'lo-editor',
    templateUrl: './lo-editor.component.html',
    styleUrls: ['./lo-editor.component.scss']
})
export class LoEditorComponent implements OnInit, AfterViewInit, OnChanges {

    private readonly accessToken: string;
    private readonly wopiSrc = isDevMode() ? 'https://192.168.1.77:8443' : 'https://127.0.0.1:8443';

    private recognition: FtSpeechRecognition;

    @ViewChild('frameElement', {static: true}) public frameRef: ElementRef;
    @Output() onSaveDocument = new EventEmitter<any>();
    @Output() editorLoaded = new EventEmitter<any>();
    @Input() disableAutoCompile?: boolean = false;
    @Input() fileId?: string;

    @Input() templateModel?: number;

    save = () => this.frameRef.nativeElement.contentWindow.socket._map.sendUnoCommand('.uno:Save')

    constructor(private _sanitizer: DomSanitizer, private _config: AppConfigService, private translate: TranslateService) {
        this.accessToken = localStorage.getItem('access_token');
    }

    ngOnChanges(changes: SimpleChanges): void {
        const fileId = changes['fileId'].currentValue + (changes['templateModel'] && changes['templateModel'].firstChange ? 'F1' : 'F0');
        const frameSrc = this._sanitizer.bypassSecurityTrustResourceUrl(`/loleaflet/ed4f732/loleaflet.html?WOPISrc=${this.wopiSrc}/wopi/files/${fileId}&access_token=${this.accessToken}`);
        this.frameRef.nativeElement.src = (frameSrc as any).changingThisBreaksApplicationSecurity;
    }

    ngAfterViewInit(): void {
        window.addEventListener('message', this.handlePostMessage, false);
    }

    ngOnInit() {
        window.document.addEventListener('variableInsert', e => {
            if (!this.disableAutoCompile) {
                let variable = e['detail']['variable'];
                this.sendCompileEvent(variable);
            }
        }, false);

        window.document.addEventListener('transcriptionStart', e => {
            this.recognition = new FtSpeechRecognition();

            if (e['detail']['action'] === 'start') {
                console.log('Start talking...');

                this.recognition.start();
                this.recognition.subject.subscribe(results => {
                    console.log(results);
                    let final = get(results, 'results.0.isFinal');

                    if (!final) return;

                    let transcript = get(results, 'results.0.0.transcript') + (final ? ' ' : '');

                    transcript = transcript.replace('point', '. ');
                    transcript = transcript.replace('virgule', ', ');
                    transcript = transcript.replace('espace', ' ');

                    console.log(transcript);

                    let event = new CustomEvent('transcriptionEvent', {detail: {'transcript': transcript}});
                    if (this.frameRef.nativeElement.contentWindow) {
                        this.frameRef.nativeElement.contentWindow.dispatchEvent(event);
                    }
                });
            } else {
                console.log('stop');
                this.recognition.stop();
            }
        }, false);
    }

    private handlePostMessage = (e: any) => {
        if (typeof e.data === typeof '') {
            let msg = JSON.parse(e.data);
            let msgId = msg.MessageId;
            let msgData = msg.Values;

            if (msgId === 'View_Added') {
                if (!this.disableAutoCompile) [...variables('PATIENT_VARIABLES', this), ...variables('EXAM_VARIABLES', this)].forEach(it => this.sendCompileEvent(it.key));

                this.postCustomMessage('changeLanguage', {'language': this._config.appLocale});
                this.postCustomMessage('setupMenu', {'menu': editorMenu(this)});
            }

            if (msgId === 'UI_Save') this.onSaveDocument.emit(true);

            if (msgData && msgData['Status'] === 'Document_Loaded') this.editorLoaded.emit(true);
        }
    };

    private sendCompileEvent(variable: string) {
        let patientData = JSON.parse(localStorage.getItem('PATIENT_DATA'));
        let examData = JSON.parse(localStorage.getItem('EXAM_DATA'));

        let variablesData = [...patientData, ...examData];

        let variableValue = variablesData.find(it => it.key === variable);
        let event = new CustomEvent('variableData', {
            detail: {'variable': variable, 'value': variableValue ? variableValue['value'] : ''}
        });

        if (this.frameRef.nativeElement.contentWindow) {
            this.frameRef.nativeElement.contentWindow.dispatchEvent(event);
        }
    }

    private postCustomMessage(typeArg: string, detail: any) {
        let event = new CustomEvent(typeArg, {detail});
        if (this.frameRef.nativeElement.contentWindow) {
            this.frameRef.nativeElement.contentWindow.dispatchEvent(event);
        }
    }

}
