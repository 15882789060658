export const PRIO_TABLE_CONF = [
    {
        "label": "id",
        "header": "ID",
        "value": "id",
        "type": "string",
        "unit": null,
        "defaultValue": "-",
        "sortable": true,
        "hidden": true,
        "searchable": true,
        "required": true
    },{
        "label": "value",
        "header": "VALUE",
        "value": "value",
        "type": "string",
        "unit": null,
        "defaultValue": "-",
        "sortable": true,
        "hidden": false,
        "searchable": true,
        "required": true
    },{
        "label": "description",
        "header": "DESCRIPTION",
        "value": "description",
        "type": "string",
        "unit": null,
        "defaultValue": "-",
        "sortable": true,
        "hidden": false,
        "searchable": true,
        "required": true
    },{
        "label": "startDate",
        "header": "STARTAFTER",
        "value": "dateRange.startDate",
        "type": "string",
        "unit": null,
        "defaultValue": "-",
        "sortable": true,
        "hidden": false,
        "searchable": true,
        "required": true
    },{
        "label": "endDate",
        "header": "ENDBEFORE",
        "value": "dateRange.endDate",
        "type": "string",
        "unit": null,
        "defaultValue": "-",
        "sortable": true,
        "hidden": false,
        "searchable": true,
        "required": true
    }, {
        "label": "color",
        "header": "COLOR",
        "value": "color",
        "type": "string",
        "unit": "color",
        "defaultValue": "#E5E5E5",
        "sortable": true,
        "hidden": false,
        "searchable": true,
        "required": true
    }
];

export const PRIO_HEADER_COLS = [
    // 'id',
    'value',
    'description',
    'startDate',
    'endDate',
    'color',
    'action'
];
