import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'ft-delete-option',
  template: `
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px" [style.padding.px]="16">
      <button color="primary" tabindex="-1" mat-button [matDialogClose]="'ME'">{{'DELETE_FOR_ME' | translate}}</button>
      <button *ngIf="data.canDeleteForEveryone" color="primary" tabindex="-1" mat-button
              [matDialogClose]="'ALL'">{{'DELETE_FOR_EVERYONE' | translate}}</button>
      <button mat-button [matDialogClose]="null">{{'CANCEL' | translate}}</button>
    </div>`,
  styles: []
})
export class DeleteOptionComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }

}
