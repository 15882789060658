<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-account-plus"></mat-icon>
    <h3 mat-dialog-title>{{'ADD_REF_PHYSICIAN' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<div mat-dialog-content [formGroup]="referringForm">

    <div fxLayout="row" fxLayoutGap="4px">
        <mat-form-field appearance="standard" formGroupName="title">
            <mat-label>{{'TITLE' | translate}}</mat-label>
            <mat-select [placeholder]="'TITLE' | translate" formControlName="id" tabindex="-1">
                <mat-option *ngFor="let title of titles" [value]="title.id">{{title.value}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>{{'LAST_NAME' | translate}}</mat-label>
            <input matInput [placeholder]="'LAST_NAME' | translate" formControlName="lastName" required>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>{{'FIRST_NAME' | translate}}</mat-label>
            <input matInput [placeholder]="'FIRST_NAME' | translate" formControlName="firstName" required>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="4px">
        <mat-form-field appearance="standard" formGroupName="speciality">
            <mat-label>{{'SPECIALITY' | translate}}</mat-label>
            <mat-select [placeholder]="'SPECIALITY' | translate" formControlName="id">
                <mat-option *ngFor="let speciality of specialities" [value]="speciality.id">{{speciality.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>{{'PHONE' | translate}}</mat-label>
            <input matInput [placeholder]="'PHONE' | translate" formControlName="phone">
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>{{'CODE_PRO' | translate}}</mat-label>
            <input [placeholder]="'CODE_PRO' | translate" formControlName="codeAnam" matInput>
        </mat-form-field>
        <mat-form-field appearance="standard" fxFlex>
            <mat-label>{{'EMAIL' | translate}}</mat-label>
            <input matInput [placeholder]="'EMAIL' | translate" formControlName="email">
        </mat-form-field>
    </div>

    <mat-form-field appearance="standard" fxFlexFill>
        <mat-label>{{'ADDRESS' | translate}}</mat-label>
        <textarea matInput [placeholder]="'ADDRESS' | translate" formControlName="address"></textarea>
    </mat-form-field>

</div>

<div mat-dialog-actions align="end">
    <button color="warn" mat-dialog-close mat-raised-button>{{'CANCEL' | translate}}</button>
    <button (click)="save(referringForm.value)" [disabled]="referringForm.invalid" color="primary" mat-raised-button>
        {{'SAVE' | translate}}
    </button>
</div>
