<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" [fontIcon]="'mdi-pencil'"></mat-icon>
    <h3 mat-dialog-title>{{'BOX' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <form [formGroup]="form">

        <mat-form-field appearance="standard" class="full-width">
            <mat-label>{{'NAME' | translate}}</mat-label>
            <input matInput placeholder="{{'NAME' | translate}}" formControlName="name" required>
        </mat-form-field>

        <mat-form-field appearance="standard" class="full-width">
            <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
            <textarea matInput placeholder="{{'DESCRIPTION' | translate}}" formControlName="description"></textarea>
        </mat-form-field>

    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="warn" [mat-dialog-close]="null">{{'CANCEL' | translate}}</button>
    <button mat-button color="primary" (click)="saveData(form.value)" [disabled]="form.invalid">{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>


