<mat-toolbar class="sc-toolbar" color="primary">
  <div class="title">
    <span>{{'VIEWERS' | translate}}</span>
  </div>

  <span fxFlex></span>

  <button [matTooltip]="'ADD_NEW' | translate" mat-button (click)="editViewer(null)">
    <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
  </button>
</mat-toolbar>

<div fxLayout="row" fxLayoutGap="6px">

  <div fxFlex fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="12px" style="padding: 8px;">
    <div fxFlex="48%" *ngFor="let viewer of viewers | async" class="v-card mat-elevation-z2">
      <div class="v-name">{{viewer.name}}</div>
      <div>{{viewer.host}}</div>
      <div>{{viewer.remotePath}}</div>
      <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="4px">
        <button mat-icon-button color="primary" (click)="editViewer(viewer)"><mat-icon class="edit-icon-gradient" fontIcon="mdi-pencil" fontSet="mdi"></mat-icon></button>
        <button mat-icon-button color="warn" (click)="deleteViewer(viewer)"><mat-icon class="delete-icon-gradient" fontIcon="mdi-delete" fontSet="mdi"></mat-icon></button>
      </div>
    </div>
  </div>

  <div  [ngClass]="{'form': true, 'visible': editing}">
    <div [formGroup]="viewerForm" fxLayout="column">

      <mat-form-field appearance="standard">
        <mat-label>{{'NAME' | translate}}</mat-label>
        <input matInput [placeholder]="'NAME' | translate" formControlName="name" required>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>{{'REMOTE_PATH' | translate}}</mat-label>
        <input matInput [placeholder]="'REMOTE_PATH' | translate" formControlName="remotePath">
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>{{'HOST' | translate}}</mat-label>
        <input matInput [placeholder]="'HOST' | translate" formControlName="host">
      </mat-form-field>

      <mat-slide-toggle [style.fontSize.px]="13" formControlName="defaultViewer">{{'DEFAULT_VIEWER' | translate}}</mat-slide-toggle>
      <mat-slide-toggle [style.fontSize.px]="13" formControlName="osirix">{{'OSIRIX' | translate}}</mat-slide-toggle>

      <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="4px">
        <button mat-raised-button color="primary" (click)="saveViewer(viewerForm.value)">{{'SAVE' | translate}}</button>
        <button mat-raised-button color="warn" (click)="discard()">{{'CANCEL' | translate}}</button>
      </div>

    </div>
  </div>
</div>
