export const TBL_CONFS = [
    {
        'label': 'title',
        'header': 'HOLIDAY',
        'value': 'title',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': true,
        'searchable': true,
        'required': true
    }, {
        'label': 'start',
        'header': 'HOLIDAY_START',
        'value': 'start',
        'type': 'date',
        'unit': 'date',
        'defaultValue': '-',
        'sortable': true,
        'hidden': true,
        'searchable': true,
        'required': true
    }, {
        'label': 'end',
        'header': 'HOLIDAY_END',
        'value': 'end',
        'type': 'date',
        'unit': 'date',
        'defaultValue': '-',
        'sortable': true,
        'hidden': true,
        'searchable': true,
        'required': true
    },
];

export const HDR_COLS = [
    'title',
    'start',
    'end',
    'action'
];

