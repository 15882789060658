import {StatisticData} from './statistic-data';


export class ChartData {
    data: number[];
    label: string;
    labels: string[];
    tableData: StatisticData[];
}

export const PIE_CHART_COLORS = [
    {
        backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)',
            'rgba(0,255,255,0.3)', 'rgba(255,0,255,0.3)', 'rgba(255,255,0,0.3)',
            'rgba(255,200,200,0.3)', 'rgba(200,200,255,0.3)', 'rgba(200,250,200,0.3)',
            'rgba(200,100,80,0.3)', 'rgba(100,100,255,0.3)', 'rgba(80,250,200,0.3)'],
    },
];
