<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-database-plus"></mat-icon>
    <h3 mat-dialog-title>{{'AETEDIT' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <form [formGroup]="form">

        <div fxLayout="row" fxLayoutGap="4px">

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'NAME' | translate}}</mat-label>
                <input matInput placeholder="{{'NAME' | translate}}" formControlName="name">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'AETITLE' | translate}}</mat-label>
                <input matInput placeholder="{{'AETITLE' | translate}}" formControlName="title">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'INSTALLER' | translate}}</mat-label>
                <input matInput placeholder="{{'INSTALLER' | translate}}" formControlName="installer">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'MANUFACTURER' | translate}}</mat-label>
                <input matInput placeholder="{{'MANUFACTURER' | translate}}" formControlName="manufacturer">
            </mat-form-field>

        </div>

        <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center" style="align-items: baseline">
            <mat-form-field appearance="standard">
                <mat-label>{{'HOSTNAME' | translate}}</mat-label>
                <input matInput placeholder="{{'HOSTNAME' | translate}}" formControlName="hostname">
            </mat-form-field>
            <button mat-button [matTooltip]="((connected | async) ? 'CONNECTED': 'NOT_CONNECTED') | translate"
                    (click)="testConnection(form.get('hostname').value)">
                <mat-icon [fontIcon]="getIcon(connected | async)"
                          [ngStyle]="{'color': getAetStatusColor(connected | async)}" fontSet="mdi"></mat-icon>
                {{'PING' | translate}}
            </button>
        </div>


        <div>
            <mat-form-field appearance="standard" formGroupName="room" fxFlex>
                <mat-label>{{'ROOM' | translate}}</mat-label>
                <mat-select placeholder="{{'ROOM' | translate}}" formControlName="id" class="full-width" required>
                    <mat-option *ngFor="let item of rooms" [value]="item.id" class="option">
                        {{item.name}}
                    </mat-option>
                </mat-select>
                <button matSuffix mat-icon-button color="accent" (click)="$event.stopImmediatePropagation();addRoom()">
                    <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>
        </div>


        <div fxLayout="row" fxLayoutGap="4px">
            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'PURCHASEDATE' | translate}}</mat-label>
                <input matInput [matDatepicker]="purchase" placeholder="{{'PURCHASEDATE' | translate}}"
                       formControlName="dateOfPurchase">
                <mat-datepicker-toggle matSuffix [for]="purchase"></mat-datepicker-toggle>
                <mat-datepicker #purchase></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'INSTALLATIONDATE' | translate}}</mat-label>
                <input matInput [matDatepicker]="install" placeholder="{{'INSTALLATIONDATE' | translate}}"
                       formControlName="installationDate">
                <mat-datepicker-toggle matSuffix [for]="install"></mat-datepicker-toggle>
                <mat-datepicker #install></mat-datepicker>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="4px">
            <mat-form-field appearance="standard" formGroupName="modality" fxFlex>
                <mat-label>{{'MODALITY' | translate}}</mat-label>
                <mat-select placeholder="{{'MODALITY' | translate}}" formControlName="id" class="full-width input-back"
                            required>
                    <mat-option *ngFor="let item of modalities" [value]="item.id" class="option">
                        {{item.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
                <mat-form-field appearance="standard" formGroupName="defaultTechnician" fxFlex>
                <mat-label>{{'TECHNICIAN' | translate}}</mat-label>
                <mat-select placeholder="{{'TECHNICIAN' | translate}}" formControlName="id">
                    <mat-option *ngFor="let item of technicians" [value]="item.id" class="option">
                        {{item.firstName}} {{item.lastName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <div>
            {{'COLOR' | translate}}: <span class="color-area" [(colorPicker)]="color"
                                           [style.background]="color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>

        <div fxLayout="row" class="space-top">
            <mat-slide-toggle fxFlex formControlName="hasMPPS"
                              class="example-margin">
                {{'HAS_MPPS' | translate}}
            </mat-slide-toggle>
            <mat-slide-toggle fxFlex formControlName="enabled"
                              class="example-margin"
                              [color]="'primary'">
                {{'AUTHORIZED' | translate}}
            </mat-slide-toggle>
            <mat-slide-toggle fxFlex formControlName="setAsDefault"
                              class="example-margin"
                              [color]="'primary'">
                {{'SETASDEFAULT' | translate}}
            </mat-slide-toggle>
        </div>
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button [mat-dialog-close]="null" color="accent" mat-raised-button>{{'CANCEL' | translate}}</button>
    <button (click)="onSave(form.value)" [disabled]="form.invalid" color="primary" mat-raised-button>{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>
