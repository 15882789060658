import {forkJoin, Subscription} from 'rxjs';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {SchedulerService} from './scheduler.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import {AVSC, GeneralSetting, Profile, QueryParam} from '../model';
import {SettingService} from '../setting/setting.service';
import {TranslateService} from '@ngx-translate/core';
import {AvailabilityCheckComponent} from './availability-check/availability-check.component';
import {OrderEditComponent} from './order-edit/order-edit.component';
import {PacsSearchComponent, SharedService} from '../shared';
import {WsService} from '../ws.service';
import {ExamAdvancedComponent} from './exam-advanced/exam-advanced.component';
import {AppointmentEditComponent} from "./appointment-edit/appointment-edit.component";

@Component({
    selector: 'app-scheduler',
    templateUrl: './scheduler.component.html',
    styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit, OnDestroy {

    examStatuses: string[] = [];


    generalSetting: GeneralSetting;

    profile: Profile;
    spsStatuses: any[];
    aets: any[];
    agendaActive: boolean = true;
    defaultView: string = '/schedule-manager';

    resourceFilterVisible: boolean = false;
    reasonForExams: any[];


    private username: string;
    private canViewConfData: any;
    private schedulerView: string;

    subs: Subscription[] = [];

    search: boolean;

    hover: boolean;

    can = (row: any, action: string): boolean => (this.profile && this.profile[action] !== 'NONE') && !row.confidential || this.canViewConfData;

    searchAvailability() {
        const dialogRef = this.dialog.open(AvailabilityCheckComponent);
        const instance = dialogRef.componentInstance;

        instance.avsc = new AVSC();

        this.subs.push(dialogRef
            .afterClosed()
            .subscribe(res => {
                if (res) {
                    let dialogRef = this.dialog.open(OrderEditComponent);
                    dialogRef.componentInstance.selectedDateTime = {
                        requestedDate: new Date(moment(res.date).format()),
                        requestedTime: res.start
                    };
                    dialogRef
                        .afterClosed()
                        .subscribe(res => this.createOrder(res));
                }
            }));
    }

    constructor(private service: SchedulerService,
                private wsService: WsService,
                private sharedService: SharedService,
                private setting: SettingService,
                private translate: TranslateService,
                private dialog: MatDialog,
                private router: Router,
                private route: ActivatedRoute,
                public snackBar: MatSnackBar) {
        let user = JSON.parse(localStorage.getItem('user'));
        this.profile = user.profile;
        this.username = user.username;
        this.canViewConfData = user.canViewConfidentialData;

        ({setting: this.generalSetting} = this.route.snapshot.data);
        localStorage.setItem('eid_api_url', this.generalSetting.scannerIdUrl);
        this.getDefaultView(this.generalSetting);
        this.subs.push(this.translate.get('EXAM_STATUSES').subscribe(res => this.examStatuses = res.split('_')));
        this.subs.push(this.service.broadcast.subscribe(viewType => this.resourceFilterVisible = viewType === 'resourceTimeGridDay'));
    }

    createExamWithPayment(queryParam: QueryParam = null) {
        this.dialog.open(ExamAdvancedComponent, {
            data: {
                spsStatus: 'ARRIVED',
                resource: 'n/a',
                patient: null,
                isr: null,
                selectedDateRange: {start: moment(), end: moment().add(10, 'm')},
                editable: true,
                queryParam: queryParam,
                panelClass: 'exam-dialog'
            },
            disableClose: true
        }).afterClosed().subscribe(res => {
            if (res && res['isrId']) this.service
                .finishExam(res.isrId)
                .subscribe();
        });
    }

    addOrder() {
        this.subs.push(this.dialog
            .open(AppointmentEditComponent, {data: {patient: null, isr: null}, disableClose: true})
            .afterClosed()
            .subscribe(/*res => this.createOrder(res)*/));
    }

    ngOnInit() {
        if (['EXTERNAL_APPOINTMENTS', 'CALENDAR'].includes(this.schedulerView)) this.router.navigateByUrl(this.defaultView);

        this.subs.push(forkJoin(
            [
                this.sharedService.getAetList(),
                this.sharedService.getSPSStatuses(),
                this.setting.getGeneralSetting(),
                this.sharedService.getReasonForExams()
            ]
        ).subscribe(data => {
            [this.aets, this.spsStatuses] = data;

            this.reasonForExams = data[3];

            localStorage.setItem('generalSetting', JSON.stringify(data[2]));
        }));
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    private createOrder(isr: any) {
        if (isr) {
            this.subs.push(this.service.createNewISR(isr).subscribe(_ => this.snackBar.open(this.examStatuses[5], 'Ok', {duration: 2000})));
        }
    }

    private getDefaultView(setting: GeneralSetting) {
        this.schedulerView = setting.schedulerDefaultView;
        switch (setting.schedulerDefaultView) {
            case 'EXTERNAL_APPOINTMENTS':
                this.defaultView = '/scheduler/external-appointments';
                this.agendaActive = false;
                break;
            case 'CALENDAR':
                this.defaultView = '/scheduler/calendar';
                break;
            case 'SCHEDULER':
                this.defaultView = '/scheduler/schedule-manager';
                this.agendaActive = false;
                break;
        }
    }

    queryPacs() {
        this.dialog.open(PacsSearchComponent, {
            data: '',
            height: '90vh',
            minWidth: '90vw',
            disableClose: true
        }).afterClosed().subscribe(res => {
            if (res && res.key === 'new') this.createExamWithPayment(res.data)
        })
    }
}
