import {BehaviorSubject, merge as observableMerge, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {DataSource} from '@angular/cdk/collections';
import {DeleteConfirmComponent, deleteItemFromArray, SharedService} from '../../shared';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {SettingService} from '../setting.service';
import {ROOM_HEADER_COLS, ROOM_TABLE_CONF} from './table-conf';
import {RoomEditComponent} from './room-edit/room-edit.component';

@Component({
    selector: 'app-room-setting',
    templateUrl: './room-setting.component.html',
    styleUrls: ['./room-setting.component.scss']
})
export class RoomSettingComponent implements OnInit {

    cols: any[];
    displayedColumns = [];

    dataSource: RoomDataSource | null;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private settingService: SettingService, private sharedService: SharedService, public dialog: MatDialog) {
        this.sharedService.getRooms().subscribe(data => this.dataSource.dataChange.next(data));
    }

    editRoom(code) {
        const dialogRef = this.dialog.open(RoomEditComponent);
        const instance = dialogRef.componentInstance;
        instance.selectedItem = code;

        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                if (code.id) deleteItemFromArray(this.dataSource.data, code);
                this.dataSource.data.push(res);
                this.dataSource.dataChange.next(this.dataSource.data);
            }
        });
    }

    deleteRoom(code) {
        const dialogRef = this.dialog.open(DeleteConfirmComponent);
        dialogRef.afterClosed().subscribe(ok => {
            if (ok) {
                this.settingService.deleteRoom(code.id).subscribe(res => {
                    deleteItemFromArray(this.dataSource.data, code);
                    this.dataSource.dataChange.next(this.dataSource.data);
                });
            }
        });
    }

    ngOnInit() {

        this.displayedColumns = ROOM_TABLE_CONF;
        this.cols = ROOM_HEADER_COLS;

        this.dataSource = new RoomDataSource(this.paginator, this.sort);
    }


}


export class RoomDataSource extends DataSource<any> {

    filteredData: Room[] = [];
    renderedData: Room[] = [];

    /** Stream that emits whenever the data has been modified. */
    dataChange: BehaviorSubject<Room[]> = new BehaviorSubject<Room[]>([]);

    constructor(private _paginator: MatPaginator, private _sort: MatSort) {
        super();
    }

    get data(): Room[] {
        return this.dataChange.value;
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<Room[]> {
        const displayDataChanges = [
            this.dataChange,
            this._paginator.page,
            this._sort.sortChange,
        ];

        return observableMerge(...displayDataChanges).pipe(map(() => {


            // Filter data
            this.filteredData = this.data.slice().filter((item: Room) => {
                return true;
            });

            // Sort filtered data
            const sortedData = this.sortData(this.filteredData.slice());

            // Grab the page's slice of the filtered sorted data.
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
            return this.renderedData;
        }));
    }

    disconnect() {
    }

    /** Returns a sorted copy of the database data. */
    sortData(data: Room[]): Room[] {
        if (!this._sort.active || this._sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._sort.active) {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'name':
                    [propertyA, propertyB] = [a.name, b.name];
                    break;
                case 'examType':
                    [propertyA, propertyB] = [a.examType, b.examType];
                    break;
            }

            let valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            let valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }


}


export interface Room {
    id: number;
    name: string;
    examType: string;
}