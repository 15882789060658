import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Location} from '@angular/common';
import {ContractField, StaffContract} from '../../../model';
import {SharedService} from '../../../shared';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {assign, flatMap, groupBy} from 'lodash';
import {Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/operators';
import {ArrayUtils} from '../../../utils';
import {AppConfigService} from "../../../app-config.service";

class ContractItem {
    constructor(public name: string, public children?: ContractItem[], public level: number = 0) {
    }
}

@Component({
    selector: 'app-edit-staff-contract',
    templateUrl: './edit-staff-contract.component.html',
    styleUrls: ['./edit-staff-contract.component.scss']
})
export class EditStaffContractComponent implements OnInit {

    form: FormGroup;
    feeTypes = ['MONTHLY', 'DAILY', 'EXAM'];
    calculationBases = ['EXAM_PRICE', 'PAID_AMOUNT'];
    partTypes = ['AMOUNT', 'PERCENTAGE'];
    partType: any;
    calculationBase: string;

    treeControl = new NestedTreeControl<ContractItem>(node => node.children);
    dataSource = new MatTreeNestedDataSource<ContractItem>();

    examTypeSelectionChange = new Subject<ContractItem[]>();
    procedureCodeSelectionChange = new Subject<ContractItem[]>();
    examTypes = [];

    procedureCodes = [];

    selection = false;

    currencyFormat = 'DH';
    examTypeFormArray: FormArray;

    procedureCodeFormArray: FormArray;

    @ViewChild('name', { static: true }) name: ElementRef;

    hasChild = (_: number, node: ContractItem) => node.children && node.children.length > 0;

    constructor(private service: SharedService,
                private location: Location,
                private route: ActivatedRoute,
                private _config: AppConfigService,
                private fb: FormBuilder) {
        this.currencyFormat = this._config.currencyFormat;
        this.createForm();

        this.service.getProcedureCodes().subscribe(codes => {
            let data = [];
            let exams = groupBy(codes, 'reasonForExam.value');

            Object.keys(exams).forEach((key) => data.push(new ContractItem(key, exams[key].map(v => new ContractItem(v.description, [], 1)))));

            this.dataSource.data = data;
        });

        this.examTypeSelectionChange.subscribe(selection => {
            let items = selection.map(field => new ContractField(field.name));
            items.forEach(c => this.addExamTypeItem(c));

            this.rebuildExamTypeFormArray(items);
        });

        this.procedureCodeSelectionChange.subscribe(selection => {
            let items = selection.filter(it => it !== undefined).map(field => new ContractField(field.name));
            items.forEach(c => this.addProcedureCodeItem(c));

            this.rebuildProcedureCodeFormArray(items);
        });
    }

    ngOnInit(): void {
        this.name.nativeElement.focus();

        this.route.queryParams
            .pipe(filter(params => params['id']))
            .subscribe(param => {
                this.service.getStaffContract(+param['id']).subscribe(res => {
                    this.form.patchValue(res);

                    setTimeout(() => {
                        res.examTypes.forEach(c => {
                            this.addExamTypeItem(c);
                            this.examTypes.push(this.dataSource.data.find(d => d.name === c.name));
                        });
                        res.procedureCodes.forEach(c => {
                            this.addProcedureCodeItem(c);
                            this.procedureCodes.push(flatMap(this.dataSource.data, c => c.children).find(d => d.name === c.name))
                        });
                    }, 500);
                })
            });
    }

    rebuildExamTypeFormArray(items: ContractField[]) {
        this.examTypeFormArray = this.form.get('examTypes') as FormArray;
        let controls = this.examTypeFormArray.controls;

        let fields = items.map(value => value.name);
        controls.forEach(ctrl => {
            if (!fields.includes(ctrl.get('name').value)) {
                controls.splice(controls.indexOf(ctrl), 1)
            }
        });
    }

    rebuildProcedureCodeFormArray(items: ContractField[]) {
        this.procedureCodeFormArray = this.form.get('procedureCodes') as FormArray;
        let controls = this.procedureCodeFormArray.controls;

        let fields = items.map(value => value.name);
        controls.forEach(ctrl => {
            if (!fields.includes(ctrl.get('name').value)) {
                controls.splice(controls.indexOf(ctrl), 1)
            }
        });
    }

    addExamTypeItem(c: ContractField): void {
        this.examTypeFormArray = this.form.get('examTypes') as FormArray;

        if (this.examTypeFormArray.controls.find(ctrl => ctrl.get('name').value === c.name)) return;
        let contractForm = this.createContractFieldForm();
        contractForm.patchValue(c);
        this.examTypeFormArray.push(contractForm);
    }

    addProcedureCodeItem(c: ContractField): void {
        this.procedureCodeFormArray = this.form.get('procedureCodes') as FormArray;

        if (this.procedureCodeFormArray.controls.find(ctrl => ctrl.get('name').value === c.name)) return;
        let contractForm = this.createContractFieldForm();
        contractForm.patchValue(c);
        this.procedureCodeFormArray.push(contractForm);
    }

    createContractFieldForm = (): FormGroup => this.fb.group(new ContractField());

    onSave(value) {
        this.service.createStaffContract(value).subscribe(res => {
            if (res) this.location.back();
        });
    }

    private createForm = () =>
        this.form = this.fb.group(assign(new StaffContract(), {
            examTypes: this.fb.array([]),
            procedureCodes: this.fb.array([])
        }));

    selectProcedureCode(node: any) {
        ArrayUtils.updateItemInArray(node, this.procedureCodes);
        this.procedureCodeSelectionChange.next(this.procedureCodes);
    }

    selectExamType(node: any) {
        ArrayUtils.updateItemInArray(node, this.examTypes);
        this.examTypeSelectionChange.next(this.examTypes);
    }
    procedureCodeSelected = (node: any): boolean => this.procedureCodes.includes(node);
    examTypeSelected = (node: ContractItem): boolean => this.examTypes.includes(node);

    examTypeChildrenPartial = (node: ContractItem): boolean => node.children.some(value => this.procedureCodes.includes(value));
    cancel = () => this.location.back();
}
