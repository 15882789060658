import { Component, OnInit } from '@angular/core';
import {SettingService} from '../setting.service';
import {Observable} from 'rxjs';
import {Viewer} from '../../model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {assign} from 'lodash';

@Component({
  selector: 'app-viewers',
  templateUrl: './viewers.component.html',
  styleUrls: ['./viewers.component.scss']
})
export class ViewersComponent implements OnInit {
  viewers: Observable<Viewer[]>;
  viewerForm: FormGroup;
  editing: boolean;

  constructor(private service: SettingService, private fb: FormBuilder) {
    this.viewerForm = this.fb.group(assign(new Viewer(), {name: ['', Validators.required]}));
  }

  ngOnInit() {
    this.viewers = this.service.getViewers();
  }

  saveViewer(value: Viewer) {
    this.service.createViewer(value).subscribe(res => {
      if (res) {
        this.resetForm();
        this.viewers = this.service.getViewers();
      }
    });
  }

  private resetForm() {
    this.viewerForm.patchValue(new Viewer());
  }

  discard() {
    this.editing = false;
    this.resetForm();
  }

  editViewer(viewer: Viewer) {
    this.editing = true;
    this.viewerForm.patchValue(viewer || new Viewer());
  }

  deleteViewer(viewer: Viewer) {
    this.editing = false;
    this.service.deleteViewer(viewer).subscribe(value => {
      if (value) this.viewers = this.service.getViewers();
    })
  }
}
