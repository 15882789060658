import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';


import {
    CommandService,
    Dictation,
    EmailMessage,
    Instance, PatientStudy,
    Report,
    ReportingTask,
    ReportParagraph,
    SRContentItem
} from '../model';
import {SortDirection} from '@angular/material/sort';
import {
    MAIL_URL,
    OFFICE_COAUTHORING_URL,
    OFFICE_URL,
    PACS_URL,
    PRINTER_URL,
    REPORTING_URL,
    SHARED_URL,
    WORKFLOW_URL
} from '../urls';
import {map, tap} from "rxjs/operators";
import {printBlob} from "../utils";


@Injectable()
export class ReportingService {

    public keyboardEventSubject = new Subject<KeyboardEvent>();
    reportSearch = new Subject<{ report: ReportingTask, waiting: boolean }>();
    variableInsert = new Subject<string>();

    constructor(private http: HttpClient) {
    }

    getReportHistory = (id: string): Observable<any> => this.http.get(`${OFFICE_URL}/history`, {params: {id}});

    getInstance = (iuid: string): Observable<Blob> => this.http.get(`${PACS_URL}/instance/ext/${iuid}`, {responseType: 'blob'});

    getPatientInfo(patientID: string): Observable<any> {
        let params = {patientID};
        return this.http.get(`${REPORTING_URL}/patientReportInfo`, {params});
    }

    getReportingTask = (id: number): Observable<ReportingTask> => this.http.get<ReportingTask>(`${REPORTING_URL}/reportingTask/${id}`);

    saveDictation = (dictation: Dictation): Observable<Dictation> => this.http.post<Dictation>(REPORTING_URL + '/saveDictation', dictation);

    getStudyInstances = (siuid: string): Observable<Instance[]> => this.http.get<Instance[]>(`${PACS_URL}/study/ext/${siuid}`);

    getStudySeries = (studyInstanceUID: string): Observable<any> =>
        this.http.get(`${REPORTING_URL}/study`, {params: new HttpParams().set('studyInstanceUID', studyInstanceUID)});

    toTranscribe = (reportingTask: ReportingTask): Observable<ReportingTask> => this.http.post<ReportingTask>(REPORTING_URL + '/toTranscribe', reportingTask)

    toValidate = (reportingTask: ReportingTask): Observable<ReportingTask> => this.http.post<ReportingTask>(REPORTING_URL + '/toValidate', reportingTask)

    toReview = (reportingTask: ReportingTask): Observable<ReportingTask> => this.http.post<ReportingTask>(REPORTING_URL + '/toReview', reportingTask)

    toSign = (reportingTask: ReportingTask): Observable<ReportingTask> => this.http.post<ReportingTask>(REPORTING_URL + '/toSign', reportingTask)


    printSimpleReport(reportingTaskId: number, printer: string, count: any): Observable<any> {
        let params = {reportingTaskId: String(reportingTaskId), printer, count};
        return this.http.get(`${PRINTER_URL}/printSimpleReportChrome`, {
            params,
            responseType: 'blob'
        }).pipe(tap(blob => printBlob(blob)));
    }

    printCupsSimpleReport(reportingTaskId: number, printer: string, count: any): Observable<any> {
        let params = {reportingTaskId: String(reportingTaskId), printer, count, printingMode: 'CUPS'};
        return this.http.get<any>(`${PRINTER_URL}/printSimpleReport`, {params});
    }

    printReport(id: number): Observable<any> {
        return this.http.get(`${PRINTER_URL}/printReportChrome/${id}`, {responseType: 'blob'}).pipe(tap(blob => printBlob(blob)));
    }

    printCupsReport(id: number, printer: string, count: any, printingMode: 'CUPS' | 'CHROME' = 'CUPS'): Observable<any> {
        let params = {printer, count, printingMode};
        return this.http.get(`${PRINTER_URL}/printReport/${id}`, {params});
    }

    getSPSByInstances(siuid: string): Observable<any> {
        let params = {siuid};
        return this.http.get(`${REPORTING_URL}/sps-by-siuid`, {params});
    }

    deleteReportingTask = (id): Observable<any> => this.http.get(`${REPORTING_URL}/deleteReportingTask`, {params: {id: String(id)}});

    saveReportingTask = (reportingTask: ReportingTask): Observable<ReportingTask> => this.http.post<ReportingTask>(`${REPORTING_URL}/saveReportingTask`, reportingTask);

    sendMail = (mail: EmailMessage, id: number): Observable<any> => this.http.post(`${MAIL_URL}/send/${id}`, mail);

    validateReport = (reportingTask: ReportingTask): Observable<ReportingTask> => this.http.post<ReportingTask>(`${REPORTING_URL}/validateReport`, reportingTask);

    getReportTasks(pageSize: number, pageIndex: number, sort: string, direction: SortDirection, taskType: string, key: string): Observable<any> {

        let params = {
            page: String(pageIndex),
            size: String(pageSize),
            sort: `${sort},${direction}`,
            taskType, key
        };

        return this.http.get(`${REPORTING_URL}/all-tasks`, {params});
    }

    signReport = (reportingTask: ReportingTask): Observable<ReportingTask> => this.http.post<ReportingTask>(`${REPORTING_URL}/signReport`, reportingTask);

    openWeasis = (studyInstanceUID: string): Observable<boolean> => this.http.get<boolean>(`${PACS_URL}/study/viewer/${studyInstanceUID}`);

    printReportingTask = (id: number): Observable<Blob> => this.http.get(`${REPORTING_URL}/print-report/${id}`, {responseType: 'blob'});

    printPatientReport = (report: Report): Observable<boolean> => this.http.post<boolean>(`${PRINTER_URL}/printPatientReport`, report);

    openOsirix = (studyInstanceUID: string, viewer: string): Observable<any> => this.http.get(`${PACS_URL}/study/osirix-viewer/${viewer}/${studyInstanceUID}`);

    getAllPatientReportingTasks = (patientID: string): Observable<ReportingTask[]> => this.http.get<ReportingTask[]>(`${REPORTING_URL}/patient-reporting-tasks`, {params: new HttpParams().set('patientID', patientID)});

    printCompletedReport = (id: any, printer: string, count: string, printingMode: 'CHROME' | 'CUPS'): Observable<any> => this.http.get<any>(`${PRINTER_URL}/printCompletedReport/${id}`, {
        params: {
            printer,
            count,
            printingMode
        }
    });

    sendMultipleEmails(ids: number[]): Observable<any> {
        let params = {ids: ids.join('-')};
        return this.http.get(`${MAIL_URL}/send/multiple`, {params});
    }

    getSeriesInstances = (seriesInstanceUID: string): Observable<any> =>
        this.http.get(`${REPORTING_URL}/instances`, {params: new HttpParams().set('seriesInstanceUID', seriesInstanceUID)});


    launchApp = (appName: string, accessionNumber: string) =>
        this.http.get(`${SHARED_URL}/launchApp`, {params: {appName, accessionNumber}});

    launchOsirix = (aeTitle: string, patientID: string, studyInstanceUID: string) =>
        this.http.get(`${SHARED_URL}/launchOsirix`, {params: {aeTitle, patientID, studyInstanceUID}});

    getSeriesImages(seriesPath: string, patientID: string): Observable<any> {
        let params = {seriesPath, patientID};
        return this.http.get(`${PACS_URL}/series-images`, {params})
    }

    getStudiesSeries(studyInstanceUID: string, patientID: string): Observable<any> {
        let params = {studyInstanceUID, patientID};
        return this.http.get(`${PACS_URL}/series`, {params})
    }

    retrieveStudy(studyInstanceUID: string) {
        let params = {studyInstanceUID};
        return this.http.get(`${PACS_URL}/retrieve`, {params});
    }

    isImagesAvailable(patientID: string, studyInstanceUID: string): Observable<boolean> {
        let params = {patientID, studyInstanceUID};
        return this.http.get<boolean>(`${PACS_URL}/images-available`, {params});
    }

    downloadPdf(reportingTaskId: number, printer: string, count: any, printingMode: 'CHROME' | 'CUPS'): Observable<any> {
        let params = {reportingTaskId: String(reportingTaskId), printer, count, printingMode};
        return this.http.get<any>(`${PRINTER_URL}/downloadSimpleReport`, {params});
    }

    getReportingTaskByCompletedReportId(id: number): Observable<ReportingTask> {
        return this.http.get<ReportingTask>(`${REPORTING_URL}/reportingTaskByCRID`, {params: {id: String(id)}});
    }

    deliverCompletedReport(id: any): Observable<boolean> {
        return this.http.get<boolean>(`${REPORTING_URL}/deliverReport`, {params: {id}});
    }

    saveNoteAlert(row: any): Observable<any> {
        return this.http.post(`${REPORTING_URL}/saveNoteAlert`, row);
    }

    getDictationTrack(uuid: any): Observable<Blob> {
        return this.http.get<Blob>(`${REPORTING_URL}/track`, {params: {uuid}, responseType: 'blob' as 'json'});
    }

    deleteDictation(id: any): Observable<boolean> {
        return this.http.get<boolean>(`${REPORTING_URL}/deleteDictation`, {params: {id}});
    }

    openReport(report: ReportingTask, waiting: boolean = false) {
        this.reportSearch.next({report, waiting})
    }

    searchReportTasks(pageSize: number, pageIndex: number, sort: string, direction: SortDirection, key: string): Observable<any> {

        let params = {
            page: String(pageIndex),
            size: String(pageSize),
            sort: `${sort},${direction}`,
            key
        };

        return this.http.get(`${WORKFLOW_URL}/search-tasks`, {params});
    }

    loadSRFile(studyInstanceUID: string): Observable<SRContentItem> {
        return this.http.get<SRContentItem>(`${PACS_URL}/dicom-sr`, {params: {studyInstanceUID}});
    }

    openStudy(studyInstanceUID: string, username: any, replace: any): Observable<any> {
        return this.http.get(`${PACS_URL}/openStudy`, {params: {studyInstanceUID, username, replace}});
    }

    closeViewerStudy(studyInstanceUID: string): Observable<any> {
        return this.http.get(`${PACS_URL}/closeStudy`, {params: {studyInstanceUID}});
    }

    getFileModel(reportId: string, templateModelId: string, defaultSource: string, uid?: string, uname?: string, actionLink?: string): Observable<any> {
        return this.http.get(`${OFFICE_URL}/files`, {
            params: {
                reportId,
                templateModelId,
                defaultSource,
                uid,
                uname,
                actionLink
            }
        });
    }

    sendCmd(cmd: CommandService): Observable<any> {
        cmd.userdata = JSON.parse(localStorage.getItem('user')).id;
        return this.http.post(OFFICE_COAUTHORING_URL, cmd);
    }

    getReportHistoryData(version: any): Observable<any> {
        return this.http.get(`${OFFICE_URL}/history-data`, {params: {version}});
    }

    restoreVersion(reportId: any, version: any): Observable<any> {
        return this.http.get(`${OFFICE_URL}/restore-version`, {params: {reportId, version}});
    }

    getQrCode(studyInstanceUID: string): Observable<any> {
        return this.http.get(`${PACS_URL}/qr-code`, {params: {studyInstanceUID}}).pipe(map(res => res ? res[0] : null));
    }

    cleanAndRetrieveStudy(studyInstanceUID: string, patientID: string): Observable<any> {
        let params = {studyInstanceUID, patientID};
        return this.http.get(`${PACS_URL}/cleanAndRetrieve`, {params});
    }

    saveReportParagraph(paragraph: ReportParagraph): Observable<any> {
        return this.http.post(`${REPORTING_URL}/create-paragraph`, paragraph);
    }

    searchParagraphs(key: string, procedureCode: string, procedureType: string, pageSize: number, pageIndex: number, sort: string, direction: SortDirection): Observable<any> {
        let params = {
            page: String(pageIndex),
            size: String(pageSize),
            sort: `${sort},${direction}`,
            key, procedureCode, procedureType
        };

        return this.http.get(`${REPORTING_URL}/report-paragraphs`, {params});
    }

    deleteParagraph(id: number): Observable<any> {
        return this.http.delete(`${REPORTING_URL}/delete-paragraph/${id}`);
    }

    getReportText(reportingTaskId: number): Observable<any> {
        let params = {reportingTaskId};
        return this.http.get(`${REPORTING_URL}/getReportText`, {params});
    }

    getPatientStudies(patientID: string): Observable<PatientStudy[]> {
        return this.http.get<PatientStudy[]>(`${WORKFLOW_URL}/getPatientStudies`, {params:{patientID}});
    }
}
