import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import * as Hammer from 'hammerjs';
import {ToolService} from "./tool.service";
import {TOOL_MODE_FUNCTIONS} from "../model";

declare var cornerstoneTools: any;
declare const cornerstone: any;
declare const cornerstoneWebImageLoader: any;
declare const cornerstoneMath: any;

@Directive({
    selector: '[viewport]',
    host: {
        unselectable: 'on',
        onmousedown: 'return false;',
        oncontextmenu: 'return false',
        onselectstart: 'return false;',
    }
})
export class ViewportDirective implements OnInit, OnDestroy, OnChanges {
    @Input('viewport') public image: any;
    @Input('synchronized') public synchronized: boolean;
    @Input('images') public images: any;

    public readonly host_origin = location.origin;

    private element: any;

    constructor(private el: ElementRef, private _toolService: ToolService) {
        cornerstoneWebImageLoader.external.cornerstone = cornerstone;
        cornerstone.registerImageLoader('http', cornerstoneWebImageLoader.loadImage);
        cornerstone.registerImageLoader('https', cornerstoneWebImageLoader.loadImage);

        cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
        cornerstoneTools.external.cornerstone = cornerstone;
        cornerstoneTools.external.Hammer = Hammer;

        cornerstoneTools.init({showSVGCursors: true, globalToolSyncEnabled: true});

        cornerstoneTools.toolColors.setActiveColor('rgb(0,248,103)');

        this._toolService.toolChanged.subscribe(_=>this._initCornerstoneTools())
    }

    ngOnChanges(changes: SimpleChanges) {
        this._synchronize(changes['synchronized'].currentValue);
    }

    ngOnInit() {
        this.element = this.el.nativeElement;
        cornerstone.enable(this.element);

        this.element.tabIndex = 0;
        this.element.focus();

        const stack = {
            currentImageIdIndex: 0,
            imageIds: this.images?.map(it => `${this.host_origin}${it.path}`)
        }

        cornerstone.loadImage(`${this.host_origin}${this.image.path}`).then((image: any) => {
            cornerstone.displayImage(this.element, image);
            cornerstone.resize(this.element, true);
            this._initCornerstoneTools();
            if (this.synchronized) this._synchronize();


            cornerstoneTools.addStackStateManager(this.element, ['stack'])
            cornerstoneTools.addToolState(this.element, 'stack', stack)
        });
    }

    ngOnDestroy() {
        cornerstone.disable(this.element);
    }

    private _synchronize(is_sync: boolean = false) {
        if (is_sync) {
            this._toolService.synchronizer.add(this.element);
            this._toolService.wwwcSynchronizer.add(this.element);
            this._toolService.panZoomSynchronizer.add(this.element);
        } else {
            this._toolService.synchronizer.remove(this.element);
            this._toolService.wwwcSynchronizer.remove(this.element);
            this._toolService.panZoomSynchronizer.remove(this.element);
        }
    }

    private _initCornerstoneTools() {
        this._toolService.availableTools.forEach(tool => {
            cornerstoneTools.addToolForElement(this.element, tool.class, {configuration: tool.configuration || {}});
            if (tool.mode) {
                const setToolActiveModeFn = TOOL_MODE_FUNCTIONS[tool.mode];
                setToolActiveModeFn(this.element, tool.name, tool.options || {});
            }
        });
    }
}
