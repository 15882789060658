import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SharedService} from "../shared.service";
import {FormControl, Validators} from "@angular/forms";
import * as moment from 'moment';

@Component({
    selector: 'ft-sms-sender',
    templateUrl: './sms-sender.component.html',
    styleUrls: ['./sms-sender.component.scss']
})
export class SmsSenderComponent implements OnInit {

    public destinationControl = new FormControl('', [Validators.minLength(10), Validators.pattern(/^(\+212|0)([1-9]\d*)?$/)])
    public messageControl = new FormControl('', [Validators.required])
    public templateControl = new FormControl('');
    public messageError: string;

    public smsTemplates = [
        {title: 'Rapport disponible', body: 'Bonjour PATIENT_NAME, votre compte rendu EXAM est disponible maintenant.'},
        {title: 'RDV planifié', body: 'Bonjour PATIENT_NAME, votre RDV pour EXAM a été planifié pour DATE.'},
    ]

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private _dRef: MatDialogRef<SmsSenderComponent>,
                private _snack: MatSnackBar,
                private _service: SharedService,) {
    }

    ngOnInit(): void {
        this.destinationControl.patchValue(this.data.patientPhoneNumber);
        this._service.getSmsTemplates().subscribe(res => {
            this.smsTemplates = res;
            let selectedModel = res.filter(it => it.isDefault)![0] || res[0];
            this.templateControl.patchValue(selectedModel.title);
            this.buildModel(selectedModel);
        });
    }

    sendSms() {
        this._service.sendSms(this.data.id, this.destinationControl.value, this.messageControl.value, this.data.source).subscribe(res => {
            if (res && res.error == '') {
                this.messageError = null;
                this._dRef.close(true);
            } else this.messageError = "Error: SMS non envoyé"
        })
    }

    onSelectTemplate(ev: any) {
        let template = this.smsTemplates.find(it => it.title == ev.value);
        this.buildModel(template);
    }

    private buildModel(template: any) {
        let sms = template.body
            .replace("PATIENT_NAME", this.data.patientName)
            .replace('EXAM', this.data.procedureCode)
            .replace('DATE', moment(this.data.appointmentDateTime).format('DD/MM/YYYY HH:mm'))
        this.messageControl.patchValue(sms);
    }
}
