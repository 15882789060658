import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SettingService} from "../../setting.service";
import {MatDialogRef} from "@angular/material/dialog";
import * as _ from 'lodash';

@Component({
    selector: 'app-priority-edit',
    templateUrl: './priority-edit.component.html',
    styleUrls: ['./priority-edit.component.scss']
})
export class PriorityEditComponent implements OnInit {

    selectedPriority: any;
    form: FormGroup;
    color: string = '#E4E4E4';

    constructor(private fb: FormBuilder,
                private settingService: SettingService,
                private dialogRef: MatDialogRef<PriorityEditComponent>) {

        this.form = this.fb.group({
            id: '',
            value: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
            description: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(160)])],
            dateRange: this.fb.group({
                id: '',
                startDate: '',
                endDate: ''
            })
        });

    }

    onSave(priority) {

        _.assign(priority, {
            color: this.color
        });

        this.settingService
            .savePriority(priority)
            .subscribe(res => {

                _.assign(res, {
                    startDate: res.dateRange.startDate,
                    endDate: res.dateRange.endDate
                });

                this.dialogRef.close(res);
            });
    }

    ngOnInit() {
        this.form.patchValue(this.selectedPriority);
        this.color = this.selectedPriority.color;
    }

}
