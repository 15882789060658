<mat-toolbar class="sc-toolbar" color="primary">
    <mat-icon fontIcon="mdi-file-table" fontSet="mdi"></mat-icon>
    {{'STAFF_CONTRACT' | translate}}

    <span fxFlex></span>
    <button mat-button class="btn-top" style="color: #FFFFFF;" (click)="selection = !selection">
        <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>{{'ADD_EXCEPTION' | translate}}
    </button>
</mat-toolbar>


<div fxLayout="row" fxLayoutGap="6px">
    <div style="padding: 12px; height: calc(100vh - 100px);" fxFlex [formGroup]="form" fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayout="row wrap" fxLayoutGap="4px">
            <mat-form-field appearance="standard">
                <mat-label>{{'NAME' | translate}}</mat-label>
                <input matInput [placeholder]="'NAME' | translate" formControlName="name" #name>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>{{'FEE_TYPE' | translate}}</mat-label>
                <mat-select placeholder="{{'FEE_TYPE' | translate}}" formControlName="feeType">
                    <mat-option *ngFor="let item of feeTypes" [value]="item">
                        {{item | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" *ngIf="form.get('feeType').value !== 'EXAM'">
                <mat-label>{{'FEE' | translate}}</mat-label>
                <input type="number" matInput [placeholder]="'FEE' | translate" formControlName="fee">
            </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="form.get('feeType').value === 'EXAM'">
            <mat-form-field appearance="standard">
                <mat-label>{{'PART_TYPE' | translate}}</mat-label>
                <mat-select placeholder="{{'PART_TYPE' | translate}}" formControlName="partType">
                    <mat-option *ngFor="let option of partTypes" [value]="option">
                        {{ option.startsWith('AMOUNT') ? (option | translate: {currency: currencyFormat}) : (option | translate) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!--if part amount-->
            <mat-form-field appearance="standard" *ngIf="form.get('partType').value === 'AMOUNT'">
                <mat-label>{{'PART' | translate}}</mat-label>
                <input type="number" matInput [placeholder]="'PART' | translate" formControlName="partPrice">
            </mat-form-field>

            <!--if part percentage-->

            <ng-container *ngIf="form.get('partType').value === 'PERCENTAGE'">
                <mat-form-field appearance="standard" class="left-padded">
                    <mat-label>{{'CALCULATION_BASE' | translate}}</mat-label>
                    <mat-select placeholder="{{'CALCULATION_BASE' | translate}}" formControlName="calculationBase">
                        <mat-option *ngFor="let item of calculationBases" [value]="item">
                            {{item | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="standard" class="left-padded">
                    <mat-label>{{'PART_PERCENTAGE' | translate}}</mat-label>
                    <input type="number" matInput [placeholder]="'PART_PERCENTAGE' | translate" formControlName="partPercentage">
                </mat-form-field>

                <!--if base exam paid-->
                <ng-container *ngIf="form.get('calculationBase').value === 'PAID_AMOUNT'">
                    <mat-form-field appearance="standard" class="left-padded">
                        <mat-label>{{'MIN_PRICE' | translate: {currency: currencyFormat} }}</mat-label>
                        <input type="number" matInput [placeholder]="'MIN_PRICE' | translate" formControlName="minPrice">
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="left-padded">
                        <mat-label>{{'MAX_PRICE' | translate: {currency: currencyFormat} }}</mat-label>
                        <input type="number" matInput [placeholder]="'MAX_PRICE' | translate" formControlName="maxPrice">
                    </mat-form-field>
                </ng-container>
            </ng-container>

        </div>

        <div fxLayout="column" style="width: 100%;">
            <div class="field-title" *ngIf="form.get('examTypes')['controls'].length != 0">{{'EXAM_TYPE_EXCEPTIONS' | translate}}</div>
            <div fxLayoutAlign="space-between center" class="tree-card"
                 formArrayName="examTypes" *ngFor="let item of form.get('examTypes')['controls']; let i = index;">
                <div fxLayout="row" fxLayoutAlign="start center" [formGroupName]="i" style="width: 100%;">
                    <div fxFlex="20%">{{item.get('name').value}}</div>
                    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start center">
                        <mat-form-field appearance="standard">
                            <mat-label>{{'PART_TYPE' | translate}}</mat-label>
                            <mat-select placeholder="{{'PART_TYPE' | translate}}" formControlName="partType">
                                <mat-option *ngFor="let option of partTypes" [value]="option">
                                    {{ option.startsWith('AMOUNT') ? (option | translate: {currency: currencyFormat}) : (option | translate) }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!--if part amount-->
                        <mat-form-field appearance="standard" *ngIf="item.get('partType').value == 'AMOUNT'">
                            <mat-label>{{'PART' | translate}}</mat-label>
                            <input type="number" matInput [placeholder]="'PART' | translate" formControlName="partPrice">
                        </mat-form-field>

                        <!--if part percentage-->
                        <ng-container *ngIf="item.get('partType').value == 'PERCENTAGE'">
                            <mat-form-field appearance="standard" class="left-padded">
                                <mat-label>{{'CALCULATION_BASE' | translate}}</mat-label>
                                <mat-select placeholder="{{'CALCULATION_BASE' | translate}}" formControlName="calculationBase">
                                    <mat-option *ngFor="let option of calculationBases" [value]="option">
                                        {{option | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="standard" class="left-padded">
                                <mat-label>{{'PART_PERCENTAGE' | translate}}</mat-label>
                                <input type="number" matInput [placeholder]="'PART_PERCENTAGE' | translate" formControlName="partPercentage">
                            </mat-form-field>

                            <!--if base exam paid-->
                            <ng-container *ngIf="item.get('calculationBase').value == 'PAID_AMOUNT'">
                                <mat-form-field appearance="standard" class="left-padded">
                                    <mat-label>{{'MIN_PRICE' | translate}}</mat-label>
                                    <input type="number" matInput [placeholder]="'MIN_PRICE' | translate" formControlName="minPrice">
                                </mat-form-field>
                                <mat-form-field appearance="standard" class="left-padded">
                                    <mat-label>{{'MAX_PRICE' | translate}}</mat-label>
                                    <input type="number" matInput [placeholder]="'MAX_PRICE' | translate" formControlName="maxPrice">
                                </mat-form-field>
                            </ng-container>
                        </ng-container>

                    </div>
                </div>
            </div>
        </div>

        <div fxLayout="column" style="width: 100%;">
            <div class="field-title" *ngIf="form.get('procedureCodes')['controls'].length != 0">{{'PROCEDURE_CODE_EXCEPTIONS' | translate}}</div>
            <div fxLayoutAlign="space-between center" class="tree-card"
                 formArrayName="procedureCodes" *ngFor="let item of form.get('procedureCodes')['controls']; let i = index;">
                <div fxLayout="row" fxLayoutAlign="start center" [formGroupName]="i" style="width: 100%;">
                    <div fxFlex="20%">{{item.get('name').value}}</div>
                    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start center">
                        <mat-form-field appearance="standard">
                            <mat-label>{{'PART_TYPE' | translate}}</mat-label>
                            <mat-select placeholder="{{'PART_TYPE' | translate}}" formControlName="partType">
                                <mat-option *ngFor="let option of partTypes" [value]="option">
                                    {{ option.startsWith('AMOUNT') ? (option | translate: {currency: currencyFormat}) : (option | translate) }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!--if part amount-->
                        <mat-form-field appearance="standard" *ngIf="item.get('partType').value == 'AMOUNT'">
                            <mat-label>{{'PART' | translate}}</mat-label>
                            <input type="number" matInput [placeholder]="'PART' | translate" formControlName="partPrice">
                        </mat-form-field>

                        <!--if part percentage-->
                        <ng-container *ngIf="item.get('partType').value == 'PERCENTAGE'">
                            <mat-form-field appearance="standard" class="left-padded">
                                <mat-label>{{'CALCULATION_BASE' | translate}}</mat-label>
                                <mat-select placeholder="{{'CALCULATION_BASE' | translate}}" formControlName="calculationBase">
                                    <mat-option *ngFor="let option of calculationBases" [value]="option">
                                        {{option | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="standard" class="left-padded">
                                <mat-label>{{'PART_PERCENTAGE' | translate}}</mat-label>
                                <input type="number" matInput [placeholder]="'PART_PERCENTAGE' | translate" formControlName="partPercentage">
                            </mat-form-field>

                            <!--if base exam paid-->
                            <ng-container *ngIf="item.get('calculationBase').value == 'PAID_AMOUNT'">
                                <mat-form-field appearance="standard" class="left-padded">
                                    <mat-label>{{'MIN_PRICE' | translate}}</mat-label>
                                    <input type="number" matInput [placeholder]="'MIN_PRICE' | translate" formControlName="minPrice">
                                </mat-form-field>
                                <mat-form-field appearance="standard" class="left-padded">
                                    <mat-label>{{'MAX_PRICE' | translate}}</mat-label>
                                    <input type="number" matInput [placeholder]="'MAX_PRICE' | translate" formControlName="maxPrice">
                                </mat-form-field>
                            </ng-container>
                        </ng-container>

                    </div>
                </div>
            </div>
        </div>

        <span fxFlex></span>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px">
            <button mat-raised-button color="warn" (click)="cancel()">{{'CANCEL' | translate}}</button>
            <button mat-raised-button color="primary" (click)="onSave(form.value)" [disabled]="!form.valid">{{'SAVE' | translate}}
            </button>
        </div>
    </div>

    <!--tree exams selection-->
    <div [ngClass]="{'selection': true, 'visible': selection}">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                <button mat-icon-button disabled></button>
                <mat-checkbox [checked]="procedureCodeSelected(node)"
                              (change)="selectProcedureCode(node)">{{node.name}}</mat-checkbox>
            </mat-tree-node>

            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <div class="mat-tree-node">
                    <button mat-icon-button matTreeNodeToggle>
                        <mat-icon fontSet="mdi" [fontIcon]="treeControl.isExpanded(node) ? 'mdi-chevron-down':'mdi-chevron-right'"></mat-icon>
                    </button>
                    <mat-checkbox [checked]="examTypeSelected(node)"
                                  [indeterminate]="examTypeChildrenPartial(node)"
                                  (change)="selectExamType(node)">{{node.name}}</mat-checkbox>
                </div>
                <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                </div>
            </mat-nested-tree-node>

        </mat-tree>
    </div>
</div>
