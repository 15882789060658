import {BehaviorSubject, fromEvent as observableFromEvent, merge, of as observableOf} from 'rxjs';

import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DeleteConfirmComponent, SharedService} from '../../shared';
import {PathologyEditComponent} from './pathology-edit/pathology-edit.component';
import {Pathology} from '../../model';

@Component({
    selector: 'app-pathology-setting',
    templateUrl: './pathology-setting.component.html',
    styleUrls: ['./pathology-setting.component.scss']
})
export class PathologySettingComponent implements AfterViewInit, OnInit {

    dataSource = new MatTableDataSource();
    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    filterChange = new BehaviorSubject('');
    editChange = new BehaviorSubject('');

    @ViewChild('filter', { static: true }) filter: ElementRef;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private shared: SharedService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.filter.nativeElement.focus();
    }

    ngAfterViewInit() {

        observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
            debounceTime(400),
            distinctUntilChanged(),)
            .subscribe(() => {
                if (!this.dataSource) return;
                this.dataSource.filter = this.filter.nativeElement.value;
                this.filterChange.next(this.filter.nativeElement.value);
            });

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange.asObservable(), this.paginator.page.asObservable(), this.editChange)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.shared.getPaginatedPathologies(this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        this.filter.nativeElement.value);
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data['totalElements'];
                    return data['content'] as Pathology[];
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.dataSource.data = data);
    }

    editPathology(pathology) {
        this.dialog.open(PathologyEditComponent, {data: pathology, disableClose: true})
            .afterClosed()
            .subscribe(res => {
                if (res) {
                    this.editChange.next(res);
                }
            })
    }

    deletePathology(pathology) {
        this.dialog.open(DeleteConfirmComponent)
            .afterClosed()
            .subscribe(ok => {
                if (ok) {
                    this.shared.deletePathology(pathology)
                        .subscribe(res => {
                            if (res) {
                                this.editChange.next(null);
                            }
                        })
                }
            });
    }
}
