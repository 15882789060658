import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {SettingService} from '../../setting.service';
import {assign} from 'lodash';
import {handleProcedureCodeNullValues, SharedService} from '../../../shared';
import {forkJoin, Observable} from 'rxjs';
import {BillingCode, PHARMACEUTICAL2, TemplateModel} from "../../../model";
import {map, startWith} from "rxjs/operators";

@Component({
    selector: 'app-procedure-code-edit',
    templateUrl: './procedure-code-edit.component.html',
    styleUrls: ['./procedure-code-edit.component.scss']
})
export class ProcedureCodeEditComponent implements OnInit {

    selectedItem: any;
    form: FormGroup;
    staffList: any[];
    templateModels: any[];
    billingCodes: any[];
    selected: number;
    modalities: any[];
    bodyRegions: any[];
    pharmaceuticals: any[];
    reasonForExams: any[];
    templateModelCtrl = new FormControl('');
    billingCodeCtrl = new FormControl('');
    filteredModels: Observable<TemplateModel[]>;
    filteredBillingCodes: Observable<BillingCode[]>;


    constructor(private fb: FormBuilder,
                public dialogRef: MatDialogRef<ProcedureCodeEditComponent>,
                private sharedService: SharedService,
                private settingService: SettingService) {
        this.createForm();
    }

    onSave(data) {

        assign(data, {
            procedureCatalog: null,
            defaultPerformingPhysician: data.defaultPerformingPhysician.id === '' ? null : data.defaultPerformingPhysician,
            templateModel: data.templateModel.id === '' ? null: data.templateModel
        });

        if (data.bodyRegion.id === '') data.bodyRegion = null;

        this.settingService
            .saveProcedureCode(data)
            .subscribe(res => {
                res = assign(res, {
                    modality0: res.modality.value,
                    bodyRegion0: res.bodyRegion ? res.bodyRegion.value : '-',
                    contrast: res.pharmaceutical,
                    procedureType: res.reasonForExam.value,
                    billCode: res.billingCode.code
                });

                this.dialogRef.close(res);
            });
    }

    ngOnInit() {
        this.pharmaceuticals = PHARMACEUTICAL2;

        forkJoin(
            [this.sharedService.getPerformingPhysicians(),
                this.settingService.getTemplateModels(),
                this.sharedService.getModalities(),
                this.sharedService.getBodyRegions(),
                this.sharedService.getReasonForExams(),
                this.sharedService.getBillingCodes()]
        ).subscribe(data => {
            [
                this.staffList,
                this.templateModels,
                this.modalities,
                this.bodyRegions,
                this.reasonForExams,
                this.billingCodes
            ] = data;

            let code = handleProcedureCodeNullValues(this.selectedItem);
            this.form.patchValue(code);

            this.filteredModels = this.templateModelCtrl.valueChanges.pipe(
                startWith(''),
                map(value => this._filterTemplates(value))
            );

            this.templateModelCtrl.patchValue(code.templateModel ? code.templateModel.name : '')

            this.filteredBillingCodes = this.billingCodeCtrl.valueChanges.pipe(
                startWith(''),
                map(value => this._filterBillingCodes(value))
            );

            this.billingCodeCtrl.patchValue(code.billingCode ? code.billingCode.code : '')
        });
    }

    templateModelChange(model: any) {
        this.form.get('templateModel').patchValue(model);
    }

    billingCodeChange(code: any) {
        this.form.get('billingCode').patchValue(code);
    }

    private _filterTemplates(value: string): TemplateModel[] {
        const filterValue = value ? value.toLowerCase() : '';

        return this.templateModels.filter(model => model.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private _filterBillingCodes(value: string): BillingCode[] {
        const filterValue = value ? value.toLowerCase() : '';

        return this.billingCodes.filter(model => model.code.toLowerCase().indexOf(filterValue) === 0);
    }

    private createForm() {
        let templateModelGroup = this.fb.group({id: '', name: ''});

        this.form = this.fb.group({
            id: '',
            code: ['', Validators.required],
            duration: ['', Validators.required],
            description: '',
            risCode: '',
            longDescription: '',
            billingCode: this.fb.group({id: ['', Validators.required], code: '', price: 0}),
            deleted: false,
            composed: false,
            templateModel: templateModelGroup,
            reasonForExam: this.fb.group({
                id: '',
                value: '',
                reportOption: ''
            }),
            defaultPerformingPhysician: this.fb.group({
                id: '',
            }),
            procedureCatalog: null,
            pharmaceutical: '',
            modality: this.fb.group({
                id: [null, Validators.required],
                value: '',
                description: ''
            }),
            bodyRegion: this.fb.group({
                id: '',
                value: '',
                description: ''
            }),
        });
    }
}

