<div class="mat-table card-view" style="border-radius: 6px" fxFill fxLayout="column">

    <div class="search-area" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px"
         [formGroup]="filterForm" style="padding: 8px 16px !important;">
        <mat-form-field floatLabel="never">
            <span matPrefix><mat-icon fontIcon="mdi-magnify" fontSet="mdi"
                                      style="position:relative;top: -6px;"></mat-icon> &nbsp;</span>
            <input #filter matInput placeholder="{{'SEARCHING' | translate}}">
        </mat-form-field>

        <span fxFlex></span>

        <mat-form-field appearance="outline" fxFlex="260px">
            <mat-label>{{'USER' | translate}}</mat-label>
            <mat-select formControlName="user" multiple>
                <mat-option *ngFor="let user of users" [value]="user.username">{{user.fullName}} ({{user.username}})
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div fxLayout="column">
            <mat-button-toggle-group (change)="changeRange($event)" aria-label="Period"
                                     formControlName="period" name="period">
                <mat-button-toggle value="TODAY">
                    {{'TODAY' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="YESTERDAY">
                    {{'YESTERDAY' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="3D">
                    {{'THREE_DAYS' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="1W">
                    {{'ONE_WEEK' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="1M">
                    {{'ONE_MONTH' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="3M">
                    {{'THREE_MONTHS' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="OT">
                    {{'ALL' | translate}}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <mat-form-field appearance="outline" fxFlex="208px">
            <mat-date-range-input [rangePicker]="picker">
                <input [placeholder]="'FROM' | translate" formControlName="startDate" matStartDate>
                <input [placeholder]="'TO' | translate" formControlName="endDate" matEndDate>
            </mat-date-range-input>
            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="filterForm.controls['startDate'].hasError('matStartDateInvalid')">Invalid start date
            </mat-error>
            <mat-error *ngIf="filterForm.controls['endDate'].hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>

    </div>


    <div fxFlex style="overflow: auto;">
        <mat-table [@state] [dataSource]="dataSource"
                   [trackBy]="trackByDatetime"
                   matSort
                   matSortActive="datetime"
                   matSortDirection="desc" matSortDisableClear>

            <!--Table-->
            <ng-container *ngFor="let col of availableColumns" [class.hidden]="col.hidden"
                          matColumnDef="{{col.label}}">
                <mat-header-cell *matHeaderCellDef [disabled]="!col['sortable']"
                                 class="fire-header-cell" mat-sort-header> {{col.header | translate}}
                </mat-header-cell>

                <ng-container *ngIf="col.unit === 'date'">
                    <mat-cell *matCellDef="let row" class="fire-cell"
                              style="font-weight: bold;"> {{row[col.value] | date: 'dd/MM/yyyy HH:mm'}}</mat-cell>
                </ng-container>

                <ng-container *ngIf="col.unit !== 'date'">
                    <mat-cell *matCellDef="let row" class="fire-cell"> {{row[col.value]}}</mat-cell>
                </ng-container>

            </ng-container>

            <!--header-->
            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true" class="fire-header-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;" class="fire-row">
            </mat-row>
        </mat-table>

        <div [style.display]="dataSource.data.length === 0 ? '' : 'none'" class="example-no-results"
             style="height: calc(60vh - 218px) !important;align-items: center">
            <div *ngIf="isLoadingResults; else noItem">
                <mat-spinner [diameter]="50" [strokeWidth]="3" color="accent"></mat-spinner>
            </div>
            <ng-template #noItem>
                {{'NOITEMSFOUND' | translate}}
            </ng-template>
        </div>
    </div>
    <mat-paginator [length]="resultsLength"
                   [pageIndex]="0"
                   [pageSizeOptions]="[5, 10, 25, 50, 100]"
                   [pageSize]="25" showFirstLastButtons>
    </mat-paginator>


</div>
