import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Article, PatientWorkflow, StockMovement, WorkflowItem} from "../../model";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {deleteItemFromArray} from "../shared-functions";
import {WorkflowService} from "../../workflow/workflow.service";
import {SharedService} from "../shared.service";
import {StockArticlesComponent} from "../stock-articles/stock-articles.component";
import * as numeral from 'numeral';
import {ArticleSerialsComponent} from "../article-serials/article-serials.component";
import {ArticleLotsComponent} from "../article-lots/article-lots.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {AppConfigService} from "../../app-config.service";

@Component({
    selector: 'app-stock-movement',
    templateUrl: './stock-movement.component.html',
    styleUrls: ['./stock-movement.component.scss']
})
export class StockMovementComponent implements OnInit {
    stockForm: FormGroup;
    articles: Article[] = [];
    stores: { name: string, id: number, isDefault: boolean }[] = [];
    selectedStore: { name: string, id: number, isDefault: boolean };

    vatAmounts = [20, 14, 10, 7, 5.50, 2.1, 0];

    workflowItem: PatientWorkflow | WorkflowItem;
    totalVAT: number = 0.0;
    totalHT: number = 0.0;
    isFormValid: boolean = false;
    totalTTC: number = 0.0;

    discountPercentage: number = 0.0;
    formDisabled: boolean = false;
    currencyFormat = 'DH';

    constructor(@Inject(MAT_DIALOG_DATA) data: WorkflowItem | PatientWorkflow,
                private service: WorkflowService,
                private shared: SharedService,
                private _config: AppConfigService,
                private dialog: MatDialog,
                private translate: TranslateService,
                private snack: MatSnackBar,
                private fb: FormBuilder) {
        this.currencyFormat = _config.currencyFormat;
        this.createForm();
        this.workflowItem = data;
    }

    ngOnInit(): void {
        this.shared.getStoreList().subscribe(data => {
            this.stores = data.map(it => {
                let st = it.split('@');
                return {name: st[0], id: parseInt(st[1]), isDefault: st[2] == "1"}
            });

            if (this.workflowItem.stockMovement == null || this.workflowItem.stockMovement.id === null) this.selectDefaultStore();
            else this.selectedStore = this.stores.find(it => it.id == this.workflowItem.stockMovement.store);
        });

        this.workflowItem && this.selectData(this.workflowItem.stockMovement);
    }

    save(entity: StockMovement, draft: boolean = true, valid: boolean = false) {
        entity.draft = draft;
        entity.valid = valid;
        entity.lines = this.articles;
        entity.patient = this.workflowItem.patientID
        entity.visitId = this.workflowItem.accessionNumber;
        entity.htAmount = this.totalHT
        entity.vatAmount = this.totalVAT
        entity.ttcAmount = this.totalTTC

        this.service.saveStockMovement(entity)
            .subscribe(mvt => {
                this.snack.open(this.translate.instant('STOCK_MVT_SAVED'), '', {duration: 2000});

                this.selectData(mvt);
            });
    }

    validate(value: StockMovement) {
        this.save(value, false, true);
    }

    unValidate(value: StockMovement) {
        this.save(value, false, false);
    }

    addNewArticle() {
        this.dialog.open(StockArticlesComponent, {
            data: this.selectedStore.id,
            disableClose: true
        }).afterClosed().subscribe((selectedArticles: any[][]) => {
            if (selectedArticles.length) {
                for (let row of selectedArticles) {
                    let article = new Article();
                    article.externalId = row[0];
                    article.code = row[1];
                    article.description = row[2];
                    article.price = parseFloat(row[4]);
                    article.vatAmount = parseFloat(row[6]);
                    article.hasSerials = row[7] == "1";
                    article.hasLots = row[8] == "1";
                    article.totalPrice = article.price * article.quantity;
                    article.store = this.selectedStore.id;

                    this.articles.push(article);
                }

                this.summaryTotals();
            }
        })
    }

    removeArticle(article: Article) {
        deleteItemFromArray(this.articles, article);
    }

    updateArticle(article: Article) {
        article.totalPrice = article.price * article.quantity;
        this.summaryTotals();
    }

    summaryTotals() {
        this.totalVAT = this.articles
            .map(it => it.quantity * it.price * it.vatAmount / 100)
            .reduce((p, c) => p + c, 0);

        this.totalHT = this.articles
            .map(it => it.price * it.quantity)
            .reduce((p, c) => p + c, 0);

        this.totalTTC = this.totalHT + this.totalVAT;

        this.checkFormValidation()

    }

    changeDiscountPercentage(event: KeyboardEvent) {
        let price = parseFloat(event.target['value'] || 0);
        let percentage = price * 100 / (this.totalHT != 0 ? this.totalHT : 1);
        this.stockForm.get('globalDiscountPercentage').patchValue(percentage.toFixed(2));
    }

    changeDiscountPrice(event: KeyboardEvent) {
        let percentage = parseFloat(event.target['value'] || 0);
        let price = this.totalHT * percentage / 100;
        this.stockForm.get('globalDiscount').patchValue(price.toFixed(2));
    }

    formatNumeral(numValue: any): any {
        return numeral(numValue).format(`0,0.00`);
    }

    selectSerialNumbers(article: Article) {
        this.dialog.open(ArticleSerialsComponent, {
            data: article,
            disableClose: true
        }).afterClosed().subscribe((serials: string[]) => {
            if (serials) {
                article.serials = serials.reduce((p, c) => `${p},${c}`);
                this.checkFormValidation();
            }
        });
    }

    selectLotNumbers(article: Article) {
        this.dialog.open(ArticleLotsComponent, {
            data: article,
            disableClose: true
        }).afterClosed().subscribe((lots: any[]) => {
            if (lots) {
                article.lots = lots.map(it => `${it.code}_${it.selectedQuantity}`).reduce((p, c) => `${p},${c}`);
                this.checkFormValidation();
            }
        });
    }

    private createForm() {
        this.stockForm = this.fb.group(new StockMovement());
    }

    lotsTotalQuantity(lots: string): number {
        return lots ? lots.split(',').map(it => parseFloat(it.split('_')[1])).reduce((p, c) => p + c, 0): 0
    }

    private selectDefaultStore() {
        this.selectedStore = this.stores.find(it => it.isDefault);
        this.stockForm.get('store').patchValue(this.selectedStore.id);
    }

    private checkFormValidation() {
        this.isFormValid = this.articles.map(it => (it.hasSerials && !!it.serials && it.serials.split(',').length === it.quantity) || (it.hasLots && !!it.lots && this.lotsTotalQuantity(it.lots) === it.quantity))
            .reduce((p, c) => p && c, true)
    }

    private selectData(stockMovement: StockMovement) {
        if (stockMovement) {
            this.stockForm.patchValue(stockMovement);
            this.articles = stockMovement.lines;
            this.summaryTotals();

            this.formDisabled = stockMovement.valid;

            stockMovement.valid ? this.stockForm.disable({emitEvent: true}) : this.stockForm.enable({emitEvent: true});
        }
    }
}
