import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SettingComponent} from './setting.component';
import {ScheduleSettingComponent} from './schedule-setting/schedule-setting.component';
import {ProfileSettingComponent} from './profile-setting/profile-setting.component';
import {AetSettingComponent} from './aet-setting/aet-setting.component';
import {UsersSettingComponent} from './users-setting/users-setting.component';
import {ProcedureCodeSettingComponent} from './procedure-code-setting/procedure-code-setting.component';
import {PathologySettingComponent} from './pathology-setting/pathology-setting.component';
import {DictionaryComponent} from './dictionary/dictionary.component';
import {ImagingCenterSettingComponent} from './imaging-center-setting/imaging-center-setting.component';
import {ModalitySettingComponent} from './modality-setting/modality-setting.component';
import {OrderPrioritySettingComponent} from './order-priority-setting/order-priority-setting.component';
import {SpsStatusComponent} from './sps-status/sps-status.component';
import {ReportTemplateSettingComponent} from './report-template-setting/report-template-setting.component';
import {ReportingConfigComponent} from './reporting-config/reporting-config.component';
import {ReasonForExamComponent} from './reason-for-exam/reason-for-exam.component';
import {RoomSettingComponent} from './room-setting/room-setting.component';
import {HolidaysSettingComponent} from './holidays-setting/holidays-setting.component';
import {ReferringPhysiciansComponent} from './referring-physicians/referring-physicians.component';
import {BillingCodesComponent} from './billing-codes/billing-codes.component';
import {StaffContractComponent} from './staff-contract/staff-contract.component';
import {EditStaffContractComponent} from './staff-contract/edit-staff-contract/edit-staff-contract.component';
import {ViewersComponent} from './viewers/viewers.component';
import {PrintingTemplatesComponent} from './printing-templates/printing-templates.component';
import {PrintingModelsComponent} from "./printing-models/printing-models.component";
import {SmsTemplatesComponent} from "./sms-templates/sms-templates.component";
import {DefaultValuesComponent} from "./default-values/default-values.component";

const routes: Routes = [
    {
        path: '', component: SettingComponent,
        children: [
            {path: '', pathMatch: 'full', redirectTo: 'schedule-setting'},
            {path: 'profile-setting', component: ProfileSettingComponent},
            {path: 'viewers', component: ViewersComponent},
            {path: 'staff-contract', component: StaffContractComponent},
            {path: 'edit-staff-contract', component: EditStaffContractComponent},
            {path: 'referring-physicians', component: ReferringPhysiciansComponent},
            {path: 'aet-setting', component: AetSettingComponent},
            {path: 'users-setting', component: UsersSettingComponent},
            {path: 'procedure-code-setting', component: ProcedureCodeSettingComponent},
            {path: 'billing-code-setting', component: BillingCodesComponent},
            {path: 'pathology-setting', component: PathologySettingComponent},
            {path: 'dictionary', component: DictionaryComponent},
            {path: 'imaging-center-setting', component: ImagingCenterSettingComponent},
            {path: 'modality-setting', component: ModalitySettingComponent},
            {path: 'priority-setting', component: OrderPrioritySettingComponent},
            {path: 'sps-status-setting', component: SpsStatusComponent},
            {path: 'report-template-setting', component: ReportTemplateSettingComponent},
            {path: 'schedule-setting', component: ScheduleSettingComponent},
            {path: 'reporting-config', component: ReportingConfigComponent},
            {path: 'printing-templates', component: PrintingTemplatesComponent},
            {path: 'reason-for-exam', component: ReasonForExamComponent},
            {path: 'rooms', component: RoomSettingComponent},
            {path: 'default-values', component: DefaultValuesComponent},
            {path: 'holidays', component: HolidaysSettingComponent},
            {path: 'printing-models', component: PrintingModelsComponent},
            {path: 'sms-templates', component: SmsTemplatesComponent},
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SettingRoutingModule {
}
