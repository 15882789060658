<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-pencil-box-outline"></mat-icon>
    <h3 mat-dialog-title>{{'NEW_PATHOLOGY' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <form [formGroup]="form" novalidate>
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'PATHOLOGY_VALUE' | translate}}</mat-label>
            <textarea matInput [placeholder]="'PATHOLOGY_VALUE' | translate" formControlName="value" required></textarea>
        </mat-form-field>
    </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="warn" [mat-dialog-close]="null">{{'CANCEL' | translate}}</button>
    <button mat-button color="primary" (click)="savePathology(form.value)" [disabled]="form.invalid"><b>{{'SAVE' |
        translate}}</b>
    </button>
</mat-dialog-actions>

