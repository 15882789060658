import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';


import {HttpClient} from '@angular/common/http';
import {SortDirection} from '@angular/material/sort';
import {ReportingTask} from '../model';
import {DOCUMENT_URL, PATIENT_URL} from '../urls';
import {FileElement} from "../shared";


@Injectable()
export class PatientService {

    private patientCreatedSource = new Subject<any>();
    patientSelected = new BehaviorSubject(null);

    patientCreated = this.patientCreatedSource.asObservable();

    constructor(private http: HttpClient) {
    }

    createPatient = (patient: any) => this.patientCreatedSource.next(patient);

    getPatient = (id: number): Observable<any> => this.http.get(`${PATIENT_URL}/getone/${id}`);

    savePatient = (patient: any): Observable<any> => this.http.post(`${PATIENT_URL}/saveNewPatient`, patient);

    preparePatient = (patient: any): Observable<any> => this.http.post(`${PATIENT_URL}/preparePatient`, patient);

    deletePatient = (patientId: number): Observable<any> => this.http.get(`${PATIENT_URL}/delete/${patientId}`);

    findPatient = (key: string): Observable<any> => this.http.get(`${PATIENT_URL}/find/${key}`);

    getPatientReports = (patientId: string): Observable<ReportingTask[]> => this.http.get<ReportingTask[]>(`${PATIENT_URL}/reports/${patientId}`);

    loadPatients(pageSize: number, pageIndex: number, sort: string, direction: SortDirection, value): Observable<any> {
        let params = {
            page: String(pageIndex),
            size: String(pageSize),
            sort: `${sort},${direction}`,
            'search-term': value
        };
        return this.http.get(`${PATIENT_URL}/load-patients`, {params})
    }

    getExistingPatients(firstName: string, lastName: string): Observable<any> {
        let params = {firstName, lastName};
        return this.http.get(`${PATIENT_URL}/find-duplicate-patients`, {params})
    }

    countExistingPatients(firstName: string, lastName: string): Observable<number> {
        let params = {firstName, lastName};
        return this.http.get<number>(`${PATIENT_URL}/count-duplicate-patients`, {params});
    }

    getPatientByID(patientID: string): Observable<any> {
        return this.http.get(`${PATIENT_URL}/patient-by-ext_id`, {params: {patientID}})
    }

    getPatientFiles(patientID: string): Observable<FileElement[]> {
        return this.http.get<FileElement[]>(`${DOCUMENT_URL}/patient-files`, {params: {patientID}});
    }

    exportPatients(ids: string = "all"): Observable<any> {
        return this.http.get(`${PATIENT_URL}/exportPatients`, {params: {ids}, responseType: 'blob'});
    }
}
