<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-timetable"></mat-icon>
    <h3 mat-dialog-title>{{'PATIENT_EXAMS' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <div class="exam-table">
        <div class="table-row table-header">
            <div>{{'DATE' | translate}}</div>
            <div>{{'EXAM' | translate}}</div>
            <div>{{'PERFORMING_PHYSICIAN' | translate}}</div>
            <div>{{'EMERGENCY' | translate}}</div>
        </div>

        <div class="table-row" *ngFor="let exam of patientExams | async" (click)="openExamDetails(exam)">
            <div>{{exam.start}}</div>
            <div>{{exam.title}}</div>
            <div>{{exam.performingPhysician}}</div>
            <div>{{exam.urgent ? 'Urgent': ''}}</div>
        </div>
    </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button color="warn" mat-dialog-close mat-raised-button>{{'EXIT' | translate}}</button>
</mat-dialog-actions>
