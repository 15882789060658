import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {SharedService} from '../shared.service';

@Component({
    selector: 'app-viewer-select',
    templateUrl: './viewer-select.component.html',
    styleUrls: ['./viewer-select.component.scss']
})
export class ViewerSelectComponent {

    viewerSelect: FormGroup;
    viewers: any[];

    constructor(private service: SharedService,
                private fb: FormBuilder,
                private dialogRef: MatDialogRef<ViewerSelectComponent>) {
        this.service.getViewers().subscribe(viewers => this.viewers = viewers);

        this.viewerSelect = this.fb.group({

            aETitle: ['', Validators.required],
            ipAddress: '',
            port: '',

        });
    }

}
