import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {SettingService} from '../../setting.service';
import {CalendarEvent, Holiday} from '../../../model';
import * as moment from 'moment';
import {assign} from 'lodash';

@Component({
    selector: 'app-holiday-edit',
    templateUrl: './holiday-edit.component.html'
})
export class HolidayEditComponent implements OnInit {


    holidayType = new FormControl('ONE_DAY');
    form: FormGroup;
    days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    holidayTypes = ['ONE_DAY', 'RANGE'];

    oneDayForm = true;

    constructor(@Inject(MAT_DIALOG_DATA) public data: CalendarEvent,
                public fb: FormBuilder,
                public dialogRef: MatDialogRef<HolidayEditComponent>,
                private settingService: SettingService) {
        this.form = this.fb.group({
            id: '',
            title: '',
            start: this.fb.group({day: '', month: ''}),
            end: this.fb.group({day: '', month: ''})
        });

        this.holidayType.valueChanges.subscribe(value => {
            this.oneDayForm = (value === 'ONE_DAY');
        });
    }

    onSave(data) {

        let start = moment({month: data.start.month - 1, day: data.start.day, hour: 1});
        let end = this.oneDayForm ? start : moment({month: data.end.month - 1, day: data.end.day, hour: 1});

        let holiday: Holiday = {
            id: data.id,
            title: data.title,
            start: start,
            end: end
        };

        this.settingService
            .saveHoliday(holiday)
            .subscribe(res => this.dialogRef.close(res));
    }

    ngOnInit() {

        let _start = {
            month: moment(this.data.start, 'YYYY-MM-DD').month() + 1,
            day: moment(this.data.start, 'YYYY-MM-DD').date()
        };

        let _end = {
            month: moment(this.data.end, 'YYYY-MM-DD').month() + 1,
            day: moment(this.data.end, 'YYYY-MM-DD').date()
        };

        this.oneDayForm = (this.data.start === this.data.end);
        this.holidayType.setValue(this.oneDayForm ? 'ONE_DAY' : 'RANGE');

        let _data = this.data;

        _data = assign(_data, {start: _start, end: _end});

        this.form.patchValue(_data);
    }

}
