<div fxFill fxLayout="row nowrap" fxLayoutAlign="space-between center" fxLayoutGap="4px">

    <div fxFlex fxLayout="column">
        <div *ngIf="pvTable === 'wdr'" class="ft-toolbar" fxLayout="row nowrap" fxLayoutAlign="start" fxLayoutGap="8px">
            <button [matMenuTriggerFor]="exportMenu" [matTooltip]="'EXPORT' | translate" mat-icon-button>
                <mat-icon fontIcon="mdi-cloud-download" fontSet="mdi"></mat-icon>
            </button>
            <mat-menu #exportMenu="matMenu">
                <button (click)="exportTo('pdf')" mat-menu-item>
                    <mat-icon fontIcon="mdi-file-pdf" fontSet="mdi"></mat-icon>
                    Export PDF
                </button>
                <button (click)="exportTo('excel')" mat-menu-item>
                    <mat-icon fontIcon="mdi-file-excel" fontSet="mdi"></mat-icon>
                    Export Excel
                </button>
                <button (click)="exportTo('html')" mat-menu-item>
                    <mat-icon fontIcon="mdi-language-html5" fontSet="mdi"></mat-icon>
                    Export HTML
                </button>
            </mat-menu>

            <span fxFlex></span>

            <button (click)="openFields()" [matTooltip]="'FIELDS' | translate" mat-icon-button>
                <mat-icon fontIcon="mdi-cog" fontSet="mdi"></mat-icon>
            </button>
            <button (click)="openOptions()" [matTooltip]="'OPTIONS' | translate" mat-icon-button><mat-icon fontIcon="mdi-cogs" fontSet="mdi"></mat-icon></button>

            <button [matMenuTriggerFor]="formatMenu" [matTooltip]="'FORMAT' | translate" mat-icon-button>
                <mat-icon fontIcon="mdi-table-cog" fontSet="mdi"></mat-icon>
            </button>
            <mat-menu #formatMenu="matMenu">
                <button (click)="openFormatting()" mat-menu-item>
                    <mat-icon fontIcon="mdi-format-paint" fontSet="mdi"></mat-icon>
                    Conditional formatting
                </button>
                <button (click)="createFormattingItem()" mat-menu-item>
                    <mat-icon fontIcon="mdi-table-column" fontSet="mdi"></mat-icon>
                    Format cell
                </button>
            </mat-menu>

            <button (click)="printReport()" [matTooltip]="'PRINT' | translate" mat-icon-button>
                <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
            </button>
            <button (click)="goFullScreen()" [matTooltip]="'FULL_SCREEN' | translate" mat-icon-button>
                <mat-icon fontIcon="mdi-fullscreen" fontSet="mdi"></mat-icon>
            </button>
        </div>

        <ng-container [ngSwitch]="pvTable">
            <ng-container *ngSwitchCase="'fm'">
                <fm-pivot #pivot
                          (beforetoolbarcreated)="beforeToolbarCreated($event)"
                          (ready)="onPivotReady($event)"
                          (reportcomplete)="onReportComplete()"
                          [customizeCell]="onCustomizeCell"
                          [height]="'calc(100vh - 170px)'"
                          [licenseKey]="flexMonsterLicenseKey"
                          [report]="config"
                          [toolbar]="true"
                          [width]="'100%'">
                </fm-pivot>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <app-wbr-pivot #pivot
                               (beforetoolbarcreated)="beforeToolbarCreated($event)"
                               (ready)="onPivotReady($event)"
                               (reportcomplete)="onReportComplete()"
                               [customizeCell]="onCustomizeCell"
                               [height]="'calc(100vh - 170px)'"
                               [report]="config"
                               [toolbar]="true"
                               [width]="'100%'">
                </app-wbr-pivot>
            </ng-container>
        </ng-container>

    </div>
</div>

