<div fxLayout="column" fxLayoutAlign="center center" style="">

    <div class="exam-grid mat-elevation-z3" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="8px"
         style="width: 600px; height: calc(100vh - 150px);">

        <div fxLayout="column" style="overflow: auto; ">
            <div class="a-table">
                <div class="a-row a-header">
                    <div>{{'AET_S' | translate}}</div>
                    <div>{{'TOTAL' | translate}}</div>
                    <div [style.textAlign]="'right'" style="padding-right: 16px">{{'TOTAL_PAID' | translate}}</div>
                </div>
                <div *ngFor="let aet of aetExams" class="a-row">
                    <div>{{aet.aet}}</div>
                    <div>{{aet.count}}</div>
                    <div [style.textAlign]="'right'" style="padding-right: 16px">{{formatNumeral(aet.totalPaid) }}</div>
                </div>
                <div class="a-row a-footer">
                    <div style="text-align: center">{{'TOTAL_EXAMS' | translate}}:</div>
                    <div>{{total}}</div>
                    <div [style.textAlign]="'right'" style="padding-right: 16px">{{formatNumeral(totalPaid)}}</div>
                </div>
            </div>
        </div>

        <div style="display: block; min-height: 320px;min-width: 600px;padding: 12px 0 32px 0;border-top: 1px solid #e7e7e7;" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="6px">
            <div style="text-align: center">
                <mat-button-toggle-group (change)="changeGraphData($event.value)">
                    <mat-button-toggle [checked]="true" value="count">
                        Total Examens
                    </mat-button-toggle>
                    <mat-button-toggle value="totalPaid">
                        Total Encaissé
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div>
                <canvas [type]="pieChartType"

                        [data]="pieChartData"
                        [legend]="pieChartLegend"
                        [options]="pieChartOptions"
                        [plugins]="pieChartPlugins"
                        baseChart>
                </canvas>
            </div>
        </div>

    </div>

</div>

