import {Component, OnInit} from '@angular/core';
import {handleNullValues, SharedService} from '../../shared';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SettingService} from '../setting.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ImagingCenter} from '../../model';
import {RAMED_ENABLED} from '../../global-variables';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'ft-imaging-center-setting',
    templateUrl: './imaging-center-setting.component.html',
    styleUrls: ['./imaging-center-setting.component.scss']
})
export class ImagingCenterSettingComponent implements OnInit {

    form: FormGroup;
    public readonly arabic: boolean = RAMED_ENABLED;

    constructor(private fb: FormBuilder,
                public sharedService: SharedService,
                public snack: MatSnackBar,
                public settingService: SettingService, private _translate: TranslateService) {

        this.form = this.fb.group(new ImagingCenter());
    }

    onSave(formValue) {
        this.settingService.saveImagingCenter(formValue)
            .subscribe(res => this.snack.open(this._translate.instant('CHANGES_SAVED'), '', {duration: 2000}));
    }

    ngOnInit() {
        this.sharedService
            .getImagingCenters()
            .subscribe(centers => this.form.patchValue(handleNullValues(centers[0])));
    }

}
