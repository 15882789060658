export interface ToolConf {
    name: string;
    class: any;
    synchronizer?: any;
    configuration?: any;
    options?: { mouseButtonMask?: number, synchronizationContext?: any};
    mode?: 'active' | 'passive' | 'enabled' | 'disabled';
}

declare var cornerstoneTools: any;
export const TOOL_MODE_FUNCTIONS = {
    active: cornerstoneTools.setToolActiveForElement,
    passive: cornerstoneTools.setToolPassiveForElement,
    enabled: cornerstoneTools.setToolEnabledForElement,
    disabled: cornerstoneTools.setToolDisabledForElement,
};
