import {Component, Inject} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {ChecklistDatabase} from './prescription-datasource';
import {Prescription, PrescriptionItem, PrescriptionItemFlatNode, PrescriptionItemNode} from './models';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SharedService} from '../shared.service';
import * as $ from 'jquery';
import * as moment from 'moment';

@Component({
    selector: 'app-prescription',
    templateUrl: './prescription.component.html',
    styleUrls: ['./prescription.component.scss'],
    providers: [ChecklistDatabase]
})
export class PrescriptionComponent {

    /** Map from flat node to nested node. This helps us finding the nested node to be modified */
    flatNodeMap = new Map<PrescriptionItemFlatNode, PrescriptionItemNode>();

    /** Map from nested node to flattened node. This helps us to keep the same object for selection */
    nestedNodeMap = new Map<PrescriptionItemNode, PrescriptionItemFlatNode>();

    /** A selected parent node to be inserted */
    selectedParent: PrescriptionItemFlatNode | null = null;

    /** The new item's name */
    newItemName = '';

    prescription = new Prescription();

    treeControl: FlatTreeControl<PrescriptionItemFlatNode>;

    treeFlattener: MatTreeFlattener<PrescriptionItemNode, PrescriptionItemFlatNode>;

    dataSource: MatTreeFlatDataSource<PrescriptionItemNode, PrescriptionItemFlatNode>;

    /** The selection for checklist */
    checklistSelection = new SelectionModel<PrescriptionItemFlatNode>(true /* multiple */);
    getLevel = (node: PrescriptionItemFlatNode) => node.level;
    isExpandable = (node: PrescriptionItemFlatNode) => node.expandable;
    getChildren = (node: PrescriptionItemNode): PrescriptionItemNode[] => node.children;
    hasChild = (_: number, _nodeData: PrescriptionItemFlatNode) => _nodeData.expandable;
    hasNoContent = (_: number, _nodeData: PrescriptionItemFlatNode) => _nodeData.name === '';
    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: PrescriptionItemNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.name === node.name
            ? existingNode
            : new PrescriptionItemFlatNode();
        flatNode.name = node.name;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private service: SharedService,
                private dialogRef: MatDialogRef<PrescriptionComponent>,
                private database: ChecklistDatabase) {
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<PrescriptionItemFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        database.dataChange.subscribe(data => {
            this.dataSource.data = data;
        });
    }

    generatePrescription() {
        let selection = this.checklistSelection.selected
            .filter(slt => !slt.expandable)
            .map(select => {
                let prescriptionItem = new PrescriptionItem();
                prescriptionItem.name = select.name;
                return prescriptionItem
            });
        this.prescription = {
            id: 0,
            title: '',
            physician: 'Dr.' + this.data.physician.physician,
            patientID: this.data.patient.patientID,
            patientName: this.data.patient.patientName,
            prescriptionDate: new Date(),
            prescriptionItems: selection
        };

        this.service.generatePrescription(this.prescription).subscribe(ok => {
            if (ok) {
                this.showPreview(`/templates/ordonnance.html?v=${moment().format('YYYYMMDDHHmmssSSS')}`);
            } else {
                console.log('Can\'t print prescription');
                this.dialogRef.close(null);
            }
        })
    }

    showPreview(url) {
        let $frame = $('<iframe />').attr('src', url).css({position: 'absolute', top: '-9999px'});
        $frame.on('load', () => setTimeout(() => $frame.remove(), 0));
        $(document.body).append($frame);
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: PrescriptionItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: PrescriptionItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    prescriptionItemSelectionToggle(node: PrescriptionItemFlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        this.checkAllParentsSelection(node);
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggle(node: PrescriptionItemFlatNode): void {
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: PrescriptionItemFlatNode): void {
        let parent: PrescriptionItemFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: PrescriptionItemFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: PrescriptionItemFlatNode): PrescriptionItemFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    /** Select the category so we can insert the new item. */
    addNewItem(node: PrescriptionItemFlatNode) {
        const parentNode = this.flatNodeMap.get(node);
        this.database.insertItem(parentNode!, '');
        this.treeControl.expand(node);
    }

    /** Save the node to database */
    saveNode(node: PrescriptionItemFlatNode, itemValue: string) {
        const nestedNode = this.flatNodeMap.get(node);
        this.database.updateItem(nestedNode!, itemValue);
    }
}
