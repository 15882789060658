import {Observable, of as observableOf} from 'rxjs';

import {debounceTime} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {deleteItemFromArray, SharedService} from '../../../shared';

import {SettingService} from '../../setting.service';
import {assign, find} from 'lodash';
import {StaffContract} from '../../../model';
import {BoxEditComponent} from "../../box-edit/box-edit.component";

function passwordMatcher(c: AbstractControl) {
    return c.get('password').value === c.get('confirmPassword').value ? null : {'nomatch': true}
}

export function required(min: number, max: number): any {
    return Validators.compose([Validators.required, Validators.minLength(min), Validators.maxLength(max)]);
}

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html'
})
export class UserEditComponent implements OnInit {

    selectedUser: any;
    form: FormGroup;
    functions: any[];
    profiles: any[];

    exists = observableOf(false);
    color: string = '#E5E5E5';
    contracts: Observable<StaffContract[]>;
    boxes = [];

    constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<UserEditComponent>,
                private sharedService: SharedService,
                private dialog: MatDialog,
                private settingService: SettingService) {
        this.createForm();
    }

    onChangeUserName() {
        this.form.get('username').valueChanges.pipe(debounceTime(200)).subscribe(value => {
            if (value && !this.selectedUser.id) this.exists = this.settingService.isUserExists(value);
        });
    }


    onSave(data) {

        assign(data, {
            color: this.color,
            contract: data.contract.id === '' ? null: data.contract,
            box: data.box.id === '' ? null: data.box
        });

        this.settingService
            .saveUser(data)
            .subscribe(res => {
                let profile = find(this.profiles, {id: res.profile.id});
                let func = find(this.functions, {id: res.function.id});

                assign(res, {
                    prof: profile.name,
                    funct: func.description
                });

                this.dialogRef.close(res)
            });
    }

    getFunctions() {
        this.sharedService.getFunctions().subscribe(data => this.functions = data);
    }

    getStaffContracts() {
        this.contracts = this.sharedService.getAllStaffContracts();
    }

    getProfiles() {
        this.sharedService.getProfiles().subscribe(data => this.profiles = data);
    }

    ngOnInit() {
        this.onChangeUserName();

        this.getFunctions();
        this.getStaffContracts();
        this.getProfiles();
        this.getBoxes();

        if (!this.selectedUser.profile) this.selectedUser.profile = {id: ''};
        if (!this.selectedUser.function) this.selectedUser.function = {id: ''};
        if (!this.selectedUser.contract) this.selectedUser.contract = {id: ''};
        if (!this.selectedUser.box) this.selectedUser.box = {id: ''};

        this.form.patchValue(this.selectedUser);

        this.color = this.selectedUser.color;
    }

    private createForm() {
        this.form = this.fb.group({
            id: '',
            clinicalID: '',
            username: ['', required(3, 12)],
            password: ['', required(3, 64)],
            confirmPassword: ['', required(3, 64)],
            firstName: ['', required(3, 64)],
            lastName: ['', required(3, 64)],
            function: this.fb.group({
                id: ['', Validators.required],
                value: '',
                description: ''
            }),
            profile: this.fb.group({
                id: ['', Validators.required],
                value: ''
            }),
            contract: this.fb.group({
                id: '',
                name: ''
            }),
            box: this.fb.group({
                id: '',
                name: ''
            }),
            deleted: false,
            canViewConfidentialData: false,
            enabled: true
        }, {validator: passwordMatcher});
    }

    private getBoxes() {
        this.sharedService.getBoxes().subscribe(value => {
            this.boxes = value;
        });
    }

    createBox() {
        this.dialog.open(BoxEditComponent).afterClosed().subscribe(res => this.updateBox(res));
    }

    editBox(box: any) {
        this.dialog.open(BoxEditComponent, {data: box}).afterClosed().subscribe(res => {
            if (res) deleteItemFromArray(this.boxes, box);
            this.updateBox(res)
        });
    }

    updateBox(box: any) {
        if (box && box.id !== 0) {
            this.boxes.push(box);
            this.form.get('box').patchValue(box);
        }
    }

    deleteBox(box: any) {
        box.deleted = true;
        this.sharedService.saveBox(box).subscribe(res => {
            if (res) {
                deleteItemFromArray(this.boxes, res);
                this.form.get('box').patchValue({id: ''});
            }
        })
    }
}
