<mat-toolbar class="dialog-toolbar" style="background-color: #009688;">
    <mat-icon fontIcon="mdi-store" fontSet="mdi"></mat-icon>
    <h3 mat-dialog-title>{{'ARTICLES' | translate}}</h3>
    <span fxFlex></span>
    <button mat-dialog-close mat-icon-button tabindex="-1">
        <mat-icon [style.color]="'white'" fontIcon="mdi-close" fontSet="mdi"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <div class="search-container">
        <mat-form-field appearance="fill" floatLabel="never" style="width: 100%">
            <mat-icon fontIcon="mdi-magnify" fontSet="mdi" matPrefix></mat-icon>
            <input #search [placeholder]="'SEARCH' | translate" matInput>
            <mat-icon (click)="clearInput()" *ngIf="filter.nativeElement.value" fontIcon="mdi-close" fontSet="mdi"
                      matSuffix style="color: red"></mat-icon>
        </mat-form-field>
    </div>

    <!-- // _id^code^description^purchase_price^sale_price^purchase_vat^sale_vat^has_serial(0 or 1)^has_lot(0 or 1)       Table-->
    <div class="limiter">
        <div class="container-table100">

            <div class="table">
                <div class="row header">
                    <div class="cell">
                        {{'CODE' | translate}}
                    </div>
                    <div class="cell">
                        {{'DESIGNATION' | translate}}
                    </div>
                    <div class="cell">
                        {{'BUYING_PRICE' | translate}}
                    </div>
                    <div class="cell">
                        {{'SELLING_PRICE' | translate}}
                    </div>
                    <div class="cell">
                        {{'BUYING_VAT' | translate}}
                    </div>
                    <div class="cell">
                        {{'SELLING_VAT' | translate}}
                    </div>
                    <div [style.width.px]="24" class="cell"></div>
                </div>

                <div (click)="selectArticle(article)" *ngFor="let article of articles"
                     [class.row-selected]="selection.isSelected(article)"
                     [style.cursor]="'pointer'"
                     class="row">
                    <div class="cell">{{article[1]}}</div>
                    <div class="cell">{{article[2]}}</div>
                    <div class="cell">{{article[3]}}</div>
                    <div class="cell">{{article[4]}}</div>
                    <div class="cell">{{article[5]}}</div>
                    <div class="cell">{{article[6]}}</div>
                    <div [style.textAlign]="'right'" [style.width.px]="24" class="cell">
                        <mat-icon [fontIcon]="selection.isSelected(article) ? 'mdi-check': ''" [style.color]="'green'"
                                  [style.marginRight.px]="8" [style.top.px]="0" fontSet="mdi"></mat-icon>
                    </div>
                </div>

                <div *ngIf="!articles.length" class="row">
                    <div class="no-cell">
                        No article found
                    </div>
                </div>

            </div>

        </div>
    </div>


</mat-dialog-content>

<mat-dialog-actions align="end">
    <button color="accent" mat-raised-button matDialogClose>{{'EXIT' | translate}}</button>
    <button (click)="validateSelection()" [disabled]="!selection.hasValue()" color="primary" mat-raised-button>
        {{'SAVE' | translate}}
    </button>
</mat-dialog-actions>
