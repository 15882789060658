<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-pencil-box-outline"></mat-icon>
    <h3 mat-dialog-title>{{'HOLIDAY' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <form [formGroup]="form">

        <div>
            <mat-form-field appearance="standard" class="full-width">
                <mat-label>{{'HOLIDAY' | translate}}</mat-label>
                <input matInput placeholder="{{'HOLIDAY' | translate}}" formControlName="title">
            </mat-form-field>
        </div>


        <mat-radio-group [formControl]="holidayType">
            <mat-radio-button *ngFor="let type of holidayTypes" [value]="type">{{type | translate}}</mat-radio-button>
        </mat-radio-group>

        <div *ngIf="oneDayForm; else range">
            <div formGroupName="start" fxLayout="row" fxLayoutGap="6px">
                <mat-form-field appearance="standard">
                    <mat-label>{{'DAY' | translate}}</mat-label>
                    <mat-select formControlName="day" [placeholder]="'DAY' | translate">
                        <mat-option *ngFor="let day of days" [value]="day">{{day}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>{{'MONTH' | translate}}</mat-label>
                    <mat-select formControlName="month" [placeholder]="'MONTH' | translate">
                        <mat-option *ngFor="let month of months" [value]="month">{{month}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <ng-template #range>
            <div formGroupName="start" fxLayout="row" fxLayoutGap="6px">
                <mat-form-field appearance="standard">
                    <mat-label>{{'START_DAY' | translate}}</mat-label>
                    <mat-select formControlName="day" [placeholder]="'START_DAY' | translate">
                        <mat-option *ngFor="let day of days" [value]="day">{{day}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>{{'START_MONTH' | translate}}</mat-label>
                    <mat-select formControlName="month" [placeholder]="'START_MONTH' | translate">
                        <mat-option *ngFor="let month of months" [value]="month">{{month}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div formGroupName="end" fxLayout="row" fxLayoutGap="6px">
                <mat-form-field appearance="standard">
                    <mat-label>{{'END_DAY' | translate}}</mat-label>
                    <mat-select formControlName="day" [placeholder]="'END_DAY' | translate">
                        <mat-option *ngFor="let day of days" [value]="day">{{day}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>{{'END_MONTH' | translate}}</mat-label>
                    <mat-select formControlName="month" [placeholder]="'END_MONTH' | translate">
                        <mat-option *ngFor="let month of months" [value]="month">{{month}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-template>
    </form>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="accent" [mat-dialog-close]="null">{{'CANCEL' | translate}}</button>
    <button mat-button color="primary" (click)="onSave(form.value)" [disabled]="form.invalid">{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>