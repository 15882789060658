<div matDialogTitle style="color: #2a5298">
    {{'SMS_SENDER' | translate}}
</div>
<div fxLayout="column" fxLayoutAlign="start" matDialogContent>
    <mat-form-field>
        <mat-label>{{'DESTINATION' | translate}}</mat-label>
        <input [formControl]="destinationControl" [placeholder]="'DESTINATION' | translate"
               aria-describedby="Destination" matInput tabindex="-1">
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{'SMS_TEMPLATE' | translate}}</mat-label>
        <mat-select (selectionChange)="onSelectTemplate($event)" [formControl]="templateControl" tabindex="-1">
            <mat-option *ngFor="let template of smsTemplates" [value]="template.title">{{template.title}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{'MESSAGE' | translate}}</mat-label>
        <textarea [formControl]="messageControl" [placeholder]="'MESSAGE' | translate" [rows]="40" matInput
                  style="height: 100px"></textarea>
    </mat-form-field>
</div>
<div *ngIf="messageError" style="text-align: center; color: red; font-style: italic;"> {{messageError}} </div>
<div align="end" matDialogActions>
    <button (click)="sendSms()" [disabled]="destinationControl.invalid || messageControl.invalid" color="primary"
            mat-button>{{'SEND' | translate}}</button>
    <button color="accent" mat-button matDialogClose>{{'CANCEL' | translate}}</button>
</div>
