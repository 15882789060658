export class TableView {
    constructor(public ownerId?: number,
                public id?: number,
                public name?: string,
                public module?: string,
                public columns: string = '',
                public publique?: boolean) {
    }
}

export class WorkflowTableView extends TableView {
    constructor(public override ownerId?: number) {
        super(ownerId);
        this.module = 'WORKFLOW'
    }
}
