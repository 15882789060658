/**
 * Created by MBX on 7/12/2017.
 */
export const PAT_TABLE_CONF = [
    {
        label: 'id',
        header: 'ID',
        value: 'id',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: true,
        searchable: true,
        order: 1,
        required: false
    },{
        label: 'patientID',
        header: 'PATIENTID',
        value: 'externalPatientID',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: false,
        hidden: false,
        searchable: true,
        order: 1,
        required: true
    },{
        label: 'title_value',
        header: 'TITLE',
        value: 'title.value',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        order: 2,
        required: true
    },{
        label: 'demographic_gender_description',
        header: 'GENDER',
        value: 'demographic.gender.description',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        order: 5,
        required: true
    },{
        label: 'demographic_maritalStatus_description',
        header: 'MARITALSTATUS',
        value: 'demographic.maritalStatus.description',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        order: 7,
        required: true
    },{
        label: 'weight',
        header: 'WEIGHT',
        value: 'weight',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        order: 9,
        required: true
    },{
        label: 'height',
        header: 'HEIGHT',
        value: 'height',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        order: 10,
        required: true
    },{
        label: 'lastName',
        header: 'LAST_NAME',
        value: 'lastName',
        type: 'string',
        unit: 'conf_data',
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        order: 3,
        required: true
    },{
        label: 'firstName',
        header: 'FIRST_NAME',
        value: 'firstName',
        type: 'string',
        unit: 'conf_data',
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        order: 4,
        required: true
    },{
        label: 'confidential',
        header: 'CONFIDENTIAL_DATA',
        value: 'confidential',
        type: 'boolean',
        unit: null,
        defaultValue: false,
        sortable: true,
        hidden: false,
        searchable: true,
        order: 17,
        required: false
    },{
        label: 'demographic_dateOfBirth',
        header: 'DATEOFBIRTH',
        value: 'demographic.dateOfBirth',
        type: 'string',
        unit: 'date',
        defaultValue: [null],
        sortable: true,
        hidden: false,
        searchable: true,
        order: 8,
        required: true
    }, {
        label: 'imc',
        header: 'IMC',
        value: 'imc',
        type: 'string',
        unit: null,
        defaultValue: '0',
        sortable: true,
        hidden: false,
        searchable: true,
        order: 11,
        required: true
    }, {
        label: 'demographic_confidentiality_value',
        header: 'Conf.',
        value: 'demographic.confidentiality.value',
        type: 'string',
        unit: 'conf',
        defaultValue: 'U',
        sortable: true,
        hidden: false,
        searchable: true,
        order: 6,
        required: true
    },{
        label: 'demographic_confidentiality_description',
        header: 'Conf.',
        value: 'demographic.confidentiality.description',
        type: 'string',
        unit: null,
        defaultValue: 'Ordinaire',
        sortable: true,
        hidden: false,
        searchable: true,
        order: 16,
        required: false
    }, {
        label: 'sc',
        header: 'SC',
        value: 'sc',
        type: 'string',
        unit: 'Surface corporelle',
        defaultValue: '0',
        sortable: true,
        hidden: false,
        searchable: true,
        order: 12,
        required: true
    }, {
        label: 'createdDate',
        header: 'CREATED_DATE',
        value: 'createdDate',
        type: 'date',
        unit: 'date',
        defaultValue: [null],
        sortable: true,
        hidden: false,
        searchable: true,
        order: 13,
        required: true
    }
];
