import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Observable} from "rxjs";


@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private _router: Router,
                private _authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this._authService.isLoggedIn()) {
            return !this._router.navigate([this._authService.redirectUrl]);
        } else return true;
    }

}
