import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CdkTableModule} from '@angular/cdk/table';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CaptionValueComponent} from './caption-value/caption-value.component';
import {CancelFormComponent} from './cancel-form/cancel-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {PatientDetailComponent} from '../patient/patient-detail/patient-detail.component';
import {DynamicFormModule} from './dynamic-form';
import {ImageGalleryComponent} from './image-gallery/image-gallery.component';
import {LocalDatePipe} from './local-date.pipe';
import {ViewerSelectComponent} from './viewer-select/viewer-select.component';
import {RdvPrintComponent} from './rdv-print/rdv-print.component';
import {CompleteExamFormComponent} from './complete-exam-form/complete-exam-form.component';
import {PatientArrivedComponent} from './patient-arrived/patient-arrived.component';
import {ReferringPhysicianAddComponent} from './referring-physician-add/referring-physician-add.component';
import {InsuranceAddComponent} from './insurance-add/insurance-add.component';
import {PatientScheduledExamsComponent} from './patient-scheduled-exams/patient-scheduled-exams.component';
import {FileExplorerModule} from './file-explorer';
import {PaymentOrderComponent} from './payment-order/payment-order.component';
import {SchedulerService} from '../scheduler/scheduler.service';
import {FirePdfComponent} from './fire-pdf/fire-pdf.component';
import {WsService} from '../ws.service';
import {PrescriptionComponent} from './prescription';
import {MessageComponent} from './message/message.component';
import {PerformerAssignComponent} from './performer-assign/performer-assign.component';
import {TemplateSearchComponent} from './template-search/template-search.component';
import {ReportSearchComponent} from './report-search/report-search.component';
import {QuillEditorComponent} from './quill-editor/quill-editor.component';
import {CommentsComponent} from './comments/comments.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TableViewComponent} from './table-view/table-view.component';
import {PlyrComponent, PlyrModule} from 'ngx-plyr';
import {NormalReportComponent} from './normal-report/normal-report.component';
import {NewCategoryComponent} from '../setting/report-template-setting/report-template-setting.component';
import {PacsSearchComponent} from './pacs-search/pacs-search.component';
import {TableLineComponent} from './table-line/table-line.component';
import {ExcelExamComponent} from './excel-exam/excel-exam.component';
import {BookletPrintComponent} from './booklet-print/booklet-print.component';
import {PrintLayoutComponent} from './print-layout/print-layout.component';
import {LoEditorComponent} from './lo-editor/lo-editor.component';
import {ValueDescriptionComponent} from './value-description/value-description.component';
import {PatientSearchComponent} from '../patient/patient-search/patient-search.component';
import {DeleteConfirmComponent} from './delete-confirm/delete-confirm.component';
import {PrintCountComponent} from './print-count/print-count.component';
import {TechniqueEditComponent} from './technique-edit/technique-edit.component';
import {StockMovementComponent} from './stock-movement/stock-movement.component';
import {StockArticlesComponent} from './stock-articles/stock-articles.component';
import {ArticleLotsComponent} from './article-lots/article-lots.component';
import {ArticleSerialsComponent} from './article-serials/article-serials.component';
import {ColorPickerComponent} from './color-picker/color-picker.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {FormattingRuleComponent} from './formatting-rule/formatting-rule.component';
import {TableFormatterComponent} from './table-formatter/table-formatter.component';
import {IconSelectComponent} from './icon-select/icon-select.component';
import {FormattingStyleLineComponent} from './formatting-style-line/formatting-style-line.component';
import {FullCalendarModule} from '@fullcalendar/angular';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
// import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import {CalendarEventComponent, FtMenuComponent} from './ft-calendar';
import {UserUpdateComponent} from './user-update/user-update.component';
import {ngfModule} from "angular-file";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {AppRefreshSnackbarComponent} from './app-refresh-snackbar/app-refresh-snackbar.component';
import {LoaderComponent} from './loader/loader.component';
import {ReporterComponent} from "./reporter/reporter.component";
import {QueueComponent} from './queue/queue.component';
import {ViewportDirective} from './viewport.directive';
import {PdfViewerModule} from "ng2-pdf-viewer";
import {ReferringPhysiciansSearchComponent} from './referring-physicians-search/referring-physicians-search.component';
import {LogsComponent} from './logs/logs.component';
import {FilterByPipe, GroupByPipe, InitialsPipe, NumericPipe, SumWorkItemByPipe} from './pipes';
import {SmsSenderComponent} from './sms-sender/sms-sender.component';
import { ReportParagraphComponent } from './report-paragraph/report-paragraph.component';
import { ReportDialogComponent } from './report-dialog/report-dialog.component';
import { StudyExplorerComponent } from './study-explorer/study-explorer.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import {CdkTreeModule} from "@angular/cdk/tree";
import { StudyViewerComponent } from './study-viewer/study-viewer.component';


FullCalendarModule.registerPlugins([
    dayGridPlugin,
    timeGridPlugin,
    listPlugin,
    interactionPlugin,
    resourceTimeGridPlugin,
    resourceTimelinePlugin,
    // momentTimezonePlugin
]);

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CdkTableModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        FlexLayoutModule,
        MatNativeDateModule,
        MatMenuModule,
        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
        MatCardModule,
        MatButtonModule,
        MatProgressBarModule,
        MatRadioModule,
        MatDialogModule,
        MatOptionModule,
        MatSelectModule,
        MatListModule,
        MatTabsModule,
        MatSidenavModule,
        MatDatepickerModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatButtonToggleModule,
        TranslateModule,
        MatTooltipModule,
        DynamicFormModule,
        MatSlideToggleModule,
        MatGridListModule,
        MatToolbarModule,
        FileExplorerModule,
        PdfViewerModule,
        MatTreeModule,
        PlyrModule,
        DragDropModule,
        MatExpansionModule,
        ColorPickerModule,
        FullCalendarModule,
        ngfModule,
        MatBottomSheetModule,
        MatIconModule,
        CdkTreeModule
    ],
    declarations: [
        CaptionValueComponent,
        CancelFormComponent,
        PatientDetailComponent,
        ImageGalleryComponent,
        LocalDatePipe,
        ViewerSelectComponent,
        RdvPrintComponent,
        CompleteExamFormComponent,
        PatientArrivedComponent,
        CompleteExamFormComponent,
        ReferringPhysicianAddComponent,
        InsuranceAddComponent,
        PatientScheduledExamsComponent,
        PaymentOrderComponent,
        FirePdfComponent,
        PrescriptionComponent,
        MessageComponent,
        PerformerAssignComponent,
        TemplateSearchComponent,
        ReportSearchComponent,
        QuillEditorComponent,
        CommentsComponent,
        TableViewComponent,
        NormalReportComponent,
        NewCategoryComponent,
        PacsSearchComponent,
        TableLineComponent,
        ExcelExamComponent,
        BookletPrintComponent,
        PrintLayoutComponent,
        LoEditorComponent,
        PatientSearchComponent,
        ValueDescriptionComponent,
        DeleteConfirmComponent,
        PrintCountComponent,
        TechniqueEditComponent,
        StockMovementComponent,
        StockArticlesComponent,
        ArticleLotsComponent,
        ArticleSerialsComponent,
        ColorPickerComponent,
        FormattingRuleComponent,
        TableFormatterComponent,
        IconSelectComponent,
        FormattingStyleLineComponent,
        CalendarEventComponent,
        FtMenuComponent,
        UserUpdateComponent,
        AppRefreshSnackbarComponent,
        LoaderComponent,
        ReporterComponent,
        QueueComponent,
        ViewportDirective,
        ReferringPhysiciansSearchComponent,
        LogsComponent,
        FilterByPipe,
        InitialsPipe,
        GroupByPipe,
        NumericPipe,
        SumWorkItemByPipe,
        SmsSenderComponent,
        ReportParagraphComponent,
        ReportDialogComponent,
        StudyExplorerComponent,
        PaymentFormComponent,
        MessageDialogComponent,
        StudyViewerComponent
    ],
    exports: [
        CdkTableModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        FlexLayoutModule,
        MatNativeDateModule,
        MatMenuModule,
        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
        MatProgressBarModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        MatSelectModule,
        MatCardModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        FileExplorerModule,
        MatListModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatDatepickerModule,
        MatSidenavModule,
        MatTooltipModule,
        MatAutocompleteModule,
        TranslateModule,
        DynamicFormModule,
        MatButtonToggleModule,
        MatGridListModule,
        MatToolbarModule,
        CaptionValueComponent,
        CancelFormComponent,
        PatientDetailComponent,
        ImageGalleryComponent,
        ViewerSelectComponent,
        CompleteExamFormComponent,
        RdvPrintComponent,
        PatientArrivedComponent,
        ReferringPhysicianAddComponent,
        InsuranceAddComponent,
        PatientScheduledExamsComponent,
        PaymentOrderComponent,
        LocalDatePipe,
        PdfViewerModule,
        PrescriptionComponent,
        TemplateSearchComponent,
        MessageComponent,
        PerformerAssignComponent,
        ReportSearchComponent,
        CommentsComponent,
        QuillEditorComponent,
        NormalReportComponent,
        NewCategoryComponent,
        DragDropModule,
        PlyrModule,
        PlyrComponent,
        MatTreeModule,
        PacsSearchComponent,
        ExcelExamComponent,
        BookletPrintComponent,
        LoEditorComponent,
        PatientSearchComponent,
        ValueDescriptionComponent,
        DeleteConfirmComponent,
        ColorPickerComponent,
        FormattingRuleComponent,
        TableFormatterComponent,
        CalendarEventComponent,
        UserUpdateComponent,
        AppRefreshSnackbarComponent,
        LoaderComponent,
        ReporterComponent,
        FilterByPipe,
        InitialsPipe,
        GroupByPipe, NumericPipe, SumWorkItemByPipe,
        ReportDialogComponent, StudyExplorerComponent,PaymentFormComponent
    ],
    providers: [SchedulerService, WsService]

})
export class FireSharedModule {
}
