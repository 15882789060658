<mat-toolbar class="dialog-toolbar" style="background-color: #009688;">
    <mat-icon fontIcon="mdi-barcode-scan" fontSet="mdi"></mat-icon>
    <h3 mat-dialog-title>{{'ARTICLE_SERIALS' | translate}}</h3>
    <span fxFlex></span>
    <button mat-dialog-close mat-icon-button tabindex="-1">
        <mat-icon [style.color]="'white'" fontIcon="mdi-close" fontSet="mdi"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <div class="search-container">
        <mat-form-field appearance="fill" floatLabel="never" style="width: 100%">
            <mat-icon fontIcon="mdi-magnify" fontSet="mdi" matPrefix></mat-icon>
            <input #search [placeholder]="'SEARCH' | translate" matInput>
            <mat-icon (click)="clearInput()" *ngIf="filter.nativeElement.value" fontIcon="mdi-close" fontSet="mdi"
                      matSuffix style="color: red"></mat-icon>
        </mat-form-field>
    </div>

    <div class="limiter">
        <div class="container-table100">

            <div class="table">
                <div class="row header">
                    <div class="cell">
                        {{'CODE' | translate}}
                    </div>
                    <div [style.color]="selection.selected.length === neededQuantity ? 'green': 'red'"
                         [style.width.px]="24" class="cell"
                         style="text-align: right;font-weight: bold;padding-right: 4px;font-size: 14px;">
                        {{selection.selected.length}}/{{neededQuantity}}
                    </div>
                </div>

                <div (click)="selectSerial(serial)" *ngFor="let serial of serials"
                     [class.row-selected]="selection.isSelected(serial)"
                     [style.cursor]="'pointer'"
                     class="row">

                    <div class="cell">{{serial}}</div>

                    <div [style.textAlign]="'right'" [style.width.px]="24" class="cell">
                        <mat-icon [fontIcon]="selection.isSelected(serial) ? 'mdi-check': ''" [style.color]="'green'"
                                  [style.marginRight.px]="8" [style.top.px]="0" fontSet="mdi"></mat-icon>
                    </div>
                </div>

                <div *ngIf="!serials.length" class="row">
                    <div class="no-cell">
                        No serial number found
                    </div>
                </div>

            </div>

        </div>
    </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button color="accent" mat-raised-button matDialogClose>{{'EXIT' | translate}}</button>
    <button (click)="validateSelection()" [disabled]="!selection.hasValue()" color="primary" mat-raised-button>{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>
