<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
    <h3 mat-dialog-title>{{'ORDERPRIORITY' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <form [formGroup]="form">


        <mat-form-field appearance="standard" class="full-width">
            <mat-label>{{'NAME' | translate}}</mat-label>
            <input matInput placeholder="{{'NAME' | translate}}" formControlName="value">
        </mat-form-field>

        <mat-form-field appearance="standard" class="full-width">
            <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
            <textarea matInput placeholder="{{'DESCRIPTION' | translate}}" formControlName="description"></textarea>
        </mat-form-field>

        <div formGroupName="dateRange">
            <div class="padded has-unit">
                <mat-form-field appearance="standard">
                    <mat-label>{{'STARTAFTER' | translate}}</mat-label>
                    <input matInput placeholder="{{'STARTAFTER' | translate}}" formControlName="startDate"
                           type="number">
                </mat-form-field>
                <span>{{'DAYS' | translate}}</span>
            </div>
            <div class="padded has-unit">
                <mat-form-field appearance="standard">
                    <mat-label>{{'ENDBEFORE' | translate}}</mat-label>
                    <input matInput placeholder="{{'ENDBEFORE' | translate}}" formControlName="endDate" type="number">
                </mat-form-field>
                <span>{{'DAYS' | translate}}</span>
            </div>
        </div>

        <div>
            {{'COLOR' | translate}}: <span class="color-area" [(colorPicker)]="color"
                                           [style.background]="color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>

    </form>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="accent" [mat-dialog-close]="null">{{'CANCEL' | translate}}</button>
    <button mat-button color="primary" (click)="onSave(form.value)" [disabled]="form.invalid">{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>
