export class StockMovement {
    constructor(
        public id?: number,
        public type?: string,
        public number?: number,
        public date?: any,
        public store?: number,
        public lines?: Article[],
        public priceMode: string = 'HT',
        public globalDiscount: number = 0,
        public globalDiscountPercentage: number = 0,
        public ttcAmount?: number,
        public vatAmount?: number,
        public htAmount?: number,
        public draft?: boolean,
        public valid?: boolean,
        public patient?: string,
        public visitId?: string,
        public comment?: string
    ) {
    }
}

export class Article {
    constructor(
        public id?: number,
        public externalId?: number,
        public code?: string,
        public description?: string,
        public quantity: number = 1,
        public price: number = 0.0,
        public store?: number,
        public lots?: string,
        public serials?: string,
        public vatAmount?: number,
        public comment: boolean = false,
        public hasSerials: boolean = false,
        public hasLots: boolean = false,
        public discount: number = 0.0,
        public totalPrice: number = 0.0
    ) {
    }
}