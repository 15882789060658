export const STATISTIC_VIEWS = [
    {
        name: 'PATIENT',
        states: [
            {value: 'ALL'}
        ]
    }, {
        name: 'PAYMENT',
        states: [
            {value: 'DATE'}
        ]
    }, {
        name: 'EXAM',
        states: [
            {value: 'GENERAL_VIEW'},
            {value: 'PERFORMING_RADIOLOGIST'},
            {value: 'REFERRING_PHYSICIAN'},
            {value: 'TECHNICIANS'},
            {value: 'AET_S'},
            {value: 'REASONS_FOR_EXAM'}
        ]
    },
]
