import {BehaviorSubject, merge, of as observableOf, Subscription} from 'rxjs';

import {catchError, debounceTime, delay, map, startWith, switchMap, tap} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {WorkflowService} from './workflow.service';
import {EXAMS_COLS, WF_COLUMNS} from './table-conf';
import {
    ColumnDataType,
    DisplayMode,
    FormatRule,
    FormattingRule,
    GeneralSetting,
    PatientWorkflow,
    Profile,
    QueryParam,
    RendezVous,
    TableColumn,
    TableConfig,
    TableView,
    Viewer,
    WorkflowFilter,
    WorkflowItem,
    WorkflowTableView
} from '../model';
import {ActivatedRoute, Router} from '@angular/router';

import {dropWhile, findIndex, get, map as _map, remove, set as _set, sortBy, union, xorBy} from 'lodash';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import {
    DateUtils,
    defaultWorkflowFormatting,
    getIconColor,
    getPatientStatusIcon,
    getReportIconColor,
    getReportingStatusIcon,
    loadRLEScripts,
    StringUtils,
    sumBy
} from '../utils';
import {TranslateService} from '@ngx-translate/core';
import {
    checkCondition,
    CommentsComponent,
    CompleteExamFormComponent,
    DeleteConfirmComponent,
    getConfidentialityColor,
    getDisplayStyle,
    groupWorkflowData,
    hasPermission,
    LocalStorageService,
    PacsSearchComponent,
    PatientArrivedComponent,
    paymentColor,
    PaymentOrderComponent,
    PerformerAssignComponent,
    PrescriptionComponent,
    RdvPrintComponent,
    SharedService,
    shortName,
    specialAttributes,
    StockMovementComponent,
    TableViewComponent,
    waitingDuration
} from '../shared';
import {SchedulerService} from '../scheduler/scheduler.service';
import {ReportingService} from '../reporting/reporting.service';
import {SettingService} from '../setting/setting.service';
import {tableAnimation} from '../animations';
import {WsService} from '../ws.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import * as FileSaver from 'file-saver';
import * as numeral from 'numeral';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AppConfigService} from '../app-config.service';
import {ExamAdvancedComponent} from '../scheduler/exam-advanced/exam-advanced.component';


const WF_TABLE_CONFIG_NAME = 'Workflow_v3';
const WF_FILTER = 'wf_filter';
declare var LPW: any;

@Component({
    selector: 'app-workflow',
    templateUrl: './workflow.component.html',
    styleUrls: ['./workflow.component.scss'],
    animations: [tableAnimation,
        trigger('detailExpand', [
            state('collapsed', style({
                height: '0px',
                minHeight: '0',
                display: 'none',
                visibility: 'hidden',
                zIndex: '-1'
            })),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class WorkflowComponent implements OnInit, AfterViewInit, OnDestroy {

    advancedSearch = false;
    filterForm: FormGroup | undefined;
    selectedView = new BehaviorSubject<TableView>(new TableView(0, 0, 'WORKFLOW_VIEW'));

    totalHidden: any;
    turnover: any;

    displayedColumns = [];
    columnsToDisplay = [];
    availableColumns = [];
    examsColumns: { header: string, attr: string, hidden: boolean }[] = EXAMS_COLS;
    examsColumnsToDisplay: { header: string, attr: string, hidden: boolean }[] = EXAMS_COLS;
    dataSource = new MatTableDataSource<PatientWorkflow>();
    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    osirixViewers: Viewer[] = [];

    expandedElement: PatientWorkflow;

    @ViewChild('date', {static: false}) _date: ElementRef;
    @ViewChild('filter', {static: true}) filter: ElementRef;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    newExamData = {
        spsStatus: 'ARRIVED',
        resource: 'n/a',
        patient: null,
        isr: null,
        selectedDateRange: {start: moment(), end: moment().add(15, 'm')},
        editable: true,
        queryParam: null
    };

    sorting: { active: string, direction: 'asc' | 'desc' };

    modalities: any[] = ['CR', 'CT', 'DX', 'MG', 'MR', 'US', 'XA'];
    technicians: any[];
    physicians: any[];
    private wf = new WorkflowFilter();

    private newWindow: any;
    private user: any;
    profile: Profile;
    canViewConfData: boolean;
    private examStatuses: string[];
    private workflowFilterSubject = new BehaviorSubject<WorkflowFilter>(new WorkflowFilter());
    private query = new BehaviorSubject<string>(null);
    generalSetting: GeneralSetting;
    meddreamViewer: Viewer;
    viewers: Viewer[] = [];
    workflowTableConfig: TableConfig;
    linesFormattingRules: FormattingRule[] = [];
    columnsFormattingRules: FormattingRule[] = [];
    styles = {};

    public totalExams: any;
    public defaultPageSize = 100;

    currencyFormat = 'DH';
    syngoviaActivated = false;
    public totalExamsHidden: any;
    currentDate: any; //= moment().format( 'LLLL');
    views: TableView[] = [new TableView(0, 0, 'WORKFLOW_VIEW'), new TableView(0, 0, 'PAYMENTS')];
    todayFormat = 'HH:mm';
    defaultViewer: Viewer;

    printers: any[] = [];
    public readonly numberFormat: string;
    totalAmount = {totalAmount: 0, paidAmount: 0, globalDiscount: 0, leftAmount: 0};
    private subTotalSubject: BehaviorSubject<any> = new BehaviorSubject<any>([0, 0, 0, 0]);
    private ws$: Subscription;

    private static isConditionFilled(colDataType: ColumnDataType, formatRule: FormatRule, data: any, firstValue: any, secondValue: any): boolean {
        return checkCondition(colDataType, formatRule, data, firstValue, secondValue);
    }

    trackById = (index: number, item: any): string => item.patientID;

    constructor(private workflowService: WorkflowService,
                private router: Router,
                private route: ActivatedRoute,
                private dialog: MatDialog,
                private scheduler: SchedulerService,
                private localStorage: LocalStorageService,
                private setting: SettingService,
                private _config: AppConfigService,
                private shared: SharedService,
                private wsService: WsService,
                private translate: TranslateService,
                private fb: FormBuilder,
                private snack: MatSnackBar,
                private reportingService: ReportingService) {


        this.currencyFormat = _config.currencyFormat;
        this.numberFormat = _config.numberFormat;
        this.syngoviaActivated = _config.syngoviaActivated;

        this.user = get(this.route.snapshot.data, 'user');
        this.profile = get(this.user, 'profile');
        this.canViewConfData = this.user.canViewConfidentialData;

        this.currentDate = moment().format(this._config.appLang === 'en' ? 'LL' : 'LLL');

        let userId = this.user.id;

        this.subTotalSubject.asObservable().subscribe((data: any[]) => {
            if (data && data.length !== 0) {
                this.totalAmount['totalAmount'] = data[0];
                this.totalAmount['paidAmount'] = data[1];
                this.totalAmount['globalDiscount'] = data[2];
                this.totalAmount['leftAmount'] = data[3];
            }
        });

        this.setting.getTableConfig(WF_TABLE_CONFIG_NAME, userId).subscribe(result => {
            if (result) {
                dropWhile(result.tableColumns, {label: 'completedReportStatus'});

                let tableColumns = get(result, 'tableColumns', []);
                let xorArray: TableColumn[] = xorBy(tableColumns, WF_COLUMNS, 'label');

                this.workflowTableConfig = result;

                if (xorArray.length !== 0) {
                    this.workflowTableConfig.tableColumns = union(tableColumns, xorArray);
                    this.onSaveTableConfig(this.workflowTableConfig);
                } else this.dispatchRules(result.formattingRules);
            } else {
                this.workflowTableConfig = {
                    id: 0,
                    userId: userId,
                    name: WF_TABLE_CONFIG_NAME,
                    tableColumns: WF_COLUMNS,
                    formattingRules: defaultWorkflowFormatting
                };

                this.onSaveTableConfig(this.workflowTableConfig);

            }
        });

        this.setting.getGeneralSetting().subscribe(value => {
            this.generalSetting = value;
            localStorage.setItem('eid_api_url', this.generalSetting.scannerIdUrl);

            if (this.generalSetting && (!this.generalSetting.billingRequired || !this.profile.managePayment)) this.availableColumns = this.availableColumns.filter(col => !['paymentStatus', 'totalAmount', 'paidAmount', 'leftAmount', 'discount', 'globalDiscount', 'billed'].includes(col.label));
            this.setColumnsToDisplay(this.availableColumns, this.selectedView.getValue().name);
            this.examsColumns = this.generalSetting && this.generalSetting.billingRequired ? this.examsColumns : this.examsColumns.filter(col => !['totalAmount', 'paidAmount', 'discount'].includes(col.attr));
            this.examsColumnsToDisplay = this.generalSetting && this.generalSetting.billingRequired ? this.examsColumnsToDisplay : this.examsColumnsToDisplay.filter(col => !['totalAmount', 'paidAmount', 'discount'].includes(col.attr));
        });

        this.translate.get('EXAM_STATUSES').subscribe(res => this.examStatuses = res.split('_'));

        this.sorting = this.localStorage.getItem('wf_sorting') || {active: 'id', direction: 'asc'};
        this.defaultPageSize = Number(this.localStorage.getItem('wf_page_size')) || 50;

        this.displayedColumns = WF_COLUMNS;
        this.availableColumns = this.localStorage.getItem(this.selectedView.getValue().name) || sortBy(WF_COLUMNS.filter(item => !item.hidden), 'order');

        this.createFilterForm();

        setTimeout(() => this.ws$ = this.subscribeToWsTopic(), 2000);
    }

    ngOnDestroy() {
        this.ws$?.unsubscribe();
    }


    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.columnsToDisplay, event.previousIndex, event.currentIndex);
        moveItemInArray(this.availableColumns, event.previousIndex, event.currentIndex);

        this.setColumnsToDisplay(this.availableColumns, this.selectedView.getValue().name);
    }

    drop2(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.examsColumns, event.previousIndex, event.currentIndex);
        this.localStorage.setItem('exams_cols', this.examsColumns);

        this.examsColumnsToDisplay = this.examsColumns.filter(it => !it.hidden);

    }

    toggleColumn(col) {
        let idx = findIndex(this.availableColumns, {header: col.header});
        this.availableColumns[idx].hidden = !col.hidden;
        this.setColumnsToDisplay(this.availableColumns, this.selectedView.getValue().name);
    }

    toggleColumn2(col) {
        let idx = findIndex(this.examsColumns, {header: col.header});
        this.examsColumns[idx].hidden = !col.hidden;
        this.examsColumnsToDisplay = this.examsColumns.filter(it => !it.hidden);
        this.localStorage.setItem('exams_cols', this.examsColumns);
    }

    setColumnsToDisplay(columns, key: string) {
        this.localStorage.setItem(key, columns);

        this.columnsToDisplay = _map(columns.filter(c => !c.hidden), 'value');
        this.columnsToDisplay = union(this.hiddenUser() ? ['hidden'] : [], this.columnsToDisplay, ['action']);
    }

    newExam(row: any) {
        this.scheduler.getPatientById(row.patientId).subscribe(patient => {
            if (patient) this.dialog.open(ExamAdvancedComponent, {
                data: {
                    spsStatus: 'ARRIVED',
                    resource: 'n/a',
                    patient: patient, isr: null,
                    selectedDateRange: {start: moment(), end: moment().add(15, 'h')},
                    editable: true,
                    queryParam: null,
                    admissionNumber: row.admissionNumber,
                    accessionNumbers: row.workflowItems.map(it => it.accessionNumber),
                    paymentID: row.paymentID,
                    panelClass: 'exam-dialog'
                },
                disableClose: true
            }).afterClosed().subscribe(res => {
                if (res) this.workflowFilterSubject.next(this.wf)
            });
        });
    }

    createExamWithPayment(queryParam: QueryParam = null) {
        _set(this.newExamData, 'queryParam', queryParam);
        this.dialog.open(ExamAdvancedComponent, {
            data: this.newExamData,
            disableClose: true, panelClass: 'exam-dialog'
        }).afterClosed().subscribe(res => {
            if (res && res['isrId']) this.finishExam({isrId: res.isrId})
        });
    }


    ngOnInit() {
        this.selectedView.subscribe(value => this.changeColumns(value));
        this.examsColumns = this.localStorage.getItem('exams_cols') || EXAMS_COLS;

        setTimeout(() => {
            if (this.generalSetting && !this.generalSetting.billingRequired) {
                this.examsColumns = this.examsColumns.filter(it => !['discount', 'totalAmount'].includes(it.attr));
                this.examsColumnsToDisplay = this.examsColumns.filter(it => !it.hidden);
            }
        });

        this.filter.nativeElement.focus();
        this.getTableViews();
        this.getViewers();
        this.shared.getPrinters().subscribe(data => this.printers = data);

        // this.shared.getWorkflowSearchField().subscribe(data => {
        //     [this.physicians, this.modalities, this.technicians] = data;
        // });
        //
    }

    printRdvForResult(row) {
        let rdv = new RendezVous(
            row.patientID,
            row.accessionNumber,
            row.patientName,
            moment().format('YYYY-MM-DD'), '', ''
        );

        this.dialog.open(RdvPrintComponent, {data: rdv});
    }

    public printReportingTask(row, printer?: string) {
        let matSnackBarRef = this.snack.open(this.translate.instant('PRINTING_IN_PROGRESS'), '', {duration: 10000});

        if (this.generalSetting.reportPrintMode === 'CHROME') this.reportingService.printSimpleReport(row.reportingTaskId, printer, 1).subscribe(_ => matSnackBarRef.dismiss());
        else {
            this.reportingService
                .printCupsSimpleReport(row.reportingTaskId, printer, '1')
                .subscribe(ok => {
                    matSnackBarRef.dismiss();
                    if (ok['status'] !== 'ok') console.log('Cannot print the report');
                    else this.snack.open(this.translate.instant('FINALIZING_PRINTING'), '', {duration: 3000});
                });
        }
    }

    public printBooklet(row, printer?: string) {
        let matSnackBarRef = this.snack.open(this.translate.instant('PRINTING_IN_PROGRESS'), '', {duration: 10000});

        if (this.generalSetting.reportPrintMode === 'CHROME') this.reportingService.printReport(row.reportingTaskId).subscribe(_ => matSnackBarRef.dismiss());
        else {
            this.reportingService
                .printCupsReport(row.reportingTaskId, printer, 1)
                .subscribe(response => {
                    if (response['status'] !== 'ok') alert('Cannot print the booklet');
                    else {
                        matSnackBarRef.dismiss();
                        this.snack.open(this.translate.instant('FINALIZING_PRINTING'), '', {duration: 4000});
                    }
                });
        }
    }


    showPatientFolder = (row) => this.router.navigate(['/patients/details', row.patientId]);

    getConfidentialityColor = (conf: string): string => getConfidentialityColor(conf);

    getPatientStatusIcon(row: WorkflowItem): string {
        if (this.isPatientAngry(row)) return 'mdi-emoticon-sad';
        return getPatientStatusIcon(row.patientStatus);
    }

    getReportingStatusIcon = (status): string => getReportingStatusIcon(status);

    getIconColor(row: WorkflowItem): string {
        return this.isPatientAngry(row) ? '#f00' : getIconColor(row.patientStatus);
    }

    getReportIconColor = (status): string => getReportIconColor(status);

    shortenName(name: string): string {
        return shortName(name)
    }

    getPaymentStatusColor = (status: string): string => paymentColor(status);

    openReport(row: WorkflowItem, newWind?: boolean): void {
        if (!row.reportingTaskId) return;
        if (newWind) {
            if (this.newWindow && !this.newWindow.closed) {
                this.newWindow.focus();
                this.newWindow.location.pathname = `/reporting/report-edition/${row.reportingTaskId}`;
            } else {
                this.newWindow = window.open(`/reporting/report-edition/${row.reportingTaskId}`, row.reportingTaskId.toString(), 'toolbar=0,location=0,menubar=0,left');

                this.newWindow.addEventListener('beforeunload', () => this.newWindow = null);
            }
        } else this.router.navigateByUrl(`/reporting/report-edition/${row.reportingTaskId}`).then(console.log);
    }

    launchOsirix(aeTitle: string, patientID: string, studyInstanceUID: string) {
        this.reportingService.launchOsirix(aeTitle, patientID, studyInstanceUID).subscribe(console.log);
    }

    startExam(row) {
        this.scheduler.startExamByAN(row.accessionNumber).subscribe(res => {
            setTimeout(() => this.workflowFilterSubject.next(this.wf), 10000);
            this.snack.open(this.examStatuses[3], 'OK', {duration: 2000});
        })
    }

    finishExam(row) {
        this.scheduler
            .finishExam(row.isrId)
            .subscribe(next => {
                setTimeout(() => this.workflowFilterSubject.next(this.wf), 10000);
                this.snack.open(this.examStatuses[0], 'OK', {duration: 2000});
            });
    }

    addPayment(row: WorkflowItem) {
        this.dialog.open(PaymentOrderComponent, {
            data: {
                spsId: row.spsId,
                patientID: row.patientID,
                patientName: row.patientName
            },
            disableClose: true
        })
            .afterClosed()
            .subscribe(order => {
                if (order)
                    this.scheduler
                        .orderPayment(order)
                        .subscribe(res => {
                            if (res && res.id) {
                                this.snack.open(this.translate.instant('NEW_PAYMENT_DONE'), 'OK', {duration: 2000});
                            }
                        });
            });
    }

    onCompleteExam(row) {
        this.dialog.open(CompleteExamFormComponent, {
            data: {
                item: row,
                isrId: row.isrId,
                canViewConfData: this.canViewConfData
            }
        }).afterClosed()
            .subscribe(sps => {
                if (sps) {
                    this.snack.open(this.examStatuses[1], 'OK', {duration: 2000});
                }
            });
    }

    onPatientLeave(row) {
        this.scheduler
            .exitPatient(row.accessionNumber)
            .subscribe(nxt => {
                setTimeout(() => this.workflowFilterSubject.next(this.wf), 10000);
                this.snack.open(this.examStatuses[2], 'OK', {duration: 2000});
            });
    }

    createPrescription(row) {
        let patient = {patientName: row.patientName, patientID: row.patientID};
        let physician = {physician: this.user.fullName};
        this.dialog.open(PrescriptionComponent, {data: {patient, physician}, width: '60%'})
            .afterClosed()
            .subscribe(res => console.log(res));
    }

    enterPatient(row) {
        this.scheduler.getISRByAN(row.accessionNumber).subscribe(isr => {
            this.dialog
                .open(PatientArrivedComponent, {
                    data: {isr: isr, canViewConfData: this.canViewConfData},
                    width: '600px'
                })
                .afterClosed()
                .subscribe(isr => {
                    if (isr) {
                        this.scheduler.markPatientAsArrived(isr)
                            .subscribe(next => {
                                setTimeout(() => this.workflowFilterSubject.next(this.wf), 10000);
                                this.snack.open(this.translate.instant('PATIENT_AUTHORIZED'), 'Ok', {duration: 2000})
                            });
                    }
                });
        });
    }

    clearInput = (field) => this.filterForm.get(field).patchValue(null);
    can = (row: any, action: string): boolean => (this.profile[action] !== 'NONE') && !row.confidential || this.canViewConfData;
    cannot = (action: string): boolean => this.profile[action] === 'NONE';

    isGranted = (row: WorkflowItem, status: string): boolean => hasPermission(status, row);

    private buildQuery = () => this.workflowFilterSubject.asObservable().subscribe((wf: WorkflowFilter) =>
        this.query.next([wf.key.replace('@', ''), wf.dateRange, wf.technicianId, wf.physicianId, wf.patientStatuses, wf.reportStatuses, wf.modalities, wf.paymentStatuses].join('@')));

    columnFormattingRules = (header: string): FormattingRule[] => this.columnsFormattingRules ? this.columnsFormattingRules.filter(it => it.targetColumn === header) : [];

    private createFilterForm() {
        this.filterForm = this.fb.group({
            key: '',
            startDate: new Date(),
            endDate: new Date(),
            period: 'TODAY',
            modality: null,
            technicianId: null,
            physicianId: null,
            reportStatus: null,
            patientStatus: null,
            paymentStatus: null
        });

        this.filterForm.valueChanges.subscribe(value => this.buildWorkflowFilter(value));
    }

    changePeriod() {
        this.filterForm.get('period').patchValue('OT');
    }

    changeRange(e) {
        let dateRange = DateUtils.PeriodDateRange(e.value);
        this.filterForm.patchValue(dateRange);
        this.wf.dateRange = `${dateRange.startDate.format('YYYYMMDD')}-${dateRange.endDate.format('YYYYMMDD')}`;
        this.workflowFilterSubject.next(this.wf);
    }

    deleteExam(wf: WorkflowItem) {
        this.dialog.open(DeleteConfirmComponent).afterClosed().subscribe(ok => {
            if (ok) {
                this.scheduler.deleteExam(wf.isrId).subscribe(res => {
                    if (res) this.snack.open(this.translate.instant('EXAM_DELETED'), 'Ok', {duration: 2000});
                });
            }
        });
    }

    private resetPaginator = () => this.query.subscribe(() => this.paginator.pageIndex = 0);

    ngAfterViewInit() {
        if (this.isRemoteEyeViewer()) loadRLEScripts();

        this.buildQuery();
        this.resetPaginator();


        this.sort.sortChange.subscribe(res => {
            this.paginator.pageIndex = 0;
            this.localStorage.setItem('wf_sorting', res);
        });

        let observedFilters = [
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.query.pipe(debounceTime(250))
        ];

        merge(...observedFilters)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    let query = this.query.getValue();
                    this.localStorage.setItem('wf_page_size', this.paginator.pageSize);
                    this.localStorage.setItem(WF_FILTER, this.wf);

                    return this.workflowService.getWorkflow(this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        query);
                }),
                tap(data => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data['totalElements'];
                }),
                map(data => data['content'] as WorkflowItem[]),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe(data => {
            this.dataSource.data = groupWorkflowData(data as WorkflowItem[]);

            if (['mgmt_srv', 'firethunder'].includes(this.user.username)) {
                this.workflowService.calculateCA(this.query.getValue().split('@')[1]).subscribe(res => {
                    this.turnover = Number(res.ca).toFixed(2);
                    this.totalHidden = Number(res.totalHidden).toFixed(2);
                    this.totalExams = res.totalExams;

                    this.totalExamsHidden = res.totalExamsHidden;
                });
            }

            this.workflowService.calculateSubTotals(this.query.getValue()).subscribe(res => this.subTotalSubject.next(res));

            this.getLinesFormattingStyles();

        });


        if (window.location.hostname.startsWith('fireris')) setTimeout(() => {
            this.filterForm.get('period').patchValue('3M');
            this.changeRange({value: '3M'})
        });
    }

    private changeColumns(view: TableView) {
        if (view.name === 'PAYMENTS' && this.profile && this.profile.managePayment) this.showPaymentsColumns();
        else if (view.name === 'WORKFLOW_VIEW') this.showWorkflowColumns();
        else if (view.name === 'PARTIAL_PAYMENTS' && this.profile && this.profile.managePayment) this.showLeftPaymentsColumns();
        else this.showCustomColumns(view)
    }

    addNewView(view?: TableView) {
        this.dialog.open(TableViewComponent, {
            data: {cols: WF_COLUMNS, tableView: view || new WorkflowTableView(this.user.id)},
            autoFocus: true,
            disableClose: true,
            position: {top: '-3px'}
        }).afterClosed().subscribe(view => {
            if (view) {
                this.localStorage.setItem(view.name, null);
                this.updateViews();
            }
        });
    }

    onSaveTableConfig(tableConfig: TableConfig) {
        this.setting.saveTableConfig(tableConfig).subscribe(res => {
            if (res) {
                this.workflowTableConfig = res;
                this.dispatchRules(res.formattingRules);

                this.getLinesFormattingStyles();
            }
        });
    }

    getColumnStyle(colType: ColumnDataType, column: TableColumn, row: PatientWorkflow | WorkflowItem): any {
        if (!this.columnsFormattingRules) return;
        let rule = this.columnsFormattingRules.find(it => it.targetColumn === column.header);
        if (rule && WorkflowComponent.isConditionFilled(colType, rule.formatRule, row[column.label], rule.primaryFormatValue, rule.secondaryFormatValue)) return getDisplayStyle(rule.formattingStyle);
    }

    getColumnBooleanTextStyle(header: string, cellValue: any, displayMode = 'TEXT'): any {
        let rules = this.columnFormattingRules(header);

        let rule = rules.find(it => it.primaryFormatValue == cellValue.toString());
        let style = rule ? rule.formattingStyle : null;

        let displayStyle = getDisplayStyle(style);

        if (rule && rule.formattingStyle.displayMode === displayMode) return displayStyle;
    }

    editView(view: TableView) {
        this.addNewView(view);
    }

    deleteView(view: TableView) {
        this.setting.deleteTableView(view.id).subscribe(res => {
            if (res) {
                this.updateViews();
                this.selectedView.next(this.views[0]);
            }
        });
    }

    assignPerformer(row: WorkflowItem, title: string = 'DELEGATE_TASK') {
        this.dialog.open(PerformerAssignComponent, {
            data: {task: {id: row.reportingTaskId, performerName: {id: row.performingPhysicianId}}, title: title},
            minWidth: '380px'
        });
    }


    canAssign(row: WorkflowItem): boolean {
        return (this.can(row, 'editReport') && (this.user.username === row.performingPhysician)) || (this.profile['editReport'] !== 'NONE');
    }

    addComment(row: WorkflowItem) {
        this.dialog.open(CommentsComponent, {
            data: {username: this.user.username, comment: row.noteAlert},
            width: '400px',
            disableClose: true
        }).afterClosed().subscribe(comments => {
            if (comments && comments === 'dismiss') return;

            this.reportingService.getReportingTask(row.reportingTaskId).subscribe(task => {
                task.noteAlert = comments;
                this.reportingService.saveNoteAlert(task).subscribe(res => this.snack.open(this.translate.instant('COMMENT_SAVED'), 'OK', {duration: 2000}))
            });
        })
    }

    generateReport(row: WorkflowItem) {
        this.snack.open(
            this.translate.instant('NO_NORMAL_REPORT_ATTACHED'),
            this.translate.instant('OPEN_REPORT'),
            {duration: 3000}).onAction().subscribe(res => {
            this.openReport(row, false);
        });
        // this.dialog.open(NormalReportComponent, {data: row.reportingTaskId, minHeight: '100vh'})
    }

    getConnectedIcon(header: string, cellValue: string): any {
        let rules = this.columnFormattingRules(header);
        let rule = rules.find(it => it.targetColumn == header);
        return rule && cellValue ? rule.formattingStyle.icon : '';
    }

    private updateViews() {
        this.views = this.views.filter(view => view.id === 0);
        this.getTableViews();
    }

    private getViewers() {
        this.setting.getViewers().subscribe(res => {
            this.viewers = res.filter(it => !it.defaultViewer);
            this.defaultViewer = res.filter(v => v.defaultViewer)[0];
            this.osirixViewers = res.filter(v => v.osirix);
            this.meddreamViewer = res.filter(v => v.name === 'MEDDREAM' || v.name.toLowerCase().includes('pro'))[0];
        });
    }

    calculateSum(label: string, comma = false): string {
        if (label === 'count') return String(this.resultsLength);
        return this.formatNumeral(get(this.totalAmount, label) || 0, comma);
    }

    isPatientAngry(row: WorkflowItem): boolean {
        return row.patientStatus === 'WAITING' && this.generalSetting && (waitingDuration(row) > this.generalSetting.waitingDurationBeforeAlert)
    }

    exportExcel() {
        this.workflowService.exportToExcel(this.query.getValue()).subscribe(res => {
            const blob = new Blob([res], {type: 'application/vnd.ms-excel'});
            const file = new File([blob], 'workflow.xlsx', {type: 'application/vnd.ms-excel'});

            FileSaver.saveAs(file);
        });
    }

    formatNumeral(numValue: any, comma: boolean = false): any {
        return numeral(numValue).format(`${comma ? this.numberFormat : '0,0'}`);
    }

    public get viewerOpen(): boolean {
        return !!window['viewerWindow'];
    }

    openInOsirix(row, aeTitle, multiple: boolean) {
        if (multiple) row.workflowItems.filter(w => w.imagesAvailables && w.studyInstanceUID).forEach(it => this.launchOsirix(aeTitle, it.patientID, row.studyInstanceUID))
        else this.launchOsirix(aeTitle, row.patientID, row.studyInstanceUID)
    }

    notPaid(row: any): boolean {
        return ['NOT_PAID', 'EXEMPT'].includes(row.paymentStatus);
    }

    openInWebViewer(row: any, viewer: Viewer, replace: boolean = true, multiple: boolean) {
        if (multiple) {
            this.workflowService.findExamsHistory(row.patientID).subscribe(history => {
                let studyInstanceUIDs = history.studyInstanceUIDs || row.workflowItems.filter(w => w.imagesAvailables && w.studyInstanceUID).map(s => s.studyInstanceUID).join(',');

                this.openViewerAndReport(row, viewer, replace, studyInstanceUIDs);
            });
        } else {
            this.openViewerAndReport(row, viewer, replace, row.studyInstanceUID);
        }
    }

    updateFromPacs(el: WorkflowItem) {
        this.dialog.open(PacsSearchComponent, {
            data: el.patientName.trim().split(' ')[0],
            height: '90vh',
            minWidth: '90vw',
            disableClose: true
        }).afterClosed().subscribe(res => {
            if (res && res.key === 'merge') this.shared.mergeExams(res.data, el.accessionNumber).subscribe(value => {
                if (!value) this.snack.open(this.translate.instant('ALREADY_MERGED'), '', {duration: 2000})
            });
        })
    }

    openStudy = (row: any, viewer: any, multiple?: boolean, replace: boolean = true) => this.openViewer(row, viewer, replace, multiple);

    hiddenUser(): boolean {
        return ['firethunder', 'mgmt_srv'].includes(this.user.username);
    }

    hideExam(row: any) {
        this.workflowService.hideExam(row.id).subscribe(res => res && this.workflowFilterSubject.next(this.wf));
    }

    queryPacs() {
        this.dialog.open(PacsSearchComponent, {
            data: '',
            disableClose: true,
            minWidth: '90vw',
            height: '90vh'
        }).afterClosed().subscribe(res => {
            if (res && res.key === 'new') this.createExamWithPayment(res.data)
        })
    }

    expandRow(row: PatientWorkflow) {
        this.expandedElement = this.expandedElement === row ? null : row;
    }

    specialFormat(header: string): boolean {
        return specialAttributes(header);
    }

    openInSyngovia(row: any, multiple = false) {
        if (multiple) {
            this.workflowService.findExamsHistory(row.patientID).subscribe(history => {
                let studyInstanceUIDs = history.studyInstanceUIDs || row.workflowItems.filter(w => w.imagesAvailables && w.studyInstanceUID).map(s => s.studyInstanceUID).join(',');

                this.openReport(row.imagesAvailables ? row.workflowItems.filter(it => it.imagesAvailables)[0] : row, false);
                this.openSyngo(studyInstanceUIDs);
            });
        } else {
            this.openReport(row, false);
            this.openSyngo(row.studyInstanceUID);
        }
    }

    public isRemoteEyeViewer = (): boolean => this._config.isRemoteEyeViewer;

    public launchRELite(studyUIDs?: any): void {
        LPW.REInt.popupRELite({
            username: this._config.remoteEyeUsername,
            password: this._config.remoteEyePassword,
            accNumsList: '',
            studyUIDsList: studyUIDs,
            rows: 1, columns: 2
        });
    }

    sendToBilling(row: PatientWorkflow) {
        this.workflowService.syncWithBilling(row.accessionNumber).subscribe(console.log)
    }

    isAfterUpdate() {
        return moment().isSameOrAfter(moment('2019-10-23', 'YYYY-MM-DD'), 'd');
    }

    getTotal(field: string): number {
        return this.dataSource.data.map(it => it[field]).reduce((acc, value) => acc + value, 0);
    }

    debitStock(row: WorkflowItem | PatientWorkflow) {
        this.dialog.open(StockMovementComponent, {data: row, disableClose: true}).afterClosed().subscribe(res => {
            this.workflowFilterSubject.next(this.wf);
        });
    }

    getColumnDisplayMode(header: string): DisplayMode {
        let rules = this.columnFormattingRules(header);
        let rule = rules[0];
        return rule ? rule.formattingStyle.displayMode : DisplayMode.TEXT;
    }

    getColumnFormattingIcon(header: string, cellValue: any): any {
        let rules = this.columnFormattingRules(header);
        let rule = rules.find(it => it.primaryFormatValue == cellValue.toString());
        return rule && rule.primaryFormatValue == cellValue.toString() ? rule.formattingStyle.icon : '';
    }

    private buildWorkflowFilter = (value: any) => {
        this.todayFormat = value.period === 'TODAY' ? 'HH:mm' : this._config.dateTimeFormat;

        let startDate = moment(value.startDate).isValid() ? moment(value.startDate) : moment().subtract(10, 'year');
        let endDate = moment(value.endDate).isValid() ? moment(value.endDate) : moment().add(10, 'd');

        let start = startDate.format('YYYYMMDD');
        let end = endDate.format('YYYYMMDD');

        this.currentDate = value.period === 'OT' ? '' : start === end ? startDate.format(this._config.appLang == 'en' ? 'LL' : 'LLLL') : DateUtils.formatRange(startDate, endDate, this._config.appLang);

        this.wf.key = value.key;
        this.wf.dateRange = `${start}-${end}`;
        this.wf.modalities = value.modality ? value.modality.join('-') : 'ALL';
        this.wf.reportStatuses = value.reportStatus && value.reportStatus.length !== 0 ? value.reportStatus.join('-') : 'ALL';
        this.wf.technicianId = value.technicianId;
        this.wf.physicianId = value.physicianId;
        this.wf.patientStatuses = value.patientStatus && value.patientStatus.length !== 0 ? value.patientStatus.join('-') : 'ALL';
        this.wf.paymentStatuses = value.paymentStatus && value.paymentStatus.length !== 0 ? value.paymentStatus.join('-') : 'ALL';

        this.workflowFilterSubject.next(this.wf);
    };

    isCt = (examType): boolean => examType.includes('TDM') || examType.includes('IRM');
    hideForced: Boolean;

    private openViewerAndReport(row: any, viewer: any, replace: boolean, studyInstanceUIDs: string) {
        this.openReport(row.imagesAvailables && row.workflowItems ? row.workflowItems.filter(it => it.imagesAvailables)[0] : row, false);

        if (viewer === 'RELite') {
            this.launchRELite(studyInstanceUIDs)
        } else {
            let params = `${viewer.name}_${studyInstanceUIDs}`;

            if (window['viewerWindow'] && !window['viewerWindow'].closed) {
                window['viewerWindow'].focus();
                this.reportingService.openStudy(row.studyInstanceUID, this.user.username, replace).subscribe()
            } else {
                window['viewerWindow'] = window.open(`/external-viewer/study?param=${params}`, 'viewerWindow', 'toolbar=0,location=0,menubar=0,left');
                window['viewerWindow'].addEventListener('beforeunload', () => window['viewerWindow'] = null);
            }
        }
    }

    getColumnFormattingIconStyle(header: string, cellValue: any): any {
        let rules = this.columnFormattingRules(header);
        let rule = rules.find(it => it.primaryFormatValue == cellValue.toString());
        if (rule) return getDisplayStyle(rule.formattingStyle);
    }

    getColumnBooleanBadgeStyle(header: string, cellValue: any): any {
        return this.getColumnBooleanTextStyle(header, cellValue, 'BADGE');
    }

    getColumnStyleDisplayMode(colType: ColumnDataType, column: TableColumn, row: PatientWorkflow | WorkflowItem, displayMode: string = DisplayMode.TEXT): any {
        if (!this.columnsFormattingRules) return;

        let rule = this.columnsFormattingRules.find(it => it.targetColumn === column.header);
        if (rule && rule.formattingStyle.displayMode === displayMode && WorkflowComponent.isConditionFilled(colType, rule.formatRule, row[column.label], rule.primaryFormatValue, rule.secondaryFormatValue)) return getDisplayStyle(rule.formattingStyle);
    }

    getRowFormattingStyle(row: WorkflowItem | PatientWorkflow): any {
        if (!this.linesFormattingRules) return;
        return this.styles[row.accessionNumber];
    }

    private getTableViews() {
        this.setting.getTableViews(this.user.id, 'WORKFLOW').subscribe(views => {
            let items = this.views.concat(views);
            if (this.generalSetting && !this.generalSetting.billingRequired) remove(items, {name: 'PAYMENTS'});
            this.views = items;
        });
    }

    private showPaymentsColumns() {
        this.availableColumns = this.localStorage.getItem('PAYMENTS') || sortBy(WF_COLUMNS.filter(item => !item.hidden), 'order').filter(col => ['patientName', 'procedureCode', 'paymentStatus', 'totalAmount', 'paidAmount', 'leftAmount', 'discount', 'globalDiscount', 'billed'].includes(col.label));
        this.setColumnsToDisplay(this.availableColumns, 'PAYMENTS');
        this.filterForm.get('paymentStatus').patchValue([]);
    }

    private showWorkflowColumns() {
        this.availableColumns = this.localStorage.getItem('WORKFLOW_VIEW') || sortBy(WF_COLUMNS.filter(item => !item.hidden), 'order');
        setTimeout(() => {
            if (this.generalSetting && !this.generalSetting.billingRequired) this.availableColumns = this.availableColumns.filter(col => !['paymentStatus', 'totalAmount', 'paidAmount', 'leftAmount', 'discount', 'globalDiscount', 'billed'].includes(col.label));
            this.setColumnsToDisplay(this.availableColumns, 'WORKFLOW_VIEW');
            this.filterForm.get('paymentStatus').patchValue([]);
        }, 100);
    }

    private showCustomColumns(view: TableView) {
        this.availableColumns = this.localStorage.getItem(view.name) || view.columns.split(',').map(col => WF_COLUMNS.find(c => c.label === col));
        this.setColumnsToDisplay(this.availableColumns, view.name);
    }

    private getLinesFormattingStyles() {
        if (!this.linesFormattingRules) return;

        this.linesFormattingRules.forEach(rule => {
            let column = this.workflowTableConfig.tableColumns.find(it => it.header === rule.targetColumn);
            this.dataSource.data.forEach(row => {
                if (WorkflowComponent.isConditionFilled(column.type, rule.formatRule, row[column.label], rule.primaryFormatValue, rule.secondaryFormatValue))
                    this.styles[row.accessionNumber] = getDisplayStyle(rule.formattingStyle);
            });
        });
    }

    private dispatchRules(defaultRules: FormattingRule[]) {
        let rules = StringUtils.groupBy(defaultRules, 'appliedTo');
        this.linesFormattingRules = rules['ROW'];
        this.columnsFormattingRules = rules['COLUMN'];
    }

    private showLeftPaymentsColumns() {
        this.availableColumns = this.localStorage.getItem('PARTIAL_PAYMENTS') || sortBy(WF_COLUMNS.filter(item => !item.hidden), 'order').filter(col => ['patientName', 'procedureCode', 'paymentStatus', 'totalAmount', 'paidAmount', 'leftAmount', 'discount', 'globalDiscount', 'billed'].includes(col.label));
        this.setColumnsToDisplay(this.availableColumns, 'PARTIAL_PAYMENTS');
        this.filterForm.get('paymentStatus').patchValue(['PAID_PARTIALLY', 'NOT_PAID'])
    }

    calculateTotal(label: string): any {
        if (label === 'patientName') return 'Total patients: ' + this.formatNumeral(this.dataSource.data.length);
    }

    private openInRadiant(viewer: Viewer, row: any, multiple = false) {
        let radiantUrl = viewer.remotePath + 'n=pstv&v=0020000D&v=%22';
        if (multiple) {
            this.workflowService.findExamsHistory(row.patientID).subscribe(history => {
                let studyInstanceUIDs = history.studyInstanceUIDs || row.workflowItems.filter(w => w.imagesAvailables && w.studyInstanceUID).map(s => s.studyInstanceUID).join('%22&n=pstv&v=0020000D&v=%22');

                this.openReport(row.imagesAvailables ? row.workflowItems.filter(it => it.imagesAvailables)[0] : row, false);
                open(radiantUrl + studyInstanceUIDs + '%22');
            });
        } else {
            this.openReport(row, false);
            open(radiantUrl + row.studyInstanceUID + '%22');
        }
    }

    private openSyngo(uids: string) {
        this.workflowService.openSyngo(uids).subscribe(res => {
            if (res) this.snack.open(this.translate.instant('SYNGO_OPENING'), '', {duration: 4000});
            else this.snack.open(this.translate.instant('SYNGO_OPENING_ERROR'), '', {duration: 2000});
        });
    }

    getNoteAlert(note: string): string {
        let tooltip;
        let text = note.split('|');
        if (text.length !== 0) {
            tooltip = text.map(t => {
                let tokens = t.split(';');
                return tokens.length > 1 ? `- ${tokens[1].toUpperCase()}:\n${tokens[2]}` : '-';
            }).join('\n')
        }
        return tooltip.indexOf(undefined) !== -1 ? '' : tooltip
    }

    private openViewer(row, viewer, replace = true, multiple: boolean = false) {
        if (viewer === 'RELite') {
            if (multiple) {
                this.workflowService.findExamsHistory(row.patientID).subscribe(history => {
                    let studyInstanceUIDs = history.studyInstanceUIDs || row.workflowItems.filter(w => w.imagesAvailables && w.studyInstanceUID).map(s => s.studyInstanceUID).join(',');

                    this.openViewerAndReport(row, viewer, replace, studyInstanceUIDs);
                });
            } else {
                this.openViewerAndReport(row, viewer, replace, row.studyInstanceUID);
            }
        } else if (viewer.remotePath.startsWith('radiant')) this.openInRadiant(viewer, row, multiple);
        else if (!viewer.osirix) this.openInWebViewer(row, viewer, replace, multiple);
        else this.openInOsirix(row, viewer, multiple)
    }

    sumByField(row, label): number {
        switch (label) {
            case 'totalAmount':
                return sumBy('totalAmount', row.workflowItems) - sumBy('discount', row.workflowItems);
            case 'globalDiscount':
                return row[label];
            case 'leftAmount':
                return sumBy('totalAmount', row.workflowItems) - sumBy('paidAmount', row.workflowItems) - sumBy('discount', row.workflowItems) - row['globalDiscount'];
        }
        return sumBy(label, row.workflowItems)
    }

    private subscribeToWsTopic(): Subscription {
        return this.wsService.observeTopic('workflow')
            .pipe(delay(1000))
            .subscribe({
                next: res => {
                    if (res.topic === 'workflow') this.workflowFilterSubject.next(this.wf);
                },
                error: err => console.log(err),
                complete: () => {
                    console.log('complete')
                }
            });
    }

    printAttestation(row: any) {
        let matSnackBarRef = this.snack.open(this.translate.instant('PRINTING_IN_PROGRESS'), '', {duration: 10000});
        this.shared.printAttestation(row.id).subscribe(_ => matSnackBarRef.dismiss())
    }
}
