import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {get, isNil, noop} from 'lodash';
import {formatTimer} from '../../utils';
import {MatSnackBar} from "@angular/material/snack-bar";

declare var window: any;

@Component({
    selector: 'ft-message-recorder',
    template: `
        <div fxLayout="row nowrap" fxLayoutAlign="center center" fxLayoutGap="16px">
            <button [disabled]="!record" (click)="close()" class="cancel-record" color="accent" mat-icon-button>
                <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
            <div class="record-time" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
                <mat-icon fontIcon="mdi-circle" fontSet="mdi"></mat-icon>
                <span>{{formatTimer(timer)}}</span>
            </div>
            <button [disabled]="!record" (click)="confirm()" class="confirm-record" color="primary" mat-icon-button>
                <mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
            </button>
        </div>`,
    styles: [`
        .cancel-record {
            border: 1px solid;
            transition: all 160ms ease-in-out;
        }

        .cancel-record:hover {
            background: #F44336;
            color: white;
            border: none;
            transform: scale(1.2);
        }

        .record-time span {
            color: gray;
        }

        .record-time mat-icon {
            font-size: 13px !important;
            color: #c70f0f;
            line-height: 21px;
            width: 16px !important;
            animation: colorAnimate infinite 1.4s ease-in-out;
        }

        .confirm-record {
            border: 1px solid;
            transition: all 160ms ease-in-out;
        }

        .confirm-record:hover {
            background: #2196f3;
            color: white;
            border: none;
            transform: scale(1.2);
        }

        @keyframes colorAnimate {
            0% {
                opacity: 0.1;
            }
            50% {
                opacity: 1.0;
            }
            100% {
                opacity: 0.1;
            }
        }

    `]
})
export class MessageRecorderComponent implements OnInit, AfterViewInit, OnDestroy {

    @Output() onClose = new EventEmitter<any>();
    @Output() onConfirm = new EventEmitter<any>();
    public error: boolean;
    public timer = 0;
    public record = null;
    private interval: any;
    private stream: MediaStream;
    private recordData: { blob?: Blob, duration?: number } = {};

    constructor(private _snack: MatSnackBar) {
    }

    formatTimer = (timer: number) => formatTimer(timer);

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        let getUserMedia = get(window, 'navigator.mediaDevices.getUserMedia', null);
        let mediaRecorder = get(window, 'MediaRecorder', null);

        if (!isNil(getUserMedia) && !isNil(mediaRecorder)) {
            window.navigator['mediaDevices']['getUserMedia']({audio: true})
                .then(stream => this.handleAudioStream(stream), _ => this.error = true);
        } else console.log('MediaRecord not supported');


        setTimeout(() => this.startRecording(), 1000);
    }

    close() {
        this.onClose.emit();
        isNil(this.stream) ? noop() : this.stream['getAudioTracks']().forEach(track => track.stop());
        this.interval = null;
    }

    confirm() {
        if (this.record.state !== 'inactive') this.record.stop();
        setTimeout(() => {
            this.onConfirm.emit(this.recordData);
            this.close();
        });
    }

    ngOnDestroy() {
        isNil(this.stream) ? noop() : this.stream['getAudioTracks']().forEach(track => track.stop());
    }

    private handleAudioStream(stream: MediaStream) {
        this.stream = stream;
        this.record = new window.MediaRecorder(stream);
        this.record.ondataavailable = (e) => {
            this.recordData = {blob: new Blob([e.data], {type: 'audio/ogg'}), duration: this.timer};
        }
    }

    private startRecording() {

        if (this.record == null) {
            this._snack.open('Media not supported', '', {duration: 2000, horizontalPosition: 'end'});
            return;
        }

        if (this.record.state === 'inactive') this.record.start();

        this.startTimer();
    }

    private startTimer() {
        this.interval = setInterval(() => {
            this.timer += 1;
        }, 10);
    }

}
