<div matDialogTitle>
    {{'CONTACTS' | translate}}
</div>
<div matDialogContent>
    <div *ngIf="contacts; else loading">
        <mat-selection-list [multiple]="source !== 'NEW'">
            <mat-list-option (selectedChange)="selectContacts(contact)" *ngFor="let contact of contacts"
                             [checkboxPosition]="'after'" fxLayout="row" fxLayoutAlign="start center">
                <div [style.background]="contact.color" mat-list-icon>{{contact.name | initials}}</div>
                <span fxFlex></span>
                <div mat-line>{{contact.name}}</div>
            </mat-list-option>
        </mat-selection-list>
    </div>
    <ng-template #loading>
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-spinner [diameter]="20" [strokeWidth]="2" color="accent"></mat-spinner>
        </div>
    </ng-template>
</div>
<div>
    <span fxFlex></span>
    <button color="accent" mat-button matDialogClose>{{'CLOSE' | translate}}</button>
    <button (click)="ok()" [disabled]="selection.length == 0" color="primary" mat-button>{{'OK' | translate}}</button>
</div>