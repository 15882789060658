import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {defaultFormattingRule, FormattingRule, TableConfig} from "../../model";
import {deleteItemFromArray} from "../shared-functions";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {sortBy} from 'lodash';

@Component({
  selector: 'table-formatter',
  templateUrl: './table-formatter.component.html',
  styleUrls: ['./table-formatter.component.scss']
})
export class TableFormatterComponent implements OnChanges {

  @Output() close = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<TableConfig>();
  @Input() tableConfig: TableConfig;
  selectedRule: FormattingRule;

  constructor() {
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.tableConfig.formattingRules, event.previousIndex, event.currentIndex);
  }

  deleteRule(rule: FormattingRule) {
    deleteItemFromArray(this.tableConfig.formattingRules, rule);

    this.onSave.emit(this.tableConfig);
  }

  editRule(rule: FormattingRule) {
    this.selectedRule = rule;
  }

  saveChanges(rule: FormattingRule) {
    console.log(rule);

    if (rule.id != 0) {
      deleteItemFromArray(this.tableConfig.formattingRules, rule);
      rule.id = 0;
    }
    this.tableConfig.formattingRules.push(rule);
    this.selectedRule = null;

    this.onSave.emit(this.tableConfig);
  }

  addNewRule() {
    this.selectedRule = defaultFormattingRule;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tableConfig = changes['tableConfig'].currentValue;
  }

    getFormattingRules(): FormattingRule[] {
        return sortBy(this.tableConfig.formattingRules, 'targetColumn');
    }
}
