<div *ngIf="resourceQueueItems && resourceQueueItems.length != 0 && resources" class="main" fxFill fxLayout="row" fxLayoutAlign="center" fxLayoutGap="24px">


    <section *ngFor="let res of resourceQueueItems" class="resource"
             fxLayout="column">

        <section class="active-queue" fxLayout="column"
                 fxLayoutAlign="space-around center">
            <div [ngSwitch]="res.queueItems[0]['patientSex']" class="active-image" fxLayout="row" fxLayoutAlign="center center">
                <img *ngSwitchCase="'F'" [src]="'/upload-dir/woman.png'" alt="F">
                <img *ngSwitchCase="'M'" [src]="'/upload-dir/man.png'" alt="M">
                <img *ngSwitchDefault [src]="'/upload-dir/woman.png'" alt="O">
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
                <div class="active-patient-name">{{res.queueItems[0]['patientName']}}</div>
                <div class="active-ticket-number">{{res.queueItems[0]['ticketNumber']}}</div>
            </div>

        </section>

        <div class="resource-title">{{res.resourceName}}</div>

        <section class="queue-items" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px">
            <div *ngFor="let o of dropFirst(res.queueItems)" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
                <div class="ticket-number">{{o['ticketNumber']}}</div>
                <div [ngSwitch]="o['patientSex']" class="queue-image" fxLayout="column" fxLayoutAlign="center center">
                    <img *ngSwitchCase="'F'" [src]="'/upload-dir/woman.png'" alt="F">
                    <img *ngSwitchCase="'M'" [src]="'/upload-dir/man.png'" alt="M">
                    <img *ngSwitchDefault [src]="'/upload-dir/woman.png'" alt="O">
                </div>
                <div fxLayout="column" fxLayoutAlign="center start">
                    <div class="patient-name">{{o['patientName']}}</div>
                    <div class="appointment-time">{{o['appointmentTime']}}</div>
                </div>
            </div>
        </section>

    </section>

</div>
<div class="circle1"></div>
<div class="circle2"></div>
