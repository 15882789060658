import {JwtModuleOptions} from '@auth0/angular-jwt';

export const tokenGetter = (): string => localStorage.getItem('access_token');
export const JwtConfig: JwtModuleOptions = {
    config: {
        tokenGetter,
        authScheme: 'Bearer ',
        headerName: 'Authorization'
    }
};
