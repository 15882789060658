import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SchedulerService} from '../scheduler.service';
import {map} from 'rxjs/operators';
import {set} from 'lodash';
import {PaymentOrderComponent} from '../../shared';

@Component({
    selector: 'app-event-detail',
    templateUrl: './event-detail.component.html',
    styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {

    currentEvent: any;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private dialog: MatDialog,
                private snackBar: MatSnackBar,
                private service: SchedulerService) {
    }

    getEventDetail(spsId: any) {
        this.service
            .getEventDetail(spsId)
            .pipe(map(event => set(event, 'patientName', this.showPatientName(event))))
            .subscribe(eventData => this.currentEvent = eventData)
    }

    ngOnInit() {
        this.getEventDetail(this.data);
    }

    addPayment() {
        this.dialog.open(PaymentOrderComponent, {data: this.data})
            .afterClosed()
            .subscribe(order => {
                if (order)
                    this.service
                        .orderPayment(order)
                        .subscribe(res => {
                            if (res && res.id) {
                                this.snackBar.open('Nouveau paiement enregistré avec succès!', 'OK', {
                                    duration: 2000
                                });
                            }
                        });
            });
    }

    private showPatientName(event): string {
        return !event.confidential || this.data.canViewConfData ? event.patientName : '**** ****';
    }
}
