import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Field} from '../../models/field.interface';

@Component({
    selector: 'app-form-input',
    templateUrl: './form-input.component.html',
    styleUrls: ['./form-input.component.css']
})
export class FormInputComponent implements Field {

    config: any;
    group: FormGroup;

    constructor() {
    }

}
