import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {forkJoin, of as observableOf, Subscription} from "rxjs";
import {ReferringPhysicianAddComponent, SharedService, ValueDescriptionComponent} from "../../shared";
import {catchError, debounceTime, map, startWith, switchMap} from "rxjs/operators";
import {PatientService} from "../../patient/patient.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Appointment, ProcedureCode, WsMessage} from "../../model";
import * as moment from "moment";
import {SchedulerService} from "../scheduler.service";
import {AppConfigService} from "../../app-config.service";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {assign, set} from 'lodash';
import {PatientSearchComponent} from "../../patient/patient-search/patient-search.component";
import {ProcedureCodeSearchComponent} from "../procedure-code-search/procedure-code-search.component";

@Component({
    selector: 'ft-appointment-edit',
    templateUrl: './appointment-edit.component.html',
    styleUrls: ['./appointment-edit.component.scss']
})
export class AppointmentEditComponent implements OnInit {
    appointmentForm: FormGroup;
    titles: any;
    genders: any;
    numOfDuplicatePatients: number;
    subs: Subscription[] = [];
    patientSelected: Boolean;
    patientForm: FormGroup;
    demographicForm: FormGroup;
    minDate = new Date();
    ageForm: FormGroup;
    confidentialities: any;
    procedureCodeControl: FormControl = new FormControl('', Validators.required);
    selected: null;
    filteredProcedures: ProcedureCode[] = [];
    referringPhysicianControl: FormControl = new FormControl('');
    filteredReferringPhysicians: any[] = [];
    clicked: boolean;
    private searchKeys: any = {};
    aetTitles: any;
    performingPhysicians: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private _fb: FormBuilder,
                private _shared: SharedService,
                private _scheduler: SchedulerService,
                private _config: AppConfigService,
                private _dialog: MatDialog,
                private _patientService: PatientService,
                private _dialogRef: MatDialogRef<any>) {
        this.appointmentForm = this._fb.group(Object.assign({}, new Appointment(), {
            startDate: [new Date(), Validators.required],
            endDate: null,
            startTime: null,
            endTime: null,
            physician: this._fb.group({id: ''}),
            aet: this._fb.group({id: ''}),
            room: this._fb.group({id: ''}),
        }));
        this.patientForm = this._fb.group({
            id: '',
            externalPatientID: '',
            firstName: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
            lastName: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
            title: this._fb.group({id: ''}),
            patientClass: this._fb.group({id: 3}),
            cin: '',
            email: '',
            phone: '',
            confidential: false
        });

        this.demographicForm = this._fb.group({
            id: '',
            dateOfBirth: [null],
            patientComments: '',
            confidentialityConstraintOnPatientData: '',
            gender: this._fb.group({id: [13, Validators.required]}),
            confidentiality: this._fb.group({id: 1})
        });

        this.createAgeForm();

    }

    ngOnInit(): void {
        forkJoin([
            this._shared.getTitles(),
            this._shared.getGenders(),
            this._shared.getConfidentialities(),
            this._shared.getAetList(),
            this._shared.getPerformingPhysicians()
        ]).subscribe(data => {
            [
                this.titles,
                this.genders,
                this.confidentialities,
                this.aetTitles,
                this.performingPhysicians
            ] = data;

            this.subs.push(this.procedureCodeControl.valueChanges
                .pipe(
                    startWith(''),
                    switchMap(query => this._shared.getPaginatedProcedureCodes(10, 0, 'code', 'asc', query)),
                    map(data => data['content']),
                    catchError(() => observableOf([]))
                ).subscribe(data => this.filteredProcedures = data));
            this.procedureCodeControl.patchValue('');

            this.subs.push(this.referringPhysicianControl.valueChanges
                .pipe(
                    startWith(''),
                    switchMap(query => this._shared.getPaginatedReferringPhysicians(10, 0, 'lastName', 'asc', query)),
                    map(data => data['content']),
                    catchError(() => observableOf([]))
                ).subscribe(data => this.filteredReferringPhysicians = data));
            this.referringPhysicianControl.patchValue('');

            this.checkExistingPatientsCount();

            if (this.data.patient) this.selectAppointment(this.data);
            if (this.data.selectedDateRange) this.appointmentForm.patchValue({
                startDate: this.data.selectedDateRange.start,
                endDate: this.data.selectedDateRange.end,
                startTime: moment(this.data.selectedDateRange.start).format('HH:mm'),
                endTime: moment(this.data.selectedDateRange.start).format('HH:mm')
            });


            if (this.data.resource) {
                let {name, id} = this.data.resource;
                if (name === 'aet') {
                    let aet = this.aetTitles.find(ae => ae.id === id);
                    this.appointmentForm.get('aet').patchValue(aet);
                    this.appointmentForm.get('room').patchValue(aet.room);
                } else if (name === 'physician') {
                    let physician = this.performingPhysicians.find(p => p.id === id);
                    this.appointmentForm.get('physician').patchValue(physician);
                } else if (name === 'room') {
                    let aets = this.aetTitles.filter(ae => ae.room.id === id);
                    let aet = aets.length != 0 ? aets[0] : null
                    this.appointmentForm.get('aet').patchValue(aet);
                    this.appointmentForm.get('room').patchValue(aet.room);
                }
            }
        });
    }

    createAppointment() {
        this.clicked = true;

        let patient = this.patientForm.getRawValue();
        if (patient.title.id === '') set(patient, 'title', null);
        let demographic = this.demographicForm.getRawValue();
        set(patient, 'demographic', demographic);

        let appointment = this.appointmentForm.getRawValue();
        set(appointment, 'patient', patient);
        if (appointment.room && appointment.room.id === '') set(appointment, 'room', null)
        if (appointment.physician && appointment.physician.id === '') set(appointment, 'physician', null)
        if (appointment.aet && appointment.aet.id === '') set(appointment, 'aet', null)

        this._scheduler.createAppointment(appointment).subscribe(res => {
            if (res) this._dialogRef.close(true);
        });
    }

    findPatient() {
        assign(this.searchKeys, {
            fn: this.patientForm.get('firstName').value,
            ln: this.patientForm.get('lastName').value
        });

        let dialogRef = this._dialog.open(PatientSearchComponent, {
            width: '760px',
            data: this.searchKeys
        });

        dialogRef.componentInstance.selectedPatient = {};

        this.subs.push(dialogRef
            .afterClosed()
            .subscribe(selectedPatient => {
                if (selectedPatient) {
                    selectedPatient.title = this.titles.find(it => it.value === selectedPatient.title_value);
                    this.selectPatient(selectedPatient);
                }
            }));
    }

    selectPatient(patient: any) {
        if (patient) {

            this.patientSelected = true;

            let demographic = patient.demographic;

            let dob = patient.demographic.dateOfBirth;

            let gender = patient.demographic.gender || {id: ''};
            let title = patient.title || {id: ''};
            let debiter = patient.debiter || 'PATIENT';

            set(patient, 'title', title);
            set(patient, 'debiter', debiter);

            set(demographic, 'gender', gender);
            set(demographic, 'dateOfBirth', dob ? dob : new Date());

            this.patientForm.patchValue(patient);
            this.demographicForm.patchValue(demographic)

            this.referringPhysicianControl.patchValue(patient.consultingDoctor ? patient.consultingDoctor.fullName : '');

            this.changeAge();
        }
    }

    upperCase(event: any, field: string) {
        let name = event.target.value;
        this.patientForm.get(field).patchValue(name.toUpperCase())
    }

    capitalize(event1: any, field: string) {
        let name = event1.target.value;
        this.patientForm.get(field).patchValue(name.charAt(0).toUpperCase() + name.substring(1))
    }

    addTitle() {
        this._dialog.open(ValueDescriptionComponent).afterClosed()?.subscribe(title => {
            if (title) this._shared.createTitle(title).subscribe(res => {
                this.titles.push(res);
                this.patientForm.get('title').patchValue(res);
            });
        })
    }

    changeDate() {
        let age = this.ageForm.getRawValue();
        if (age.years !== null && age.months !== null && age.days !== null) {
            this._scheduler.calculateDOB(`${age.years || '0'}-${age.months || '0'}-${age.days || '0'}`).subscribe((res: WsMessage) => {
                let dob = moment(res.data, this._config.momentDateFormat);
                setTimeout(() => this.demographicForm.patchValue({dateOfBirth: dob}), 200);
            });
        }
    }

    changeAge() {
        let value = this.demographicForm.get('dateOfBirth').value;
        this.subs.push(this._scheduler.calculateAge(moment(value).format('YYYYMMDD')).subscribe((res: WsMessage) => {
            if (res.data) {
                let age = res.data.split('-');

                this.ageForm.get('years').patchValue(age[0]);
                this.ageForm.get('months').patchValue(age[1]);
                this.ageForm.get('days').patchValue(age[2]);
            }
        }));
    }

    searchProcedureCode() {
        this.subs.push(this._dialog.open(ProcedureCodeSearchComponent, {minWidth: '600px'})
            .afterClosed()
            .subscribe(procedure => {
                if (procedure) {
                    this.procedureCodeControl.patchValue(procedure.description);
                    let aet = this.aetTitles.find(v => v.title == procedure.modality.defaultAETitle);
                    this.appointmentForm.patchValue({
                        appointmentReason: procedure.description,
                        appointmentReasonId: procedure.id,
                        aet: aet,
                        physician: procedure.defaultPerformingPhysician || this.performingPhysicians[0],
                        room: aet?.room
                    });
                }
            }));
    }

    onChangeProcedureCode(event: MatAutocompleteSelectedEvent) {
        let reason: ProcedureCode = event.option.value;
        this.procedureCodeControl.patchValue(reason.description);
        let aet = this.aetTitles.find(v => v.title == reason.modality.defaultAETitle);
        this.appointmentForm.patchValue({
            appointmentReason: reason.description,
            appointmentReasonId: reason.id,
            aet: aet,
            physician: reason.defaultPerformingPhysician || this.performingPhysicians[0],
            room: aet?.room
        });
    }

    addReferringPhysician() {
        this.subs.push(this._dialog.open(ReferringPhysicianAddComponent)
            .afterClosed()
            .subscribe(physician => {
                if (physician) {
                    this.filteredReferringPhysicians.push(physician);
                    this.appointmentForm.get('referringPhysician').patchValue(physician);
                    this.referringPhysicianControl.patchValue(physician.fullName);
                }
            }));
    }

    onChangeRefPhy(event: MatAutocompleteSelectedEvent) {
        let referringPhysician = event.option.value;
        this.referringPhysicianControl.patchValue(referringPhysician.fullName);
        this.appointmentForm.get('referringPhysician').patchValue(referringPhysician);
    }

    private checkExistingPatientsCount() {
        this.subs.push(this.patientForm.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            if (value.firstName && value.lastName)
                this.subs.push(this._patientService
                    .countExistingPatients(value.firstName, value.lastName)
                    .subscribe(total => this.numOfDuplicatePatients = total));

        }));
    }

    private createAgeForm() {
        this.ageForm = this._fb.group({years: 0, months: 0, days: 0});
    }

    private selectAppointment(apt: Appointment) {
        this.appointmentForm.patchValue(apt);
        this.referringPhysicianControl.patchValue(apt.referringPhysician ? apt.referringPhysician.fullName : '');
        this.procedureCodeControl.patchValue(apt.appointmentReason);

        let patient = apt.patient;
        if (!patient.title) patient.title = {id: ''}
        this.patientForm.patchValue(patient);

        let demographic = patient.demographic;
        if (!demographic.gender) demographic.gender = {id: ''}
        this.demographicForm.patchValue(demographic);
    }
}
