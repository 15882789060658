<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
    <h3 mat-dialog-title>{{'CONFIRM' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <div class="confirm-content">
        {{(message || 'DELETEMESSAGE') | translate}}
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="onYes()" color="primary" mat-raised-button tabindex="2">{{'YES' | translate}}</button>
    <button (click)="onNo()" color="warn" mat-raised-button tabindex="-1">{{'NO' | translate}}</button>
</mat-dialog-actions>

