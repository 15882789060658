<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-calendar-question"></mat-icon>
    <h3 mat-dialog-title>{{'SEARCH_AVAILABILITY' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<div mat-dialog-content>

    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4px">
        <div fxLayout="column" class="search-side" [formGroup]="form">

            <div [formGroup]="resourceForm" fxLayout="column">
                <mat-form-field appearance="standard" formGroupName="aet">
                    <mat-label>{{'AET' | translate}}</mat-label>
                    <mat-select [placeholder]="'AET' | translate" formControlName="id" required>
                        <mat-option *ngFor="let aet of aets" [value]="aet.id">
                            {{aet.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="standard" formGroupName="technician">
                    <mat-label>{{'STAFF' | translate}}</mat-label>
                    <mat-select [placeholder]="'TECHNICIAN' | translate" formControlName="id"
                                (openedChange)="selected = !selected">
                        <mat-option *ngFor="let staff of staffList" [value]="staff.id">
                            {{staff.fullName}} <span *ngIf="selected" class="list-option-badge">{{staff.function?.value}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div fxLayout="row" fxLayoutAlign="start center">
                <button fxFlex="24px" mat-button class="aux-button" (click)="previousDate(form.value)"><</button>
                <mat-form-field appearance="standard">
                    <mat-label>{{'START_DATE' | translate}}</mat-label>
                    <input matInput style="padding-left: 4px !important;"
                           [matDatepicker]="datePicker" placeholder="{{'START_DATE' | translate}}" [min]="minDate"
                           formControlName="date">
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                </mat-form-field>
                <button fxFlex="24px" mat-button class="aux-button" (click)="nextDate(form.value)">></button>
            </div>


            <mat-form-field appearance="standard">
                <mat-label>{{'DURATION' | translate}}</mat-label>
                <input matInput placeholder="{{'DURATION' | translate}}" formControlName="duration">
            </mat-form-field>

        </div>

        <div class="mat-table result-side" fxFlex>

            <mat-table #table [dataSource]="dataSource" class="fire-table" matSort>

                <!--Table-->
                <ng-container *ngFor="let col of displayedColumns" cdkColumnDef="{{col.label}}"
                              [class.hidden]="col.hidden">
                    <mat-header-cell *cdkHeaderCellDef class="fire-header-cell" mat-sort-header> {{col.header |
                        translate}}
                        <span *ngIf="col.unit !== 'date' && col.unit !== 'color'">{{col.unit}}</span></mat-header-cell>

                    <ng-container *ngIf="col.unit === 'date'">
                        <mat-cell *cdkCellDef="let row" class="fire-cell"> {{row[col.label] | date:'dd/MM/yyyy'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container *ngIf="col.unit !== 'date'">
                        <mat-cell *cdkCellDef="let row" class="fire-cell"> {{row[col.label]}}</mat-cell>
                    </ng-container>

                </ng-container>

                <!--actions-->
                <ng-container cdkColumnDef="action">
                    <mat-header-cell *cdkHeaderCellDef class="fire-header-cell"></mat-header-cell>
                    <mat-cell *cdkCellDef="let row" class="fire-cell" style="text-align: right">
                        <button color="primary" *ngIf="!fromCalendar" mat-icon-button
                                [matTooltip]="'CREATE_ORDER' | translate"
                                (click)="createOrder(row)">
                            <mat-icon style="color: inherit" fontIcon="mdi-telegram" fontSet="mdi"></mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!--header-->
                <mat-header-row *cdkHeaderRowDef="cols; sticky:true" class="fire-header-row"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: cols;" class="fire-row" (click)="onSelect(row)"></mat-row>

            </mat-table>

            <mat-paginator class="no-border-bottom" #paginator [length]="dataSource.filteredData.length" [pageIndex]="0"
                           [pageSize]="10"
                           [pageSizeOptions]="[5, 10, 20, 25]" showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>

</div>
<mat-dialog-actions align="end">
    <button color="accent" mat-dialog-close mat-raised-button>{{'CANCEL' | translate}}</button>
</mat-dialog-actions>
