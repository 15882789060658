import * as moment from "moment";

export const APPOINTMENT_STATUSES = [
    'canceled',
    'waiting_list',
    'entered',
    'missed',
    'planned',
    'unplanned'
];

export function getOrderStatusColor(status: string): string {
    switch (status) {
        case 'unplanned':
            return '#a09ab0';
        case 'entered':
            return '#36a5fa';
        case 'waiting_list':
            return '#ffc25d';
        case 'planned':
            return '#1bca68';
        case 'canceled':
            return '#fa1848';
        case 'missed':
            return '#f12727';
        default:
            return '#f12727';
    }
}

export function getOrderStatusIcon(status: string): string {
    switch (status) {
        case 'unplanned':
            return 'mdi-calendar-remove';
        case 'entered':
            return 'mdi-account-arrow-left';
        case 'waiting_list':
            return 'mdi-timer-sand';
        case 'planned':
            return 'mdi-calendar-check';
        case 'canceled':
            return 'mdi-cancel';
        case 'missed':
            return 'mdi-call-missed';
        default:
            return 'mdi-call-missed';
    }
}

export const SOURCES_COLORS: any = {
    FireRIS: '#00bcd4',
    PORTAL: '#f44336',
    HIS: '#48f0a8'
}

export enum AptStatus {
    waiting_list = 'waiting_list',
    canceled = 'canceled',
    planned = 'planned',
    unplanned = 'unplanned',
    missed = 'missed',
    entered = 'entered',
}

export class OrderFilter {
    constructor(public key: string = '',
                public dateRange: string = '',
                public physicianId: string = null,
                public appointmentStatus: string = 'ALL') {
        let today = moment().format('YYYYMMDD');
        this.dateRange = `${today}-${today}`
    }
}

