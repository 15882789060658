export class PaymentChangeModel {
    constructor(
        public admissionType: 'PEC' | 'PATIENT' | 'THIRD_PARTY_PAYMENT' | 'INSURED_PATIENT' = 'PATIENT',
        public organism?: string,
        public procedures?: {procedure: string, price: number, discount: number}[],
        public convention?: string,
        public conventionExceptions?: {procedureCode:string, patientPart: number, organismPart: number}[],
        public pec?: string,
        public pecLines: {code: string, price: number, grantedPrice: number}[] = [],
        public tariffId?: number,
        public tariffLines?: any[],
        public globalDiscount: number = 0,
        public enteredAmount: number = 0,
        public changeAmount: boolean = false) {
    }
}
