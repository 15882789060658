<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-pencil-box-outline"></mat-icon>
    <h3 mat-dialog-title>{{'PROCEDURE_TYPE' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <form [formGroup]="form">

        <div fxLayout="column">
            <mat-form-field appearance="standard" class="full-width">
                <mat-label>{{'CODE' | translate}}</mat-label>
                <input matInput placeholder="{{'CODE' | translate}}" formControlName="value" [maxlength]="4">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full-width">
                <mat-label>{{'DAYS_BEFORE_RESULT' | translate}}</mat-label>
                <input matInput type="number" placeholder="{{'DAYS_BEFORE_RESULT' | translate}}"
                       formControlName="daysBeforeExamResult" [min]="0">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full-width">
                <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
                <textarea matInput placeholder="{{'DESCRIPTION' | translate}}" formControlName="description"></textarea>
            </mat-form-field>

            <mat-radio-group fxLayout="row" fxLayoutAlign="start none" formControlName="reportOption">
                <mat-radio-button fxFlex *ngFor="let reportOption of reportOptions" [value]="reportOption">
                    {{reportOption | translate}}
                </mat-radio-button>
            </mat-radio-group>

            <div>
                {{'COLOR' | translate}}: <span class="color-area" [(colorPicker)]="color"
                                               [style.background]="color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </div>
        </div>


    </form>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button [mat-dialog-close]="null" color="warn" mat-raised-button>{{'CANCEL' | translate}}</button>
    <button (click)="onSave(form.value)" [disabled]="form.invalid" color="primary" mat-raised-button>{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>

