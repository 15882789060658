<div fxLayout="column">

    <div fxLayout="row" fxLayoutAlign="space-between center" style="padding-bottom: 16px">
        <h3>{{'SEARCH_PARAGRAPHS' | translate}}</h3>
        <span fxFlex=""></span>
        <button mat-button color="accent" (click)="addNewParagraph()">
            <mat-icon fontSet="mdi" fontIcon="mdi-plus-circle"></mat-icon>
            {{'ADD_NEW' | translate}}</button>
    </div>

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" fxLayoutGap="4px"
        [formGroup]="searchForm">
        <mat-form-field appearance="outline" fxFlex="40%">
            <mat-label>{{'SEARCH' | translate}}</mat-label>
            <input matInput placeholder="{{'SEARCH' | translate}}" formControlName="key">
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="20%">
            <mat-label>{{'CATEGORY' | translate}}</mat-label>
            <mat-select formControlName="procedureType">
                <mat-option *ngFor="let pType of procedureTypes" [value]="pType">
                    {{pType}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="40%">
            <mat-label>{{'PROCEDURE_CODE' | translate}}</mat-label>
            <input [formControl]="procedureCodeCtrl"
                   [matAutocomplete]="autocode"
                   matInput
                   placeholder="{{'PROCEDURE_CODE' | translate}}"
                   type="text">
            <mat-autocomplete #autocode="matAutocomplete" autoActiveFirstOption>
                <mat-option (onSelectionChange)="procedureCodeChange(item)"
                            *ngFor="let item of filteredProcedureCodes | async" [value]="item">
                    {{item}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <div style="overflow: auto; height: calc(100vh - 176px)" [@listAnimation]="resultsLength">

        <fieldset class="editing" *ngIf="newParagraph">
            <div fxLayout="column" [formGroup]="editForm">
                <mat-form-field appearance="outline">
                    <mat-label>{{'TITLE' | translate}}</mat-label>
                    <input matInput placeholder="{{'TITLE' | translate}}" formControlName="title">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'CATEGORY' | translate}}</mat-label>
                    <mat-select formControlName="procedureType">
                        <mat-option *ngFor="let pType of procedureTypes" [value]="pType">
                            {{pType}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'PROCEDURE_CODE' | translate}}</mat-label>
                    <input [formControl]="procedureCodeCtrls[0]"
                           [matAutocomplete]="autocode"
                           matInput
                           placeholder="{{'PROCEDURE_CODE' | translate}}"
                           type="text">
                    <mat-autocomplete #autocode="matAutocomplete" autoActiveFirstOption>
                        <mat-option (onSelectionChange)="procedureEditCodeChange(0, item)"
                                    *ngFor="let item of filteredEditProcedureCodes[0] | async" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'TEXT' | translate}}</mat-label>
                    <textarea matInput placeholder="{{'TEXT' | translate}}" formControlName="text" [rows]="5"
                              [style.height]="'auto'"></textarea>
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="6px">
                    <button (click)="saveParagraph(editForm.value)"
                            color="primary" mat-button>
                        {{'SAVE' | translate}}
                    </button>
                    <button (click)="newParagraph = false"
                            color="accent" mat-button>
                        {{'CANCEL' | translate}}
                    </button>
                </div>
            </div>
        </fieldset>

        <div class="search-progress">
            <mat-progress-bar *ngIf="isLoadingResults" [mode]="'query'" color="accent"></mat-progress-bar>
        </div>
        <fieldset *ngFor="let paragraph of filteredParagraphs" [class.editing]="paragraphToEdit == paragraph">
            <ng-container *ngIf="paragraphToEdit == paragraph; else display">

                <div fxLayout="column" [formGroup]="editForm">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'TITLE' | translate}}</mat-label>
                        <input matInput placeholder="{{'TITLE' | translate}}" formControlName="title">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>{{'CATEGORY' | translate}}</mat-label>
                        <mat-select formControlName="procedureType">
                            <mat-option *ngFor="let pType of procedureTypes" [value]="pType">
                                {{pType}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>{{'PROCEDURE_CODE' | translate}}</mat-label>
                        <input [formControl]="procedureCodeCtrls[paragraph.id]"
                               [matAutocomplete]="autocode"
                               matInput
                               placeholder="{{'PROCEDURE_CODE' | translate}}"
                               type="text">
                        <mat-autocomplete #autocode="matAutocomplete" autoActiveFirstOption>
                            <mat-option (onSelectionChange)="procedureEditCodeChange(paragraph.id, item)"
                                        *ngFor="let item of filteredEditProcedureCodes[paragraph.id] | async" [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>{{'TEXT' | translate}}</mat-label>
                        <textarea matInput placeholder="{{'TEXT' | translate}}" formControlName="text" [rows]="5"
                                  [style.height]="'auto'"></textarea>
                    </mat-form-field>
                </div>

            </ng-container>
            <ng-template #display>
                <legend>{{paragraph.title}}
                    <div class="legend-badge">{{paragraph.procedureType}} → {{paragraph.procedureCode}}</div>
                </legend>
                <div class="text">{{paragraph.text}}</div>
            </ng-template>

            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="6px">
                <button *ngIf="paragraphToEdit == paragraph; else btns" (click)="saveParagraph(editForm.value)"
                        color="primary" mat-button>
                    {{'SAVE' | translate}}
                </button>
                <button *ngIf="paragraphToEdit == paragraph" (click)="paragraphToEdit = null"
                        color="accent" mat-button>
                    {{'CANCEL' | translate}}
                </button>
                <ng-template #btns>
                    <button (click)="insertParagraph(paragraph)" class="btn-insert" color="primary" mat-button>
                        <mat-icon fontIcon="mdi-arrow-left" fontSet="mdi"></mat-icon>
                        {{'INSERT' | translate}}
                    </button>
                    <span fxFlex=""></span>
                    <button [matTooltip]="'EDIT' | translate" (click)="editParagraph(paragraph)" color="primary"
                            mat-icon-button>
                        <mat-icon fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
                    </button>
                    <button [matTooltip]="'DELETE' | translate" (click)="deleteParagraph(paragraph)"
                            mat-icon-button>
                        <mat-icon class="delete-btn" fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                    </button>
                </ng-template>
            </div>
        </fieldset>

    </div>

</div>