import {Validators} from '@angular/forms';
import {RAMED_ENABLED} from "../global-variables";
import {concat} from 'lodash';

export class StaticResource {
    id = 0;
    value = '';
    description = '';

    constructor() {
    }
}

export class Gender extends StaticResource {
}

export class ReasonForExam {
    constructor(public id?: number, public value?: string, public color?: string, public description?: string, public reportOption?: string, public daysBeforeExamResult?: number) {
    }
}

export class ProcedureCode {
    constructor(public id?: number,
                public code?: string,
                public description?: string,
                public duration?: number,
                public modality?: any,
                public bodyRegion?: any,
                public pharmaceutical?: any,
                public reasonForExam?: any,
                public billingCode?: any,
                public defaultPerformingPhysician?: any,
                public composed?: boolean,
                public templateModel?: any) {
    }
}

export interface EmailMessage {
    destAddress: string;
    subject: string;
    body: string;
}

export interface GeneralSetting {
    id?: number,
    smsSendingMode: string;
    smsAutoTrigger: string;
    patientIdGenerationMode: string;
    accessionIdGenerationMode: string;
    patientIdPrefix: string;
    accessionNumberPrefix: string;
    reportWaiting: string;
    reportInProgress: string;
    reportReady: string;
    reportDelivered: string;
    reportPrinted: string;
    reportSent: string;
    wfNone: string;
    wfLate: string;
    wfWaiting: string;
    wfDelivered: string;
    wfBeforeTime: string;
    wfExamFinished: string;
    wfExamStarted: string;
    billingRequired: boolean;
    defaultExam: number;
    idScannerConfigured: boolean;
    scannerIdUrl: string;
    waitingDurationBeforeAlert: number;
    singleReport: boolean;
    schedulerDefaultView: string;
    reportPrintMode: 'CHROME' | 'CUPS';
    receiptPrintMode: 'CHROME' | 'CUPS';
    ticketPrintMode: 'CHROME' | 'CUPS';
    statisticDefaultView: string;
}

export interface Dictionary {
    id: number;
    name: string;
    deleted?: boolean;
    values: KeyValue[];
}

export interface KeyValue {
    id: string;
    key?: string;
    value: string;
}

export interface IStaticVD {
    id: string;
    value: string;
}

export interface Staff {
    id: string;
    lastName: string;
    firstName: string;
}

export interface Holiday {
    id: number;
    title: string;
    start: any;
    end: any;
}

export class ImagingCenter {
    constructor(public id?: string,
                public name?: string,
                public gmailPassword?: string,
                public communicatingEmail?: string,
                public oadc?: string,
                public smsUsername?: string,
                public smsPassword?: string,
                public arabicName?: string,
                public email?: string,
                public phone?: string,
                public fax?: string,
                public address?: string,
                public website?: string
    ) {
    }
}

export const initEntity = {
    id: '',
    value: '',
    description: ''
};

export const initRequiredEntity = {
    id: ['', Validators.required],
    value: '',
    description: ''
};

export const initPriority = {
    id: ['', Validators.required],
    value: '',
    description: '',
    color: ''
};

export const initStaff = {
    id: '',
    lastName: ''
};

export const imagingServiceRequestEntity = {
    id: '',
    version: '',
    accessionNumber: '',
    imagingServiceRequestComments: '',
    requestingPhysician: '',
    requestingPhysicianIdentification: '',
    requestingService: '',
    issuerOfAccessionNumberSequence: '', // array of 0 or 1 item
    issueDateOfTheImagingServiceRequest: [null],
    issueTimeOfTheImagingServiceRequest: '12:35',
    placerOrderNumber: '',
    orderPlacerIdentification: '',
    fillerOrderNumber: '',
    orderFillerIdentification: '',
    orderEnteredBy: null,
    orderEnterersLocation: '',
    orderCallBackPhoneNumber: '',
    admissionID: '',
    issuerOfAdmissionIDSequence: '', // array of oly 1 item
    requestedProcedure: '',
    imagingOrder: '',
    patient: '',
};

export const requestedProcedureEntity = {
    id: '',
    version: '',
    requestedProcedureID: '',
    requestedProcedureComments: '',
    reasonForRequestedProcedureCodeSequence: '', // array
    studyInstanceUID: '',
    studyDate: [null],
    studyTime: '08:00',
    referencedStudySequence: '', // array
    requestedProcedureDescription: '',
    requestedProcedurePriority: '',
    patientTransportArrangements: '',
    requestedProcedureLocation: '',
    confidentialityCode: '',
    reportingPriority: '',
    namesOfIntendedRecipientsOfResults: '',
    scheduledProcedureSteps: [],
    referencedSOPInstanceUID: '',
    referencedSOPClassUID: '',
};

export const scheduledProcedureStepEntity = {
    id: '',
    version: '',
    scheduledStationAETitle: null,
    scheduledStationName: '',
    scheduledProcedureStepLocation: '',
    scheduledProcedureStepStartTime: '12:00',
    scheduledProcedureStepEndTime: '13:00',
    scheduledProcedureStepStartDate: [null],
    scheduledProcedureStepEndDate: [null],
    scheduledPerformingPhysiciansName: '',
    scheduledPerformingPhysicianIdentification: '',
    scheduledProcedureStepDescription: '',
    scheduledProtocolCodeSequence: [],
    scheduledProcedureStepID: '',
    scheduledProcedureStepStatus: '',
    commentsOnTheSPS: '',
    spsStatusColor: '',
    modality: null,
    requestedContrastAgent: '',
    preMedication: '',
    anatomicalOrientationType: '',
    performedProcedureStep: null,
    requestedProcedure: null,
    reportingTask: null,
    procedureCode: null,
    workflowItem: null,
    assistantPhysicians: '',
    numberOfFilms: '',
    contrast: '',
    defaultTemplateModel: '',
    patientAge: '',
    patientSex: '',
    accessionNumber: '',
    patientName: '',
    patientID: '',
    paymentID: '',
    enteredBy: '',
    studyInstanceUID: '',
    technician: ''
};

export const imagingOrderEntity = {
    id: '',
    version: '',
    callBackPhoneNumber: '',
    requestingPhysician: '',
    orderingProvider: '',
    enteringOrganization: '',
    requestingService: null,
    placerOrderNumber: '',
    fillerOrderNumber: '',
    relevantClinicalInfo: '',
    ResultCopiesTo: '',
    plannedPatientTransportComment: '',
    reasonForStudy: '',
    supplementalServiceInformation: '',
    referringDoctor: '',
    requestedDate: [null],
    requestedTime: '08:00',
    enteredBy: null,
    otherRejectionReason: null,
    orderTimeLine: null,
    transcriptionist: null,
    verifiedBy: null,
    assistantResultInterpreter: null,
    principalResultInterpreter: null,
    orderControl: null,
    orderStatus: null,
    orderType: null,
    transportArrangement: null,
    transportationMode: null,
    rejectionReason: null,
    billingStatus: null,
    priority: null,
    patient: null,
    confidentiality: null
};

export const DEBITERS: string[] = concat(['PATIENT', 'INSURED_PATIENT', 'THIRD_PARTY_PAYMENT', 'PEC'], RAMED_ENABLED ? ['RAMED'] : []);

export const ADMISSIONS: string[] = ['Interne', 'Externe'];

export const addressEntity = {
    country: '',
    province: '',
    city: '',
    street: '',
    postalCode: '',
};


export const PHARMACEUTICAL2: string[] = ['C+', 'C-'];

export const PHARMACEUTICAL: string[] = [
    '18F - NAF',
    'ACE INHIBITOR',
    'ANTISPASMODIC',
    'ARBUTAMINE',
    'AV - 45',
    'BARIUM',
    'BILIARY CONTRAST',
    'BLADDER CONTRAST',
    'CEA',
    'CHOLEGRAPHIC CONTRAST',
    'COLLECTING SYSTEM CONTRAST',
    'DIURETIC',
    'DOBUTAMINE',
    'DTPA',
    'ECD',
    'FISTULA CONTRAST',
    'FLORBETAPIR',
    'GALLIUM',
    'GLUCOHEPTONATE',
    'HIDA',
    'HIPPURAN',
    'HSA',
    'I - 123',
    'I - 123 MIBG',
    'I - 131',
    'I - 131 TOSITUMOMAB',
    'IN - 111',
    'IN - 111 CAPROMAB PENDETIDE',
    'IN - 111 PENTETREOTIDE',
    'IN - DTPA',
    'IN - WBC',
    'INTRA POUCH CONTRAST',
    'INTRAARTICULAR CONTRAST',
    'INTRADISCAL CONTRAST',
    'INTRAFALLOPIAN CONTRAST',
    'INTRALOOP CONTRAST',
    'INTRATHECAL CONTRAST',
    'INTRATHECAL CONTRAST VIA LUMBAR INJECTION',
    'INTRAUTERINE CONTRAST',
    'INTRAVENOUS CONTRAST',
    'INTRAVESICAL CONTRAST',
    'IPPA',
    'IVC CONTRAST',
    'MAA',
    'MAG3',
    'MDP',
    'MIBG',
    'MICROSPHERES',
    'MORPHINE',
    'NEPHROSTOMY CONTRAST',
    'ORAL CHOLECYSTOGRAPHIC CONTRAST',
    'ORAL CONTRAST',
    'PERSANTINE',
    'PLATELET PLASMA',
    'PYROPHOSPHATE',
    'Radiolabeled denatured RBC',
    'RADIUM',
    'RECTAL CONTRAST',
    'SALIVARY DUCT CONTRAST',
    'SAMARIUM - 153',
    'SEMINAL VESICLE CONTRAST',
    'SESTAMIBI',
    'SHUNT CONTRAST',
    'SIR - SPHERES',
    'SULFUR COLLOID',
    'T - TUBE CONTRAST',
    'TC - DTPA',
    'TC - HMPAO',
    'TC - O4',
    'TC - RBC',
    'TC - WBC',
    'TECHNIETIUM',
    'THALLIUM',
    'THERASPHERES',
    'THROMBOLYTIC',
    'URETERAL STENT CONTRAST',
    'URETHRAL CONTRAST',
    'WATER SOLUBLE CONTRAST',
    'WITH IV CONTRAST',
    'WITHOUT IV CONTRAST',
    'WITHOUT THEN WITH IV CONTRAST',
    'XENON',
    'Y - 90 IBRITUMOMAB TIUXETAN',
];

export const VIEW: string[] = [
    'AP',
    'AP SUPINE',
    'APICAL LORDOTIC',
    'AXILLARY',
    'BASILAR',
    'BENDING',
    'CONE',
    'CROSS TABLE LATERAL',
    'DECUBITUS',
    'ERECT',
    'EXPIRATION',
    'EXTENSION',
    'FLEXION',
    'INLET',
    'INSPIRATION',
    'JUDET',
    'LATERAL',
    'LEFT LATERAL',
    'LEFT LATERAL DECUBITUS',
    'LEFT OBLIQUE',
    'OBLIQUE',
    'OBLIQUES',
    'OUTLET',
    'PA',
    'PANORAMIC',
    'RIGHT DECUBITUS',
    'RIGHT LATERAL',
    'RIGHT LATERAL DECUBITUS',
    'RIGHT OBLIQUE',
    'SPOT',
    'SUBMENTOVERTICAL',
    'SUNRISE',
    'SUPINE',
    'SWIMMERS',
    'TRANSTHORACIC',
    'TUNNEL',
    'UPRIGHT',
    'WATERS'
];

export const CONFIDENTIALITIES: string[] = ['PUBLIC', 'PRIVATE', 'VIP'];
