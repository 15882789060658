import {Component, Input, OnInit} from '@angular/core';
import * as Plyr from 'plyr';

@Component({
    selector: 'app-player',
    template: `
        <div *ngIf="audio" plyr style="width: 400px;"
             [plyrTitle]="'Audio title'"
             [plyrType]="'audio'"
             [plyrSources]="audioSources"
             [plyrOptions]="playerOptions"
             (plyrInit)="player = $event"></div>`,
    styles: [`
        ::ng-deep .plyr--audio .plyr__controls {
            background: transparent !important;
            color: #737b7e !important;
            padding: 0 !important;
        }
    `]
})
export class PlayerComponent implements OnInit {
    player: Plyr;
    audioSources: Plyr.Source[] = [];
    playerOptions = {invertTime: false, blankVideo: '', loadSprite: true, controls: ['play','progress', 'duration', 'mute', 'volume']};

    @Input() audio: {source: Plyr.Source, duration: number};

    constructor() {}

    ngOnInit(): void {
        this.playerOptions['duration'] = this.audio.duration;
        this.audioSources.push(this.audio.source);
    }
}
