<mat-toolbar class="sc-toolbar" color="primary">
    <div class="title">
        <span>{{'GENERAL_SETTING' | translate}}</span>
    </div>
</mat-toolbar>

<div [formGroup]="calFormGroup" *ngIf="generalSetting" style="padding: 16px">


    <div class="setting-area">

        <h4 style="margin: 8px 0 16px 0;">{{'CALENDAR_SETTING' | translate}}</h4>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">

            <mat-form-field appearance="outline">
                <mat-label>{{'OPENINGTIME' | translate}}</mat-label>
                <input type="time" matInput placeholder="{{'OPENINGTIME' | translate}}" formControlName="openingTime"
                       (change)="onSelectTime($event)">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'CLOSINGTIME' | translate}}</mat-label>
                <input type="time" matInput placeholder="{{'CLOSINGTIME' | translate}}" formControlName="closingTime"
                       (change)="onSelectTime($event)">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'SCHEDULER_DEFAULT_VIEW' | translate}}</mat-label>
                <mat-select placeholder="{{'SCHEDULER_DEFAULT_VIEW' | translate}}"
                            (selectionChange)="onSelectSchedulerView($event)"
                            [(ngModel)]="generalSetting.schedulerDefaultView" [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let option of ['CALENDAR', 'EXTERNAL_APPOINTMENTS', 'SCHEDULER']"
                                [value]="option" class="option">
                        {{option | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <mat-form-field appearance="outline" formGroupName="defaultView">
                <mat-label>{{'DEFAULTCALENDARVIEW' | translate}}</mat-label>
                <mat-select placeholder="{{'DEFAULTCALENDARVIEW' | translate}}"
                            (selectionChange)="onSelectView($event)"
                            formControlName="id">
                    <mat-option *ngFor="let _view of views" [value]="_view.id" class="option">
                        {{_view.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" formGroupName="minTimeSlot">
                <mat-label>{{'MINIMUMTIMESLOT' | translate}}</mat-label>
                <mat-select placeholder="{{'MINIMUMTIMESLOT' | translate}}"
                            (selectionChange)="onSelectTime($event)"
                            formControlName="id">
                    <mat-option *ngFor="let _slot of slots" [value]="_slot.id" class="option">
                        {{_slot.value}} minutes
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
            <h5 fxFlex="140px">{{'CLOSINGDAYS' | translate}}:</h5>

            <section fxFlex>
                <mat-button-toggle-group multiple [vertical]="false" #group="matButtonToggleGroup">
                    <mat-button-toggle *ngFor="let day of days" [disabled]="false" [checked]="day.checked"
                                       [value]="day.name"
                                       (change)="onSelectDay($event)">
                        {{day.name}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </section>
        </div>
        <!--
                <mat-form-field appearance="outline" formGroupName="minTimeSlot">
                    <mat-label>{{'DEFAULT_EXAM' | translate}}</mat-label>
                    <mat-select placeholder="{{'DEFAULT_EXAM' | translate}}"
                                [(ngModel)]="generalSetting.defaultExam" [ngModelOptions]="{standalone: true}"
                                (selectionChange)="saveGeneralSetting(generalSetting)">
                        <mat-option *ngFor="let exam of exams | async" [value]="exam.id" class="option">
                            {{exam.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>-->

    </div>

    <div class="setting-area">
        <h4 style="margin: 16px 0;">{{'PRINTING_MODES' | translate}}</h4>

        <div *ngIf="generalSetting" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <h5 fxFlex="200px">{{'REPORT_PRINT_MODE' | translate}}:</h5>
            <section>
                <mat-button-toggle-group #report="matButtonToggleGroup"
                                         (change)="saveGeneralSetting(generalSetting)"
                                         [(ngModel)]="generalSetting.reportPrintMode"
                                         [ngModelOptions]="{standalone: true}">
                    <mat-button-toggle *ngFor="let option of printingModes" [checked]="option.checked"
                                       [value]="option.name">
                        {{option.name | translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </section>
        </div>
        <div *ngIf="generalSetting" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <h5 fxFlex="200px">{{'RECEIPT_PRINT_MODE' | translate}}:</h5>
            <section>
                <mat-button-toggle-group #receipt="matButtonToggleGroup"
                                         (change)="saveGeneralSetting(generalSetting)"
                                         [(ngModel)]="generalSetting.receiptPrintMode"
                                         [ngModelOptions]="{standalone: true}">
                    <mat-button-toggle *ngFor="let option of printingModes" [checked]="option.checked"
                                       [value]="option.name">
                        {{option.name | translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </section>
        </div>
        <div *ngIf="generalSetting" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <h5 fxFlex="200px">{{'TICKET_PRINT_MODE' | translate}}:</h5>
            <section>
                <mat-button-toggle-group #ticket="matButtonToggleGroup"
                                         (change)="saveGeneralSetting(generalSetting)"
                                         [(ngModel)]="generalSetting.ticketPrintMode"
                                         [ngModelOptions]="{standalone: true}">
                    <mat-button-toggle *ngFor="let option of printingModes" [checked]="option.checked"
                                       [value]="option.name">
                        {{option.name | translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </section>
        </div>

    </div>

    <div class="setting-area">
        <h4 style="margin: 16px 0;">{{'ID_GENERATION_MODES' | translate}}</h4>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" *ngIf="generalSetting">
            <h5 fxFlex="200px">{{'PATIENT_ID_GENERATION' | translate}}:</h5>
            <section>
                <mat-button-toggle-group #gro="matButtonToggleGroup"
                                         [(ngModel)]="generalSetting.patientIdGenerationMode"
                                         [ngModelOptions]="{standalone: true}"
                                         (change)="saveGeneralSetting(generalSetting)">
                    <mat-button-toggle *ngFor="let option of idGenerationModes" [checked]="option.checked"
                                       [value]="option.name">
                        {{option.name | translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </section>

            <mat-form-field appearance="standard" *ngIf="generalSetting.patientIdGenerationMode === 'AUTOMATIC'">
                <mat-label>{{'PATIENT_ID_PREFIX' | translate}}</mat-label>
                <input matInput [placeholder]="'PATIENT_ID_PREFIX' | translate"
                       [(ngModel)]="generalSetting.patientIdPrefix"
                       [ngModelOptions]="{standalone: true}"
                       (change)="saveGeneralSetting(generalSetting)">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" *ngIf="generalSetting">
            <h5 fxFlex="200px">{{'AN_GENERATION_MODE' | translate}}:</h5>
            <section>
                <mat-button-toggle-group #group="matButtonToggleGroup"
                                         [(ngModel)]="generalSetting.accessionIdGenerationMode"
                                         [ngModelOptions]="{standalone: true}"
                                         (change)="saveGeneralSetting(generalSetting)">
                    <mat-button-toggle *ngFor="let option of idGenerationModes" [checked]="option.checked"
                                       [value]="option.name">
                        {{option.name | translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </section>
            <mat-form-field appearance="standard" *ngIf="generalSetting.accessionIdGenerationMode === 'AUTOMATIC'">
                <mat-label>{{'AN_PREFIX' | translate}}</mat-label>
                <input matInput [placeholder]="'AN_PREFIX' | translate"
                       [(ngModel)]="generalSetting.accessionNumberPrefix"
                       [ngModelOptions]="{standalone: true}"
                       (change)="saveGeneralSetting(generalSetting)">
            </mat-form-field>
        </div>
    </div>


    <div class="setting-area" fxLayout="row" fxLayoutAlign="space-between">

        <div fxFlex>
            <h4 style="margin: 16px 0;">{{'BILLING' | translate}}</h4>
            <mat-slide-toggle [(ngModel)]="generalSetting.billingRequired" [ngModelOptions]="{standalone: true}"
                              (change)="saveGeneralSetting(generalSetting)">{{'BILLING_REQUIRED' | translate}}
            </mat-slide-toggle>

            <h4 style="margin: 16px 0;">{{'PATIENT_WITH_MULTIPLE_EXAMS' | translate}}</h4>
            <mat-slide-toggle [(ngModel)]="generalSetting.singleReport" [ngModelOptions]="{standalone: true}"
                              (change)="saveGeneralSetting(generalSetting)">{{'SINGLE_REPORT' | translate}}
            </mat-slide-toggle>
        </div>
        <div fxFlex>
            <h4 style="margin: 16px 0;">{{'PATIENT_STATUS_WAITING_DURATION' | translate}}</h4>
            <mat-form-field appearance="outline">
                <mat-label>{{'WAITING_DURATION_BEFORE_ALERT' | translate}}</mat-label>
                <mat-select placeholder="{{'WAITING_DURATION_BEFORE_ALERT' | translate}}"
                            [(ngModel)]="generalSetting.waitingDurationBeforeAlert"
                            [ngModelOptions]="{standalone: true}"
                            (selectionChange)="saveGeneralSetting(generalSetting)">
                    <mat-option *ngFor="let d of [15,20,25,30,45,60]" [value]="d" class="option">{{d}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>

    <div class="setting-area">
        <div fxLayout="column">
            <h4 style="margin: 16px 0;">{{'STATISTICS' | translate}}</h4>
            <div>
                <mat-form-field appearance="outline">
                    <mat-label>{{'DEFAULT_STATE' | translate}}</mat-label>
                    <mat-select (selectionChange)="saveGeneralSetting(generalSetting)"
                                [(ngModel)]="generalSetting.statisticDefaultView" [ngModelOptions]="{standalone: true}"
                                placeholder="{{'DEFAULT_STATE' | translate}}">
                        <mat-optgroup *ngFor="let view of statisticViews" [label]="view.name | translate">
                            <mat-option *ngFor="let state of view.states" [value]="view.name+'@'+state.value">
                                {{state.value | translate}}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
            </div>

            <h4 style="margin: 16px 0;">{{'ID_CARD_SCANNER' | translate}}</h4>

            <mat-slide-toggle (change)="saveGeneralSetting(generalSetting)"
                              [(ngModel)]="generalSetting.idScannerConfigured"
                              [ngModelOptions]="{standalone: true}">{{'ID_CARD_SCANNER_CONFIGURED' | translate}}
            </mat-slide-toggle>


            <mat-form-field *ngIf="generalSetting.idScannerConfigured" appearance="standard" fxFlex>
                <mat-label>{{'SCANNER_ID_URL' | translate}}</mat-label>
                <input (change)="saveGeneralSetting(generalSetting)" [(ngModel)]="generalSetting.scannerIdUrl"
                       [ngModelOptions]="{standalone: true}"
                       [placeholder]="'SCANNER_ID_URL' | translate"
                       matInput>
            </mat-form-field>
        </div>
    </div>

    <div class="setting-area">
        <div fxLayout="column">
            <h4 style="margin: 16px 0;">{{'SMS' | translate}}</h4>

            <div style="font-weight: bold;padding: 8px 0;color: #607d8b;">{{'MODE' | translate}}</div>
            <mat-radio-group (change)="saveGeneralSetting(generalSetting)" [(ngModel)]="generalSetting.smsSendingMode"
                             [ngModelOptions]="{standalone: true}" fxLayout="row" fxLayoutAlign="start end">
                <mat-radio-button *ngFor="let option of ['AUTOMATIC', 'MANUAL']" [value]="option">
                    {{option | translate}}
                </mat-radio-button>
            </mat-radio-group>


            <ng-container *ngIf="generalSetting.smsSendingMode === 'AUTOMATIC'">

                <div style="font-weight: bold;padding: 8px 0;color: #607d8b;">{{'TRIGGER' | translate}}</div>
                <mat-radio-group (change)="saveGeneralSetting(generalSetting)"
                                 [(ngModel)]="generalSetting.smsAutoTrigger"
                                 [ngModelOptions]="{standalone: true}" fxLayout="row" fxLayoutAlign="start end">
                    <mat-radio-button *ngFor="let option of ['REPORT_SIGNED', 'REPORT_VALIDATED']" [value]="option">
                        {{option | translate}}
                    </mat-radio-button>
                </mat-radio-group>

            </ng-container>
        </div>
    </div>

</div>
