<div class="opt-group" [formGroup]="group">

    <span class="opt-label"> {{ config.name }}</span>

    <mat-radio-group [formControlName]="config.name">
        <mat-radio-button *ngFor="let option of config.options" [value]="option.value">
            {{ option.value }}
        </mat-radio-button>
    </mat-radio-group>

</div>
