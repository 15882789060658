import {AfterViewInit, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {StateComponent, ViewQuery} from '../model';
import {StatisticService} from '../statistic.service';
import {map as _map} from 'lodash';
import {BehaviorSubject, merge, of as observableOf, Subscription} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import * as moment from "moment";
import {ReferringPhysicianExam} from "../../model";
import * as numeral from 'numeral';
import {MatSort} from "@angular/material/sort";
import {RPH_TABLE_CONF} from "./table-conf";
import {catchError, map, startWith, switchMap, tap} from "rxjs/operators";
import {tableAnimation} from "../../animations";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-referring-exam-state',
    templateUrl: './referring-exam-state.component.html',
    styleUrls: ['./referring-exam-state.component.scss'],
    animations: [tableAnimation,
        trigger('detailExpand', [
            state('collapsed', style({
                height: '0px',
                minHeight: '0',
                display: 'none',
                visibility: 'hidden',
                zIndex: '-1'
            })),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class ReferringExamStateComponent implements StateComponent, AfterViewInit, OnDestroy {
    @Input() data: ViewQuery;

    displayedColumns = [];
    columnsToDisplay = [];
    availableColumns = [];
    dataSource = new MatTableDataSource<ReferringPhysicianExam>();
    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    expandedElement: ReferringPhysicianExam;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    trackById = (index: number, item: any): string => item.physicianName;
    private query = new BehaviorSubject<any>(null);
    private sub: Subscription;
    private dataSet: any;


    constructor(private service: StatisticService) {
        this.displayedColumns = RPH_TABLE_CONF;
        this.availableColumns = RPH_TABLE_CONF.filter(item => !item.hidden);

        this.columnsToDisplay = _map(this.availableColumns.filter(c => !c.hidden), 'value');
    }

    expandRow(row: ReferringPhysicianExam) {
        this.expandedElement = this.expandedElement === row ? null : row;
    }


    ngAfterViewInit(): void {
        this.query.next({startDate: this.data.startDate, endDate: this.data.endDate, referringPhysicianId: this.data.filter || '0'});
        this.sort.sortChange.subscribe(res => {
            this.paginator.pageIndex = 0;
        });

        let observedFilters = [
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.query
        ];

        this.sub = merge(...observedFilters)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;

                    let query = this.query.getValue();
                    let start = moment(query.startDate).format('YYYYMMDD');
                    let end = moment(query.endDate).format('YYYYMMDD');

                    let dateRange= `${start}-${end}`;
                    return this.service.getExamsPerReferringPhysicians(
                        this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        dateRange,
                        query.referringPhysicianId);
                }),
                tap(data => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data['totalElements'];
                }),
                map(data => data['content'] as ReferringPhysicianExam[]),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe(data => {
            this.dataSource.data = data
        });
    }

    filter(month: number) {
        this.dataSet = this.dataSet.filter(v => v.month === month);
    }

    formatNumeral(numValue: any): any {
        return numeral(numValue).format(`0,0`);
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    getExams(row: ReferringPhysicianExam): {exam: string, count: string, totalPaid: number}[] {
        return row.examTypes.map(it => {
            let split = it.split('_');
            return {
                exam: split[0],
                count: split[1],
                totalPaid: parseFloat(split[2])
            }
        });
    }
}
