<div class="speech-container" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="12px">

    <div class="speech-bar">
        <input type="text" id="note-name" [placeholder]="('TITLE' | translate)+'...'" #noteName >
    </div>

    <div fxFlex id="result" class="text-result" contenteditable (keydown)="clearInput($event)">
        <span class="placeholder">Note ici...</span>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center">
        <button *ngIf="!data" mat-mini-fab [disabled]="recording.getValue()" (click)="startSpeak()" [matTooltip]="'START_SPEECH' | translate">
            <mat-icon fontSet="mdi" fontIcon="mdi-microphone"></mat-icon>
        </button>
        <span fxFlex></span>
        <button *ngIf="!data" mat-button color="primary" (click)="save()">{{'SAVE' | translate}}</button>
        <button mat-button color="accent" matDialogClose>{{'CLOSE' | translate}}</button>
    </div>

</div>
