import {CalendarOptions, EventApi, FullCalendarComponent} from '@fullcalendar/angular';
import {
    AfterViewInit,
    ApplicationRef,
    Component,
    ComponentFactoryResolver,
    Injector,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {RESOURCE_TYPES} from './event-utils';
import {assign, get as _get} from 'lodash';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {WsService} from '../../ws.service';
import {SettingService} from '../../setting/setting.service';
import {SchedulerService} from '../../scheduler/scheduler.service';

import * as $ from 'jquery';
import {ComponentPortal, DomPortalOutlet} from '@angular/cdk/portal';
import {CalendarEventComponent} from './calendar-event/calendar-event.component';
import {DeleteConfirmComponent} from '../delete-confirm/delete-confirm.component';
import {CalendarEvent, GeneralSetting} from '../../model';
import {PatientArrivedComponent} from '../patient-arrived/patient-arrived.component';
import {PaymentOrderComponent} from '../payment-order/payment-order.component';
import {calculateSlotDuration, showPrintPreview, targetView} from '../shared-functions';
import {FtMenuComponent} from './ft-menu/ft-menu.component';
import {forkJoin, Observable} from 'rxjs';
import {SharedService} from '../shared.service';
import {toMoment} from '@fullcalendar/moment';
import {TranslateService} from "@ngx-translate/core";
import {calendarPresets} from "./calendar-config";
import {AppConfigService} from "../../app-config.service";
import {AppointmentEditComponent} from "../../scheduler/appointment-edit/appointment-edit.component";
import {CalendarSetting} from "../../setting/schedule-setting/schedule-setting.component";
import {PatientService} from "../../patient/patient.service";
import {ExamAdvancedComponent} from "../../scheduler/exam-advanced/exam-advanced.component";
import * as moment from 'moment';
import {AptStatus} from "../../scheduler/external-appointments";

@Component({
    selector: 'ft-calendar',
    templateUrl: './ft-calendar.component.html',
    styleUrls: ['./ft-calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FtCalendarComponent implements OnChanges, AfterViewInit {

    calendarOptions: CalendarOptions;
    currentEvents: EventApi[] = [];
    @Input() user: any;
    @Input() calendarSetting: CalendarSetting;
    @ViewChild('calendar') calendar: FullCalendarComponent;
    @ViewChild('picker') picker: any;
    private canViewConfData: boolean;
    private res: string = 'aet';
    private calendarApi;
    private generalSetting: GeneralSetting;

    private businessHours = [
        {
            daysOfWeek: [],
            startTime: '01:00',
            endTime: '23:00'
        }
    ]

    constructor(private _resolver: ComponentFactoryResolver,
                private _injector: Injector,
                private _appRef: ApplicationRef,
                public dialog: MatDialog,
                private snackBar: MatSnackBar,
                private router: Router,
                private ws: WsService,
                private _config: AppConfigService,
                private translate: TranslateService,
                private sharedService: SharedService,
                private setting: SettingService,
                private patientService: PatientService,
                private service: SchedulerService) {

        this.setting.getGeneralSetting().subscribe(value => this.generalSetting = value);

        this.calendarOptions = assign({}, calendarPresets(this), {
            customButtons: {
                dateSelect: {
                    icon: 'date-select',
                    click: this.changeDate.bind(this)
                },
                resourceSelect: {}
            },
            eventsSet: this.handleEvents.bind(this),
            eventDidMount: this.renderEvent.bind(this),
            eventMouseEnter: (info) => info.el.classList += ' hover',
            eventMouseLeave: (mouseEnterInfo) => $(mouseEnterInfo.el).removeClass('hover'),
            eventDrop: (info) => {
                if (toMoment(info.event._instance.range.start, this.calendarApi).isBefore(toMoment(new Date(), this.calendarApi), 'minute')) info.revert();
                else this.updateEvent(info.event._instance.range, info.event._def.extendedProps);
            },
            dateClick: this.clickDate.bind(this),
            eventClick: this.clickEvent.bind(this),
            select: this.createEvent.bind(this),
            resources: (fetchInfo, successCallback, failureCallback) => {
                this.getResourceByName(this.res).subscribe(list => {
                    successCallback(list.map(item => {
                        let res = {
                            id: item.id,
                            title: this.res === 'physician' ? item.fullName : item.name,
                            resourceName: this.res,
                            groupId: item.id,
                        }

                        if (res.title === 'CT') res['businessHours'] = [
                            {
                                startTime: '08:00',
                                endTime: '18:00',
                                daysOfWeek: [1, 3, 5]
                            },
                            {
                                startTime: '07:00',
                                endTime: '19:00',
                                daysOfWeek: [2, 4, 6]
                            }
                        ];

                        return res;
                    }));
                })
            },
            events: (info, successCallback, failureCallback) => {
                if (this.calendarApi) this.service
                    .getScheduledAppointments(this.res, toMoment(info.start, this.calendarApi).format('YYYYMMDD'), toMoment(info.end, this.calendarApi).format('YYYYMMDD'))
                    .subscribe(successCallback);
            },
            /*eventAdd: console.log.bind(this),
            eventChange: console.log.bind(this),
            eventRemove: console.log.bind(this),
            eventClassNames: console.log.bind(this),
            eventWillUnmount: console.log.bind(this),*/
        });

    }

    private getResourceByName(res: any): Observable<any> {
        if (res === 'room') {
            return this.sharedService.getRooms();
        } else if (res === 'physician') {
            return this.sharedService.getPerformingPhysicians();
        } else {
            return this.setting.getAetList();
        }
    }

    private updateEvent(eventRange: any, event: CalendarEvent) {
        let updatedEvent = assign({}, {
            id: event.spsId,
            startDate: toMoment(eventRange.start, this.calendarApi).format('YYYY-MM-DD'),
            endDate: toMoment(eventRange.end, this.calendarApi).format('YYYY-MM-DD'),
            startTime: toMoment(eventRange.start, this.calendarApi).format('HH:mm'),
            endTime: toMoment(eventRange.end, this.calendarApi).format('HH:mm')
        });

        this.service.updateAppointment(updatedEvent).subscribe(res => {
            if (!res) console.error('Cannot update calendar event !');
            else this.snackBar.open(this.translate.instant('RDV_UPDATED'), '', {duration: 1000})
        });
    }

    changeDate() {
        this.picker.open();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['user'] && changes['user'].currentValue.length !== 0) this.canViewConfData = changes['user'].currentValue.canViewConfData;
        if (changes['calendarSetting'] && changes['calendarSetting'].currentValue) this.updateCalendarSetting(changes['calendarSetting'].currentValue)
    }

    handleEvents(events: EventApi[]) {
        this.currentEvents = events;
    }

    gotoDate($event: any) {
        this.calendar.getApi().gotoDate($event.value.toDate())
    }

    private createEvent(info) {
        let {resourceName, groupId: resourceId} = _get(info.resource, '_resource.extendedProps', {});
        this.calendarApi.unselect(); // clear date selection

        this.dialog.open(AppointmentEditComponent, {
            data: {
                selectedDateRange: {
                    start: toMoment(info.start, this.calendarApi),
                    end: toMoment(info.end, this.calendarApi)
                },
                resource: {name: resourceName, id: resourceId}
            },
            disableClose: true
        }).afterClosed().subscribe(res => {
            this.calendarApi.refetchEvents();
            this.calendarApi.render();
        });
    }

    private clickDate(info) {
        if (info.view.name === 'month') {
            info.jsEvent.preventDefault();
            info.jsEvent.stopPropagation();

            this.calendar.getApi().changeView('timeGridDay')
        }
    }

    private clickEvent(info) {
        let calEvent = info.event._def.extendedProps;
        this.editEvent(calEvent);
    }

    printRDV(event: CalendarEvent) {
        this.service.printAppointment(event.spsId).subscribe(ok => {
            if (ok) showPrintPreview(`/templates/rdv.html?key=${toMoment(new Date(), this.calendarApi).format('YYYYMMDDHHmmssSSS')}`);
            else console.log('Can\'t print rdv');
        });
    }

    private renderEvent(info) {

        if (['timeGridWeek', 'timeGridDay', 'resourceTimeGridDay', 'resourceTimeGrid2Day', 'resourceTimelineDay'].includes(info.view.type)) {
            $(info.el).empty();

            const calendarEventComponentPortal = new ComponentPortal<CalendarEventComponent>(CalendarEventComponent);
            const _eventHost = new DomPortalOutlet(info.el, this._resolver, this._appRef, this._injector);
            const _cmpRef = _eventHost.attachComponentPortal(calendarEventComponentPortal);

            info.event.setExtendedProp('title', info.event.title);
            info.event.setExtendedProp('timeText', info.timeText);

            _cmpRef.instance.event = info.event._def.extendedProps;
            _cmpRef.instance.viewType = info.view.type;
            _cmpRef.instance.user = this.user;
            _cmpRef.instance.generalSetting = this.generalSetting;

            _cmpRef.instance.onDelete.subscribe(ev => this.deleteEvent(ev, _eventHost, info));
            _cmpRef.instance.onEventEdit.subscribe(this.editEvent.bind(this));
            _cmpRef.instance.onSchedule.subscribe(this.scheduleExam.bind(this));
            _cmpRef.instance.onPrint.subscribe(this.printRDV.bind(this));
            _cmpRef.instance.onPatientEdit.subscribe(ev => this.router.navigate(['/patients/details', ev.patientId]));
        }
    }

    private orderPayment(event) {
        this.dialog.open(PaymentOrderComponent, {
            data: {
                spsId: event.spsId, patientID: event.patientID, patientName: event.patientName
            },
            disableClose: true
        }).afterClosed()
            .subscribe(order => {
                if (order)
                    this.service
                        .orderPayment(order)
                        .subscribe(res => {
                            if (res && res.id) {
                                this.snackBar.open(this.translate.instant('NEW_PAYMENT_DONE'), 'OK', {duration: 2000});
                            }
                        });
            });
    }

    private deleteEvent(ev: CalendarEvent, _eventHost: DomPortalOutlet, info: any) {
        this.dialog
            .open(DeleteConfirmComponent, {disableClose: true})
            .afterClosed()
            .subscribe(ok => {
                if (ok) {
                    _eventHost.detach();
                    info.event.remove();
                    this.service.deleteEvent(ev.spsId)
                        .subscribe(_ => this.snackBar.open(this.translate.instant('RDV_DELETED'), '', {duration: 2000}));
                }
            });
    }

    private scheduleExam(event: CalendarEvent) {
        forkJoin([
            this.patientService.getPatient(+event.patientId),
            this.service.getAppointment(+event.spsId)
        ]).subscribe(data => {
            let [patient, apt] = data;
            this.dialog.open(ExamAdvancedComponent, {
                data: {
                    spsStatus: 'ARRIVED',
                    resource: 'n/a',
                    patient: patient,
                    isr: apt,
                    selectedDateRange: {start: moment(), end: moment().add(15, 'h')},
                    editable: true,
                    queryParam: null,
                    panelClass: 'exam-dialog',
                    is_external: true
                },
                disableClose: true
            }).afterClosed().subscribe(res => {
                if (res) {
                    if (res.hasOwnProperty('isrId')) return;
                    this.service
                        .updateAppointmentStatus(apt.id, AptStatus.entered)
                        .subscribe();
                }
            });
        });
    }

    private markPatientAuthorized(event: CalendarEvent) {
        this.service.getISRByRpID(event.rpId).subscribe(isr => {
            this.dialog
                .open(PatientArrivedComponent, {
                    data: {isr: isr, canViewConfData: this.canViewConfData},
                    width: '600px',
                    disableClose: true
                }).afterClosed()
                .subscribe(isr => {
                    if (isr) {
                        this.service.markPatientAsArrived(isr)
                            .subscribe(next => {
                                this.snackBar.open(this.translate.instant('PATIENT_AUTHORIZED'), 'Ok', {duration: 2000});
                            });
                    }
                });
        });
    }

    private editEvent(calEvent: CalendarEvent) {
        if (calEvent.spsId) this.service.getAppointment(calEvent.spsId).subscribe(apt =>
            this.dialog.open(AppointmentEditComponent, {
                data: apt,
                disableClose: true
            }).afterClosed().subscribe(res => {
                if (res) {
                    this.snackBar.open(this.translate.instant('RDV_UPDATED'), '', {duration: 2000});
                    this.calendarApi.refetchEvents();
                }
            })
        );
    }

    ngAfterViewInit(): void {
        this.calendarApi = this.calendar.getApi();
        this.calendarApi.refetchEvents();

        this.createResourceFilterMenu();
    }

    private createResourceFilterMenu() {
        let fc = document.getElementsByClassName('fc-resourceSelect-button')[0];

        $(fc).empty();

        const _mcp = new ComponentPortal<FtMenuComponent>(FtMenuComponent);
        const _menuHost = new DomPortalOutlet(fc, this._resolver, this._appRef, this._injector);
        const _cmpRef = _menuHost.attachComponentPortal(_mcp);

        _cmpRef.instance.listItem = RESOURCE_TYPES;
        _cmpRef.instance.onMenuItemSelect.subscribe(res => {
            this.res = res;
            this.calendar.getApi().refetchResources();
            this.calendar.getApi().refetchEvents();
            this.calendar.getApi().render();
        });
    }

    private updateCalendarSetting(calendarSetting: CalendarSetting) {
        if (calendarSetting) {
            let closingDays = calendarSetting.closingDays?.split(';').map(cd => Number(cd.charAt(1)));
            this.businessHours.forEach(bh => bh.daysOfWeek.push(...[1, 2, 3, 4, 5, 6].filter(d => !closingDays.includes(d))));

            assign(this.calendarOptions, {
                businessHours: this.businessHours,
                slotMinTime: calendarSetting.openingTime,
                slotMaxTime: calendarSetting.closingTime,
                slotDuration: calculateSlotDuration(calendarSetting.minTimeSlot)
            });

            this.calendarApi.changeView(targetView(calendarSetting.defaultView));
        }
    }
}
