import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SettingComponent} from './setting.component';
import {SettingService} from './setting.service';
import {SettingRoutingModule} from './setting-routing.module';
import {AetEditComponent} from './aet-setting/aet-edit/aet-edit.component';
import {FireSharedModule, SharedService} from '../shared';
import {PriorityEditComponent} from './order-priority-setting/priority-edit/priority-edit.component';
import {UserEditComponent} from './users-setting/user-edit/user-edit.component';
import {ProfileEditComponent} from './profile-setting/profile-edit/profile-edit.component';
import {UnavailabilityEditComponent} from './unavailability/unavailability-edit/unavailability-edit.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {ProcedureCodeEditComponent} from './procedure-code-setting/procedure-code-edit/procedure-code-edit.component';
import {ReasonForExamEditComponent} from './reason-for-exam/reason-for-exam-edit/reason-for-exam-edit.component';
import {RoomEditComponent} from './room-setting/room-edit/room-edit.component';
import {PathologyEditComponent} from './pathology-setting/pathology-edit/pathology-edit.component';
import {HolidayEditComponent} from './holidays-setting/holiday-edit/holiday-edit.component';
import {AetSettingComponent} from './aet-setting/aet-setting.component';
import {ProcedureCodeSettingComponent} from './procedure-code-setting/procedure-code-setting.component';
import {UsersSettingComponent} from './users-setting/users-setting.component';
import {ProfileSettingComponent} from './profile-setting/profile-setting.component';
import {ReportTemplateSettingComponent} from './report-template-setting/report-template-setting.component';
import {ModalitySettingComponent} from './modality-setting/modality-setting.component';
import {ImagingCenterSettingComponent} from './imaging-center-setting/imaging-center-setting.component';
import {ScheduleSettingComponent} from './schedule-setting/schedule-setting.component';
import {OrderPrioritySettingComponent} from './order-priority-setting/order-priority-setting.component';
import {StaticResourceEditComponent} from './static-resource-edit/static-resource-edit.component';
import {UnavailabilityComponent} from './unavailability/unavailability.component';
import {DictionaryComponent} from './dictionary/dictionary.component';
import {ReasonForExamComponent} from './reason-for-exam/reason-for-exam.component';
import {SpsStatusComponent} from './sps-status/sps-status.component';
import {ReportingConfigComponent} from './reporting-config/reporting-config.component';
import {RoomSettingComponent} from './room-setting/room-setting.component';
import {PathologySettingComponent} from './pathology-setting/pathology-setting.component';
import {HolidaysSettingComponent} from './holidays-setting/holidays-setting.component';
import {ReferringPhysiciansComponent} from './referring-physicians/referring-physicians.component';
import {BillingCodesComponent} from './billing-codes/billing-codes.component';
import {BillingCodeEditComponent} from './billing-codes/billing-code-edit/billing-code-edit.component';
import {StaffContractComponent} from './staff-contract/staff-contract.component';
import {EditStaffContractComponent} from './staff-contract/edit-staff-contract/edit-staff-contract.component';
import {ViewersComponent} from './viewers/viewers.component';
import {ACE_CONFIG, AceConfigInterface, AceModule} from 'ngx-ace-wrapper';
import {PrintingTemplatesComponent} from './printing-templates/printing-templates.component';
import {BoxEditComponent} from './box-edit/box-edit.component';
import {PrintingModelsComponent} from './printing-models/printing-models.component';
import {SmsTemplatesComponent} from './sms-templates/sms-templates.component';
import {ClipboardModule} from "@angular/cdk/clipboard";
import { DefaultValuesComponent } from './default-values/default-values.component';

const DEFAULT_ACE_CONFIG: AceConfigInterface = {};

@NgModule({
    imports: [
        CommonModule,
        ColorPickerModule,
        SettingRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatTooltipModule,
        MatListModule,
        MatToolbarModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatSelectModule,
        MatOptionModule,
        MatSidenavModule,
        FireSharedModule,
        MatExpansionModule,
        AceModule,
        ClipboardModule
    ],
    declarations: [
        SettingComponent,
        AetSettingComponent,
        ProcedureCodeSettingComponent,
        UsersSettingComponent,
        ProfileSettingComponent,
        ReportTemplateSettingComponent,
        ScheduleSettingComponent,
        ImagingCenterSettingComponent,
        OrderPrioritySettingComponent,
        ModalitySettingComponent,
        AetEditComponent,
        StaticResourceEditComponent,
        PriorityEditComponent,
        UserEditComponent,
        ProfileEditComponent,
        UnavailabilityComponent,
        UnavailabilityEditComponent,
        ProcedureCodeEditComponent,
        DictionaryComponent,
        ReasonForExamComponent,
        ReasonForExamEditComponent,
        SpsStatusComponent,
        ReportingConfigComponent,
        RoomSettingComponent,
        RoomEditComponent,
        PathologySettingComponent,
        HolidaysSettingComponent,
        HolidayEditComponent,
        PathologyEditComponent,
        ReferringPhysiciansComponent,
        BillingCodesComponent,
        BillingCodeEditComponent,
        StaffContractComponent,
        EditStaffContractComponent,
        ViewersComponent,
        PrintingTemplatesComponent,
        BoxEditComponent,
        PrintingModelsComponent,
        SmsTemplatesComponent,
        DefaultValuesComponent
    ],
    providers: [
        SettingService,
        SharedService,
        {
            provide: ACE_CONFIG,
            useValue: DEFAULT_ACE_CONFIG
        }]
})
export class SettingModule {
}
