import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {SchedulerService} from '../../scheduler/scheduler.service';
import {Observable} from 'rxjs';
import {CalendarEvent} from '../../model';
import {EventDetailComponent} from '../../scheduler/event-detail/event-detail.component';

@Component({
    selector: 'app-patient-scheduled-exams',
    templateUrl: './patient-scheduled-exams.component.html',
    styleUrls: ['./patient-scheduled-exams.component.scss']
})
export class PatientScheduledExamsComponent implements OnInit {

    patientExams: Observable<CalendarEvent[]>;

    constructor(@Inject(MAT_DIALOG_DATA) public data: string,
                private dialog: MatDialog,
                private service: SchedulerService) {
    }

    ngOnInit() {
        this.patientExams = this.service.findPatientFutureAppointments(this.data);
    }

    openExamDetails(exam) {
        this.dialog.open(EventDetailComponent, {
            data: {
                isrId: exam.isrId,
                spsId: exam.spsId,
            },
            width: '800px'
        });
    }

}
