import {Component, Inject, OnInit} from '@angular/core';
import {ProcedureCodeSearchComponent} from '../procedure-code-search/procedure-code-search.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {initRequiredEntity, scheduledProcedureStepEntity} from '../../model';
import {assign, find as _find, get} from 'lodash';

import * as moment from 'moment';
import {SchedulerService} from '../scheduler.service';
import {forkJoin} from 'rxjs';
import {buildProcedureAttributes, SharedService} from '../../shared';

@Component({
    selector: 'app-event-edit',
    templateUrl: './event-edit.component.html',
    styleUrls: ['./event-edit.component.scss']
})
export class EventEditComponent implements OnInit {

    spsForm: FormGroup;

    aets: any[];
    staffList: any[];
    perfPhys: any;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private service: SchedulerService,
                private fb: FormBuilder,
                private sharedService: SharedService,
                private dialog: MatDialog,
                private dialogRef: MatDialogRef<EventEditComponent>) {

        this.spsForm = this.fb.group(assign(scheduledProcedureStepEntity, {
            modality: this.fb.group(initRequiredEntity),
            scheduledPerformingPhysiciansName: this.fb.group({
                id: ''
            }),
            scheduledProcedureStepStartDate: [null, Validators.required],
            scheduledProcedureStepStartTime: ['12:00', Validators.required],
            procedureCode: this.fb.group({
                id: '',
                duration: '30',
                code: '',
                reasonForExam: ''
            }),
            scheduledStationAETitle: this.fb.group({
                id: ['', Validators.required],
                title: ''
            }),
            patientName: '',
            workflowItem: null,
            defaultTemplateModel: ''
        }));

    }

    onSelectProcedure() {
        this.dialog
            .open(ProcedureCodeSearchComponent)
            .afterClosed()
            .subscribe(procedureCode => {
                if (procedureCode) {
                    this.sharedService.getModalityAETitles(procedureCode.modality.id).subscribe(aets => {
                        let sps = buildProcedureAttributes(aets, procedureCode);
                        this.spsForm.patchValue(sps);
                    });
                }
            });
    }

    saveSPS() {

        let sps = this.spsForm.getRawValue();

        sps = assign(sps, {
            modality: sps.modality.id !== '' ? sps.modality : null,
            scheduledPerformingPhysiciansName: this.perfPhys,
            urgent: this.data.urgent,
            requestedProcedure: {id: this.data.rpId},
            scheduledProcedureStepStartDate: moment(sps.scheduledProcedureStepStartDate).add(moment().utcOffset(), 'm').format(),
            scheduledProcedureStepEndTime: moment(sps.scheduledProcedureStepStartTime, 'HH:mm').add(sps.procedureCode.duration, 'minutes').format('HH:mm')
        });

        this.service.scheduleSPS(sps).subscribe(res => {
            this.dialogRef.close(res);
        });
    }


    ngOnInit() {
        forkJoin(
            [this.sharedService.getPerformingPhysicians(),
                this.sharedService.getAetList(),
                this.service.findSPSById(this.data.spsId)]
        ).subscribe(data => {
            [this.staffList, this.aets] = data;

            this.spsForm.get('scheduledPerformingPhysiciansName').valueChanges.subscribe(res => {
                this.perfPhys = _find(this.staffList, {id: res.id});
            });

            this.spsForm.patchValue(assign(data[2], {
                modality: data[2].modality || get(data[2], 'scheduledStationAETitle.modality') || {id: ''},
                scheduledProcedureStepStartDate: new Date(moment(data[2].scheduledProcedureStepStartDate).format()),
                scheduledProcedureStepDescription: data[2].procedureCode['code'],
                scheduledPerformingPhysiciansName: data[2].scheduledPerformingPhysiciansName || {id: ''}
            }));
        });


    }
}
