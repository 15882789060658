export class ThermalPrintModel {
 constructor(
     public patientID: string,
     public patientName: string,
     public date: string,
     public physician: string,
     public accessionNumbers: string,
     public procedureCodes: string
 ) {}
}
