<div fxLayout="column" class="card-view">
    <div class="search-area" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px"
         style="padding-right: 4px; padding-bottom: 0!important;">
        <mat-form-field floatLabel="never">
                <span matPrefix><mat-icon class="prefix-icon" fontIcon="mdi-magnify" fontSet="mdi" style="top: -3px;"
                                          tabindex="-1"></mat-icon>&nbsp;
            </span>
            <input matInput #filter placeholder="{{'SEARCH' | translate}}" [autofocus]="true">
        </mat-form-field>

        <span fxFlex></span>
        <button *ngIf="profile && (profile.exportPatient !== 'NONE' || profile.patientModule)" mat-button
                (click)="exportCSV()"
                [matTooltip]="'EXPORTCSV' | translate" id="save">
            <mat-icon class="export-csv-icon" fontIcon="mdi-cloud-download" fontSet="mdi"></mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon fontIcon="mdi-dots-vertical" fontSet="mdi"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" [overlapTrigger]="true">
            <button (click)="$event.stopPropagation();toggleColumn(item)" *ngFor="let item of availableColumns"
                    mat-menu-item>
                <mat-icon fontIcon="{{item.hidden ? 'mdi-checkbox-blank-circle-outline': 'mdi-check-circle'}}"
                          fontSet="mdi"
                          style="color: #1565c0;"></mat-icon>
                {{item.header | translate}}
            </button>
        </mat-menu>

    </div>

    <div fxFlex="calc(100vh - 200px)" style="overflow: auto;">
        <mat-table [dataSource]="dataSource" class="fire-table" [@state]="dataSource.data.length"
                   [trackBy]="trackById"
                   matSort
                   matSortActive="createdDate"
                   matSortDirection="desc"
                   matSortDisableClear>
            <!--Table-->
            <ng-container *ngFor="let col of displayedColumns" [class.hidden]="col.hidden" matColumnDef="{{col.label}}">

                <mat-header-cell *matHeaderCellDef class="fire-header-cell" mat-sort-header="{{col.value}}"
                                 [disabled]="!col.sortable" [matTooltip]="col.unit"> {{col.header | translate}}
                </mat-header-cell>

                <ng-container *ngIf="col.unit === 'date'">
                    <mat-cell *matCellDef="let row" class="fire-cell"> {{formatDate(row[col.label])}}
                    </mat-cell>
                </ng-container>
                <ng-container *ngIf="col.unit === 'conf_data'">
                    <mat-cell *matCellDef="let row" class="fire-cell"> {{showConfData(row, col.label)}}
                    </mat-cell>
                </ng-container>

                <ng-container *ngIf="col.unit === 'conf'">
                    <mat-cell *matCellDef="let row" class="fire-cell">

                        <div class="ft-chip"
                             [ngStyle]="{backgroundColor: getConfidentialityColor(row[col.label])}"
                             [matTooltip]="row['demographic_confidentiality_description']"
                             [matTooltipPosition]="'right'">{{row[col.label] | translate}}
                        </div>

                    </mat-cell>
                </ng-container>

                <ng-container *ngIf="col.unit !== 'date' && col.unit !== 'conf_data' && col.unit !== 'conf'">
                    <mat-cell *matCellDef="let row" class="fire-cell"> {{row[col.label]}}</mat-cell>
                </ng-container>

            </ng-container>

            <!--actions-->
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="fire-header-cell"></mat-header-cell>
                <mat-cell *matCellDef="let row" class="fire-cell" fxLayout="row nowrap" fxLayoutAlign="end center">
                    <button class="edit-patient-icon" *ngIf="can(row, 'updatePatient')"
                            mat-icon-button [matTooltip]="'DETAILS' | translate"
                            (click)="$event.stopPropagation(); editPatient(row)">
                        <mat-icon fontIcon="mdi-pencil-box" fontSet="mdi"></mat-icon>
                    </button>
                    <button class="delete-patient-icon" *ngIf="can(row, 'deletePatient')" mat-icon-button
                            (click)="$event.stopPropagation(); deletePatient(row)">
                        <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <!--header-->
            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true" class="fire-header-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay;" class="fire-row"></mat-row>
        </mat-table>

        <div class="example-no-results" [style.display]="resultsLength === 0 ? '' : 'none'">
            <div *ngIf="isLoadingResults; else noItem">
                <mat-spinner [diameter]="50" [strokeWidth]="3" color="accent"></mat-spinner>
            </div>
            <ng-template #noItem>
                {{'NOFOUND' | translate}}
            </ng-template>
        </div>
    </div>

    <mat-paginator
            [length]="resultsLength"
            [pageIndex]="0"
            [pageSize]="20"
            [pageSizeOptions]="[5, 10, 15, 20, 50]" showFirstLastButtons>
    </mat-paginator>
</div>
<ng-container *ngIf="downloading">
    <div fxLayout="row" fxLayoutAlign="center center"
         style="position:fixed;top: 0; left: 0;height: 100vh;width: 100vw; background: rgba(0, 0, 0, 0.4); z-index: 9999">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px"
             style="height: 86px;width: 360px;border-radius: 8px;background: rgb(255 255 255);color: #585858;">
            <mat-spinner diameter="30" strokeWidth="2"></mat-spinner>
            <h3>{{'EXPORTING' | translate}}</h3>
        </div>
    </div>
</ng-container>