import * as moment from "moment";

export class Appointment {
    constructor(
        public id?: number,
        public comments?: string,
        public deleted: boolean = false,
        public scheduled: boolean = false,
        public waiting: boolean = true,
        public recurrent: boolean = false,
        public startTime: any = moment().utc(true).format('HH:mm'),
        public endTime?: any,
        public startDate: any = moment(),
        public endDate?: any,
        public duration: number = 10,
        public patientArrival?: any,
        public creationDate?: any,
        public patientDeparture?: any,
        public appointmentReason?: string,
        public appointmentReasonId?: number,
        public appointmentStatus: string = 'waiting_list',
        public appointmentType?: string,
        public appointmentSource: string = 'FireRIS',
        public physician?: any,
        public aet?: any,
        public room?: any,
        public referringPhysician?: any,
        public patient?: any) {
    }

    static [Symbol.hasInstance](obj) {
        return obj.appointmentSource && obj.appointmentStatus;
    }
}

export class AppointmentReason {

    constructor(
        public id: number,
        public value: string,
        public description: string
    ) {
    }
}
