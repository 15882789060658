import {BehaviorSubject, merge as observableMerge, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {SettingService} from '../setting.service';
import {DeleteConfirmComponent, deleteItemFromArray, SharedService} from '../../shared';
import {UserEditComponent} from './user-edit/user-edit.component';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DataSource} from '@angular/cdk/collections';

import {get, map as _map, reduce} from 'lodash';
import {USR_HEADER_COLS, USR_TABLE_CONF} from './table-conf';
import {UnavailabilityComponent} from '../unavailability/unavailability.component';

@Component({
    selector: 'app-users-setting',
    templateUrl: './users-setting.component.html',
    styleUrls: ['./users-setting.component.scss']
})
export class UsersSettingComponent implements OnInit {

    cols: any[];
    displayedColumns = [];

    dataSource: UserDataSource | null;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private settingService: SettingService, private sharedService: SharedService, public dialog: MatDialog) {
        this.sharedService.getUsers().subscribe(data => {

                let items = _map(data, tsk => {
                    return reduce(this.displayedColumns, (obj, field) => {
                        obj[field.label] = get(tsk, field.value, field.defaultValue);
                        obj['contract'] = get(tsk, 'contract');
                        obj['function'] = get(tsk, 'function');
                        obj['profile'] = get(tsk, 'profile');
                        obj['enabled'] = get(tsk, 'enabled');
                        return obj;
                    }, {});
                });

                this.dataSource.dataChange.next(items);
            }
        );
    }

    editUser(user) {

        const dialogRef = this.dialog.open(UserEditComponent);
        const instance = dialogRef.componentInstance;

        instance.selectedUser = user;

        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                if (user.id) this.deleteItem(user);
                this.dataSource.data.push(res);
                this.dataSource.dataChange.next(this.dataSource.data);
            }
        });
    }

    editUserUnavailability(user) {
        this.dialog.open(UnavailabilityComponent, {
            data: user,
            width: '860px'
        });
    }

    deleteItem(user): void {
        deleteItemFromArray(this.dataSource.data, user);
    }


    deleteUser(user) {
        this.dialog.open(DeleteConfirmComponent).afterClosed().subscribe(ok => {
            if (ok) {
                this.settingService.deleteUser(user).subscribe(res => {
                    this.deleteItem(user);
                    this.dataSource.dataChange.next(this.dataSource.data);
                });
            }
        });
    }

    ngOnInit() {

        this.displayedColumns = USR_TABLE_CONF;
        this.cols = USR_HEADER_COLS;

        this.dataSource = new UserDataSource(this.paginator, this.sort);
    }


}


export class UserDataSource extends DataSource<any> {

    filteredData: UserItem[] = [];
    renderedData: UserItem[] = [];

    /** Stream that emits whenever the data has been modified. */
    dataChange: BehaviorSubject<UserItem[]> = new BehaviorSubject<UserItem[]>([]);

    constructor(private _paginator: MatPaginator, private _sort: MatSort) {
        super();
    }

    get data(): UserItem[] {
        return this.dataChange.value;
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<UserItem[]> {
        const displayDataChanges = [
            this.dataChange,
            this._paginator.page,
            this._sort.sortChange,
        ];

        return observableMerge(...displayDataChanges).pipe(map(() => {


            // Filter data
            this.filteredData = this.data.slice().filter((item: UserItem) => {
                return true;
            });

            // Sort filtered data
            const sortedData = this.sortData(this.filteredData.slice());

            // Grab the page's slice of the filtered sorted data.
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
            return this.renderedData;
        }));
    }

    disconnect() {
    }

    /** Returns a sorted copy of the database data. */
    sortData(data: UserItem[]): UserItem[] {
        if (!this._sort.active || this._sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._sort.active) {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'name':
                    [propertyA, propertyB] = [a.name, b.name];
                    break;
            }

            let valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            let valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }


}


export interface UserItem {
    id: string;
    name: string;
    clinicalID: string;
    firstName: string;
    lastName: string;
    username: string;
    profile: string;
    color: string;
    function: string;
}
