import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SettingService} from "../../setting/setting.service";

@Component({
    selector: 'app-fire-calendar',
    template: `
        <ft-calendar [user]="user" [calendarSetting]="calendarSetting"></ft-calendar>`
})
export class FireCalendarComponent {

    user: any;
    calendarSetting: any;

    constructor(private route: ActivatedRoute, private _setting: SettingService) {
        ({user: this.user} = this.route.snapshot.data);
        this._setting.getCalendarSetting(1).subscribe(data => this.calendarSetting = data);
    }

}
