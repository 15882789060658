import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {SettingService} from '../../setting.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Profile} from '../../../model';
import {MODULES} from '../table-conf';

@Component({
    selector: 'app-profile-edit',
    templateUrl: './profile-edit.component.html',
    styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

    profileForm: FormGroup;
    modules: { name: string, module: string, fields: string[] }[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private service: SettingService,
                private fb: FormBuilder,
                private dialogRef: MatDialogRef<ProfileEditComponent>) {
        this.modules = MODULES;
        this.profileForm = this.fb.group(new Profile());
    }

    onSave(profile) {
        profile.id = this.data.id;
        this.service.saveProfile(profile).subscribe(res => this.dialogRef.close(res));
    }

    ngOnInit() {
        this.profileForm.patchValue(this.data);

        this.profileForm.get('admin').valueChanges
            .subscribe(val => this.modules.forEach(m => this.toggleModulePermissions(m.module, val)));
    
    }

    private toggleModulePermissions(module: any, value: boolean) {
        let _module = this.modules.find(m=>m.module === module);
        let fields = _module.fields;
        this.profileForm.get(module).patchValue(value);
        fields.forEach(f=>this.profileForm.get(f).patchValue(value ? 'ALL': 'NONE'))
    }

}
