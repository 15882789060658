import { Component, OnInit } from '@angular/core';
import 'brace';
import 'brace/mode/scss';
import 'brace/mode/html';
import 'brace/theme/dracula';
import {AceConfigInterface} from 'ngx-ace-wrapper';
import {SettingService} from '../setting.service';
import {PrintingTemplateModel} from '../../model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-printing-templates',
  templateUrl: './printing-templates.component.html',
  styleUrls: ['./printing-templates.component.scss']
})
export class PrintingTemplatesComponent implements OnInit {
  public content: string = 'body {background: #454545;}';

  public config: AceConfigInterface = {
    mode: 'html',
    theme: 'dracula',
    readOnly : false
  };

  private successMessage = 'Modèle enregistrée avec succès !';
  private errorMessage = 'Error: Non enregistré';

  cssFiles = [];
  htmlFiles = [];
  public selectedFile: PrintingTemplateModel;

  constructor(private setting: SettingService, private snack: MatSnackBar) { }

  ngOnInit() {
    this.setting.getPrintingTemplateModels().subscribe(models => {
      this.cssFiles = models.filter(model => model.type === 'css');
      this.htmlFiles = models.filter(model => model.type === 'html');
    });
  }

  public onValueChange(value: string): void {
    if (this.selectedFile) this.selectedFile.content = value;
  }

  openFile(file: PrintingTemplateModel) {
    this.selectedFile = file;
    this.content = file.content;
  }

  saveFile(file: PrintingTemplateModel) {
    this.setting.savePrintingTemplateFile(file).subscribe(value => {
      this.snack.open(value ? this.successMessage: this.errorMessage, 'Ok', {duration: 2000});
    });
  }
}
