import {BehaviorSubject, merge, Observable, of as observableOf} from 'rxjs';

import {catchError, debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ReportingService} from '../reporting.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {get, map as _map, reduce} from 'lodash';
import {R_DISPLAY_COLS, R_TABLE_COLS} from './table-config';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ReportFilter, ReportingConfig, ReportingTask} from '../../model';
import {SettingService} from '../../setting/setting.service';
import {
    CommentsComponent,
    DeleteConfirmComponent,
    LocalStorageService,
    MessageComponent,
    NormalReportComponent,
    PacsSearchComponent,
    PerformerAssignComponent,
    PrescriptionComponent
} from '../../shared';
import {ActivatedRoute, Router} from '@angular/router';
import {tableAnimation} from '../../animations';
import {WsService} from '../../ws.service';
import {DateUtils} from '../../utils';
import {AppConfigService} from '../../app-config.service';

export const taskTypeAndListTitle = [
    {taskType: 'I', title: 'INTERPRETATION_DICTATION_LIST'},
    {taskType: 'T', title: 'TRANSCRIPTION_LIST'},
    {taskType: 'V', title: 'VERIFICATION_LIST'},
    {taskType: 'R', title: 'REVIEW_LIST'},
    {taskType: 'S', title: 'SIGNING_LIST'}
];

@Component({
    selector: 'app-reporting-home',
    templateUrl: './reporting-home.component.html',
    styleUrls: ['./reporting-home.component.scss'],
    animations: [tableAnimation]
})
export class ReportingHomeComponent implements AfterViewInit, OnInit {

    displayedColumns = [];
    cols = [];

    userFullName: string;
    profile: any;
    canViewConfData: boolean;

    reportingConfig: ReportingConfig;
    oneList: boolean;

    worklist: string = 'REPORTS_LIST';
    dataSource = new MatTableDataSource();
    resultsLength = 0;
    isLoadingResults = true;
    isDataLoaded = false;

    filterForm: FormGroup;
    menuChange = new BehaviorSubject('');

    @ViewChild('filter', {static: true}) filter: ElementRef;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    private newWindow: any;
    private reportFilterSubject = new BehaviorSubject<ReportFilter>(new ReportFilter());
    private rf = new ReportFilter();
    currentDate = moment().format('LLLL');
    private query = new BehaviorSubject('');
    private username: string;

    sorting: { active: string, direction: 'asc' | 'desc' };
    dateFormat: string = 'DD/MM/YYYY';

    constructor(private reportingService: ReportingService,
                private settingService: SettingService,
                private dialog: MatDialog,
                private fb: FormBuilder,
                private snack: MatSnackBar,
                private router: Router,
                private _config: AppConfigService,
                private route: ActivatedRoute,
                private wsService: WsService,
                private localStorage: LocalStorageService) {

        this.dateFormat = this._config.momentDateFormat;
        this.currentDate = moment().format(this._config.appLang == 'en' ? 'LL' : 'LLLL');
        this.createForm();

        this.sorting = this.localStorage.getItem('rt_sorting') || {
            active: 'expectedCompletionDateTime',
            direction: 'asc'
        };

        let user = get(this.route.snapshot.data, 'user');
        this.profile = user.profile;
        this.userFullName = user.fullName;
        this.username = user.username;
        this.canViewConfData = user.canViewConfidentialData;

        this.displayedColumns = R_DISPLAY_COLS;
        this.cols = R_TABLE_COLS;
        this.settingService.getGeneralSetting().subscribe(gs => {
            if (!gs.billingRequired) this.cols = this.cols.filter(col => col !== 'paymentStatus')
        });

        this.reportingConfig = this.localStorage.getItem('reportingConfig');

        if (!this.reportingConfig) {
            this.getReportingConfig()
                .subscribe(res => {
                    this.reportingConfig = res;
                    this.oneList = res.oneTaskList;
                    this.localStorage.setItem('reportingConfig', this.reportingConfig);
                });
        } else {
            this.oneList = this.reportingConfig.oneTaskList;
        }

        this.menuChange.next(this.oneList ? 'all' : 'I');

        setTimeout(() => this.wsService.observeTopic('reporting').subscribe(res => {
            if (res.topic === 'reporting' && res.response === 'reportUpdate') this.reportFilterSubject.next(this.rf)
        }), 2000);
    }

    addComment(row) {
        this.dialog.open(CommentsComponent, {
            data: {username: this.userFullName, comment: row.noteAlert},
            width: '400px',
            disableClose: true
        })
            .afterClosed().subscribe(comments => {
            if (comments) {
                row.noteAlert = comments;
                this.reportingService.saveNoteAlert(row).subscribe()
            }
        })
    }

    changeRange(e) {
        let start = moment().format('YYYYMMDD');
        let end = start;

        switch (e.value) {
            case 'TODAY':
                this.rf.dateRange = `${start}-${end}`;
                this.setDateRange(start, end);
                break;
            case 'YESTERDAY':
                start = moment().subtract(1, 'd').format('YYYYMMDD');
                this.rf.dateRange = `${start}-${start}`;
                this.setDateRange(start, start);
                break;
            case '3D':
                start = moment().subtract(2, 'd').format('YYYYMMDD');
                this.rf.dateRange = `${start}-${end}`;
                this.setDateRange(start, end);
                break;
            case '1W':
                start = moment().subtract(6, 'd').format('YYYYMMDD');
                this.rf.dateRange = `${start}-${end}`;
                this.setDateRange(start, end);
                break;
            case '2W':
                start = moment().subtract(13, 'd').format('YYYYMMDD');
                this.rf.dateRange = `${start}-${end}`;
                this.setDateRange(start, end);
                break;
            case '1M':
                start = moment().subtract(1, 'month').format('YYYYMMDD');
                this.rf.dateRange = `${start}-${end}`;
                this.setDateRange(start, end);
                break;
            case '3M':
                start = moment().subtract(3, 'month').format('YYYYMMDD');
                this.rf.dateRange = `${start}-${end}`;
                this.setDateRange(start, end);
                break;
            case 'OT':
                start = moment().subtract(10, 'year').format('YYYYMMDD');
                end = moment().add(10, 'd').format('YYYYMMDD');
                this.rf.dateRange = `${start}-${end}`;
                this.setDateRange(null, null);
                break;
            default:
                this.rf.dateRange = `${start}-${end}`;
        }

        this.reportFilterSubject.next(this.rf);
    }

    private setDateRange(start, end) {
        let startDate = moment(start, 'YYYYMMDD');
        let endDate = moment(end, 'YYYYMMDD');
        this.filterForm.patchValue({startDate, endDate});
    }

    getPaymentStatusColor(status: string): string {
        switch (status) {
            case 'PAID':
                return '#0F0';
            case 'NOT_PAID':
                return '#F00';
            case 'PAID_PARTIALLY':
                return '#e6c34c';
            case 'EXEMPT':
                return '#b1aaaa';
            default:
                return '#f00'
        }
    }

    openReport(id: number, newWind?: boolean): void {
        if (newWind) {
            if (this.newWindow && !this.newWindow.closed) {
                this.newWindow.focus();
                this.newWindow.location.pathname = `/reporting/report-edition/${id}`;
            } else {
                this.newWindow = window.open(`/reporting/report-edition/${id}`, '_blank');
                this.newWindow.addEventListener('beforeunload', () => this.newWindow = null);
            }
        } else this.router.navigateByUrl(`/reporting/report-edition/${id}`).then(console.log);
    }

    trackById(index: number, item: any): string {
        return item.id;
    }

    ngOnInit() {
        this.filter.nativeElement.focus();
        if (!this.oneList) this.showList(0);
    }

    showList(idx: number) {
        this.worklist = taskTypeAndListTitle[idx].title;
        this.menuChange.next(taskTypeAndListTitle[idx].taskType);
    }

    showConfData(row, label): string {
        return !row.confidential || this.canViewConfData ? row[label] : '**** ****';
    }

    deleteReportingTask(reportTask) {
        this.dialog.open(DeleteConfirmComponent)
            .afterClosed()
            .subscribe(ok => {
                if (ok) {
                    this.reportingService
                        .deleteReportingTask(reportTask.id)
                        .subscribe(res => {
                            this.reportFilterSubject.next(this.rf);
                            this.snack.open('La ligne selectionnée a été supprimée', 'Ok', {duration: 3000})
                        })
                }
            });
    }

    printReportingTask(row) {
        this.reportingService.printReportingTask(row.id).subscribe(res => {
            let mediaType = 'application/pdf';
            let blob = new Blob([res], {type: mediaType});
            let filename = 'Report_' + moment().format('YYYYMMDDHHmmss') + '.pdf';
            FileSaver.saveAs(blob, filename);
        });
    }

    createPrescription(row) {
        let patient = {patientName: row.patientName, patientID: row.patientID};
        let physician = {physician: this.userFullName};
        this.dialog.open(PrescriptionComponent, {data: {patient, physician}, width: '60%'})
            .afterClosed()
            .subscribe(res => console.log(res));
    }

    getStatus(col: any, row: any): string {
        switch (row['taskType']) {
            case 'Vérification':
                return row['taskStatus'] === 'Terminé' ? 'Validé' : row[col.label];
            case 'Signature':
                return row['taskStatus'] === 'Terminé' ? 'Signé' : row[col.label];
            default:
                return row[col.label];
        }
    }

    getStatusColor(status: string): string {
        switch (status) {
            case 'WAITING':
                return '#fbbc05';
            case 'IN_PROGRESS':
                return '#ea4335';
            case 'TO_TRANSCRIBE':
                return '#2147a8';
            case 'TO_REVIEW':
                return '#a85a16';
            case 'TO_VERIFY':
                return '#e62a5d';
            case 'TO_SIGN':
                return '#f33e2d';
            case 'FINISHED':
                return '#34a853';
            case 'VERIFIED':
                return '#4285f3';
            case 'SIGNED':
                return '#36cd17';
            case 'DELIVERED':
                return '#ca1151';
            default:
                return '#565f73';
        }
    }

    canPrint(row: any): boolean {
        let status = this.getStatus({labal: 'taskStatus'}, row);
        return (status === 'Validé' || status === 'Signé');
    }

    openDetails(row: ReportingTask) {
        if (this.userFullName === row.performerName.fullName) {
            this.dialog.open(PerformerAssignComponent, {data: {task: row, title: 'DELEGATE_TASK'}, minWidth: '420px'})
        } else this.dialog.open(MessageComponent, {data: row, minWidth: '420px'})
    }

    getRowIcon(row: ReportingTask): string {
        if (this.userFullName === row.performerName.fullName) return 'mdi-account-multiple';
        else return 'mdi-comment-text-outline';
    }

    assignPerformer(row: ReportingTask, title: string = 'DELEGATE_TASK') {
        this.dialog.open(PerformerAssignComponent, {data: {task: row, title: title}, minWidth: '380px'})
    }


    private resetPaginator() {
        this.filterForm.valueChanges.subscribe(() => this.paginator.pageIndex = 0)
    }

    private buildQueryParams = () => this.reportFilterSubject.subscribe(value => this.query.next([value.key, value.dateRange, value.pathology].join('@')));

    formatDate(date: any): any {
        return moment(date, 'YYYY-MM-DD').format(this.dateFormat)
    }

    ngAfterViewInit() {
        this.buildQueryParams();
        this.resetPaginator();

        this.sort.sortChange.subscribe(res => {
            this.paginator.pageIndex = 0;
            this.localStorage.setItem('rt_sorting', res);
        });

        const filterObservables = [
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.menuChange.asObservable(),
            this.query.asObservable()
        ];

        merge(...filterObservables)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    let query = this.query.getValue();
                    return this.reportingService.getReportTasks(
                        this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        'all',
                        query);
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isDataLoaded = false;
                    this.resultsLength = data.totalElements;
                    return _map(data.content, item => {
                        return reduce(this.displayedColumns, (obj, field) => {
                            obj[field.label] = get(item, field.value, field.defaultValue);
                            obj['confidential'] = get(item, 'confidential');
                            obj['secondPerformerName'] = get(item, 'secondPerformerName');
                            obj['performerName'] = get(item, 'performerName');
                            obj['noteAlert'] = get(item, 'noteAlert');
                            return obj
                        }, {});
                    });
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isDataLoaded = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.dataSource.data = data);
    }

    private getReportingConfig(): Observable<any> {
        return this.settingService.getReportingConfig(1);
    }

    can(row: any, action: string): boolean {
        return (this.profile[action] !== 'NONE') && !row.confidential || this.canViewConfData;
    }

    canAssign(row: any): boolean {
        return (this.can(row, 'editReport') && (this.userFullName === row.performerName.fullName)) || (this.profile['editReport'] === 'ALL');
    }

    changePeriod() {
        this.filterForm.get('period').patchValue('OT');
    }

    private createForm() {
        this.filterForm = this.fb.group({
            key: '',
            pathology: '',
            startDate: moment(),
            endDate: moment(),
            period: 'TODAY'
        });

        this.filterForm.valueChanges.pipe(debounceTime(300)).subscribe(value => this.buildReportFilter(value));
    }

    generateReport(row: any) {
        this.dialog.open(NormalReportComponent, {data: row.id, minHeight: '100vh'})
    }

    queryPacs() {
        this.dialog.open(PacsSearchComponent, {data: this.username, height: '100%', disableClose: true});
    }

    private buildReportFilter = (value: any) => {

        let startDate = moment(value.startDate).isValid() ? moment(value.startDate) : moment().subtract(10, 'year');
        let endDate = moment(value.endDate).isValid() ? moment(value.endDate) : moment().add(10, 'd');

        let start = startDate.format('YYYYMMDD');
        let end = endDate.format('YYYYMMDD');

        this.currentDate = start === end ? startDate.format(this._config.appLang == 'en' ? 'LL' : 'LLLL') : DateUtils.formatRange(startDate, endDate, this._config.appLang);

        this.rf.key = value.key;
        this.rf.dateRange = `${start}-${end}`;
        this.rf.pathology = value.pathology;

        this.reportFilterSubject.next(this.rf);
    };
}
