import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {
    AppointmentReason,
    FtBox,
    Hl7IdName,
    Insurance,
    Pathology,
    ProcedureCode,
    QueryParam,
    StaffContract,
    Technique
} from '../model';
import {
    PACS_URL,
    PATIENT_URL,
    PHARMACY_URL,
    PRINTER_URL,
    QUEUE_URL,
    RESOURCE_URL,
    SCHEDULER_URL,
    SETTING_URL,
    SHARED_URL,
    STATIC_URL,
    WORKFLOW_URL
} from '../urls';
import {SortDirection} from '@angular/material/sort';
import {Prescription} from './prescription';
import {forkJoin, Observable, of, Subject} from "rxjs";
import {tap} from "rxjs/operators";
import {printBlob} from "../utils";

@Injectable()
export class SharedService {

    pacsSettingUrl = `/api/setting`;

    private templateChangedSource = new Subject<any>();
    templateChanged = this.templateChangedSource.asObservable();

    constructor(private http: HttpClient) {
    }

    changeTemplate = (tm: any) => this.templateChangedSource.next(tm);


    getPrinters(): Observable<any> {
        return this.http.get(PRINTER_URL + '/printers');
    }

    queryPacs(queryParam: QueryParam): Observable<QueryParam[]> {
        if (queryParam.validQuery()) return this.http.post<QueryParam[]>(`${PACS_URL}/query`, queryParam);
        return of([])
    }

    printAttestation(id: number): Observable<any> {
        return this.http.get(`${PRINTER_URL}/printAttestation`, {params:{id}, responseType: 'blob'}).pipe(tap(blob => printBlob(blob)));
    }

    getLicenseInfo(): Observable<any> {
        return this.http.get('/api/documents/infos');
    }

    postFile(fileToUpload: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        return this.http.post<any>(`${STATIC_URL}/file-upload`, formData);
    }

    getPathologies = (): Observable<Pathology[]> => this.http.get<Pathology[]>(STATIC_URL + '/pathologies');

    getReasonForExamsByModality = (modalityId: number): Observable<any> => this.http.get(`${STATIC_URL}rfe-by-modality/${modalityId}`);

    getReasonsForExamByModality(aet: any): Observable<any> {
        if (aet !== undefined) {
            return this.http.get(`${STATIC_URL}rfe-by-modality/${aet.modality.id}`);
        } else {
            return this.http.get(STATIC_URL + '/reasonsForExam');
        }
    }

    getPaginatedPathologies(pageSize: number, pageIndex: number, sort: string, direction: string, value): Observable<any> {
        let params = {'page': String(pageIndex), 'size': String(pageSize), sort, direction, 'search-term': value};
        return this.http.get<any>(`${STATIC_URL}/pathologyPages`, {params: params});
    }

    getPaginatedTechniques(pageSize: number, pageIndex: number, sort: string, direction: string, value): Observable<any> {
        let params = {'page': String(pageIndex), 'size': String(pageSize), sort, direction, value};
        return this.http.get<any>(`${RESOURCE_URL}/techniques`, {params: params});
    }

    createTechnique(technique: any): Observable<Technique> {
        return this.http.post<Technique>(`${RESOURCE_URL}/saveTechnique`, technique);
    }

    deleteTechnique(techniqueId: any): Observable<boolean> {
        return this.http.get<boolean>(`${RESOURCE_URL}/deleteTechnique`, {params: {techniqueId}});
    }

    getProcedureCodesById = (procedureTypeId: number): Observable<ProcedureCode[]> => this.http.get<ProcedureCode[]>(`${RESOURCE_URL}/procedureCodesById/${procedureTypeId}`);

    getRooms = (): Observable<any> => this.http.get(RESOURCE_URL + '/rooms');

    getModalityAETitles = (modalityId: number): Observable<any> => this.http.get(STATIC_URL + `/modalityAETs/${modalityId}`);

    getBodyRegions = (): Observable<any> => this.http.get(STATIC_URL + '/bodyRegions');

    getHospitalServices = (): Observable<any> => this.http.get(STATIC_URL + '/hospitalServices');

    getPopulations = (): Observable<any> => this.http.get(STATIC_URL + '/populations');

    getLateralities = (): Observable<any> => this.http.get(STATIC_URL + '/lateralities');

    getUnavailabilityReasons = (): Observable<any> => this.http.get(STATIC_URL + '/unavailabilityReasons');

    getProcedureCatalogs = (): Observable<any> => this.http.get(RESOURCE_URL + '/procedureCatalogs');

    getPerformingPhysicians = (): Observable<any> => this.http.get(STATIC_URL + '/performingPhysicians');

    getFunctions = (): Observable<any> => this.http.get(STATIC_URL + '/functions');

    getImagingCenters = (): Observable<any> => this.http.get(RESOURCE_URL + '/imagingCenters');

    getUsers = (): Observable<any> => this.http.get('/api/setting/users');

    getProfiles = (): Observable<any> => this.http.get('/api/setting/profiles');

    getModalities = (): Observable<any> => this.http.get(STATIC_URL + '/modalities');

    getBillingCodes = (): Observable<any> => this.http.get(STATIC_URL + '/billingCodes');

    getProcedureCodes = (): Observable<any> => this.http.get(RESOURCE_URL + '/procedureCodes');

    getPriorities = (): Observable<any> => this.http.get(STATIC_URL + '/priorities');

    getConfidentialityCodes = (): Observable<any> => this.http.get(STATIC_URL + '/confidentialityCodes');

    getTransportationModes = (): Observable<any> => this.http.get(STATIC_URL + '/transportationModes');

    getTransportArranged = (): Observable<any> => this.http.get(STATIC_URL + '/transportArranged');

    getStaff = (): Observable<any> => this.http.get(STATIC_URL + '/allStaff');

    getOrderTypes = (): Observable<any> => this.http.get(STATIC_URL + '/orderTypes');

    getGenders = (): Observable<any> => this.http.get(STATIC_URL + '/genders');

    getMaritalStatuses = (): Observable<any> => this.http.get(STATIC_URL + '/maritalStatuses');

    getTitles = (): Observable<any> => this.http.get(STATIC_URL + '/titles');

    getPatientStats = (): Observable<any> => this.http.get(STATIC_URL + '/patientStats');

    getAmbulatoryStatuses = (): Observable<any> => this.http.get(STATIC_URL + '/ambulatoryStatuses');

    getEthnicGroups = (): Observable<any> => this.http.get(STATIC_URL + '/ethnicGroups');

    getDangerCodes = (): Observable<any> => this.http.get(STATIC_URL + '/dangerCodes');

    getOrderStatusByValue = (value: string): Observable<any> => this.http.get(STATIC_URL + `/orderStatus/${value}`);

    getAetList = (): Observable<any> => this.http.get(RESOURCE_URL + '/aets');

    getOrderCancellationReasons = (): Observable<any> => this.http.get(STATIC_URL + '/rejectionReasons');

    getReasonForExams = (): Observable<any> => this.http.get(STATIC_URL + '/reasonsForExam');
    getReasonForExamsValues = (): Observable<any> => this.http.get(STATIC_URL + '/reasonsForExamValues');

    getCountries = (): Observable<any> => this.http.get(STATIC_URL + '/countries');

    getProvinces = (countryId: number): Observable<any> => this.http.get(STATIC_URL + `/provinces/${countryId}`);

    getCities = (provinceId: number): Observable<any> => this.http.get(STATIC_URL + `/cities/${provinceId}`);

    getSPSStatuses = (): Observable<any> => this.http.get(STATIC_URL + '/spsStatuses');

    getTechnicians = (): Observable<any> => this.http.get(STATIC_URL + '/technicians');

    savePathology = (pathology: Pathology): Observable<Pathology> => this.http.post<Pathology>(STATIC_URL + '/create-pathology', pathology);

    deletePathology = (pathology: Pathology): Observable<boolean> => this.http.post<boolean>(`${STATIC_URL}/deletePathology`, pathology);

    checkIdIfExists = (value: any = 'none'): Observable<boolean> => this.http.get<boolean>(`${PATIENT_URL}/checkId/${value}`);

    checkAnIfExists = (value: any = 'none'): Observable<boolean> => this.http.get<boolean>(`${SCHEDULER_URL}/checkAN/${value}`);

    generateAccessionNumber = (): Observable<any> => this.http.get(`${SHARED_URL}/new-order-id`);

    generatePatientId = (): Observable<any> => this.http.get(`${SHARED_URL}/new-patient-id`);

    getViewers = (): Observable<any> => this.http.get<any>(`${this.pacsSettingUrl}/viewers`);

    getRadiologists = (): Observable<any> => this.http.get(`${STATIC_URL}/radiologists`);

    getAllergies = (): Observable<any> => this.http.get(`${STATIC_URL}/allergies`);

    getAppointmentReasons = (): Observable<AppointmentReason[]> => this.http.get<AppointmentReason[]>(`${STATIC_URL}/appointmentReasons`);

    getAppointmentStatuses = (): Observable<any> => this.http.get(`${STATIC_URL}/appointmentStatuses`);

    getAppointmentTypes = (): Observable<any> => this.http.get(`${STATIC_URL}/appointmentTypes`);

    getReferringPhysicians = (): Observable<any> => this.http.get(`${STATIC_URL}/referringPhysicians`);

    getSpecialities = (): Observable<any> => this.http.get(`${STATIC_URL}/specialities`);

    getPatientClasses = (): Observable<any> => this.http.get(`${STATIC_URL}/patientClasses`);

    getConfidentialities = (): Observable<any> => this.http.get(`${STATIC_URL}/confidentialities`);

    getInsurances = (): Observable<any> => this.http.get(`${RESOURCE_URL}/insurances`);

    createNewInsurance = (insurance: any): Observable<Insurance> => this.http.post<Insurance>(`${RESOURCE_URL}/saveInsurance`, insurance);

    checkForPatientFutureAppointment = (patientID: string): Observable<boolean> => this.http.get<boolean>(`${SCHEDULER_URL}/checkForPatientFutureAppointment/${patientID}`);

    getInsuranceOrganizations = (): Observable<string[]> => this.http.get<string[]>(`${STATIC_URL}/insuranceOrganizations`);

    getWorkflowSearchField = (): Observable<any> => forkJoin([this.getPerformingPhysicians(), this.getModalities(), this.getTechnicians(), this.getConfidentialities()]);

    deleteReferringPhysician = (item: any): Observable<any> =>
        this.http.get(`${RESOURCE_URL}/deleteReferringPhysician`, {params: {id: String(item.id)}});

    getPaginatedReferringPhysicians(pageSize: number, pageIndex: number, active: string, direction: SortDirection, value: string): Observable<any> {
        let params = new HttpParams()
            .set('page', String(pageIndex))
            .set('size', String(pageSize))
            .set('sort', active)
            .set('direction', direction)
            .set('key', value);
        return this.http.get(`${RESOURCE_URL}/paginatedReferringPhysicians`, {params: params});
    }

    testConnection(ip: string): Observable<boolean> {
        return this.http.get<boolean>(`${RESOURCE_URL}/ping`, {params: {ip}});
    }

    generatePrescription = (prescription: Prescription): Observable<boolean> => this.http.post<boolean>(`${SHARED_URL}/generatePrescription`, prescription);

    getBanks = (): Observable<any> => this.http.get(`${RESOURCE_URL}/banks`);

    getPaymentMethods = (): Observable<any> => this.http.get(`${RESOURCE_URL}/paymentMethods`);

    createPaymentMethod = (paymentMethod: any): Observable<any> => this.http.post(`${RESOURCE_URL}/createPaymentMethod`, paymentMethod);

    createBank = (bank: any): Observable<any> => this.http.post(`${RESOURCE_URL}/createBank`, bank);

    getOrganisms = (): Observable<any> => this.http.get(`${RESOURCE_URL}/organisms`);

    createOrganism = (organism: any): Observable<any> => this.http.post(`${RESOURCE_URL}/createOrganism`, organism);

    createAllergy = (allergy: any): Observable<any> => this.http.post(`${STATIC_URL}/createAllergy`, allergy);

    getPaginatedProcedureCodes(pageSize: number, pageIndex: number, active: string, direction: SortDirection, value: string): Observable<any> {
        let params = {page: String(pageIndex), size: String(pageSize), sort: active, direction, value};
        return this.http.get(`${RESOURCE_URL}/procedures`, {params});
    }

    printPatientsPerDate(date: string): Observable<any> {
        return this.http.get(`${PRINTER_URL}/printPatientPerDate`,  {responseType: 'blob', params:{date}}).pipe(tap(printBlob));
    }

    getPaginatedPostalCodes(pageSize: number, pageIndex: number, active: string, direction: SortDirection, key: string): Observable<any> {
        let params = {page: String(pageIndex), size: String(pageSize), sort: active, direction, key};
        return this.http.get(`${STATIC_URL}/postalCodes`, {params});
    }

    getPaginatedBillingCodes(pageSize: number, pageIndex: number, active: string, direction: SortDirection, key: string): Observable<any> {
        let params = {page: String(pageIndex), size: String(pageSize), sort: active, direction, key};
        return this.http.get(`${RESOURCE_URL}/paginatedBillingCodes`, {params});
    }

    getStaffContracts(pageSize: number, pageIndex: number, active: string, direction: SortDirection, key: string) {
        let params = {page: String(pageIndex), size: String(pageSize), sort: active, direction, key};
        return this.http.get(`${RESOURCE_URL}/staff-contracts`, {params});
    }

    createStaffContract(value: any): Observable<StaffContract> {
        return this.http.post<StaffContract>(`${RESOURCE_URL}/createStaffContract`, value);
    }

    getStaffContract(id: number): Observable<StaffContract> {
        return this.http.get<StaffContract>(`${RESOURCE_URL}/getStaffContract`, {params: {id: String(id)}});
    }

    getAllStaffContracts(): Observable<StaffContract[]> {
        return this.http.get<StaffContract[]>(`${RESOURCE_URL}/getAllStaffContracts`);
    }

    getOrganismsList(): Observable<Hl7IdName[]> {
        return this.http.get<Hl7IdName[]>(`${RESOURCE_URL}/organismsList`);
    }

    getOrganismConventions(organismId: any): Observable<any> {
        return this.http.get(`${RESOURCE_URL}/organismConventions`, {params: {organismId}});
    }

    getOrganismPECS(patientID: string, organismId: any): Observable<any> {
        return this.http.get(`${RESOURCE_URL}/patientPECS`, {params: {patientID: patientID, organismId: organismId}});
    }

    getConventionException(conventionId: any): Observable<any> {
        return this.http.get(`${RESOURCE_URL}/conventionExceptions`, {params: {conventionId}});
    }

    getOrganismTariffs(organismId: any): Observable<any> {
        let params = {organismId};
        return this.http.get(`${RESOURCE_URL}/organismTariffs`, {params});
    }

    mergeExams(row: QueryParam, an: string): Observable<any> {
        return this.http.post(`${SCHEDULER_URL}/mergeExams/${an}`, row);
    }

    checkIfExamExists(studyUID: string): Observable<boolean> {
        return this.http.get<boolean>(`${SCHEDULER_URL}/checkExistsByUID`, {params: {studyUID}});
    }

    getTariffs(): Observable<any> {
        return this.http.get(`${RESOURCE_URL}/tariffs`)
    }

    getTariffLines(id: any): Observable<any> {
        let params = {id};
        return this.http.get(`${RESOURCE_URL}/tariffLines`, {params});
    }

    getPECLines(pecId: any): Observable<any> {
        return this.http.get(`${RESOURCE_URL}/pecExams`, {params: {pecId}});
    }

    getProcedureCode(code: any): Observable<ProcedureCode> {
        return this.http.get(`${RESOURCE_URL}/findProcedureCode`, {params: {code}});
    }

    getUsersWithPaymentRight(): Observable<any> {
        return this.http.get(`${SETTING_URL}/usersWithPaymentRight`)
    }

    getBoxes(): Observable<FtBox[]> {
        return this.http.get<FtBox[]>(`${SETTING_URL}/boxes`)
    }

    saveBox(box: any): Observable<any> {
        return this.http.post(`${SETTING_URL}/saveBox`, box);
    }

    getOrderStatuses = (): Observable<any> => this.http.get(`${STATIC_URL}/orderStatuses`);

    getStoreList(): Observable<any> {
        return this.http.get(`${PHARMACY_URL}/stores-list`);
    }

    getArticles(storeId: any): Observable<any> {
        return this.http.get(`${PHARMACY_URL}/articles-list`, {params: {storeId}});
    }

    getArticleSerials(articleId: any, storeId: any): Observable<any> {
        return this.http.get(`${PHARMACY_URL}/article-serials`, {params: {articleId, storeId}});
    }

    getArticleLots(articleId: any, storeId: any): Observable<any> {
        return this.http.get(`${PHARMACY_URL}/article-lots`, {params: {articleId, storeId}});
    }

    createTitle(title: any): Observable<any> {
        return this.http.post(`${STATIC_URL}/createTitle`, title);
    }

    getExamTypes(): Observable<any> {
        return this.http.get(`${RESOURCE_URL}/examTypes`);
    }

    getPaginatedTemplateModels(pageSize: number, pageIndex: number, active: string, direction: SortDirection, value: string): Observable<any> {
        let params = {page: String(pageIndex), size: String(pageSize), sort: active, direction, value};
        return this.http.get(`${RESOURCE_URL}/paginatedTemplateModels`, {params});
    }

    reportTemplateModels(pageSize: number, pageIndex: number, active: string, direction: SortDirection, searchKey: string): Observable<any> {
        let params = {page: String(pageIndex), size: String(pageSize), sort: active, direction, searchKey};
        return this.http.get(`${RESOURCE_URL}/report-template-models`, {params});
    }

    getQueueItems(): Observable<any> {
        return this.http.get(`${QUEUE_URL}`);
    }

    getPerformingPhysician(filter: string): Observable<any> {
        return this.http.get(`${STATIC_URL}/performingPhysician`, {params: {id: filter}})
    }

    getProcedureCodeById(id: any): Observable<any> {
        return this.http.get(`${RESOURCE_URL}/procedureCodeById`, {params: {id}});
    }

    findProcedureCode(studyDescription: string, modalitiesInStudy: any): Observable<ProcedureCode> {
        return this.http.get<ProcedureCode>(`${RESOURCE_URL}/findProcedureCodeByDescription`, {
            params: {
                studyDescription,
                modalitiesInStudy
            }
        });
    }

    loadLogs(pageSize: number, pageIndex: number, active: string, direction: SortDirection, key, user?: any, startDate?: any, endDate?: any): Observable<any> {
        let params = {
            page: String(pageIndex),
            size: String(pageSize),
            sort: `${active},${direction}`,
            key,
            user,
            startDate,
            endDate
        };
        return this.http.get(`${SHARED_URL}/logging`, {params});
    }

    exportPhysicians(): Observable<any> {
        return this.http.get(`${RESOURCE_URL}/exportPhysicians`, {responseType: "blob"});
    }

    exportProcedures(): Observable<any> {
        return this.http.get(`${RESOURCE_URL}/exportProcedures`, {responseType: "blob"});
    }

    sendSms(id: number, destination: any, message: any, source: string = 'REPORTING_TASK'): Observable<any> {
        return this.http.get(`${WORKFLOW_URL}/sendSMS`, {params: {id, destination, message, source}});
    }

    getSmsTemplates(): Observable<any> {
        return this.http.get(`${RESOURCE_URL}/smsTemplates`);
    }

    saveSmsTemplate(template: any): Observable<any> {
        return this.http.post(`${RESOURCE_URL}/createSmsTemplate`, template);
    }

    deleteSmsTemplate(id: number): Observable<any> {
        return this.http.delete(`${RESOURCE_URL}/deleteSmsTemplate`, {params: {id}});
    }

    getProcedureCodesValues(): Observable<any> {
        return this.http.get(`${RESOURCE_URL}/findProcedureCodesValues`);
    }

    isReferringPhysicianUsed(id: any): Observable<any> {
        return this.http.get(`${PATIENT_URL}/physician-used`, {params: {id}});
    }
}
