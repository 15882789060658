import {Component, OnInit} from '@angular/core';
import {DefaultValues} from "../../model";
import {SettingService} from "../setting.service";
import {forkJoin} from "rxjs";
import {SharedService} from "../../shared";


@Component({
    selector: 'ft-default-values',
    templateUrl: './default-values.component.html',
    styleUrls: ['./default-values.component.scss']
})
export class DefaultValuesComponent implements OnInit {

    defaultValues: DefaultValues;

    titles: any[] = [];
    maritalStatuses: any[] = [];
    genders: any[] = [];
    paymentMethods: any[] = [];
    priorities: any[] = [];
    physicians: any[] = [];
    technicians: any[] = [];
    patientClasses: any[] = [];
    confidentialities: any[] = [];

    constructor(private _setting: SettingService, private _shared: SharedService) {
    }

    ngOnInit(): void {
        forkJoin([
            this._shared.getTitles(),
            this._shared.getMaritalStatuses(),
            this._shared.getGenders(),
            this._shared.getPaymentMethods(),
            this._shared.getPriorities(),
            this._shared.getPerformingPhysicians(),
            this._shared.getTechnicians(),
            this._shared.getPatientClasses(),
            this._shared.getConfidentialities(),
        ]).subscribe(data => {
            [
                this.titles,
                this.maritalStatuses,
                this.genders,
                this.paymentMethods,
                this.priorities,
                this.physicians,
                this.technicians,
                this.patientClasses,
                this.confidentialities,
            ] = data;

            this._setting.getDefaultValues().subscribe(value => this.defaultValues = value);
        });
    }

    saveDefaultValues(defaultValues: DefaultValues) {
        this._setting
            .saveDefaultValues(defaultValues)
            .subscribe()
    }

    clearInput(key: string) {
        this.defaultValues[key] = undefined;
        this.saveDefaultValues(this.defaultValues);
    }
}
