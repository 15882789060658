import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DeleteConfirmComponent, deleteItemFromArray, SharedService} from "../../shared";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'ft-sms-templates',
    templateUrl: './sms-templates.component.html',
    styleUrls: ['./sms-templates.component.scss']
})
export class SmsTemplatesComponent implements OnInit {
    editing: any;

    public smsTemplates = [
        {
            id: '',
            title: 'Rapport disponible',
            body: 'Bonjour PATIENT_NAME, votre compte rendu EXAM est disponible maintenant.',
            isDefault: true
        },
        {
            id: '',
            title: 'RDV planifié',
            body: 'Bonjour PATIENT_NAME, votre RDV pour EXAM a été planifié pour DATE.',
            isDefault: false
        },
    ];
    newModelForm: FormGroup;
    isDefault: boolean;

    constructor(private _fb: FormBuilder, private _service: SharedService, private _dialog: MatDialog) {
        this.createForm()
    }

    ngOnInit(): void {
        this._service.getSmsTemplates().subscribe(data => this.smsTemplates = data);
    }

    saveSmsModel(value: any) {
        value.isDefault = this.isDefault;

        this._service.saveSmsTemplate(value).subscribe(res => {
            if (this.smsTemplates.find(it => it.id === res.id)) deleteItemFromArray(this.smsTemplates, value);

            this.smsTemplates.push(res)

            this.editing = false;
            this.createForm();
        });
    }

    editModel(sms: any) {
        this.editing = true;
        this.isDefault = sms.isDefault;
        this.newModelForm.patchValue(sms);
    }

    deleteModel(sms: any) {
        this._dialog.open(DeleteConfirmComponent).afterClosed().subscribe(ok => {
            if (ok) {
                this._service.deleteSmsTemplate(sms.id).subscribe(res => {
                    if (res) deleteItemFromArray(this.smsTemplates, sms);
                });
            }
        });
    }

    private createForm() {
        this.newModelForm = this._fb.group({
            id: null,
            title: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
            body: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
            isDefault: false
        });
    }

    copyVariable(ev: any) {
        let variable = ev.target['outerText'];
        let el = document.getElementById('sms-body') as HTMLTextAreaElement;
        el.focus();
        el.setRangeText(variable, el.selectionStart, el.selectionEnd, 'end');
    }
}
