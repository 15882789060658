export function loadJSScript(scriptSrc: string, tagId: string) {
    console.log(`Loading ${tagId}:${scriptSrc} ...`)
    let node = document.createElement('script');
    node.src = scriptSrc;
    node.type = 'text/javascript';
    node.id = tagId;
    document.getElementsByTagName('body')[0].appendChild(node);
}


export function loadRLEScripts() {
    if (!document.getElementById('rle-int')) loadJSScript('/LPW/js/LPW.REInt.js', 'rle-int');
    if (!document.getElementById('rle-api'))
        loadJSScript('/LPW/api/a/AppSettings/getAppGlobalSettings?responseType=jsonp&func=LPW.REInt.setSettings', 'rle-api');
}

export function getPrintFrame(): HTMLIFrameElement {
    let frame = document.getElementById("PRINT_FRAME_ID");

    if (frame) frame.parentElement.removeChild(frame);

    frame = document.createElement('iframe');
    frame.style.position = 'absolute';
    frame.style.top = '-9999px';
    frame.setAttribute('id', "PRINT_FRAME_ID");

    document.body.appendChild(frame);

    return frame as HTMLIFrameElement;
}

export function printBlob(blob) {
    const frame = getPrintFrame();
    const objectUrl = URL.createObjectURL(blob);

    frame.setAttribute('src', objectUrl);

    const load = () => {
        frame.contentWindow.print();
        window.addEventListener('focus', afterPrint);
    };

    const afterPrint = () => {
        if (frame.parentElement) frame.parentElement.removeChild(frame);
        window.removeEventListener('focus', afterPrint);
    };

    frame.addEventListener('load', load);
}