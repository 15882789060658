import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {map} from 'rxjs/operators';
import * as $ from 'jquery';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SettingService} from '../setting/setting.service';
import {WsService} from '../ws.service';
import {Viewer} from '../model';

const insertAtIndex = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index)
]

@Component({
    selector: 'app-external-viewer',
    templateUrl: './external-viewer.component.html',
    styleUrls: ['./external-viewer.component.scss']
})
export class ExternalViewerComponent implements OnInit {
    private $frame: any;
    private viewer: Viewer;

    constructor(private route: ActivatedRoute, private snack: MatSnackBar,
                private setting: SettingService, private ws: WsService) {
        this.ws.observeTopic('viewer').subscribe(res => {
            let request = JSON.parse(res.response) as { studyInstanceUID: string, replace: boolean, close: boolean };

            if (!request.studyInstanceUID) return;

            let src = this.$frame.attr('src');

            if (src) {

                let urlPath = src.split('?')[0];

                if (request.replace && !request.close) {
                    this.$frame.attr('src', urlPath + "?study=" + request.studyInstanceUID);
                    return;
                }

                let openStudies = new Set(src.substr(src.indexOf("=") + 1).split(","));

                if (openStudies.has(request.studyInstanceUID)) openStudies.delete(request.studyInstanceUID)

                let setToList = []
                openStudies.forEach((value, value2) => setToList.push(value))
                setToList = insertAtIndex(setToList, 0, request.studyInstanceUID);


                let newSet = new Set(setToList);

                if (request.close && newSet.has(request.studyInstanceUID)) {
                    newSet.delete(request.studyInstanceUID)
                }

                let studies = []
                newSet.forEach((value, value2) => {
                    studies.push(value)
                });

                if (studies.length > 5) {
                    this.snack.open('Vous avez dépassé la limite des études configurée à ouvrir', 'Fermer', {duration: 5000});
                    return;
                }

                let url = urlPath + "?study=" + studies.join(",")

                console.log(url);

                this.$frame.attr('src', url);
            }

        })
    }

    ngOnInit() {
        this.route
            .queryParams.pipe(map((params: Params) => params['param']))
            .subscribe(param => this.showViewer(param));
    }

    showViewer(params: any) {
        console.log(params);
        if (!params) {
            this.snack.open('Pas d\'examen disponible pour visualiser!', 'Ok', {duration: 3000});
            return;
        }

        let ssc = params.split('_');

        this.setting.getViewer(ssc[0]).subscribe(dicomWebViewer => {
            this.viewer = dicomWebViewer;
            let queryKey = (dicomWebViewer.name === 'MEDDREAM') || dicomWebViewer.name.toLowerCase().includes('pro') ? 'study' : 'studyInstanceUID';

            let url = `https://${dicomWebViewer.host}${dicomWebViewer.remotePath}?${queryKey}=${ssc[1]}`;

            if (this.$frame) {
                console.log(this.$frame.src);
                this.$frame.remove();
            } else {
                this.$frame = $('<iframe />').attr('src', url).css({
                    position: 'absolute',
                    top: '0px',
                    height: '100vh',
                    width: '100vw',
                    border: 'none',
                    margin: '0',
                    padding: '0',
                });
                $('#viewer').append(this.$frame);
            }
        });

    }

}
