<div fxLayout="row" fxLayoutAlign="space-between center" style="background: #303344; padding: 8px; color: #FFFFFF; font-weight: bold;">
  <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon> &nbsp;&nbsp;
  <span>{{'PRINTING_TEMPLATES' | translate}}</span>
  <span fxFlex></span>
  <button mat-raised-button color="primary" [disabled]="!selectedFile" (click)="saveFile(selectedFile)">{{'SAVE' | translate}}</button>
</div>
<mat-divider style="background: rgb(51, 53, 64); border-top-width: 3px"></mat-divider>
<div style="height: 100%" fxLayout="row" fxLayoutAlign="start">

  <div fxFlex="200px" style="background: rgb(51, 53, 64); overflow: auto;">
    <mat-nav-list>
      <h3 mat-subheader>{{'CSS_FILES' | translate}}</h3>
      <mat-list-item *ngFor="let file of cssFiles" (click)="openFile(file)" [ngStyle]="{color: selectedFile && (file.name === selectedFile.name) && (file.type === selectedFile.type) ? '#ff0081': ''}">
        <mat-icon mat-list-icon fontSet="mdi" fontIcon="mdi-language-css3"></mat-icon>
        <h4 mat-line>{{file.name}}</h4>
      </mat-list-item>
      <mat-divider></mat-divider>
      <h3 mat-subheader>{{'HTML_FILES' | translate}}</h3>
      <mat-list-item *ngFor="let file of htmlFiles" (click)="openFile(file)">
        <mat-icon mat-list-icon fontSet="mdi" fontIcon="mdi-language-html5"></mat-icon>
        <h4 mat-line>{{file.name}}</h4>
      </mat-list-item>
    </mat-nav-list>
  </div>


  <ace [config]="config"
       [(value)]="content"
       (valueChange)="onValueChange($event)">
  </ace>
</div>
