import {assign, find, get} from 'lodash';
import * as moment from 'moment';

export function buildISR(patient: any, examData: any, rfes: any[], aets: any[], spsStatus: string, ids?: { isrId: number, rpId: number, spsId: number }, isr?: any, physicians?: any[]): any[] {

    let isrs: any[] = [];

    let exams = get(examData, 'exams');
    let title = get(patient, 'title');
    let patientClass = get(patient, 'patientClass');
    let gender = get(patient, 'demographic.gender');
    let dob = get(patient, 'demographic.dateOfBirth');
    let confidentiality = get(patient, 'demographic.confidentiality');

    let orderTimeLine = ids ? get(isr, 'imagingOrder.orderTimeLine'): {creation: new Date()};
    let orderStatus = ids ? get(isr, 'imagingOrder.orderStatus'): null;

    patient = assign(patient, {
        title: title.id !== '' ? title : null,
        firstName: patient.firstName.trim(),
        lastName: patient.lastName.trim(),
        patientClass: patientClass.id !== '' ? patientClass : null,
        demographic: assign(get(patient, 'demographic'), {
            gender: gender.id !== '' ? gender : null,
            confidentiality: confidentiality.id !== '' ? confidentiality : null,
            dateOfBirth: dob ? moment(dob).format('YYYY-MM-DD'): null
        }),
        confidential: confidentiality.id === 3
    });

    exams.forEach(exam => {
        let accessionNumber = get(exam, 'accessionNumber');
        let patientPart = get(exam, 'patientPart');
        let organismPart = get(exam, 'organismPart');
        let paymentType = get(exam, 'paymentType');
        let pecId = get(exam, 'pecId');
        let pecRef = get(exam, 'pecRef');
        let totalAmount = get(exam, 'totalAmount');
        let discount = get(exam, 'discount');
        let percentageDiscount = get(exam, 'percentageDiscount');
        let reasonForExam = find(rfes, {id: get(exam, 'reasonForExam.id')});
        let procedureCode = get(exam, 'procedureCode');
        let technician = get(exam, 'technician');
        let aet = find(aets, {id: get(exam, 'aet.id')});
        let referringPhysician = get(exam, 'referringPhysician');
        let performingPhysician = get(exam, 'performingPhysician');
        let priority = get(exam, 'priority');
        let date = get(exam, 'date');
        let time = get(exam, 'time');
        let duration = get(exam, 'duration');
        let notes = get(exam, 'notes');

        let endTime = moment(time, 'HH:mm').add(duration, 'm').format('HH:mm');

        if (get(referringPhysician, 'id') === '') referringPhysician = null;

        if (ids == null) {
            if (get(performingPhysician, 'id') === '') performingPhysician = null;
            else {
                performingPhysician = physicians.find(v => v.id === get(performingPhysician, 'id'))
            }
        }

        let sps = {
            // id: ids ? ids.spsId : 0,
            scheduledStationAETitle: aet,
            scheduledStationName: get(aet, 'name'),
            scheduledProcedureStepStartTime: time,
            scheduledProcedureStepEndTime: endTime,
            scheduledProcedureStepStartDate: moment(date).format('YYYY-MM-DD'),
            scheduledProcedureStepEndDate: moment(date).format('YYYY-MM-DD'),
            scheduledPerformingPhysiciansName: performingPhysician || get(procedureCode, 'defaultPerformingPhysician'),
            scheduledProcedureStepDescription: get(reasonForExam, 'value') + ' ' + get(procedureCode, 'code'),
            scheduledProcedureStepStatus: spsStatus || 'SCHEDULED',
            modality: get(aet, 'modality'),
            anatomicalOrientationType: 'BIPED',
            procedureCode: procedureCode,
            accessionNumber: accessionNumber,
            patientPart: patientPart,
            organismPart: organismPart,
            paymentType: paymentType,
            pecId: pecId,
            pecRef: pecRef,
            totalAmount: totalAmount,
            discount: discount,
            percentageDiscount: percentageDiscount,
            technician: technician,
            urgent: patientClass.id === 1
        };

        let rp = {
            // id: ids ? ids.rpId : 0,
            studyDate: moment(date).format('YYYY-MM-DD'),
            studyTime: time,
            reasonForRequestedProcedure: reasonForExam,
            requestedProcedureDescription: notes,
            requestedProcedurePriority: priority.id !== '' ? priority : null,
            confidentiality: confidentiality.id !== '' ? confidentiality : null,
            reportingPriority: priority.id !== '' ? priority : null,
            namesOfIntendedRecipientsOfResults: get(referringPhysician, 'lastName') + ' ' + get(referringPhysician, 'firstName'),
            scheduledProcedureSteps: Array.of(sps)
        };

        let order = {
            // id: get(exam, 'orderId'),
            callBackPhoneNumber: get(referringPhysician, 'phone'),
            requestingPhysician: get(referringPhysician, 'lastName'),
            relevantClinicalInfo: get(patient, 'medicalHistory.additionalPatientHistory'),
            requestedDate: moment(date).format('YYYY-MM-DD'),
            requestedTime: time,
            orderTimeLine: orderTimeLine,
            priority: priority.id !== '' ? priority : null,
            patient: patient,
            confidentiality: confidentiality.id !== '' ? confidentiality : null,
            referringPhysician: referringPhysician,
            orderStatus: orderStatus
        };

        let isr = {
            // id: ids ? ids.isrId : 0,
            patient: patient,
            accessionNumber: accessionNumber,
            dateOfTheFirstExam: moment(date),
            issueDateOfTheImagingServiceRequest: new Date(),
            issueTimeOfTheImagingServiceRequest: moment().format('HH:mm'),
            orderCallBackPhoneNumber: '',
            requestedProcedure: rp,
            imagingOrder: order,
        };

        isrs.push(isr);
    });

    return isrs;
}

export function buildOrder(patient: any, orderData: any, rfes: any[]): any {

    let reasonForExam = find(rfes, {id: get(orderData, 'appointmentReason.id')});
    let date = get(orderData, 'date');
    let time = get(orderData, 'time');
    let confidentiality = get(patient, 'demographic.confidentiality');

    let title = get(patient, 'title');
    let patientClass = get(patient, 'patientClass');
    let gender = get(patient, 'demographic.gender');

    patient = assign(patient, {
        externalPatientID: '',
        title: title.id !== '' ? title : null,
        patientClass: patientClass.id !== '' ? patientClass : null,
        demographic: assign(get(patient, 'demographic'), {
            gender: gender.id !== '' ? gender : null,
            confidentiality: confidentiality.id !== '' ? confidentiality : null
        }),
        confidential: confidentiality.id === 3
    });

    let order = {
        patient: patient,
        reasonForExam: reasonForExam,
        reasonForStudy: get(reasonForExam, 'value'),
        relevantClinicalInfo: get(patient, 'medicalHistory.additionalPatientHistory'),
        requestedDate: date,
        requestedTime: time,
        orderTimeLine: {creation: new Date()},
        confidentiality: confidentiality.id !== '' ? confidentiality : null
    };

    return {
        imagingOrder: order,
        issueDateOfTheImagingServiceRequest: new Date(),
        issueTimeOfTheImagingServiceRequest: moment().format('HH:mm'),
        patient: patient,
        requestedProcedure: null
    };

}
