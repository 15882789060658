<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-calendar-plus"></mat-icon>
    <h3 mat-dialog-title>{{(order ? 'ADD_WAITING_ORDER' : 'SCHEDULE_EXAM') | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<div mat-dialog-content>
    <mat-vertical-stepper [linear]="true" #stepper>
        <ng-template matStepperIcon="edit">
            <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
        </ng-template>
        <ng-template matStepperIcon="done">
            <mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
        </ng-template>
        <ng-template matStepperIcon="number" let-index="index">
            {{index + 1}}
        </ng-template>

        <mat-step [stepControl]="patientForm">
            <form [formGroup]="patientForm">
                <ng-template matStepLabel>{{'PATIENT_EXAM' | translate}}</ng-template>

                <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="6px">
                    <ng-container *ngIf="generalSetting && generalSetting.idScannerConfigured && false">
                        <div fxFlex="80px">
                            <img [src]="profilePicture" alt="PROFILE" class="patient-image">
                        </div>
                    </ng-container>
                    <div fxFlex>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="4px">
                            <div *ngIf="!order" fxFlex="180px">
                                <mat-form-field appearance="standard">
                                    <mat-label>{{'PATIENT_ID' | translate}}</mat-label>
                                    <input [placeholder]="'PATIENT_ID' | translate" [required]="generalSetting && (generalSetting.patientIdGenerationMode === 'MANUAL')" formControlName="externalPatientID"
                                           matInput
                                           tabindex="-1">
                                </mat-form-field>
                                <div *ngIf="patientIdExists && !patientSelected" class="ft-error">
                                    <span>{{'ALREADY_USED' | translate}}</span>
                                </div>
                            </div>

                            <div class="select-with-suffix">
                                <mat-form-field appearance="standard" formGroupName="title">
                                    <mat-label>{{'TITLE' | translate}}</mat-label>
                                    <mat-select [placeholder]="'TITLE' | translate" formControlName="id" tabindex="-1">
                                        <mat-option *ngFor="let title of titles" [value]="title.id">{{title.value}}</mat-option>
                                    </mat-select>
                                    <button (click)="$event.stopImmediatePropagation(); addTitle()" class="select-suffix"
                                            mat-icon-button matSuffix>
                                        <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>


                            <mat-form-field appearance="standard" fxFlex>
                                <mat-label>{{'LAST_NAME' | translate}}</mat-label>
                                <input #lastName (blur)="upperCase($event, 'lastName')" [placeholder]="'LAST_NAME' | translate" formControlName="lastName" matInput required type="text">
                            </mat-form-field>

                            <mat-form-field appearance="standard">
                                <mat-label>{{'FIRST_NAME' | translate}}</mat-label>
                                <input (blur)="capitalize($event, 'firstName')" [placeholder]="'FIRST_NAME' | translate" formControlName="firstName" matInput required>
                            </mat-form-field>


                            <button (click)="findPatient()" *ngIf="!data.patient" class="find-patient" mat-icon-button>
                                <mat-icon fontIcon="mdi-magnify" fontSet="mdi" style="color: #FFFFFF;"></mat-icon>
                                <span *ngIf="numOfDuplicatePatients && !patientSelected"
                                      class="badge">{{numOfDuplicatePatients}}</span>
                            </button>
                        </div>
                        <div formGroupName="demographic" fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="4px">
                            <mat-form-field appearance="standard" fxFlex="180px">
                                <mat-label>{{'DATEOFBIRTH' | translate}}</mat-label>
                                <input (dateInput)="changeAge()" [matDatepicker]="dobPicker" [max]="minDate" formControlName="dateOfBirth" matInput
                                       placeholder="{{'DATEOFBIRTH' | translate}}">
                                <mat-datepicker-toggle [for]="dobPicker" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #dobPicker (closed)="changeAge()"></mat-datepicker>
                            </mat-form-field>

                            <div [formGroup]="ageForm" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="4px">
                                <mat-form-field appearance="standard" fxFlex="80px">
                                    <mat-label>{{'AGE_YEARS' | translate}}</mat-label>
                                    <input (keyup)="changeDate()" formControlName="years" matInput
                                           placeholder="{{'AGE_YEARS' | translate}}"
                                           type="number">
                                </mat-form-field>

                                <mat-form-field appearance="standard" fxFlex="80px">
                                    <mat-label>{{'AGE_MONTHS' | translate}}</mat-label>
                                    <input (keyup)="changeDate()" formControlName="months" matInput
                                           placeholder="{{'AGE_MONTHS' | translate}}"
                                           type="number">
                                </mat-form-field>
                            </div>

                            <div formGroupName="gender" fxLayout="column">
                                <div  [style.color]="patientForm.get('demographic.gender').invalid ? '#cc4040':''" class="label-option">{{'GENDER' | translate}}*</div>

                                <mat-radio-group formControlName="id" fxLayout="row" fxLayoutAlign="start end">
                                    <mat-radio-button *ngFor="let option of genders" [value]="option.id" fxFlex required>
                                        {{option.value == 'U' ? ('UNKNOWN' | translate): option.value}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <span fxFlex></span>


                            <div formGroupName="confidentiality" fxLayout="column" fxLayoutAlign="space-between">
                                <div class="label-option">{{'CONFIDENTIALITY' | translate}}</div>

                                <mat-radio-group formControlName="id" fxLayout="row" fxLayoutAlign="start end">
                                    <mat-radio-button *ngFor="let option of confidentialities" [value]="option.id"
                                                      fxFlex>
                                        {{option.description}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>

                </div>

                <ng-container *ngIf="alerts">
                    <div class="alert">{{'MEDICALALERTS' | translate}}: {{alerts}}</div>
                </ng-container>
                <div fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="4px">
                    <mat-form-field appearance="standard">
                        <mat-label>{{'CIN' | translate}}</mat-label>
                        <input matInput [placeholder]="'CIN' | translate" formControlName="cin">
                    </mat-form-field>
                    <mat-form-field appearance="standard">
                        <mat-label>{{'PHONE' | translate}}</mat-label>
                        <input [placeholder]="'PHONE' | translate" formControlName="phone" matInput type="tel">
                    </mat-form-field>
                    <mat-form-field appearance="standard" fxFlex>
                        <mat-label>{{'EMAIL' | translate}}</mat-label>
                        <input type="email" matInput [placeholder]="'EMAIL' | translate" formControlName="email">
                    </mat-form-field>
                </div>

                <div formGroupName="address" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="4px">
                    <mat-form-field appearance="standard" fxFlex="">
                        <mat-label>{{'ADDRESS' | translate}}</mat-label>
                        <input [placeholder]="'ADDRESS' | translate" formControlName="street" matInput>
                    </mat-form-field>
                    <mat-form-field appearance="standard" fxFlex="200px">
                        <mat-label>{{'POSTAL_CODE' | translate}}</mat-label>
                        <input [formControl]="postalCodeControl"
                               [matAutocomplete]="postalCodeAuto"
                               [placeholder]="'POSTAL_CODE' | translate"
                               matInput>
                        <mat-autocomplete #postalCodeAuto="matAutocomplete"
                                          (optionSelected)="onChangePostalCode($event)"
                                          [autoActiveFirstOption]="true">
                            <ng-container>
                                <mat-option *ngFor="let city of filteredPostalCodes"
                                            [value]="city" class="option-line">
                                    {{ city.code }} - {{city.location}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field appearance="standard" fxFlex="200px">
                        <mat-label>{{'CITY' | translate}}</mat-label>
                        <input [formControl]="cityControl"
                               [matAutocomplete]="cityAuto1"
                               [placeholder]="'CITY' | translate"
                               matInput>
                        <mat-autocomplete #cityAuto1="matAutocomplete"
                                          (optionSelected)="onChangePostalCode($event)"
                                          [autoActiveFirstOption]="true">
                            <ng-container>
                                <mat-option *ngFor="let city of filteredPostalCodes"
                                            [value]="city" class="option-line">
                                    {{ city.location }}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="4px">

                    <div fxLayout="column" fxLayoutAlign="space-between" formGroupName="patientClass">
                        <div class="label-option">{{'PATIENT_CLASS' | translate}}</div>

                        <mat-radio-group fxLayout="row" fxLayoutAlign="start end" formControlName="id">
                            <mat-radio-button fxFlex *ngFor="let option of patientClasses" [value]="option.id">
                                {{option.description }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <span fxFlex></span>

                    <div [formGroup]="patientForm">
                        <div fxLayout="column" fxLayoutAlign="space-between">
                            <div class="label-option">{{'DEBITER' | translate}}</div>

                            <mat-radio-group fxLayout="row" fxLayoutAlign="start end" formControlName="debiter">
                                <mat-radio-button fxFlex *ngFor="let option of debiters" [value]="option">
                                    {{(option === 'PATIENT' ? 'SELF_PAYMENT': option) | translate}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>

                <div *ngIf="billingRequired" fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="4px"
                     [formGroup]="patientForm">

                    <mat-form-field fxFlex appearance="standard" formGroupName="insurance">
                        <input [matAutocomplete]="organismAuto"
                               [placeholder]="'INS_NAME' | translate"
                               aria-label="Insurance organism"
                               formControlName="organismName"
                               matInput
                               type="text">
                        <mat-autocomplete #organismAuto="matAutocomplete" (optionSelected)="onSelectOrganism()" autoActiveFirstOption>
                            <mat-option *ngFor="let organism of filteredOrganisms | async" [value]="organism.name">
                                {{organism.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <div formGroupName="insurance">
                        <mat-form-field appearance="standard">
                            <mat-label>{{'COVER_NUMBER' | translate}}</mat-label>
                            <input [placeholder]="'COVER_NUMBER' | translate" formControlName="coverNumber" matInput>
                        </mat-form-field>
                    </div>

                    <mat-form-field fxFlex appearance="standard">
                        <mat-label>{{'TARIFF' | translate}}</mat-label>
                        <mat-select [placeholder]="'TARIFF' | translate"
                                    (selectionChange)="changeTariff($event)"
                                    [(ngModel)]="tariffId"
                                    [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let value of tariffs" [value]="splitter(value, 1)">
                                {{splitter(value, 0)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ng-container [ngSwitch]="convType">
                        <ng-container *ngSwitchCase="'PEC'">
                            <mat-form-field fxFlex appearance="standard" formGroupName="insurance"
                                            *ngIf="patientSelected">
                                <mat-label>{{'PEC_REF' | translate}}</mat-label>
                                <mat-select [placeholder]="'PEC_REF' | translate"
                                            (selectionChange)="onSelectPEC($event)"
                                            formControlName="pecName">
                                    <mat-option *ngFor="let pec of organismPecs" [value]="pec">
                                        {{splitter(pec, 1)}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'THIRD_PARTY_PAYMENT'">
                            <mat-form-field fxFlex appearance="standard" formGroupName="insurance">
                                <mat-label>{{'CONVENTION' | translate}}</mat-label>
                                <mat-select [placeholder]="'CONVENTION' | translate"
                                            [compareWith]="conventionCompare" formControlName="conventionName">
                                    <mat-option *ngFor="let convention of organismConventions" (onSelectionChange)="onSelectConvention(convention)"
                                                [value]="convention">
                                        {{splitter(convention, 0)}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchDefault></ng-container>
                    </ng-container>
                </div>

                <mat-divider *ngIf="order" class="ft-divider"></mat-divider>

                <div fxLayout="row wrap" fxLayoutGap="4px" *ngIf="order" [formGroup]="orderForm">

                    <mat-form-field appearance="standard">
                        <mat-label>{{'APT_REASON' | translate}}</mat-label>
                        <mat-select [placeholder]="'APT_REASON' | translate" formControlName="appointmentReason">
                            <mat-option *ngFor="let reason of reasonForExams" [value]="reason">{{reason.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex="180px">
                        <mat-label>{{'DATE' | translate}}</mat-label>
                        <input [placeholder]="'DATE' | translate" matInput [matDatepicker]="myDatepicker"
                               formControlName="date" required>
                        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex="108px">
                        <mat-label>{{'TIME' | translate}}</mat-label>
                        <input [placeholder]="'TIME' | translate" matInput type="time" formControlName="time" required>
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex="100px">
                        <mat-label>{{'DURATION' | translate}}</mat-label>
                        <input [placeholder]="'DURATION' | translate" formControlName="duration" inputmode="numeric" matInput type="number">
                    </mat-form-field>
                </div>

                <div *ngIf="!order" [formGroup]="examForm">
                    <div formArrayName="exams" *ngFor="let item of examForm.get('exams')['controls']; let i = index;">
                        <div [formGroupName]="i" class="exam-box">
                            <!--<mat-divider *ngIf="i > 0" class="ft-divider"></mat-divider>-->

                            <div class="select-with-suffix" fxLayout="row wrap" fxLayoutGap="4px">

                                <mat-form-field fxFlex="" appearance="standard" class="auto-comp">
                                    <mat-label>{{'PROCEDURE_CODE' | translate}}</mat-label>
                                    <input matInput [placeholder]="'PROCEDURE_CODE' | translate" required
                                           [formControl]="procedureCodeControl[i]"
                                           [matAutocomplete]="modelList">
                                    <mat-autocomplete #modelList="matAutocomplete"
                                                      [autoActiveFirstOption]="true"
                                                      (opened)="selected = null"
                                                      (optionSelected)="onChangeProcedureCode($event, item, i)">
                                        <mat-option *ngFor="let pc of filteredProcedures[i]" [value]="pc">
                                            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="2px">
                                                <span>{{pc.description}}</span>
                                                <span fxFlex=""></span>
                                                <div class="select-badge"
                                                     *ngIf="selected !== pc.id && generalSetting.billingRequired"
                                                     [style.color]="'#353535'"
                                                     fxFlex="46px">{{pc.billingCode?.price || 0.0}}</div>
                                                <div class="select-badge"
                                                     *ngIf="selected !== pc.id && generalSetting.billingRequired"
                                                     [style.color]="'#353535'" [style.backgroundColor]="'#ffb364'"
                                                     fxFlex="46px">{{pc.billingCode?.description || 'Z00'}}</div>
                                                <div class="select-badge" *ngIf="selected !== pc.id"
                                                     [style.background]="pc.reasonForExam?.color || '#444'"
                                                     fxFlex="46px">{{pc.reasonForExam?.value || '-'}}</div>
                                            </div>

                                        </mat-option>
                                    </mat-autocomplete>
                                    <button mat-icon-button matSuffix
                                            (click)="$event.stopImmediatePropagation();searchProcedureCode(item, i)">
                                        <mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
                                    </button>
                                </mat-form-field>
                                <div *ngIf="generalSetting && generalSetting.billingRequired" fxLayout="row nowrap"
                                     fxLayoutAlign="start end" fxLayoutGap="4px">
                                    <div fxLayout="row nowrap" fxFlex="180px" fxLayoutGap="4px"
                                         *ngIf="convType==='THIRD_PARTY_PAYMENT'">
                                        <mat-form-field fxFlex="80px" appearance="standard" style="width: 100%;">
                                            <mat-label>{{'PAT_PART' | translate}}(%)</mat-label>
                                            <input matInput [placeholder]="('PAT_PART' | translate)+'(%)'"
                                                   formControlName="patientPart">
                                        </mat-form-field>
                                        <mat-form-field fxFlex="80px" appearance="standard" style="width: 100%;">
                                            <mat-label>{{'ORG_PART' | translate}}(%)</mat-label>
                                            <input matInput [placeholder]="('ORG_PART' | translate)+'(%)'"
                                                   formControlName="organismPart">
                                        </mat-form-field>
                                    </div>
                                    <mat-form-field appearance="standard" fxFlex="100px">
                                        <mat-label>{{'TOTAL_AMOUNT' | translate}}</mat-label>
                                        <input [placeholder]="'TOTAL_AMOUNT' | translate" matInput type="number"
                                               formControlName="totalAmount"
                                               (keyup)="enterLinearTotalAmount(item, $event)">
                                    </mat-form-field>

                                    <mat-form-field appearance="standard" fxFlex="100px">
                                        <mat-label>{{'DISCOUNT' | translate}}({{currencyFormat}})</mat-label>
                                        <input [placeholder]="'DISCOUNT' | translate" matInput type="number"
                                               formControlName="discount"
                                               (keyup)="enterLinearDiscount(item, $event)">
                                    </mat-form-field>

                                    <mat-form-field appearance="standard" fxFlex="80px">
                                        <mat-label>{{'DISCOUNT_PERCENTAGE' | translate}}</mat-label>
                                        <input [placeholder]="'DISCOUNT_PERCENTAGE' | translate" matInput type="number"
                                               formControlName="percentageDiscount"
                                               (keyup)="enterLinearPercentageDiscount(item, $event)">
                                    </mat-form-field>
                                </div>

                            </div>
                            <div fxLayout="row wrap" fxLayoutAlign="space-between end" fxLayoutGap="4px">

                                <mat-form-field fxFlex="180px" appearance="standard" formGroupName="priority">
                                    <mat-label>{{'PRIORITY' | translate}}</mat-label>
                                    <mat-select formControlName="id" [placeholder]="'PRIORITY' | translate">
                                        <mat-option *ngFor="let option of priorities" [value]="option.id">
                                            {{option.value}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field fxFlex appearance="standard" formGroupName="performingPhysician">
                                    <mat-label>{{'PERFORMING_PHYSICIAN' | translate}}</mat-label>
                                    <mat-select formControlName="id" [placeholder]="'PERFORMING_PHYSICIAN' | translate">
                                        <mat-option *ngFor="let option of performingPhysicians" [value]="option.id">
                                            {{option.fullName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>


                                <mat-form-field appearance="standard" fxFlex>
                                    <mat-label>{{'REFERRING_PHYSICIAN' | translate}}</mat-label>
                                    <input matInput [placeholder]="'REFERRING_PHYSICIAN' | translate"
                                           [formControl]="referringPhysicianControl[i]"
                                           [matAutocomplete]="refPhyList">
                                    <mat-autocomplete #refPhyList="matAutocomplete"
                                                      [autoActiveFirstOption]="true"
                                                      (optionSelected)="onChangeRefPhy($event, item, i)">
                                        <ng-container>
                                            <mat-option *ngFor="let user of filteredReferringPhysicians[i]"
                                                        [value]="user" class="option-line">
                                                <div fxLayout="column" fxLayoutAlign="center start" [style.lineHeight.px]="14" [style.fontSize.px]="12">
                                                    <span fxFlex class="option-line-1"> {{ user.fullName }} </span>
                                                    <span *ngIf="user.codeAnam" class="option-line-2"
                                                          fxFlex> Code: {{ user.codeAnam || '-'}} </span>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                    <button mat-icon-button matSuffix
                                            (click)="$event.stopImmediatePropagation(); addReferringPhysician(item, i)">
                                        <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
                                    </button>
                                    <button (click)="$event.stopImmediatePropagation(); findReferringPhysician(item, i)"
                                            mat-icon-button
                                            matSuffix>
                                        <mat-icon fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="start center">
                                <mat-form-field appearance="standard" formGroupName="aet">
                                    <mat-label>{{'AET' | translate}}</mat-label>
                                    <mat-select [placeholder]="'AET' | translate" formControlName="id"
                                                [required]="!order">
                                        <mat-option *ngFor="let aet of filteredAets[i]" [value]="aet.id">{{aet.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field fxFlex appearance="standard" formGroupName="technician">
                                    <mat-label>{{'TECHNICIAN' | translate}}</mat-label>
                                    <mat-select [placeholder]="'TECHNICIAN' | translate" formControlName="id">
                                        <mat-option *ngFor="let usr of technicians" [value]="usr.id">{{usr.fullName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="standard" fxFlex="180px">
                                    <mat-label>{{'DATE' | translate}}</mat-label>
                                    <input [placeholder]="'DATE' | translate" matInput [matDatepicker]="examDatePicker"
                                           formControlName="date" required>
                                    <mat-datepicker-toggle matSuffix [for]="examDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #examDatePicker></mat-datepicker>
<!--                                    <mat-datepicker #examDatePicker [dateClass]="dateClass"></mat-datepicker>-->
                                </mat-form-field>

                                <mat-form-field appearance="standard" fxFlex="100px">
                                    <mat-label>{{'TIME' | translate}}</mat-label>
                                    <input type="time" matInput [placeholder]="'TIME' | translate"
                                           formControlName="time"
                                           required>
                                </mat-form-field>
                                <mat-form-field appearance="standard" fxFlex="80px">
                                    <mat-label>{{'DURATION' | translate}}</mat-label>
                                    <input type="number" matInput [placeholder]="'DURATION' | translate"
                                           formControlName="duration"
                                           required>
                                </mat-form-field>

                                <button class="find-patient" mat-icon-button
                                        [matTooltip]="'SEARCH_AVAILABILITY' | translate"
                                        [disabled]="!data.editable"
                                        (click)="checkAvailability(item)">
                                    <mat-icon style="color: #FFFFFF;" fontIcon="mdi-calendar-text"
                                              fontSet="mdi"></mat-icon>
                                </button>

                            </div>

                            <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="4px" *ngIf="!data.ids">
                                <button color="primary" mat-button (click)="addItem()"
                                        *ngIf="i === examForm.get('exams')['controls'].length -1"
                                        [disabled]="!data.editable || convType === 'PEC'">
                                    <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                                    {{'ADD_NEW_EXAM' | translate}}
                                </button>
                                <button mat-icon-button (click)="removeItem(i)"
                                        *ngIf="examForm.get('exams')['controls'].length > 1">
                                    <mat-icon fontSet="mdi" fontIcon="mdi-delete" color="warn"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="paymentForm" [optional]="true"
                  *ngIf="generalSetting && generalSetting.billingRequired">
            <form [formGroup]="paymentForm">
                <ng-template matStepLabel>{{'PAYMENT' | translate}}</ng-template>

                <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="4px">
                    <mat-form-field appearance="standard" fxFlex="140px">
                        <mat-label>{{'PAYMENT_DATE' | translate}}</mat-label>
                        <input matInput [matDatepicker]="picker10" placeholder="DD/MM/YYYY" formControlName="date"
                               tabindex="-1">
                        <mat-datepicker-toggle matSuffix [for]="picker10"></mat-datepicker-toggle>
                        <mat-datepicker #picker10></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex="140px">
                        <mat-label>{{'DUE_DATE' | translate}}</mat-label>
                        <input matInput [matDatepicker]="picker2" placeholder="DD/MM/YYYY" formControlName="dueDate"
                               tabindex="-1">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex="80px">
                        <mat-label>{{'DUE_AMOUNT' | translate}}</mat-label>
                        <input matInput [placeholder]="'DUE_AMOUNT' | translate" formControlName="due" type="number"
                               (keyup)="onChangeTotalAmount($event)"
                               tabindex="-1">
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex="68px">
                        <mat-label>{{'DISCOUNT_PRICE' | translate: {currency: currencyFormat} }}</mat-label>
                        <input matInput (keyup)="calculatePercentage($event)"
                               (blur)="handlePaymentEmptyField('discount', $event)"
                               [placeholder]="'DISCOUNT_PRICE' | translate"
                               formControlName="discount" type="number">
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex="80px">
                        <mat-label>{{'TOTAL_AMOUNT' | translate: {currency: currencyFormat} }}</mat-label>
                        <input matInput [placeholder]="'TOTAL_AMOUNT' | translate" formControlName="totalAfterDiscount"
                               type="number">
                    </mat-form-field>

                    <ng-container *ngIf="this.patientForm.get('debiter').value !== 'PATIENT'">
                        <mat-form-field appearance="standard" fxFlex="80px">
                            <mat-label>{{'ORG_PART' | translate}}({{currencyFormat}})</mat-label>
                            <input matInput [placeholder]="'ORG_PART' | translate" formControlName="organismPart"
                                   (keyup)="enterAmount($event)"
                                   type="number">
                        </mat-form-field>

                        <mat-form-field appearance="standard" fxFlex="80px">
                            <mat-label>{{'PAT_PART' | translate}}({{currencyFormat}})</mat-label>
                            <input matInput [placeholder]="'PAT_PART' | translate" formControlName="patientPart"
                                   (keyup)="enterAmount($event)"
                                   type="number">
                        </mat-form-field>
                    </ng-container>


                    <mat-form-field appearance="standard" fxFlex="80px">
                        <mat-label>{{'PAID_AMOUNT' | translate}}</mat-label>
                        <input matInput [placeholder]="'PAID_AMOUNT' | translate" formControlName="enteredAmount"
                               (keyup)="enterAmount($event)"
                               type="number">
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex="60px">
                        <mat-label>{{'LEFT_AMOUNT' | translate}}</mat-label>
                        <input matInput [ngModel]="leftAmount" [ngModelOptions]="{standalone: true}" type="number"
                               [placeholder]="'LEFT_AMOUNT' | translate" disabled>
                    </mat-form-field>

                </div>

                <div fxLayout="row wrap" fxLayoutAlign="space-between end" fxLayoutGap="4px">
                    <div fxLayout="column">
                        <div class="label-option">{{'PAYER' | translate}}</div>

                        <mat-radio-group fxLayout="row" fxLayoutAlign="start end" formControlName="payer">
                            <mat-radio-button fxFlex *ngFor="let option of ['PATIENT', 'THIRD_PAYER', 'OTHER']"
                                              [value]="option">
                                {{option | translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <span fxFlex></span>

                    <div [ngSwitch]="paymentForm.get('payer').valueChanges | async" fxLayoutGap="4px">

                        <!--<ng-container *ngSwitchCase="'THIRD_PAYER'">
                            <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4px"
                                 formGroupName="organism">
                                <mat-form-field fxFlex="160px" appearance="standard">
                                    <mat-label>{{'ORGANISM' | translate}}</mat-label>
                                    <mat-select [placeholder]="'ORGANISM' | translate" formControlName="id">
                                        <mat-option *ngFor="let item of organisms | async" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div [formGroup]="paymentForm">
                                    <mat-form-field fxFlex="120px" appearance="standard">
                                        <mat-label>{{'ORGANISM_PART' | translate}}</mat-label>
                                        <input matInput type="number" (keyup)="calculatePartPercentage($event)"
                                               [placeholder]="'ORGANISM_PART' | translate"
                                               formControlName="organismPart">
                                    </mat-form-field>

                                    <mat-form-field fxFlex="120px" appearance="standard">
                                        <mat-label>{{'ORGANISM_PART_PERCENTAGE' | translate}}</mat-label>
                                        <input matInput type="number" (keyup)="calculatePart($event)"
                                               [placeholder]="'ORGANISM_PART_PERCENTAGE' | translate"
                                               formControlName="organismPartPercentage">
                                    </mat-form-field>
                                </div>

                            </div>
                        </ng-container>-->
                        <ng-container *ngSwitchCase="'OTHER'">
                            <mat-form-field fxFlex="160px" appearance="standard" [formGroup]="paymentForm">
                                <mat-label>{{'PAYER_NAME' | translate}}</mat-label>
                                <input matInput [placeholder]="'PAYER_NAME' | translate" formControlName="otherPayer">
                            </mat-form-field>

                            <mat-form-field fxFlex="160px" appearance="standard" [formGroup]="paymentForm">
                                <mat-label>{{'PAYER_ID' | translate}}</mat-label>
                                <input matInput [placeholder]="'PAYER_ID' | translate" formControlName="payerID">
                            </mat-form-field>
                        </ng-container>

                    </div>
                </div>

                <div fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="space-between">
                    <mat-form-field appearance="standard" fxFlex formGroupName="paymentMethod">
                        <mat-label>{{'PAYMENT_METHOD' | translate}}</mat-label>
                        <mat-select [placeholder]="'PAYMENT_METHOD' | translate" formControlName="id">
                            <mat-option *ngFor="let item of paymentMethods" [value]="item.id">
                                {{item.value}}
                            </mat-option>
                        </mat-select>
<!--                        <button (click)="$event.stopImmediatePropagation(); addPaymentMethod()" [disabled]="paymentForm.get('paymentDispense').value" mat-icon-button
                                matSuffix>
                            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                        </button>-->
                    </mat-form-field>

                    <mat-form-field appearance="standard" fxFlex>
                        <mat-label>{{'REFERENCE' | translate}}</mat-label>
                        <input matInput [placeholder]="'REFERENCE' | translate"
                               formControlName="reference">
                    </mat-form-field>
                    <mat-form-field appearance="standard" fxFlex formGroupName="bank">
                        <mat-label>{{'BANK' | translate}}</mat-label>
                        <mat-select [placeholder]="'BANK' | translate" formControlName="id">
                            <mat-option *ngFor="let item of banks" [value]="item.id">
                                {{item.value}}
                            </mat-option>
                        </mat-select>
                        <button matSuffix mat-icon-button [disabled]="paymentForm.get('paymentDispense').value"
                                (click)="$event.stopImmediatePropagation(); addBank()">
                            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <mat-slide-toggle tabindex="-1" formControlName="paymentDispense"
                                  style="font-size: 12px; font-weight: 400;">
                    {{'PAYMENT_DISPENSE' | translate}}
                </mat-slide-toggle>

                <div *ngIf="paymentItems" [ngStyle]="{border: '2px solid #f3f3f3', marginTop: '12px'}">
                    <div class="ft-row ft-header">
                        <div class="ft-cell">{{'CODE' | translate}}</div>
                        <div class="ft-cell">{{'DESIGNATION' | translate}}</div>
                        <div class="ft-cell">{{'PRICE' | translate}}</div>
                        <div class="ft-cell">{{'ORG_PART' | translate}}</div>
                        <div class="ft-cell">{{'PAT_PART' | translate}}</div>
                        <div class="ft-cell">{{'DISCOUNT' | translate}}</div>
                    </div>
                    <div class="ft-row" *ngFor="let item of paymentItems">
                        <div class="ft-cell">{{item.accessionNumber}}</div>
                        <div class="ft-cell">{{item.procedureCode}}</div>
                        <div class="ft-cell">{{item.price}}</div>
                        <div class="ft-cell">{{item.organismPartPrice}}</div>
                        <div class="ft-cell">{{item.patientPartPrice}}</div>
                        <div class="ft-cell">{{item.discount}}</div>
                    </div>

                </div>

            </form>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>{{'ATTACHED_FILES' | translate}}</ng-template>
            <app-file-explorer [fileElements]="fileElements | async"
                               [path]="currentPath"
                               [patientID]="patientID"
                               [modalMode]="true"
                               [canNavigateUp]="canNavigateUp"
                               (folderAdded)="addFolder($event)"
                               (elementRemoved)="removeElement($event)"
                               (navigatedDown)="navigateToFolder($event)"
                               (navigatedUp)="navigateUp()"
                               (onUpload)="afterUpload($event)"
                               (elementRenamed)="renameElement($event)"
                               (elementMoved)="moveElement($event)">
            </app-file-explorer>
        </mat-step>
    </mat-vertical-stepper>
</div>

<mat-progress-bar *ngIf="saving || !ready" [mode]="saving ? 'indeterminate':'query'" color="accent"></mat-progress-bar>

<div mat-dialog-actions fxLayout="row" fxLayoutGap="4px">
    <!--<mat-slide-toggle tabindex="-1" [(ngModel)]="singleReport" *ngIf="!order" style="font-size: 12px;">
            {{'SINGLE_REPORT' | translate}}
        </mat-slide-toggle>-->

    <!--    <mat-slide-toggle *ngIf="!examSaved" [(ngModel)]="posEnabled" style="font-size: 12px;" tabindex="-1"
                          (change)="savePrintState($event)">
            {{'POS_ENABLED' | translate}}
        </mat-slide-toggle>-->

    <!--<mat-slide-toggle tabindex="-1" [(ngModel)]="order" *ngIf="!data.isr" style="font-size: 12px;">
            {{'MAKE_WAITING' | translate}}
        </mat-slide-toggle>

        <mat-slide-toggle disabled tabindex="-1" *ngIf="!order" style="font-size: 12px;">
            {{'NOTIFY_BY_SMS' | translate}}
        </mat-slide-toggle>

        <mat-slide-toggle disabled tabindex="-1" *ngIf="!order" style="font-size: 12px;">
            {{'NOTIFY_BY_EMAIL' | translate}}
        </mat-slide-toggle>-->
    <button (click)="scanIDCard()" *ngIf="generalSetting && generalSetting.idScannerConfigured" mat-raised-button>
        {{'SCAN_CARD' | translate}}
    </button>

    <button *ngIf="examSaved" color="primary" mat-raised-button
            (click)="printTicket(patientForm.value, order ? orderForm.value: examForm.value)">
        <mat-icon fontIcon="mdi-barcode" fontSet="mdi"></mat-icon>
        {{'PRINT_STICKER' | translate}}
    </button>

    <button *ngIf="examSaved && generalSetting.billingRequired" color="primary" mat-raised-button
            (click)="printPaymentReceipt()">
        <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
        {{'PAYMENT_RECEIPT' | translate}}
    </button>

    <span fxFlex></span>

    <div class="has-future-exam" *ngIf="hasFutureExams | async">
        <span>{{'HAS_FUTURE_EXAMS' | translate}}</span>
        <button (click)="showPatientFutureExams()">{{'SHOW' | translate}}</button>
    </div>

    <span fxFlex></span>

    <button (click)="cancel()" color="accent" mat-raised-button>{{(examSaved ? 'EXIT' : 'CANCEL') | translate}}</button>

    <button *ngIf="data.editable && !examSaved" color="primary" mat-raised-button
            (click)="printAndSave(patientForm.value, order ? orderForm.value: examForm.value)"
            [disabled]="saving || (order ? (patientForm.invalid || orderForm.invalid): (patientForm.invalid || examForm.invalid || anExists))">
        {{'PRINT_AND_SAVE' | translate}}
    </button>

    <button *ngIf="data.editable && !examSaved" color="primary" mat-raised-button
            (click)="saving = true; save(patientForm.value, order ? orderForm.value: examForm.value)"
            [disabled]="order ? (patientForm.invalid || orderForm.invalid): (patientForm.invalid || examForm.invalid || anExists || saving)">
        {{'SAVE' | translate}}
    </button>
</div>
