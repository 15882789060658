import {ChangeDetectorRef, Component} from '@angular/core';
import {concat, reduce} from 'lodash';
import {from, of, Subscription} from 'rxjs';

import {map, mergeMap, tap} from 'rxjs/operators';
import {SettingService} from "../../setting/setting.service";
import {UploadInstance} from "../../model";
import {UPLOAD_ITEM_ANIMATION} from "../../animations";
import {StringUtils} from "../../utils";
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
    selector: 'app-excel-exam',
    templateUrl: './excel-exam.component.html',
    styleUrls: ['./excel-exam.component.scss'],
    animations: [UPLOAD_ITEM_ANIMATION]
})
export class ExcelExamComponent {

    public total: number = 0;
    public files: File[] = [];
    public currentFile: UploadInstance;

    public hasBaseDropZoneOver = false;
    public subscription: Subscription | Promise<any>;
    public fileTypesAuthorized = 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    public uploadComplete: boolean = false;

    constructor(private service: SettingService, private cdr: ChangeDetectorRef, private _bsRef: MatBottomSheetRef) {
    }

    private static _handleEntries(event) {
        return Array.from(event.dataTransfer.items)
            .filter((item: DataTransferItem) => item.kind === 'file')
            .map((item: DataTransferItem) => item.webkitGetAsEntry());
    }

    fileSize(size: number): string {
        return StringUtils.humanFileSize(size);
    }

    public handleItems(event, isFiles) {
        const entries = isFiles ? event : ExcelExamComponent._handleEntries(event);
        const subject = isFiles ? of(event).pipe(map(items => items.map(item => {
            return {file: item, path: "/" + item.name}
        }))) : from(this._buildTree(entries)).pipe(map(items => this._inlineTree(items)));

        this.subscription = subject.pipe(
            tap(items => this.total = items.length),
            map(items => items.map((item, index) => new UploadInstance(item.file, index, item.path))),
            mergeMap(items => this.service.uploadFiles(items))
        ).subscribe({
            next: data => {
                this.currentFile = data;
                this.cdr.detectChanges();
            },
            complete: () => {
                this.files = [];
                this.currentFile = null;
                this.uploadComplete = true;

                this.service.createTemplates().subscribe()
            }
        });
    }

    private _inlineTree(tree) {
        return reduce(tree.directories, (files, directory) => {
            return concat(files, this._inlineTree(directory));
        }, tree.files);
    }

    close(res) {
        this._bsRef.dismiss(res);
        if (this.subscription instanceof Subscription) this.subscription.unsubscribe();
    }

    private _parseDirectoryEntry(directoryEntry) {
        const directoryReader = directoryEntry.createReader();
        return new Promise((resolve, reject) => {
            directoryReader.readEntries(
                entries => resolve(this._buildTree(entries)), err => reject(err)
            );
        });
    }

    private _buildTree(entries) {
        const promises = [];
        const tree = {files: [], directories: []};
        entries.forEach(entry => {
            if (entry.isFile) {
                const promise = this._parseFileEntry(entry).then(file => {
                    tree.files.push(file);
                });
                promises.push(promise);
            } else if (entry.isDirectory) {
                const promise = this._parseDirectoryEntry(entry).then(directory => {
                    tree.directories.push(directory);
                });

                promises.push(promise);
            }
        });

        return Promise.all(promises).then(() => tree);
    }

    private _parseFileEntry(fileEntry) {
        return new Promise((resolve, reject) => {
            fileEntry.file(
                file => resolve({file, path: fileEntry.fullPath}), err => reject(err)
            );
        });
    }
}
