import {Component} from '@angular/core';
import {Field} from '../../models/field.interface';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-form-textarea',
    templateUrl: './form-textarea.component.html',
    styleUrls: ['./form-textarea.component.scss']
})
export class FormTextareaComponent implements Field {

    config: any;
    group: FormGroup;

    constructor() {
    }

}
