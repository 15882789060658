<button (click)="close(uploadComplete)" class="close-btn" mat-icon-button><mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon></button>
<div class="ft-parent-layout" fxFill fxFlex fxLayout="column">
    <ng-template #uploader>
        <div (drop)="handleItems($event, false)"
             (filesChange)="handleItems($event, true)"
             (validDragChange)="hasBaseDropZoneOver = $event"
             *ngIf="!currentFile" [accept]="fileTypesAuthorized"
             [files]='files'
             [ngClass]="{'file-over': hasBaseDropZoneOver}"
             class="upload-section"
             fxFlex
             fxLayout="column"
             multiple="1"
             ngfDrop
             ngfSelect>
            <span [@uploadItemTransition]>{{'DRAG_N_DROP_UPLOAD' | translate}}</span>
            <mat-icon [@uploadItemTransition] fontIcon="mdi-cloud-upload" fontSet="mdi"></mat-icon>
        </div>
    </ng-template>

    <ng-container *ngIf="uploadComplete; else uploader">
        <div (click)="close(true)" class="upload-section" fxFlex style="display: flex; align-items: center">
            <span [@uploadItemTransition] style="color: #009688">{{'UPLOAD_DONE' | translate}}</span>
        </div>
    </ng-container>


    <div *ngIf="currentFile" class="progress-section" fxFlex fxLayout="column">
        <mat-progress-bar [@uploadItemTransition] [value]="currentFile.progress" mode="buffer"></mat-progress-bar>
        <span [@uploadItemTransition]>{{'CURRENT_FILE_UPLOAD' | translate: {index: currentFile.index, total: total} }}
            ({{fileSize(currentFile.size)}})</span>
    </div>

</div>

