import {Component, OnInit} from '@angular/core';
import {LOGO} from '../global-variables';
import {SharedService} from "../shared";

@Component({
    selector: 'app-app-info',
    templateUrl: './app-info.component.html',
    styleUrls: ['./app-info.component.scss']
})
export class AppInfoComponent implements OnInit {

    license: any;
    logo = LOGO;

    constructor(private _shared: SharedService) {
    }

    ngOnInit() {
        this._shared.getLicenseInfo().subscribe(license => this.license = license);
    }

    toList(txt: string = ''): string[] {
        if (txt === null || txt === '' || txt.split(',').length === 1) return Array.of(txt);
        return Array.from(txt.split(','))
    }

    phone(contact: string) {
        return contact.substr(0, 15);
    }
}
