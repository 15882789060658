<div id="ft-player" fxLayout="column" fxLayoutAlign="space-between">
    <mat-toolbar class="dialog-toolbar">
        <mat-icon fontSet="mdi" fontIcon="mdi-disc-player"></mat-icon>
        <h3 mat-dialog-title>{{'AUDIO_PLAYER' | translate}}</h3>
        <span fxFlex></span>
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
        </button>
    </mat-toolbar>

    <div fxFlex="grow" mat-dialog-content style="overflow: visible">
       <div *ngIf="audioSources.length" fxFlex plyr style="width: 400px;"
            [plyrTitle]="'Audio title'"
            [plyrType]="'audio'"
            [plyrSources]="audioSources"
            [plyrOptions]="{invertTime: false, blankVideo: '#'}"
            (plyrInit)="player = $event"></div>
    </div>
</div>
