<div fxLayout="row" fxLayoutAlign="start" style="height: 100%">

    <div class="left-side" fxFlex="200px" style="overflow: auto;">
        <mat-nav-list>
            <h3 mat-subheader>{{'PRINTING_TEMPLATES' | translate}}</h3>
            <mat-list-item (click)="openFile(file)" *ngFor="let file of printingFiles">
                <mat-icon class="word-icon-gradient" fontIcon="mdi-file-word-box" fontSet="mdi" mat-list-icon></mat-icon>
                <h4 mat-line>{{file.name}}</h4>
            </mat-list-item>
        </mat-nav-list>

    </div>


    <ft-reporter (editorInitialized)="editorReady($event)"
                 [documentTitle]="selectedFile?.name"
                 [editable]="true"
                 [fileId]="'P_' + selectedFile?.name"
                 [templateMode]="1"
                 [templateModel]="selectedFile?.name" fxFlex>
    </ft-reporter>


</div>
