<div fxFill>
    <div fxLayout="column" class="card-view">
        <!--search area-->
        <div [formGroup]="filterForm" class="search-area" fxLayout="row wrap"
             fxLayout.lt-sm="column" fxLayoutAlign="space-between end" fxLayoutGap="8px"
             style="padding-right: 4px; padding-bottom: 8px;">

            <mat-form-field floatLabel="never" fxFlex fxFlex.lt-lg="80%" fxFlexOrder.lt-lg="1">
                <input #filter [autofocus]="true" [placeholder]="'SEARCH' | translate" formControlName="key" matInput>
                <span matPrefix><mat-icon class="prefix-icon" fontIcon="mdi-magnify"
                                          fontSet="mdi"></mat-icon>&nbsp;</span>
            </mat-form-field>

            <span fxFlex></span>

            <div fxLayout="column">
                <span class="label-option">{{'APPOINTMENT_STATUS' | translate}}</span>
                <mat-button-toggle-group aria-label="appointment status" formControlName="appointmentStatus"
                                         multiple
                                         name="appointmentStatus">
                    <mat-button-toggle *ngFor="let status of appointmentStatuses" [matTooltip]="status | translate"
                                       [value]="status">
                        <mat-icon [fontIcon]="getOrderStatusIcon(status)" [style.color]="getOrderStatusColor(status)"
                                  fontSet="mdi"></mat-icon>
                    </mat-button-toggle>

                </mat-button-toggle-group>

            </div>

            <div *ngIf="group.value === 'OT'" class="date-range-2" fxLayout="row nowrap" fxLayoutAlign="start end"
                 fxLayoutGap="4px">
                <mat-form-field fxFlex="120px">
                    <input (dateInput)="changePeriod()" [matDatepicker]="startDate" [placeholder]="'FROM' | translate"
                           formControlName="startDate" matInput>
                    <mat-datepicker-toggle [for]="startDate" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #startDate (closed)="changePeriod()"></mat-datepicker>
                </mat-form-field>

                <mat-form-field fxFlex="120px">
                    <input (dateInput)="changePeriod()" [matDatepicker]="endDate" [placeholder]="'TO' | translate"
                           formControlName="endDate"
                           matInput>
                    <mat-datepicker-toggle [for]="endDate" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #endDate (closed)="changePeriod()"></mat-datepicker>
                </mat-form-field>
            </div>


            <mat-button-toggle-group #group="matButtonToggleGroup" (change)="changeRange($event)" aria-label="Period"
                                     formControlName="period" name="period">
                <mat-button-toggle value="1M">
                    -{{'ONE_MONTH' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="1W">
                    -{{'ONE_WEEK' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="TODAY">
                    {{'TODAY' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="1W+">
                    +{{'ONE_WEEK' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="1M+">
                    +{{'ONE_MONTH' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="3M+">
                    +{{'THREE_MONTHS' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="OT">
                    {{'ALL' | translate}}
                </mat-button-toggle>
            </mat-button-toggle-group>


            <!--
                    <div fxFlexOrder.lt-lg="2" fxLayout="row nowrap">
                        <button [matTooltip]="'PRINT_LIST' | translate" (click)="printAptsList()" mat-icon-button fxFlex="32px">
                            <mat-icon fontSet="mdi" fontIcon="mdi-printer" style="color: #b72553"></mat-icon>
                        </button>

                        <button [matTooltip]="'EXPORT_EXCEL' | translate" (click)="exportXLS()" mat-icon-button fxFlex="32px">
                            <mat-icon fontIcon="mdi-file-excel" fontSet="mdi" style="color: #3c763d"></mat-icon>
                        </button>
                    </div>
            -->


        </div>

        <!--table-->
        <mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>

        <div fxFlex="calc(100vh - 204px)" style="overflow: auto;">
            <mat-table [@state]="resultsLength" [dataSource]="dataSource"
                       [trackBy]="trackById"
                       class="fire-table"
                       matSort
                       matSortActive="id"
                       matSortDirection="desc"
                       matSortDisableClear>

                <!--Table-->
                <ng-container *ngFor="let col of displayedColumns" [class.hidden]="col.hidden"
                              matColumnDef="{{col.label}}">
                    <mat-header-cell *matHeaderCellDef class="fire-header-cell"
                                     mat-sort-header="{{col.sortField}}"> {{col.header | translate}}
                    </mat-header-cell>

                    <ng-container *ngIf="col.header === 'DATE'">
                        <mat-cell *matCellDef="let row"
                                  class="fire-cell"> {{row[col.label] | date: 'dd/MM/yyyy'}}</mat-cell>
                    </ng-container>

                    <ng-container *ngIf="col.header === 'Source'">
                        <mat-cell *matCellDef="let row"
                                  class="fire-cell"><span [class.ft-badge]="row[col.label]"
                                                          [style.background]="sourceColors[row[col.label]]"
                                                          [style.color]="'white'">{{row[col.label]}}</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container *ngIf="col.header === 'APPOINTMENT_STATUS'">
                        <mat-cell *matCellDef="let row" class="fire-cell">
                            <mat-icon [fontIcon]="getOrderStatusIcon(row[col.label])"
                                      [matTooltip]="row[col.label] | translate"
                                      [style.color]="getOrderStatusColor(row[col.label])" fontSet="mdi"></mat-icon>
                        </mat-cell>
                    </ng-container>

                    <ng-container *ngIf="!['DATE', 'APPOINTMENT_STATUS', 'Source'].includes(col.header)">
                        <mat-cell *matCellDef="let row" class="fire-cell"> {{_get(row, col.value)}}</mat-cell>
                    </ng-container>
                </ng-container>

                <!--actions-->
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef class="fire-header-cell"></mat-header-cell>
                    <mat-cell *matCellDef="let row" class="fire-cell">
                        <button (click)="onScheduleAppointment(row)"
                                *ngIf="profile && profile.scheduleRequestedOrder !== 'NONE' && isEditable(row)"
                                [disabled]="row.appointmentStatus === 'planned'"
                                [matTooltip]="'SCHEDULE_EXAM' | translate" mat-icon-button>
                            <mat-icon fontIcon="mdi-calendar-check" fontSet="mdi"></mat-icon>
                        </button>
                        <button (click)="editAppointment(row)"
                                *ngIf="profile && profile.updateOrder !== 'NONE' && isEditable(row)"
                                [matTooltip]="'APPROVE' | translate" mat-icon-button>
                            <mat-icon fontIcon="mdi-calendar-edit" fontSet="mdi"></mat-icon>
                        </button>
                        <button (click)="onCancelAppointment(row)"
                                *ngIf="profile && profile.cancelOrder !== 'NONE' && isEditable(row)"
                                [matTooltip]="'CANCEL' | translate" mat-icon-button>
                            <mat-icon fontIcon="mdi-close-circle" fontSet="mdi"></mat-icon>
                        </button>
                        <button (click)="deleteApt(row)"
                                [matTooltip]="'DELETE' | translate" mat-icon-button>
                            <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!--header-->
                <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"
                                class="fire-header-row"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columnsToDisplay;" class="fire-row"></mat-row>

            </mat-table>

            <div [style.display]="resultsLength === 0 ? '' : 'none'" class="example-no-results">
                {{'NOFOUND' | translate }}
            </div>
        </div>

        <mat-paginator #paginator [length]="dataSource.data.length"
                       [pageIndex]="0"
                       [pageSizeOptions]="[10, 25, 50, 100]"
                       [pageSize]="defaultPageSize"
                       fxFlex="nogrow" showFirstLastButtons>
        </mat-paginator>
    </div>

</div>

