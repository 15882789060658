import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SettingService} from '../../setting.service';
import {SharedService} from '../../../shared';
import {assign} from 'lodash';
import * as moment from 'moment';
import {RoomEditComponent} from '../../room-setting/room-edit/room-edit.component';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-aet-edit',
    templateUrl: './aet-edit.component.html',
    styleUrls: ['./aet-edit.component.scss']
})
export class AetEditComponent implements OnInit {

    form: FormGroup;

    technicians: any[];
    modalities: any[];
    staffList: any[];
    rooms: any[] = [];

    color: string = '#7bd7f4';
    connected: Observable<boolean>;

    constructor(@Inject(MAT_DIALOG_DATA) public selectedAet: any, private dialogRef: MatDialogRef<AetEditComponent>,
                private settingService: SettingService,
                private sharedService: SharedService,
                private dialog: MatDialog,
                private fb: FormBuilder) {

        this.form = this.fb.group({
            id: '',
            name: '',
            title: '',
            hostname: '',
            enabled: '',
            installer: '',
            setAsDefault: '',
            manufacturer: '',
            hasMPPS: false,
            dateOfPurchase: [null],
            installationDate: [null],
            modality: this.fb.group({
                id: ['', Validators.required],
                value: ''
            }),
            defaultTechnician: this.fb.group({
                id: '',
                lastName: ''
            }),
            room: this.fb.group({
                id: ['', Validators.required],
                name: ''
            })
        });

        assign(this.selectedAet, {
            dateOfPurchase: new Date(moment(this.selectedAet.dateOfPurchase).format()),
            installationDate: new Date(moment(this.selectedAet.installationDate).format())
        })

    }

    testConnection(ip: string) {
        if (ip.length > 8)
            this.connected = this.sharedService.testConnection(ip);
    }

    getIcon(connected: boolean): string {
        if (this.form.get('hostname').value.length < 9) return 'mdi-emoticon-neutral-outline';
        else if (connected) return 'mdi-emoticon-happy';
        else return 'mdi-emoticon-sad';
    }

    getAetStatusColor(connected: any): string {
        if (this.form.get('hostname').value.length < 9) return 'orange';
        else if (connected) return 'green';
        else return 'red';
    }

    addRoom() {
        const dialogRef = this.dialog.open(RoomEditComponent);
        const instance = dialogRef.componentInstance;
        instance.selectedItem = {};

        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.rooms.push(res);
            }
        });
    }

    onSave(aet) {
        assign(aet, {
            color: this.color
        });

        this.settingService
            .saveAet(prepareAetToSave(aet))
            .subscribe(res => {

                assign(res, {
                    _room: res.room.name
                });

                this.dialogRef.close(res)
            });
    }

    getStaff() {
        this.sharedService.getStaff().subscribe(
            data => {
                this.staffList = data;
            }
        );
    }

    getTechnicians() {
        this.sharedService.getTechnicians().subscribe(
            data => {
                this.technicians = data;
            }
        );
    }

    getModalities() {
        this.sharedService.getModalities().subscribe(
            data => {
                this.modalities = data;
            }
        );
    }

    getRooms() {
        this.sharedService.getRooms().subscribe(
            data => {
                this.rooms = data;
            }
        );
    }

    ngOnInit() {
        this.getStaff();
        this.getTechnicians();
        this.getModalities();
        this.getRooms();

        this.form.patchValue(handleNullVals(this.selectedAet));

        this.color = this.selectedAet.color;

        this.testConnection(this.selectedAet!.hostname);
    }

}

export function prepareAetToSave(aet): any {
    return assign(aet, {
        modality: aet.modality && aet.modality.id !== '' ? aet.modality : null,
        room: aet.room && aet.room.id !== '' ? aet.room : null,
        defaultTechnician: aet.defaultTechnician.id === '' ? null : aet.defaultTechnician
    })
}

export function handleNullVals(aet): any {
    return assign(aet, {
        modality: aet.modality || {id: ''},
        room: aet.room || {id: ''},
        defaultTechnician: aet.defaultTechnician || {id: ''},
    })
}
