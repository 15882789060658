export const UNV_TABLE_CONF = [
    {
        'label': 'id',
        'header': 'ID',
        'value': 'id',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': true,
        'searchable': true,
        'required': true
    },{
        'label': 'startTime',
        'header': 'STARTTIME',
        'value': 'startTime',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    },{
        'label': 'endTime',
        'header': 'ENDTIME',
        'value': 'endTime',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    },{
        'label': 'startDate',
        'header': 'STARTDATE',
        'value': 'startDate',
        'type': 'date',
        'unit': 'date',
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    },{
        'label': 'endDate',
        'header': 'ENDDATE',
        'value': 'endDate',
        'type': 'date',
        'unit': 'date',
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    },{
        'label': 'description',
        'header': 'DESCRIPTION',
        'value': 'description',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    },{
        'label': 'unavailableReason',
        'header': 'UNAVAILABILITYREASON',
        'value': 'unavailabilityReason.value',
        'type': 'boolean',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    },{
        'label': 'otherReason',
        'header': 'OTHERREASON',
        'value': 'otherReason',
        'type': 'boolean',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }
];

export const UNV_HEADER_COLS = [
    // 'id',
    'startTime',
    'endTime',
    'startDate',
    'endDate',
    'description',
    'unavailableReason',
    'otherReason',
    'action'
];


