<div class="mat-table">
    <mat-toolbar class="sc-toolbar" color="primary">
        <div class="title">
            <span>{{'ROOMS' | translate}}</span>
        </div>

        <span class="fire-spacer"></span>

        <button [matTooltip]="'ADD_NEW' | translate" mat-button (click)="editRoom({})">
            <mat-icon class="add-icon-gradient" fontIcon="mdi-plus-box" fontSet="mdi"></mat-icon>
        </button>

    </mat-toolbar>

    <mat-table #table [dataSource]="dataSource" class="fire-table" matSort>

        <!--Table-->
        <ng-container *ngFor="let col of displayedColumns" cdkColumnDef="{{col.label}}" [class.hidden]="col.hidden">
            <mat-header-cell *cdkHeaderCellDef class="fire-header-cell" mat-sort-header> {{col.header | translate}}
            </mat-header-cell>


            <ng-container *ngIf="col.unit !== 'date'">
                <mat-cell *cdkCellDef="let row" class="fire-cell"> {{row[col.label]+'' | translate}}</mat-cell>
            </ng-container>

        </ng-container>

        <!--actions-->
        <ng-container cdkColumnDef="action">
            <mat-header-cell *cdkHeaderCellDef class="fire-header-cell"></mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="fire-cell">
                <button mat-icon-button (click)="$event.stopPropagation(); editRoom(row)">
                    <mat-icon class="edit-icon-gradient" fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
                </button>
                <button mat-icon-button (click)="$event.stopPropagation(); deleteRoom(row)">
                    <mat-icon class="delete-icon-gradient" fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <!--header-->
        <mat-header-row *cdkHeaderRowDef="cols; sticky:true" class="fire-header-row"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: cols;"
                 class="fire-row">
        </mat-row>
    </mat-table>

    <mat-paginator #paginator
                   [length]="dataSource.filteredData.length"
                   [pageIndex]="0"
                   [pageSize]="25"
                   [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
    </mat-paginator>
</div>
