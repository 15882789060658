<mat-toolbar color="primary">
    <mat-icon fontIcon="mdi-account-multiple-plus" fontSet="mdi"></mat-icon>&nbsp;
    {{ title | translate}}
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close>
        <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
    </button>
</mat-toolbar>
<div style="padding: 12px;" fxLayout="column">
    <mat-form-field appearance="standard">
        <mat-label>{{'RADIOLOGIST' | translate}}</mat-label>
        <mat-select [(ngModel)]="assignedRadiologist" [compareWith]="compareFn">
            <mat-option *ngFor="let radiologist of radiologists" [value]="radiologist">
                {{radiologist.fullName}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="title === 'DELEGATE_TASK'">
        <mat-label>{{'MESSAGE' | translate}}</mat-label>
        <textarea matInput [placeholder]="'CONTENT' | translate" [(ngModel)]="comment"></textarea>
    </mat-form-field>

</div>
<mat-dialog-actions align="end">
    <button *ngIf="reportingTask && reportingTask.secondPerformerName && title !== 'RE_ASSIGN_TASK'" mat-raised-button
            color="warn"
            (click)="removeAssignee()">
        {{'REMOVE_ASSIGNEE' | translate}}
    </button>
    <button color="accent" mat-dialog-close mat-raised-button>
        {{'CANCEL' | translate}}
    </button>
    <button (click)="saveRow()" [disabled]="!assignedRadiologist" color="primary" mat-raised-button>
        {{'SAVE' | translate}}
    </button>
</mat-dialog-actions>
