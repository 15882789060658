<div style="padding: 12px;">
    <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
        <div fxFlex>
            <ng-template #spinner>
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px"
                     style="height: 100%;width: 100%;">
                    <span style="font-size: 14px;font-weight: bold;color: #444444">{{'RETRIEVING_IMAGES' | translate}}</span>
                    <mat-spinner color="accent" diameter="100" strokeWidth="5"></mat-spinner>
                </div>
            </ng-template>
            <mat-accordion *ngIf="series.length != 0; else spinner" [displayMode]="'flat'" hideToggle>
                <mat-expansion-panel *ngFor="let file of filteredSeries"
                                     [expanded]="filteredSeries.indexOf(file) === 0">
                    <mat-expansion-panel-header [collapsedHeight]="'32px'" [expandedHeight]="'32px'">
                        <mat-panel-title [style.maxWidth.px]="20">
                            <mat-icon fontIcon="mdi-folder" fontSet="mdi"></mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                            {{file.name}}
                            <span fxFlex></span>
                            <button (click)="$event.stopPropagation();selectAll(file)" mat-icon-button>
                                <mat-icon [fontIcon]="isAllSelected(file) ? 'mdi-check-circle': 'mdi-circle-outline'"
                                          fontSet="mdi"></mat-icon>
                            </button>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <ng-template matExpansionPanelContent>
                        <div style="height: calc(100vh - 196px) !important; overflow: auto;">
                            <mat-grid-list [cols]="8" [gutterSize]="'1'" [rowHeight]="'90px'">
                                <mat-grid-tile
                                        *ngFor="let image of file['images']"
                                        [style.background]="'#000000'">
                                    <mat-grid-tile-header style="height: 25%;background: transparent;padding: 0;">
                                        <button (click)="$event.stopPropagation();selectImage(image)" mat-icon-button>
                                            <mat-icon
                                                    [fontIcon]="isSelected(image) ? 'mdi-check-circle': 'mdi-circle-outline'"
                                                    [style.color]="'cyan'"
                                                    fontSet="mdi"></mat-icon>
                                        </button>
                                    </mat-grid-tile-header>
                                    <img [alt]="image.name" [src]="image.path" style="height: inherit !important;">
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </ng-template>

                </mat-expansion-panel>

            </mat-accordion>
        </div>

        <div fxFlex fxLayout="column">
            <div fxLayout="row nowrap" fxLayoutAlign="space-between end" fxLayoutGap="8px">
                <mat-form-field fxFlex="64px" appearance="standard">
                    <mat-label>{{'LAYOUT' | translate}}</mat-label>
                    <mat-select [formControl]="form" [placeholder]="'LAYOUT' | translate">
                        <mat-option *ngFor="let layout of layouts" [value]="layout">{{layout}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="standard" fxFlex="100px">
                    <mat-label>{{'PRINT_COUNT' | translate}}</mat-label>
                    <input [(ngModel)]="numberOfCopies" inputmode="numeric" matInput
                           style="color: dodgerblue; font-weight: bold;text-align: right;" type="number">
                </mat-form-field>

                <span fxFlex></span>
                <div fxLayout="column">
                    <div class="label-option">{{'IMAGE_BACKGROUND' | translate}}</div>
                    <mat-radio-group [(ngModel)]="imageBackground" [ngModelOptions]="{standalone: true}" fxLayout="row"
                                     fxLayoutAlign="start end">
                        <mat-radio-button fxFlex *ngFor="let option of ['BLACK', 'WHITE']" [value]="option">
                            {{option | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <span fxFlex></span>
                <div fxLayout="column">
                    <div class="label-option">{{'PRINT_OPTION' | translate}}</div>
                    <mat-radio-group [(ngModel)]="printOption" [ngModelOptions]="{standalone: true}" fxLayout="row"
                                     fxLayoutAlign="start end">
                        <mat-radio-button *ngFor="let option of ['REPORT_ONLY', 'IMAGES_ONLY', 'BOTH', 'SIMPLE_A4']" [value]="option"
                                          fxFlex>
                            {{option | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <button [matTooltip]="'CLOSE' | translate" color="accent" mat-dialog-close mat-icon-button matTooltipPosition="left" style="position:absolute;top: 4px; right:8px;">
                    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </div>

            <div class="layout-container">
                <button (click)="linkOrUnlinkAll()" class="tool-btn link-btn" mat-mini-fab><mat-icon [fontIcon]="isAllLinked ? 'mdi-link': 'mdi-link-off'" fontSet="mdi"></mat-icon></button>

                <app-print-layout *ngFor="let array of imagesArray" [imageBackground]="imageBackground.toLowerCase()"
                                  [printLayout]="selectedLayout" [selectedImages]="array"></app-print-layout>
                <div class="total-pages"> {{'TOTAL_PAGES' | translate}} {{imagesArray.length}}</div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px"
                 style="position: absolute; z-index: 9; bottom: 24px;right: 24px;">
                <ng-container [ngSwitch]="(generalSetting | async)?.reportPrintMode">
                    <button *ngSwitchCase="'CUPS'" [matMenuTriggerFor]="printersMenu" color="primary" mat-mini-fab>
                        <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                    </button>
                    <button (click)="chromePrint()" *ngSwitchDefault color="primary" mat-mini-fab>
                        <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                    </button>
                </ng-container>
                <mat-menu #printersMenu="matMenu">
                    <button (click)="validate(printer['label'])" *ngFor="let printer of printers" mat-menu-item>
                        <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                        {{printer['label']}}
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>

