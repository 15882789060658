export interface PatientDetail {
    createdDate: Date;
    gender: string;
    maritalStatus: string;
    weight: number;
    height: number;
    imc: number;
    sc: number;
    age: number;
    country: string;
    city: string;
    confidential: boolean;
}

export class Patient {
    constructor(
        public demographic: Demographic = new Demographic(),
        public id?: number,
        public externalPatientID?: string,
        public firstName?: string,
        public lastName?: string,
        public email?: string,
        public phone?: string,
        public debiter:string = 'PATIENT',
        public confidentiality: any = {id: 1},
        public cin?: string,
        public title?: any,
        public insurance: Insurance = new Insurance(),
        public medicalHistory: MedicalHistory = new MedicalHistory(),
        public imageBase64?: any,
        public patientClass: any = {id:3},
        public consultingDoctor?: any,
        public address: Address = new Address(),
        public confidential: boolean = false,
        public nationalNumber: string = '',
        public admissionNumber: string = '',
        public createdDate?: any,
        public weight?: any,
        public height?: any,
        public imc?: any,
        public sc?: any,
        public fileElements?: any,
        public patientPhotoUUID?: any,
    ) {
    }
}

export class Address {
    constructor(
        public id?: number,
        public country?: string,
        public province?: string,
        public street?: string,
        public city?: string,
        public postalCode?: string) {
    }
}

export class Demographic {
    constructor(
        public id?: number,
        public gender?: any,
        public dateOfBirth?: any,
        public patientComments?: any,
        public confidentialityConstraintOnPatientData?: any,
        public maritalStatus?: any,
        public ambulatoryStatus?: any,
        public ethnicGroup?: any,
        public confidentiality: any = {id:1},
    ) {
    }
}

export class MedicalHistory {
    constructor(
        public id?: number,
        public medicalAlerts: any = '',
        public specialNeeds?: any,
        public additionalPatientHistory?: any,
        public allergies: string = '',
        public observations?: any,
        public patientState?: any,
        public dangerCode?: any,
    ) {
    }
}

export class Insurance {
    constructor(public id?: number,
                public code?: string,
                public name?: string,
                public relationWithInsured: string = 'HIMSELF',
                public organismId?: string,
                public organismName?: string,
                public conventionName?: string,
                public conventionId?: number,
                public pecName?: string,
                public organismPart?: number,
                public patientPart?: number,
                public insuredTitle?: string,
                public coverNumber?: string,
                public insuredFirstName?: string,
                public insuredLastName?: string,
                public address?: string,
                public deleted: boolean = false) {
    }
}

export class Organism {
    constructor(public id?: number,
                public code?: string,
                public name?: string,
                public fullName?: string,
                public defaultRefaundAmount?: number) {
    }
}
