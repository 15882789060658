<mat-toolbar style="border-radius: 4px 4px 0 0; font-size: 14px">
    {{'SMS_TEMPLATES' | translate}}
    <span fxFlex></span>
    <button (click)="editing=true" color="primary" mat-button>
        <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
        {{'SMS_TEMPLATE' | translate}}
    </button>
</mat-toolbar>
<div class="sms-main" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">

    <div class="sms-models-container" fxFlex fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="8px">

        <div #el (mouseenter)="el.classList.toggle('mat-elevation-z5')"
             (mouseleave)="el.classList.remove('mat-elevation-z5')"
             *ngFor="let sms of smsTemplates"
             class="sms-template" fxLayout="column">
            <h3>{{sms.title}}</h3>
            <div>{{sms.body}}</div>
            <span fxFlex></span>
            <div class="actions">
                <mat-icon *ngIf="sms.isDefault" [matTooltip]="'DEFAULT_SMS' | translate"
                          class="fav-icon-gradient"  fontIcon="mdi-star" fontSet="mdi"></mat-icon>
                <span fxFlex></span>
                <button (click)="editModel(sms)" color="primary" mat-icon-button>
                    <mat-icon class="edit-icon-gradient" fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
                </button>
                <button (click)="deleteModel(sms)" color="accent" mat-icon-button>
                    <mat-icon class="delete-icon-gradient" fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                </button>
            </div>
        </div>
        <ng-container *ngIf="smsTemplates.length < 1">
            <div class="create-template" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
                <button (click)="editing=true" color="primary" mat-button>
                    <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                    {{'SMS_TEMPLATE' | translate}}
                </button>
            </div>
        </ng-container>

    </div>
    <ng-container *ngIf="editing">
        <div [formGroup]="newModelForm" class="sms-edit-container mat-elevation-z3" fxFlex="360px" fxLayout="column">
            <h3 style="margin-top: 0;">{{'SMS_TEMPLATE' | translate}}</h3>
            <mat-form-field appearance="outline">
                <mat-label>{{'TITLE' | translate}}</mat-label>
                <input [placeholder]="'TITLE' | translate" formControlName="title" matInput type="text">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'MESSAGE' | translate}}</mat-label>
                <textarea [placeholder]="'MESSAGE' | translate" [rows]="40" formControlName="body" id="sms-body"
                          matInput
                          style="height: 100px"></textarea>
            </mat-form-field>

            <mat-slide-toggle [(ngModel)]="isDefault" [ngModelOptions]="{standalone:true}" color="primary"
                              labelPosition="after"
                              style="font-size: 12px; font-weight: bold;">
                {{'DEFAULT_SMS' | translate}}
            </mat-slide-toggle>

            <div [matTooltip]="'CLICK_TO_INSERT' | translate" class="variables">* Variables:
                <span (click)="copyVariable($event)">PATIENT_NAME</span> -
                <span (click)="copyVariable($event)">EXAM</span> -
                <span (click)="copyVariable($event)">DATE</span>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px" style="margin-top: 24px;">
                <button (click)="editing=false" color="accent" mat-button>{{'CANCEL' | translate}}</button>
                <button (click)="saveSmsModel(newModelForm.value)" [disabled]="newModelForm.invalid" color="primary"
                        mat-button>{{'SAVE' | translate}}</button>
            </div>
        </div>

    </ng-container>

</div>