<div fxLayout="column" fxLayoutAlign="space-between center" fxFill *ngIf="license">

    <img [width]="300" alt="Logo" src="../../assets/images/{{logo}}">

    <mat-divider class="info-divider"></mat-divider>

    <div class="license-title">{{'LICENSE_ATTRIBUTED_TO' | translate}}:</div>

    <mat-divider class="info-divider"></mat-divider>

    <div class="client">
        {{license.clientName}}
    </div>

    <mat-divider class="info-divider"></mat-divider>

    <div class="contacts">
        <mat-list>
            <h3 matSubheader style="height: 26px; color: #3e3e3e; font-weight: bold">Contacts</h3>
            <mat-list-item *ngFor="let contact of toList(license.contacts)"><a [href]="'tel:'+phone(contact)">{{contact}}</a></mat-list-item>
        </mat-list>
    </div>

    <span fxFlex></span>
    <mat-divider style="width: 100%;"></mat-divider>
    <div class="version">
        {{license.version}} &copy; {{license.company}} {{license.year}}
    </div>
</div>
