<mat-toolbar color="primary">
    <h3>{{'NORMAL_REPORT' | translate}}</h3>
    <span fxFlex></span>
    <span>{{reportingTask?.patientName}} - {{reportingTask?.procedureCodes}}</span>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1"><mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon></button>
</mat-toolbar>
<mat-dialog-content style="padding: 4px !important; min-height: calc(100vh - 92px);">
    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="4px">
        <div fxFlex="300px" *ngIf="templateModels">
            <mat-nav-list dense class="my-list">
                <mat-list-item *ngFor="let tm of templateModels" (click)="showTemplateModel(tm)"
                               style="height: 27px !important;">
                    {{ tm.name }}
                </mat-list-item>
            </mat-nav-list>
        </div>
        <div fxFlex>

        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button [matMenuTriggerFor]="printersMenu" color="primary" [matTooltip]="'PRINT' | translate"><mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon></button>
    <mat-menu #printersMenu="matMenu">
        <button *ngFor="let printer of printers" mat-menu-item (click)="saveAndPrint(printer['label'])">
            <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
            {{printer['label']}}
        </button>
    </mat-menu>
    <span fxFlex></span>
    <button mat-raised-button color="accent" [matTooltip]="'CLOSE' | translate" mat-dialog-close><mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon></button>
    <button mat-raised-button color="primary" [matTooltip]="'SAVE_AND_EXIT' | translate" (click)="saveAndExit()"><mat-icon fontSet="mdi" fontIcon="mdi-floppy"></mat-icon></button>
</mat-dialog-actions>

