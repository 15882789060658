export const ROOM_TABLE_CONF = [
    {
        'label': 'id',
        'header': 'ID',
        'value': 'id',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': true,
        'searchable': true,
        'required': true
    }, {
        'label': 'name',
        'header': 'NAME',
        'value': 'name',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }, {
        'label': 'examType',
        'header': 'EXAMTYPE',
        'value': 'examType',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }
];

export const ROOM_HEADER_COLS = [
    'name',
    'examType',
    'action'
];
