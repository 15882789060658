import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RendezVous} from '../../model';
import * as moment from 'moment';
import {assign} from 'lodash';
import {SchedulerService} from '../../scheduler/scheduler.service';
import * as $ from 'jquery';

@Component({
    selector: 'app-rdv-print',
    templateUrl: './rdv-print.component.html',
    styleUrls: ['./rdv-print.component.scss']
})
export class RdvPrintComponent {

    rdvForm: FormGroup;
    time: any = '10:00';
    public minDate = new Date();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private service: SchedulerService,
                private fb: FormBuilder,
                private dialogRef: MatDialogRef<RdvPrintComponent>) {
        this.rdvForm = this.fb.group(new RendezVous('', '', '', '', '', ''));

        this.rdvForm.patchValue(this.data);

        this.rdvForm.get('patientID').disable();
        this.rdvForm.get('patientName').disable();
    }

    printRdv(value) {

        let date = moment(value.date).format('YYYY-MM-DD') + ' ' + this.time;
        value = assign(value, {
            date: date,
            patientID: this.data.patientID,
            accessionNumber: this.data.accessionNumber,
            patientName: this.data.patientName,
        });

        this.service.printRdvForResult(value).subscribe(ok => {
            if (ok) this.showPreview(`/templates/rdv-result.html?v=${moment().format('YYYYMMDDHHmmssSSS')}`);
            else console.log('Can\'t print rdv');
            this.dialogRef.close(null);
        });
    }

    showPreview(url) {
        let $frame = $('<frame />').attr('src', url).css({position: 'absolute', top: '-9999px'});
        $frame.on('load', () => setTimeout(() => $frame.remove(), 0));
        $(document.body).append($frame);
    }

}
