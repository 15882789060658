<div [formGroup]="searchForm" fxLayout="row" fxLayoutAlign="space-between center" matDialogTitle
     style="font-size: 14px; padding: 4px 12px;margin: 0 !important; color: #050505;font-weight: 500;">
    <mat-form-field autofocus floatLabel="never" style="width: calc(100% - 24px);margin: 0 12px;">
        <input [placeholder]="'SEARCH' | translate" formControlName="searchKey" matInput>
    </mat-form-field>
    <button mat-icon-button matDialogClose tabindex="-1">
        <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
    </button>
</div>
<div matDialogContent style="height: 312px !important; min-width: 372px; padding: 0 !important;">

    <div [formGroup]="searchForm" fxLayout="row nowrap" fxLayoutAlign="center center">
        <button class="nav-btn nav-left" id="nav-left" mat-icon-button><mat-icon fontIcon="mdi-chevron-left" fontSet="mdi"></mat-icon></button>
        <mat-button-toggle-group aria-label="Exam type" formControlName="examType" id="toggle-group" multiple="true" name="examType">
            <mat-button-toggle *ngFor="let item of reasonForExams" [value]="item.value"
                               fxFlex>{{item.value}}</mat-button-toggle>
        </mat-button-toggle-group>
        <button class="nav-btn nav-right" id="nav-right" mat-icon-button><mat-icon fontIcon="mdi-chevron-right" fontSet="mdi"></mat-icon></button>
    </div>

    <div style="padding: 0; overflow: auto;height: 275px">
        <mat-list dense>
            <mat-list-item (click)="selectReport(item)" *ngFor="let item of dataSource" matRipple>
                <div fxFill fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
                    <div fxFlex style="font-size: 12px;">
                        <b>{{item.patientName}}</b><br>
                        <span style="font-size: 12px">{{item.procedureCode}}</span><br>
                        <!--                        <span>{{item.scheduledProcedureStepStartDateTime | date: 'dd/MM/yyyy HH:mm'}}</span>-->
                    </div>

                    <button (click)="$event.stopPropagation();openReportAndViewer(item)"
                            *ngIf="defaultViewer && item.imagesAvailables"
                            [matTooltip]="'OPEN_IN_VIEWER' | translate"
                            color="primary" mat-icon-button>
                        <mat-icon fontIcon="mdi-image" fontSet="mdi" style="color: #00bcd4"></mat-icon>
                    </button>

                    <button (click)="$event.stopPropagation();openReportAndViewer(item, false)"
                            *ngIf="defaultViewer && item.imagesAvailables"
                            [matTooltip]="'ADD_TO_VIEWER' | translate"
                            color="primary" mat-icon-button>
                        <mat-icon fontIcon="mdi-layers-plus" fontSet="mdi"></mat-icon>
                    </button>
                    <button (click)="$event.stopPropagation();addToWaitingList(item)"
                            [matTooltip]="'ADD_TO_WAITING_LIST' | translate"
                            mat-icon-button>
                        <mat-icon fontIcon="mdi-shape-circle-plus" fontSet="mdi" style="color: #E91E63;"></mat-icon>
                    </button>
                </div>
            </mat-list-item>
        </mat-list>
    </div>

</div>
