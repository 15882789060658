<div class="mat-table" fxFill fxLayout="column">
    <mat-toolbar class="sc-toolbar" color="primary" fxFlex="nogrow" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title">
            <mat-icon fontIcon="mdi-account" fontSet="mdi"></mat-icon>
            <span>{{'REFERRING_PHYSICIAN' | translate}}</span>
        </div>

        <button mat-dialog-close mat-icon-button>
            <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
        </button>

    </mat-toolbar>

    <mat-dialog-content fxLayout="column"
                        style=" padding: 0 !important;">
        <div fxLayout="row nowrap">
            <div fxLayout="column" style="overflow: hidden; min-height: calc(60vh - 80px) !important;">
                <div class="search-area" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px"
                     style="padding: 0 16px !important;">
                    <mat-form-field floatLabel="never">
                        <span matPrefix><mat-icon fontIcon="mdi-magnify" fontSet="mdi"></mat-icon> &nbsp;</span>
                        <input #filter matInput placeholder="{{'SEARCHING' | translate}}">
                    </mat-form-field>

                </div>

                <div fxFlex style="overflow: auto;">
                    <mat-table [@state]="dataSource.data.length"
                               [dataSource]="dataSource" [trackBy]="trackByLastName" matSort
                               matSortActive="lastName"
                               matSortDirection="desc">

                        <!--Table-->
                        <ng-container *ngFor="let col of displayedColumns" [class.hidden]="col.hidden"
                                      matColumnDef="{{col.label}}">
                            <mat-header-cell *matHeaderCellDef class="fire-header-cell"
                                             mat-sort-header> {{col.header | translate}}
                                <span *ngIf="col.unit !== 'date' && col.unit !== 'color'">{{col.unit}}</span>
                            </mat-header-cell>

                            <ng-container *ngIf="col.unit === 'date'">
                                <mat-cell *matCellDef="let row"
                                          class="fire-cell"> {{row[col.label] | date:'dd/MM/yyyy'}}</mat-cell>
                            </ng-container>
                            <ng-container *ngIf="col.unit !== 'date' && col.unit !== 'color'">
                                <mat-cell *matCellDef="let row" class="fire-cell"> {{row[col.label]}}</mat-cell>
                            </ng-container>
                            <ng-container *ngIf="col.unit === 'color'">
                                <mat-cell *matCellDef="let row" class="fire-cell">
                                    <mat-icon [ngStyle]="{'color': row[col.label]}" fontIcon="mdi-checkbox-blank-circle"
                                              fontSet="mdi"></mat-icon>
                                </mat-cell>
                            </ng-container>

                        </ng-container>

                        <!--actions-->
                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef class="fire-header-cell"></mat-header-cell>
                            <mat-cell *matCellDef="let row" class="fire-cell" fxLayout="row nowrap"
                                      fxLayoutAlign="end center">
                                <button (click)="$event.stopPropagation();editReferringPhysician(row)"
                                        [matTooltip]="'EDIT' | translate"
                                        mat-icon-button>
                                    <mat-icon fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>


                        <!--header-->
                        <mat-header-row *matHeaderRowDef="cols; sticky:true" class="fire-header-row"></mat-header-row>
                        <mat-row (click)="selectPhysician(row)"
                                 *matRowDef="let row; columns: cols;" class="fire-row">
                        </mat-row>
                    </mat-table>

                    <div [style.display]="dataSource.data.length === 0 ? '' : 'none'" class="example-no-results"
                         style="height: calc(60vh - 218px) !important;align-items: center">
                        <div *ngIf="isLoadingResults; else noItem">
                            <mat-spinner [diameter]="50" [strokeWidth]="3" color="accent"></mat-spinner>
                        </div>
                        <ng-template #noItem>
                            {{'NOITEMSFOUND' | translate}}
                        </ng-template>
                    </div>
                </div>
                <mat-paginator [length]="resultsLength"
                               [pageIndex]="0"
                               [pageSizeOptions]="[5, 10, 25, 50, 100]"
                               [pageSize]="25" showFirstLastButtons>
                </mat-paginator>
            </div>
            <div id="sidenav">

                <div [formGroup]="referringForm">

                    <div fxLayout="row" fxLayoutGap="4px">
                        <mat-form-field appearance="standard">
                            <mat-label>{{'LAST_NAME' | translate}}</mat-label>
                            <input matInput [placeholder]="'LAST_NAME' | translate" formControlName="lastName" required>
                        </mat-form-field>
                        <mat-form-field appearance="standard">
                            <mat-label>{{'FIRST_NAME' | translate}}</mat-label>
                            <input matInput [placeholder]="'FIRST_NAME' | translate" formControlName="firstName" required>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="4px">
                        <mat-form-field appearance="standard" formGroupName="title">
                            <mat-label>{{'TITLE' | translate}}</mat-label>
                            <mat-select [placeholder]="'TITLE' | translate" formControlName="id" tabindex="-1">
                                <mat-option *ngFor="let title of titles" [value]="title.id">{{title.value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="standard" formGroupName="speciality">
                            <mat-label>{{'SPECIALITY' | translate}}</mat-label>
                            <mat-select [placeholder]="'SPECIALITY' | translate" formControlName="id">
                                <mat-option *ngFor="let speciality of specialities" [value]="speciality.id">{{speciality.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <mat-form-field appearance="standard">
                        <mat-label>{{'CODE_PRO' | translate}}</mat-label>
                        <input [placeholder]="'CODE_PRO' | translate" formControlName="codeAnam" matInput>
                    </mat-form-field>

                    <div fxLayout="row" fxLayoutGap="4px">
                        <mat-form-field appearance="standard">
                            <mat-label>{{'PHONE' | translate}}</mat-label>
                            <input matInput [placeholder]="'PHONE' | translate" formControlName="phone">
                        </mat-form-field>
                        <mat-form-field appearance="standard">
                            <mat-label>{{'EMAIL' | translate}}</mat-label>
                            <input matInput [placeholder]="'EMAIL' | translate" formControlName="email">
                        </mat-form-field>
                    </div>

                    <mat-form-field appearance="standard" fxFlexFill>
                        <mat-label>{{'ADDRESS' | translate}}</mat-label>
                        <textarea matInput [placeholder]="'ADDRESS' | translate" formControlName="address"></textarea>
                    </mat-form-field>

                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="6px">
                        <button mat-button color="accent" (click)="toggleSidenav()">{{'CANCEL' | translate}}</button>
                        <button mat-button color="primary" [disabled]="referringForm.invalid" (click)="saveReferringPhysician(referringForm.value)">{{'SAVE' | translate}}</button>
                    </div>
                </div>

            </div>
        </div>


    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button color="accent" mat-raised-button matDialogClose>{{'CLOSE' | translate}}</button>
    </mat-dialog-actions>


</div>
