import * as moment from 'moment';

export class CalendarEvent {
    constructor(
        public id = 0,
        public title = 'No Title',
        public timeText = '',
        public start: any = moment().format('YYYY-MM-DD HH:mm'),
        public end: any = moment().add(1, 'hours').format('YYYY-MM-DD HH:mm'),
        public borderColor = '#915f7b',
        public backgroundColor = '#915f7b',
        public constraint = 'businessHours',
        public resourceId?: string,
        public isrId?: string,
        public spsId?: number,
        public rpId?: string,
        public patientId?: string,
        public patientID?: string,
        public patientName?: string,
        public resource?: string,
        public className?: string,
        public editable?: boolean,
        public startEditable?: string,
        public durationEditable?: string,
        public resourceEditable?: string,
        public rendering?: string,
        public overlap?: string,
        public color?: string,
        public textColor?: string,
        public examColor?: string,
        public orderStatus?: string,
        public performingPhysician?: string,
        public scheduledAETitle?: string,
        public billingStatus?: string,
        public urgent = false,
        public confidential = false,
        public spsStatus?: string
    ) {
    }
}

function getBillingColor(status): string {
    switch (status) {
        case 'EXEMPT':
            return '#b1aaaa';
        case 'NOT_PAID':
            return '#ff1c13';
        case 'PAID':
            return '#2d9338';
        case 'PAID_PARTIALLY':
            return '#ff913e';
        default:
            return '#454545';
    }
}

export function showPatientName(event: any): string {
    return !event.confidential || event.canViewConfData ? event.patientName : '**** ****';
}
