import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ReportingTask} from "../../model";
import {ReportingService} from "../../reporting/reporting.service";

@Component({
  selector: 'ft-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss']
})
export class ReportDialogComponent implements OnChanges {

  @Input('reportingTask') reportingTask: ReportingTask;
  @Output('onClose') onClose: EventEmitter<any> = new EventEmitter<any>();

  reportText: string[] = [];

  constructor(private _service: ReportingService) { }

  ngOnChanges(changes: SimpleChanges) {
    let {reportingTask} = changes;

    if (reportingTask.currentValue) {
      this._service.getReportText(reportingTask.currentValue.id).subscribe(data => this.reportText = data);
    }
  }

  close() {
    this.reportText = [];
    this.onClose.emit(true);
  }

}
