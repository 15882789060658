/**
 * Created by MBX on 7/18/2017.
 */

export const AV_TABLE_CONF = [
    {
        label: 'aet',
        header: 'AET',
        value: 'aet',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    },{
        label: 'staff',
        header: 'STAFF',
        value: 'staff',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    }, {
        label: 'date',
        header: 'DATE',
        value: 'date',
        type: 'string',
        unit: 'date',
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    }, {
        label: 'start',
        header: 'FROM_TIME',
        value: 'start',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    }, {
        label: 'end',
        header: 'TO_TIME',
        value: 'end',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    }, {
        label: 'duration',
        header: 'DURATION',
        value: 'duration',
        type: 'string',
        unit: null,
        defaultValue: '-',
        sortable: true,
        hidden: false,
        searchable: true,
        required: true
    }

];

export const AV_HEADER_COLS = [
    // 'aet',
    // 'staff',
    // 'date',
    'start',
    'end',
    // 'duration',
    'action'
];
