<mat-card [style.padding.px]="0" fxFill fxLayout="column">

    <div fxLayout="row nowrap" fxLayoutAlign="start stretch" style="min-height: 100%">

        <!--Menu-->
        <div class="ft-side-nav" fxFlex="260px" fxLayout="column">
            <div fxFlex fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px">
                <button (click)="selectView(view, 'EXAM', true)" *ngFor="let view of views['EXAM']"
                        [class.menu-item-selected]="currentState === 'EXAM' && view === currentView"
                        [ngStyle]="{display: hasPermission(view) ? 'block':'none'}"
                        mat-flat-button style="text-align:left;">
                    {{view | translate}}
                </button>
                <button (click)="selectedDataType = 'PAYMENTS'; selectView('DATE', 'PAYMENT', true)"
                        *ngIf="hasPermission('PAYMENT')" [class.menu-item-selected]="currentState === 'PAYMENT'"
                        mat-flat-button
                        style="text-align: left;">
                    {{'PAYMENTS' | translate}}
                </button>
                <button (click)="selectedDataType = 'PATIENTS'; selectView('ALL', 'PATIENT', true)"
                        *ngIf="hasPermission('PATIENT')" [class.menu-item-selected]="currentState === 'PATIENT'"
                        mat-flat-button
                        style="text-align:left;">
                    {{'PATIENTS' | translate}}
                </button>
            </div>
        </div>

        <!--container-->
        <div class="ft-area ft-no-shadow ft-no-margin" fxFlex fxLayout="column"
             style="padding: 8px;">
            <div style="margin-bottom: 8px"
                 fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px"
                 [formGroup]="queryForm">
                <ng-container *ngIf="currentState === 'PAYMENT' && hasPermission('PAYMENT')">
                    <mat-button-toggle-group #group2="matButtonToggleGroup" formControlName="filter2">
                        <mat-button-toggle value="USER">
                            {{'USER_ACCOUNT' | translate}}
                        </mat-button-toggle>
                        <mat-button-toggle value="BOX" checked>
                            {{'BOX' | translate}}
                        </mat-button-toggle>
                    </mat-button-toggle-group>


                    <ng-container *ngIf="group2.value === 'BOX'">
                        <mat-form-field appearance="standard">
                            <mat-label>{{'BOX' | translate}}</mat-label>
                            <mat-select [placeholder]="'BOX' | translate" formControlName="filter" tabindex="-1">
                                <mat-option *ngFor="let option of boxes"
                                            [value]="option.name">{{option.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="group2.value === 'USER'">
                        <mat-form-field appearance="standard">
                            <mat-label>{{'USER_ACCOUNT' | translate}}</mat-label>
                            <mat-select [placeholder]="'USER_ACCOUNT' | translate" formControlName="filter"
                                        tabindex="-1">
                                <mat-option *ngFor="let option of users"
                                            [value]="option.fullName">{{option.fullName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                </ng-container>

                <ng-container *ngIf="currentView === 'REFERRING_PHYSICIAN' && hasPermission(currentView)">
                    <mat-form-field appearance="standard">
                        <input matInput [placeholder]="'REFERRING_PHYSICIAN' | translate"
                               [formControl]="referringPhysicianControl"
                               [matAutocomplete]="refPhyList">
                        <mat-autocomplete #refPhyList="matAutocomplete"
                                          [autoActiveFirstOption]="true"
                                          (optionSelected)="onChangeRefPhy($event)">
                            <ng-container>
                                <mat-option *ngFor="let user of filteredReferringPhysicians"
                                            [value]="user">
                                    {{ user['fullName'] }}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                        <button (click)="clearInput($event)" mat-icon-button matSuffix>
                            <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                        </button>
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="currentView === 'PERFORMING_RADIOLOGIST' && hasPermission(currentView)">
                    <mat-form-field appearance="outline">
                        <mat-select (valueChange)="onChangeUser($event)"
                                    [placeholder]="'PERFORMING_PHYSICIAN' | translate">
                            <mat-option *ngFor="let physician of physicians"
                                        [value]="physician">{{physician.fullName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="currentView === 'TECHNICIANS' && hasPermission(currentView)">
                    <mat-form-field appearance="outline">
                        <mat-select (valueChange)="onChangeUser($event)"
                                    [placeholder]="'TECHNICIAN' | translate">
                            <mat-option *ngFor="let technician of technicians"
                                        [value]="technician">{{technician.fullName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>

                <mat-form-field appearance="outline" floatLabel="never" fxFlex="112px">
                    <input matInput [matDatepicker]="startPicker" formControlName="startDate"
                           placeholder="{{startDateLabel | translate}}">
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field *ngIf="currentState !== 'PAYMENT'" appearance="outline"
                                fxFlex="112px">
                    <input matInput [matDatepicker]="picker" formControlName="endDate"
                           placeholder="{{'TO' | translate}}">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>


                <mat-button-toggle-group *ngIf="currentState !== 'PAYMENT'"
                                         #group="matButtonToggleGroup"
                                         formControlName="period" (change)="updateDateRange(group.value)">
                    <mat-button-toggle value="TODAY">
                        {{'TODAY' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="YESTERDAY">
                        {{'YESTERDAY' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="1W">
                        {{'ONE_WEEK' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="1M">
                        {{'ONE_MONTH' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="6M">
                        {{'SIX_MONTHS' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="1Y">
                        {{'ONE_YEAR' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="OT">
                        {{'ALL' | translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>

                <mat-menu #exportMenu="matMenu" [overlapTrigger]="true">
                    <button (click)="exportData('EXCEL')" mat-menu-item>
                        <mat-icon [style.color]="'#009688'" fontIcon="mdi-microsoft-excel" fontSet="mdi"></mat-icon>
                        Excel
                    </button>
                    <button (click)="exportData('PDF')" [disabled]="true" mat-menu-item style="color: #a0a0a0">
                        <mat-icon [style.color]="'#a0a0a0'" fontIcon="mdi-file-pdf" fontSet="mdi"></mat-icon>
                        PDF
                    </button>
                </mat-menu>

                <button *ngIf="exportButtonVisible && hasPermission('EXPORT')" [disabled]="!currentPhysicianId"
                        [matMenuTriggerFor]="exportMenu"
                        class="export-btn" mat-button style="position:absolute;right: 12px;">
                    <mat-icon fontIcon="mdi-cloud-download" fontSet="mdi"></mat-icon>
                </button>
            </div>

            <ng-template ftState></ng-template>
        </div>

    </div>
</mat-card>
<ng-container *ngIf="downloading">
    <div fxLayout="row" fxLayoutAlign="center center"
         style="position:fixed;top: 0; left: 0;height: 100vh;width: 100vw; background: rgba(0, 0, 0, 0.4);">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px"
             style="height: 86px;width: 360px;border-radius: 8px;background: rgb(255 255 255);color: #585858;">
            <mat-spinner diameter="30" strokeWidth="2"></mat-spinner>
            <h3>{{'EXPORTING' | translate}}</h3>
        </div>
    </div>
</ng-container>
