import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, of, throwError} from "rxjs";
import {AuthService} from "../services/auth.service";
import {catchError, switchMap} from "rxjs/operators";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(public authService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let refreshToken = this.authService.getRefreshToken();

        if (this.authService.isLoggedIn()) {
            request = this._addToken(request, this.authService.getAccessToken());
        } else if(refreshToken && this.authService.refreshTokenIsValid()) {
            request = this._addToken(request, refreshToken);
        } else request = this._addToken(request, '');


        return next.handle(request).pipe(catchError(error => {
            if (refreshToken && [401, 403].includes(error.status)) {
                return this._handleError(request, next);
            } else {
                this.authService.logout().subscribe(_ => null);
                return throwError(error);
            }
        }));
    }

    private _addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private _handleError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.refreshToken().pipe(
            switchMap((tokens: any) => {
                return next.handle(this._addToken(request, tokens.access_token));
            }), catchError(err => {
                return throwError(err);
            }));
    }
}