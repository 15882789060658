<mat-toolbar class="dialog-toolbar">
  <mat-icon fontSet="mdi" fontIcon="mdi-credit-card"></mat-icon>
  <h3 mat-dialog-title>{{'BILLING_CODE' | translate}}</h3>
  <span fxFlex></span>
  <button mat-icon-button matDialogClose tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<mat-dialog-content>

  <form [formGroup]="billingCodeForm">

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{'CODE' | translate}}</mat-label>
      <input matInput placeholder="{{'CODE' | translate}}" formControlName="code" required>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{'PRICE' | translate}}</mat-label>
      <input matInput type="number" placeholder="{{'PRICE' | translate}}" formControlName="price">
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{'CODE_NGAP' | translate}}</mat-label>
      <input matInput placeholder="{{'CODE_NGAP' | translate}}" formControlName="description">
    </mat-form-field>

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="warn" mat-raised-button matDialogClose>{{'CANCEL' | translate}}</button>
  <button (click)="saveBillingCode(billingCodeForm.value)" [disabled]="billingCodeForm.invalid" color="primary" mat-raised-button>{{'SAVE' |translate}}
  </button>
</mat-dialog-actions>
