<mat-card class="card-view-parent" fxFill fxLayout="column" style="padding: 0;">
    <mat-card-content fxFlex style="overflow: hidden">
        <mat-toolbar class="sc-toolbar2" color="primary">

            <a mat-button routerLink="schedule-manager">
                <mat-icon class="toolbar-icon-gradient" fontIcon="mdi-calendar-text" fontSet="mdi"></mat-icon>
                {{ 'SCHEDULER' | translate }}
            </a>

            <a (click)="agendaActive = true" [queryParams]="{res: 'aet'}" mat-button
               routerLink="calendar">
                <mat-icon class="toolbar-icon-gradient-1" fontIcon="mdi-calendar-weekend" fontSet="mdi"></mat-icon>
                {{'CALENDAR' | translate}}
            </a>

            <a (click)="agendaActive = false;" *ngIf="profile && profile.searchExam !== 'NONE'" mat-button
               routerLink="external-appointments">
                <mat-icon class="toolbar-icon-gradient-2" fontIcon="mdi-calendar-arrow-left" fontSet="mdi"></mat-icon>
                {{'EXTERNAL_APPOINTMENTS' | translate}}
            </a>

    <!--        <a (click)="agendaActive = false;" *ngIf="profile && profile.searchExam !== 'NONE'" mat-button routerLink="az-exams">
                <mat-icon fontIcon="mdi-briefcase-clock" fontSet="mdi"></mat-icon>
                {{'AZ_EXAMS' | translate}}
            </a>-->
            <span fxFlex></span>

            <button (click)="searchAvailability()" *ngIf="false" mat-button>
                <mat-icon fontIcon="mdi-calendar-question" fontSet="mdi"></mat-icon>
                {{'AVAILABILITY' | translate}}
            </button>

            <button (click)="$event.stopPropagation();queryPacs()"
                    *ngIf="profile && profile.scheduleExam !== 'NONE'"
                    class="plus-no-outline"
                    mat-button>
                <mat-icon class="toolbar-icon-gradient" fontIcon="mdi-nas" fontSet="mdi"></mat-icon>
                {{'QUERY_PACS' | translate }}
            </button>

            <button (click)="addOrder()"
                    class="plus-no-outline"
                    mat-button>
                <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                {{'ADD_APPOINTMENT' | translate}}
            </button>

            <button (click)="$event.stopPropagation();createExamWithPayment()"
                    *ngIf="profile && profile.scheduleExam !== 'NONE'"
                    class="plus-no-outline toolbar-btn-gradient"
                    mat-button>
                <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                {{'NEW_EXAM' | translate}}
            </button>

        </mat-toolbar>

        <router-outlet></router-outlet>
    </mat-card-content>
</mat-card>
