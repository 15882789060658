import {Injectable} from '@angular/core';
import {ToolConf} from "../model";
import {Subject} from "rxjs";

declare var cornerstoneTools: any;

@Injectable({
    providedIn: 'root'
})
export class ToolService {

    public synchronizer = new cornerstoneTools.Synchronizer('cornerstonenewimage', cornerstoneTools.updateImageSynchronizer);
    public wwwcSynchronizer = new cornerstoneTools.Synchronizer('cornerstoneimagerendered', cornerstoneTools.wwwcSynchronizer);
    public panZoomSynchronizer = new cornerstoneTools.Synchronizer('cornerstoneimagerendered', cornerstoneTools.panZoomSynchronizer);

    public availableTools: ToolConf[] = [
/*        {
            name: 'ZoomMouseWheel',
            mode: 'active',
            class: cornerstoneTools.ZoomMouseWheelTool,
            options: {mouseButtonMask: 2},
        },*/
        {
            name: 'Wwwc',
            mode: 'active',
            class: cornerstoneTools.WwwcTool,
            synchronizer: this.wwwcSynchronizer,
            options: {mouseButtonMask: 1},
        },
        {
            name: 'Zoom',
            mode: 'active',
            class: cornerstoneTools.ZoomTool,
            options: {mouseButtonMask: 2},
        },
        {
            name: 'StackScrollMouseWheel',
            mode: 'active',
            class: cornerstoneTools.StackScrollMouseWheelTool,
            options: {mouseButtonMask: 3},
        },
        {
            name: 'Pan',
            mode: 'active',
            class: cornerstoneTools.PanTool,
            options: {mouseButtonMask: 4},
        }
    ];
    toolChanged: Subject<any> = new Subject<any>();

    constructor() {
    }

    activateRotateTool() {
        this.availableTools.push(        {
            name: 'Rotate',
            mode: 'active',
            class: cornerstoneTools.RotateTool,
            synchronizer: this.wwwcSynchronizer,
            options: {mouseButtonMask: 1},
        });

        this.toolChanged.next(true)
    }
}
