export class RendezVous {
    constructor(
        public patientID: string,
        public accessionNumber: string,
        public patientName: string,
        public date: string,
        public exam: string,
        public observation: string
    ) {
    }
}
