import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExternalViewerComponent} from "./external-viewer.component";
import {ViewerRoutingModule} from "./viewer-routing.module";
import { ViewerComponent } from './viewer/viewer.component';


@NgModule({
    declarations: [ExternalViewerComponent, ViewerComponent],
    imports: [
        CommonModule,
        ViewerRoutingModule
    ]
})
export class ViewerModule {
}
