/**
 * Created by MBX on 15/03/2021.
 */

export class CommandService {
    public c:string;
    public key:string;
    public userdata:string;
    constructor( k: string,  ud?:string) {
        this.c = 'forcesave';
        this.key = k;
        this.userdata = ud;
    }
}
