export const REF_PH_TABLE_CONF = [
    {
        'label': 'firstName',
        'header': 'FIRSTNAME',
        'value': 'firstName',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }, {
        'label': 'lastName',
        'header': 'LASTNAME',
        'value': 'lastName',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }, {
        'label': 'codeAnam',
        'header': 'CODE_PRO',
        'value': 'codeAnam',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }, {
        'label': 'address',
        'header': 'ADDRESS',
        'value': 'address',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }
];

export const REF_PH_HEADER_COLS = [
    'lastName',
    'firstName',
    'codeAnam',
    'address',
    'action'
];