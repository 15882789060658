<div class="mat-table">
    <mat-toolbar class="sc-toolbar" color="primary">
        <div class="title">
            <mat-icon fontSet="mdi" fontIcon="mdi-account-multiple"></mat-icon>
            <span>{{'USERS' | translate}}</span>
        </div>

        <span class="fire-spacer"></span>

        <button mat-button class="btn-top" (click)="editUser({})">
            <mat-icon class="add-icon-gradient" fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
            {{'ADD_NEW' | translate}}
        </button>

    </mat-toolbar>


    <mat-table #table [dataSource]="dataSource" class="fire-table" matSort>

        <!--Table-->
        <ng-container *ngFor="let col of displayedColumns" cdkColumnDef="{{col.label}}" [class.hidden]="col.hidden">
            <mat-header-cell *cdkHeaderCellDef class="fire-header-cell" mat-sort-header> {{col.header | translate}}
                <span *ngIf="col.unit !== 'date' && col.unit !== 'color'">{{col.unit}}</span>
            </mat-header-cell>

            <ng-container *ngIf="col.unit === 'date'">
                <mat-cell *cdkCellDef="let row" class="fire-cell"> {{row[col.label] | date:'dd/MM/yyyy'}}</mat-cell>
            </ng-container>
            <ng-container *ngIf="col.unit !== 'date' && col.unit !== 'color'">
                <mat-cell *cdkCellDef="let row" class="fire-cell"> {{row[col.label] | translate}}</mat-cell>
            </ng-container>
            <ng-container *ngIf="col.unit === 'color'">
                <mat-cell *cdkCellDef="let row" class="fire-cell">
                    <mat-icon [ngStyle]="{background: 'linear-gradient(45deg, black, '+row[col.label]+', white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}" fontSet="mdi"
                              fontIcon="mdi-checkbox-blank-circle"></mat-icon>
                </mat-cell>
            </ng-container>

        </ng-container>

        <!--actions-->
        <ng-container cdkColumnDef="action">
            <mat-header-cell *cdkHeaderCellDef class="fire-header-cell"></mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="fire-cell">
                <button mat-icon-button (click)="$event.stopPropagation(); editUser(row)">
                    <mat-icon class="edit-icon-gradient" fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
                </button>
                <button mat-icon-button [matTooltip]="'EDIT_UNAVAILABILITY' | translate" (click)="$event.stopPropagation(); editUserUnavailability(row)">
                    <mat-icon class="availability-icon-gradient" fontSet="mdi" fontIcon="mdi-clock-alert"></mat-icon>
                </button>
                <button mat-icon-button (click)="$event.stopPropagation(); deleteUser(row)">
                    <mat-icon class="delete-icon-gradient" fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <!--header-->
        <mat-header-row *cdkHeaderRowDef="cols; sticky:true" class="fire-header-row"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: cols;"
                class="fire-row">
        </mat-row>
    </mat-table>

    <mat-paginator #paginator
                  [length]="dataSource.filteredData.length"
                  [pageIndex]="0"
                  [pageSize]="50"
                  [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons>
    </mat-paginator>
</div>
