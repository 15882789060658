import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';

export class FtPaginatorI18n {
    constructor(private _translate: TranslateService) {
    }

    public getPaginatorIntl(): MatPaginatorIntl {
        const paginatorConfig = new MatPaginatorIntl();

        paginatorConfig.itemsPerPageLabel = this._translate.instant('generic_table.items_per_page_label');
        paginatorConfig.nextPageLabel = this._translate.instant('generic_table.next_page_label');
        paginatorConfig.previousPageLabel = this._translate.instant('generic_table.previous_page_label');
        paginatorConfig.firstPageLabel = this._translate.instant('generic_table.first_page_label');
        paginatorConfig.lastPageLabel = this._translate.instant('generic_table.last_page_label');
        paginatorConfig.getRangeLabel = this._getRangeLabel.bind(this);

        return paginatorConfig;
    }

    private _getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0 || pageSize === 0) {
            return this._translate.instant('generic_table.range_page_label_1', {length});
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        return this._translate.instant(
            'generic_table.range_page_label_2', {
                startIndex: startIndex + 1, endIndex, length
            }
        );
    }
}
