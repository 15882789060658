import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DOCUMENT} from "@angular/common";
import {mapTo, tap} from "rxjs/operators";
import {get} from 'lodash';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {

    public appConfig: any = {};

    constructor(private _http: HttpClient, @Inject(DOCUMENT) private _document: any,
                private _translate: TranslateService,
                private _router: Router) {
    }

    get appLocale(): any {
        return get(this.appConfig, 'locale') || 'fr_FR';
    }

    get flexMonsterLicense(): any {
        return get(this.appConfig, 'flexMonsterLicense') || '';
    }

    get appLang(): any {
        return get(this.appConfig, 'lang') || 'fr';
    }

    get isRemoteEyeViewer(): any {
        return get(this.appConfig, 'remoteEyeViewer') || false;
    }

    get remoteEyeUsername(): any {
        return get(this.appConfig, 'remoteEyeUsername') || 'admin';
    }

    get remoteEyePassword(): any {
        return get(this.appConfig, 'remoteEyePassword') || 'admin';
    }

    get displayDateInput(): string {
        return get(this.appConfig, 'displayDateInput') || 'L';
    }

    get dateFormat(): string {
        return get(this.appConfig, 'dateFormat') || 'dd/MM/yyyy';
    }

    get currencyFormat(): string {
        return get(this.appConfig, 'currencyFormat') || 'DH';
    }

    get numberFormat(): string {
        return get(this.appConfig, 'numberFormat') || '0,0';
    }

    get syngoviaActivated(): boolean {
        return get(this.appConfig, 'syngoviaActivated') || false;
    }

    get dateTimeFormat(): string {
        return get(this.appConfig, 'dateTimeFormat') || 'dd/MM/yyyy HH:mm';
    }

    get momentDateFormat(): string {
        return get(this.appConfig, 'momentDateFormat') || 'DD/MM/YYYY';
    }

    get momentDateTimeFormat(): string {
        return get(this.appConfig, 'momentDateTimeFormat') || 'DD/MM/YYYY HH:mm';
    }

    load(url: string): Promise<boolean> {
        return this._http.get(url)
            .pipe(
                tap(data => {
                    this.appConfig = data;
                    this._document.documentElement.setAttribute('lang', this.appLang);
                    this._translate.addLangs(['en', 'fr']);
                    this._translate.setDefaultLang('fr');
                    this._translate.use(this.appLang);
                }),
                mapTo(true),
            ).toPromise();
    }
}
