import {Pipe, PipeTransform} from '@angular/core';
import {sumBy} from "lodash";

@Pipe({
    name: 'sumWorkItemBy'
})
export class SumWorkItemByPipe implements PipeTransform {
    transform(row: any, ...field: any[]): number {
        let [label] = field;
        switch (label) {
            case 'totalAmount':
                return sumBy(row.workflowItems, 'totalAmount') - sumBy(row.workflowItems, 'discount');
            case 'globalDiscount':
                return row[label];
            case 'leftAmount':
                return sumBy(row.workflowItems, 'totalAmount') - sumBy(row.workflowItems, 'paidAmount') - sumBy(row.workflowItems, 'discount') - row['globalDiscount'];
        }
        return sumBy(row.workflowItems, label)
    }
}
