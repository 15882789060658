import {ContractField} from './contract-field';

export class StaffContract {
constructor(public id?: string,
            public name?: string,
            public fee?: number,
            public feeType: string = 'EXAM',
            public calculationBase: string = 'EXAM_PRICE',
            public partPrice?: number,
            public partPercentage?: number,
            public partType: string = 'PERCENTAGE',
            public minPrice?: number,
            public maxPrice?: number,
            public examTypes: ContractField[] = [],
            public procedureCodes: ContractField[] = []) {}
}
