<div fxLayout="column" style="height: 100%">
    <mat-toolbar fxFlex="nogrow" class="sc-toolbar" color="primary">
        <div class="title">{{'IMAGINGCENTERDETAILS' | translate}}</div>
    </mat-toolbar>

    <div class="padd" fxFlex="grow" fxLayout="column" fxFlexAlign="space-between none" style="height: 100%">


            <div fxFlex="grow" style="height: 100%" [formGroup]="form">
                <div fxLayout="row wrap" fxLayoutGap="6px">
                    <div fxFlex class="padded">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{'NAME' | translate}}</mat-label>
                            <textarea class="textarea-long" matInput placeholder="{{'NAME' | translate}}"
                                      formControlName="name"></textarea>
                        </mat-form-field>
                    </div>
                    <div *ngIf="arabic" class="padded" fxFlex>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label dir="rtl">{{'إسم المركز أو الوحدة الاستشفائية'}}</mat-label>
                            <textarea class="textarea-long" matInput placeholder="إسم المركز أو الوحدة الاستشفائية"
                                      dir="rtl"
                                      formControlName="arabicName"></textarea>
                        </mat-form-field>
                    </div>
                </div>


                <div fxLayout="row wrap">
                    <div fxFlex class="padded">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{'PHONE' | translate}}</mat-label>
                            <input matInput placeholder="{{'PHONE' | translate}}" formControlName="phone"
                            >
                        </mat-form-field>
                    </div>
                    <div fxFlex class="padded">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{'FAX' | translate}}</mat-label>
                            <input matInput placeholder="{{'FAX' | translate}}" formControlName="fax">
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row wrap">
                    <div fxFlex class="padded">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{'EMAIL' | translate}}</mat-label>
                            <input matInput placeholder="{{'EMAIL' | translate}}" formControlName="email"
                            >
                        </mat-form-field>
                    </div>
                    <div fxFlex class="padded">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{'WEBSITE' | translate}}</mat-label>
                            <input matInput placeholder="{{'WEBSITE' | translate}}" formControlName="website"
                            >
                        </mat-form-field>
                    </div>


                </div>

                <div class="padded">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{'ADDRESS' | translate}}</mat-label>
                        <textarea matInput placeholder="{{'ADDRESS' | translate}}" formControlName="address"></textarea>
                    </mat-form-field>
                </div>

                <fieldset fxLayout="column">
                    <legend>{{'GMAIL_SETTING' | translate}}</legend>

                    <mat-form-field>
                        <mat-label>{{'EMAIL' | translate}}</mat-label>
                        <input [placeholder]="'EMAIL' | translate" formControlName="communicatingEmail" matInput>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'GMAIL_PASSWORD' | translate}}</mat-label>
                        <input [placeholder]="'GMAIL_PASSWORD' | translate" formControlName="gmailPassword" matInput
                               type="password">
                    </mat-form-field>
                </fieldset>

                <fieldset fxLayout="column">
                    <legend>{{'SMS_SETTING' | translate}}</legend>

                    <mat-form-field>
                        <mat-label>{{'OADC' | translate}}</mat-label>
                        <input [placeholder]="'OADC' | translate" formControlName="oadc" matInput>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'USERNAME' | translate}}</mat-label>
                        <input [placeholder]="'USERNAME' | translate" formControlName="smsUsername" matInput>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'ACCOUNT_PASSWORD' | translate}}</mat-label>
                        <input [placeholder]="'ACCOUNT_PASSWORD' | translate" formControlName="smsPassword" matInput
                               type="password">
                    </mat-form-field>
                </fieldset>

            </div>

        <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="end none" fxLayoutGap="4px" class="btn-actions">
            <button color="accent" mat-raised-button>{{'CANCEL' | translate}}</button>
            <button (click)="onSave(form.value)" [disabled]="!form.valid" color="primary" mat-raised-button>{{'SAVE' |
                    translate}}
                </button>
            </div>

    </div>

</div>
