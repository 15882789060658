<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
    <h3 mat-dialog-title>{{'PRINT_RDV' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content style="padding: 12px;">

    <div [formGroup]="rdvForm" fxLayout="column">

        <mat-form-field appearance="standard" class="full-width">
            <mat-label>{{'PATIENT_ID' | translate}}</mat-label>
            <input matInput [placeholder]="'PATIENT_ID' | translate" formControlName="patientID">
        </mat-form-field>

        <mat-form-field appearance="standard" class="full-width">
            <mat-label>{{'PATIENT_NAME' | translate}}</mat-label>
            <input matInput [placeholder]="'PATIENT_NAME' | translate" formControlName="patientName">
        </mat-form-field>

        <mat-form-field appearance="standard" class="full-width">
            <mat-label>{{'REASON_FOR_EXAM' | translate}}</mat-label>
            <textarea matInput [placeholder]="'REASON_FOR_EXAM' | translate" formControlName="exam"></textarea>
        </mat-form-field>

        <div fxLayout="row">
            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'DATE' | translate}}</mat-label>
                <input matInput [matDatepicker]="picker" [min]="minDate" [placeholder]="'DATE' | translate"
                       formControlName="date" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex="100px" style="padding-right: 4px;">
                <mat-label>{{'TIME' | translate}}</mat-label>
                <input [placeholder]="'TIME' | translate" matInput type="time" required
                       [(ngModel)]="time"
                       [ngModelOptions]="{standalone: true}">
            </mat-form-field>
        </div>

        <mat-form-field appearance="standard" class="full-width">
            <mat-label>{{'OBSERVATION' | translate}}</mat-label>
            <textarea matInput [placeholder]="'OBSERVATION' | translate" formControlName="observation"></textarea>
        </mat-form-field>

    </div>


</mat-dialog-content>

<mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="accent" mat-raised-button>{{'CANCEL' | translate}}</button>
    <button (click)="printRdv(rdvForm.value)" [disabled]="rdvForm.invalid" color="primary" mat-raised-button>
        {{'PRINT_RDV' | translate}}
    </button>
</mat-dialog-actions>
