export const PROFILE_TABLE_CONF = [
    {
        'label': 'id',
        'header': 'ID',
        'value': 'id',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': true,
        'searchable': true,
        'required': true
    }, {
        'label': 'name',
        'header': 'PROFILE',
        'value': 'name',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }, {
        'label': 'description',
        'header': 'DESCRIPTION',
        'value': 'description',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }, {
        'label': 'defaultRoute',
        'header': 'DEFAULT_ROUTE',
        'value': 'defaultRoute',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }
];

export const PROFILE_HEADER_COLS = [
    'name',
    'description',
    'defaultRoute',
    'action'
];

export const PATIENT_FIELDS = [
    'createPatient',
    'updatePatient',
    'deletePatient',
    'exportPatient',
    'downloadPatientFiles'
];

export const SCHEDULER_FIELDS = [
    'createOrder',
    'updateOrder',
    'cancelOrder',
    'scheduleRequestedOrder',
    'scheduleExam',
    'searchExam',
    'authorizeExam',
    'viewRequestedOrders',
    'editExam',
    'viewOrdersInProgress',
    'printExamsList',
    'startExam',
    'finishExam',
    'exitPatient',
    'createRdvForResult',
    'printRdvForExam',
    'viewPatientFolderCalendar',
    'viewReports',
    'completeExam',
    'sendSms',
];

export const REPORTING_FIELDS = [
    'editReport',
    'editSignedReport',
    'createReport',
    'dictateReport',
    'transcribeReport',
    'verifyReport',
    'signReport',
    'compareReport',
    'reviewReport',
    'exportReport',
    'deleteReport',
    'printReport',
    'shareReport',
    'launchViewer',
    'viewImages'
];

export const WORKFLOW_FIELDS = [
    'viewPatientFolder',
    'viewPatientReport',
    'viewPatientStudies',
    'createPrescription',
    'manageExam'
];

export const SETTING_FIELDS = [
    'managePathologies',
    'manageDictionaries',
    'manageUsers',
    'manageAETs',
    'manageModalities',
    'manageNetwork',
    'manageProfile',
    'manageProcedureCodes',
    'manageCenter',
    'manageReportTemplates',
    'manageOrderPriorities',
    'manageExamStatus',
    'manageCalendarSetting',
    'manageHolidays',
    'manageRooms'
];

export const STATISTIC_FIELDS = [
    'exportStatistic', 'viewStatistic',
    'showPivotTable',
    'showPerformingPhysicianStats',
    'showTechnicianStats',
    'showAetStats',
    'showPaymentStats',
    'showExamTypeStats',
    'showPatientStats',
    'showReferringPhysicianStats',
];

export const MODULES = [
    {name: 'SCHEDULER_LABEL', module: 'schedulerModule', fields: SCHEDULER_FIELDS},
    {name: 'REPORTING_LABEL', module: 'reportingModule', fields: REPORTING_FIELDS},
    {name: 'SETTING_LABEL', module: 'settingModule', fields: SETTING_FIELDS},
    {name: 'PATIENT_LABEL', module: 'patientModule', fields: PATIENT_FIELDS},
    {name: 'WORKFLOW_LABEL', module: 'workflowModule', fields: WORKFLOW_FIELDS},
    {name: 'STATISTIC_LABEL', module: 'statisticModule', fields: STATISTIC_FIELDS},
    {name: 'Logs', module: 'loggingModule', fields: []},
];
