<div class="mat-table card-view" fxFill fxLayout="column">
    <mat-toolbar class="sc-toolbar2" color="primary" fxLayout="row" fxLayoutGap="12px"
                 fxLayoutAlign="start center">
        <div class="title">
            <mat-icon class="wf-icon" fontIcon="mdi-clipboard-flow" fontSet="mdi"></mat-icon>
            <button [matMenuTriggerFor]="dataMenu" class="view-button" mat-flat-button
                    [style.lineHeight.px]="28">{{selectedView.getValue().name | translate}}</button>
            <button (click)="addNewView()" [style.fontSize.px]="14" mat-button>
                <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                {{'NEW_TABLE_VIEW' | translate}}
            </button>
            <mat-slide-toggle style="width: 180px" *ngIf="hiddenUser()" (toggleChange)="hideForced = !hideForced" [checked]="hideForced">
                {{'FORCE_BILLED' | translate}}
            </mat-slide-toggle>
        </div>

        <span fxFlex></span>
        <span [style.fontSize.px]="14">{{currentDate}}</span>
        <span fxFlex></span>

        <button (click)="createExamWithPayment()" *ngIf="profile['scheduleExam'] !== 'NONE'" mat-button class="toolbar-btn-gradient">
            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
            {{'NEW_EXAM' | translate}}
        </button>

        <button (click)="queryPacs()" mat-button>
            <mat-icon class="toolbar-icon-gradient" fontSet="mdi" fontIcon="mdi-nas"></mat-icon>
            {{'QUERY_PACS' | translate}}
        </button>

        <button (click)="exportExcel()" mat-button>
            <mat-icon class="toolbar-icon-gradient-1" fontSet="mdi" fontIcon="mdi-file-excel"></mat-icon>
            {{'EXPORT_EXCEL' | translate}}
        </button>

        <mat-menu #dataMenu="matMenu" [overlapTrigger]="true">
            <div mat-menu-item *ngFor="let view of views" (click)="selectedView.next(view)" fxLayout="row"
                 fxLayoutAlign="space-between center">
                <span fxFlex>{{view.name | translate}}</span>

                <ng-container *ngIf="view.id !== 0">
                    <mat-icon [style.marginLeft.px]="36" (click)="editView(view)" [style.color]="'#607d8b'"
                              fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
                    <mat-icon style="margin-right: -4px !important; margin-left: 0 !important;"
                              (click)="deleteView(view)" [style.color]="'#f44336'" fontIcon="mdi-delete"
                              fontSet="mdi"></mat-icon>
                </ng-container>
            </div>
        </mat-menu>

    </mat-toolbar>

    <div class="search-area" fxLayout="row wrap" fxLayoutAlign="end end"
         [formGroup]="filterForm"
         fxLayoutGap="4px">
        <mat-form-field floatLabel="never" fxFlex>
            <span matPrefix><mat-icon fontIcon="mdi-magnify" fontSet="mdi" class="prefix-icon" style="top: -3px;"
                                      tabindex="-1"></mat-icon>&nbsp;
            </span>
            <input class="filter-field" matInput #filter [placeholder]="'SEARCH' | translate" [autofocus]="true"
                   formControlName="key">
        </mat-form-field>

        <span fxFlex></span>


        <div fxLayout="column">
            <span class="label-option">{{'MODALITY' | translate}}</span>
            <mat-button-toggle-group aria-label="modality" formControlName="modality" multiple
                                     name="modality">
                <mat-button-toggle *ngFor="let modality of modalities"
                                   [value]="modality">
                    {{modality}}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>


        <div fxLayout="column">
            <span class="label-option">{{'REPORT_STATUS' | translate}}</span>
            <mat-button-toggle-group aria-label="Report status" formControlName="reportStatus" multiple
                                     name="reportStatus">
                <mat-button-toggle *ngFor="let rule of columnsFormattingRules | filterBy: 'REPORTING_STATUS'"
                                   [matTooltip]="rule.primaryFormatValue | translate" [value]="rule.primaryFormatValue">
                    <ng-container [ngSwitch]="rule.formattingStyle?.displayMode">
                        <ng-container *ngSwitchCase="'ICON'">
                            <mat-icon [fontIcon]="rule.formattingStyle?.icon"
                                      [ngStyle]="{background: 'linear-gradient(45deg, black, '+rule.formattingStyle?.color+', white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}" fontSet="mdi"></mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <span [style.color]="rule.formattingStyle?.color">{{rule.primaryFormatValue | translate}}</span>
                        </ng-container>
                    </ng-container>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div fxLayout="column">
            <span class="label-option">{{'PATIENT_STATUS' | translate}}</span>
            <mat-button-toggle-group aria-label="patient status" formControlName="patientStatus" multiple
                                     name="patientStatus">
                <mat-button-toggle *ngFor="let rule of columnsFormattingRules | filterBy: 'PATIENT_STATUS'"
                                   [matTooltip]="rule.primaryFormatValue | translate" [value]="rule.primaryFormatValue">
                    <ng-container [ngSwitch]="rule.formattingStyle?.displayMode">
                        <ng-container *ngSwitchCase="'ICON'">
                            <mat-icon [fontIcon]="rule.formattingStyle?.icon"
                                      [ngStyle]="{background: 'linear-gradient(45deg, black, '+rule.formattingStyle?.color+', white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}" fontSet="mdi"></mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <span [style.color]="rule.formattingStyle?.color">{{rule.primaryFormatValue | translate}}</span>
                        </ng-container>
                    </ng-container>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div *ngIf="generalSetting && generalSetting.billingRequired" fxLayout="column">
            <span class="label-option">{{'PAYMENT_STATUS' | translate}}</span>
            <mat-button-toggle-group aria-label="payment status" formControlName="paymentStatus" multiple
                                     name="paymentStatus">
                <mat-button-toggle *ngFor="let rule of columnsFormattingRules | filterBy: 'PAYMENT_STATUS'"
                                   [matTooltip]="rule.primaryFormatValue | translate" [value]="rule.primaryFormatValue">
                    <ng-container [ngSwitch]="rule.formattingStyle?.displayMode">
                        <ng-container *ngSwitchCase="'ICON'">
                            <mat-icon [fontIcon]="rule.formattingStyle?.icon"
                                      [ngStyle]="{background: 'linear-gradient(45deg, black, '+rule.formattingStyle?.color+', white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}" fontSet="mdi"></mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <span [style.color]="rule.formattingStyle?.color">{{rule.primaryFormatValue | translate}}</span>
                        </ng-container>
                    </ng-container>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>


        <div fxLayout="row nowrap" fxLayoutAlign="start end" fxLayoutGap="4px" class="date-range"
             *ngIf="group.value === 'OT'">
            <mat-form-field>
                <input matInput [matDatepicker]="startDate" [placeholder]="'FROM' | translate"
                       formControlName="startDate" (dateInput)="changePeriod()">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate (closed)="changePeriod()"></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <input matInput [matDatepicker]="endDate" [placeholder]="'TO' | translate" formControlName="endDate"
                       (dateInput)="changePeriod()">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate (closed)="changePeriod()"></mat-datepicker>
            </mat-form-field>
        </div>

        <div fxLayout="column">
            <span class="label-option">{{'PERIOD' | translate}}</span>
            <mat-button-toggle-group #group="matButtonToggleGroup" (change)="changeRange($event)" aria-label="Period"
                                     formControlName="period" name="period">
                <mat-button-toggle value="TODAY">
                    {{'TODAY' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="YESTERDAY">
                    {{'YESTERDAY' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="3D">
                    {{'THREE_DAYS' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="1W">
                    {{'ONE_WEEK' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="1M">
                    {{'ONE_MONTH' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="3M">
                    {{'THREE_MONTHS' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="OT">
                    {{'ALL' | translate}}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <button [matMenuTriggerFor]="menu" [matTooltip]="'TABLE_COLUMNS' | translate" mat-icon-button
                matTooltipPosition="left">
            <mat-icon class="table-custom-column-icon" fontIcon="mdi-table-cog" fontSet="mdi"></mat-icon>
        </button>
    </div>

    <mat-menu #menu="matMenu" [overlapTrigger]="true" class="columns-menu">
        <div cdkDropList class="ft-drag-list" (cdkDropListDropped)="drop($event)">
            <button class="ft-drag-box" mat-menu-item *ngFor="let item of availableColumns"
                    (click)="$event.stopPropagation();toggleColumn(item)" cdkDrag>
                <mat-icon style="color: #1565c0;" fontSet="mdi"
                          fontIcon="{{item.hidden ? 'mdi-checkbox-blank-circle-outline': 'mdi-check-circle'}}"></mat-icon>
                {{item.header | translate}}
            </button>
        </div>
    </mat-menu>

    <mat-menu #menu2="matMenu" [overlapTrigger]="true">
        <div cdkDropList class="ft-drag-list" (cdkDropListDropped)="drop2($event)">
            <button class="ft-drag-box" mat-menu-item *ngFor="let column of examsColumns"
                    (click)="$event.stopPropagation();toggleColumn2(column)" cdkDrag>
                <mat-icon style="color: #1565c0;"
                          fontSet="mdi"
                          fontIcon="{{column.hidden ? 'mdi-checkbox-blank-circle-outline': 'mdi-check-circle'}}"></mat-icon>
                {{column.header | translate}}
            </button>
        </div>
    </mat-menu>

    <mat-drawer-container [hasBackdrop]="false" class="scheduler-container" fxFlex="calc(100vh - 178px)">
        <mat-drawer #drawer [style.marginTop.px]="-1" [style.width.px]="320" mode="over" position="end">
            <table-formatter (close)="drawer.toggle()" (onSave)="onSaveTableConfig($event)"
                             [tableConfig]="workflowTableConfig"></table-formatter>
        </mat-drawer>
        <mat-drawer-content>
            <div fxLayout="column" style="width: 100%; height: 100%;">
                <div style="overflow: auto; height: calc(100vh - 202px)">
                    <mat-table [@state]="resultsLength" [dataSource]="dataSource"
                               [matSortActive]="sorting.active"
                               [matSortDirection]="sorting.direction"
                               [trackBy]="trackById"
                               class="fire-table expandable-rows-table"
                               matSort
                               matSortDisableClear
                               multiTemplateDataRows>

                        <!-- Nested row-->
                        <ng-container matColumnDef="expandedRow">
                            <mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplay.length">
                                <div [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'"
                                     class="example-element-detail">
                                    <ng-container *ngIf="expandedElement">

                                        <table class="nested-table">
                                            <thead>
                                            <tr>
                                                <th *ngFor="let col of examsColumnsToDisplay">{{col.header | translate}}</th>
                                                <th>
                                                    <button [matMenuTriggerFor]="menu2" [style.float]="'right'"
                                                            mat-icon-button>
                                                        <mat-icon class="menu-btn" fontIcon="mdi-dots-vertical"
                                                                  fontSet="mdi"></mat-icon>
                                                    </button>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let el of expandedElement.workflowItems">
                                                <ng-container *ngFor="let col of examsColumnsToDisplay">
                                                    <td *ngIf="col.header === 'SPSSTATUS'">
                                                        <mat-icon
                                                                [ngClass]="{'mdi-spin': getPatientStatusIcon(el) === 'mdi-timelapse'}"
                                                                [ngStyle]="{'color': getIconColor(el), 'fontSize': '20px !important'}"
                                                                fontIcon="{{getPatientStatusIcon(el)}}"
                                                                fontSet="mdi"></mat-icon>
                                                    </td>
                                                    <td *ngIf="col.header === 'REPORTING_STATUS'">
                                                        <button (click)="openReport(el)" mat-icon-button>
                                                            <mat-icon
                                                                    [ngStyle]="{'color': getReportIconColor(el.reportingStatus), 'fontSize': '20px !important'}"
                                                                    fontIcon="{{getReportingStatusIcon(el.reportingStatus)}}"
                                                                    fontSet="mdi">
                                                            </mat-icon>
                                                        </button>
                                                    </td>
                                                    <td *ngIf="['PERFORMING_PHYSICIAN', 'TECHNICIAN'].includes(col.header)"
                                                        [matTooltip]="el[col.attr]" matTooltipPosition="right">
                                                        {{el[col.attr] | initials}}
                                                    </td>
                                                    <td *ngIf="['EXAM_START', 'EXAM_FINISH'].includes(col.header)">
                                                        {{el[col.attr] | date: 'HH:mm'}}
                                                    </td>
                                                    <td *ngIf="col.header === 'PATIENTARRIVAL'">
                                                        {{el[col.attr] | date: todayFormat}}
                                                    </td>
                                                    <td *ngIf="col.header === 'PAYMENT_STATUS'">
                                            <span [class.animate]="el.paymentStatus === 'NOT_PAID'"
                                                  [ngStyle]="{background: getPaymentStatusColor(el.paymentStatus), borderRadius: '50%'}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </td>
                                                    <td *ngIf="['TOTAL_AMOUNT', 'PAID_AMOUNT', 'DISCOUNT', 'LEFT_AMOUNT'].includes(col.header)">
                                                        {{el[col.attr] | numeric: {numberFormat, comma: true} }}
                                                    </td>
                                                    <td *ngIf="!specialFormat(col.header)">{{el[col.attr]}}</td>
                                                </ng-container>

                                                <!--actions-->
                                                <td fxLayout="row nowrap">

                                                    <button (click)="addPayment(el)"
                                                            *ngIf="generalSetting && generalSetting.billingRequired && profile.managePayment"
                                                            [disabled]="cannot('addPayment')"
                                                            [matTooltip]="'PAYMENT' | translate"
                                                            mat-icon-button>
                                                        <mat-icon [class.btn-action-pay]="!cannot('addPayment')" fontIcon="mdi-currency-usd"
                                                                  fontSet="mdi"></mat-icon>
                                                    </button>

                                                    <button (click)="updateFromPacs(el)"
                                                            *ngIf="!el.imagesAvailables"
                                                            [matTooltip]="'MERGE_FROM_PACS' | translate"
                                                            mat-icon-button>
                                                        <mat-icon class="toolbar-icon-gradient" fontIcon="mdi-source-merge" fontSet="mdi"></mat-icon>
                                                    </button>


                                                    <button (click)="openStudy(el, defaultViewer, false)"
                                                            *ngIf="profile.viewPatientStudies !== 'NONE' && defaultViewer"
                                                            [disabled]="!(el['imagesAvailables'] && el['studyInstanceUID'] !== '')"
                                                            [matTooltip]="'OPEN_IN' | translate:{viewer: defaultViewer.name}" mat-icon-button>
                                                        <mat-icon [class.btn-action-view]="(el['imagesAvailables'] && el['studyInstanceUID'] !== '')" [fontIcon]="'mdi-desktop-mac-dashboard'" [fontSet]="'mdi'"
                                                                  [style.color]="(el['imagesAvailables'] && el['studyInstanceUID'] !== '') ? '#00bcd4': ''"></mat-icon>
                                                    </button>


                                                    <button (click)="openStudy(el, defaultViewer, false, false)"
                                                            *ngIf="profile.viewPatientStudies !== 'NONE' && defaultViewer && viewerOpen"
                                                            [disabled]="!(el['imagesAvailables'] && el['studyInstanceUID'] !== '')"
                                                            [matTooltip]="('ADD_TO' | translate) + defaultViewer.name" mat-icon-button>
                                                        <mat-icon [fontIcon]="'mdi-layers-plus'" [fontSet]="'mdi'"
                                                                  [style.color]="(el['imagesAvailables'] && el['studyInstanceUID'] !== '') ? '#E91E63': ''"></mat-icon>
                                                    </button>

                                                    <button [disabled]="el.old" [matMenuTriggerFor]="wfMenu2"
                                                            mat-icon-button>
                                                        <mat-icon fontIcon="mdi-dots-horizontal"
                                                                  fontSet="mdi"></mat-icon>
                                                    </button>

                                                    <mat-menu #wfMenu2="matMenu" [overlapTrigger]="true">
                                                        <button (click)="enterPatient(el)"
                                                                [disabled]="cannot('authorizeExam') || !isGranted(el, 'authorizeExam')"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-login" fontSet="mdi"></mat-icon>
                                                            {{'ENTER_PATIENT' | translate}}
                                                        </button>
                                                        <button (click)="startExam(el)"
                                                                [disabled]="cannot('startExam') || !isGranted(el, 'startExam')"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-play" fontSet="mdi"></mat-icon>
                                                            {{'STARTEXAM' | translate}}
                                                        </button>
                                                        <button (click)="finishExam(el)"
                                                                [disabled]="cannot('finishExam') || !isGranted(el, 'finishExam')"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-stop" fontSet="mdi"></mat-icon>
                                                            {{'FINISHEXAM' | translate}}
                                                        </button>
                                                        <button (click)="onCompleteExam(el)"
                                                                [disabled]="cannot('completeExam') || !isGranted(el, 'completeExam')"
                                                                mat-menu-item>
                                                            <mat-icon
                                                                    fontIcon="mdi-checkbox-multiple-marked-circle-outline"
                                                                    fontSet="mdi"></mat-icon>
                                                            {{'COMPLETE_EXAM' | translate}}
                                                        </button>
                                                        <button (click)="onPatientLeave(el)"
                                                                [disabled]="cannot('exitPatient') || !isGranted(el, 'exitPatient')"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-logout" fontSet="mdi"></mat-icon>
                                                            {{'PATIENTLEAVE' | translate}}
                                                        </button>
                                                        <mat-divider></mat-divider>
                                                        <button (click)="debitStock(el)"
                                                                [disabled]="cannot('completeExam')"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-store" fontSet="mdi"></mat-icon>
                                                            {{'PHARMACY' | translate}}
                                                        </button>
                                                        <mat-divider></mat-divider>
                                                        <button (click)="generateReport(el)"
                                                                [disabled]="cannot('editReport') || !isGranted(el, 'editReport') || (el.reportingTaskId === 0)"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-file" fontSet="mdi"></mat-icon>
                                                            {{'NORMAL_REPORT' | translate}}
                                                        </button>
                                                        <button (click)="openReport(el, true)"
                                                                [disabled]="cannot('editReport') || !isGranted(el, 'editReport')"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-open-in-new"
                                                                      fontSet="mdi"></mat-icon>
                                                            {{'REPORT_NEW_TAB' | translate}}
                                                        </button>
                                                        <mat-divider></mat-divider>

                                                        <ng-container
                                                                *ngIf="generalSetting?.reportPrintMode === 'CHROME'">
                                                            <button (click)="printReportingTask(el)"
                                                                    [disabled]="cannot('printReport') || !isGranted(el, 'printReport')"
                                                                    mat-menu-item>
                                                                <mat-icon fontIcon="mdi-printer"
                                                                          fontSet="mdi"></mat-icon>
                                                                {{'PRINT_REPORT' | translate}}
                                                            </button>

                                                            <button (click)="printBooklet(el)"
                                                                    [disabled]="cannot('printReport') || !isGranted(el, 'printBooklet')"
                                                                    mat-menu-item>
                                                                <mat-icon fontIcon="mdi-printer"
                                                                          fontSet="mdi"></mat-icon>
                                                                {{'PRINT_BOOKLET' | translate}}
                                                            </button>
                                                        </ng-container>
                                                        <ng-template>
                                                            <button [disabled]="cannot('printReport') || !isGranted(el, 'printReport')"
                                                                    [matMenuTriggerFor]="printersMenu3"
                                                                    mat-menu-item>
                                                                <mat-icon fontIcon="mdi-printer"
                                                                          fontSet="mdi"></mat-icon>
                                                                {{'PRINT_REPORT' | translate}}
                                                            </button>

                                                            <button [disabled]="cannot('printReport') || !isGranted(el, 'printBooklet')"
                                                                    [matMenuTriggerFor]="printersMenu4"
                                                                    mat-menu-item>
                                                                <mat-icon fontIcon="mdi-printer"
                                                                          fontSet="mdi"></mat-icon>
                                                                {{'PRINT_BOOKLET' | translate}}
                                                            </button>

                                                            <mat-menu #printersMenu3="matMenu">
                                                                <button (click)="printReportingTask(el, printer['label'])"
                                                                        *ngFor="let printer of printers"
                                                                        mat-menu-item>
                                                                    <mat-icon fontIcon="mdi-printer"
                                                                              fontSet="mdi"></mat-icon>
                                                                    {{printer['label']}}
                                                                </button>
                                                            </mat-menu>
                                                            <mat-menu #printersMenu4="matMenu">
                                                                <button (click)="printBooklet(el, printer['label'])"
                                                                        *ngFor="let printer of printers"
                                                                        mat-menu-item>
                                                                    <mat-icon fontIcon="mdi-printer"
                                                                              fontSet="mdi"></mat-icon>
                                                                    {{printer['label']}}
                                                                </button>
                                                            </mat-menu>

                                                        </ng-template>


                                                        <button (click)="showPatientFolder(el)"
                                                                [disabled]="!can(el, 'viewPatientFolder')"
                                                                [matTooltip]="'PATIENT_FOLDER' | translate"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-folder-account"
                                                                      fontSet="mdi"></mat-icon>
                                                            {{'PATIENT_FOLDER' | translate}}
                                                        </button>

                                                        <mat-divider></mat-divider>

                                                        <button (click)="printRdvForResult(el)"
                                                                [disabled]="!can(el, 'createRdvForResult') || !isGranted(el, 'createRdvForResult')"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                            {{'RDV_FOR_RESULT' | translate}}
                                                        </button>

                                                        <button (click)="deleteExam(el)"
                                                                *ngIf="profile && profile.manageExam !== 'NONE' && notPaid(el)"
                                                                mat-menu-item>
                                                            <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                                                            {{'DELETE' | translate}}
                                                        </button>
                                                    </mat-menu>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </ng-container>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <!-- Hidden Column -->
                        <ng-container matColumnDef="hidden">
                            <mat-header-cell *matHeaderCellDef class="fire-header-cell"></mat-header-cell>
                            <mat-cell (click)="$event.stopPropagation();" *matCellDef="let row" class="fire-cell">
                                <mat-icon (click)="hideExam(row)" *ngIf="!row.billed || hideForced"
                                          [fontIcon]="row.hidden ? 'mdi-eye-off':'mdi-eye'"
                                          [matTooltip]="(row.hidden ? 'UNHIDE': 'HIDE') | translate"
                                          [class.eye-off-gradient]="row.hidden"
                                          [class.eye-gradient]="!row.hidden"
                                          [style.cursor]="'pointer'"
                                          fontSet="mdi"></mat-icon>
                            </mat-cell>
                            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                        </ng-container>

                        <!--Table-->
                        <ng-container *ngFor="let col of displayedColumns" [class.hidden]="col.hidden"
                                      matColumnDef="{{col.label}}">

                            <mat-header-cell *matHeaderCellDef [disabled]="!col.sortable" class="fire-header-cell"
                                             mat-sort-header="{{col.value}}">
                                {{col.header | translate}}
                            </mat-header-cell>

                            <ng-container [ngSwitch]="col.type">
                                <ng-container *ngSwitchCase="'DATE'">
                                    <mat-cell *matCellDef="let row" [ngStyle]="getColumnStyle(col.type, col, row)"
                                              class="fire-cell">
                                        <span>{{row[col.label] | date:  todayFormat }}</span>
                                    </mat-cell>
                                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>

                                <ng-container *ngSwitchCase="'BOOLEAN'">
                                    <mat-cell *matCellDef="let row"
                                              [ngStyle]="getColumnStyleDisplayMode(col.type, col, row)"
                                              class="fire-cell">
                                        <ng-container [ngSwitch]="getColumnDisplayMode(col.header)">
                                            <ng-container *ngSwitchCase="'ICON'">
                                                <mat-icon
                                                        [fontIcon]="getColumnFormattingIcon(col.header, row[col.label])"
                                                        [matTooltip]="(row[col.label] ? 'YES': 'NO') | translate"
                                                        [ngStyle]="getColumnFormattingIconStyle(col.header, row[col.label])"
                                                        fontSet="mdi"></mat-icon>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'BADGE'">
                                                <span [ngStyle]="getColumnStyleDisplayMode(col.type, col, row, 'BADGE')"
                                                      class="ft-badge">{{(row[col.label] ? 'YES' : 'NO') | translate}}</span>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                {{(row[col.label] ? 'YES' : 'NO')  | translate }}
                                            </ng-container>
                                        </ng-container>

                                    </mat-cell>
                                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>

                                <ng-container *ngSwitchCase="'STATUS'">
                                    <mat-cell *matCellDef="let row"
                                              [ngStyle]="getColumnStyleDisplayMode(col.type, col, row)"
                                              class="fire-cell">
                                        <ng-container [ngSwitch]="getColumnDisplayMode(col.header)">
                                            <ng-container *ngSwitchCase="'ICON'">
                                                <mat-icon *ngIf="col.header !== 'REPORTING_STATUS' || row.count == 1"
                                                        (click)="$event.stopPropagation(); col.header === 'REPORTING_STATUS' ? openReport(row): null"
                                                        [fontIcon]="col.header == 'CONNECTED' ? getConnectedIcon(col.header, row[col.label]) :getColumnFormattingIcon(col.header, row[col.label])"
                                                        [matTooltip]="row[col.label] | translate"
                                                        [ngStyle]="getColumnFormattingIconStyle(col.header, row[col.label])"
                                                        [style.cursor]="col.header === 'REPORTING_STATUS' ? 'pointer': ''"
                                                        fontSet="mdi"></mat-icon>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'BADGE'">
                                                <span [ngStyle]="getColumnBooleanBadgeStyle(col.header, row[col.label])"
                                                      class="ft-badge">{{row[col.label]  | translate}}</span>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                {{ row[col.label] | translate }}
                                            </ng-container>
                                        </ng-container>

                                    </mat-cell>
                                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>


                                <ng-container *ngSwitchCase="'NUMERIC'">
                                    <mat-cell *matCellDef="let row" [ngStyle]="getColumnStyle(col.type, col, row)"
                                              class="fire-cell">
                                        {{row | sumWorkItemBy: col.label | numeric: {
                                        numberFormat,
                                        comma: col.label != 'count'
                                    } }}
                                    </mat-cell>
                                    <mat-footer-cell *matFooterCellDef>
                                        <span style="width: 100%">{{calculateSum(col.label, col.label != 'count')}}</span>
                                    </mat-footer-cell>
                                </ng-container>

                                <ng-container *ngSwitchDefault>

                                    <ng-container *ngIf="col.unit === 'ICON'">
                                        <mat-cell *matCellDef="let row" class="fire-cell">
                                            <mat-icon *ngIf="row[col.label]"
                                                      [fontIcon]="'mdi-message'"
                                                      [matTooltipClass]="'alert-tooltip'"
                                                      [matTooltip]="getNoteAlert(row[col.label])"
                                                      [ngStyle]="{color: '#00BCD4'}"
                                                      fontSet="mdi"></mat-icon>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container *ngIf="col.unit !== 'ICON'">
                                        <mat-cell *matCellDef="let row"
                                                  [ngStyle]="getColumnStyle(col.type, col, row)"
                                                  class="fire-cell">
                                            <ng-container *ngIf="col.label === 'patientName'">
                                                <div fxLayout="row nowrap" fxLayoutAlign="space-between center">
                                                    <a [routerLink]="'/patients/details/'+row.patientId"
                                                       class="patient-name">{{row[col.label]}}</a>

                                                    <ng-container *ngIf="row.smsSent">
                                                        <mat-icon [matTooltip]="row.smsContent"
                                                                  [style.color]="'#607D8B'"
                                                                  fontIcon="mdi-email-send"
                                                                  fontSet="mdi"></mat-icon>
                                                    </ng-container>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="col.label !== 'patientName'">
                                                <span [matTooltip]="['insuranceName', 'procedureCode', 'sideEffects'].includes(col.label) ? (row[col.label] | translate): null">{{ row[col.label] | translate }}</span>
                                            </ng-container>

                                        </mat-cell>
                                    </ng-container>


                                    <mat-footer-cell *matFooterCellDef><span
                                            style="width: 100%">{{calculateTotal(col.label)}}</span></mat-footer-cell>
                                </ng-container>
                            </ng-container>

                        </ng-container>

                        <!--actions-->
                        <ng-container matColumnDef="action" stickyEnd>
                            <mat-header-cell *matHeaderCellDef class="fire-header-cell">
                                <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%">
                                    <span fxFlex></span>
                                    <button (click)="drawer.toggle()"
                                            [matTooltip]="'CONDITIONAL_FORMATTING' | translate"
                                            matTooltipPosition="left"
                                            mat-icon-button>
                                        <mat-icon class="toolbar-icon-gradient" fontIcon="mdi-cog" fontSet="mdi"></mat-icon>
                                    </button>
                                </div>
                            </mat-header-cell>
                            <mat-cell (click)="$event.stopPropagation();" *matCellDef="let row" class="fire-cell">
                                <div fxLayout="row nowrap" fxLayoutAlign="end center" style="width: 100%">
                                    <mat-menu #viewerMenu="matMenu" [overlapTrigger]="true">
                                        <button (click)="openStudy(row, viewer, true)"
                                                *ngFor="let viewer of viewers"
                                                mat-menu-item>
                                            {{viewer.name}}
                                        </button>
                                    </mat-menu>

                                    <mat-menu #wfMenu="matMenu" [overlapTrigger]="true">
                                        <button (click)="newExam(row)"
                                                [disabled]="profile && profile['scheduleExam'] === 'NONE'"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                                            {{'NEW_EXAM' | translate}}
                                        </button>
                                        <mat-divider></mat-divider>
                                        <button (click)="enterPatient(row)"
                                                [disabled]="cannot('authorizeExam') || !isGranted(row, 'authorizeExam')"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-login" fontSet="mdi"></mat-icon>
                                            {{'ENTER_PATIENT' | translate}}
                                        </button>
                                        <button (click)="startExam(row)" *ngIf="row.count === 1"
                                                [disabled]="cannot('startExam') || !isGranted(row, 'startExam')"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-play" fontSet="mdi"></mat-icon>
                                            {{'STARTEXAM' | translate}}
                                        </button>
                                        <button (click)="finishExam(row)" *ngIf="row.count === 1"
                                                [disabled]="cannot('finishExam') || !isGranted(row, 'finishExam')"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-stop" fontSet="mdi"></mat-icon>
                                            {{'FINISHEXAM' | translate}}
                                        </button>
                                        <button (click)="onCompleteExam(row)"
                                                *ngIf="row.count === 1"
                                                [disabled]="cannot('completeExam') || !isGranted(row, 'completeExam')"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-checkbox-multiple-marked-circle-outline"
                                                      fontSet="mdi"></mat-icon>
                                            {{'COMPLETE_EXAM' | translate}}
                                        </button>
                                        <button (click)="onPatientLeave(row)" *ngIf="row.count === 1"
                                                [disabled]="cannot('exitPatient') || !isGranted(row, 'exitPatient')"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-logout" fontSet="mdi"></mat-icon>
                                            {{'PATIENTLEAVE' | translate}}
                                        </button>
                                        <mat-divider></mat-divider>
                                        <button (click)="debitStock(row)" *ngIf="row.count === 1"
                                                [disabled]="cannot('completeExam')"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-store" fontSet="mdi"></mat-icon>
                                            {{'PHARMACY' | translate}}
                                        </button>
                                        <mat-divider></mat-divider>
                                        <button (click)="generateReport(row)" *ngIf="row.count === 1"
                                                [disabled]="cannot('editReport') || !isGranted(row, 'editReport') || row.reportingTaskId === 0"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-file" fontSet="mdi"></mat-icon>
                                            {{'NORMAL_REPORT' | translate}}
                                        </button>
                                        <button (click)="openReport(row, true)"
                                                *ngIf="row.count === 1"
                                                [disabled]="cannot('editReport') || !isGranted(row, 'editReport')"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-open-in-new" fontSet="mdi"></mat-icon>
                                            {{'REPORT_NEW_TAB' | translate}}
                                        </button>
                                        <button (click)="assignPerformer(row)"
                                                *ngIf="canAssign(row)" mat-menu-item>
                                            <mat-icon fontIcon="mdi-account-arrow-right" fontSet="mdi"></mat-icon>
                                            {{'DELEGATE_TASK' | translate}}
                                        </button>
                                        <button (click)="assignPerformer(row, 'RE_ASSIGN_TASK')"
                                                *ngIf="canAssign(row)" mat-menu-item>
                                            <mat-icon fontIcon="mdi-account-convert" fontSet="mdi"></mat-icon>
                                            {{'RE_ASSIGN_TASK' | translate}}
                                        </button>
                                        <button (click)="addComment(row)" mat-menu-item>
                                            <mat-icon fontIcon="mdi-comment-alert" fontSet="mdi"></mat-icon>
                                            {{'ADD_COMMENT' | translate}}
                                        </button>
                                        <mat-divider></mat-divider>
                                        <button (click)="printAttestation(row)"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                            {{'PRINT_ATTESTATION' | translate}}
                                        </button>

                                        <ng-container *ngIf="generalSetting?.reportPrintMode === 'CHROME'">
                                            <button (click)="printReportingTask(row)"
                                                    *ngIf="row.count === 1"
                                                    [disabled]="cannot('printReport') || !isGranted(row, 'printReport')"
                                                    mat-menu-item>
                                                <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                {{'PRINT_REPORT' | translate}}
                                            </button>


                                            <button (click)="printBooklet(row)"
                                                    *ngIf="row.count === 1"
                                                    [disabled]="cannot('printReport') || !isGranted(row, 'printBooklet')"
                                                    mat-menu-item>
                                                <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                {{'PRINT_BOOKLET' | translate}}
                                            </button>
                                        </ng-container>
                                        <ng-template>
                                            <button *ngIf="row.count === 1"
                                                    [disabled]="cannot('printReport') || !isGranted(row, 'printReport')"
                                                    [matMenuTriggerFor]="printersMenu"
                                                    mat-menu-item>
                                                <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                {{'PRINT_REPORT' | translate}}
                                            </button>
                                            <mat-menu #printersMenu="matMenu">
                                                <button (click)="printReportingTask(row, printer['label'])"
                                                        *ngFor="let printer of printers"
                                                        mat-menu-item>
                                                    <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                    {{printer['label']}}
                                                </button>
                                            </mat-menu>

                                            <button *ngIf="row.count === 1"
                                                    [disabled]="cannot('printReport') || !isGranted(row, 'printBooklet')"
                                                    [matMenuTriggerFor]="printersMenu2"
                                                    mat-menu-item>
                                                <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                {{'PRINT_BOOKLET' | translate}}
                                            </button>
                                            <mat-menu #printersMenu2="matMenu">
                                                <button (click)="printBooklet(row, printer['label'])"
                                                        *ngFor="let printer of printers"
                                                        mat-menu-item>
                                                    <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                    {{printer['label']}}
                                                </button>
                                            </mat-menu>

                                        </ng-template>

                                        <button (click)="showPatientFolder(row)"
                                                [disabled]="!can(row, 'viewPatientFolder')"
                                                [matTooltip]="'PATIENT_FOLDER' | translate"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-folder-account" fontSet="mdi"></mat-icon>
                                            {{'PATIENT_FOLDER' | translate}}
                                        </button>

                                        <mat-divider></mat-divider>

                                        <button (click)="printRdvForResult(row)"
                                                [disabled]="!can(row, 'createRdvForResult') || !isGranted(row, 'createRdvForResult')"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                            {{'RDV_FOR_RESULT' | translate}}
                                        </button>

                                        <button (click)="deleteExam(row)"
                                                *ngIf="profile && profile.manageExam !== 'NONE' && (row.count === 1) && notPaid(row)"
                                                mat-menu-item>
                                            <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                                            {{'DELETE' | translate}}
                                        </button>
                                    </mat-menu>

                                    <button (click)="sendToBilling(row)"
                                            *ngIf="generalSetting && generalSetting.billingRequired && !row.sent && isAfterUpdate()"
                                            [disabled]="profile && !profile.managePayment"
                                            [matTooltip]="'SYNC_BILLING' | translate"
                                            mat-icon-button>
                                        <mat-icon class="btn-action-sync" fontIcon="mdi-sync" fontSet="mdi"></mat-icon>
                                    </button>
                                    <button (click)="addPayment(row)"
                                            *ngIf="generalSetting && generalSetting.billingRequired && profile.managePayment"
                                            [disabled]="cannot('addPayment')" [matTooltip]="'PAYMENT' | translate"
                                            mat-icon-button>
                                        <mat-icon class="btn-action-pay" fontIcon="mdi-currency-usd" fontSet="mdi"></mat-icon>
                                    </button>
                                    <button (click)="openInSyngovia(row)"
                                            *ngIf="syngoviaActivated"
                                            [disabled]="!(row['imagesAvailables'] && row['studyInstanceUID'] !== '' && isCt(row['examType']))"
                                            [matTooltip]="'OPEN_IN' | translate: {viewer: 'Syngo via'}"
                                            [style.color]="(row['imagesAvailables'] && row['studyInstanceUID'] !== '' && isCt(row['examType'])) ? '#6c71f3': ''"
                                            mat-icon-button>
                                        <mat-icon fontIcon="mdi-check-circle" fontSet="mdi"></mat-icon>
                                    </button>

                                    <button (click)="openStudy(row, defaultViewer, true)"
                                            *ngIf="profile.viewPatientStudies !== 'NONE' && defaultViewer"
                                            [disabled]="!(row['imagesAvailables'] && row['studyInstanceUID'] !== '')"
                                            [matTooltip]="'OPEN_IN' | translate: {viewer: defaultViewer.name}"
                                            mat-icon-button>
                                        <mat-icon [class.btn-action-view]="(row['imagesAvailables'] && row['studyInstanceUID'] !== '')" [fontIcon]="'mdi-desktop-mac-dashboard'" [fontSet]="'mdi'"
                                                  [style.color]="(row['imagesAvailables'] && row['studyInstanceUID'] !== '') ? '#00bcd4': ''"></mat-icon>
                                    </button>

                                    <button (click)="openStudy(row, 'RELite', true)"
                                            *ngIf="profile.viewPatientStudies !== 'NONE' && isRemoteEyeViewer()"
                                            [disabled]="!(row['studyInstanceUID'] !== '')"
                                            [matTooltip]="'OPEN_IN' | translate: {viewer: 'RELite'}" mat-icon-button>
                                        <mat-icon [class.btn-action-view]="row['studyInstanceUID'] !== ''" [fontIcon]="'mdi-desktop-mac-dashboard'" [fontSet]="'mdi'"
                                                  [style.color]="(row['studyInstanceUID'] !== '') ? '#096aa0': ''"></mat-icon>
                                    </button>

                                    <button (click)="openStudy(row, 'RELite', true, false)"
                                            *ngIf="profile.viewPatientStudies !== 'NONE' && viewerOpen"
                                            [disabled]="row['studyInstanceUID'] === ''"
                                            [matTooltip]="('ADD_TO' | translate) + defaultViewer.name" mat-icon-button>
                                        <mat-icon [class.btn-action-view]="row['studyInstanceUID'] !== ''" [fontIcon]="'mdi-layers-plus'" [fontSet]="'mdi'"
                                                  [style.color]="(row['studyInstanceUID'] !== '') ? '#E91E63': ''"></mat-icon>
                                    </button>

                                    <button *ngIf="profile.viewPatientStudies !== 'NONE' && viewers.length"
                                            [disabled]="!(row['imagesAvailables'] && row['studyInstanceUID'] !== '')"
                                            [matMenuTriggerFor]="viewerMenu"
                                            mat-icon-button>
                                        <mat-icon [class.btn-action-view-other]="(row['imagesAvailables'] && row['studyInstanceUID'] !== '')" fontIcon="mdi-image-multiple" fontSet="mdi"></mat-icon>
                                    </button>

                                    <button [disabled]="row['old']" [matMenuTriggerFor]="wfMenu" mat-icon-button>
                                        <mat-icon fontIcon="mdi-dots-horizontal" fontSet="mdi"></mat-icon>
                                    </button>

                                </div>


                            </mat-cell>
                            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                        </ng-container>

                        <!--header-->
                        <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"
                                        class="fire-header-row"></mat-header-row>
                        <!--            <mat-row *cdkRowDef="let row; columns: columnsToDisplay;" [ngClass]="{'fire-row': true, 'hide': row.hidden}"
                                             (click)="expandRow(row)"></mat-row>-->

                        <mat-row (click)="expandRow(row)"
                                 *matRowDef="let row; columns: columnsToDisplay;"
                                 [ngClass]="{'fire-row': true, 'hide': row.hidden}"
                                 [ngStyle]="getRowFormattingStyle(row)"></mat-row>


                        <mat-row *matRowDef="let row; columns: ['expandedRow']" [class.visible]="row == expandedElement"
                                 class="example-detail-row"></mat-row>
                        <mat-footer-row *matFooterRowDef="columnsToDisplay; sticky: true"
                                        [class.hidden-footer]="resultsLength == 0"
                                        [style.minHeight.px]="32"></mat-footer-row>
                    </mat-table>
                    <div [style.display]="resultsLength === 0 ? '' : 'none'" class="example-no-results">
                        <div *ngIf="isLoadingResults; else noItem">
                            <mat-spinner [diameter]="50" [strokeWidth]="3" color="accent"></mat-spinner>
                        </div>
                        <ng-template #noItem>
                            <button (click)="createExamWithPayment()" color="primary" mat-button class="table-btn-new">
                                <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                                {{'NEW_EXAM' | translate}}
                            </button>
                        </ng-template>
                    </div>

                </div>
                <mat-paginator [length]="resultsLength"
                               [pageIndex]="0"
                               [pageSizeOptions]="[20, 50, 100, 150, 200]"
                               [pageSize]="defaultPageSize" showFirstLastButtons>
                </mat-paginator>
            </div>

        </mat-drawer-content>
    </mat-drawer-container>


</div>

<div class="states" fxLayout="row nowrap" fxLayoutGap="4px" *ngIf="hiddenUser()">
    <div class="total-hidden">Caché: {{totalHidden}} {{currencyFormat}} | {{totalExamsHidden}} examens</div>
    <div class="turnover">CA: {{turnover}} {{currencyFormat}} | {{totalExams}} examens.</div>
</div>
