<mat-toolbar>
  <mat-icon fontSet="mdi" fontIcon="mdi-comment-multiple"></mat-icon>
  <span>{{'COMMENTS' | translate}}</span>
  <span fxFlex></span>
  <button (click)="saveComments()" color="primary" mat-button tabindex="-1">
    {{'SAVE' | translate}}
  </button>
  <button mat-icon-button matDialogClose="dismiss" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<mat-dialog-content>

  <div class="new-comment">
    <textarea #editArea [placeholder]="'TYPE_HERE' | translate" (keyup)="saveOnEnter($event)"></textarea>
  </div>

  <div *ngIf="comments.length !== 0" class="comments-list">
    <div class="list-title">{{'ALL_COMMENTS' | translate}}</div>
    <div style="max-height: 314px; overflow: auto; padding: 0 6px 6px;">
      <div *ngFor="let c of comments" style="margin: 6px 0 2px;">
        <div fxLayout="row" fxLayoutAlign="space-between center"> <span class="username">{{c.username}}</span> <span class="datetime">{{c.datetime}}</span></div>
        <div fxLayout="row nowrap" class="comment-line">
          <div class="comment">{{c.comment}}</div>
          <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon fontSet="mdi" fontIcon="mdi-dots-horizontal" [style.color]="'#716c6c'"></mat-icon></button>
        </div>

        <mat-menu #menu="matMenu" [overlapTrigger]="true">
          <button mat-menu-item (click)="editComment(c)"><mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>{{'EDIT' | translate}}</button>
          <button mat-menu-item (click)="deleteComment(c)"><mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>{{'DELETE' | translate}}</button>
        </mat-menu>
      </div>
    </div>

  </div>

</mat-dialog-content>
