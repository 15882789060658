import {Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {FtState, PIE_CHART_COLORS, StateComponent, ViewQuery} from '../model';
import {StatisticService} from '../statistic.service';
import * as moment from "moment";
import {Subscription} from "rxjs";
import {ChartConfiguration, ChartData, ChartType} from "chart.js";
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import {BaseChartDirective} from "ng2-charts";

@Component({
    selector: 'app-patient-state',
    templateUrl: './patient-state.component.html',
    styleUrls: ['./patient-state.component.scss']
})
export class PatientStateComponent implements StateComponent, OnDestroy {
    @Input() data: ViewQuery;

    @ViewChild(BaseChartDirective) baseChart: BaseChartDirective | undefined;

    public barChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            }
        }
    };
    public barChartType: ChartType = 'bar';
    public barChartPlugins = [
        DataLabelsPlugin
    ];

    qualitativeValues = [
        'maritalStatus',
        'city',
        'confidentiality',
        'patientClass',
        'sex',
        'insuranceName'
    ];

    dataMap: { label: string, data: FtState[], chartData: ChartData<any>, chartLabels: any[], chartType: any }[] = [];
    public pieChartOptions: any = {
        responsive: true,
        legend: {
            display: true,
            position: 'bottom'
        }
    };
    public chartColors = PIE_CHART_COLORS;
    private CHART_TYPES = {
        maritalStatus: 'bar',
        city: 'bar',
        confidentiality: 'pie',
        patientClass: 'pie',
        sex: 'pie',
        insuranceName: 'bar'
    }
    private sub: Subscription;

    constructor(private service: StatisticService) {
    }

    ngAfterViewInit() {
        let start = moment(this.data.startDate).format('YYYYMMDD');
        let end = moment(this.data.endDate).format('YYYYMMDD');

        this.sub = this.service.getPatientStates(`${start}-${end}`).subscribe(data => {
            this.dataMap = this.qualitativeValues.map(it => {
                let stateData = this.countDataByAttribute(data, it);
                return {
                    label: it,
                    data: stateData,
                    chartData: {
                        labels: stateData.map(v => {
                            return ['none', ''].includes(v.value) ? 'Autre' : v.value;
                        }),
                        datasets: [
                            {data: stateData.map(c => c.count), label: it}
                        ]
                    },
                    chartLabels: stateData.map(v => {
                        return ['none', ''].includes(v.value) ? 'Autre' : v.value;
                    }),
                    chartType: this.CHART_TYPES[it]
                }
            });
        });
    }

    countDataByAttribute(data: any, attr: string): FtState[] {
        let obj = data[attr];
        return Object.keys(obj).map(it => FtState.of(it === 'none' ? 'Autre' : it, obj[it]));
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
