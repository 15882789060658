import {Component, Input, OnInit} from '@angular/core';
import {StateComponent, ViewQuery} from '../model';
import {StatisticService} from '../statistic.service';
import {PaymentState} from '../../model';
import * as moment from 'moment';
import * as numeral from 'numeral';
import {AppConfigService} from "../../app-config.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SharedService} from "../../shared";

@Component({
    selector: 'app-payment-date-state',
    templateUrl: './payment-date-state.component.html',
    styleUrls: ['./payment-date-state.component.scss']
})
export class PaymentDateStateComponent implements StateComponent, OnInit {
    @Input() data: ViewQuery;

    due: number;
    enteredAmount: number;
    discount: number;

    total: any;

    state: PaymentState;

    finalSold: number = 0;
    turnover: number = 0;
    notPaid: number = 0;
    totalPatientAmount: number = 0;
    totalMode: number = 0;

    cashDayAntNbr: number = 0;
    cashDayAntAmount: number = 0;
    checkDayAntNbr: number = 0;
    checkDayAntAmount: number = 0;
    cardDayAntNbr: number = 0;
    cardDayAntAmount: number = 0;
    private templateUrl = '/templates';
    private readonly numberFormat: any;

    date: string;

    constructor(private service: StatisticService, private _config:AppConfigService,
                private _snack:MatSnackBar, private _shared:SharedService) {
        this.numberFormat = this._config.numberFormat;
    }

    ngOnInit() {
        let date = moment(this.data.startDate).format('YYYYMMDD');

        this.date = date;

        if (!this.data.filter2) this.data.filter2 = 'USER';

        let filter = `${this.data.filter2}_${this.data.filter}`;

        this.service.todayPayments(date, filter).subscribe(data => {
            this.state = data;

            this.finalSold = (data.daily_paid_amount + data.anterior_paid_amount);
            this.totalPatientAmount = (data.cash_daily_paid_amount + data.check_daily_paid_amount + data.credit_daily_card_paid_amount);

            this.cashDayAntNbr = data.cash_daily_paid_amount_nbr + data.cash_anterior_paid_amount_nbr;
            this.cashDayAntAmount = (data.cash_daily_paid_amount + data.cash_anterior_paid_amount);

            this.checkDayAntNbr = data.check_daily_paid_amount_nbr + data.check_anterior_paid_amount_nbr;
            this.checkDayAntAmount = (data.check_daily_paid_amount + data.check_anterior_paid_amount);

            this.cardDayAntNbr = data.credit_daily_card_paid_amount_nbr + data.credit_card_anterior_paid_amount_nbr;
            this.cardDayAntAmount = (data.credit_daily_card_paid_amount + data.credit_card_anterior_paid_amount);

            this.turnover = this.finalSold;

            this.notPaid = (data.daily_taxed_amount - data.daily_paid_amount);

            this.totalMode = (Number(this.cashDayAntAmount) + Number(this.checkDayAntAmount) + Number(this.cardDayAntAmount));
        });
    }

    printState() {
        let date = moment(this.data.startDate).format('YYYYMMDD');
        if (!this.data.filter2) this.data.filter2 = 'USER';

        let filter = `${this.data.filter2}_${this.data.filter}`;
        this.service.printState(date, filter, true).subscribe();
    }

    printAnteriorState() {
        this.service.printAnteriorState().subscribe();
    }

    formatNumeral(numValue: any, comma: boolean = true): any {
        return numeral(numValue).format(`${comma ? this.numberFormat: '0,0'}`);
    }

    exportToPdf() {
        let snackBarRef = this._snack.open('Impression en cours...', '', {duration: 10000});
        this._shared.printPatientsPerDate(this.date).subscribe({
            next: _ => snackBarRef.dismiss(),
            error: _ => snackBarRef.dismiss()
        });
    }
}

