<div fxLayout="row" fxLayoutAlign="space-between stretch" style="height: 100%;">

    <div fxFlex="25%" class="my-list">
        <mat-nav-list dense style="padding-top: 0">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="background-color: #4db6ac; border-radius: 4px 0 0 0">
                <h3 mat-subheader fxFlex style="color: white; font-size: 14px; margin-top: 0 !important;">
                    {{'DICTIONARIES' | translate}}</h3>
                <button fxFlex="20%" mat-icon-button (click)="addDictionary()"
                        [matTooltip]="'ADD_DICTIONARY' | translate">
                    <mat-icon fontSet="mdi" fontIcon="mdi-plus" style="color: white;"></mat-icon>
                </button>
            </div>

            <mat-list-item *ngFor="let dic of dictionaries" (click)="showDictionary(dic)"
                           style="height: 27px !important; font-weight: 400">

                <span mat-line><b>{{ dic.name}}</b></span>
                <button mat-icon-button [matMenuTriggerFor]="dicMenu"
                        (click)="$event.stopPropagation(); selectedDictionary=dic">
                    <mat-icon fontIcon="mdi-dots-vertical" fontSet="mdi"></mat-icon>
                </button>
                <mat-menu #dicMenu="matMenu">
                    <button mat-menu-item (click)="deleteDictionary(dic)">{{'DELETE' | translate}}</button>
                </mat-menu>
            </mat-list-item>
        </mat-nav-list>
    </div>

    <div fxFlex style="padding: 16px;">

        <mat-card class="mat-elevation-z8" style="margin: 0 auto; max-width: 600px">
            <div *ngIf="selectedDictionary">

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-form-field appearance="standard" fxFlex class="dic-name">
                        <mat-label>{{'NAME' | translate}}</mat-label>
                        <input matInput [(ngModel)]="selectedDictionary.name" placeholder="{{'NAME' | translate}}">
                    </mat-form-field>

                    <div fxLayout="row" fxFlexAlign="end center">
                        <button *ngIf="selectedValues.length !== 0" mat-icon-button color="warn" (click)="deleteItems()"
                                [matTooltip]="'DELETE_ITEMS' | translate">
                            <mat-icon style="color: inherit;" fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
                        </button>
                        <button mat-icon-button color="primary" (click)="addNewItem()"
                                [matTooltip]="'ADD_ITEM' | translate">
                            <mat-icon style="color: inherit;" fontIcon="mdi-plus-circle" fontSet="mdi"></mat-icon>
                        </button>
                    </div>

                </div>

                <div class="example-header" *ngIf="selectedItem">
                    <mat-form-field appearance="standard" floatLabel="never">
                        <input matInput [(ngModel)]="selectedItem.value" placeholder="{{'VALUE' | translate}}" (keydown.enter)="saveItem(selectedItem)">
                    </mat-form-field>
                </div>

                <mat-selection-list (selectionChange)="onSelectOption($event)">
                    <mat-list-option *ngFor="let val of selectedDictionary.values" [value]="val">
                        {{val.value}}
                    </mat-list-option>
                </mat-selection-list>

            </div>
            <div class="no-selection" *ngIf="!selectedDictionary">{{'SELECT_DICTIONARY' | translate}}</div>

        </mat-card>

    </div>

</div>