import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {ICONS} from "../../utils";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    selector: 'app-icon-select',
    templateUrl: './icon-select.component.html',
    styleUrls: ['./icon-select.component.scss']
})
export class IconSelectComponent implements OnInit, AfterViewInit {
    icons = [];
    selectedIcon: string;

    constructor(@Inject(MAT_DIALOG_DATA) public icon: string) {
    }

    ngOnInit(): void {
        this.icons = ICONS
    }

    ngAfterViewInit(): void {

    }

}
