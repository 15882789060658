import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PrescriptionItemNode} from './models';


const TREE_DATA = {
    'Numérisation formule + Plaquettes': null,
    'Urée - Créatinine': null,
    'Bilan hepatique complet': null,
    'Lonogramme': ['Na+', 'K+', 'Cl-', 'Mg+', 'Ca++', 'Phosphore',],
    'Bilan d\'hémostate': {
        'TP': null,
        'PDF': null,
        'TCK': null,
        'Fibrinogéne': null,
        'INR': null,
        'Marqueurs tumoraux': ['ACE', 'CA19-9', 'CA 15-3', 'CA125', 'PSA', 'AFP', 'B-HCG', 'Thyroglobuline', 'Calcitonine', 'NSE', 'B2-microglobuline']
    },
    'Sérologie': ['Hépatite B', 'Hépatite C', 'HIV']
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
    dataChange = new BehaviorSubject<PrescriptionItemNode[]>([]);

    get data(): PrescriptionItemNode[] { return this.dataChange.value; }

    constructor() {
        this.initialize();
    }

    initialize() {
        // Build the tree nodes from Json object. The result is a list of `PrescriptionItemNode` with nested
        //     file node as children.
        const data = this.buildFileTree(TREE_DATA, 0);

        // Notify the change.
        this.dataChange.next(data);
    }

    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `PrescriptionItemNode`.
     */
    buildFileTree(obj: { [key: string]: any }, level: number): PrescriptionItemNode[] {
        return Object.keys(obj).reduce<PrescriptionItemNode[]>((accumulator, key) => {
            const value = obj[key];
            const node = new PrescriptionItemNode();
            node.name = key;

            if (value != null) {
                if (typeof value === 'object') {
                    node.children = this.buildFileTree(value, level + 1);
                } else {
                    node.name = value;
                }
            }

            return accumulator.concat(node);
        }, []);
    }

    /** Add an item to to-do list */
    insertItem(parent: PrescriptionItemNode, name: string) {
        if (parent.children) {
            parent.children.push({ name: name } as PrescriptionItemNode);
            this.dataChange.next(this.data);
        }
    }

    updateItem(node: PrescriptionItemNode, name: string) {
        node.name = name;
        this.dataChange.next(this.data);
    }
}
