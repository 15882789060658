<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
    <h3 mat-dialog-title>{{'PROCEDURECODE' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <form [formGroup]="form">

        <div fxLayout="row wrap" fxLayoutGap="4px">

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'CODE' | translate}}</mat-label>
                <input matInput placeholder="{{'CODE' | translate}}" formControlName="code">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'DURATION' | translate}}</mat-label>
                <input type="number" matInput placeholder="{{'DURATION' | translate}}" formControlName="duration"
                       required>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'BILLING_CODE' | translate}}</mat-label>
                <input [formControl]="billingCodeCtrl"
                       [matAutocomplete]="autobill"
                       matInput
                       placeholder="{{'BILLING_CODE' | translate}}"
                       type="text">
                <mat-autocomplete #autobill="matAutocomplete" autoActiveFirstOption>
                    <mat-option (onSelectionChange)="billingCodeChange(item)"
                                *ngFor="let item of filteredBillingCodes | async" [value]="item.code">
                        {{item.code}} <span *ngIf="selected !== item.id" class="list-option-badge">{{item.price}}</span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

        </div>

        <div fxLayout="row">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
                <input matInput placeholder="{{'DESCRIPTION' | translate}}" formControlName="description" required>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'LONG_DESCRIPTION' | translate}}</mat-label>
                <input matInput placeholder="{{'LONG_DESCRIPTION' | translate}}" formControlName="longDescription">
            </mat-form-field>
        </div>

        <div fxLayout="row" class="space-top" fxLayoutGap="4px">

            <mat-form-field appearance="standard" formGroupName="reasonForExam" fxFlex>
                <mat-label>{{'PROCEDURE_TYPE' | translate}}</mat-label>
                <mat-select placeholder="{{'PROCEDURE_TYPE' | translate}}" formControlName="id" required>
                    <mat-option *ngFor="let item of reasonForExams" [value]="item.id" class="option">
                        {{item.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" formGroupName="defaultPerformingPhysician" fxFlex>
                <mat-label>{{'DEFAULT_PERFORMING_PHYSICIAN' | translate}}</mat-label>
                <mat-select placeholder="{{'DEFAULT_PERFORMING_PHYSICIAN' | translate}}" formControlName="id" required>
                    <mat-option *ngFor="let item of staffList" [value]="item.id" class="option">
                        {{item.fullName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'TEMPLATEMODEL' | translate}}</mat-label>
                <input [formControl]="templateModelCtrl"
                       [matAutocomplete]="auto"
                       matInput
                       [placeholder]="'TEMPLATEMODEL' | translate"
                       type="text">
                <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
                    <mat-option (onSelectionChange)="templateModelChange(model)" *ngFor="let model of filteredModels | async" [value]="model.name">
                        {{model.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

        </div>

        <div fxLayout="row" fxLayoutGap="4px">

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'RIS_CODE' | translate}}</mat-label>
                <input formControlName="risCode" matInput placeholder="{{'RIS_CODE' | translate}}">
            </mat-form-field>

            <mat-form-field appearance="standard" formGroupName="modality" fxFlex>
                <mat-label>{{'MODALITY' | translate}}</mat-label>
                <mat-select placeholder="{{'MODALITY' | translate}}" formControlName="id" required>
                    <mat-option *ngFor="let item of modalities" [value]="item.id" class="option">
                        {{item.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'PHARMACEUTICAL' | translate}}</mat-label>
                <mat-select placeholder="{{'PHARMACEUTICAL' | translate}}" formControlName="pharmaceutical">
                    <mat-option *ngFor="let item of pharmaceuticals" [value]="item" class="option">
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-slide-toggle formControlName="composed">{{'COMPOSED_CODE' | translate}}</mat-slide-toggle>
    </form>


</mat-dialog-content>
<mat-dialog-actions>
    <span fxFlex></span>
    <button [mat-dialog-close]="null" color="warn" mat-raised-button>{{'CANCEL' | translate}}</button>
    <button (click)="onSave(form.value)" [disabled]="!form.valid" color="primary"
            mat-raised-button>{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>
