import {fromEvent as observableFromEvent} from 'rxjs';

import {delay} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from './services/auth.service';
import {DEFAULT_CENTER_NAME, LOGO} from '../global-variables';
import {ImagingCenter} from "../model";

@Component({
    selector: 'ft-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    title = 'Login';
    form: FormGroup;
    submitted = false;
    errorMsg: string;
    hide: boolean = true;
    logo = LOGO;
    centers: ImagingCenter[] = [{name: DEFAULT_CENTER_NAME}];
    selectedCenter = this.centers[0];

    constructor(
        private router: Router,
        private authService: AuthService,
        private fb: FormBuilder
    ) {
        this.form = this.fb.group({
            username: ['', Validators.compose([Validators.required])],
            password: ['', Validators.compose([Validators.required])]
        });
    }

    ngOnInit() {
        observableFromEvent(document.getElementById('login'), 'keyup').subscribe((event: KeyboardEvent) => {
            if (event.code === 'Enter') this.onSubmit();
        });
    }


    onSubmit() {
        this.submitted = true;
        this.errorMsg = null;

        this.authService.login(this.form.value).pipe(delay(400))
            .subscribe({
                next: data => {
                    // localStorage.setItem('access_token', data.access_token);

                    this.authService
                        .checkUser()
                        .subscribe(_ =>
                            this.router.navigate([this.authService.redirectUrl]));
                },
                error: e => {
                    this.submitted = false;
                    this.errorMsg = 'Login ou mot de passe incorrect.';
                }
            });

    }

}

