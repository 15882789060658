import {BehaviorSubject, fromEvent as observableFromEvent, merge, of as observableOf} from 'rxjs';

import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {PatientService} from '../patient.service';
import {PAT_HEADER_COLS, PAT_TABLE_CONF} from './table-conf';
import {get, map as _map, reduce} from 'lodash';
import {tableAnimation} from '../../animations';

@Component({
    selector: 'app-patient-search',
    templateUrl: './patient-search.component.html',
    styleUrls: ['./patient-search.component.scss'],
    animations: [tableAnimation]
})
export class PatientSearchComponent implements AfterViewInit {

    selectedPatient: any;
    cols: any[];
    displayedColumns = [];

    dataSource = new MatTableDataSource();

    @ViewChild('filter', {static: true}) filter: ElementRef;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    filterChange = new BehaviorSubject('');
    resultsLength = 0;
    isLoadingResults = true;
    isResultsLoaded = false;

    profile: any;
    canViewConfData: any;

    constructor(@Inject(MAT_DIALOG_DATA) public searchKeys: any,
                private patientService: PatientService,
                public dialogRef: MatDialogRef<PatientSearchComponent>) {
        this.displayedColumns = PAT_TABLE_CONF;
        this.cols = PAT_HEADER_COLS;

        const user = JSON.parse(localStorage.getItem('user'));
        this.profile = user.profile;
        this.canViewConfData = user.canViewConfidentialData;

    }

    onSelect(row) {
        if (this.can(row)) this.dialogRef.close(row);
    }

    trackById(index: number, item: any): string {
        return item.id;
    }

    can(row: any): boolean {
        return !row.confidential || this.canViewConfData;
    }

    ngAfterViewInit(): void {

        this.filterChange.next(this.searchKeys.ln+' '+this.searchKeys.fn);
        this.filter.nativeElement.value = this.searchKeys.ln+' '+this.searchKeys.fn;

        observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
            debounceTime(400),
            distinctUntilChanged(),)
            .subscribe(() => {
                if (!this.dataSource) return;
                this.paginator.pageIndex = 0;
                this.filterChange.next(this.filter.nativeElement.value);
            });

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        let dataChanges = [
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.filterChange.asObservable()
        ];

        merge(...dataChanges)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.patientService.loadPatients(this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        this.filterChange.getValue());
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isResultsLoaded = false;
                    this.resultsLength = data.totalElements;

                    return _map(data.content, tsk => {
                        return reduce(this.displayedColumns, (obj, field) => {
                            obj[field.label] = get(tsk, field.value, field.defaultValue);
                            obj['phone'] = get(tsk, 'phone');
                            obj['title'] = get(tsk, 'title');
                            obj['height'] = get(tsk, 'height');
                            obj['cin'] = get(tsk, 'cin');
                            obj['weight'] = get(tsk, 'weight');
                            obj['createdDate'] = get(tsk, 'createdDate');
                            obj['imc'] = get(tsk, 'imc');
                            obj['confidential'] = get(tsk, 'confidential');
                            obj['debiter'] = get(tsk, 'debiter');
                            obj['demographic'] = get(tsk, 'demographic');
                            obj['medicalHistory'] = get(tsk, 'medicalHistory');
                            obj['consultingDoctor'] = get(tsk, 'consultingDoctor');
                            obj['insurance'] = get(tsk, 'insurance');
                            obj['address'] = get(tsk, 'address');
                            return obj
                        }, {});
                    });
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isResultsLoaded = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.dataSource.data = data);
    }

}
