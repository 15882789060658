<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" [fontIcon]="'mdi-'+headerIcon"></mat-icon>
    <h3 mat-dialog-title>{{title | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <form [formGroup]="form" class="width-300">

        <mat-form-field appearance="standard" class="full-width">
            <mat-label>{{title | translate}}</mat-label>
            <textarea matInput placeholder="{{title | translate}}" formControlName="value" required></textarea>
        </mat-form-field>

    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="warn" [mat-dialog-close]="null">{{'CANCEL' | translate}}</button>
    <button mat-button color="primary" (click)="saveData(form.value)" [disabled]="form.invalid">{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>

