<div class="mat-table" fxLayout="column" fxFill>
    <mat-toolbar fxFlex="nogrow" class="sc-toolbar" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title">
            <mat-icon fontIcon="mdi-note" fontSet="mdi"></mat-icon>
            <span>{{'PACS_DATA' | translate}}</span>
        </div>

        <button mat-icon-button mat-dialog-close>
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
        </button>

    </mat-toolbar>

    <mat-dialog-content fxLayout="column"
                        style="overflow: hidden; min-height: calc(90vh - 80px) !important; padding: 0 !important;">
        <div class="search-area" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px"
             [formGroup]="queryForm" style="padding: 0 16px !important;">

            <mat-form-field appearance="standard" floatLabel="never">
                <span matPrefix><mat-icon fontIcon="mdi-magnify" fontSet="mdi"></mat-icon> &nbsp;</span>
                <input matInput placeholder="{{'PATIENT_NAME' | translate}}"
                       [autofocus]="true" formControlName="patientName">
            </mat-form-field>

            <span fxFlex></span>

            <mat-button-toggle-group #group="matButtonToggleGroup" name="period"
                                     aria-label="Period" (change)="changeRange($event)">
                <mat-button-toggle value="TODAY">
                    {{'TODAY' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="3D">
                    {{'THREE_DAYS' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="1W">
                    {{'ONE_WEEK' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="2W">
                    {{'TWO_WEEKS' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="1M">
                    {{'ONE_MONTH' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="3M">
                    {{'THREE_MONTHS' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="OT">
                    {{'ALL' | translate}}
                </mat-button-toggle>
            </mat-button-toggle-group>

        </div>

        <div fxFlex style="overflow: auto;">
            <mat-table [dataSource]="dataSource"
                       matSort [@state]="dataSource.data.length"
                       [trackBy]="trackById"
                       matSortActive="studyDate"
                       matSortDisableClear matSortDirection="desc">

                <!--Table-->
                <ng-container *ngFor="let col of displayedColumns">

                    <ng-container *ngIf="col === 'studyDate'" matColumnDef="{{col}}">
                        <mat-header-cell *matHeaderCellDef
                                         class="fire-header-cell"> {{col | translate}} </mat-header-cell>
                        <mat-cell *matCellDef="let row" class="fire-cell"> {{formatDate(row[col])}} </mat-cell>
                    </ng-container>

                    <ng-container *ngIf="col === 'studyTime'" matColumnDef="{{col}}">
                        <mat-header-cell *matHeaderCellDef
                                         class="fire-header-cell"> {{col | translate}} </mat-header-cell>
                        <mat-cell *matCellDef="let row" class="fire-cell"> {{formatTime(row[col])}} </mat-cell>
                    </ng-container>

                    <ng-container *ngIf="!['action', 'studyDate', 'studyTime'].includes(col)" matColumnDef="{{col}}">
                        <mat-header-cell *matHeaderCellDef
                                         class="fire-header-cell"> {{col | translate}} </mat-header-cell>
                        <mat-cell *matCellDef="let row" class="fire-cell"> {{formatString(row[col])}} </mat-cell>
                    </ng-container>
                </ng-container>

                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef class="fire-header-cell"></mat-header-cell>
                    <mat-cell *matCellDef="let row" class="fire-cell">
                        <div fxLayout="row nowrap" fxLayoutAlign="end center" style="width: 100%">
                            <button (click)="createPatientExam(row)" *ngIf="isExternalExam(row)"
                                    [matTooltip]="'CREATE_EXAM' | translate"
                                    mat-icon-button>
                                <mat-icon fontIcon="mdi-database-plus" fontSet="mdi" style="color: #2196F3;"></mat-icon>
                            </button>
                            <button (click)="mergeExam(row)" *ngIf="mergeEnabled"
                                    [matTooltip]="'MERGE_EXAM' | translate"
                                    mat-icon-button>
                                <mat-icon fontIcon="mdi-source-merge" fontSet="mdi" style="color: #E91E63;"></mat-icon>
                            </button>
                            <button (click)="openInViewer(row)" *ngIf="defaultViewer"
                                    [matTooltip]="'OPEN_IN_VIEWER' | translate"
                                    mat-icon-button>
                                <mat-icon fontIcon="mdi-monitor" fontSet="mdi" style="color: #0a666f;"></mat-icon>
                            </button>
                        </div>

                    </mat-cell>
                </ng-container>

                <!--header-->
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="fire-header-row"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="fire-row"></mat-row>
            </mat-table>

            <div class="example-no-results" [style.display]="dataSource.data.length === 0 ? '' : 'none'">
                <div *ngIf="isLoadingResults; else noItem">
                    <mat-spinner color="accent" [diameter]="50" [strokeWidth]="3"></mat-spinner>
                </div>
                <ng-template #noItem>
                    {{'NOITEMSFOUND' | translate}}
                </ng-template>
            </div>
        </div>

        <mat-paginator
                [length]="dataSource.data.length"
                [pageIndex]="0"
                [pageSize]="50"
                [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons>
        </mat-paginator>
    </mat-dialog-content>


    <mat-dialog-actions align="end">
        <button color="accent" mat-raised-button matDialogClose>{{'CLOSE' | translate}}</button>
    </mat-dialog-actions>
</div>
