import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-value-description',
  templateUrl: './value-description.component.html',
  styleUrls: ['./value-description.component.scss']
})
export class ValueDescriptionComponent implements OnInit {

  selectedItem: any;
  form: FormGroup;

    constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<ValueDescriptionComponent>) {
    this.form = this.fb.group({
      id: '',
      value: ['', Validators.compose([Validators.required, Validators.maxLength(20)])],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(36)])],
      deleted: false
    });
  }

  onSave(data) {
    this.dialogRef.close(data);
  }

  ngOnInit() {
    this.form.patchValue(this.selectedItem || {id: 0, value: '', description: '', deleted: false});
  }

}
