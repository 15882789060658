<mat-toolbar class="sc-toolbar" color="primary">
    <div class="title">
        <span>{{'DEFAULT_VALUES' | translate}}</span>
    </div>
</mat-toolbar>

<div *ngIf="defaultValues" style="padding: 16px">

    <div class="values-section">
        <h4>{{'PATIENT' | translate}}</h4>
        <div class="values-grid">
            <mat-form-field appearance="outline">
                <mat-label>{{'DEFAULT_TITLE' | translate}}</mat-label>
                <mat-select (selectionChange)="saveDefaultValues(defaultValues)"
                        [(ngModel)]="defaultValues.defaultTitle" [ngModelOptions]="{standalone: true}"
                        placeholder="{{'DEFAULT_TITLE' | translate}}">
                    <mat-option *ngFor="let title of titles" [value]="title.value">
                        {{title.value}}
                    </mat-option>
                </mat-select>
                <button *ngIf="defaultValues.defaultTitle" (click)="$event.stopImmediatePropagation(); clearInput('defaultTitle')" mat-icon-button
                        color="accent"
                        matSuffix>
                    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'DEFAULT_GENDER' | translate}}</mat-label>
                <mat-select (selectionChange)="saveDefaultValues(defaultValues)"
                        [(ngModel)]="defaultValues.defaultGender" [ngModelOptions]="{standalone: true}"
                        placeholder="{{'DEFAULT_GENDER' | translate}}">

                    <mat-option *ngFor="let gender of genders" [value]="gender.value">
                        {{gender.description}}
                    </mat-option>

                </mat-select>
                <button *ngIf="defaultValues.defaultGender" (click)="$event.stopImmediatePropagation(); clearInput('defaultGender')" mat-icon-button
                        color="accent"
                        matSuffix>
                    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'DEFAULT_MARITAL_STATUS' | translate}}</mat-label>
                <mat-select (selectionChange)="saveDefaultValues(defaultValues)"
                        [(ngModel)]="defaultValues.defaultMaritalStatus" [ngModelOptions]="{standalone: true}"
                        placeholder="{{'DEFAULT_MARITAL_STATUS' | translate}}">

                    <mat-option *ngFor="let ms of maritalStatuses" [value]="ms.value">
                        {{ms.description}}
                    </mat-option>

                </mat-select>
                <button *ngIf="defaultValues.defaultMaritalStatus" (click)="$event.stopImmediatePropagation(); clearInput('defaultMaritalStatus')" mat-icon-button
                        color="accent"
                        matSuffix>
                    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'DEFAULT_RELATION_WITH_INSURED' | translate}}</mat-label>
                <mat-select (selectionChange)="saveDefaultValues(defaultValues)"
                        [(ngModel)]="defaultValues.defaultRelationWithInsured" [ngModelOptions]="{standalone: true}"
                        placeholder="{{'DEFAULT_RELATION_WITH_INSURED' | translate}}">

                    <mat-option *ngFor="let state of ['HIMSELF', 'PARTNER', 'CHILD']" [value]="state">
                        {{state | translate}}
                    </mat-option>

                </mat-select>
                <button *ngIf="defaultValues.defaultRelationWithInsured" (click)="$event.stopImmediatePropagation(); clearInput('defaultRelationWithInsured')" mat-icon-button
                        color="accent"
                        matSuffix>
                    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>

    <div class="values-section">
        <h4>{{'LOCATION' | translate}}</h4>
        <div class="values-grid">
            <mat-form-field appearance="outline">
                <mat-label>{{'DEFAULT_COUNTRY' | translate}}</mat-label>
                <input matInput [(ngModel)]="defaultValues.defaultCountry" [ngModelOptions]="{standalone: true}"
                       (blur)="saveDefaultValues(defaultValues)"
                       [placeholder]="'DEFAULT_COUNTRY' | translate">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'DEFAULT_CITY' | translate}}</mat-label>
                <input matInput [(ngModel)]="defaultValues.defaultCity" [ngModelOptions]="{standalone: true}"
                       (blur)="saveDefaultValues(defaultValues)"
                       [placeholder]="'DEFAULT_CITY' | translate">
            </mat-form-field>
        </div>
    </div>

    <div class="values-section">
        <h4>{{'PROCEDURE_CODE' | translate}}</h4>
        <div class="values-grid">
            <mat-form-field appearance="outline">
                <mat-label>{{'DEFAULT_PRIORITY' | translate}}</mat-label>
                <mat-select (selectionChange)="saveDefaultValues(defaultValues)"
                        [(ngModel)]="defaultValues.defaultPriority" [ngModelOptions]="{standalone: true}"
                        placeholder="{{'DEFAULT_PRIORITY' | translate}}">

                    <mat-option *ngFor="let priority of priorities" [value]="priority.value">
                        {{priority.value}}
                    </mat-option>
                </mat-select>
                <button *ngIf="defaultValues.defaultPriority" (click)="$event.stopImmediatePropagation(); clearInput('defaultPriority')" mat-icon-button
                        color="accent"
                        matSuffix>
                    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'DEFAULT_PATIENT_CLASS' | translate}}</mat-label>
                <mat-select (selectionChange)="saveDefaultValues(defaultValues)"
                        [(ngModel)]="defaultValues.defaultPatientClass" [ngModelOptions]="{standalone: true}"
                        placeholder="{{'DEFAULT_PATIENT_CLASS' | translate}}">

                    <mat-option *ngFor="let cls of patientClasses" [value]="cls.value">
                        {{cls.description}}
                    </mat-option>

                </mat-select>
                <button *ngIf="defaultValues.defaultPatientClass" (click)="$event.stopImmediatePropagation(); clearInput('defaultPatientClass')" mat-icon-button
                        color="accent"
                        matSuffix>
                    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'DEFAULT_CONFIDENTIALITY' | translate}}</mat-label>
                <mat-select (selectionChange)="saveDefaultValues(defaultValues)"
                        [(ngModel)]="defaultValues.defaultConfidentiality" [ngModelOptions]="{standalone: true}"
                        placeholder="{{'DEFAULT_CONFIDENTIALITY' | translate}}">

                    <mat-option *ngFor="let confidentiality of confidentialities" [value]="confidentiality.value">
                        {{confidentiality.description}}
                    </mat-option>

                </mat-select>
                <button *ngIf="defaultValues.defaultConfidentiality" (click)="$event.stopImmediatePropagation(); clearInput('defaultConfidentiality')" mat-icon-button
                        color="accent"
                        matSuffix>
                    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'DEFAULT_PERFORMING_PHYSICIAN' | translate}}</mat-label>
                <mat-select (selectionChange)="saveDefaultValues(defaultValues)"
                        [(ngModel)]="defaultValues.defaultPerformingPhysician" [ngModelOptions]="{standalone: true}"
                        placeholder="{{'DEFAULT_PERFORMING_PHYSICIAN' | translate}}">

                    <mat-option *ngFor="let physician of physicians" [value]="physician.id">
                        {{physician.fullName}}
                    </mat-option>

                </mat-select>
                <button *ngIf="defaultValues.defaultPerformingPhysician" (click)="$event.stopImmediatePropagation(); clearInput('defaultPerformingPhysician')" mat-icon-button
                        color="accent"
                        matSuffix>
                    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'DEFAULT_TECHNICIAN' | translate}}</mat-label>
                <mat-select (selectionChange)="saveDefaultValues(defaultValues)"
                        [(ngModel)]="defaultValues.defaultTechnician" [ngModelOptions]="{standalone: true}"
                        placeholder="{{'DEFAULT_TECHNICIAN' | translate}}">

                    <mat-option *ngFor="let technician of technicians" [value]="technician.id">
                        {{technician.fullName}}
                    </mat-option>

                </mat-select>
                <button *ngIf="defaultValues.defaultTechnician" (click)="$event.stopImmediatePropagation(); clearInput('defaultTechnician')" mat-icon-button
                        color="accent"
                        matSuffix>
                    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>

    <div class="values-section">
        <h4>{{'PAYMENT_AND_INSURANCE' | translate}}</h4>
        <div class="values-grid">
            <mat-form-field appearance="outline">
                <mat-label>{{'DEFAULT_PAYMENT_MODALITY' | translate}}</mat-label>
                <mat-select (selectionChange)="saveDefaultValues(defaultValues)"
                        [(ngModel)]="defaultValues.defaultPaymentModality" [ngModelOptions]="{standalone: true}"
                        placeholder="{{'DEFAULT_PAYMENT_MODALITY' | translate}}">

                    <mat-option *ngFor="let state of ['PATIENT', 'INSURED_PATIENT', 'THIRD_PARTY_PAYMENT', 'PEC','RAMED']" [value]="state">
                        {{state | translate}}
                    </mat-option>

                </mat-select>
                <button *ngIf="defaultValues.defaultPaymentModality" (click)="$event.stopImmediatePropagation(); clearInput('defaultPaymentModality')" mat-icon-button
                        color="accent"
                        matSuffix>
                    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'DEFAULT_PAYMENT_METHOD' | translate}}</mat-label>
                <mat-select (selectionChange)="saveDefaultValues(defaultValues)"
                        [(ngModel)]="defaultValues.defaultPaymentMethod" [ngModelOptions]="{standalone: true}"
                        placeholder="{{'DEFAULT_PAYMENT_METHOD' | translate}}">

                    <mat-option *ngFor="let method of paymentMethods" [value]="method.value">
                        {{method.value}}
                    </mat-option>

                </mat-select>
                <button *ngIf="defaultValues.defaultPaymentMethod" (click)="$event.stopImmediatePropagation(); clearInput('defaultPaymentMethod')" mat-icon-button
                        color="accent"
                        matSuffix>
                    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>

</div>

