import {Component} from '@angular/core';
import {PatientService} from './patient.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from "@angular/router";


@Component({
    selector: 'app-patient',
    templateUrl: './patient.component.html',
    styleUrls: ['./patient.component.scss']
})
export class PatientComponent {

    profile: any;
    title = 'Patients';

    constructor(public patientService: PatientService,
                private _router: Router,
                private _dialog: MatDialog) {
        this.profile = JSON.parse(localStorage.getItem('profile'));
    }

    createNewPatient() {
        this._router.navigate(['/patients/details', 0]);
    }
}
