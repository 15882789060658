import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {DynamicFormComponent} from './containers/dynamic-form/dynamic-form.component';
import {
    DynamicFieldDirective,
    FormButtonComponent,
    FormInputComponent,
    FormMultiselectComponent,
    FormRadioComponent,
    FormSelectComponent,
    FormTextareaComponent
} from './components';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatRadioModule,
        MatChipsModule,
        MatAutocompleteModule
    ],
    declarations: [DynamicFormComponent, FormInputComponent, FormSelectComponent, FormButtonComponent, DynamicFieldDirective, FormTextareaComponent, FormRadioComponent, FormMultiselectComponent],
    exports: [DynamicFormComponent]
})
export class DynamicFormModule {
}
