import {BehaviorSubject, merge as observableMerge, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {SettingService} from '../setting.service';
import {DeleteConfirmComponent, deleteItemFromArray, SharedService} from '../../shared';
import {PROFILE_HEADER_COLS, PROFILE_TABLE_CONF} from './table-conf';
import {DataSource} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';

import {MatSort} from '@angular/material/sort';
import {assign, map as _map} from 'lodash';
import {ProfileEditComponent} from './profile-edit/profile-edit.component';
import {Profile} from '../../model';
import {AuthService} from "../../auth";

@Component({
    selector: 'app-profile-setting',
    templateUrl: './profile-setting.component.html',
    styleUrls: ['./profile-setting.component.scss']
})
export class ProfileSettingComponent implements OnInit {

    cols: any[];
    displayedColumns = [];

    dataSource: ProfileDataSource | null;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private service: SettingService,
                private _authService: AuthService,
                private snackBar: MatSnackBar,
                private sharedService: SharedService,
                private dialog: MatDialog) {

        this.sharedService.getProfiles().subscribe(data => {
            let items = _map(data, tsk => assign({}, new Profile(), tsk));
            this.dataSource.dataChange.next(items);
        });
    }

    editProfile(profile) {
        this.dialog
            .open(ProfileEditComponent, {
                data: profile,
                panelClass: 'profile-panel',
                maxWidth: '1000px'
            })
            .afterClosed()
            .subscribe(res => {
                if (res) {
                    if (profile.id) deleteItemFromArray(this.dataSource.data, profile);
                    this.dataSource.data.push(res);
                    this.dataSource.dataChange.next(this.dataSource.data);
                    this.openSnackBar(`Le profil ${profile.name} à été mis à jour`, 'OK');
                }
            })
    }


    deleteProfile(profile) {
        this.dialog
            .open(DeleteConfirmComponent)
            .afterClosed()
            .subscribe(res => {
                if (res) {
                    if (profile.id === 410 || profile.value === 'admin') {
                        this.openSnackBar('You cannot delete this profile', 'OK')
                    } else {
                        this.service.deleteProfile(profile.id).subscribe(res2 => {
                            deleteItemFromArray(this.dataSource.data, profile);
                            this.dataSource.dataChange.next(this.dataSource.data);
                        });
                    }
                }
            })
    }

    private openSnackBar(msg: string, action: string): void {
        this.snackBar.open(msg, action, {
            duration: 2000,
        });
    }


    ngOnInit() {
        this.displayedColumns = PROFILE_TABLE_CONF;
        this.cols = PROFILE_HEADER_COLS;

        this.dataSource = new ProfileDataSource(this.paginator, this.sort);
    }

}


export class ProfileDataSource extends DataSource<any> {

    filteredData: ProfileItem[] = [];
    renderedData: ProfileItem[] = [];

    /** Stream that emits whenever the data has been modified. */
    dataChange: BehaviorSubject<ProfileItem[]> = new BehaviorSubject<ProfileItem[]>([]);

    constructor(private _paginator: MatPaginator, private _sort: MatSort) {
        super();
    }

    get data(): ProfileItem[] {
        return this.dataChange.value;
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<ProfileItem[]> {
        const displayDataChanges = [
            this.dataChange,
            this._paginator.page,
            this._sort.sortChange,
        ];

        return observableMerge(...displayDataChanges).pipe(map(() => {


            // Filter data
            this.filteredData = this.data.slice().filter((item: ProfileItem) => {
                return true;
            });

            // Sort filtered data
            const sortedData = this.sortData(this.filteredData.slice());

            // Grab the page's slice of the filtered sorted data.
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
            return this.renderedData;
        }));
    }

    disconnect() {
    }

    /** Returns a sorted copy of the database data. */
    sortData(data: ProfileItem[]): ProfileItem[] {
        if (!this._sort.active || this._sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._sort.active) {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'name':
                    [propertyA, propertyB] = [a.name, b.name];
                    break;
                case 'description':
                    [propertyA, propertyB] = [a.description, b.description];
                    break;
            }

            let valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            let valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }


}

export interface ProfileItem {
    id: string;
    name: string;
    description: string;
}
