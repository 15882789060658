import {BehaviorSubject, merge as observableMerge, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {SettingService} from '../setting.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AetEditComponent} from './aet-edit/aet-edit.component';

import * as _ from 'lodash';
import {DataSource} from '@angular/cdk/collections';
import {AET_HEADER_COLS, AET_TABLE_CONF} from './table-conf';
import {ImagingCenter, IStaticVD, Staff} from '../../model';
import {DeleteConfirmComponent, deleteItemFromArray} from '../../shared';
import {UnavailabilityComponent} from '../unavailability/unavailability.component';

@Component({
    selector: 'app-aet-setting',
    templateUrl: './aet-setting.component.html',
    styleUrls: ['./aet-setting.component.scss']
})
export class AetSettingComponent implements OnInit {

    aets: any[];
    cols: any[];
    displayedColumns = [];

    dataSource: AETDataSource | null;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private settingService: SettingService, public dialog: MatDialog) {
        this.settingService.getAetList().subscribe(
            data => {
                let items = _.map(data, tsk => {
                    return _.reduce(this.displayedColumns, (obj, field) => {
                        obj[field.label] = _.get(tsk, field.value, field.defaultValue);
                        obj['defaultTechnician'] = _.get(tsk, 'defaultTechnician');
                        obj['setAsDefault'] = _.get(tsk, 'setAsDefault');
                        obj['enabled'] = _.get(tsk, 'enabled');
                        obj['name'] = _.get(tsk, 'name');
                        obj['modality'] = _.get(tsk, 'modality');
                        obj['room'] = _.get(tsk, 'room');
                        return obj
                    }, {});
                });

                this.dataSource.dataChange.next(items);
            }
        );
    }

    editAet(aet) {

        if (aet === null) {
            aet = {
                id: '',
                name: '',
                hostname: '',
                title: '',
                color: ''
            }
        }

        const dialogRef = this.dialog.open(AetEditComponent, {
            data: aet
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                if (aet.id) this.deleteItem(aet);

                this.dataSource.data.push(res);
                this.dataSource.dataChange.next(this.dataSource.data);
            }
        });
    }

    private deleteItem(aet): void {
        deleteItemFromArray(this.dataSource.data, aet);
    }

    editAETUnavailability(aet) {
        this.dialog.open(UnavailabilityComponent, {
            data: aet,
            width: '860px'
        });
    }

    deleteAet(aet) {
        const dialogRef = this.dialog.open(DeleteConfirmComponent);
        dialogRef.afterClosed().subscribe(ok => {
            if (ok) {
                this.settingService.deleteAet(aet.id).subscribe(res => {
                    this.deleteItem(aet);
                    this.dataSource.dataChange.next(this.dataSource.data);
                });
            }
        });
    }


    ngOnInit() {

        this.displayedColumns = AET_TABLE_CONF;
        this.cols = AET_HEADER_COLS;

        this.dataSource = new AETDataSource(this.paginator, this.sort);
    }

}


export class AETDataSource extends DataSource<any> {

    filteredData: AetItem[] = [];
    renderedData: AetItem[] = [];

    /** Stream that emits whenever the data has been modified. */
    dataChange: BehaviorSubject<AetItem[]> = new BehaviorSubject<AetItem[]>([]);

    get data(): AetItem[] {
        return this.dataChange.value;
    }

    constructor(private _paginator: MatPaginator, private _sort: MatSort) {
        super();
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<AetItem[]> {
        const displayDataChanges = [
            this.dataChange,
            this._paginator.page,
            this._sort.sortChange,
        ];

        return observableMerge(...displayDataChanges).pipe(map(() => {


            // Filter data
            this.filteredData = this.data.slice().filter((item: AetItem) => {
                return true;
            });

            // Sort filtered data
            const sortedData = this.sortData(this.filteredData.slice());

            // Grab the page's slice of the filtered sorted data.
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
            return this.renderedData;
        }));
    }

    disconnect() {
    }

    /** Returns a sorted copy of the database data. */
    sortData(data: AetItem[]): AetItem[] {
        if (!this._sort.active || this._sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let pA: number | string = '';
            let pB: number | string = '';

            switch (this._sort.active) {
                case 'id':
                    [pA, pB] = [a.id, b.id];
                    break;
                case 'title':
                    [pA, pB] = [a.title, b.title];
                    break;
                case 'installer':
                    [pA, pB] = [a.installer, b.installer];
                    break;
                case 'hostname':
                    [pA, pB] = [a.hostname, b.hostname];
                    break;
                case 'performingPhysician':
                    [pA, pB] = [a.performingPhysician, b.performingPhysician];
                    break;
            }

            let valueA = isNaN(+pA) ? pA : +pA;
            let valueB = isNaN(+pB) ? pB : +pB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }


}


export interface AetItem {
    id: string;
    title: string;
    installer: string;
    manufacturer: string;
    hostname: string;
    dateOfPurchase: string;
    installationDate: string;
    performingPhysician: string;
    color: string;
    deviceType: IStaticVD;
    imagingCenter: ImagingCenter;
    defaultTechnician: Staff;
}
