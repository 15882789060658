<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
    <h3 mat-dialog-title>{{'UNAVAILABILITY' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>

    <div [formGroup]="form">

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'STARTDATE' | translate}}</mat-label>
                <input matInput [matDatepicker]="startpicker" placeholder="{{'STARTDATE' | translate}}"
                       formControlName="startDate">
                <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
                <mat-datepicker #startpicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'ENDDATE' | translate}}</mat-label>
                <input matInput [matDatepicker]="endpicker" placeholder="{{'ENDDATE' | translate}}"
                       formControlName="endDate">
                <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
                <mat-datepicker #endpicker></mat-datepicker>
            </mat-form-field>

        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <mat-slide-toggle tabindex="-1" formControlName="allDay">
                {{'ALL_DAY' | translate}}
            </mat-slide-toggle>

            <span fxFlex></span>

            <mat-form-field appearance="standard" *ngIf="!form.get('allDay').value">
                <mat-label>{{'STARTTIME' | translate}}</mat-label>
                <input matInput type="time" placeholder="{{'STARTTIME' | translate}}" formControlName="startTime">
            </mat-form-field>

            <mat-form-field appearance="standard" *ngIf="!form.get('allDay').value">
                <mat-label>{{'ENDTIME' | translate}}</mat-label>
                <input matInput type="time" placeholder="{{'ENDTIME' | translate}}" formControlName="endTime">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">

            <mat-form-field appearance="standard" formGroupName="unavailabilityReason" fxFlex>
                <mat-label>{{'UNAVAILABILITYREASON' | translate}}</mat-label>
                <mat-select placeholder="{{'UNAVAILABILITYREASON' | translate}}" formControlName="id">
                    <mat-option *ngFor="let reason of unavailabilityReasons" [value]="reason.id">
                        {{reason.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
                <input matInput placeholder="{{'DESCRIPTION' | translate}}" formControlName="description">
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>{{'OTHERREASON' | translate}}</mat-label>
                <input matInput placeholder="{{'OTHERREASON' | translate}}" formControlName="otherReason">
            </mat-form-field>

        </div>

    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="warn" [mat-dialog-close]="null">{{'CANCEL' | translate}}</button>
    <button mat-button color="primary" (click)="onSave(form.value)" [disabled]="form.invalid">{{'SAVE' | translate}}
    </button>
</mat-dialog-actions>
