import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ReportingComponent} from './reporting.component';
import {ReportingHomeComponent} from './reporting-home/reporting-home.component';
import {ReportingEditComponent} from './reporting-edit/reporting-edit.component';
import {UserResolver} from "../user.resolver";


const routes: Routes = [
    {
        path: '',
        component: ReportingComponent,
        resolve: {user: UserResolver},
        children: [
            {path: '', pathMatch: 'full', redirectTo: 'reports-list'},
            {path: 'reports-list', component: ReportingHomeComponent, resolve: {user: UserResolver}},
            {path: 'report-edition/:id', component: ReportingEditComponent}
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ReportingRoutingModule {
}
