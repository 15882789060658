<div class="mat-table">
    <mat-toolbar class="sc-toolbar" color="primary">
        <div class="title">
            <span>{{'REFERRING_PHYSICIANS' | translate}}</span>
        </div>

        <span class="fire-spacer"></span>

        <button (click)="exportPhysicians()" [matTooltip]="'EXPORT_TO_EXCEL' | translate" mat-button>
            <mat-icon class="excel-icon-gradient" fontIcon="mdi-microsoft-excel" fontSet="mdi"></mat-icon>
        </button>

        <button [matTooltip]="'ADD_NEW' | translate" mat-button (click)="editReferringPhysician({})">
            <mat-icon class="add-icon-gradient" fontIcon="mdi-plus-box" fontSet="mdi"></mat-icon>
        </button>

    </mat-toolbar>

    <div class="search-area">
        <mat-form-field floatLabel="never">
            <span matPrefix><mat-icon fontIcon="mdi-magnify" fontSet="mdi" style="vertical-align: baseline;"></mat-icon> &nbsp;</span>
            <input matInput #filter placeholder="{{'SEARCHING' | translate}}">
        </mat-form-field>

    </div>

    <mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate"></mat-progress-bar>


    <mat-table #table [dataSource]="dataSource" class="fire-table"
               matSort matSortActive="lastName" matSortDirection="desc"
               [trackBy]="trackByLastName"
               [@state]="dataSource.data.length">

        <!--Table-->
        <ng-container *ngFor="let col of displayedColumns" cdkColumnDef="{{col.label}}" [class.hidden]="col.hidden">
            <mat-header-cell *cdkHeaderCellDef class="fire-header-cell" mat-sort-header> {{col.header | translate}}
                <span *ngIf="col.unit !== 'date' && col.unit !== 'color'">{{col.unit}}</span>
            </mat-header-cell>

            <ng-container *ngIf="col.unit === 'date'">
                <mat-cell *cdkCellDef="let row" class="fire-cell"> {{row[col.label] | date:'dd/MM/yyyy'}}</mat-cell>
            </ng-container>
            <ng-container *ngIf="col.unit !== 'date' && col.unit !== 'color'">
                <mat-cell *cdkCellDef="let row" class="fire-cell"> {{row[col.label]}}</mat-cell>
            </ng-container>
            <ng-container *ngIf="col.unit === 'color'">
                <mat-cell *cdkCellDef="let row" class="fire-cell">
                    <mat-icon [ngStyle]="{background: 'linear-gradient(45deg, black, '+row[col.label]+', white)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent'}" fontSet="mdi"
                              fontIcon="mdi-checkbox-blank-circle"></mat-icon>
                </mat-cell>
            </ng-container>

        </ng-container>

        <!--actions-->
        <ng-container cdkColumnDef="action">
            <mat-header-cell *cdkHeaderCellDef class="fire-header-cell"></mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="fire-cell">
                <button mat-icon-button (click)="$event.stopPropagation(); editReferringPhysician(row)">
                    <mat-icon class="edit-icon-gradient" fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
                </button>
                <button mat-icon-button (click)="$event.stopPropagation(); deleteReferringPhysician(row)">
                    <mat-icon class="delete-icon-gradient" fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <!--header-->
        <mat-header-row *cdkHeaderRowDef="cols; sticky:true" class="fire-header-row"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: cols;"
                 class="fire-row">
        </mat-row>
    </mat-table>

    <mat-paginator [length]="resultsLength"
                   [pageIndex]="0"
                   [pageSize]="25"
                   [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons>
    </mat-paginator>
</div>

<ng-container *ngIf="downloading">
    <div fxLayout="row" fxLayoutAlign="center center"
         style="position:fixed;top: 0; left: 0;height: 100vh;width: 100vw; background: rgba(0, 0, 0, 0.4);">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px"
             style="height: 86px;width: 360px;border-radius: 8px;background: rgb(255 255 255);color: #585858;">
            <mat-spinner diameter="30" strokeWidth="2"></mat-spinner>
            <h3>{{'EXPORTING' | translate}}</h3>
        </div>
    </div>
</ng-container>