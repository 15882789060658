<mat-toolbar class="dialog-toolbar">
    <mat-icon fontSet="mdi" fontIcon="mdi-voice"></mat-icon>
    <h3 mat-dialog-title>{{'AUDIO_RECORDER' | translate}}</h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <div>
        <audio #myAudio controls>Audio stream not available.</audio>

        <div fxLayout="row" fxLayoutAlign="center none">
            <button fxFlex="36px" mat-icon-button color="primary" (click)="startRecording()"
                    [matTooltip]="'RECORD' | translate">
                <mat-icon [ngStyle]="{'color':'red'}" fontIcon="mdi-record-rec" fontSet="mdi"></mat-icon>
            </button>
            <button fxFlex="36px" mat-icon-button color="accent" (click)="pauseRecording()"
                    [matTooltip]="'PAUSE' | translate">
                <mat-icon fontSet="mdi" fontIcon="mdi-pause"></mat-icon>
            </button>
            <button fxFlex="36px" mat-icon-button color="primary" (click)="stopRecording()"
                    [matTooltip]="'STOP' | translate">
                <mat-icon fontIcon="mdi-stop" fontSet="mdi"></mat-icon>
            </button>
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="accent" [mat-dialog-close]="null">{{'CLOSE' | translate}}</button>
    <button mat-button color="primary" (click)="saveFile()">{{'SAVE' | translate}}</button>
</mat-dialog-actions>
