import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Message} from '../models';
import {formatTimer} from '../../utils';
import {MatSliderChange} from '@angular/material/slider';

@Component({
    selector: 'ft-audio-message',
    templateUrl: './audio-message.component.html',
    styleUrls: ['./audio-message.component.scss']
})
export class AudioMessageComponent implements OnChanges {
    public playing: boolean;
    public audioDuration: number = 10;
    public currentTime: number = 0;

    @Input('audioMessage') message: Message;

    @Output('played') played: EventEmitter<Message> = new EventEmitter<Message>();
    private audio: HTMLAudioElement;
    public audioLength: string = '00:00';

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        let msg = changes['message'].currentValue;
        if (msg) {
            this.audio = document.createElement('audio');
            this.audio.src = msg.data;
            this.audioDuration = msg.duration / 100;
            this.audioLength = this.getTimerString(msg.duration / 100);

            this.audio.addEventListener('timeupdate', ev => {
                let {currentTime, duration} = (ev.target as HTMLAudioElement);

                this.currentTime = currentTime;
                this.audioLength = this.getTimerString(currentTime);

                if (currentTime === duration) {
                    this.currentTime = 0;
                    this.playing = false;
                    this.audioLength = this.getTimerString(msg.duration / 100);
                }
            });
        }
    }

    playPause() {
        this.playing = !this.playing;
        if (this.playing) {
            this.audio.play();
            this.played.emit(this.message);
        } else this.audio.pause();
    }

    getTimerString(timer: number): string {
        return formatTimer(timer * 100).substr(0, 5);
    }

    setProgress(slider: MatSliderChange): void {
        this.currentTime = slider.value;
        this.audio.currentTime = slider.value;
    }
}
