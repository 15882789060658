export const PLUGINS_GUIDS = {
    SAR: "asc.{C820F3CC-1248-4CA2-8D0F-D7EFD44DE09C}",
    INV: "asc.{C820F3CC-12A8-A5A2-8D0F-D6EFD66DD80C}",
    ACM: "asc.{A103601F-FDA0-418A-BC37-A514031894C0}"
};

export function SearchAndReplace(key: string, value: string): string {
    return JSON.stringify({
        frameEditorId: "reporter",
        guid: PLUGINS_GUIDS.SAR,
        type: "onExternalPluginMessage",
        data: {
            type: "searchAndReplace",
            text: [
                {
                    searchString: key,
                    replaceString: value,
                    matchCase: true
                }
            ]
        }
    });
}

export function InsertVariable(key: string): string {
    return JSON.stringify({
        frameEditorId: "reporter",
        guid: PLUGINS_GUIDS.INV,
        type: "onExternalPluginMessage",
        data: {
            type: "insertVariable",
            text: key
        }
    });
}

export function InitAutocompleteDictionary(data: string[]): string {
    return JSON.stringify({
        frameEditorId: "reporter",
        guid: PLUGINS_GUIDS.ACM,
        type: "onExternalPluginMessage",
        data: {
            type: "init_dictionary",
            text: data
        }
    });
}

export function ClosePlugin(guid: string): string {
    return JSON.stringify({
        frameEditorId: "reporter",
        guid: PLUGINS_GUIDS[guid],
        type: "onExternalPluginMessage",
        data: {type: "close"}
    });
}
