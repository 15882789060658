import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'caption-value',
    templateUrl: './caption-value.component.html',
    styleUrls: ['./caption-value.component.scss']
})
export class CaptionValueComponent implements OnInit {

    @Input() caption: any;
    @Input() value: any;
    @Input() unit: any;
    @Input() cStyle: any;
    @Input() vStyle: any;

    constructor() {
    }

    ngOnInit() {
    }

}
