import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SettingService} from '../../setting.service';
import {SharedService} from '../../../shared';
import {ReasonForExam} from '../../../model';
import {MatDialogRef} from '@angular/material/dialog';
import {assign} from 'lodash';

@Component({
    selector: 'app-reason-for-exam-edit',
    templateUrl: './reason-for-exam-edit.component.html',
    styleUrls: ['./reason-for-exam-edit.component.scss']
})
export class ReasonForExamEditComponent implements OnInit {

    selectedItem: any;
    form: FormGroup;
    reportOptions = ['WITHOUT', 'WITH'];
    color: string = '#E2E9EE';


    constructor(private fb: FormBuilder,
                public dialogRef: MatDialogRef<ReasonForExamEditComponent>,
                private shared: SharedService,
                private settingService: SettingService) {
        this.form = this.fb.group(new ReasonForExam());
    }

    onSave(data) {

        assign(data, {
            color: this.color
        });

        this.settingService
            .saveReasonForExam(data)
            .subscribe(res => this.dialogRef.close(res));
    }

    ngOnInit() {
        this.form.patchValue(this.selectedItem);

        this.color = this.selectedItem.color;
    }


}
