<mat-toolbar color="primary">
    <mat-icon fontSet="mdi" fontIcon="mdi-code-array"></mat-icon>&nbsp;
    <span>{{'SEARCH_TEMPLATE' | translate}}</span>
    <span fxFlex></span>
    <button mat-icon-button tabindex="-1" matDialogClose>
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content style="padding: 0 !important; overflow: hidden; height: calc(100% - 90px);">

    <div *ngIf="reports.length !== 0; else loading" style="height: 100%; overflow: auto">
        <mat-accordion displayMode="flat" fxFill style="overflow: auto;">
            <mat-expansion-panel *ngFor="let item of reports" [expanded]="item === selected" (click)="selected = item">
                <mat-expansion-panel-header [collapsedHeight]="'32px'" [expandedHeight]="'32px'">
                    <mat-panel-title>
                        {{item.key}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-nav-list dense class="my-list">
                    <mat-list-item *ngFor="let tm of item.data" (click)="selectRow(tm)" style="height: 27px !important;">
                        <span mat-line>{{ tm.name }}</span>
                    </mat-list-item>
                </mat-nav-list>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <ng-template #loading>
        <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
            <mat-spinner [diameter]="50" [strokeWidth]="2"></mat-spinner>
        </div>
    </ng-template>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button color="primary" mat-dialog-close mat-raised-button>{{'OK' | translate}}</button>
    <button color="accent" mat-dialog-close mat-raised-button>{{'EXIT' | translate}}</button>
</mat-dialog-actions>

