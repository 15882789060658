import {Pipe, PipeTransform} from '@angular/core';
import * as numeral from 'numeral';

@Pipe({
  name: 'numeric'
})
export class NumericPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let [{numberFormat, comma}] = args;
    return numeral(value).format(comma ? numberFormat : '0,0');
  }

}
