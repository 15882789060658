import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PatientService} from '../patient.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';

import {findIndex, get, map as _map, reduce, sortBy} from 'lodash';
import {BehaviorSubject, fromEvent as observableFromEvent, merge, of as observableOf} from 'rxjs';
import {PAT_TABLE_CONF} from './table-conf';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {DeleteConfirmComponent, getConfidentialityColor, LocalStorageService} from '../../shared';
import {tableAnimation} from '../../animations';
import {AppConfigService} from "../../app-config.service";
import * as moment from "moment";
import * as FileSaver from 'file-saver';
import {error} from "jquery";


@Component({
    selector: 'app-patient-list',
    templateUrl: './patient-list.component.html',
    styleUrls: ['./patient-list.component.scss'],
    animations: [tableAnimation]
})
export class PatientListComponent implements AfterViewInit, OnInit {

    title = 'PATIENTS';
    profile: any;
    canViewConfData: boolean;

    displayedColumns = [];
    columnsToDisplay = [];
    availableColumns = [];

    dataSource = new MatTableDataSource();

    @ViewChild('filter', { static: true }) filter: ElementRef;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    filterChange = new BehaviorSubject('');
    resultsLength = 0;
    isLoadingResults = true;
    isResultsLoaded = false;

    deleteSubject = new BehaviorSubject<number>(null);
    dateFormat: string = 'DD/MM/YYYY';
    downloading: boolean;

    constructor(private patientService: PatientService,
                private router: Router,
                private route: ActivatedRoute,
                private _config: AppConfigService,
                private dialog: MatDialog,
                private localStorage: LocalStorageService) {
        this.dateFormat = this._config.momentDateFormat

        const user = get(this.route.snapshot.data, 'user');
        this.profile = get(user, 'profile');
        this.canViewConfData = user.canViewConfidentialData;

        this.displayedColumns = PAT_TABLE_CONF;
        this.availableColumns = this.localStorage.getItem('patient_cols') || sortBy(PAT_TABLE_CONF.filter(item => (!item.hidden && item.required)), 'order');
        this.setColumnsToDisplay(this.availableColumns);
    }

    trackById(index: number, item: any): string {
        return item.id;
    }

    exportCSV() {
        this.downloading = true;
        let ids = "all";
        if (this.filter.nativeElement.value != '')
            ids = this.dataSource.data.map(value => value['id']).join('.') || 'all';

        this.patientService.exportPatients(ids).subscribe(value => {
            this.downloading = false;
            const blob = new Blob([value], {type: 'application/vnd.ms-excel'});
            const file = new File([blob], 'patients.xlsx', {type: 'application/vnd.ms-excel'});

            FileSaver.saveAs(file);
        }, error => this.downloading = false);
    }

    toggleColumn(col) {
        let idx = findIndex(this.availableColumns, {header: col.header});
        this.availableColumns[idx].hidden = !col.hidden;
        this.setColumnsToDisplay(this.availableColumns);
    }

    setColumnsToDisplay(columns) {
        this.localStorage.setItem('patient_cols', columns);

        this.columnsToDisplay = _map(columns.filter(c => !c.hidden), 'label');
        this.columnsToDisplay.push('action');
    }

    showConfData(row, label): string {
        return !row.confidential || this.canViewConfData ? row[label] : '****';
    }

    editPatient(row) {
        this.router.navigate(['/patients/details', row.id]);
    }

    deletePatient(row) {
        const dialogRef = this.dialog.open(DeleteConfirmComponent, {data: 'PATIENT_DELETE_MESSAGE'});
        dialogRef.afterClosed().subscribe(ok => {
            if (ok) {
                this.patientService
                    .deletePatient(row.id)
                    .subscribe(res => this.deleteSubject.next(row.id));
            }
        });
    }

    ngOnInit() {
        this.filter.nativeElement.focus();
    }

    private resetPaginator() {
        this.patientService.patientCreated.subscribe(() => this.paginator.pageIndex = 0)
    }

    ngAfterViewInit() {
        this.resetPaginator();

        observableFromEvent(this.filter.nativeElement, 'keyup')
            .subscribe(() => {
                if (!this.dataSource) return;
                this.paginator.pageIndex = 0;
                this.filterChange.next(this.filter.nativeElement.value);
            });

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        let dataChanges = [
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.filterChange.asObservable(),
            this.patientService.patientCreated,
            this.deleteSubject
        ];

        merge(...dataChanges)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.patientService.loadPatients(this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        this.filterChange.getValue());
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isResultsLoaded = false;
                    this.resultsLength = data.totalElements;

                    return _map(data.content, tsk => {
                        return reduce(this.displayedColumns, (obj, field) => {
                            obj[field.label] = get(tsk, field.value, field.defaultValue);
                            return obj
                        }, {});
                    });
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isResultsLoaded = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.dataSource.data = data);
    }

    getConfidentialityColor(conf: string): string {
        return getConfidentialityColor(conf);
    }

    can(row: any, action: string): boolean {
        return (this.profile[action] !== 'NONE') && !row.confidential || this.canViewConfData;
    }


    formatDate(date: any): any {
        return date ? moment(date, 'YYYY-MM-DD').format(this.dateFormat): '-';
    }
}
