import {ColumnDataType, TableColumn} from "../../model";

export const SC_COLUMNS: TableColumn[] = [
    {
        label: 'id',
        value: 'id',
        hidden: true,
        header: 'ID',
        sortField: 'id',
        sortable: true,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.NUMERIC,
        defaultValue: '',
        selected: true,
        unit: ''
    }, {
        label: 'appointmentDateTime',
        value: 'appointmentDateTime',
        hidden: false,
        sortField: 'appointment_date_time',
        header: 'RDV_DATE',
        sortable: true,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.DATE,
        defaultValue: '',
        selected: true,
        unit: ''
    }, {
        label: 'patientName',
        value: 'patientName',
        hidden: false,
        sortField: 'patient_name',
        header: 'FULLNAME',
        sortable: true,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.STRING,
        defaultValue: '',
        selected: true,
        unit: ''
    }, {
        label: 'procedureCode',
        value: 'procedureCode',
        hidden: false,
        sortField: 'procedure_code',
        header: 'PROCEDURE_CODE',
        sortable: true,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.STRING,
        defaultValue: '',
        selected: true,
        unit: ''
    },{
        label: 'noteAlert',
        value: 'noteAlert',
        hidden: false,
        sortField: 'note_alert',
        header: 'NOTE_ALERT',
        sortable: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.STRING,
        defaultValue: '',
        selected: true,
        unit: 'ICON'
    }, {
        label: 'count',
        value: 'count',
        hidden: false,
        sortField: 'count',
        header: '#',
        sortable: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.NUMERIC,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'patientID',
        header: 'PATIENTID',
        value: 'patientID',
        sortField: 'ext_patient_id',
        sortable: true,
        hidden: true,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.STRING,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'insuranceName',
        header: 'INSURANCE',
        value: 'insuranceName',
        sortField: 'insurance_name',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.STRING,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'patientId',
        header: 'PATIENT_ID',
        value: 'patientId',
        sortField: 'patient_id',
        sortable: true,
        hidden: true,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.NUMERIC,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'patientArrival',
        header: 'PATIENTARRIVAL',
        value: 'patientArrival',
        sortField: 'patient_arrival',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.DATE,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'referringPhysician',
        header: 'REFERRING_PHYSICIAN',
        value: 'referringPhysician',
        sortField: 'referring_physician',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.STRING,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'examType',
        header: 'PROCEDURE_TYPE',
        value: 'examType',
        sortField: 'exam_type',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.STATUS,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'completedReportStatus',
        header: 'REPORT_COMPLETION_STATUS',
        value: 'completedReportStatus',
        sortField: 'cmp_report_status',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.STATUS,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'reportSignature',
        header: 'REPORT_SIGNATURE',
        value: 'reportSignature',
        sortField: 'report_signature',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.DATE,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'reportDelivered',
        header: 'DELIVERY_DATE',
        value: 'reportDelivered',
        sortField: 'report_delivered',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.DATE,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'urgent',
        header: 'U.',
        value: 'urgent',
        sortField: 'urgent',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.BOOLEAN,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'patientClass',
        header: 'PATIENT_CLASS',
        value: 'patientClass',
        sortField: 'patient_class',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.STATUS,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'patientStatus',
        header: 'PATIENT_STATUS',
        value: 'patientStatus',
        sortField: 'patient_status',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.STATUS,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'sent',
        header: 'Sync.',
        value: 'sent',
        sortField: 'sent',
        sortable: true,
        hidden: true,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.BOOLEAN,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'billed',
        header: 'BILLED',
        value: 'billed',
        sortField: 'billed',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.BOOLEAN,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'paymentStatus',
        header: 'PAYMENT_STATUS',
        value: 'paymentStatus',
        sortField: 'payment_status',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.STATUS,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'totalAmount',
        header: 'TOTAL_AMOUNT',
        value: 'totalAmount',
        sortField: 'total_amount',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.NUMERIC,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'paidAmount',
        header: 'PAID_AMOUNT',
        value: 'paidAmount',
        sortField: 'paid_amount',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.NUMERIC,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'leftAmount',
        header: 'LEFT_AMOUNT',
        value: 'leftAmount',
        sortField: 'left_amount',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.NUMERIC,
        defaultValue: '',
        selected: true,
        unit: ''
    },
    {
        label: 'globalDiscount',
        header: 'DISCOUNT',
        value: 'globalDiscount',
        sortField: 'globalDiscount',
        sortable: true,
        hidden: false,
        searchable: true,
        required: false,
        order: 1,
        id: 0,
        type: ColumnDataType.NUMERIC,
        defaultValue: '',
        selected: true,
        unit: ''
    }
];


export const SC_COLS = [
    {header: 'PROCEDURE_CODE', attr: 'procedureCode', hidden: false},
    {header: 'ACCESSION_NUMBER', attr: 'accessionNumber', hidden: false},
    {header: 'SPSSTATUS', attr: 'spsStatus', hidden: false},
    {header: 'REPORT_COMPLETION_STATUS', attr: 'completedReportStatus', hidden: false},
    {header: 'MODALITY', attr: 'modality', hidden: false},
    {header: 'PROCEDURE_TYPE', attr: 'examType', hidden: false},
    {header: 'PERFORMING_PHYSICIAN', attr: 'performingPhysician', hidden: false},
    {header: 'TECHNICIAN', attr: 'technician', hidden: false},
    {header: 'PATIENTARRIVAL', attr: 'patientArrival', hidden: false},
    {header: 'EXAM_START', attr: 'examinationStarted', hidden: false},
    {header: 'EXAM_FINISH', attr: 'examinationFinished', hidden: false},
    {header: 'TOTAL_AMOUNT', attr: 'totalAmount', hidden: false},
    {header: 'DISCOUNT', attr: 'discount', hidden: false}
];
