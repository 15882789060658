<mat-card fxFill style="padding: 0" class="card-view">
        <div class="mat-table" fxFill fxLayout="column">
            <mat-toolbar class="sc-toolbar2" color="primary" fxFlex="nogrow" fxLayout="row"
                         fxLayoutAlign="space-between center">
                <div class="title">
                    <mat-icon fontIcon="mdi-note" fontSet="mdi"></mat-icon>
                    <span>{{worklist | translate}}</span>
                </div>

                <span fxFlex></span>
                <span [style.fontSize.px]="14">{{currentDate}}</span>
                <span fxFlex></span>
            </mat-toolbar>

            <div [formGroup]="filterForm" [style.paddingLeft.px]="12" [style.paddingTop.px]="8" class="search-area" fxLayout="row wrap"
                 fxLayoutAlign="start center" fxLayoutGap="8px">

                <mat-form-field appearance="standard" floatLabel="never">
                        <span matPrefix><mat-icon fontIcon="mdi-magnify" fontSet="mdi"></mat-icon> &nbsp;</span>
                    <input #filter [autofocus]="true" formControlName="key" matInput
                           placeholder="{{'SEARCH' | translate}}">
                </mat-form-field>

                <mat-form-field appearance="standard" floatLabel="never">
                        <span matPrefix><mat-icon fontIcon="mdi-test-tube" fontSet="mdi"></mat-icon> &nbsp;</span>
                    <input formControlName="pathology" matInput placeholder="{{'PATHOLOGY' | translate}}">
                </mat-form-field>

                <span fxFlex></span>

                <div *ngIf="group.value === 'OT'" class="date-range" fxLayout="row nowrap" fxLayoutAlign="start end"
                     fxLayoutGap="4px">
                    <mat-form-field>
                        <input (dateInput)="changePeriod()" [matDatepicker]="startDate" [placeholder]="'FROM' | translate"
                               formControlName="startDate" matInput>
                        <mat-datepicker-toggle [for]="startDate" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #startDate (closed)="changePeriod()"></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field>
                        <input (dateInput)="changePeriod()" [matDatepicker]="endDate" [placeholder]="'TO' | translate"
                               formControlName="endDate" matInput>
                        <mat-datepicker-toggle [for]="endDate" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #endDate (closed)="changePeriod()"></mat-datepicker>
                    </mat-form-field>
                </div>

                <mat-button-toggle-group #group="matButtonToggleGroup" (change)="changeRange($event)" aria-label="Period"
                                         formControlName="period" name="period">
                    <mat-button-toggle value="TODAY">
                        {{'TODAY' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="3D">
                        {{'THREE_DAYS' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="1W">
                        {{'ONE_WEEK' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="2W">
                        {{'TWO_WEEKS' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="1M">
                        {{'ONE_MONTH' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="3M">
                        {{'THREE_MONTHS' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="OT">
                        {{'ALL' | translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>

                <!--    <button (click)="queryPacs()" mat-flat-button>Query PACS</button>
-->
            </div>

            <div fxFlex style="overflow: auto">
                <mat-table [@state]="dataSource.data.length" [dataSource]="dataSource"
                           [matSortActive]="sorting.active" [matSortDirection]="sorting.direction"
                           [trackBy]="trackById"
                           class="fire-table"
                           matSort
                           matSortDisableClear>

                    <!--Table-->
                    <ng-container *ngFor="let col of displayedColumns" matColumnDef="{{col.label}}">
                        <mat-header-cell *matHeaderCellDef [disabled]="!col.sortable" class="fire-header-cell"
                                         mat-sort-header="{{col.value}}"> {{col.header | translate}}
                        </mat-header-cell>

                        <ng-container *ngIf="col.unit === 'comment'">
                            <mat-cell *matCellDef="let row" class="fire-cell">
                                <button (click)="openDetails(row)" *ngIf="row.assigningComment"
                                        [matTooltip]="(userFullName === row.performerName.fullName ? 'OPEN_PARAM': 'ASSIGNED_FROM') | translate:{value: row.performerName.fullName}"
                                        mat-icon-button
                                        matTooltipClass="assignee-tooltip">
                                    <mat-icon [fontIcon]="getRowIcon(row)" [ngStyle]="{color: userFullName === row.performerName.fullName ? '#ff65d1': '#03A9F4'}"
                                              fontSet="mdi"></mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container *ngIf="col.unit === 'prio'">
                            <mat-cell *matCellDef="let row" class="fire-cell">
                                <div [ngStyle]="{'background': 'linear-gradient(45deg, '+row['priority_color']+', white)'}" class="rpt-status">
                                    {{row[col.label]}}
                                </div>
                            </mat-cell>
                        </ng-container>

                        <ng-container *ngIf="col.unit === 'payment'">
                            <mat-cell *cdkCellDef="let row" [matTooltip]="row[col.label] | translate"
                                      class="fire-cell">
                                <span [ngStyle]="{background: 'linear-gradient(45deg, black, '+getPaymentStatusColor(row[col.label])+', white)', borderRadius: '50%', height: '16px'}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container *ngIf="col.unit === 'history'">
                            <mat-cell *cdkCellDef="let row" class="fire-cell">
                                <mat-icon  *ngIf="row[col.label]" [matTooltip]="row[col.label]" color="warn" fontIcon="mdi-account-clock" fontSet="mdi" matTooltipClass="med-history"></mat-icon>
                            </mat-cell>
                        </ng-container>

                        <ng-container *ngIf="col.unit === 'date'">
                            <mat-cell *matCellDef="let row"
                                      class="fire-cell"> {{formatDate(row[col.label])}}
                            </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="col.unit === 'status'">
                            <mat-cell *matCellDef="let row" class="fire-cell">
                                <div [ngStyle]="{'background': 'linear-gradient(45deg, '+getStatusColor(row[col.label])+', white)'}"
                                     class="rpt-status">
                                    {{row[col.label] | translate}}
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="col.unit === 'pathology'">
                            <mat-cell *matCellDef="let row" [matTooltip]="row[col.label]" class="fire-cell">
                                {{row[col.label]}}
                            </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="col.unit === 'FULL_NAME'">
                            <mat-cell *matCellDef="let row" class="fire-cell">
                                {{showConfData(row, col.label)}}
                            </mat-cell>
                        </ng-container>
                        <ng-container
                                *ngIf="col.unit !== 'date' && col.unit !== 'comment' && col.unit !== 'history'  && col.unit !== 'payment' && col.unit !== 'status' && col.unit !== 'FULL_NAME' && col.unit !== 'prio' && col.unit !== 'pathology'">
                            <mat-cell *matCellDef="let row" class="fire-cell"> {{row[col.label]}}</mat-cell>
                        </ng-container>
                    </ng-container>

                    <!--actions-->
                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef class="fire-header-cell"></mat-header-cell>
                        <mat-cell *matCellDef="let row" class="fire-cell" fxLayout="row nowrap"
                                  fxLayoutAlign="end center" style="width: 120px;">
                            <button (click)="openReport(row.id)"
                                    *ngIf="can(row, 'editReport')" [matTooltip]="'OPEN' | translate"
                                    mat-icon-button>
                                <mat-icon class="open-report-icon" fontIcon="mdi-folder-open" fontSet="mdi"></mat-icon>
                            </button>
                            <button (click)="openReport(row.id, true)"
                                    *ngIf="can(row, 'editReport')" [matTooltip]="'OPEN_NEW_TAB' | translate"
                                    mat-icon-button>
                                <mat-icon class="open-report-tab-icon" fontIcon="mdi-open-in-new" fontSet="mdi"></mat-icon>
                            </button>
                            <button [matMenuTriggerFor]="menu" mat-icon-button>
                                <mat-icon fontIcon="mdi-dots-horizontal" fontSet="mdi"></mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" [overlapTrigger]="true">
                                <!--<button mat-menu-item *ngIf="can(row, 'createPrescription')"
                                        (click)="createPrescription(row)">
                                    <mat-icon fontIcon="mdi-test-tube" fontSet="mdi"></mat-icon>
                                    {{'CREATE_PRESCRIPTION' | translate}}
                                </button>-->
                                <button (click)="generateReport(row)" *ngIf="false" [disabled]="!can(row, 'editReport')"
                                        mat-menu-item>
                                    <mat-icon fontIcon="mdi-file" fontSet="mdi"></mat-icon>
                                    {{'NORMAL_REPORT' | translate}}
                                </button>
                                <button (click)="printReportingTask(row)" *ngIf="can(row, 'printReport') && canPrint(row)"
                                        mat-menu-item>
                                    <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                    {{'PRINT_REPORT' | translate}}
                                </button>
                                <button (click)="assignPerformer(row)"
                                        *ngIf="canAssign(row)" mat-menu-item>
                                    <mat-icon fontIcon="mdi-account-arrow-right" fontSet="mdi"></mat-icon>
                                    {{'DELEGATE_TASK' | translate}}
                                </button>
                                <button (click)="assignPerformer(row, 'RE_ASSIGN_TASK')"
                                        *ngIf="canAssign(row)" mat-menu-item>
                                    <mat-icon fontIcon="mdi-account-convert" fontSet="mdi"></mat-icon>
                                    {{'RE_ASSIGN_TASK' | translate}}
                                </button>
                                <button (click)="addComment(row)" mat-menu-item>
                                    <mat-icon fontIcon="mdi-comment-alert" fontSet="mdi"></mat-icon>
                                    {{'ADD_COMMENT' | translate}}
                                </button>
                                <mat-divider></mat-divider>
                                <button (click)="deleteReportingTask(row)"
                                        *ngIf="can(row, 'deleteReport') && row.reportingStatus !== 'DELIVERED'"
                                        mat-menu-item>
                                    <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                                    {{'DELETE' | translate}}
                                </button>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>

                    <!--header-->
                    <mat-header-row *matHeaderRowDef="cols; sticky: true" class="fire-header-row"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: cols;"
                             class="fire-row">
                    </mat-row>
                </mat-table>

                <div [style.display]="resultsLength === 0 ? '' : 'none'" class="example-no-results" style="height: calc(100% - 44px)">
                    <div *ngIf="isLoadingResults; else noItem">
                        <mat-spinner [diameter]="50" [strokeWidth]="3" color="accent"></mat-spinner>
                    </div>
                    <ng-template #noItem>
                        {{'NOITEMSFOUND' | translate}}
                    </ng-template>
                </div>
            </div>

            <mat-paginator
                    [length]="resultsLength"
                    [pageIndex]="0"
                    [pageSizeOptions]="[10, 20, 50, 100]"
                    [pageSize]="50" showFirstLastButtons>
            </mat-paginator>
        </div>
</mat-card>
