import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'emojisDisplay'
})
export class EmojisDisplayPipe implements PipeTransform {

    private re = /\[\d+\]/g;

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: string, ...args: any[]): any {
        if (!value) return '';
        let m, emojis = [], maps = {};
        do {
            m = this.re.exec(value);
            if (m) {
                let [v] = m;
                emojis.push(v);
            }
        } while (m);

        emojis.forEach(em => {
            let e = em.replace('[', '').replace(']', '');
            maps[em] = `<img src="assets/emojis/${e}.png" height="22">`;
        });

        for (let key in maps) value = value.replaceAll(key, maps[key]);
        return this.sanitizer.bypassSecurityTrustHtml(`${value}`);
    }

}
