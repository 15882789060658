import {Component} from '@angular/core';
import {SettingService} from './setting.service';
import {Profile} from '../model';
import {AuthService} from "../auth";

@Component({
    selector: 'app-setting',
    templateUrl: './setting.component.html',
    styleUrls: ['./setting.component.scss'],
    providers: [SettingService]
})
export class SettingComponent {

    headerTitle = 'Settings';
    headerIcon = 'building';

    profile: Profile;

    systemItems = [
        {
            title: 'GENERAL',
            icon: 'mdi-cogs',
            iconColor: 'dodgerblue',
            route: 'schedule-setting',
            authLabel: 'manageCalendarSetting'
        },
        {
            title: 'IMAGINGCENTER',
            icon: 'mdi-hospital-building',
            iconColor: 'teal',
            route: 'imaging-center-setting',
            authLabel: 'manageCenter'
        },
        {
            title: 'MODALITIES',
            icon: 'mdi-drawing',
            iconColor: 'darkblue',
            route: 'modality-setting',
            authLabel: 'manageModalities'
        },
        {
            title: 'AETS',
            icon: 'mdi-lan-connect',
            iconColor: '#009688',
            route: 'aet-setting',
            authLabel: 'manageAETs'
        },
        {
            title: 'VIEWERS',
            icon: 'mdi-monitor-dashboard',
            iconColor: '#961f74',
            route: 'viewers',
            authLabel: 'manageAETs'
        },
        {
            title: 'ORDERPRIORITIES',
            icon: 'mdi-sort',
            iconColor: 'crimson',
            route: 'priority-setting',
            authLabel: 'manageOrderPriorities'
        },
        {
            title: 'ROOMS',
            icon: 'mdi-door',
            iconColor: '#e91e63',
            route: 'rooms',
            authLabel: 'manageRooms'
        }, {
            title: 'REFERRING_PHYSICIANS',
            icon: 'mdi-account-multiple',
            iconColor: '#007dc1',
            route: 'referring-physicians',
            authLabel: 'manageUsers'
        },
        {
            title: 'HOLIDAYS',
            icon: 'mdi-alarm-off',
            iconColor: '#0ee9b4',
            route: 'holidays',
            authLabel: 'manageHolidays'
        },
        {
            title: 'SMS_TEMPLATES',
            icon: 'mdi-message',
            iconColor: '#d35603',
            route: 'sms-templates',
            authLabel: 'manageReportTemplates'
        }
    ];
    procedureItems = [
        {
            title: 'REASONSFOREXAM',
            icon: 'mdi-file-question',
            iconColor: '#e91e63',
            route: 'reason-for-exam',
            authLabel: 'manageProcedureCodes'
        },
        {
            title: 'PROCEDURECODES',
            icon: 'mdi-folder-move',
            iconColor: '#ff5722',
            route: 'procedure-code-setting',
            authLabel: 'manageProcedureCodes'
        },
        {
            title: 'BILLING_CODES',
            icon: 'mdi-credit-card',
            iconColor: '#02bcc0',
            route: 'billing-code-setting',
            authLabel: 'manageProcedureCodes'
        },
        {
            title: 'PATHOLOGIES',
            icon: 'mdi-dna',
            iconColor: '#6b81ff',
            route: 'pathology-setting',
            authLabel: 'managePathologies'
        },
        {
            title: 'DEFAULT_VALUES',
            icon: 'mdi-order-bool-descending',
            iconColor: '#2dce01',
            route: 'default-values',
            authLabel: 'manageDefaultValues'
        },
        {
            title: 'REPORTTEMPLATEMODELS',
            icon: 'mdi-folder-open',
            iconColor: '#ffd200',
            route: 'report-template-setting',
            authLabel: 'manageReportTemplates'
        },
     /*   {
            title: 'DICTIONARY',
            icon: 'mdi-book',
            iconColor: '#4db6ac',
            route: 'dictionary',
            authLabel: 'manageDictionaries'
        },*/
        {
            title: 'PRINTING_TEMPLATES',
            icon: 'mdi-printer',
            iconColor: '#b63255',
            route: 'printing-templates',
            authLabel: 'manageReportTemplates'
        },
        {
            title: 'PRINTING_TEMPLATES_CUPS',
            icon: 'mdi-printer',
            iconColor: '#01bbc1',
            route: 'printing-models',
            authLabel: 'manageReportTemplates'
        }
    ];
    securityItems = [
        {
            title: 'USERS',
            icon: 'mdi-account-multiple',
            iconColor: '#ff5722',
            route: 'users-setting',
            authLabel: 'manageUsers'
        },
        {
            title: 'PROFILES',
            icon: 'mdi-incognito',
            iconColor: '#455a64',
            route: 'profile-setting',
            authLabel: 'manageProfile'
        },
        {
            title: 'STAFF_CONTRACTS',
            icon: 'mdi-file-table',
            iconColor: '#4db6ac',
            route: 'staff-contract',
            authLabel: 'manageUsers'
        }
    ];

    constructor(private _authService: AuthService) {
        this._authService.profileChanged.subscribe(profile => this.profile = profile);

        this.profile = JSON.parse(localStorage.getItem('profile'));
    }

    setHeaderTitle(item) {
        this.headerTitle = item.title;
        this.headerIcon = item.icon;
    }
}
