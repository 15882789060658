export const MOD_TABLE_CONF = [
    {
        'label': 'id',
        'header': 'ID',
        'value': 'id',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': true,
        'searchable': true,
        'required': true
    }, {
        'label': 'value',
        'header': 'VALUE',
        'value': 'value',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }, {
        'label': 'description',
        'header': 'DESCRIPTION',
        'value': 'description',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }, {
        'label': 'defaultAETitle',
        'header': 'DEFAULTAETITLE',
        'value': 'defaultAETitle',
        'type': 'string',
        'unit': null,
        'defaultValue': '-',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }, {
        'label': 'color',
        'header': 'COLOR',
        'value': 'color',
        'type': 'string',
        'unit': 'color',
        'defaultValue': '#e5e5e5',
        'sortable': true,
        'hidden': false,
        'searchable': true,
        'required': true
    }
];

export const MOD_HEADER_COLS = [
    // 'id',
    'value',
    'description',
    'defaultAETitle',
    'color',
    'action'
];
