<div class="viewer" fxLayout="column" fxLayoutAlign="space-between" fxFill>

    <!--header-->
    <div fxLayout="row" fxLayoutAlign="space-between center" style="z-index: 1000">
        <h4 style="color: #FFFFFF;">{{current.name}}</h4>
        <span fxFlex></span>
        <!--zoom-->
        <button mat-icon-button (click)="zoomin()">
            <mat-icon fontSet="mdi" fontIcon="mdi-magnify-plus-outline" class="w-icon"></mat-icon>
        </button>
        <!--set kos-->
        <button mat-icon-button (click)="setKOS(current)">
            <mat-icon fontSet="mdi" [fontIcon]="current.fav ? 'mdi-star': 'mdi-star-outline'" class="w-icon"></mat-icon>
        </button>

        <button mat-icon-button (click)="zoomout()">
            <mat-icon fontSet="mdi" fontIcon="mdi-magnify-minus-outline" class="w-icon"></mat-icon>
        </button>
        <span fxFlex></span>
        <!--download-->
        <button *ngIf="!data.fromPacs" mat-icon-button (click)="download()">
            <mat-icon fontSet="mdi" fontIcon="mdi-cloud-download" class="w-icon"></mat-icon>
        </button>
        <button mat-icon-button mat-dialog-close>
            <mat-icon fontSet="mdi" fontIcon="mdi-close" class="w-icon"></mat-icon>
        </button>
    </div>

    <!--container-->
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <button class="btn-left" mat-icon-button (click)="previous()">
            <mat-icon fontIcon="mdi-arrow-left" fontSet="mdi" class="w-icon"></mat-icon>
        </button>

        <div fxFlex fxLayout="column" fxLayoutAlign="start center">
            <img [src]="getOriginalImage(current.url)" [alt]="current.name" class="main-image" #image>
        </div>

        <button class="btn-right" mat-icon-button (click)="next()">
            <mat-icon fontIcon="mdi-arrow-right" fontSet="mdi" class="w-icon"></mat-icon>
        </button>
    </div>

    <!--images grid -->
    <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px" style="overflow: hidden;">
        <div *ngFor="let image of images" class="image-item" (click)="current = image">
            <img [src]="image.url" [ngClass]="{'selected': image === current}">
        </div>
    </div>
</div>
