<div *ngIf="formattingRule" class="list-style-line" fxLayout="row" fxLayoutAlign="start center"
     style="border-bottom: 1px solid #e5e5e5; padding: 8px;">
    <div cdkDragHandle class="drag-icon">
        <mat-icon (click)="$event.preventDefault();$event.stopPropagation()" fontIcon="mdi-drag-vertical"
                  fontSet="mdi"></mat-icon>
    </div>
    <div [ngStyle]="previewStyle" [style.height.px]="56"
         class="preview-shape"
         fxFlex="44px" fxLayout="row" fxLayoutAlign="center center">
        <span>{{formattingRule.appliedTo === 'ROW' ? 'Li': 'Col'}}</span>
    </div>
    <div class="style-detail" fxFlex="212px" fxLayout="column" fxLayoutAlign="center start">
        <div class="header">{{formattingRule.targetColumn | translate}}</div>
        <div class="description">{{formattingRule.formatRule | translate}}:
            <span [style.color]="formattingRule.formattingStyle.color" [style.fontWeight]="'bold'">{{formattingRule.primaryFormatValue | translate}}</span>
            <span *ngIf="formattingRule.secondaryFormatValue" [style.color]="formattingRule.formattingStyle.color" [style.fontWeight]="'bold'"> & {{formattingRule.secondaryFormatValue}}</span>
        </div>
    </div>
    <div>
        <button (click)="onDelete.emit(true)" mat-icon-button>
            <mat-icon fontIcon="mdi-delete" fontSet="mdi" style="color: #949494;"></mat-icon>
        </button>
    </div>
</div>