import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SchedulerComponent} from './scheduler.component';
import {FireCalendarComponent} from './fire-calendar/fire-calendar.component';
import {ExternalAppointmentsComponent} from './external-appointments';
import {UserResolver} from '../user.resolver';
import {GeneralSettingResolver} from '../general-setting.resolver';
import {ScheduleManagerComponent} from "./schedule-manager/schedule-manager.component";

const routes: Routes = [
    {
        path: '',
        component: SchedulerComponent,
        resolve: {setting: GeneralSettingResolver},
        children: [
            {path: '', pathMatch: 'full', redirectTo: 'schedule-manager'},
            {path: 'schedule-manager', component: ScheduleManagerComponent, resolve: {user: UserResolver}},
            {path: 'external-appointments', component: ExternalAppointmentsComponent, resolve: {user: UserResolver}},
            {path: 'calendar', component: FireCalendarComponent, resolve: {user: UserResolver}}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SchedulerRoutingModule {
}
