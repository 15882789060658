import {ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormButtonComponent} from './form-button/form-button.component';
import {FormInputComponent} from './form-input/form-input.component';
import {FormSelectComponent} from './form-select/form-select.component';
import {FormTextareaComponent} from './form-textarea/form-textarea.component';
import {FormRadioComponent} from './form-radio/form-radio.component';
import {FormMultiselectComponent} from './form-multiselect/form-multiselect.component';


const components = {
    BUTTON: FormButtonComponent,
    TEXTFIELD: FormInputComponent,
    SELECT: FormSelectComponent,
    TEXTAREA: FormTextareaComponent,
    RADIO: FormRadioComponent,
    MULTI: FormMultiselectComponent,
};

@Directive({
    selector: '[dynamicField]'
})
export class DynamicFieldDirective implements OnInit {
    @Input()
    config;

    @Input()
    group: FormGroup;

    component;

    constructor(private container: ViewContainerRef, private resolver: ComponentFactoryResolver) {
    }

    ngOnInit() {
        const component = components[this.config.type];
        const factory = this.resolver.resolveComponentFactory<any>(component);
        this.component = this.container.createComponent(factory);
        this.component.instance.config = this.config;
        this.component.instance.group = this.group;
    }

}
