import {BehaviorSubject, fromEvent as observableFromEvent, last, merge, of as observableOf} from 'rxjs';

import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DeleteConfirmComponent, ReferringPhysicianAddComponent, SharedService} from '../../shared';
import {REF_HEADER_COLS, REF_TABLE_CONF} from './table-conf';
import {tableAnimation} from '../../animations';
import * as FileSaver from 'file-saver';
import {MessageDialogComponent} from "../../shared/message-dialog/message-dialog.component";

@Component({
    selector: 'app-referring-physicians',
    templateUrl: './referring-physicians.component.html',
    styleUrls: ['./referring-physicians.component.scss'],
    animations: [tableAnimation]
})
export class ReferringPhysiciansComponent implements OnInit, AfterViewInit {

    dataSource = new MatTableDataSource();
    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    filterChange = new BehaviorSubject('');
    editChange = new BehaviorSubject('');

    cols: any[];
    displayedColumns = [];

    @ViewChild('filter', { static: true }) filter: ElementRef;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    public downloading: boolean = false;

    constructor(private shared: SharedService, private dialog: MatDialog) {
        this.cols = REF_HEADER_COLS;
        this.displayedColumns = REF_TABLE_CONF;
    }

    ngOnInit() {
        this.filter.nativeElement.focus();
    }

    trackByLastName(index: number, item: any): string {
        return item.lastName;
    }

    ngAfterViewInit() {

        observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
            debounceTime(400),
            distinctUntilChanged(),)
            .subscribe(() => {
                if (!this.dataSource) return;
                this.paginator.pageIndex = 0;
                this.filterChange.next(this.filter.nativeElement.value);
            });

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange.asObservable(), this.paginator.page.asObservable(), this.editChange, this.filterChange)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.shared.getPaginatedReferringPhysicians(this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        this.filter.nativeElement.value);
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data['totalElements'];
                    let physicians = data['content'];
                    return physicians.filter(val => val.id !== 420);
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.dataSource.data = data);
    }

    editReferringPhysician(item) {
        this.dialog.open(ReferringPhysicianAddComponent, {data: item})
            .afterClosed()
            .subscribe(res => {
                if (res) {
                    this.editChange.next(res);
                }
            })
    }

    deleteReferringPhysician(item) {
        this.shared.isReferringPhysicianUsed(item.id).pipe(last()).subscribe(value => {
            if (value) this.dialog.open(MessageDialogComponent, {data: 'CANNOT_DELETE_USED'});
            else {
                this.dialog.open(DeleteConfirmComponent)
                    .afterClosed()
                    .subscribe(ok => {
                        if (ok) {
                            this.shared.deleteReferringPhysician(item)
                                .subscribe(res => {
                                    if (res) {
                                        this.editChange.next(null);
                                    }
                                })
                        }
                    });
            }
        });
    }

    exportPhysicians() {
        this.downloading = true;

        this.shared.exportPhysicians().subscribe({
            next: data => {
                this.downloading = false;

                const blob = new Blob([data], {type: 'application/vnd.ms-excel'});
                const file = new File([blob], 'physicians.xlsx', {type: 'application/vnd.ms-excel'});

                FileSaver.saveAs(file);
            }, error: _ => this.downloading = false
        });
    }
}
