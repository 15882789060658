import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {SharedService} from '../../../shared';
import {SettingService} from '../../setting.service';

import * as moment from 'moment';
import {assign, set as _set} from 'lodash';

@Component({
    selector: 'app-unavailability-edit',
    templateUrl: './unavailability-edit.component.html',
    styleUrls: ['./unavailability-edit.component.scss']
})
export class UnavailabilityEditComponent implements OnInit {

    selectedUnavailability: any;
    unavailabilityReasons: any[];
    form: FormGroup;
    uid: number;
    resourceID: any;

    constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<UnavailabilityEditComponent>,
                private sharedService: SharedService, private settingService: SettingService) {
        this.form = this.fb.group({
            id: '',
            startTime: moment().format('HH:mm'),
            endTime: moment().format('HH:mm'),
            startDate: [null, Validators.required],
            endDate: [null, Validators.required],
            allDay: false,
            description: '',
            otherReason: '',
            unavailabilityReason: this.fb.group({
                id: '',
                value: '',
                description: ''
            }, Validators.required)
        });
    }

    onSave(data) {

        data.startDate = moment(data.startDate).add(1, 'h');
        data.endDate = moment(data.endDate).add(1, 'h');

        if (data.allDay) {
            _set(data, 'startTime', '00:00');
            _set(data, 'endTime', '23:59');
        }

        this.settingService
            .saveUnavailability(data, this.resourceID)
            .subscribe(res => this.dialogRef.close(res));
    }

    getUnavailabilityReasons() {
        this.sharedService
            .getUnavailabilityReasons()
            .subscribe(data => this.unavailabilityReasons = data);
    }

    ngOnInit() {

        this.uid = this.selectedUnavailability.id;
        this.getUnavailabilityReasons();

        let un = this.selectedUnavailability;

        let unavailability = assign(un, {
            startDate: new Date(moment(un.startDate).format()),
            endDate: new Date(moment(un.endDate).format()),
        });

        this.form.patchValue(unavailability);
    }

}
