import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {SharedService} from '../shared.service';
import {forkJoin} from 'rxjs';
import {SettingService} from '../../setting/setting.service';
import {assign} from 'lodash';

@Component({
    selector: 'app-referring-physician-add',
    templateUrl: './referring-physician-add.component.html',
    styleUrls: ['./referring-physician-add.component.scss']
})
export class ReferringPhysicianAddComponent implements OnInit {

    referringForm: FormGroup;

    titles: any[];
    specialities: any[];
    functions: any[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private shared: SharedService,
                private setting: SettingService,
                private dialogRef: MatDialogRef<ReferringPhysicianAddComponent>) {
        this.createForm();
    }

    save(data) {
        this.setting
            .saveUser(ReferringPhysicianAddComponent.escapeNullData(data))
            .subscribe(res => this.dialogRef.close(res));
    }

    ngOnInit() {
        forkJoin(
            [this.shared.getTitles(),
            this.shared.getSpecialities(),
            this.shared.getFunctions()]
        ).subscribe(data => {
            [this.titles, this.specialities, this.functions] = data;

            if (this.data) this.referringForm.patchValue(ReferringPhysicianAddComponent.prepareDate(this.data));
        })
    }

    private createForm() {
        this.referringForm = this.fb.group({
            id: '',
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            title: this.fb.group({id: ''}),
            speciality: this.fb.group({id: ''}),
            facility: this.fb.group({id: ''}),
            phone: '',
            codeAnam: '',
            email: '',
            address: '',
            function: this.fb.group({id: 130}),
            profile: null
        })
    }

    private static escapeNullData(data: any): any {
        return assign(data, {
            title: data.title.id === '' ? null : data.title,
            speciality: data.speciality.id === '' ? null : data.speciality
        });
    }

    private static prepareDate(data: any) {
        return assign(data, {
            title: data.title || {id: ''},
            speciality: data.speciality || {id: ''},
            facility: data.facility || {id: ''},
            function: data.function || {id: ''},
        });
    }
}
