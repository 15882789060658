import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CalendarEvent, GeneralSetting} from '../../../model';

@Component({
    selector: 'ft-calendar-event',
    templateUrl: './calendar-event.component.html',
    styleUrls: ['./calendar-event.component.scss']
})
export class CalendarEventComponent {

    @Output() onDelete = new EventEmitter<CalendarEvent>();
    @Output() onEventEdit = new EventEmitter<CalendarEvent>();
    @Output() onSchedule = new EventEmitter<CalendarEvent>();
    @Output() onPaymentEdit = new EventEmitter<CalendarEvent>();
    @Output() onPatientEdit = new EventEmitter<CalendarEvent>();
    @Output() onPrint = new EventEmitter<CalendarEvent>();
    @Output() onAuthorizePatient = new EventEmitter<CalendarEvent>();

    _event: CalendarEvent;
    _viewType: string;
    _user: any;
    _generalSetting: GeneralSetting;

    @Input()
    set event(event: CalendarEvent) {
        this._event = event;
    }

    get event(): CalendarEvent {
        return this._event;
    }

    @Input()
    set viewType (viewType: string) {
        this._viewType = viewType;
    }

    get viewType(): string {
        return this._viewType;
    }


    @Input()
    set user(user: any) {
        this._user = user;
    }

    get user(): any {
        return this._user;
    }

    get generalSetting(): GeneralSetting {
        return this._generalSetting;
    }

    @Input()
    set generalSetting(generalSetting: GeneralSetting) {
        this._generalSetting = generalSetting;
    }
}
