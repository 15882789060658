import {Subject} from 'rxjs';

export class FtSpeechRecognition {

    subject = new Subject<any>();
    isStopped: boolean;
    recognition: any;

    constructor() {

        if (!('webkitSpeechRecognition' in window)) return null;

        const webkitSpeechRecognition = window['webkitSpeechRecognition'];

        this.recognition = new webkitSpeechRecognition();

        this.recognition.lang = 'fr-FR';
        this.recognition.continuous = false;
        this.recognition.interimResults = true;

        this.recognition['onstart'] = () => this.isStopped = false;
        this.recognition['onresult'] = (event) => {
            console.log(event);
            this.subject.next(event);
        };
        this.recognition['onend'] = () => {
            if (!this.isStopped) this.recognition.start();
        };

    }

    start() {
        this.isStopped = false;
        if (!this.isStopped) this.recognition.start();
        this.subject = new Subject<any>();
    }

    stop() {
        this.isStopped = true;
        this.subject.complete();
        this.recognition.stop();
    }

}
